import React, { useEffect, useState } from 'react';
import Disclaimer from '../../../Layout/GuestUser/Disclaimer';
import { useHistory, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import {
  sendOtp,
  verifyOtp,
  register as signup,
} from '../../../Store/Actions/userActions';
import MobileScreenLogo from '../../../Common/MobileScreenLogo';
import DeskTopHeader from '../../../Common/DeskTopHeader';
import logoWhiteDesktop from '../../../assets/images/logo_white_desktop.svg'
import {
  USER_REGISTER_FAIL,
  USER_SEND_OTP_SUCCESS,
} from '../../../Store/Constants/userConstants';

const StepFiveNumberVerify = (props) => {
  let [valueOne, setValueOne] = useState('');
  let [valueTwo, setValueTwo] = useState('');
  let [valueThree, setValueThree] = useState('');
  let [valueFour, setValueFour] = useState('');
  let [verify, setVerify] = useState(false);
  const [seconds, setSeconds] = React.useState(59);

  React.useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    }
  });

  let valueOneChange = (e, val) => {
    setValueOne(e.target.value);
    if (e.target.value.length === e.target.maxLength) {
      window.$(val).focus();
    }
  };
  let valueTwoChange = (e, val) => {
    setValueTwo(e.target.value);
    if (e.target.value.length === e.target.maxLength) {
      window.$(val).focus();
    }
  };
  let valueThreeChange = (e, val) => {
    setValueThree(e.target.value);
    if (e.target.value.length === e.target.maxLength) {
      window.$(val).focus();
    }
  };
  let valueFourChange = (e) => {
    setValueFour(e.target.value);
  };
  useEffect(() => {
    if (
      valueOne !== '' &&
      valueTwo !== '' &&
      valueThree !== '' &&
      valueFour !== ''
    )
      setVerify(false);
    else setVerify(true);
  }, [valueOne, valueTwo, valueThree, valueFour]);

  let history = useHistory();
  const dispatch = useDispatch();

  const userSendOtp = useSelector((state) => state.userSendOtp);
  const { loading, userSendOtpInfo, error } = userSendOtp;

  const userRegister = useSelector((state) => state.userRegister);
  const { userInfo, error: errorUserRegister } = userRegister;
  const [successmsg, setSuccessmsg] = useState('');
  const userVerifyOtp = useSelector((state) => state.userVerifyOtp);
  const {
    loading: loadingUserVerifyOtpInfo,
    userVerifyOtpInfo,
    error: errorUserVerifyOtpInfo,
  } = userVerifyOtp;

  useEffect(() => {
    dispatch({ type: USER_REGISTER_FAIL, payload: [] });
  }, []);

  const { register, handleSubmit, reset, errors } = useForm();
  
  const onSubmit = (data) => {
    let verifyNumber =
      data.number1 + '' + data.number2 + '' + data.number3 + '' + data.number4;
    localStorage.setItem('verifyNumber', verifyNumber);
    dispatch(verifyOtp(localStorage.getItem('StepFourNumber'), verifyNumber,localStorage.getItem('StepFourCode')));
  };

  const sendAgainOTP = (data) => {
    dispatch(
      sendOtp(
        localStorage.getItem('StepFourNumber'),
        localStorage.getItem('StepFourSendOption'),
        localStorage.getItem('StepFourCode')
      )
    );
    setSeconds(59);
  };

  useEffect(() => {
    if (userVerifyOtpInfo?.otpVerified) {
      if (localStorage.getItem('InviteCode') != "null") {
        const params = {
          firstName: localStorage.getItem('StepThreeFirstName'),
          lastName: localStorage.getItem('StepThreeLastName'),
          phoneNumber: localStorage.getItem('StepFourNumber'),
          email: localStorage.getItem('StepOneEmail'),
          password: localStorage.getItem('StepTwoPassword'),
          actionListId: localStorage.getItem('InviteCode')
        }
        dispatch(signup(params));
      } else {
        const params = {
          firstName: localStorage.getItem('StepThreeFirstName'),
          lastName: localStorage.getItem('StepThreeLastName'),
          phoneNumber: localStorage.getItem('StepFourNumber'),
          email: localStorage.getItem('StepOneEmail'),
          password: localStorage.getItem('StepTwoPassword'),
        }
        dispatch(signup(params));
      }
    }
    return () => {
      //
    };
  }, [userVerifyOtpInfo]);

  useEffect(() => {
    if (userSendOtpInfo?.msg === 'otp send to your number!') {
      setSuccessmsg(userSendOtpInfo.msg);
      setTimeout(
        function () {
          setSuccessmsg('');
          dispatch({ type: USER_SEND_OTP_SUCCESS, payload: [] });
        }.bind(this),
        3000
      );
    }
  }, [userSendOtpInfo]);

  useEffect(() => {
    if (userInfo) {
      if (userInfo.token) {
        Cookies.set('token', userInfo.token);
        Cookies.set('verified', userInfo.verified);
        localStorage.removeItem('StepOneEmail');
        localStorage.removeItem('StepTwoPassword');
        localStorage.removeItem('StepThreeLastName');
        localStorage.removeItem('StepThreeLastName');
        localStorage.removeItem('StepFourNumber');
        localStorage.removeItem('InviteCode');
        if (userInfo.verified) {
          history.push('/account-created');
        } else {
          history.push('/email-verification');
        }
      }
    }
    return () => {
      //
    };
  }, [userInfo]);

  return (
    <div>
      <div class='mobile-header'>
        <MobileScreenLogo navlink='Log in'   linkdata="/discover" />
      </div>
      <div class='content signup_content'>
        <div className="logo-desktop ml-0 ml-xl-3 ml-lg-3 ml-md-2">
        <Link to="/discover">
          <img src={logoWhiteDesktop} />
          </Link>
        </div>
      </div>
      <div className="right-signupcontent">
        <div className="login-link">
          <DeskTopHeader
            linkdata='/login'
            displayContent='Already have an account?'
            navlink='Log in'
          />
        </div>
        <div class='row'>
          <div class='col-12'>
            <form
              onSubmit={handleSubmit(onSubmit)}
              class='signup_form custom_Form_View'
            >
              <div className="backward-arrow" onClick={() => history.goBack()}>
                <img src='images/back.png' />
                <p>back</p>
              </div>
              <h2 class='signup-head'>Verify your number</h2>
              <p class='para text-left'>
                We’ve sent a verification code to your phone number ending in{' '}
                {localStorage
                  .getItem('StepFourNumber')
                  .slice(localStorage.getItem('StepFourNumber').length - 4)}
                  .
                </p>
              <a
                onClick={() => props.history.push('/signup-number')}
                class='change-numberlink'
              >
                Change number
              </a>
              <div className='form-group'>
                {errorUserRegister?.errors?.length && (
                  <div className='error-box'>
                    {errorUserRegister.errors.map((er, erIndex) => (
                      <p key={erIndex} className='incorrect'>
                        {er.msg}
                      </p>
                    ))}
                  </div>
                )}
                {successmsg != '' && (
                  <div className='error-box'>
                    {
                      <p className='incorrect' style={{ color: 'green' }}>
                        {successmsg}
                      </p>
                    }
                  </div>
                )}
                {errorUserRegister?.msg && (
                  <div className='error-box'>
                    {<p className='incorrect'>{errorUserRegister?.msg}</p>}
                  </div>
                )}
                {error?.msg && (
                  <div className='error-box'>
                    {<p className='incorrect'>{error?.msg}</p>}
                  </div>
                )}
                <label className="mb-2">Verification code</label>
                <div className='row verification-row'>
                  <div className={`col-3  ${errors.number1 && 'invalid-row'}`}>
                    <input
                      type='text'
                      id='valueOne'
                      name='number1'
                      value={valueOne}
                      onChange={(e) => valueOneChange(e, '#valueTwo')}
                      ref={register({
                        required: 'Enter your number',
                        valueAsNumber: true,
                      })}
                      maxLength='1'
                      className='form-control'
                      placeholder='8'
                    />
                  </div>
                  <div className={`col-3  ${errors.number2 && 'invalid-row'}`}>
                    <input
                      type='text'
                      id='valueTwo'
                      name='number2'
                      value={valueTwo}
                      onChange={(e) => valueTwoChange(e, '#valueThree')}
                      ref={register({
                        required: 'Enter your number',
                        valueAsNumber: true,
                      })}
                      maxLength='1'
                      className='form-control'
                      placeholder='8'
                    />
                  </div>
                  <div className={`col-3  ${errors.number3 && 'invalid-row'}`}>
                    <input
                      type='text'
                      id='valueThree'
                      name='number3'
                      value={valueThree}
                      onChange={(e) => valueThreeChange(e, '#valueFour')}
                      ref={register({
                        required: 'Enter your number',
                        valueAsNumber: true,
                      })}
                      maxLength='1'
                      className='form-control'
                      placeholder='8'
                    />
                  </div>
                  <div className={`col-3  ${errors.number4 && 'invalid-row'}`}>
                    <input
                      type='text'
                      id='valueFour'
                      name='number4'
                      value={valueFour}
                      onChange={valueFourChange}
                      ref={register({
                        required: 'Enter your number',
                        valueAsNumber: true,
                      })}
                      maxLength='1'
                      className='form-control'
                      placeholder='8'
                    />
                  </div>
                </div>
                <div>
                  {seconds === 0 ? (
                    <a
                      onClick={() => {
                        sendAgainOTP();
                      }}
                      className='other-link'
                    >
                      Send code again
                    </a>
                  ) : (
                    <div>
                      <label>
                        <strong>00:{seconds}</strong> before you can resend
                      </label>
                    </div>
                  )}
                </div>
              </div>
              <div className='keep-verified'>
                {/* <label htmlFor='keepVerified'>
                  Keep this device verified for 30 days
                  <input type='checkbox' id='keepVerified' />
                  <span className='checkmark'></span>
                </label> */}
              </div>
              <button
                type='submit'
                disabled={verify}
                className='btn btn-primary'
              >
                Verify
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepFiveNumberVerify;