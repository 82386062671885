import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { loadState, saveState } from "./localStorage";
import { throttle } from "lodash";

import {
  userSigninReducer,
  userRegisterReducer,
  userUpdateReducer,
  userSendOtpReducer,
  userVerifyOtpReducer,
  userForgotPasswordReducer,
  userResetPasswordReducer,
  createListReducer,
  userChangePasswordReducer,
  userCardBillingAddressReducer,
  UserDetailsReducer,
  updateUserDetailsReducer,
  getuserNeedsListReducer,
  userupdateCardBillingAddressReducer,
  userActionSelectedreducer,
  getuserWishListReducer,
  placeOrderReducer,
  getStripeTokenrReducer,
  getActionListOnNeedReducer,
  getUserActionListChatsReducer,
  getMyActionsRecordReducer,
  getReportDetailsReducer,
} from "./Reducers/userReducers";

// const userInfo = JSON.parse(localStorage?.getItem("userInfo")) || null;

// const initialState = {
//   userSignin: { userInfo },
// };

const reducer = combineReducers({
  userSignin: userSigninReducer,
  userRegister: userRegisterReducer,
  userUpdate: userUpdateReducer,
  userSendOtp: userSendOtpReducer,
  userVerifyOtp: userVerifyOtpReducer,
  userForgotPassword: userForgotPasswordReducer,
  userResetPassword: userResetPasswordReducer,
  createList: createListReducer,
  userChangePassword: userChangePasswordReducer,
  userCardBillingAddress: userCardBillingAddressReducer,
  UserDetails: UserDetailsReducer,
  updateUserDetails: updateUserDetailsReducer,
  getuserNeedsList: getuserNeedsListReducer,
  userupdateCardBillingAddress: userupdateCardBillingAddressReducer,
  userActionSelected: userActionSelectedreducer,
  getuserWishList: getuserWishListReducer,
  userPlaceOrder: placeOrderReducer,
  stripeTokenInfo: getStripeTokenrReducer,
  getActionListOnNeedMsgInfo: getActionListOnNeedReducer,
  getUserActionListChatsInfo: getUserActionListChatsReducer,
  getMyActionsData: getMyActionsRecordReducer,
  getReportInfo: getReportDetailsReducer,
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistedState = loadState();
const store = createStore(
  reducer,
  persistedState,
  composeEnhancer(applyMiddleware(thunk))
);

store.subscribe(
  throttle(() => {
    saveState({
      userSignin: store.getState().userSignin,
      getuserWishList: store.getState().getuserWishList,
    });
  }, 1000)
);

export default store;
