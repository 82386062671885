import React from 'react';
import Disclaimer from '../../Layout/GuestUser/Disclaimer';
import { Link } from 'react-router-dom';
import MobileScreenLogo from '../../Common/MobileScreenLogo';
import DeskTopHeader from '../../Common/DeskTopHeader';
import logoWhiteDesktop from "../../assets/images/logo_white_desktop.svg";

const ForgotCompleted = () => {
  return (
    <div>
      <div class='mobile-header'>
        <MobileScreenLogo navlink='Sign up' linkdata='#' />
      </div>
      <div class='content signup_content forgot_instructions'>
        <div className="logo-desktop ml-0 ml-xl-3 ml-lg-3 ml-md-2">
          <Link to="/discover">
            <img src={logoWhiteDesktop} />
          </Link>
        </div>
      </div>
      <div className="right-signupcontent">
        <div className="login-link">
          <DeskTopHeader
            linkdata='#'
            displayContent='Don’t have an account?'
            navlink='Sign up'
          />
        </div>
       
        <div class='row'>
          <div class='col-12'>
            <form class='signup_form text-left instruction-form'>
            <h2 class='heading2 signup-head pt-2 pt-xl-5 pt-lg-5 pt-md-5'>Instructions sent</h2>
              <p class='para'>
                Instructions for reseting your password have been sent to{' '}
                <strong>{localStorage.getItem('forgotPasswordEmail')}</strong>
              </p>
              <p class='para pt-3'>
                You’ll receive this email within 5 minutes. Be sure to check your spam
                folder.
              </p>
              <div class='forgot-link pt-3'>
                <Link className='login' to='/login'>
                  Back to Log in
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotCompleted;
