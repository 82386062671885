export const USER_SIGNIN_REQUEST = "USER_SIGNIN_REQUEST";
export const USER_SIGNIN_SUCCESS = "USER_SIGNIN_SUCCESS";
export const USER_SIGNIN_FAIL = "USER_SIGNIN_FAIL";

export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAIL = "USER_UPDATE_FAIL";

export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";

export const USER_SEND_OTP_REQUEST = "USER_SEND_OTP_REQUEST";
export const USER_SEND_OTP_SUCCESS = "USER_SEND_OTP_SUCCESS";
export const USER_SEND_OTP_FAIL = "USER_SEND_OTP_FAIL";

export const USER_VERIFY_OTP_REQUEST = "USER_VERIFY_OTP_REQUEST";
export const USER_VERIFY_OTP_SUCCESS = "USER_VERIFY_OTP_SUCCESS";
export const USER_VERIFY_OTP_FAIL = "USER_VERIFY_OTP_FAIL";

export const USER_FORGOT_PASSWORD_REQUEST = "USER_FORGOT_PASSWORD_REQUEST";
export const USER_FORGOT_PASSWORD_SUCCESS = "USER_FORGOT_PASSWORD_SUCCESS";
export const USER_FORGOT_PASSWORD_FAIL = "USER_FORGOT_PASSWORD_FAIL";

export const USER_RESET_PASSWORD_REQUEST = "USER_RESET_PASSWORD_REQUEST";
export const USER_RESET_PASSWORD_SUCCESS = "USER_RESET_PASSWORD_SUCCESS";
export const USER_RESET_PASSWORD_FAIL = "USER_RESET_PASSWORD_FAIL";

export const USER_LOGOUT = "USER_LOGOUT";

export const ADD_GENERAL_INFO = "ADD_GENERAL_INFO";
export const ADD_PURPOSE = "ADD_PURPOSE";
export const ADD_DESCRIPTION = "ADD_DESCRIPTION";
export const ADD_ADDITIONAL_INFO = "ADD_ADDITIONAL_INFO";
export const CREATE_LIST_REQUEST = "CREATE_LIST_REQUEST";
export const CREATE_LIST_SUCCESS = "CREATE_LIST_SUCCESS";
export const CREATE_LIST_FAIL = "CREATE_LIST_FAIL";
export const DELETE_LIST = "DELETE_LIST";
export const DESCRIPTION_FULL_PREVIEW_SAVE = "DESCRIPTION_FULL_PREVIEW_SAVE";

export const USER_CHANGE_PASSWORD_REQUEST = "USER_CHANGE_PASSWORD_REQUEST";
export const USER_CHANGE_PASSWORD_SUCCESS = "USER_CHANGE_PASSWORD_SUCCESS";
export const USER_CHANGE_PASSWORD_FAIL = "USER_CHANGE_PASSWORD_FAIL";

export const USER_NEW_CARD_BILLING_REQUEST = "USER_NEW_CARD_BILLING_REQUEST";
export const USER_NEW_CARD_BILLING_SUCCESS = "USER_NEW_CARD_BILLING_SUCCESS";
export const USER_NEW_CARD_BILLING_FAIL = "USER_NEW_CARD_BILLING_FAIL";

export const CREATE_NEED = "CREATE_NEED";

export const GET_USER_DETAIL_REQUEST = "GET_USER_DETAIL_REQUEST";
export const GET_USER_DETAIL_SUCCESS = "GET_USER_DETAIL_SUCCESS";
export const GET_USER_DETAIL_FAIL = "GET_USER_DETAIL_FAIL";

export const UPDATE_USER_DETAIL_REQUEST = "UPDATE_USER_DETAIL_REQUEST";
export const UPDATE_USER_DETAIL_SUCCESS = "UPDATE_USER_DETAIL_SUCCESS";
export const UPDATE_USER_DETAIL_FAIL = "UPDATE_USER_DETAIL_FAIL";

export const USER_NEEDS_DETAIL_LIST_REQUEST = "USER_NEEDS_DETAIL_LIST_REQUEST";
export const USER_NEEDS_DETAIL_LIST_SUCCESS = "USER_NEEDS_DETAIL_LIST_SUCCESS";
export const USER_NEEDS_DETAIL_LIST_FAIL = "USER_NEEDS_DETAIL_LIST_FAIL";

export const USER_UDATE_CARD_BILLING_REQUEST =
  "USER_UDATE_CARD_BILLING_REQUEST";
export const USER_UDATE_CARD_BILLING_SUCCESS =
  "USER_UDATE_CARD_BILLING_SUCCESS";
export const USER_UDATE_CARD_BILLING_FAIL = "USER_UDATE_CARD_BILLING_FAIL";

export const USER_ACTION_LIST_LIST_SUCCESS = "USER_ACTION_LIST_LIST_SUCCESS";

export const CREATE_WISHLIST_SUCCESS = "CREATE_WISHLIST_SUCCESS";

export const PLACE_ORDER_SUCCESS = "PLACE_ORDER_SUCCESS";
export const PLACE_ORDER_FAIL = "PLACE_ORDER_FAIL";

export const STRIPE_TOKEN_SUCCESS = "STRIPE_TOKEN_SUCCESS";
export const STRIPE_TOKEN_FAIL = "STRIPE_TOKEN_FAIL";

export const ACTION_LIST_ON_NEED_FOR_MSG_SUCCESS =
  "ACTION_LIST_ON_NEED_FOR_MSG_SUCCESS";
export const ACTION_LIST_ON_NEED_FOR_MSG_FAIL =
  "ACTION_LIST_ON_NEED_FOR_MSG_FAIL";

export const GET_USER_ACTION_LIST_CHATS_SUCCESS =
  "GET_USER_ACTION_LIST_CHATS_SUCCESS";
export const GET_USER_ACTION_LIST_CHATS_FAIL =
  "GET_USER_ACTION_LIST_CHATS_FAIL";

export const GET_MY_ACTIONS_RECORDS_SUCCESS = "GET_MY_ACTIONS_RECORDS_SUCCESS";
export const GET_MY_ACTIONS_RECORDS_FAIL = "GET_MY_ACTIONS_RECORDS_FAIL";

export const GET_REPORT_DATA_SUCCESS = "GET_REPORT_DATA_SUCCESS";
export const GET_REPORT_DATA_FAIL = "GET_REPORT_DATA_FAIL";
