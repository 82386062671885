import React, { useState, useEffect } from "react";
import Back from "../../../assets/images/back.png";
import Close from "../../../assets/images/close2.png";
import { useForm } from "react-hook-form";
import ListCreationMobileHeader from "../../../Common/MobileHeaderOne";
import UserDeskTopHeaderOne from "../../../Common/UserDeskTopHeaderOne";
import Profileheadermobile from "../../../Common/Profileheadermobile";

import { useSelector, useDispatch } from "react-redux";
import {
  addDescription,
  deleteList,
  createList,
} from "../../../Store/Actions/userActions";
import { isMobile } from "react-device-detect";
export default function ListCreationDescription(props) {
  let { register, handleSubmit, errors } = useForm();
  let [character, setCharacter] = useState(0);
  let [description, setDescription] = useState("");
  let dispatch = useDispatch();
  const create_List = useSelector((state) => state.createList);
  const { userCreateList } = create_List;
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  useEffect(() => {
    if (userCreateList && userCreateList.description) {
      setDescription(userCreateList.description);
      setCharacter(userCreateList.description.length);
    }
  }, [userCreateList]);

  let submit = (data) => {
    let list = { ...userCreateList, description: description };
    dispatch(addDescription(list));
    props.history.push("/me/create/step4");
  };

  function fullPreviewDescription(e) {
    e.preventDefault();
    let list = { ...userCreateList, description: description };
    dispatch(addDescription(list));
    props.history.push("/me/create/step3-full-preview");
  }

  const closeButtonFunc = () => {
    let list = {
      ...userCreateList,
      userId: userInfo?.userId,
      userName: userInfo?.firstName + " " + userInfo?.lastName,
      actionListStatus: "draft",
      userType: userInfo?.userType,
    };
    dispatch(createList(list));
    dispatch(deleteList());
    props.history.push("/me/lists");
  };

  function characterCount(e) {
    setDescription(e.target.value);
    setCharacter(e.target.value.length);
  }

  return (
    <div>
           {isMobile ? "" :  <Profileheadermobile history={props.history} />}
      <ListCreationMobileHeader
        pageTitle="Create new ActionList"
        previousPage="/me/create/step2"
        close="/account-created"
        history={props.history}
      />

      <div className="content">
        <div
          className="content-top-header description-page-header"
          style={{ marginBottom: 100 }}
        >
          <a
            onClick={() => props.history.push("/me/create/step2")}
            className="back_link cursor-pointer"
          >
            <img src={Back} alt="back" />
            Back
          </a>
          <a
            onClick={() => {
              {
                closeButtonFunc();
              }
            }}
            className="ml-auto"
          >
            <img src={Close} alt="close" />
          </a>
        </div>
        {/* <label className='page-subhead'>Create new ActionList</label> */}
        <h2 className="heading2">Step 3 of 5: Description</h2>
        <div className="row">
          <div className="col-12">
            <form className="description-form" onSubmit={handleSubmit(submit)}>
              <div className="form-group">
                <label htmlFor="title">
                  Tell your story and describe needs
                </label>
                <div className="story_description_wrapper">
                  <textarea
                    rows="10"
                    className="form-control"
                    maxLength="400"
                    minLength="10"
                    onChange={characterCount}
                    ref={register({
                      required: "Description cannot be empty",
                      maxLength: {
                        value: 400,
                        message:
                          "Description cannot be more than 400 characters",
                      },
                      minLength: {
                        value: 24,
                        message:
                          "Description cannot be less than 24 characters",
                      },
                    })}
                    value={description}
                    name="description"
                    id="title"
                    placeholder="e.g. Hello everyone! I can’t pay for the deliver, and I decided to ask someone who has a car to help me. I need to move 4 chairs etc..."
                  ></textarea>
                  <button
                    type="button"
                    className="expand_button"
                    onClick={(e) => fullPreviewDescription(e)}
                  ></button>
                </div>
                {console.log(errors)}
                {Object.keys(errors).length !== 0 && (
                  <div className="error-box">
                    <ul>
                      {errors.description && (
                        <li key="1" className="incorrect">
                          {errors.description.message}
                        </li>
                      )}
                    </ul>
                  </div>
                )}
                <div className="info d-flex">
                  <span className="mr-auto">
                    You can edit the description later.
                  </span>
                  <span className="limit">{character}/400</span>
                </div>
              </div>
              <div className="fixed-bottom bottom-btn">
                <button type="submit" className="btn btn-primary">
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
