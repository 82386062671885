import React from "react";
import goBackImage from "../../assets/images/back_black.png";
import profilePic from "../../assets/images/account_profile_pic.png";
import Profileheadermobile from "../../Common/Profileheadermobile";
import ProfileLeftPane from "../../Common/ProfileLeftPane";
import BottomTab from "../../Common/BottomTab";
import UnloginuserHead from "../../Common/UnloginuserHead";
import Cookies from "js-cookie";

const Profile = ({ ...props }) => {
  return (
    <div>
      {Cookies.get("token") ? (
        <Profileheadermobile history={props.history} />
      ) : (
        <UnloginuserHead history={props.history} />
      )}
      <div className="content profile">
        <ProfileLeftPane activePage="" />
        <div className="tab-content hide" id="settingsTabContent">
          <div
            className="tab-pane fade show active"
            id="account-settings"
            role="tabpanel"
            aria-labelledby="account-settings-tab"
          >
            <div className="mobile-header">
              <nav className="navbar">
                <div onClick={() => props.history.goBack()} className="back">
                  <img src={goBackImage} alt="go-back" />
                </div>
                <label className="navbar-brand mx-auto middle-label">
                  Account settings
                </label>
              </nav>
            </div>
            <div className="inner-content">
              <label className="holder-name desktop">Melinda Johnson</label>
              <div className="holder-pic-row">
                <div className="profile-pic">
                  <img src={profilePic} alt="Profile-pic" />
                </div>
                <label className="holder-name mobile">Melinda Johnson</label>
                <button type="button" className="btn btn-extra">
                  Add Photo
                </button>
                <a href="#" className="link-extra">
                  Delete Photo
                </a>
              </div>
              <form className="personal-info">
                <h2>Personal information</h2>
                <div className="form-group">
                  <label for="fullName">Full Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="fullName"
                    placeholder="Melinda Johnson"
                    value="Melinda Johnson"
                  />
                </div>
                <div className="form-group row">
                  <div className="col-xl-6 col-lg-6">
                    <label for="phoneNumber" className="label">
                      Phone Number
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="phoneNumber"
                      placeholder="+1 (045) 456-361-46"
                      value="+1 (045) 456-361-46"
                    />
                  </div>
                  <div className="col-xl-6 col-lg-6">
                    <label for="email" className="label">
                      Email
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="email"
                      placeholder="melinda.johnson@gmail.com"
                      value="melinda.johnson@gmail.com"
                    />
                  </div>
                </div>
                <button type="button" className="btn btn-prime">
                  Save Changes
                </button>
              </form>
              <div className="bottom-panel">
                <div className="inner">
                  <button type="button" className="link-extra">
                    Delete Account
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="payment-methods"
            role="tabpanel"
            aria-labelledby="payment-methods-tab"
          >
            Payment methods
          </div>
          <div
            className="tab-pane fade"
            id="email-notifications"
            role="tabpanel"
            aria-labelledby="email-notifications-tab"
          >
            Email notifications
          </div>
          <div
            className="tab-pane fade"
            id="password-reset"
            role="tabpanel"
            aria-labelledby="password-reset-tab"
          >
            Change Password
          </div>
        </div>
        <BottomTab activeScreen={"profile"} />
      </div>
    </div>
  );
};

export default Profile;
