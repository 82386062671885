import React, { useState } from 'react';
import ListPreviewGray from '../../assets/images/list_preview_gray.svg';
import ListPreview from '../../assets/images/list_preview.svg';
import Leaf from '../../assets/images/leaf.svg';
import moment from 'moment';
import People from '../../assets/images/people.png';
import AxiosApi from "../../Config/axios-api";
import { ToastContainer, toast } from "react-toastify";
import '../../../node_modules/react-toastify/dist/ReactToastify.min.css'
import Swal from 'sweetalert2';
import {stringCapitalize} from '../../services/stringModify';

function actionListItem(props) {
  const ImagebaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;
  let isDelete = false
  let theme;
  let width;
  let savedAction;
  switch (props.theme) {
    case 'blossom':
      theme = 'list-box theme-blossom';
      break;
    case 'greenery':
      theme = 'list-box theme-greenery';
      break;
    case 'waterpolo':
      theme = 'list-box theme-waterpolo';
      break;
    case 'dawn':
      theme = 'list-box theme-dawn';
      break;
    case 'fair':
      theme = 'list-box theme-fair';
      break;
    case 'sunshine':
      theme = 'list-box theme-sunshine';
      break;
    default:
      theme = 'list-box theme-undefined-draft';
  }

  if (props.needsCount > 0) {
    width = (props.needsDone / props.needsCount) * 100;
  }

  var findIndex = props.userInfoData?.savedActionList?.findIndex(
    (val) => val == props.id
  );

  const addremoveList = (e) => {
    e.stopPropagation();
    props.saveActionList(props.list);
  };

  const handleDelete = async (e, id) => {
    e.preventDefault();
    isDelete = true
    Swal.fire({
      title: 'Are you sure to want delete ActionList!',
      showCancelButton: true,
      confirmButtonColor: "#e8271a",
      confirmButtonText: `Delete`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { data } = await AxiosApi.delete('actionList/removeActionListById/' + id)
        if (data?.msg) {
          Swal.fire('Deleted!', data?.msg, 'success')
          props.getActionListApiCall()
        } else {
          toast.error("Something went worng!")
        }
      }
    })
  }

  const handleNeedDetailPage = (e, list) => {
    e.preventDefault();
    if (!isDelete) {
      props.click(e, list)
    }
  }

  return (
    <div className={theme} onClick={(e) => handleNeedDetailPage(e, props.list)}>
      <div className='top'>
        {props?.status === 'draft' ? (
          <button type='button' className='btn-delete' onClick={(e) => handleDelete(e, props?.list?.actionListId)}>
            Delete
          </button>
        ) : (
          ""
        )}

        <label className='need-name'>{stringCapitalize(props?.name)}</label>
        <div className='row2'>
          <div className='left'></div>
          <div className='right'>
            <label className='last-updated mobile-view'>
            Last updated: {moment(props.list.updatedAt).format('lll')}
            </label>
            <img src={Leaf} alt='' />
            <label className='environment'>{stringCapitalize(props.list.purpose)}</label>
            <label className='last-updated desktop-view'>
            Last updated: {moment(props.list.updatedAt).format('lll')}
            </label>
          </div>
        </div>
      </div>
      {props.needsCount > 0 && (
        <div className='bottom'>
          <div className='left'>
            <div className='column-people'>
              <div className='profile-pic88 profile-pic881'>
                {props.list?.userImages &&
                  <>
                    {props.list?.userImages[0] ?
                      <img src={ImagebaseUrl + props.list?.userImages[0]} alt='' />
                      :
                      <img 
                      src={ImagebaseUrl + '1628062524365avatar.png'
                      } alt='' />
                    }
                  </>
                }
                {props.list?.userImages?.length > 1 &&
                  <>
                    {props.list?.userImages[1] ?
                      <img 
                      src={ImagebaseUrl + props.list?.userImages[1]} alt=''className="middle-imgpostion" />
                      :
                      <img src={
                        ImagebaseUrl + '1628062524365avatar.png'
                      } alt='' className="second-userimg2"/>
                    }
                  </>
                }
                {props.list?.userImages?.length > 2 &&
                  <>
                    {props.list?.userImages[2] ?
                      <img src={ImagebaseUrl + props.list?.userImages[2]} alt=''className="second-userimg" />
                      :
                      <img src={
                        ImagebaseUrl + '1628062524365avatar.png'
                      } alt='' />
                    }
                  </>
                }
              </div>
              <label>{props.actionTaken} People are taking action</label>
            </div>
          </div>
          <div className='right'>
            <div className='column-progress'>
              <label className='progress-report desktop-view'>
                <strong>{props.needsDone} Actioned</strong> of{' '}
                {props.needsCount} Needs
              </label>
              <div className='progress'>
                <div
                  className='progress-bar'
                  role='progressbar'
                  style={{ width: width }}
                  aria-valuenow='100'
                  aria-valuemin='0'
                  aria-valuemax='100'
                ></div>
              </div>
              <label className='progress-report mobile-view'>
                <strong>{props.needsDone} Actioned</strong> of{' '}
                {props.needsCount} Needs
              </label>
            </div>
          </div>
        </div>
      )}
      {props.needsCount === 0 && props?.status === 'active' && (
        <div className='bottom'>
          <div className='left'></div>
          <div className='right'>
            {/* <div className='column-no-needs'>No needs yet</div> */}
          </div>
        </div>
      )}
      {props.needsCount === 0 && props?.status === 'draft' && (
        <div className='bottom'>
          <div className='right'>
            <div className='column-draft'>Draft</div>
          </div>
        </div>
      )}
      {/*props?.status==='done' &&<div class="bottom">
                    <div class="left"></div>
                    <div class="right">
                        <div class="column-no-needs">Completed</div>
                    </div>
            </div>*/}
      <ToastContainer autoClose={3000} />
    </div>
  );
}

export default actionListItem;
