import React, { useState, useEffect } from "react";
/*@Components*/
import LeftPlane from "../../../Common/LeftPlane";
import MobileHeaderTwo from "../../../Common/MobileHeaderTwo";
import DesktopHeaderTwo from "../../../Common/UserDesktopHeaderTwo";
import "../style.css";
import "../responsive.css";

import NeedsCreationOpenCard from "./NeedsCreationOpenCard";
import NeedsCreationBuyTypeSelectedMandatoryInfo from "./NeedsCreationBuyTypeSelectedMandatoryInfo";
import NeedsCreationDoTypeSelectedmandatoryInfo from "./NeedsCreationDoTypeSelectedmandatoryInfo";

import NeedCreationNeedInfoEditingFreeAccountRestrictions from "./NeedCreationNeedInfoEditingFreeAccountRestrictions";
import NeedsCreationGiveTypeDetailsAdding from "./NeedsCreationGiveTypeDetailsAdding";
import NeedsCreationBuyTypeDetailsAdding from "./NeedsCreationBuyTypeDetailsAdding";
import NeedsCreationDoTypeDetailsAdding from "./NeedsCreationDoTypeDetailsAdding";
import NeedsCreationNeedInfoPreview from "./NeedsCreationNeedInfoPreview";
import NeedsCreationFilters from "./NeedCreationListFilters";

import NeedsCreationShortenListView from "./NeedsCreationShortenListView";
import NeedCreationExpandedListView from "./NeedCreationExpandedListView";
import NeedsCreationDraftEditing from "./NeedsCreationDraftEditing";
import { isMobile } from "react-device-detect";

/*@Images*/
import PlusWhite from "../../../assets/images/plus_white.png";
import BuyBlue from "../../../assets/images/buy_blue.svg";
import GiveBlue from "../../../assets/images/give_blue.svg";
import DoBlue from "../../../assets/images/do_blue.svg";
import ViewEnabled from "../../../assets/images/view_enabled.svg";
import Share from "../../../assets/images/share.svg";
import Filter from "../../../assets/images/filter.png";
import MinusWhite from "../../../assets/images/minus_white.png";
import { USER_NEEDS_DETAIL_LIST_SUCCESS } from "../../../Store/Constants/userConstants";
import { useDispatch, useSelector } from "react-redux";
import AxiosApi from "../../../Config/axios-api";
import Cookies from "js-cookie";
import { getNeedsList } from "../../../Store/Actions/userActions";
import { RenderReport } from "../ReportView";
import buyImg from "../../../assets/images/buy.svg";
import doImg from "../../../assets/images/do.svg";
import giveImg from "../../../assets/images/give.svg";
import ShareModal from "../../../Common/ShareModal";
import { ToastContainer, toast } from "react-toastify";
import { stringCapitalize } from "../../../services/stringModify";
import NeedsCreationgiveTypeSelectedMandatoryInfo from "./NeedsCreationGiveTypeSelectedMandatoryInfo";
export default function NeedsCreationEmptyList(props) {
  console.log("-->>", { props });
  const baseUrl = window.location.href.slice(
    0,
    window.location.href.indexOf("#")
  );
  const reportDetails = useSelector((state) => state.getReportInfo?.reportData);
  const dispatch = useDispatch();
  let [need_Type, setNeed_Type] = useState("");
  let [openCard, setOpenCard] = useState(false);
  let [plus, setPlus] = useState(false);
  let [GiveTypeDetailsAdding, setGiveTypeDetailsAdding] = useState(false);
  let [allNeedsExpands, setAllNeedsExpanded] = useState(false);
  let [draftEditing, setDraftEditing] = useState(false);
  let [editDraftModal, setEditDraftModal] = useState(false);
  let [buyModal, setBuyModal] = useState(false);
  let [giveModal, setGiveModal] = useState(false);
  let [buyDetails, setBuyDetails] = useState(false);
  let [openCardDo, setOpenCardDo] = useState(false);
  let [openCardGive, setOpenCardGive] = useState(false);
  let [addDetailsDoModal, setAddDetailsDoModal] = useState(false);
  let [addDetailsGiveModal, setAddDetailsGiveModal] = useState(false);
  let [infoPreview, setInfoPreview] = useState(false);
  let [needEditFree, setNeedEditFree] = useState(false);
  let [infoEditProAcc, setInfoEditProAcc] = useState(false); // if acc is pro
  let [needsFilters, setFilters] = useState(false);
  let [callbuy, setCallbuy] = useState(false);
  let [callgive, setCallgive] = useState(false);
  let [calldo, setCalldo] = useState(false);
  let [callall, setCallAll] = useState(true);
  let [params, setParams] = useState(props.match.params.id);
  let [buydata, setBuydata] = useState();
  let [givedata, setgivedata] = useState();
  let [dodata, setdodata] = useState();
  let [needNameForAdd, setNeedNameForAdd] = useState("");
  const [theme, setTheme] = useState();
  const [selectedaction, setselectedaction] = useState();
  const [isPostNeedButCliked, setIsPostNeedButCliked] = useState(false);
  const [liveTitleAdd, setLiveTitleAdd] = useState("");
  const [needTypeForEditPopup, setNeedTypeForEditPopup] = useState(
    props?.selectedNeedItem?.needType
  );
  const [isBackInPopup, setIsBackInPopup] = useState(false);

  console.log("object data today", needTypeForEditPopup);
  let isMobileView = false;
  // const userActionSelected = useSelector((state) => state.userActionSelected);
  // const { selectedaction } = userActionSelected;
  function initializeNeedsModule() {
    //get api call to initialize infoEditProAcc
  }

  useEffect(() => {
    const themeType = localStorage.getItem("selectedtheme");
    if (themeType) {
      setTheme(themeType);
    }
  }, []);

  useEffect(initializeNeedsModule, []);
  useEffect(() => {
    setselectedaction(JSON.parse(localStorage.getItem("selectedList")));
    var getId = localStorage.getItem("NewList");
    if (getId === props.match.params.id) {
      window.$("#listCreationSuccessModal").modal("show");
    } else {
      localStorage.removeItem("NewList");
    }
  }, []);
  let changeSign = () => {
    console.log("7654321", plus);
    setPlus(!plus);
  };

  function filters() {
    setFilters(!needsFilters);
  }

  function openAddDetailsDoModal(data) {
    setdodata(data);
    setOpenCardDo(false);
    setAddDetailsDoModal(true);
    setNeedTypeForEditPopup("");
    addCloseGiveTypeDetailsHandler();
    buyTypeDetailsCloseHandler();
  }
  function openAddDetailsGiveModal(data) {
    console.log("object data today calll", data);
    setgivedata(data);
    setOpenCardGive(false);
    setGiveModal(false);
    setAddDetailsGiveModal(true);
    setGiveTypeDetailsAdding(true);
    setNeedTypeForEditPopup("");
    // addCloseGiveTypeDetailsHandler();

    setBuyModal(false);
    setOpenCard(false);
    setOpenCardDo(false);
    buyTypeDetailsCloseHandler();
  }

  function closeAddDetailsDoModal(e) {
    setIsBackInPopup(true);
    // e.preventDefault();
    setAddDetailsDoModal(false);
    closeDoModal();
    setBuyModal(false);
    setOpenCard(false);
    setOpenCardDo(false);
  }

  let buyTypeDetailsHandler = (data) => {
    console.log("object data today empty", data);
    setBuydata(data);
    setBuyDetails(true);
    setBuyModal(false);
    setNeedTypeForEditPopup("");
    closeAddDetailsDoModal(); //do details close
    addCloseGiveTypeDetailsHandler(); //give details close
  };

  let buyTypeDetailsCloseHandler = () => {
    setIsBackInPopup(true);
    setBuyDetails(false);
    setBuyModal(false);
    setOpenCard(false);
    setOpenCardDo(false);
  };

  let openGiveModal = () => {
    setNeedNameForAdd("give");
    setOpenCard(true);
    setOpenCardGive(false);
    setNeedTypeForEditPopup("give");
    closeEditDraft();
    // window.$('#needCreationCardModal').modal('show')
    closeBuyModal();
    closeDoModal();
    setGiveModal(true);
  };

  let closeGiveModal = () => {
    setOpenCard(false);
    setGiveModal(false);
    setPlus(true);
    setNeedTypeForEditPopup("")
  };
  function openBuyModal() {
    setNeedNameForAdd("buy");
    setNeedTypeForEditPopup("buy");
    closeEditDraft();
    closeDoModal();
    closeGiveModal();
    setBuyModal(true);
  }

  function closeBuyModal() {
    setBuyModal(false);
    setNeedTypeForEditPopup("")
  }

  let addCloseGiveTypeDetailsHandler = () => {
    setIsBackInPopup(true);
    setGiveTypeDetailsAdding(false);
    closeGiveModal();
    setBuyModal(false);
    setOpenCard(false);
    setOpenCardDo(false);
  };

  function bothModalPopClose() {
    if (props?.func) {
      props?.func("closeInfoPreview");
      props?.func("closeNeedEditInfo");
    }
  }

  function addDetailsFromOpenCard(data) {
    setgivedata(data);

    closeGiveModal();
    buyTypeDetailsCloseHandler(); //buy details close
    closeAddDetailsDoModal(); //do details close
    setGiveTypeDetailsAdding(true);
  }

  function expandToggle() {
    setNeedNameForAdd("");
    setAllNeedsExpanded(!allNeedsExpands);
  }

  function editDraft() {
    setDraftEditing(true);
    setEditDraftModal(true);
  }

  function closeEditDraft() {
    setDraftEditing(false);
  }

  function openDoModal() {
    setNeedNameForAdd("do");
    setNeedTypeForEditPopup("do");

    closeEditDraft();
    closeBuyModal();
    closeGiveModal();
    setOpenCardDo(true);
  }

  function closeDoModal() {
    setOpenCardDo(false); 
    setNeedTypeForEditPopup("")
  }

  function detailsFunction(d, data) {
    switch (d) {
      case "buyOpen":
        buyTypeDetailsHandler(data);
        break;
      case "giveOpen":
        addDetailsFromOpenCard(data);
        break;
      case "doOpen":
        openAddDetailsDoModal(data);
        break;
      default:
        return 0;
    }
  }

  function openShareModal() {
    window.$("#shareListModal").modal("show");
  }
  function setParamsData(data) {
    dispatch({ type: USER_NEEDS_DETAIL_LIST_SUCCESS, payload: [] });
    if (data === "buy") {
      setCallbuy(true);
      setCallgive(false);
      setCalldo(false);
      setCallAll(false);
    } else if (data === "give") {
      setCallbuy(false);
      setCallgive(true);
      setCalldo(false);
      setCallAll(false);
    } else if (data === "do") {
      setCallbuy(false);
      setCallgive(false);
      setCalldo(true);
      setCallAll(false);
    } else {
      setCallbuy(false);
      setCallgive(false);
      setCalldo(false);
      setCallAll(true);
    }
    let id = props.match.params.id;
    if (data != "all") {
      id = id + "?needType=" + data;
    }
    setParams(id);
  }
  async function createNeed(item, isPostNeedClcikedVal) {
    const isPostClicked = isPostNeedClcikedVal;
    let {
      title,
      needType,
      completedBy,
      cost,
      description,
      location,
      deliveryType,
      picupDistance,
      qtyNeeded,
      priority,
      instructions,
      thankYouMsg,
      autoAppoved,
      needStatus,
    } = item;
    let need = {
      title,
      needType,
      completedBy,
      cost,
      description,
      location,
      deliveryType,
      picupDistance,
      qtyNeeded,
      priority,
      instructions,
      thankYouMsg,
      autoAppoved,
      needStatus,
    };

    if (isMobileView) {
      need = { ...need, actionListId: props?.match?.params?.id };
    } else {
      need = isPostClicked
        ? {
            ...need,
            actionListId: props?.selectedNeedItem?.actionListId,
            needId: props?.selectedNeedItem?.needId,
          }
        : { ...need, actionListId: props?.match?.params?.id };
    }
    try {
      let endPoint = "";
      if (isMobileView) {
        endPoint = "createNeeds";
      } else {
        endPoint = isPostClicked ? "updateNeedsData" : "createNeeds";
      }

      const { data } = await AxiosApi.post(`/actionList/${endPoint}`, need, {
        headers: {
          token: Cookies.get("token"),
        },
      });
      if (data.msg == "needs added") {
        toast.success(stringCapitalize(data.msg));
      }
      rerenderList();
      if (isPostClicked) {
        props?.func("closeInfoPreview");
        props?.func("closeNeedEditInfo");
        setIsPostNeedButCliked(isPostNeedClcikedVal);
      }
    } catch (err) {
      console.log(err);
    }
  }

  const rerenderList = () => {
    dispatch(getNeedsList(params));
  };
  return (
    <div>
      {(!props?.draftWindowOpen || isPostNeedButCliked) && (
        <>
          <DesktopHeaderTwo history={props.history} />
          <MobileHeaderTwo
            pageTitle="Needs"
            previousPage="/me/lists"
            filter={true}
            close="/account-created"
            history={props.history}
            filterClose={filters}
            selectedTheme={theme || selectedaction?.themeName}
          />
          <div className="list-creation-innerpage">
            <LeftPlane
              history={props.history}
              route="/needs"
              activrID={props.match.params.id}
              selectedTheme={theme || selectedaction?.themeName}
            />
            <div
              className="rightpane"
              style={{ minHeight: "50vh", maxHeight: "88vh", overflow: "auto" }}
            >
              <div className="rightpane-header rightHeader">
                <h2 className="mr-auto">
                  {localStorage.getItem("selectedListName")}
                </h2>
                <div className="button-panel">
                  {/* <button
                className='btn view-btn'
                onClick={() => window.open(baseUrl+"#/l/"+ props.match.params.id, "_blank")

                  // props.history.push(
                  //   '/l/' + props.match.params.id
                  // )
                }
              >
                <img src={ViewEnabled} alt='' />
                View
              </button>
              <button className='btn share-btn for-desktop'
                onClick={openShareModal}>
                <img src={Share} alt='' />
                Share
              </button> */}

                  {isMobile ? (
                    <div
                      className={`dropdown addneed-dropdown ${
                        plus ? "show" : ""
                      }`}
                    >
                      <button
                        style={{ marginLeft: 10 }}
                        className="btn addneed-btn dropdown-toggle"
                        type="button"
                        onClick={(e) => setPlus(!plus)}
                        // data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded={plus ? "true" : "false"}
                      >
                        <div className="mob_view">
                          <img src={plus ? MinusWhite : PlusWhite} alt="" />
                          {/* <img src={MinusWhite} alt="" /> */}
                        </div>
                        <span>Add Need</span>
                      </button>
                      {/* {plus && ( */}
                      <div
                        // className="dropdown-menu show"
                        className={`dropdown-menu  ${plus ? "show" : ""}`}
                        style={{
                          position: "absolute",
                          transform: "translate3d(-79px, -220px, 0px)",
                          top: "0px",
                          left: "0px",
                          willChange: "transform",
                        }}
                        x-placement="top-start"
                        aria-labelledby=""
                      >
                        <div
                          className="dropdown-item"
                          onClick={(e) => openBuyModal()}
                        >
                          <img src={BuyBlue} alt="" />
                          <span>Buy</span>
                        </div>
                        <div
                          className="dropdown-item"
                          onClick={(e) => openGiveModal()}
                        >
                          <img src={GiveBlue} alt="" />
                          <span>Give</span>
                        </div>
                        <div
                          className="dropdown-item"
                          onClick={(e) => openDoModal()}
                        >
                          <img src={DoBlue} alt="" />
                          <span>Do</span>
                        </div>
                      </div>
                      {/* )} */}
                    </div>
                  ) : (
                    <div className="dropdown addneed-dropdown ">
                      <button
                        style={{ marginLeft: 10 }}
                        className="btn addneed-btn dropdown-toggle"
                        type="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded={plus ? "true" : "false"}
                      >
                        <span>Add Need</span>
                      </button>
                      {/* {plus && ( */}
                      <div
                        // className="dropdown-menu show"
                        className={`dropdown-menu  `}
                        aria-labelledby=""
                      >
                        <div
                          className="dropdown-item"
                          onClick={(e) => openBuyModal()}
                        >
                          <img src={BuyBlue} alt="" />
                          <span>Buy</span>
                        </div>
                        <div
                          className="dropdown-item"
                          onClick={(e) => openGiveModal()}
                        >
                          <img src={GiveBlue} alt="" />
                          <span>Give</span>
                        </div>
                        <div
                          className="dropdown-item"
                          onClick={(e) => openDoModal()}
                        >
                          <img src={DoBlue} alt="" />
                          <span>Do</span>
                        </div>
                      </div>
                      {/* )} */}
                    </div>
                  )}
                  {
                    <button
                      type="button"
                      className={
                        allNeedsExpands ? "btn grid-btn active" : "btn grid-btn"
                      }
                      onClick={expandToggle}
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="4.5" cy="6.5" r="4.5" />
                        <circle cx="4.5" cy="17.5" r="4.5" />
                        <rect x="12" y="2" width="12" height="9" rx="4" />
                        <rect x="12" y="13" width="12" height="9" rx="4" />
                      </svg>
                    </button>
                  }
                  {
                    <button
                      type="button"
                      className={
                        !allNeedsExpands
                          ? "btn grid-btn active"
                          : "btn grid-btn"
                      }
                      onClick={expandToggle}
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="2.5" cy="12.5" r="2.5" />
                        <circle cx="2.5" cy="19.5" r="2.5" />
                        <circle cx="2.5" cy="5.5" r="2.5" />
                        <rect x="8" y="10" width="16" height="4" rx="2" />
                        <rect x="8" y="18" width="16" height="4" rx="2" />
                        <rect x="8" y="3" width="16" height="4" rx="2" />
                      </svg>
                    </button>
                  }
                </div>
              </div>
              <div
                className="tab-content rightTabContent"
                id="leftpaneTabContent"
              >
                <div
                  className="tab-pane tabContentPane fade"
                  id="manage-content"
                  role="tabpanel"
                  aria-labelledby="manage-tab"
                >
                  Manage
                </div>
                <div
                  className={`${
                    theme || selectedaction?.themeName
                  } tab-pane tabContentPane fade show active`}
                  id="needs-content"
                  role="tabpanel"
                  aria-labelledby="needs-tab"
                >
                  <div className="action-row Needs_tabsactionrow">
                    <ul
                      className="nav nav-tabs mr-auto"
                      id="contentTab"
                      role="tablist"
                    >
                      <li className="nav-item" key="item-all">
                        <a
                          onClick={() => setParamsData("all")}
                          className="nav-link active"
                          id="all-tab"
                          data-toggle="tab"
                          href="#all"
                          role="tab"
                          aria-controls="all"
                          aria-selected="true"
                        >
                          All Needs
                        </a>
                      </li>
                      <li className="nav-item" key="item-buy">
                        <a
                          onClick={() => setParamsData("buy")}
                          className="nav-link"
                          id="buy-tab"
                          data-toggle="tab"
                          href="#buy"
                          role="tab"
                          aria-controls="buy"
                          aria-selected="false"
                        >
                          Buy
                        </a>
                      </li>
                      <li className="nav-item" key="item-give">
                        <a
                          onClick={() => setParamsData("give")}
                          className="nav-link"
                          id="give-tab"
                          data-toggle="tab"
                          href="#give"
                          role="tab"
                          aria-controls="give"
                          aria-selected="false"
                        >
                          Give
                        </a>
                      </li>
                      <li className="nav-item" key="item1-do">
                        <a
                          onClick={() => setParamsData("do")}
                          className="nav-link"
                          id="do-tab"
                          data-toggle="tab"
                          href="#do"
                          role="tab"
                          aria-controls="do"
                          aria-selected="false"
                        >
                          Do
                        </a>
                      </li>
                    </ul>
                    <button
                      className="btn view-btn new-dashview "
                      onClick={
                        () =>
                          window.open(
                            baseUrl +
                              "/l/" +
                              props.match.params.id,
                            "_blank"
                          )

                        // props.history.push(
                        //   '/l/' + props.match.params.id
                        // )
                      }
                    >
                      <img src={ViewEnabled} alt="" className="mr-2" />
                      View
                    </button>
                    <button
                      className="btn share-btn for-desktop"
                      onClick={openShareModal}
                    >
                      <img src={Share} alt="" className="mr-2" />
                      Share
                    </button>
                    {/* <div className='dropdown'>
                  <button
                    className='btn btn-secondary dropdown-toggle'
                    type='button'
                    id='dropdownMenuButton'
                    data-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    Filter
                    <img src={Filter} className='ml-auto' alt='' />
                  </button>
                  <div
                    className='dropdown-menu'
                    aria-labelledby='dropdownMenuButton'
                  >
                    <a className='dropdown-item'>
                      Action
                    </a>
                    <a className='dropdown-item'>
                      Another action
                    </a>
                    <a className='dropdown-item'>
                      Something else here
                    </a>
                  </div>
                </div> */}
                    <button
                      type="button"
                      className={
                        allNeedsExpands ? "btn grid-btn active" : "btn grid-btn"
                      }
                      onClick={expandToggle}
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="4.5" cy="6.5" r="4.5" />
                        <circle cx="4.5" cy="17.5" r="4.5" />
                        <rect x="12" y="2" width="12" height="9" rx="4" />
                        <rect x="12" y="13" width="12" height="9" rx="4" />
                      </svg>
                    </button>
                    <button
                      type="button"
                      className={
                        !allNeedsExpands
                          ? "btn grid-btn active"
                          : "btn grid-btn"
                      }
                      onClick={expandToggle}
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="2.5" cy="12.5" r="2.5" />
                        <circle cx="2.5" cy="19.5" r="2.5" />
                        <circle cx="2.5" cy="5.5" r="2.5" />
                        <rect x="8" y="10" width="16" height="4" rx="2" />
                        <rect x="8" y="18" width="16" height="4" rx="2" />
                        <rect x="8" y="3" width="16" height="4" rx="2" />
                      </svg>
                    </button>
                  </div>
                  <div className="contentTab-content pl-0">
                    <div className="tab-content" id="myTabContent">
                      <div
                        className="tab-pane fade show active all-tab-content"
                        id="all"
                        role="tabpanel"
                        aria-labelledby="all-tab"
                      >
                        {!allNeedsExpands && callall && (
                          <NeedsCreationShortenListView
                            openDraft={editDraft}
                            params={params}
                            onupdateList={rerenderList}
                            needForAdd={needNameForAdd}
                            createNeed={createNeed}
                            match={props.match}
                            history={props.history}
                            liveTitleAdd={liveTitleAdd}
                          />
                        )}
                        {allNeedsExpands && callall && (
                          <NeedCreationExpandedListView
                            openDraft={editDraft}
                            params={params}
                            onupdateList={rerenderList}
                            needForAdd={needNameForAdd}
                            createNeed={createNeed}
                            match={props.match}
                            history={props.history}
                            liveTitleAdd={liveTitleAdd}
                          />
                        )}
                      </div>
                      <div
                        className="tab-pane fade"
                        id="buy"
                        role="tabpanel"
                        aria-labelledby="buy-tab"
                      >
                        {/* Buy */}
                        {!allNeedsExpands && callbuy && (
                          <NeedsCreationShortenListView
                            openDraft={editDraft}
                            params={params}
                            onupdateList={rerenderList}
                            needForAdd={needNameForAdd}
                            createNeed={createNeed}
                            match={props.match}
                            history={props.history}
                            liveTitleAdd={liveTitleAdd}
                          />
                        )}
                        {allNeedsExpands && callbuy && callbuy && (
                          <NeedCreationExpandedListView
                            openDraft={editDraft}
                            params={params}
                            onupdateList={rerenderList}
                            needForAdd={needNameForAdd}
                            createNeed={createNeed}
                            match={props.match}
                            history={props.history}
                            liveTitleAdd={liveTitleAdd}
                          />
                        )}
                      </div>
                      <div
                        className="tab-pane fade"
                        id="give"
                        role="tabpanel"
                        aria-labelledby="give-tab"
                      >
                        {/* Give */}
                        {!allNeedsExpands && callgive && (
                          <NeedsCreationShortenListView
                            openDraft={editDraft}
                            params={params}
                            onupdateList={rerenderList}
                            needForAdd={needNameForAdd}
                            createNeed={createNeed}
                            match={props.match}
                            history={props.history}
                            liveTitleAdd={liveTitleAdd}
                          />
                        )}
                        {allNeedsExpands && callgive && (
                          <NeedCreationExpandedListView
                            openDraft={editDraft}
                            params={params}
                            onupdateList={rerenderList}
                            needForAdd={needNameForAdd}
                            createNeed={createNeed}
                            match={props.match}
                            history={props.history}
                            liveTitleAdd={liveTitleAdd}
                          />
                        )}
                      </div>
                      <div
                        className="tab-pane fade"
                        id="do"
                        role="tabpanel"
                        aria-labelledby="do-tab"
                      >
                        {/* Do */}
                        {!allNeedsExpands && calldo && (
                          <NeedsCreationShortenListView
                            openDraft={editDraft}
                            params={params}
                            needForAdd={needNameForAdd}
                            createNeed={createNeed}
                            match={props.match}
                            history={props.history}
                            liveTitleAdd={liveTitleAdd}
                          />
                        )}
                        {allNeedsExpands && calldo && (
                          <NeedCreationExpandedListView
                            openDraft={editDraft}
                            params={params}
                            needForAdd={needNameForAdd}
                            createNeed={createNeed}
                            match={props.match}
                            history={props.history}
                            liveTitleAdd={liveTitleAdd}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="reports-content"
                  role="tabpanel"
                  aria-labelledby="reports-tab"
                >
                  Reports
                  {/* {RenderReport()} */}
                </div>
                <div
                  className="tab-pane fade"
                  id="settings-content"
                  role="tabpanel"
                  aria-labelledby="settings-tab"
                >
                  Settings
                </div>
              </div>
            </div>
          </div>
          <div className="mob_view">
            {(isMobileView = true)}

            {/* {(needTypeForEditPopup === "give" || openCard) && (
              <NeedsCreationOpenCard
                createNeed={createNeed}
                show={openCard}
                openGiveModal={openGiveModal}
                openBuyModal={openBuyModal}
                openDoModal={openDoModal}
                clicked={closeGiveModal}
                addDetails={(data) => addDetailsFromOpenCard(data)}
                selectedNeedItem={props?.selectedNeedItem}
              />
            )}

            {(needTypeForEditPopup === "buy" || buyModal) && (
              <NeedsCreationOpenCard
                createNeed={createNeed}
                show={openCard}
                openGiveModal={openGiveModal}
                openBuyModal={openBuyModal}
                openDoModal={openDoModal}
                clicked={closeGiveModal}
                addDetails={(data) => addDetailsFromOpenCard(data)}
                selectedNeedItem={props?.selectedNeedItem}
              />
            )} */}

            {(needTypeForEditPopup === "buy" || buyModal) && (
              <NeedsCreationBuyTypeSelectedMandatoryInfo
                openGiveModal={openGiveModal}
                openBuyModal={openBuyModal}
                openDoModal={openDoModal}
                addDetails={(data) => buyTypeDetailsHandler(data)}
                show={buyModal}
                clicked={closeBuyModal}
                createNeed={createNeed}
                selectedNeedItem={props?.selectedNeedItem}
              />
            )}

            {(needTypeForEditPopup === "give" || giveModal) && (
              <NeedsCreationgiveTypeSelectedMandatoryInfo
                show={giveModal}
                addDetails={(data) => openAddDetailsGiveModal(data)}
                openGiveModal={openGiveModal}
                openBuyModal={openBuyModal}
                openDoModal={openDoModal}
                clicked={closeGiveModal}
                createNeed={createNeed}
                selectedNeedItem={props?.selectedNeedItem}
              />
            )}
            {(needTypeForEditPopup === "do" || openCardDo) && (
              <NeedsCreationDoTypeSelectedmandatoryInfo
                show={openCardDo}
                addDetails={(data) => openAddDetailsDoModal(data)}
                openGiveModal={openGiveModal}
                openBuyModal={openBuyModal}
                openDoModal={openDoModal}
                clicked={closeDoModal}
                createNeed={createNeed}
                selectedNeedItem={props?.selectedNeedItem}
              />
            )}
            {/* {draftEditing && (
              <NeedsCreationDraftEditing
                openBuyModal={openBuyModal}
                openGiveModal={openGiveModal}
                openDoModal={openDoModal}
                show={editDraftModal}
                clicked={closeEditDraft}
              />
            )} */}

            {/* mobile needs open start*/}
            {buyDetails && (
              <NeedsCreationBuyTypeDetailsAdding
                detailsFunction={detailsFunction}
                close={buyTypeDetailsCloseHandler}
                bothPopClose={bothModalPopClose}
                createNeed={createNeed}
                clicked={() => setBuyDetails(false)}
                buyDetailsinfo={buydata}
                match={props.match}
                history={props.history}
                liveTitleAdd={setLiveTitleAdd}
              />
            )}

            {GiveTypeDetailsAdding && (
              <NeedsCreationGiveTypeDetailsAdding
                close={addCloseGiveTypeDetailsHandler}
                bothPopClose={bothModalPopClose}
                detailsFunction={detailsFunction}
                createNeed={createNeed}
                giveDetailsinfo={givedata}
                clicked={() => setGiveTypeDetailsAdding(false)}
                match={props.match}
                history={props.history}
                liveTitleAdd={setLiveTitleAdd}
              />
            )}

            {addDetailsDoModal && (
              <NeedsCreationDoTypeDetailsAdding
                close={closeAddDetailsDoModal}
                bothPopClose={bothModalPopClose}
                detailsFunction={detailsFunction}
                createNeed={createNeed}
                clicked={() => setAddDetailsDoModal(false)}
                doDetailsinfo={dodata}
                match={props.match}
                history={props.history}
                liveTitleAdd={setLiveTitleAdd}
              />
            )}

            {/* mobile needs open end*/}
            {infoPreview && <NeedsCreationNeedInfoPreview />}
            {needEditFree && (
              <NeedCreationNeedInfoEditingFreeAccountRestrictions
                proAccount={infoEditProAcc}
              />
            )}
            {props?.selectedNeedItem?.needType === "" &&
              (openCardDo || buyModal || openCard) === false &&
              !isBackInPopup && (
                <NeedsCreationOpenCard
                  createNeed={createNeed}
                  show={openCard}
                  openGiveModal={openGiveModal}
                  openBuyModal={openBuyModal}
                  openDoModal={openDoModal}
                  clicked={closeGiveModal}
                  addDetails={(data) => addDetailsFromOpenCard(data)}
                  // selcetedNeedType = {props?.selcetedNeedType}
                  selectedNeedItem={props?.selectedNeedItem}
                />
              )}
          </div>
        </>
      )}

      <div className="for-desktop">
        {(buyDetails || buyModal) && (
          <NeedsCreationBuyTypeDetailsAdding
            detailsFunction={detailsFunction}
            close={buyTypeDetailsCloseHandler}
            bothPopClose={bothModalPopClose}
            createNeed={createNeed}
            clicked={() => setBuyDetails(false)}
            buyDetailsinfo={buydata}
            match={props.match}
            history={props.history}
            liveTitleAdd={setLiveTitleAdd}
          />
        )}
        {(GiveTypeDetailsAdding || openCard) && (
          <NeedsCreationGiveTypeDetailsAdding
            close={addCloseGiveTypeDetailsHandler}
            bothPopClose={bothModalPopClose}
            detailsFunction={detailsFunction}
            createNeed={createNeed}
            giveDetailsinfo={givedata}
            clicked={() => setGiveTypeDetailsAdding(false)}
            match={props.match}
            history={props.history}
            liveTitleAdd={setLiveTitleAdd}
          />
        )}

        {(addDetailsDoModal || openCardDo) && (
          <NeedsCreationDoTypeDetailsAdding
            close={closeAddDetailsDoModal}
            bothPopClose={bothModalPopClose}
            detailsFunction={detailsFunction}
            createNeed={createNeed}
            clicked={() => setAddDetailsDoModal(false)}
            doDetailsinfo={dodata}
            match={props.match}
            history={props.history}
            liveTitleAdd={setLiveTitleAdd}
          />
        )}
      </div>

      {/*@Modals*/}
      {props?.draftWindowOpen && (
        <>
          {(needTypeForEditPopup === "give" || openCard) && (
            <NeedsCreationOpenCard
              createNeed={createNeed}
              show={openCard}
              openGiveModal={openGiveModal}
              openBuyModal={openBuyModal}
              openDoModal={openDoModal}
              clicked={closeGiveModal}
              addDetails={(data) => addDetailsFromOpenCard(data)}
              selectedNeedItem={props?.selectedNeedItem}
            />
          )}

          {(needTypeForEditPopup === "buy" || buyModal) && (
            <NeedsCreationBuyTypeSelectedMandatoryInfo
              openGiveModal={openGiveModal}
              openBuyModal={openBuyModal}
              openDoModal={openDoModal}
              addDetails={(data) => buyTypeDetailsHandler(data)}
              show={buyModal}
              clicked={closeBuyModal}
              createNeed={createNeed}
              selectedNeedItem={props?.selectedNeedItem}
            />
          )}

          {GiveTypeDetailsAdding && (
            <NeedsCreationGiveTypeDetailsAdding
              close={addCloseGiveTypeDetailsHandler}
              bothPopClose={bothModalPopClose}
              detailsFunction={detailsFunction}
              createNeed={createNeed}
              giveDetailsinfo={givedata}
              clicked={() => setGiveTypeDetailsAdding(false)}
              match={props.match}
              history={props.history}
            />
          )}

          {draftEditing && (
            <NeedsCreationDraftEditing
              openBuyModal={openBuyModal}
              openGiveModal={openGiveModal}
              openDoModal={openDoModal}
              show={editDraftModal}
              clicked={closeEditDraft}
            />
          )}

          {buyDetails && (
            <NeedsCreationBuyTypeDetailsAdding
              detailsFunction={detailsFunction}
              close={buyTypeDetailsCloseHandler}
              bothPopClose={bothModalPopClose}
              createNeed={createNeed}
              clicked={() => setBuyDetails(false)}
              buyDetailsinfo={buydata}
              match={props.match}
              history={props.history}
            />
          )}

          {(needTypeForEditPopup === "do" || openCardDo) && (
            <NeedsCreationDoTypeSelectedmandatoryInfo
              show={openCardDo}
              addDetails={(data) => openAddDetailsDoModal(data)}
              openGiveModal={openGiveModal}
              openBuyModal={openBuyModal}
              openDoModal={openDoModal}
              clicked={closeDoModal}
              createNeed={createNeed}
              selectedNeedItem={props?.selectedNeedItem}
            />
          )}

          {addDetailsDoModal && (
            <NeedsCreationDoTypeDetailsAdding
              close={closeAddDetailsDoModal}
              bothPopClose={bothModalPopClose}
              detailsFunction={detailsFunction}
              createNeed={createNeed}
              clicked={() => setAddDetailsDoModal(false)}
              doDetailsinfo={dodata}
              match={props.match}
              history={props.history}
            />
          )}
          {infoPreview && <NeedsCreationNeedInfoPreview />}
          {needEditFree && (
            <NeedCreationNeedInfoEditingFreeAccountRestrictions
              proAccount={infoEditProAcc}
            />
          )}
        </>
      )}
      {props?.selectedNeedItem?.needType === "" &&
        (openCardDo || buyModal || openCard) === false &&
        !isBackInPopup && (
          <NeedsCreationOpenCard
            createNeed={createNeed}
            show={openCard}
            openGiveModal={openGiveModal}
            openBuyModal={openBuyModal}
            openDoModal={openDoModal}
            clicked={closeGiveModal}
            addDetails={(data) => addDetailsFromOpenCard(data)}
            // selcetedNeedType = {props?.selcetedNeedType}
            selectedNeedItem={props?.selectedNeedItem}
          />
        )}
      {needsFilters && <NeedsCreationFilters close={filters} />}
      <div
        class="modal list-creation-success-modal"
        tabindex="-1"
        role="dialog"
        id="listCreationSuccessModal"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <a
                class="modal-title"
                onClick={() =>
                  window
                    .$("#listCreationSuccessModal")
                    .modal("hide", localStorage.removeItem("NewList"))
                }
              >
                <img src="images/logo.svg" />
              </a>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() =>
                  window
                    .$("#listCreationSuccessModal")
                    .modal("hide", localStorage.removeItem("NewList"))
                }
              >
                <img src="images/close2.png" alt="close" />
              </button>
            </div>
            <div class="modal-body">
              <div class="congratulation_content">
                <h3 class="heading2">You have created a new ActionList!</h3>
                <p>
                  {/* You have created your first list! */}
                  The next step is to start adding
                  <br />
                  Needs to your list so people can find ways to take action.
                  {/* Move forward adding needs to help you with */}
                </p>
                {/* <img src='images/list_creation_success.png' /> */}
                <button
                  type="button"
                  class="btn_primary"
                  onClick={() =>
                    window
                      .$("#listCreationSuccessModal")
                      .modal("hide", localStorage.removeItem("NewList"))
                  }
                >
                  Add Needs
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ShareModal location={window.location.href} />
      <ToastContainer autoClose={3000} />
    </div>
  );
}
