import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import goBackImage from "../../assets/images/back_black.png";
import Profileheadermobile from "../../Common/Profileheadermobile";
import ProfileLeftPane from "../../Common/ProfileLeftPane";
import BottomTab from "../../Common/BottomTab";
import UnloginuserHead from "../../Common/UnloginuserHead";
import Cookies from "js-cookie";
import {
  getAndPostUserUpdateNotification,
  getUserDetailsInfo,
} from "../../Store/Actions/userActions";
import { ToastContainer, toast } from "react-toastify";
import "../../../node_modules/react-toastify/dist/ReactToastify.min.css";
import { isMobile } from "react-device-detect";

const EmailNotifications = ({ ...props }) => {
  const dispatch = useDispatch();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const notification = JSON.parse(localStorage.getItem("userNotifications"));

  const [enableEmailNotification, setEnableEmailNotification] = useState();
  const [newMsgNotify, setNewMsgNotify] = useState();
  const [newListAroundYouNotify, setNewListAroundYouNotify] = useState();
  const [savedListUpdatedNotify, setSavedListUpdatedNotify] = useState();
  const [
    actionListPlatformUpdatesAndNewsNotify,
    setActionListPlatformUpdatesAndNewsNotify,
  ] = useState();
  const [notifyUpdate, setNotifyUpdate] = useState(false);
  const [notifyUpdateCall, setNotifyUpdateCall] = useState("");

  useEffect(() => {
    dispatch(getUserDetailsInfo(userInfo?.userId)).then((res) => {
      if (res) {
        console.log("abc", res);
        const notification = res.data.notification;
        setEnableEmailNotification(notification?.email);
        setNewMsgNotify(notification?.newMsg);
        setNewListAroundYouNotify(notification?.newList);
        setSavedListUpdatedNotify(notification?.saveList);
        setActionListPlatformUpdatesAndNewsNotify(
          notification?.actionListUpdate
        );
      }
    });
  }, []);

  useEffect(() => {
    console.log(notifyUpdateCall);
    if (notifyUpdateCall) {
      notificationUpdate();
    }
  }, [notifyUpdateCall]);

  const notificationUpdate = () => {
    let data = {
      userId: userInfo?.userId,
      notification: {
        email: enableEmailNotification,
        newMsg: newMsgNotify,
        newList: newListAroundYouNotify,
        saveList: savedListUpdatedNotify,
        actionListUpdate: actionListPlatformUpdatesAndNewsNotify,
      },
    };
    dispatch(getAndPostUserUpdateNotification(data, userInfo?.token)).then(
      (res) => {
        if (res?.data) {
          const { msg } = res?.data;
          notifyUpdate && toast.success(msg);
          dispatch(getUserDetailsInfo(userInfo?.userId));
        } else {
          toast.error("Somthing went wrong");
        }
      }
    );
  };

  return (
    <div>
      {!isMobile &&
      (Cookies.get("token") ? (
        <Profileheadermobile history={props.history} />
      ) : (
        <UnloginuserHead history={props.history} />
       ))}
      <div className="content profile">
        <ProfileLeftPane activePage="email-notifications" />
        <div class="tab-content" id="settingsTabContent">
          <div
            class="tab-pane fade"
            id="account-settings"
            role="tabpanel"
            aria-labelledby="account-settings-tab"
          >
            Account settings
          </div>
          <div
            class="tab-pane fade"
            id="payment-methods"
            role="tabpanel"
            aria-labelledby="payment-methods-tab"
          >
            Payment methods
          </div>
          <div
            class="tab-pane fade show active"
            id="email-notifications"
            role="tabpanel"
            aria-labelledby="email-notifications-tab"
          >
            <div class="mobile-header">
              <nav class="navbar">
                <div onClick={() => props.history.goBack()} class="back">
                  <img src={goBackImage} alt="go-back" />
                </div>
                <label class="navbar-brand mx-auto middle-label">
                  Email notifications
                </label>
              </nav>
            </div>
            <div class="inner-content notification">
              <h2 class="heading2">Email notifications</h2>
              <p class="para">
                Enable email notifications to receive alerts when there is
                activity related to your account.
              </p>
              <form class="notification-form">
                <div class="row">
                  <div class="col-xl-12 col-lg-12">
                    <label for="emailNotification">
                      Email notifications
                      <input
                        type="checkbox"
                        id="emailNotification"
                        onClick={() => setNotifyUpdate(true)}
                        checked={enableEmailNotification}
                        onChange={(e) => {
                          setEnableEmailNotification((val) => !val);
                          setNotifyUpdateCall("emailNotification");
                        }}
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-xl-5 col-lg-5">
                    <label for="newMessage">
                      New message in a chat
                      <input
                        type="checkbox"
                        id="newMessage"
                        onClick={() => setNotifyUpdate(true)}
                        checked={newMsgNotify}
                        onChange={() => {
                          setNewMsgNotify((val) => !val);
                          setNotifyUpdateCall("newMessage");
                        }}
                      />
                      <span class="checkmark"></span>
                    </label>
                    <label for="savedList">
                      Saved lists updates
                      <input
                        type="checkbox"
                        id="savedList"
                        onClick={() => setNotifyUpdate(true)}
                        checked={newListAroundYouNotify}
                        onChange={() => {
                          setNewListAroundYouNotify((val) => !val);
                          setNotifyUpdateCall("savedList");
                        }}
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="col-xl-5 col-lg-5">
                    <label for="newList">
                      New lists around you
                      <input
                        type="checkbox"
                        id="newList"
                        onClick={() => setNotifyUpdate(true)}
                        checked={savedListUpdatedNotify}
                        onChange={() => {
                          setSavedListUpdatedNotify((val) => !val);
                          setNotifyUpdateCall("newList");
                        }}
                      />
                      <span class="checkmark"></span>
                    </label>
                    <label for="platformNews">
                      ActionList platform updates & news
                      <input
                        type="checkbox"
                        id="platformNews"
                        onClick={() => setNotifyUpdate(true)}
                        checked={actionListPlatformUpdatesAndNewsNotify}
                        onChange={() => {
                          setActionListPlatformUpdatesAndNewsNotify(
                            (val) => !val
                          );
                          setNotifyUpdateCall("platformNews");
                        }}
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="password-reset"
            role="tabpanel"
            aria-labelledby="password-reset-tab"
          >
            Password reset
          </div>
        </div>

        <BottomTab activeScreen={"profile"} />
      </div>
      <ToastContainer autoClose={3000} />
    </div>
  );
};

export default EmailNotifications;
