import React from 'react';
import {ShowPreviewData} from './showPreviewData';

const ListCreationPreviewWaterpolo = (props) => {
  return (
    <>
      <ShowPreviewData 
        thmName = 'waterpolo'
          history = {props?.history}
      />
    </>
  );
};

export default ListCreationPreviewWaterpolo;
