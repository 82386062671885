import React, { useState, useEffect } from "react";
import { ProcessedToCheckoutRoute } from "../../Routing/RouteConstants";
import mastercard from "../../assets/images/mastercard.png";
import checkmark_white from "../../assets/images/checkmark_white.png";
import { useSelector, useDispatch } from "react-redux";
import {
  addnewCardBillingAddress,
  getStripeToken,
  getUserDetailsInfo,
} from "../../Store/Actions/userActions";
import Profileheadermobile from "../../Common/Profileheadermobile";
import BottomTab from "../../Common/BottomTab";
import { isMobile } from "react-device-detect";
import Cookies from "js-cookie";
import UnloginuserHead from "../../Common/UnloginuserHead";
import PhoneInput, {
  isValidPhoneNumber,
  formatPhoneNumberIntl,
  parsePhoneNumber,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { getWishList } from "../../Store/Actions/userActions";
import Header from "./Header";

const ProcessedToPayment = (props) => {
  const idArray = window.location.href.split("/");
  const id = idArray[idArray.length - 1];
  const [cardError, setCardError] = useState({
    // fullName: false,
    firstName: false,
    lastName: false,
    email: false,
    cardNumber: false,
    holderName: false,
    expirydate: false,
    cvvnum: false,
    usermessage: "",
    emailmessage: "",
    cardmsg: "",
    holdermsg: "",
    expirymsg: "",
    cvvmsg: "",
  });
  const [inputField, setInputField] = useState({
    email: "",
    // fullName: '',
    firstName: "",
    lastName: "",
    cardNumber: "",
    holderName: "",
    expirydate: "",
    cvvnum: "",
  });
  const dispatch = useDispatch();
  const [expirydate, setexpiryDate] = useState("");
  const [buttonDisable, setbuttonDisable] = useState(true);
  const [StripeError, setStripeError] = useState("");
  const [addCardChecked, setaddCardChecked] = useState(isMobile ? false : true);
  const [saveCardChecked, setsaveCardChecked] = useState(false);
  const [chagecardmsg, setchagecardmsg] = useState("");
  const userCardBillingAddress = useSelector(
    (state) => state.userCardBillingAddress
  );
  const {
    loading: cardloading,
    usercardBillingAddInfo,
    error: carderror,
  } = userCardBillingAddress;
  const [primaryCard, setPrimaryCard] = useState([]);
  const [otherCard, setOtherCard] = useState([]);
  const [showCard, setShowCard] = useState(false);
  const userSignin = useSelector((state) => state.userSignin);
  let [phoneNo, setPhoneNo] = useState(null);
  const [formError, setFormError] = useState({ phone: "" });

  function er(e) {
    setFormError({ phone: e });
  }

  const { loading: userloading, userInfo, error: usererror } = userSignin;
  const UserDetails = useSelector((state) => state.UserDetails);
  const {
    loading: userLoading,
    getuserInfo,
    error: userinfoerror,
  } = UserDetails;
  const getuserWishList = useSelector(
    (state) => state.getuserWishList.userWishlistInfo
  );

  const subTotal = getuserWishList.reduce(
    (subTotal, item) => subTotal + item.cost * item.qty,
    0
  );

  const BuyQty = getuserWishList.reduce(function (sum, item) {
    return item.needType === "buy" ? sum + item.qty : sum;
  }, 0);

  const DoQty = getuserWishList.reduce(function (sum, item) {
    return item.needType === "do" ? sum + item.qty : sum;
  }, 0);

  const GiveQty = getuserWishList.reduce(function (sum, item) {
    return item.needType === "give" ? sum + item.qty : sum;
  }, 0);

  const DonationSum = getuserWishList.reduce(function (sum, item) {
    return item.needType === "Donation" ? sum + item.cost : sum;
  }, 0);

  var Buy = false;
  var Do = false;
  var Give = false;
  var Donate = false;
  getuserWishList.map((item) => {
    if (item.needType === "buy") {
      return (Buy = true);
    } else if (item.needType === "do") {
      return (Do = true);
    } else if (item.needType === "give") {
      return (Give = true);
    } else if (item.needType === "Donation") {
      return (Donate = true);
    }
  });
  const stripeTokenInfo = useSelector((state) => state.stripeTokenInfo);

  const handleChange = (e) => {
    const { name, value } = e.target;
    inputField[name] = value;
    setInputField({ ...inputField });

    let datavalidation = validationfun(inputField, e.target.name);
    if (datavalidation) {
      return;
    }
  };

  const onRadioChange = (item) => {
    setInputField({
      email: inputField.email,
      // fullName: inputField.fullName,
      firstName: inputField.firstName,
      lastName: inputField.lastName,
      cardNumber: item.cardNumber,
      holderName: item.cardHolderName,
      expirydate: item.expirationDate,
      cvvnum: item.cvv,
    });
    setexpiryDate(item.expirationDate);
  };

  useEffect(() => {
    dispatch(getUserDetailsInfo(userInfo?.userId));
  }, []);

  useEffect(() => {
    if (getuserWishList) {
      let isBuyGiveType;
      const typeOfNeed = getuserWishList?.map((item) => {
        if (item.needType === "buy") {
          return "Buy";
        } else if (item.needType === "do") {
          return "Do";
        } else if (item.needType === "give") {
          return "Give";
        } else if (item.needType === "Donation") {
          return "Donation";
        }
      });

      isBuyGiveType = exist("Buy", typeOfNeed) || exist("Donation", typeOfNeed);
      console.log("show", isBuyGiveType);
      setShowCard(isBuyGiveType);
    }
  }, [getuserWishList]);
  function exist(value, array) {
    return array.some((e) => e === value);
  }
  const setcardeipiryData = (e) => {
    if (e.target.value.length <= 5) {
      if (e.target.value.indexOf("/") === -1) {
        if (e.target.value.length === 3) {
          var tempstr =
            e.target.value.slice(0, 2) + "/" + e.target.value.slice(2);
        } else {
          var tempstr = e.target.value;
        }
      } else {
        var tempstr = e.target.value;
      }
      setexpiryDate(tempstr);
      handleChange(e);
    }
  };

  function ValidateCreditCardNumber(ccNum) {
    var visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
    var mastercardRegEx = /^(?:5[1-5][0-9]{14})$/;
    var amexpRegEx = /^(?:3[47][0-9]{13})$/;
    var discovRegEx = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;
    var JCBRegEx = /^(?:2131|1800|35\d{3})\d{11}$/;
    var unionRegEx = /^(62[0-9]{14,17})$/;
    var isValid = false;

    if (visaRegEx.test(ccNum)) {
      isValid = true;
    } else if (mastercardRegEx.test(ccNum)) {
      isValid = true;
    } else if (amexpRegEx.test(ccNum)) {
      isValid = true;
    } else if (discovRegEx.test(ccNum)) {
      isValid = true;
    } else if (JCBRegEx.test(ccNum)) {
      isValid = true;
    } else if (unionRegEx.test(ccNum)) {
      isValid = true;
    }
    return isValid;
  }

  useEffect(() => {
    if (getuserInfo) {
      var otherCard = getuserInfo?.savedCards;
      var temparray = [];
      var findIndex = otherCard.findIndex(
        (item) => getuserInfo?.primaryCardId == item.cardId
      );
      otherCard.length > 0 && temparray.push(otherCard[findIndex]);
      setPrimaryCard(temparray);
      otherCard.splice(findIndex, 1);
      setOtherCard(otherCard);
    }

    return () => {};
  }, [getuserInfo]);

  const validationfun = (validationform, type) => {
    let showallerror = false;
    var mailformat =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    var regExName = /^[a-zA-Z]+$/;
    var regExFullNameFirst = /^[a-zA-Z]+ [a-zA-Z]+$/;
    var regExFullNameSecond = /^[a-zA-Z]+ [a-zA-Z]+ [a-zA-Z]+$/;

    // var form = {
    //   fullName: false,
    //   email: false,
    //   cardNumber: false,
    //   holderName: false,
    //   expirydate: false,
    //   cvvnum: false,
    //   usermessage: '',
    //   emailmessage: '',
    //   cardmsg: '',
    //   holdermsg: '',
    //   expirymsg: '',
    //   cvvmsg: '',
    // };

    // if (type === 'fullName') {
    //   if (
    //     validationform.fullName === undefined ||
    //     validationform.fullName === '' || !regExFullNameFirst.test(validationform.fullName) && !regExFullNameSecond.test(validationform.fullName)
    //   ) {
    //     showallerror = true;
    //     // form.fullName = true;
    //     // form.usermessage = 'Please Enter your Full Name';
    //     cardError.fullName = true
    //     cardError.usermessage = 'Please Enter your Full Name';
    //   }
    //   else{
    //     cardError.fullName = false
    //     cardError.usermessage = '';
    //   }
    // }

    if (type === "firstName") {
      if (
        validationform.firstName === undefined ||
        validationform.firstName === "" ||
        !regExName.test(validationform.firstName)
      ) {
        showallerror = true;
        // form.fullName = true;
        // form.usermessage = 'Please Enter your Full Name';
        cardError.firstName = true;
        cardError.usermessage = "Please enter your first name";
      } else {
        cardError.firstName = false;
        cardError.usermessage = "";
      }
    }

    if (type === "lastName") {
      if (
        validationform.lastName === undefined ||
        validationform.lastName === "" ||
        !regExName.test(validationform.lastName)
      ) {
        showallerror = true;
        // form.fullName = true;
        // form.usermessage = 'Please enter your last name';
        cardError.lastName = true;
        cardError.usermessage = "Please enter your last name";
      } else {
        cardError.lastName = false;
        cardError.usermessage = "";
      }
    }

    if (type === "email") {
      if (validationform.email === undefined || validationform.email === "") {
        showallerror = true;
        // form.email = true;
        // form.emailmessage = 'Please Enter your Email';
        cardError.email = true;
        cardError.emailmessage = "Please Enter your Email";
      } else if (!validationform.email.match(mailformat)) {
        showallerror = true;
        // form.email = true;
        // form.emailmessage = 'Please Enter your valid Email';
        cardError.email = true;
        cardError.emailmessage = "Please Enter your valid Email";
      } else {
        cardError.email = false;
        cardError.emailmessage = "";
      }
    }
    if (addCardChecked && showCard) {
      if (type === "cardNumber") {
        if (
          validationform.cardNumber === undefined ||
          validationform.cardNumber === ""
        ) {
          showallerror = true;
          // form.cardNumber = true;inputField
          // form.cardmsg = 'Please Enter your Card Number';
          cardError.cardNumber = true;
          cardError.cardmsg = "Please Enter your Card Number";
        } else if (!ValidateCreditCardNumber(validationform.cardNumber)) {
          showallerror = true;
          // form.cardNumber = true;
          // form.cardmsg = 'Please Enter your valid Card Number';
          cardError.cardNumber = true;
          cardError.cardmsg = "Please Enter your valid Card Number";
        } else {
          cardError.cardNumber = false;
          cardError.cardmsg = "";
        }
      }
      if (type === "holderName") {
        if (
          validationform.holderName === undefined ||
          validationform.holderName === ""
        ) {
          showallerror = true;
          // form.holderName = true;
          // form.holdermsg = 'Please Enter your Card Holder Name';
          cardError.holderName = true;
          cardError.holdermsg = "Please Enter your Card Holder Name";
        } else {
          cardError.holderName = false;
          cardError.holdermsg = "";
        }
      }
      if (type === "expirydate") {
        if (expirydate === undefined || expirydate === "") {
          showallerror = true;
          // form.expirydate = true;
          // form.expirymsg = 'Please Enter Card Expiry Date';
          cardError.expirydate = true;
          cardError.expirymsg = "Please Enter Card Expiry Date";
        } else {
          cardError.expirydate = false;
          cardError.expirymsg = "";
        }
      }
      if (type === "cvvnum") {
        if (
          validationform.cvvnum === undefined ||
          validationform.cvvnum === ""
        ) {
          showallerror = true;
          // form.cvvnum = true;
          // form.cvvmsg = 'Please Enter Cvv/CVC';
          cardError.cvvnum = true;
          cardError.cvvmsg = "Please Enter Cvv/CVC";
        } else {
          cardError.cvvnum = false;
          cardError.cvvmsg = "";
        }
      }
    }

    // setCardError(form);
    setCardError({ ...cardError });
    return showallerror;
  };

  const submitChanges = () => {
    let datavalidation = validationfun(inputField);
    if (datavalidation) {
      console.log("active", showCard);

      return;
    }
    // const { cardNumber, holderName, cvvnum, fullName, email } = inputField;
    const { cardNumber, holderName, cvvnum, firstName, lastName, email } =
      inputField;
    var obj = {};
    var stripeObj = {};
    var billadd = {};

    var mystring = expirydate;
    var exp_date = mystring.split("/");
    var month = exp_date[0];
    var year = exp_date[1];

    obj.userId = userInfo?.userId;
    // obj.fullName = fullName;
    obj.fullName = firstName + " " + lastName;
    obj.email = email;

    console.log("active", showCard);
    if (showCard) {
      obj.cardNumber = cardNumber;
      obj.cardHolderName = holderName;
      obj.expirationDate = expirydate;
      obj.cvv = cvvnum;
      obj.setAsPrimary = false;
      obj.billingAddress = billadd;
      stripeObj.cardNumber = cardNumber;
      stripeObj.expMonth = month;
      stripeObj.expYear = year;
      stripeObj.cvv = cvvnum;

      dispatch(getStripeToken(stripeObj)).then((res) => {
        if (res?.id) {
          props.history.push({
            pathname: "/embed/processed-to-checkout/" + id,
            state: inputField,
          });
        }
      });
    } else {
      if (phoneNo === null || phoneNo === " " || phoneNo === undefined)
        return er("Enter Phone Number");
      else er("");
      if (phoneNo && !isValidPhoneNumber(phoneNo)) {
        const { country } = parsePhoneNumber(phoneNo);
        return er(`Invalid phone number for ${country} country code`);
      } else er("");
      let phoneNoIntFmt = formatPhoneNumberIntl(phoneNo);
      let num = phoneNoIntFmt.split(" ")[0];
      const phoneNumber = phoneNo;
      console.log(phoneNumber);
      inputField.phoneNumber = phoneNumber;

      props.history.push({
        pathname: "/embed/processed-to-checkout/" + id,
        state: inputField,
      });
    }

    console.log(obj);
    if (saveCardChecked) {
      dispatch(addnewCardBillingAddress(obj));
      dispatch(getUserDetailsInfo(userInfo?.userId));
    }
  };

  // useEffect(() => {
  //   if (
  //     inputField.fullName &&
  //     inputField.email &&
  //     inputField.holderName &&
  //     inputField.cardNumber &&
  //     inputField.cvvnum
  //   ) {
  //     setbuttonDisable(false);
  //   } else {
  //     setbuttonDisable(true);
  //   }
  // }, [inputField]);

  useEffect(() => {
    if (stripeTokenInfo?.error?.msg) {
      setStripeError(stripeTokenInfo?.error?.msg);
      setTimeout(function () {
        setStripeError("");
      }, 5000);
    }
  }, [stripeTokenInfo]);

  useEffect(() => {
    if (usercardBillingAddInfo?.msg.includes("Card Details Saved")) {
      setchagecardmsg(usercardBillingAddInfo?.msg);
      usercardBillingAddInfo.msg = "";
    }
    if (carderror?.msg) {
      setchagecardmsg(carderror?.msg);
      setTimeout(function () {
        setchagecardmsg("");
      }, 5000);
      carderror.msg = "";
    }
    return () => {
      //
    };
  }, [usercardBillingAddInfo, carderror]);

  const nextButtonDisable = () => {
    // if((inputField.fullName && inputField.email) && !(cardError.fullName && cardError.email)){
    //   return false
    // }else{
    //   return true
    // }
    if (
      inputField.firstName &&
      inputField.firstName &&
      inputField.email &&
      !(cardError.firstName && cardError.lastName && cardError.email)
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div>
      <Header />
      <div className="content purchase desktop">
        <div className="breadcrumb-row">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a>Review</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Details &amp; Payment
              </li>
              <li className="breadcrumb-item">
                <a>Submit</a>
              </li>
            </ol>
          </nav>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-8 left col-padding">
              <div className="header d-md-flex d-none">
                <h2 className="heading2">Details &amp; Payment</h2>
                {Cookies.get("token") ? null : (
                  <a
                    onClick={() => {
                      props.history.push("/login");
                    }}
                    className="link-extra"
                  >
                    Have an account? Log in
                  </a>
                )}
              </div>
              <h3 className="section-heading">Contact information</h3>
              <form className="contact-form">
                {/* <div className="form-group">
                  <label htmlFor="fullname">Full name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="fullname"
                    name="fullName"
                    placeholder
                    value={inputField.fullName}
                    onChange={handleChange}
                  />
                  {cardError.fullName && (
                    <div className="warningmessage">
                      {cardError.usermessage}
                    </div>
                  )}
                </div> */}
                <div className="form-group">
                  <label htmlFor="firstName">First Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    name="firstName"
                    placeholder
                    value={inputField.firstName}
                    onChange={handleChange}
                  />
                  {cardError.firstName && (
                    <div className="warningmessage">
                      {cardError.usermessage}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="lastName">Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    name="lastName"
                    placeholder
                    value={inputField.lastName}
                    onChange={handleChange}
                  />
                  {cardError.lastName && (
                    <div className="warningmessage">
                      {cardError.usermessage}
                    </div>
                  )}
                </div>
                <div className="form-group ml-0">
                  <label htmlFor="email">Your email address</label>
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    name="email"
                    placeholder
                    value={inputField.email}
                    onChange={handleChange}
                  />
                  {cardError.email && (
                    <div className="warningmessage">
                      {cardError.emailmessage}
                    </div>
                  )}
                </div>
                {!showCard && (
                  <>
                    <div class="form-group">
                      <label>Your phone number</label>
                      <div class="phone-row">
                        <div class="col1 column">
                          {/* <div className="drop-arrowdiv">
                  <img src="images/down_angle.png"></img>
                  </div> */}
                        </div>
                        <div class="col2 column"></div>
                        <div class="col3 column">
                          <PhoneInput
                            value={phoneNo}
                            type="phone"
                            defaultCountry="US"
                            onChange={setPhoneNo}
                            placeholder="(000) 000 000 00"
                          />
                        </div>
                        <div className="drop-arrowdiv" style={{ left: "15%" }}>
                          <img src="images/down_angle.png"></img>
                        </div>
                      </div>
                      {formError.phone !== "" && (
                        <div className="warningmessage">{formError.phone}</div>
                      )}
                    </div>
                  </>
                )}

                {showCard && (
                  <div class="card-row d-md-none d-block">
                    <label>Primary</label>
                    {primaryCard &&
                      primaryCard.length > 0 &&
                      primaryCard.map((item, index) => {
                        return (
                          <>
                            <div
                              class="existing-card-row"
                              style={{ marginTop: index === 0 ? 0 : 10 }}
                            >
                              <div class="column1">
                                <label for="mastercard">
                                  <input
                                    type="radio"
                                    name="paymentcard"
                                    id="mastercard"
                                    onClick={() => onRadioChange(item)}
                                  />
                                  <span class="checkmark"></span>
                                </label>
                              </div>
                              <div class="column2">
                                <img src={mastercard} alt="mastercard" />
                              </div>
                              <div class="column3">
                                <label class="row1">
                                  **** **** ****{" "}
                                  {String(item?.cardNumber)?.substr(12)}
                                </label>
                                <label class="row2">{item?.cardname}</label>
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </div>
                )}

                {showCard && (
                  <div class="card-row d-md-none d-block">
                    <label>Other</label>
                    {otherCard &&
                      otherCard.length > 0 &&
                      otherCard.map((item, index) => {
                        return (
                          <>
                            <div
                              class="existing-card-row"
                              style={{ marginTop: index === 0 ? 0 : 10 }}
                            >
                              <div class="column1">
                                <label for={index}>
                                  <input
                                    type="radio"
                                    name="paymentcard"
                                    id={index}
                                    onClick={() => onRadioChange(item)}
                                  />
                                  <span class="checkmark"></span>
                                </label>
                              </div>
                              <div class="column2">
                                <img src={mastercard} alt="master card" />
                              </div>
                              <div class="column3">
                                <label class="row1">
                                  **** **** ****{" "}
                                  {String(item.cardNumber).substr(12)}
                                </label>
                                <label class="row2">{item.cardname}</label>
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </div>
                )}
                {showCard && (
                  <>
                    {!addCardChecked ? (
                      <button
                        type="button"
                        className="btn btn-extra"
                        onClick={() => {
                          setaddCardChecked(!addCardChecked);
                        }}
                      >
                        Add new card
                      </button>
                    ) : null}
                  </>
                )}
              </form>
              <div
                style={{
                  marginTop: 20,
                  textAlign: "center",
                  color: StripeError ? "red" : "green",
                }}
              >
                {StripeError}
              </div>

              {showCard && addCardChecked ? (
                <>
                  <h3 className="section-heading">Payment details</h3>
                  <form className="payment-form mobform-group">
                    <div className="form-group">
                      <label htmlFor="cardnumber">Card number</label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0 6C0 5.20435 0.316071 4.44129 0.87868 3.87868C1.44129 3.31607 2.20435 3 3 3H21C21.7956 3 22.5587 3.31607 23.1213 3.87868C23.6839 4.44129 24 5.20435 24 6V7.5H0V6Z"
                              fill="#607BFF"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M0 10.5V18C0 18.7956 0.316071 19.5587 0.87868 20.1213C1.44129 20.6839 2.20435 21 3 21H21C21.7956 21 22.5587 20.6839 23.1213 20.1213C23.6839 19.5587 24 18.7956 24 18V10.5H0ZM4.5 13.5C4.10218 13.5 3.72064 13.658 3.43934 13.9393C3.15804 14.2206 3 14.6022 3 15V16.5C3 16.8978 3.15804 17.2794 3.43934 17.5607C3.72064 17.842 4.10218 18 4.5 18H6C6.39782 18 6.77936 17.842 7.06066 17.5607C7.34196 17.2794 7.5 16.8978 7.5 16.5V15C7.5 14.6022 7.34196 14.2206 7.06066 13.9393C6.77936 13.658 6.39782 13.5 6 13.5H4.5Z"
                              fill="#607BFF"
                            />
                          </svg>
                        </div>
                        <input
                          type="number"
                          className="form-control inputnum"
                          id="cardnumber"
                          placeholder=" 0000 0000 0000 0000"
                          name="cardNumber"
                          value={inputField.cardNumber}
                          onChange={handleChange}
                          maxLength={20}
                          keyboardType="numeric"
                        />
                      </div>
                      {cardError.cardNumber && (
                        <div className="warningmessage">
                          {cardError.cardmsg}
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="cardholdername">Cardholder name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="cardholdername"
                        placeholder="JOHN SMITH"
                        name="holderName"
                        value={inputField.holderName}
                        onChange={handleChange}
                      />
                      {cardError.holderName && (
                        <div className="warningmessage">
                          {cardError.holdermsg}
                        </div>
                      )}
                    </div>
                    <div className="form-group row">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        <label htmlFor="cardholdername" className="label">
                          Expiration date
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="expirydate"
                          placeholder="MM/YY"
                          name="expirydate"
                          value={expirydate}
                          onChange={setcardeipiryData}
                          maxLength={5}
                          minLength={5}
                        />
                        {cardError.expirydate && (
                          <div className="warningmessage">
                            {cardError.expirymsg}
                          </div>
                        )}
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        <label htmlFor="cardholdername" className="label">
                          CVC/Secure code
                        </label>
                        <input
                          type="number"
                          className="form-control inputnum"
                          id="cvvnum"
                          placeholder="CVC/CVV"
                          name="cvvnum"
                          value={inputField.cvvnum}
                          onChange={handleChange}
                          type="password"
                          maxLength={3}
                          minLength={3}
                        />
                        {cardError.cvvnum && (
                          <div className="warningmessage">
                            {cardError.cvvmsg}
                          </div>
                        )}
                      </div>
                    </div>
                    <label htmlFor="savecard" className="savecard">
                      Save my credit card
                      <input
                        type="checkbox"
                        id="savecard"
                        checked={saveCardChecked}
                        onChange={() => setsaveCardChecked((val) => !val)}
                      />
                      <span className="checkmark" />
                    </label>
                  </form>
                  <div
                    style={{
                      marginTop: 20,
                      textAlign: "center",
                      color: chagecardmsg.includes("Card Details Saved")
                        ? "green"
                        : chagecardmsg.includes("card details updated!")
                        ? "green"
                        : "red",
                    }}
                  >
                    {chagecardmsg}
                  </div>
                </>
              ) : null}
            </div>

            <div className="col-xl-4 col-lg-4 right col-padding">
              <div className="header d-md-block d-none">
                <div className="stepper type2">
                  <div className="step complete">
                    <img src={checkmark_white} />
                    <span>1</span>
                  </div>
                  <div className="step-line" />
                  <div className="step active">
                    <img src="images/checkmark_white.png" />
                    <span>2</span>
                  </div>
                  <div className="step-line" />
                  <div className="step">
                    <img src="images/checkmark_white.png" />
                    <span>3</span>
                  </div>
                </div>
                <div className="stepper-label type2">
                  <label className="complete">Review</label>
                  <label className="active">Details &amp; Payment</label>
                  <label>Submit</label>
                </div>
              </div>
              <h2 className="heading2 d-md-block d-none">Summary</h2>
              <div className="bottom-content">
                {Buy ? (
                  <div className="buy-need-row">
                    <label className="text">Buy needs</label>
                    <label className="quantity">x {BuyQty}</label>
                  </div>
                ) : (
                  ""
                )}
                {Give ? (
                  <div className="buy-need-row">
                    <label className="text">Give needs</label>
                    <label className="quantity">x {GiveQty}</label>
                  </div>
                ) : (
                  ""
                )}
                {Do ? (
                  <div className="buy-need-row">
                    <label className="text">Do needs</label>
                    <label className="quantity">x {DoQty}</label>
                  </div>
                ) : (
                  ""
                )}
                {Donate ? (
                  <div className="buy-need-row">
                    <label className="text">General donation</label>
                    <label className="quantity">
                      ${DonationSum.toFixed(2)}
                    </label>
                  </div>
                ) : (
                  ""
                )}
                {Buy || Donate ? (
                  <div className="sub-total-row">
                    <label className="text">Subtotal:</label>
                    <label className="price">${subTotal.toFixed(2)}</label>
                  </div>
                ) : (
                  ""
                )}
                <div className="bottom-row">
                  <button
                    type="button"
                    className="btn btn-prime"
                    onClick={submitChanges}
                    disabled={nextButtonDisable()}
                  >
                    Next
                  </button>
                  <div className="secure-link">
                    <svg
                      width={16}
                      height={16}
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8 0C5.93225 0 4.25 1.7944 4.25 4V6.4H3.5C3.10218 6.4 2.72064 6.56857 2.43934 6.86863C2.15804 7.16869 2 7.57565 2 8V14.4C2 14.8243 2.15804 15.2313 2.43934 15.5314C2.72064 15.8314 3.10218 16 3.5 16H12.5C12.8978 16 13.2794 15.8314 13.5607 15.5314C13.842 15.2313 14 14.8243 14 14.4V8C14 7.57565 13.842 7.16869 13.5607 6.86863C13.2794 6.56857 12.8978 6.4 12.5 6.4H11.75V4C11.75 1.7944 10.0677 0 8 0ZM5.75 4C5.75 2.6768 6.7595 1.6 8 1.6C9.2405 1.6 10.25 2.6768 10.25 4V6.4H5.75V4ZM8.75 12.5784V14.4H7.25V12.5784C6.98779 12.4182 6.77724 12.1771 6.64612 11.8867C6.515 11.5963 6.46946 11.2704 6.51551 10.9518C6.56156 10.6333 6.69704 10.3371 6.90407 10.1023C7.11111 9.86759 7.37999 9.70527 7.67525 9.6368C7.89456 9.58508 8.12196 9.58652 8.34068 9.64103C8.5594 9.69554 8.76386 9.80172 8.93899 9.95174C9.11411 10.1018 9.25543 10.2918 9.35253 10.5078C9.44963 10.7239 9.50002 10.9604 9.5 11.2C9.49957 11.4798 9.43002 11.7545 9.29835 11.9965C9.16668 12.2385 8.97754 12.4392 8.75 12.5784Z"
                        fill="#72819B"
                      />
                    </svg>
                    Secure checkout
                  </div>
                </div>
              </div>
            </div>
            <BottomTab activeScreen="dashboard" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProcessedToPayment;
