import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';

export default function PrivateUserRoute({
  component: Component,
  redirectUrl,
  pageTitle,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) =>
        Cookies.get('token') ? (
          <Component {...rest} {...props} />
        ) : (
          <Redirect
            to={{ pathname: redirectUrl, state: { from: props.location } }}
          />
        )
      }
      // render={(props) => <Component {...rest} {...props} />}
    />
  );

  // return <Route render={props=><Component {...rest} {...props}/>}/>
}
