import React, { useEffect, useState } from "react";
import ActionListItem from "../../Common/actionList/actionListItem";
import AxiosApi from "../../Config/axios-api";
import Cookies from "js-cookie";
import Filter from "../../assets/images/filter.png";
import BottomTab from "../../Common/BottomTab";
import Profileheadermobile from "../../Common/Profileheadermobile";
import { useSelector, useDispatch } from "react-redux";
import { ListCreationRoute } from "../../Routing/RouteConstants";
import { USER_ACTION_LIST_LIST_SUCCESS } from "../../Store/Constants/userConstants";
import UnloginuserHead from "../../Common/UnloginuserHead";
import { getUserDetailsInfo } from "../../Store/Actions/userActions";
import { ToastContainer, toast } from "react-toastify";
import "../../../node_modules/react-toastify/dist/ReactToastify.min.css";
import { stringCapitalize } from "../../services/stringModify";
import ListPreview from "../../assets/images/list_preview.svg";
import location from "../../assets/images/location.svg";

import $ from "jquery";
import { useHistory } from "react-router";

function MyList(props) {
  let [myList, setMylist] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const userDetailsError = useSelector((state) => state.UserDetails?.error);
  let user = Cookies.get("userInfo");
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const [userInfoData, setUserDetails] = useState();
  const [savedList, setSsaveList] = useState([]);

  useEffect(() => {
    sliderfun();
  }, [savedList]);

  const sliderfun = () => {
    $(".saved-list-slider").slick({
      centerMode: false,
      slidesToShow: 3,
      infinite: false,
      dots: true,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            arrows: false,
            centerMode: false,
            centerPadding: "0%",
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            centerMode: false,
            centerPadding: "0%",
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: "0%",
            slidesToShow: 1.1,
          },
        },
      ],
    });
  };

  useEffect(onMount, []);

  function onMount() {
    localStorage.removeItem("selectedtheme");

    if (userDetailsError?.invalidToken) {
      Cookies.set("token", "");
      Cookies.set("userInfo", null);
      localStorage.setItem("userInfo", null);
      props.history.push("/login");
    } else {
      getActionList();
      getuserDetails();
    }
  }

  async function getActionList() {
    try {
      const { data } = await AxiosApi.get(
        "/actionList/getActionListByUserId/" + userInfo.userId,
        {
          headers: {
            token: Cookies.get("token"),
          },
        }
      );
      setMylist(data);
      console.log({ data });
      //getSavedList(data);
    } catch (error) {}
  }
  const getSavedList = async (item) => {
    try {
      setSsaveList([]);
      let data = [];
      var obj = {};
      obj.actionListIds = item?.savedActionList;
      data = await AxiosApi.post(
        `/actionList/getSavedActionList?userId=${userInfo?.userId}`,
        obj
      );
      console.log("mylistsave", data.data);

      await setSsaveList(data.data);
    } catch (error) {}
  };

  const getuserDetails = async () => {
    dispatch(getUserDetailsInfo(userInfo?.userId)).then((res) => {
      if (res?.data) {
        setUserDetails(res?.data);
        // getSavedList(res?.data);
      }
    });
    // const { data } = await AxiosApi.get(
    //   '/userDetailsById/' + userInfo?.userId,
    //   {}
    // );
    // setUserDetails(data);
    // getSavedList(data);
  };
  function gotoNeeds(e, id) {
    e.preventDefault();

    redirectlist(id);
  }
  const redirectlist = (item) => {
    dispatch({
      type: USER_ACTION_LIST_LIST_SUCCESS,
      payload: item,
    });
    localStorage.setItem("selectedListName", item.title);
    localStorage.setItem("selectedList", JSON.stringify(item));
    // props.history.push('/needs/' + item.actionListId);
    props.history.push("/manage/" + item.actionListId);
    // props.history.push('/l/' + item.actionListId);
  };

  return (
    <>
      {Cookies.get("token") ? (
        <Profileheadermobile history={props.history} active="list" />
      ) : (
        <UnloginuserHead history={props.history} />
      )}
      <div className="content dashboard mylist mylist-height">
        <div className="container">
          <div className="section2">
            <div className="header-row">
              <div className="desktop-wrapper">
                <div className="left">
                  <h3 className="subhead">My Lists</h3>
                </div>
                <div className="right">
                  <button
                    type="button"
                    // className='btn btn-prime desktop-view'
                    className={
                      userInfo?.allowCreateActionLIst
                        ? "btn btn-prime desktop-view"
                        : "btn btn-prime desktop-view disabled-btnbg"
                    }
                    onClick={() => {
                      if (userInfo?.allowCreateActionLIst) {
                        props.history.push(ListCreationRoute);
                      } else {
                        toast.error(
                          "You don't have permissions to create ActionList...!"
                        );
                      }
                    }}
                  >
                    New ActionList
                  </button>
                  {/* <button type='button' className='btn-filter'>
                    <img src={Filter} alt='' />
                  </button> */}
                </div>
              </div>
              {myList.length != 0 && (
                <div className="mobile-wrapper mobile-view">
                  <button
                    type="button"
                    className="btn btn-prime"
                    onClick={() => props.history.push(ListCreationRoute)}
                  >
                    New ActionList
                  </button>
                </div>
              )}
            </div>

            {myList.length === 0 && (
              <div className="mylist-empty">
                <div className="empty-msg">
                  <h2>You haven’t created any lists yet :(</h2>
                  <button
                    type="button"
                    className="btn btn-prime mobile-view"
                    onClick={() => props.history.push(ListCreationRoute)}
                  >
                    New ActionList
                  </button>
                </div>
              </div>
            )}

            {myList.length > 0 &&
              myList.map((list) => {
                return (
                  <ActionListItem
                    //  saveActionList={(item) => saveActionList(item)}
                    key={list._id}
                    theme={list.themeName}
                    id={list.actionListId}
                    name={list.title}
                    status={list.actionListStatus}
                    needsCount={list.needsCount}
                    needsDone={list.done}
                    list={list}
                    actionTaken={list.actionTaken}
                    click={gotoNeeds}
                    userInfoData={userInfoData}
                    getActionListApiCall={() => getActionList()}
                  />
                );
              })}
            <BottomTab activeScreen={"my-list"} />
          </div>
        </div>
        <ToastContainer autoClose={3000} />
      </div>
    </>
  );
}

export default MyList;
