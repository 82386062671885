import React, { useRef, useState, useEffect } from "react";
import goBackImage from "../../assets/images/back_black.png";
import profilePic from "../../assets/images/account_profile_pic.png";
import Profileheadermobile from "../../Common/Profileheadermobile";
import ProfileLeftPane from "../../Common/ProfileLeftPane";
import {
  getUserDetailsInfo,
  logout,
  updateUserDetailsInfo,
  getWishList,
} from "../../Store/Actions/userActions";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import BottomTab from "../../Common/BottomTab";
import AxiosApi from "../../Config/axios-api";
import Cookies from "js-cookie";
import UnloginuserHead from "../../Common/UnloginuserHead";
import { CropImage } from "../../services/cropImage";
import { isMobile } from "react-device-detect";

const AccountSettings = ({ ...props }) => {
  const { innerWidth: width, innerHeight: height } = window;
  const dispatch = useDispatch();
  const [profilePicture, setProfilePicture] = useState(profilePic);
  const [username, SetUserName] = useState();
  const [pnoneNumber, setPhoneNumber] = useState();
  const [email, setEmail] = useState();
  const userSignin = useSelector((state) => state.userSignin);
  const { loading: userloading, userInfo, error: usererror } = userSignin;
  const [errormodel, seterrormodel] = React.useState({
    username: false,
    email: false,
    pnoneNumber: false,
    usermessage: "",
    emailmessage: "",
    phonemessage: "",
  });
  const [refImg, setImgRef] = useState();

  const openImagePicker = () => {
    if (refImg) {
      refImg.click();
    }
  };

  const UserDetails = useSelector((state) => state.UserDetails);
  const { loading, getuserInfo, error } = UserDetails;
  const updateUserDetails = useSelector((state) => state.updateUserDetails);
  const [updatemsg, setupdatemsg] = useState("");
  const {
    loading: updateloading,
    updateuserInfo,
    error: updateerror,
  } = updateUserDetails;
  const [errormessageupdate, setErrorMsg] = useState("");
  const ImagebaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;
  const userDetailsError = useSelector((state) => state.UserDetails?.error);
  const [imageFilll, setImageFilll] = useState(null);
  const [imageFileWithoutUrl, setImageFileWithoutUrl] = useState(null);
  useEffect(() => {
    if (userDetailsError?.invalidToken) {
      Cookies.set("token", "");
      Cookies.set("userInfo", null);
      localStorage.setItem("userInfo", null);
      props.history.push("/login");
    } else {
      dispatch(getUserDetailsInfo(userInfo?.userId));
    }
  }, []);

  useEffect(() => {
    if (getuserInfo) {
      SetUserName(getuserInfo.firstName + " " + getuserInfo.lastName);
      if (getuserInfo.phoneNumber) {
        setPhoneNumber(getuserInfo.phoneNumber);
      }
      setEmail(getuserInfo.email);
    }
    if (error?.msg) {
      setErrorMsg(error?.msg);
      error.msg = "";
    }
    return () => {};
  }, [getuserInfo, error]);

  useEffect(() => {
    if (updateuserInfo?.msg) {
      setupdatemsg(updateuserInfo?.msg);
      updateuserInfo.msg = "";
      setTimeout(function () {
        setupdatemsg("");
      }, 3000);
    }
    if (updateerror?.msg) {
      setupdatemsg(updateerror?.msg);
      setTimeout(function () {
        setupdatemsg("");
      }, 3000);
      updateerror.msg = "";
    }
    return () => {};
  }, [updateuserInfo, updateerror]);

  const handleFileSelect = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      console.log(e.target.files[0]);
      setImageFileWithoutUrl(e.target.files[0]);
      reader.readAsDataURL(e.target.files[0]);
      reader.addEventListener("load", () => {
        setImageFilll(reader.result);
      });
    }

    // const file = e.target.files[0];
    // let formData = new FormData();
    // formData.append('userImage', file);
    // const { data } = await AxiosApi.post(
    //   '/setUserImage/' + userInfo?.userId,
    //   formData
    // );
    // if (data.msg === 'user image updated') {
    //   dispatch(getUserDetailsInfo(userInfo?.userId));
    // }
  };

  const submitChanges = () => {
    var validationform = {
      username,
      pnoneNumber,
      email,
    };
    let datavalidation = validationfun(validationform);
    if (datavalidation) {
      return;
    }
    var obj = {};
    const splittedName = username.split(" ");
    const firstName = splittedName[0];
    // removing first Name
    splittedName.splice(0, 1);
    const lastName = splittedName.join(" ").trim() || " ";
    obj.userId = userInfo.userId;
    obj.firstName = firstName;
    obj.lastName = lastName;
    obj.email = email;
    console.log(pnoneNumber);
    if (pnoneNumber) obj.phoneNumber = pnoneNumber;
    else obj.phoneNumber = " ";
    console.log(obj);

    // obj.blocked = true;
    obj.userType = "organization";
    dispatch(updateUserDetailsInfo(obj));
  };

  const validationfun = (validationform) => {
    let showallerror = false;
    var mailformat =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    var phoneno = /^\d{10}$/;
    var form = {
      username: false,
      email: false,
      pnoneNumber: false,
      usermessage: "",
      emailmessage: "",
      phonemessage: "",
    };
    if (
      validationform.username === undefined ||
      validationform.username === ""
    ) {
      showallerror = true;
      form.username = true;
      form.usermessage = "Please Enter your Full Name";
    }
    if (validationform.email === undefined || validationform.email === "") {
      showallerror = true;
      form.email = true;
      form.emailmessage = "Please Enter your Email";
    } else if (!validationform.email.match(mailformat)) {
      showallerror = true;
      form.email = true;
      form.emailmessage = "Please Enter your valid Email";
    }
    if (
      validationform.pnoneNumber === undefined ||
      validationform.pnoneNumber === " " ||
      validationform.pnoneNumber === ""
    ) {
      showallerror = false;
      form.pnoneNumber = false;
      form.phonemessage = "";
    } else if (!validationform.pnoneNumber.match(phoneno)) {
      showallerror = true;
      form.pnoneNumber = true;
      form.phonemessage = "Please, enter your valid phone number";
    }
    seterrormodel(form);
    return showallerror;
  };

  const deleteImage = async () => {
    const { data } = await AxiosApi.delete(
      "/removeImage/" + userInfo?.userId,
      {}
    );
    if (data.msg === "user image deleted!!") {
      // setupdatemsg(data.msg);
      setupdatemsg("User image deleted");

      setTimeout(function () {
        setupdatemsg("");
      }, 3000);
      dispatch(getUserDetailsInfo(userInfo?.userId));
    }
  };

  const deletemyAccount = async () => {
    const { data } = await AxiosApi.delete(
      "/removeUser/" + userInfo?.userId,
      {}
    );
    if (data.msg === "User removed.") {
      window.$("#deleteAccountModal").modal("hide");
      Cookies.remove("token");
      Cookies.remove("verified");
      dispatch(logout());
      dispatch(getWishList([]));
      props.history.push("/");
    }
  };

  const handleProfilePic = async (img) => {
    let response = await fetch(img);
    let imgData = await response.blob();
    let metadata = {
      type: "image/jpeg",
    };
    let file = new File([imgData], imageFileWithoutUrl?.name, metadata);

    let formData = new FormData();
    formData.append("userImage", file);
    const { data } = await AxiosApi.post(
      "/user/setUserImage/" + userInfo?.userId,
      formData
    );
    if (data.msg === "user image updated") {
      dispatch(getUserDetailsInfo(userInfo?.userId));
      setImageFilll(null);
      setImageFileWithoutUrl(null);
      setImgRef(null);
      refImg.value = "";
    }
  };

  const handleCancelProfilePic = (img) => {
    setImageFilll(null);
    setImgRef(null);
    refImg.value = "";
    setImageFileWithoutUrl(null);
  };

  return (
    <div>
      {!isMobile &&
        (Cookies.get("token") ? (
          <Profileheadermobile history={props.history} />
        ) : (
          <UnloginuserHead history={props.history} />
        ))}
      <div className="content profile">
        <ProfileLeftPane activePage="account-settings" />
        <div className="tab-content" id="settingsTabContent">
          <div
            className="tab-pane fade show active"
            id="account-settings"
            role="tabpanel"
            aria-labelledby="account-settings-tab"
          >
            <div className="mobile-header">
              <nav className="navbar">
                <div onClick={() => props.history.goBack()} className="back">
                  <img src={goBackImage} alt="go-back" />
                </div>
                <label className="navbar-brand mx-auto middle-label">
                  Account settings
                </label>
              </nav>
            </div>
            <div className="inner-content">
              <label className="holder-name desktop">
                {getuserInfo?.firstName && getuserInfo?.firstName + " "}
                {getuserInfo?.lastName && getuserInfo?.lastName}
              </label>
              <div
                className="holder-pic-row"
                style={{ marginTop: width > 768 && 20 }}
              >
                <div className="profile-pic">
                  {getuserInfo?.userImage != undefined &&
                  getuserInfo?.userImage != "" ? (
                    <img
                      src={ImagebaseUrl + getuserInfo?.userImage}
                      alt="Profile-pic"
                    />
                  ) : (
                    <img
                      src={ImagebaseUrl + "1628062524365avatar.png"}
                      alt="Profile-pic"
                    />
                  )}
                </div>
                <label className="holder-name mobile">{username}</label>
                <input
                  type="file"
                  ref={(newRef) => setImgRef(newRef)}
                  onChange={handleFileSelect}
                  accept="image/*"
                  style={{ display: "none" }}
                />
                <button
                  type="button"
                  className="btn btn-extra"
                  onClick={openImagePicker}
                >
                  Add Photo
                </button>
                <span className="link-extra" onClick={deleteImage}>
                  Delete Photo
                </span>
              </div>
              <div
                style={{
                  marginTop: 20,
                  // textAlign: 'center',
                  color: updatemsg?.includes("user details updated")
                    ? "green"
                    : updatemsg?.includes("User image deleted")
                    ? "green"
                    : "red",
                }}
              >
                {updatemsg}
              </div>
              <div className="warningmessage">{errormessageupdate}</div>
              <form
                className="personal-info"
                style={{ marginTop: width > 768 && 18 }}
              >
                <h2>Personal information</h2>
                <div className="form-group">
                  <label for="fullName">Full Name</label>
                  <input
                    type="text"
                    className={`form-control ${
                      errormodel.username && "errorMessage"
                    }`}
                    id="fullName"
                    placeholder="Melinda Johnson"
                    value={username}
                    onChange={(e) => SetUserName(e.target.value)}
                  />
                  {errormodel.username && (
                    <div className="warningmessage">
                      {errormodel.usermessage}
                    </div>
                  )}
                </div>
                <div className="form-group row">
                  <div className="col-xl-6 col-lg-6">
                    <label for="phoneNumber" className="label">
                      Phone Number
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        errormodel.pnoneNumber && "errorMessage"
                      }`}
                      id="phoneNumber"
                      placeholder="+1 (045) 456-361-46"
                      value={pnoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                    {errormodel.pnoneNumber && (
                      <div className="warningmessage">
                        {errormodel.phonemessage}
                      </div>
                    )}
                  </div>
                  <div className="col-xl-6 col-lg-6">
                    <label for="email" className="label">
                      Email
                    </label>
                    <input
                      readOnly
                      type="text"
                      className={`form-control ${
                        errormodel.email && "errorMessage"
                      }`}
                      id="email"
                      placeholder=""
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {errormodel.email && (
                      <div className="warningmessage">
                        {errormodel.emailmessage}
                      </div>
                    )}
                  </div>
                </div>

                <button
                  type="button"
                  className="btn btn-prime"
                  onClick={submitChanges}
                >
                  Save Changes
                </button>
              </form>
              <div
                className="bottom-panel pb-3"
                style={{ position: "inherit", padding: "0 0 0 0" }}
              >
                <div
                  className="inner"
                  style={{
                    paddingBottom: width > 768 && 0,
                    padding: "0 0 0px 0px",
                    marginTop: 20,
                  }}
                >
                  <button
                    type="button"
                    className="link-extra"
                    onClick={() =>
                      window.$("#deleteAccountModal").modal("show")
                    }
                    // onClick={deletemyAccount}
                  >
                    Delete Account
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="payment-methods"
            role="tabpanel"
            aria-labelledby="payment-methods-tab"
          >
            Payment Methods
          </div>
          <div
            className="tab-pane fade"
            id="email-notifications"
            role="tabpanel"
            aria-labelledby="email-notifications-tab"
          >
            Email Notifications
          </div>
          <div
            className="tab-pane fade"
            id="password-reset"
            role="tabpanel"
            aria-labelledby="password-reset-tab"
          >
            Password reset
          </div>
        </div>

        <BottomTab activeScreen={"profile"} />
      </div>

      <div
        class="modal list-creation-success-modal"
        tabindex="-1"
        role="dialog"
        id="deleteAccountModal"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <a
                class="modal-title"
                onClick={() => window.$("#deleteAccountModal").modal("hide")}
              >
                <img src="images/logo.svg" />
              </a>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => window.$("#deleteAccountModal").modal("hide")}
              >
                <img src="images/close2.png" alt="close" />
              </button>
            </div>
            <div class="modal-body">
              <div class="congratulation_content">
                <h3 class="heading3">
                  Are you sure Want to Delete your Account
                </h3>
                <button
                  type="button"
                  class="btn_primary"
                  onClick={deletemyAccount}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {imageFilll && (
        <CropImage
          imageFilll={imageFilll}
          handleProfileImg={(img) => handleProfilePic(img)}
          handleCancelProfileImg={() => handleCancelProfilePic()}
        />
      )}
    </div>
  );
};

export default AccountSettings;
