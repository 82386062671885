import React, { useState, useEffect } from "react";
import Disclaimer from "../../Layout/GuestUser/Disclaimer";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { signin } from "../../Store/Actions/userActions";
import { DashboardRoute } from "../../Routing/RouteConstants";
import MobileScreenLogo from "../../Common/MobileScreenLogo";
import DeskTopHeader from "../../Common/DeskTopHeader";
import { USER_SIGNIN_FAIL } from "../../Store/Constants/userConstants";
import logoWhiteDesktop from "../../assets/images/logo_white_desktop.svg";
import visibleIcon from "../../assets/images/view_enabled.png";
import hideIcon from "../../assets/images/view_disabled.png";
import { ToastContainer, toast } from "react-toastify";
import "../../../node_modules/react-toastify/dist/ReactToastify.min.css";

const Login = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const userSignin = useSelector((state) => state.userSignin);
  const { loading, userInfo, error } = userSignin;
  const [passwordShown, setPasswordShown] = useState(false);
  const [password, setPassword] = useState();
  const { register, handleSubmit, reset, errors } = useForm();
  const userDetailsError = useSelector((state) => state.UserDetails?.error);

  useEffect(() => {
    dispatch({ type: USER_SIGNIN_FAIL, payload: [] });
  }, []);

  useEffect(() => {
    // if (userDetailsError?.invalidToken) {
    //   toast.error("Your Session has Expired. Please Login Again.");
    // }
    if (userInfo) {
      if (userInfo.verified) {
        history.push(DashboardRoute);
      } else {
        history.push("/email-verification");
      }
      // window.location.reload();
    }
    return () => {
      //
    };
  }, [userInfo]);

  const onSubmit = (data) => {
    dispatch({ type: USER_SIGNIN_FAIL, payload: [] });
    dispatch(signin(data.email.toLowerCase(), data.password));
  };

  const togglePasswordVisiblity = () =>
    setPasswordShown(passwordShown ? false : true);

  return (
    <div>
      <MobileScreenLogo navlink="Sign up" linkdata="/discover" />
      <div class="content signup_content">
        <div className="logo-desktop ml-0 ml-xl-3 ml-lg-3 ml-md-2">
          <Link to="/discover">
            <img src={logoWhiteDesktop} />
          </Link>
        </div>
      </div>
      <div className="right-signupcontent">
        <div className="login-link">
          <DeskTopHeader
            linkdata="/signup"
            displayContent="Don’t have an account?"
            navlink="Sign up"
          />
        </div>

        <div class="row">
          <div class="col-12">
            <form
              class="signup_form custom_Form_View"
              onSubmit={handleSubmit(onSubmit)}
            >
              <h2 class="heading2 signup-head">Log in</h2>
              <div className="form-group">
                {error?.errors?.length && (
                  <div className="error-box">
                    {<p className="incorrect">{error.errors[0].msg}</p>}
                  </div>
                )}

                <label htmlFor="email">Your email address</label>
                <input
                  type="input"
                  className={
                    errors.email ? "form-control invalid" : "form-control"
                  }
                  name="email"
                  ref={register({
                    required: "Please, enter your email",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Enter a valid email address",
                    },
                  })}
                  placeholder=""
                />
                {/* {errors?.email?.type !== 'required' && (
                  <button
                    type='button'
                    onClick={() => reset({ email: '' })}
                    className='close_button'
                  ></button>
                )} */}
              </div>
              {errors.email && (
                <div className="error-box">
                  {<p className="incorrect">{errors.email.message}</p>}
                </div>
              )}
              <div className="form-group">
                <label htmlFor="createpassword">Password</label>
                <input
                  type={passwordShown ? "text" : "password"}
                  className={
                    errors.password ? "form-control invalid" : "form-control"
                  }
                  name="password"
                  ref={register({ required: "Enter your password" })}
                  placeholder=""
                  onChange={(e) => setPassword(e.target.value)}
                />
                {password && (
                  <button
                    type="button"
                    style={
                      passwordShown
                        ? { backgroundImage: `url(${visibleIcon})` }
                        : { backgroundImage: `url(${hideIcon})` }
                    }
                    onClick={togglePasswordVisiblity}
                    className="view_button view_button1"
                  ></button>
                )}
              </div>
              {errors.password && (
                <div className="error-box">
                  {<p className="incorrect">{errors.password.message}</p>}
                </div>
              )}
              {error?.msg && (
                <div className="error-box">
                  {
                    <p className="incorrect">
                      {error?.msg === "Please check your email and password!"
                        ? "There is no account behind this email address. Please, check out your login info"
                        : error?.msg}
                    </p>
                  }
                </div>
              )}
              <button type="submit" className="btn btn-primary">
                Log in
              </button>
              <div
                className="forgot-link text-center"
                style={{ textAlign: "center" }}
              >
                <Link className="login" to="/forgot-password">
                  Forgot password?
                </Link>
              </div>
              <Disclaimer />
            </form>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={3000} />
    </div>
  );
};

export default Login;
