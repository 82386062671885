import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Back from '../../../assets/images/back.png';
import help from '../../../assets/images/help.png';
export default function WithDrawBenificial(props) {
  return (
    <div
      class='tab-pane fade show active'
      id='withdraw-funds'
      role='tabpanel'
      aria-labelledby='withdraw-funds-tab'
    >
      <div class='mobile-header-inner'>
        <nav class='navbar'>
          <a onClick={props.backdata} class='back'>
            <img src={Back} />
          </a>
          <label class='navbar-brand mx-auto middle-label'>
            Withdraw funds
          </label>
        </nav>
      </div>
      <form class='withdrawal-form form-2' style={{ marginTop: 0 }}>
        <h2 class='heading2'>Are you sure?</h2>
        <p class='para'>
          You are about to give away access to ALL of this campaign’s money to:
        </p>

        <div class='user-detail'>
          <label class='name'>Jason Melrose</label>
          <label class='email'>jason.melrose@gmail.com</label>
        </div>

        <button type='submit' onClick={props.nextclick} class='btn btn-prime'>
          Invite beneficiary
        </button>
        <a href='#' class='link-extra'>
          Cancel
        </a>
      </form>
    </div>
  );
}
