import React from 'react';
import BackBlack from '../assets/images/back_black.png';
import FilterBlack from '../assets/images/filter_black.png';

export default function MobileHeaderTwo({
  pageTitle,
  previousPage,
  filter,
  filterClose,
  selectedTheme,
  ...rest
}) {
  return (
    <div className={`${selectedTheme}-custom-theme mobile-header-inner`}>
      <nav className='navbar'>
        {previousPage ? (
          <div
            className='back'
            {...rest}
            onClick={(e) => rest.history.push(previousPage)}
          >
            <img src={BackBlack} alt='Go back' />
          </div>
        ) : null}
        <label className='navbar-brand mx-auto middle-label'>{pageTitle}</label>
        {filter ? (
          <div href='#.' className='filter'>
            <img src={FilterBlack} alt='' onClick={(e) => filterClose()} />
          </div>
        ) : null}
      </nav>
    </div>
  );
}
