import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Default from '../../../assets/images/themes/theme-default.png';
import WaterPolo from '../../../assets/images/themes/theme-waterpolo.png';
import Greenery from '../../../assets/images/themes/theme-greenery.png';
import Tokyo from '../../../assets/images/themes/theme-tokyo.png';
import Fair from '../../../assets/images/themes/theme-fair.png';
import Dawn from '../../../assets/images/themes/theme-dawn.png';
import Back from '../../../assets/images/back.png';
import Close from '../../../assets/images/close2.png';
import ListCreationMobileHeader from '../../../Common/MobileHeaderOne';
import { useSelector, useDispatch } from 'react-redux';
import { createList,deleteList } from '../../../Store/Actions/userActions';
import UserDeskTopHeaderOne from '../../../Common/UserDeskTopHeaderOne';
import Profileheadermobile from "../../../Common/Profileheadermobile";

import $ from 'jquery';
import 'slick-carousel';  
import Cookies from 'js-cookie';
import { isMobile } from "react-device-detect";
export default function ListCreationSelectTheam(props) {
  const [theme, setTheme] = useState("sunshine");
  let { register, handleSubmit } = useForm();
  let dispatch = useDispatch();
  const List = useSelector((state) => state.createList);
  const { userCreateList, successCreateList } = List;

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
 
  useEffect(() => {
    let themeName  = localStorage.getItem('themeName')
    if (window.performance) {
      if (performance.navigation.type == 1) {
        setTheme('sunshine')
      } else {
          if(themeName){
            setTheme(themeName)
          }else{
            setTheme("sunshine")
          }
        // alert( "This page is not reloaded");
      }
    }
    $('.themes-slider').slick({
      centerMode: false,
      centerPadding: '0%',
      slidesToShow: 6,
      infinite: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: '0%',
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: '0%',
            slidesToShow: 1.5,
          },
        },
      ],
    });
  }, []);

  useEffect(() => {
    if (successCreateList) {
      console.log('successCreateList', successCreateList);
      var urldata = '/needs/' + successCreateList.actionListId;
      localStorage.setItem('selectedListName', successCreateList.title);
      localStorage.setItem('selectedtheme', theme);
      localStorage.setItem('NewList', successCreateList.actionListId);
      props.history.push(urldata);
    }
  }, [successCreateList]);

  let submit = (data) => {
    console.log("data",userCreateList)
    let list = {
      ...userCreateList,
      ...data,
      userId: userInfo?.userId,
      userName: userInfo?.firstName + ' ' + userInfo?.lastName,
      actionListStatus: "active",
      userType: userInfo?.userType
    };
     console.log('list--->', list);
    dispatch(createList(list))
    // props.history.push('/needs/5r_-jWiXv');
    // props.history.push('/me/create/step1-success-screen');
  };
  
  const closeButtonFunc = () => {
    let list = {
      ...userCreateList,
      userId: userInfo?.userId,
      userName: userInfo?.firstName + ' ' + userInfo?.lastName,
      actionListStatus: "draft",
      userType: userInfo?.userType
    };
    dispatch(createList(list))
    dispatch(deleteList());
    props.history.push('/me/lists')
  }

  
  return (
    <div>
      {/* <UserDeskTopHeaderOne history={props.history} /> */}
      {isMobile ? "" : <Profileheadermobile history={props.history} />}
      <ListCreationMobileHeader
        pageTitle='Create new ActionList'
        previousPage='/me/create/step4'
        close='/account-created'
        history={props.history}
      />

      <div className='content'>
        <div
          className='content-top-header theme-page-header'
          style={{ marginBottom: 100 }}
        >
          <a
            onClick={() =>
              props.history.push('/me/create/step4')
            }
            className='back_link cursor-pointer'
          >
            <img src={Back} alt='back' />
            Back
          </a>
          <a
            onClick={() => closeButtonFunc()}
            className='ml-auto'
          >
            <img src={Close} alt='close' />
          </a>
        </div>
        {/* <label className='page-subhead'>Create new ActionList</label> */}
        <h2 className='heading2'>Step 5 of 5: Select theme</h2>

        <div className='row'>
          <div className='col-12'>
            <form
              className='theme-form custom_theme_form'
              onSubmit={handleSubmit(submit)}
            >
              <div className='slider themes-slider'>
                <div>
                  <label htmlFor='themedefault'>
                    <input
                      type='radio'
                      name='theme'
                      id='themedefault'
                      value='sunshine'
                      checked = {theme === 'sunshine'}
                      defaultChecked
                      ref={register}
                      onChange={(e) => setTheme(e.target.value)}
                    />
                    <img src={Default} alt='default' />
                  </label>
                </div>
                <div>
                  <label htmlFor='themewaterpolo'>
                    <input
                      type='radio'
                      name='theme'
                      id='themewaterpolo'
                      value='waterpolo'
                      checked = {theme === 'waterpolo'}
                      ref={register}
                      onChange={(e) => setTheme(e.target.value)}
                    />
                    <img src={WaterPolo} alt='waterpolo' />
                  </label>
                </div>
                <div>
                  <label htmlFor='themegreenery'>
                    <input
                      type='radio'
                      name='theme'
                      id='themegreenery'
                      checked = {theme === 'greenery'}
                      value='greenery'
                      ref={register}
                      onChange={(e) => setTheme(e.target.value)}
                    />
                    <img src={Greenery} alt='greenery' />
                  </label>
                </div>
                <div>
                  <label htmlFor='themetokyo'>
                    <input
                      type='radio'
                      name='theme'
                      id='themetokyo'
                      value='blossom'
                      checked = {theme === 'blossom'}
                      ref={register}
                      onChange={(e) => setTheme(e.target.value)}
                    />
                    <img src={Tokyo} alt='tokyo' />
                  </label>
                </div>
                <div>
                  <label htmlFor='themefair'>
                    <input
                      type='radio'
                      name='theme'
                      id='themefair'
                      value='fair'
                      checked = {theme === 'fair'}
                      ref={register}
                      onChange={(e) => setTheme(e.target.value)}
                    />
                    <img src={Fair} alt='fair' />
                  </label>
                </div>
                {console.log(props.history)}
                <div>
                  <label htmlFor='themedawn'>
                    <input
                      type='radio'
                      name='theme'
                      id='themedawn'
                      value='dawn'
                      checked = {theme === 'dawn'}
                      ref={register}
                      onChange={(e) => setTheme(e.target.value)}
                    />
                    <img src={Dawn} alt='dawn' />
                  </label>
                </div>
              </div>
              <button
                type='button'
                className='btn btn-extra'
                onClick={() => {
                  switch (theme) {
                    case 'sunshine':
                      props.history.push(
                        '/me/create/preview/sunshine'
                      );
                      break;
                    case 'waterpolo':
                      props.history.push(
                        '/me/create/preview/waterpolo'
                      );
                      break;
                    case 'greenery':
                      props.history.push(
                        '/me/create/preview/greenery'
                      );
                      break;
                    case 'blossom':
                      props.history.push('/me/create/preview/blossom');
                      break;
                    case 'fair':
                      props.history.push('/me/create/preview/fair');
                      break;
                    case 'dawn':
                      props.history.push('/me/create/preview/dawn');
                      break;
                    default:
                      break;
                  }
                }}
              >
                Preview
              </button>
              <button type='submit' className='btn btn-primary'>
                Create new List
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
