import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Back from "../../../assets/images/back.png";
import AxiosApi from "../../../Config/axios-api";
import { ToastContainer, toast } from "react-toastify";
import "../../../../node_modules/react-toastify/dist/ReactToastify.min.css";
import { stringCapitalize } from "../../../services/stringModify";
import { useHistory } from "react-router";

export default function Editlistdetails(props) {
  const dispatch = useDispatch();

  let history = useHistory();
  const [listTitle, setListTitle] = useState("");
  const [purposeReason, setPurposeReason] = useState("");
  const [listDec, setListdec] = useState("");
  const [purposeList, setPurposeList] = useState([
    { Name: "Advocacy", val: "advocacy" },
    { Name: "Animals and Pets", val: "animals and pets" },
    { Name: "Arts and Culture", val: "arts and culture" },
    { Name: "Crisis Support", val: "crisis support" },
    { Name: "Community and Local", val: "community and local" },
    { Name: "Education", val: "education" },
    { Name: "Environment", val: "environment" },
    { Name: "Events", val: "events" },
    { Name: "Faith", val: "faith" },
    { Name: "Friends and Family", val: "friends and family" },
    {
      Name: "Emergency and Disaster Relief",
      val: "emergency and disasterrelief",
    },
    { Name: "Health and Medical", val: "health and medical" },
    { Name: "Social Justic", val: "social justice" },
    { Name: "Sports and Recreation", val: "sports and recreation" },
    { Name: "Other", val: "other" },
  ]);
  useEffect(() => {
    var value = props.selectedaction;
    setListTitle(value?.title);
    var findIndex = purposeList.findIndex((item) => item.val == value?.purpose);
    setPurposeReason(findIndex);
    if (props?.newData?.location?.state?.data == "editneed") {
      setListdec(props?.newData?.location?.state?.detail);
    } else {
      setListdec(value?.listDesc);
    }
  }, [props?.selectedaction]);

  useEffect(() => {
    var value = props.selectedaction;
    if (props?.newData?.location?.state?.data == "editneed") {
      setListdec(props?.newData?.location?.state?.detail);
    } else {
      setListdec(value?.listDesc);
    }
  }, [props?.history?.location?.state?.detail]);

  const updatelist = async () => {
    var obj = {};
    obj.actionListId = props.actionId;
    obj.purpose = purposeList[purposeReason]?.val;
    obj.title = listTitle;
    obj.listDesc = listDec;
    const { data } = await AxiosApi.post(
      "/actionList/updateActionListPurpose",
      obj
    );
    if (data?.msg === "Action list updated!") {
      toast.success(stringCapitalize(data?.msg));
      props.listUpdated();
    }
  };

  function fullPreviewDescription(e) {
    e.preventDefault();
    console.log("object");
    // let list = { ...userCreateList, description: description };
    // dispatch(addDescription(list));
    history.push({
      pathname: "/me/create/step3-full-preview",
      state: { detail: "editneed", id: props.actionId, desc: listDec },
    });
  }
  return (
    <div
      class="tab-pane fade show active"
      id="edit-list"
      role="tabpanel"
      aria-labelledby="edit-list-tab"
      style={{ paddingBottom: "20px !important" }}
    >
      <div class="mobile-header-inner">
        <nav class="navbar">
          <a onClick={props.backdata} class="back">
            <img src={Back} alt="" />
          </a>
          <label class="navbar-brand mx-auto middle-label">
            Edit list info
          </label>
        </nav>
      </div>
      <h2 class="heading2">List information</h2>
      <form>
        <div class="form-group">
          <label for="listTitleInput">Your list title</label>
          <input
            type="text"
            class="form-control"
            id="listTitleInput"
            placeholder="Your list title"
            onChange={(e) => setListTitle(e.target.value)}
            value={listTitle}
            maxLength={50}
          />
          <div class="info d-flex">
            <span class="limit ml-auto">{listTitle?.length}/50</span>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-xl-12 col-lg-12">
            <label for="listTitleInput" class="label">
              The purpose of your list
            </label>
          </div>
          <div class="col-xl-6 col-lg-6">
            {/* <div class='dropdown purpose-dropdown'>
              <button
                class='btn dropdown-toggle'
                type='button'
                id='dropdownMenuButton'
                data-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='false'
              >
                {purposeList[purposeReason]?.Name}
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                  class='ml-auto'
                >
                  <path
                    d='M4 9L12 17L20 9'
                    stroke='#607BFF'
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              </button>
              <div
                class='dropdown-menu'
                aria-labelledby='dropdownMenuButton'
                style={{
                  height: 300,
                  overflowY: 'scroll',
                  transition: 'none',
                  width: 'auto',
                }}
              >
                {purposeList &&
                  purposeList.length > 0 &&
                  purposeList.map((item, index) => {
                    return (
                      <a
                        class='dropdown-item'
                        onClick={() => setPurposeReason(index)}
                      >
                        {item.Name}
                      </a>
                    );
                  })}
              </div>
            </div> */}
            <div class="dropdown purpose-dropdown date-formatnewdrop">
              <button
                class="btn dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                //style={{ color: !timeFormat && '#98a4b8' }}
              >
                {purposeList[purposeReason]?.Name}
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="ml-auto"
                >
                  <path
                    d="M4 9L12 17L20 9"
                    stroke="#607BFF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
              <div
                class="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
                style={{ height: "300px", overflow: "scroll" }}
              >
                {purposeList &&
                  purposeList.length > 0 &&
                  purposeList.map((item, index) => {
                    return (
                      <a
                        class="dropdown-item"
                        onClick={() => setPurposeReason(index)}
                      >
                        {item.Name}
                      </a>
                    );
                  })}
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-lg-6">
            <div class="purpose-info-box">
              <label>The list for: personal reasons</label>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="email">Your story</label>
          <div class="story_description_wrapper">
            <textarea
              class="form-control"
              id="yourStory"
              placeholder="Your story"
              maxlength="400"
              onChange={(e) => setListdec(e.target.value)}
              value={listDec}
            />
            <button
              type="butotn"
              class="expand_button"
              onClick={(e) => fullPreviewDescription(e)}
            ></button>
          </div>
          <div class="info d-flex">
            <span class="mr-auto">You can edit the description later.</span>
            <span class="limit">{listDec?.length}/400</span>
          </div>
        </div>
        <button
          type="button"
          className="btn btn-prime"
          style={{ width: "50%", float: "right", marginBottom: 200 }}
          onClick={(e) => updatelist(e)}
        >
          Save changes
        </button>
      </form>
      <button
        type="button"
        class="btn btn-delete-list"
        data-toggle="modal"
        data-target="#deleteListModal"
        style={{ position: "unset", marginTop: 25, marginBottom: 70 }}
      >
        Delete the list
      </button>
      <ToastContainer autoClose={3000} />
    </div>
  );
}
