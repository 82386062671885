import React, { useState, useEffect } from "react";
import CloseIcon from "../../../../assets/images/close2.png";
import { usePaymentInputs } from "react-payment-inputs";
import { validateCardDetails } from "../../../../utils/cardUtils";
const ModalAddCard = (props) => {
  const cardDetails = props?.cardListDetails?.billingDetails
  const [cardNumber, setCardNumber] = useState();
  const [holderName, setHolderName] = useState();
  const [expirydate, setexpiryDate] = useState();
  const [cvvnum, setCvvnem] = useState();
  const [nameError,setNameError] = useState()

  const [cardError, setCardError] = useState({
    cardNumber: false,
    holderName: false,
    expirydate: false,
    cvvnum: false,
    cardmsg: "",
    holdermsg: "",
    expirymsg: "",
    cvvmsg: "",
  });

  const { meta, getCardNumberProps, getExpiryDateProps, getCVCProps } =
    usePaymentInputs();
  const [primaryPaymentMethodChecked, setPrimaryPaymentMethodChecked] =
    useState(false);

  const setcardeipiryData = (e) => {
    setexpiryDate(e.target.value);
  };

  const handleFormatCardNumber = (e) => {
    if (e.target.value.length <= 20) {
      setCardNumber(e.target.value);
    }
  };

  const handleChangeCVC = (e) => {
    setCvvnem(e.target.value);
  };
  const handleNameChange = (e) => {
    setHolderName(e.target.value);
    if (e.target.value === undefined || e.target.value === "") {
      setNameError("Please Enter your Card Holder Name");
    } else {
      setNameError("");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addnewCardDetails();
    if(meta.error){
      return
    }
    else{
      addnewCardDetails();
    }
  };
  const addnewCardDetails = async() => {
    var validationform = { cardNumber, holderName, expirydate, cvvnum };
    let datavalidation = validateCardDetails(validationform);
    console.log("imp", datavalidation);
    if (datavalidation?.isValid) {
      return;
    }
    var obj = {};

    //if (editStatus) obj.cardId = editcardId;
    //obj.userId = userInfo?.userId;
    obj.cardNumber = cardNumber;
    obj.cardHolderName = holderName;
    obj.expirationDate = expirydate;
    //obj.cvv = cvvnum;

    console.log(obj);

    // const data= await setBillingDetails(obj,props?.actionListId)
    // if(data?.msg){
    //   toast.success(data?.msg)
    // }
    // else{
    //   toast.error("Card Details not saved")
    // }
  };

  const handleClose = () => {
    clearData()
    window.$("#newPaymentMedthodModal").modal("hide")
    clearData()
  }
  const clearData = () => {
    setCardNumber("");
    setHolderName("");
    setexpiryDate("");
    setCvvnem("");
  };

  return (
    <div
      className="modal fade add-payment-billing-modal"
      id="newPaymentMedthodModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered"  role="document">
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            >
              <img src={CloseIcon} />
            </button>
            <div className="content">
              <h4>Add a new payment method</h4>
              <form className="billing-form payment-form" onSubmit={handleSubmit}>
                <div className="form-group row">
                  <div className="col-xl-6 col-lg-6 col-12">
                    <label htmlFor="cardnumber" className="label">
                      Card number
                    </label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0 6C0 5.20435 0.316071 4.44129 0.87868 3.87868C1.44129 3.31607 2.20435 3 3 3H21C21.7956 3 22.5587 3.31607 23.1213 3.87868C23.6839 4.44129 24 5.20435 24 6V7.5H0V6Z"
                            fill="#607BFF"
                          />
                          <path
                            fillRule="evenodd"
                            clip-rule="evenodd"
                            d="M0 10.5V18C0 18.7956 0.316071 19.5587 0.87868 20.1213C1.44129 20.6839 2.20435 21 3 21H21C21.7956 21 22.5587 20.6839 23.1213 20.1213C23.6839 19.5587 24 18.7956 24 18V10.5H0ZM4.5 13.5C4.10218 13.5 3.72064 13.658 3.43934 13.9393C3.15804 14.2206 3 14.6022 3 15V16.5C3 16.8978 3.15804 17.2794 3.43934 17.5607C3.72064 17.842 4.10218 18 4.5 18H6C6.39782 18 6.77936 17.842 7.06066 17.5607C7.34196 17.2794 7.5 16.8978 7.5 16.5V15C7.5 14.6022 7.34196 14.2206 7.06066 13.9393C6.77936 13.658 6.39782 13.5 6 13.5H4.5Z"
                            fill="#607BFF"
                          />
                        </svg>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        id="cardnumber"
                        value={cardNumber ? cardNumber : cardDetails?.cardNumber}
                        {...getCardNumberProps({
                          onChange: handleFormatCardNumber,
                        })}
                        maxLength={20}
                        placeholder="0000 0000 0000 0000"
                      />
                    </div>
                    {meta.touchedInputs.cardNumber &&
                      meta?.erroredInputs?.cardNumber && (
                        <div className="warningmessage">
                          {console.log(meta)}
                          {meta?.erroredInputs?.cardNumber}
                        </div>
                      )}
                  </div>
                  <div className="col-xl-6 col-lg-6 col-12">
                    <label htmlFor="cardholdername" className="label">
                      Cardholder name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="cardholdername"
                      value={holderName ? holderName : cardDetails?.cardHolderName}
                      onChange={handleNameChange}
                      placeholder="JOHN SMITH"
                    />
                  </div>
                  {nameError && (
                    <div className="warningmessage">{nameError}</div>
                  )}
                </div>
                <div className="form-group row">
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6">
                    <label htmlFor="cardholdername" className="label">
                      Expiration date
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="cardholdername"
                      placeholder="MM/YY"
                      value={expirydate ? expirydate : cardDetails?.expirydate}
                      {...getExpiryDateProps({ onChange: setcardeipiryData })}

                      // onKeyUp={(e) => setcardeipiryData(e)}
                      //onChange={(e) => setcardeipiryData(e)}
                    />
                    {meta.touchedInputs?.expiryDate &&
                      meta?.erroredInputs?.expiryDate && (
                        <div className="warningmessage">
                          {meta?.erroredInputs?.expiryDate}
                        </div>
                      )}
                  </div>
                  {/* <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6">
                    <label htmlFor="cardholdername" className="label">
                      CVC/Secure code
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="cardholdername"
                      placeholder="CVC/CVV"
                      value={cvvnum}
                      {...getCVCProps({ onChange: handleChangeCVC })}
                    />
                    {meta.touchedInputs?.cvc && meta?.erroredInputs?.cvc && (
                      <div className="warningmessage">
                        {meta?.erroredInputs?.cvc}
                      </div>
                    )}
                  </div> */}
                </div>
                
                <div className="row button-panel">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                    <button
                      type="button"
                      className="btn-extra"
                      onClick={handleClose
                      }
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                    <button type="submit" 
                    className="btn-prime">
                     Update
                    </button>
                  </div>
                  
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalAddCard;
