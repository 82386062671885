import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import Profileheadermobile from '../../Common/Profileheadermobile';
import UnloginuserHead from '../../Common/UnloginuserHead';
import error500 from '../../assets/images/500.png';
const Error500 = (props) => {
  return (
    <>
      {Cookies.get('token') ? (
        <Profileheadermobile history={props.history} />
      ) : (
        <UnloginuserHead history={props.history} />
      )}
      <div class='content dashboard error'>
        <div class='content-wrapper' style={{ marginTop: 50 }}>
          <div class='error-img-wrapper'>
            <img src={error500} alt='500' />
          </div>
          <p class='error-message'>
            Internal Server Error.
            <br />
            Please, contact your administrator.
          </p>

          <button type='button' class='btn btn-extra'>
            Back to Home
          </button>
        </div>
      </div>
    </>
  );
};

export default Error500;
