import React from "react";
import "./style.css";
import "./responsive.css";
import Buysvg from "../../Common/SvgImage/Buysvg";
import Givesvg from "../../Common/SvgImage/Givesvg";
import SvgforDo from "../../Common/SvgImage/SvgforDo";

const WriteNeedForAdd = (props) => {
  return (
    <div className="existing-need-row priority need-type-svgbox">
      {props?.needType === "buy" && <Buysvg />}
      {props?.needType === "give" && <Givesvg />}
      {props?.needType === "do" && <SvgforDo />}
      <input
        className="write-needname"
        type="text"
        value={props?.liveTitleAdd ? props?.liveTitleAdd : props?.needName}
        placeholder="Add Need..."
        onChange={(e) => props?.handleChange(e)}
        onFocus={(e) => (e.target.placeholder = "Type a Need...")}
        // disabled={props?.needType ? false : true}
        onKeyUp={(e) => e.keyCode === 13 && props?.handleSubmit()}
        ref={props?.inputRef}
      />
      {/* {props?.needName && <label>Details</label>}
          {props?.needType && <div className='column column-last'>
            <button
              type='button'
              data-toggle='collapse'
              data-target='.multi-collapse'
              aria-expanded='false'
              aria-controls='multiCollapseExample1'
            >
              <img src={RightAngle} alt='' />
            </button>
          </div>} */}
    </div>
  );
};

export default WriteNeedForAdd;
