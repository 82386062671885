import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../style.css";
import Back from "../../../assets/images/back.png";
import right_angle from "../../../assets/images/right_angle_gray.png";
import {
  getNeedsList,
  getNeedDetailsData,
} from "../../../Store/Actions/userActions";
import moment, { utc } from "moment";
import { stringCapitalize } from "../../../services/stringModify";
import { ToastContainer, toast } from "react-toastify";
// var needsContribution = 0;

export default function ManageGiveList(props) {
  const dispatch = useDispatch();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  let needsContribution = 0;
  let yesterdayCount = 0;
  let todayCount = 0;
  let dayCount = 0;
  let uniqueDate = "";
  let [needs, setNeeds] = useState([]);
  let [needsList, setNeedsList] = useState([]);
  let [needsListOpen, setNeedsListOpen] = useState(false);
  let [newArray, setNewArray] = useState([]);
  let [needsContri, setNeedsContri] = useState(0);
  let [key, setKey] = useState();
  useEffect(() => {
    setNeedsList(props.needsList);
    setNewArray(props.needsLis);
    setNeedsListOpen(true);
    var needsval = props.needsList;
    for (var i = 0; i < needsval.length; i++) {
      if (needsval[i].needType === "give") {
        // needsContribution = needsContribution + needsval[i].totalQuantity;
        needsContribution = needsContribution + needsval[i].amount;
      }
      if (i === needsval.length - 1) {
        !props?.startSearching && setNeedsContri(needsContribution);
        // setNeedsContri(needsContribution);
      }
    }
  }, [props.needsList]);

  useEffect(() => {
    setNeeds(props.needs);
  }, [props.needs]);
  console.log("todayCount", { todayCount });

  const renderDayName = (msgDate, payload) => {
    // msgDate = moment(msgDate).format("YYYY-MM-DD HH:mm:ssZ");
    // console.log("",msgDate)
    // console.log("sortedCars1",new Date(new Date(msgDate).toString().split('GMT')[0]+'UTC').toISOString().split('.')[0]);
    // console.log("sortedCars1",new Date(moment(msgDate).utc()));
    let finalDate = "";
    const msgDay = moment(msgDate).format("DD MMMM YYYY");
    const yesterday = new Date(new Date().valueOf() - 1000 * 60 * 60 * 24);

    if (msgDay === moment().format("DD MMMM YYYY")) {
      todayCount += 1;
      return todayCount === 1 && "Today";
    }
    if (msgDay === moment(yesterday).format("DD MMMM YYYY")) {
      yesterdayCount += 1;
      return yesterdayCount === 1 && "Yesterday";
    }

    if (uniqueDate === "" || uniqueDate !== msgDay) {
      uniqueDate = msgDay;
      payload?.length > 0 &&
        payload?.map((item) => {
          const itemDate = moment(item?.date?.toString()).format(
            "DD MMMM YYYY"
          );
          if (itemDate === msgDay) {
            dayCount += 1;
            if (dayCount === 1) {
              finalDate = msgDay;
            }
          }
        });
      return finalDate;
    } else {
      dayCount = 0;
      return finalDate;
    }
  };

  const getNeedDetails = (needId, index) => {
    setKey(index);
    dispatch(getNeedDetailsData(needId, userInfo?.token)).then((res) => {
      const data = res?.data;
      if (data) {
        props.setNeedList(data);
        props.setNeedInfo(data);
        props.selectedproduct("give");
        props.getChatList(data?.needId);
      } else {
        toast.error("Something went wrong.");
      }
    });
  };

  // useEffect(() => {
  //   console.log("sortedCars1 --", needsList);
  //   let abc = needsList.map((item) => {
  //     item.date = moment(item.date).format("DD/MM/YYYY");
  //     return item;
  //   });
  //   let sortedCars1 = abc.sort(
  //     (a, b) => new Date(...a.date) - new Date(...b.date)
  //   );
  //   console.log("sortedCars1", abc);
  //   console.log("sortedCars1 sort", sortedCars1);
  //   //  setNeedsList(sortedCars1);
  //   setNewArray(sortedCars1);
  // }, [needsListOpen]);
  return (
    <div>
      <div class="top-row">
        <div class="name-panel">
          <svg
            width="33"
            height="32"
            viewBox="0 0 33 32"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1.26343 12C1.26343 11.4477 1.71114 11 2.26343 11H11.2634C11.8157 11 12.2634 11.4477 12.2634 12V19H20.16C20.7123 19 21.16 19.4477 21.16 20V30C21.16 30.5523 20.7123 31 20.16 31H2.26343C1.71114 31 1.26343 30.5523 1.26343 30V12ZM3.26343 13V29H19.16V21H11.2634C10.7111 21 10.2634 20.5523 10.2634 20V13H3.26343Z"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.5403 1.07516C12.9153 1.22933 13.1601 1.59462 13.1601 2.00003V12C13.1601 12.5523 12.7123 13 12.1601 13H2.26346C1.85994 13 1.49596 12.7575 1.34057 12.3851C1.18519 12.0127 1.26884 11.5834 1.55268 11.2966L11.4493 1.29661C11.7345 1.00845 12.1654 0.92099 12.5403 1.07516ZM4.66004 11H11.1601V4.4321L4.66004 11Z"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.16 2C11.16 1.44772 11.6077 1 12.16 1H30.16C30.5645 1 30.9291 1.24364 31.0839 1.61732C31.2387 1.99099 31.1531 2.42111 30.8671 2.70711L20.8671 12.7071C20.6796 12.8946 20.4253 13 20.16 13H12.16C11.6077 13 11.16 12.5523 11.16 12V2ZM13.16 3V11H19.7458L27.7458 3H13.16Z"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20.16 19H30.16C30.5645 19 30.9291 19.2436 31.0839 19.6173C31.2387 19.991 31.1531 20.4211 30.8671 20.7071L20.8671 30.7071C20.5811 30.9931 20.151 31.0787 19.7774 30.9239C19.4037 30.7691 19.16 30.4045 19.16 30V20C19.16 19.4477 19.6077 19 20.16 19ZM21.16 21V27.5858L27.7458 21H21.16Z"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M30.5427 1.07615C30.9164 1.23093 31.16 1.59557 31.16 2.00003V20C31.16 20.5523 30.7123 21 30.16 21H20.16C19.6077 21 19.16 20.5523 19.16 20V12C19.16 11.7348 19.2654 11.4805 19.4529 11.2929L29.4529 1.29292C29.7389 1.00692 30.169 0.921369 30.5427 1.07615ZM21.16 12.4142V19H29.16V4.41424L21.16 12.4142Z"
            />
          </svg>
          <label>{needsContri} Contributions</label>
        </div>
      </div>
      <div class="list-content">
        {/* {needsList && needsList.length > 0 && (
          
        )} */}
        {(() => {
          for (let i = 0; i < needsList.length; i++) {
            if (needsList[i].needType === "give" && !needsList[i].approved) {
              return <h5 class="list-header">Needs Review</h5>;
              break;
            }
          }
        })()}
        {needsList &&
          needsList.length > 0 &&
          needsList.slice().reverse().map((obj, index) => {
            console.log(obj.approved);
            return (
              <div>
                {obj.needType === "give" && !obj.approved && (
                  <div>
                    {/* {console.log(`index${index}`,obj)} */}
                    {/* {obj.items &&
                      obj.items.length > 0 &&
                      obj.items.map((item, index) => {
                        const selectIndex = needs.findIndex(  
                          (temp) => temp.needId === item.needId
                        );
                        console.log('selectIndex', selectIndex, needs);
                        return ( */}
                    <>
                      {/* <label className="date_label">
                        {renderDayName(obj?.date, needsList)}
                      </label> */}
                      <div
                        class={`list-item priority ${
                          key == index ? "active" : ""
                        }`}
                        // onClick={() => (
                        // props.setNeedList(obj),
                        // props.setNeedInfo(obj),
                        // props.selectedproduct('give'),
                        // props.getChatList(obj.needId)
                        // )}
                        onClick={() => {
                          getNeedDetails(obj?.needId, index);
                        }}
                      >
                        <div
                          class={`column-first ${key == index ? "active" : ""}`}
                        >
                          <div class="row1">
                            <label class="name">
                              {stringCapitalize(obj?.Name)}
                            </label>
                          </div>
                          {obj?.userNames && (
                            <label class="row2">
                              {obj?.userNames[0]} wants to give this
                            </label>
                          )}
                        </div>
                        <div class="column-last">
                          <button type="button">
                            <img src={right_angle} />
                          </button>
                        </div>
                      </div>
                    </>
                    {/* );
                      })} */}
                  </div>
                )}
              </div>
            );
          })}

        {needsList &&
          needsList.length > 0 &&
          needsList
            .slice()
            .reverse()
            .map((obj, index) => {
              return (
                <div className="main-heightbox">
                  {obj.needType === "give" && obj.approved && (
                    <div>
                      {/* {console.log(`index${index}`,obj)} */}
                      {/* {obj.items &&
                      obj.items.length > 0 &&
                      obj.items.map((item, index) => {
                        const selectIndex = needs.findIndex(  
                          (temp) => temp.needId === item.needId
                        );
                        console.log('selectIndex', selectIndex, needs);
                        return ( */}
                      <>
                        <label className="date_label">
                          {renderDayName(obj?.date, needsList)}
                        </label>
                        <div
                          class={`list-item priority ${
                            key == index ? "active" : ""
                          }`}
                          // onClick={() => (
                          // props.setNeedList(obj),
                          // props.setNeedInfo(obj),
                          // props.selectedproduct('give'),
                          // props.getChatList(obj.needId)
                          // )}
                          onClick={() => {
                            getNeedDetails(obj?.needId, index);
                          }}
                        >
                          <div class="column-first">
                            <div class="row1">
                              <label class="name">
                                {stringCapitalize(obj?.Name)}
                              </label>
                            </div>
                            {obj?.userNames && (
                              <label class="row2">
                                {obj?.userNames[0]} wants to give this
                              </label>
                            )}
                          </div>
                          <div class="column-last">
                            <button type="button">
                              <img src={right_angle} />
                            </button>
                          </div>
                        </div>
                      </>
                      {/* );
                      })} */}
                    </div>
                  )}
                </div>
              );
            })}
      </div>
      <ToastContainer autoClose={3000} />
    </div>
  );
}
