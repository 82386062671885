import Cookies from "js-cookie";
import React, { useState, useEffect } from "react";
import Profileheadermobile from "../../Common/Profileheadermobile";
import ListCreationMobileHeader from "../../Common/MobileHeaderOne";
import AxiosApi from "../../Config/axios-api";
import { useDispatch, useSelector } from "react-redux";
import { USER_ACTION_LIST_LIST_SUCCESS } from "../../Store/Constants/userConstants";
import Leaf from "../../assets/images/leaf.svg";
import ListPreviewGray from "../../assets/images/list_preview_gray.png";
import ListPreview from "../../assets/images/list_preview.png";
import UnloginuserHead from "../../Common/UnloginuserHead";
import { data } from "jquery";
import { getUserDetailsInfo } from "../../Store/Actions/userActions";
import right_angleimg from "../../assets/images/right_angle_gray.png";
import { stringCapitalize } from "../../services/stringModify";
const Search = (props) => {
  const { innerWidth: width, innerHeight: height } = window;

  const [searchResponse, setSearchResponse] = useState();
  const [noDataResponse, setNoDataResponse] = useState("");
  const [userDetails, setUserDetails] = useState();
  const dispatch = useDispatch();

  const userSignin = useSelector((state) => state.userSignin);

  const { userInfo } = userSignin;

  useEffect(() => {
    getSearchData(props.match.params.searchData);
  }, [props.match.params.searchData]);

  const getSearchData = async (searchitem) => {
    const { data } = await AxiosApi.get(
      "actionList/searchActionList?searchKey=" + searchitem
    );

    if (data?.actionLists) {
      setSearchResponse(data);
      setNoDataResponse("");
    } else if (data?.msg.includes("No Record")) {
      setNoDataResponse(data?.msg);
    }
  };

  useEffect(() => {
    getuserDetails();
  }, []);

  const redirectlist = (item) => {
    dispatch({
      type: USER_ACTION_LIST_LIST_SUCCESS,
      payload: item,
    });
    props.history.push("/l/" + item.actionListId);
  };

  const getuserDetails = async () => {
    dispatch(getUserDetailsInfo(userInfo?.userId)).then((res) => {
      if (res?.data) {
        setUserDetails(res?.data);
      }
    });
    // const { data } = await AxiosApi.get(
    //   "/userDetailsById/" + userInfo?.userId,
    //   {}
    // );
    // setUserDetails(data);
  };

  return (
    <>
      {Cookies.get("token") ? (
        <Profileheadermobile history={props.history} />
      ) : (
        <UnloginuserHead history={props.history} />
      )}

      <div className="content dashboard searchpage">
        <div className="container">
          <>
            {/* Action Lists */}
            {searchResponse ? (
              <div>
                <div className="section1 section2">
                  <div className="header-row">
                    <div className="left">
                      <h3 className="subhead">Search Results</h3>
                      {noDataResponse !== "" ? null : (
                        <label>Your Actions</label>
                      )}
                    </div>
                  </div>

                  {noDataResponse !== "" ? (
                    <div className="">
                      <div className="nothing-found subhead">
                        <label
                          style={{
                            width: "100%",
                            textAlign: "center",
                            fontSize: "25px",
                          }}
                        >
                          Nothing found
                        </label>
                      </div>
                    </div>
                  ) : (
                    searchResponse?.actionLists.length > 0 &&
                    searchResponse?.actionLists.map((aitem, index) => {
                      let percentwidth =
                        Math.ceil((aitem.done / aitem.needsCount) * 100) + "%";
                      let theme;
                      switch (aitem.themeName) {
                        case "blossom":
                          theme = "list-box theme-blossom";
                          break;
                        case "greenery":
                          theme = "list-box theme-greenery";
                          break;
                        case "waterpolo":
                          theme = "list-box theme-waterpolo";
                          break;
                        case "dawn":
                          theme = "list-box theme-dawn";
                          break;
                        case "fair":
                          theme = "list-box theme-fair";
                          break;
                        case "sunshine":
                          theme = "list-box theme-sunshine";
                          break;
                        default:
                          theme = "list-box theme-sunshine";
                      }
                      return (
                        <>
                          {aitem?.userId === userDetails?.userId && (
                            <div
                              className={theme}
                              onClick={() => redirectlist(aitem)}
                            >
                              <div className="action-listbox">
                                <h3>{stringCapitalize(aitem?.title)}</h3>
                                <img
                                  src={right_angleimg}
                                  className="search-listimg"
                                />
                                <p>
                                  Organized by{" "}
                                  <strong>{aitem?.userName}</strong>
                                </p>
                              </div>
                            </div>
                          )}
                        </>
                      );
                    })
                  )}
                </div>
              </div>
            ) : (
              <div className="header-row">
                <div className="">
                  <h3 className="subhead">Search Result</h3>
                  <div className="nothing-found">
                    <label
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "25px",
                      }}
                      className="subhead"
                    >
                      Nothing found
                    </label>
                  </div>
                </div>
              </div>
            )}

            {/* Public Lists */}

            {searchResponse ? (
              <div>
                <div className="section1 section2">
                  <div className="header-row">
                    <div className="left">
                      {noDataResponse !== "" ? null : (
                        <label>Public Lists</label>
                      )}
                    </div>
                  </div>

                  {noDataResponse !== "" ? (
                    <div className="">
                      <div className="nothing-found subhead">
                        <label
                          style={{
                            width: "100%",
                            textAlign: "center",
                            fontSize: "25px",
                          }}
                        >
                          Nothing found
                        </label>
                      </div>
                    </div>
                  ) : (
                    searchResponse?.actionLists.length > 0 &&
                    searchResponse?.actionLists.map((item, index) => {
                      let percentwidth =
                        Math.ceil((item.done / item.needsCount) * 100) + "%";
                      let theme;
                      switch (item.themeName) {
                        case "blossom":
                          theme = "list-box theme-blossom";
                          break;
                        case "greenery":
                          theme = "list-box theme-greenery";
                          break;
                        case "waterpolo":
                          theme = "list-box theme-waterpolo";
                          break;
                        case "dawn":
                          theme = "list-box theme-dawn";
                          break;
                        case "fair":
                          theme = "list-box theme-fair";
                          break;
                        case "sunshine":
                          theme = "list-box theme-sunshine";
                          break;
                        default:
                          theme = "list-box theme-sunshine";
                      }
                      return (
                        <>
                          {item && item?.userId !== userDetails?.userId && (
                            <div
                              className={theme}
                              onClick={() => redirectlist(item)}
                            >
                              <div className="action-listbox">
                                <h3>{stringCapitalize(item?.title)}</h3>
                                <img
                                  src={right_angleimg}
                                  className="search-listimg"
                                />
                                <p>
                                  Organized by <strong>{item?.userName}</strong>
                                </p>
                              </div>
                            </div>
                          )}
                        </>
                      );
                    })
                  )}
                </div>
              </div>
            ) : (
              <div className="header-row">
                <div className="">
                  <div className="nothing-found">
                    <label
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "25px",
                      }}
                      className="subhead"
                    >
                      Nothing found
                    </label>
                  </div>
                </div>
              </div>
            )}
          </>
        </div>
      </div>
    </>
  );
};

export default Search;
