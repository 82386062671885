import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Back from '../../../assets/images/back.png';
import help from '../../../assets/images/help.png';
export default function WithDrawAddress(props) {
  return (
    <div
      class='tab-pane fade show active'
      id='withdraw-funds'
      role='tabpanel'
      aria-labelledby='withdraw-funds-tab'
    >
      <div class='mobile-header-inner'>
        <nav class='navbar'>
          <a onClick={props.backdata} class='back'>
            <img src={Back} />
          </a>
          <label class='navbar-brand mx-auto middle-label'>
            Withdraw funds
          </label>
        </nav>
      </div>
      <form class='verify-form'>
        <div class='wrapper'>
          <div class='form-div form1'>
            <h5 class='form-heading'>Representative information</h5>

            <div class='form-group'>
              <label for='firstname'>First name</label>
              <input
                type='text'
                class='form-control'
                id='firstname'
                placeholder='Carol'
                value='Samanta'
              />
            </div>

            <div class='form-group'>
              <label for='lastname'>Last name</label>
              <input
                type='text'
                class='form-control'
                id='lastname'
                placeholder='Smith'
                value='Jackson'
              />
            </div>

            <div class='form-group'>
              <label for='address'>Street address</label>
              <input
                type='text'
                class='form-control'
                id='address'
                placeholder='89 Spring St'
                value='89 Spring St'
              />
            </div>

            <div class='form-group'>
              <label for='city'>Postal town or city</label>
              <input
                type='text'
                class='form-control'
                id='city'
                placeholder='New York'
                value='New York'
              />
            </div>

            <div class='form-group row'>
              <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6'>
                <label for='province' class='label'>
                  Province
                </label>
                <input
                  type='text'
                  class='form-control'
                  id='province'
                  placeholder=''
                  value=''
                />
              </div>
              <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6'>
                <label for='postcode' class='label'>
                  Postal code
                </label>
                <input
                  type='text'
                  class='form-control'
                  id='postcode'
                  placeholder='10012'
                  value='3535144'
                />
              </div>
            </div>

            <div class='form-group'>
              <label for='listTitleInput' class='label'>
                Country
              </label>
              <div class='dropdown purpose-dropdown'>
                <button
                  class='btn dropdown-toggle'
                  type='button'
                  id='dropdownMenuButton'
                  data-toggle='dropdown'
                  aria-haspopup='true'
                  aria-expanded='false'
                >
                  Canada
                  <svg
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    class='ml-auto'
                  >
                    <path
                      d='M4 9L12 17L20 9'
                      stroke='#607BFF'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                  </svg>
                </button>
                <div class='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                  <a class='dropdown-item' href='#'>
                    Canada
                  </a>
                </div>
              </div>
            </div>

            <div class='form-group'>
              <label for='city'>Date of birth</label>
              <input
                type='text'
                class='form-control'
                id='dob'
                placeholder='03/15/1987'
                value='03/15/1987'
              />
            </div>
          </div>

          <div class='form-div form2 hide'>
            <h5 class='form-heading'>Company information</h5>

            <div class='form-group'>
              <label for='companyname'>Company name</label>
              <input
                type='text'
                class='form-control'
                id='companyname'
                placeholder='ABC Company'
              />
            </div>

            <div class='form-group'>
              <label for='taxid'>Company Tax ID</label>
              <input
                type='text'
                class='form-control'
                id='taxid'
                placeholder='123456789'
              />
            </div>

            <div class='form-group'>
              <label for='address'>Street address</label>
              <input
                type='text'
                class='form-control'
                id='address'
                placeholder='72 Spring St'
              />
            </div>

            <div class='form-group'>
              <label for='city'>Postal town or city</label>
              <input
                type='text'
                class='form-control'
                id='city'
                placeholder='New York'
              />
            </div>

            <div class='form-group row'>
              <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6'>
                <label for='province' class='label'>
                  Province
                </label>
                <input
                  type='text'
                  class='form-control'
                  id='province'
                  placeholder=''
                />
              </div>
              <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6'>
                <label for='postcode' class='label'>
                  Postal code
                </label>
                <input
                  type='text'
                  class='form-control'
                  id='postcode'
                  placeholder='10012'
                />
              </div>
            </div>

            <div class='form-group'>
              <label for='listTitleInput' class='label'>
                Country
              </label>
              <div class='dropdown purpose-dropdown'>
                <button
                  class='btn dropdown-toggle'
                  type='button'
                  id='dropdownMenuButton'
                  data-toggle='dropdown'
                  aria-haspopup='true'
                  aria-expanded='false'
                >
                  Select country
                  <svg
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    class='ml-auto'
                  >
                    <path
                      d='M4 9L12 17L20 9'
                      stroke='#607BFF'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                  </svg>
                </button>
                <div class='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                  <a class='dropdown-item' href='#'>
                    Canada
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <button type='submit' class='btn btn-prime'>
          Verify identity
        </button>
        <button type='submit' class='btn btn-extra' onClick={props.nextclick}>
          Fill in the Company details
        </button>
      </form>
    </div>
  );
}
