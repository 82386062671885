import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import buyImg from "../../assets/images/buy.svg";
import doImg from "../../assets/images/do.svg";
import giveImg from "../../assets/images/give.svg";
import "./style.css";
import "./responsive.css";
import { getReportDetails } from "../../Store/Actions/userActions";
import LeftPlane from "../../Common/LeftPlane";
import MobileHeaderTwo from "../../Common/MobileHeaderTwo";
import DesktopHeaderTwo from "../../Common/UserDesktopHeaderTwo";

export const RenderReport = (props) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const theme = localStorage.getItem("selectedtheme");
  const selectedList = JSON.parse(localStorage.getItem("selectedList"));
  const dispatch = useDispatch();
  const [reportDetails, setReportDetails] = useState();
  const [selectedaction, setselectedaction] = useState();
  const [scrolltop, setScrollTop] = useState(false);

  let [needsFilters, setFilters] = useState(false);

  useEffect(() => {
    dispatch(getReportDetails(props?.match?.params?.id, userInfo?.token)).then(
      (res) => {
        if (res?.data) {
          console.log({ res });
          setReportDetails(res?.data);
        }
      }
    );
    setselectedaction(selectedList);
  }, []);

  function filters() {
    setFilters(!needsFilters);
  }
  let percentwidthForBuy =
    Math.ceil((reportDetails?.buyDone / reportDetails?.totalBuy) * 100) + "%";
  let percentwidthForGive =
    Math.ceil((reportDetails?.giveDone / reportDetails?.totalGive) * 100) + "%";
  let percentwidthForDo =
    Math.ceil((reportDetails?.doDone / reportDetails?.totalDo) * 100) + "%";
  useEffect(() => {
    percentwidthForBuy =
      Math.ceil((reportDetails?.buyDone / reportDetails?.totalBuy) * 100) + "%";
    percentwidthForGive =
      Math.ceil((reportDetails?.giveDone / reportDetails?.totalGive) * 100) +
      "%";
    percentwidthForDo =
      Math.ceil((reportDetails?.doDone / reportDetails?.totalDo) * 100) + "%";
  }, [reportDetails]);

  return (
    <>
      <DesktopHeaderTwo history={props.history} />
      <MobileHeaderTwo
        pageTitle="Reports"
        previousPage="/me/lists"
        filter={true}
        close="/account-created"
        history={props.history}
        filterClose={filters}
        selectedTheme={theme || selectedaction?.themeName}
      />
      <div class="list-creation-innerpage list-creation-innerpagenew ">
        <LeftPlane
          history={props.history}
          route="/reports"
          activrID={props.match.params.id}
          selectedTheme={theme || selectedaction?.themeName}
        />
        {/* <div class={`rightpane rightpane-manage  ${scrolltop && 'scrolled'}`}> */}
        <div
          class="rightpane rightpane-manage righttape-report"
          style={{ minHeight: "50vh", maxHeight: "80vh", overflow: "auto" }}
        >
          <div class="rightpane-header">
            <h2 class="mr-auto">{localStorage.getItem("selectedListName")}</h2>
          </div>
          <div className="tab-content rightTabContent" id="leftpaneTabContent">
            <div
              // className='tab-pane fade'
              id="reports-content"
              role="tabpanel"
              aria-labelledby="reports-tab"
            >
              <div className="row ">
                <div className="col-md-12 report-pagecontent">
                  <div className="row hidden-inmobile">
                    <div className="col-md-3">
                      <div className="report-number">
                        <h3>
                          {reportDetails?.openNeeds
                            ? reportDetails?.openNeeds
                            : 0}
                        </h3>
                        <p>Open needs</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="report-number">
                        <h3>
                          {reportDetails?.actionTaken
                            ? reportDetails?.actionTaken
                            : 0}
                        </h3>
                        <p>Have taken action</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="report-number">
                        <h3>
                          {reportDetails?.donation
                            ? "$" + reportDetails?.donation
                            : "$0"}
                        </h3>
                        <p>Donated</p>
                      </div>
                    </div>
                  </div>
                  <div className="row hidden-indesktop">
                    <div className="col-md-12">
                      <div className="report-number">
                        <h3>{reportDetails?.actionTaken}</h3>
                        <p>Have taken action</p>
                      </div>
                    </div>
                    <div className=" text-center">
                      <div className="report-number report-number1">
                        <h3>{reportDetails?.openNeeds}</h3>
                        <p>Open needs</p>
                      </div>
                    </div>
                    <div className="text-center">
                      <div className="report-number ">
                        <h3>{"$" + reportDetails?.donation}</h3>
                        <p>Donated</p>
                      </div>
                    </div>
                  </div>

                  <div className="report-pagecontentborder"></div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="progress-bardiv mt-5">
                    <div className="left-sideprogress">
                      {console.log({ reportDetails })}
                      <div
                        className="progress"
                        data-percentage={
                          reportDetails?.buyDone === 0
                            ? "0"
                            : percentwidthForBuy.slice(
                                0,
                                percentwidthForBuy.indexOf("%")
                              )
                        }
                      >
                        <span className="progress-left">
                          <span className="progress-bar"></span>
                        </span>
                        <span className="progress-right">
                          <span className="progress-bar"></span>
                        </span>
                        <div className="progress-value">
                          <div>
                            <img src={buyImg} />
                          </div>
                        </div>
                      </div>
                      <p className="buy">Buy</p>
                    </div>
                    <div className="right-sideprogress">
                      <p className="buy_num">
                        {reportDetails?.buyDone +
                          " of " +
                          reportDetails?.totalBuy +
                          " Needs"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="progress-bardiv">
                    <div className="left-sideprogress">
                      <div
                        className="progress"
                        data-percentage={
                          reportDetails?.giveDone === 0
                            ? "0"
                            : percentwidthForGive.slice(
                                0,
                                percentwidthForGive.indexOf("%")
                              )
                        }
                      >
                        <span className="progress-left">
                          <span className="progress-bar"></span>
                        </span>
                        <span className="progress-right">
                          <span className="progress-bar"></span>
                        </span>
                        <div className="progress-value">
                          <div>
                            <img src={giveImg} />
                          </div>
                        </div>
                      </div>
                      <p className="buy">Give</p>
                    </div>
                    <div className="right-sideprogress">
                      <p className="buy_num">
                        {reportDetails?.giveDone +
                          " of " +
                          reportDetails?.totalGive +
                          " Actions "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="progress-bardiv">
                    <div className="left-sideprogress">
                      <div
                        className="progress"
                        data-percentage={
                          reportDetails?.doDone === 0
                            ? "0"
                            : percentwidthForDo.slice(
                                0,
                                percentwidthForDo.indexOf("%")
                              )
                        }
                      >
                        <span className="progress-left">
                          <span className="progress-bar"></span>
                        </span>
                        <span className="progress-right">
                          <span className="progress-bar"></span>
                        </span>
                        <div className="progress-value">
                          <div>
                            <img src={doImg} />
                          </div>
                        </div>
                      </div>
                      <p className="buy">Do</p>
                    </div>

                    <div className="right-sideprogress">
                      <p className="buy_num">
                        {reportDetails?.doDone +
                          " of " +
                          reportDetails?.totalDo +
                          " Actions"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
