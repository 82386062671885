import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
// import UserDeskTopHeaderOne from '../../Common/UserDeskTopHeaderOne';
import Profileheadermobile from '../../Common/Profileheadermobile';
import ListCreationMobileHeader from '../../Common/MobileHeaderOne';
import { useForm } from 'react-hook-form';
import {setHelpCenterContactdata} from '../../Store/Actions/userActions';
import { ToastContainer, toast } from "react-toastify";
import '../../../node_modules/react-toastify/dist/ReactToastify.min.css'
const Contactus = (props) => {
  const dispatch = useDispatch();
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const { register: contactinfo, handleSubmit, errors } = useForm();
  const [reason, setReason] = useState();
  const [submitStatus, setSubmitStatus] = useState(false);
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [email, setEmail] = useState('')
  const [msg, setMsg] = useState('')

  const submit = (data) => {
    let payload ={
      firstName:data.firstname,
      lastName:data.lastname,
      company:data.company,
      email:data.email,
      resion:reason,
      message:data.message
    }
    
    setSubmitStatus(true);
    dispatch(setHelpCenterContactdata(payload, userInfo?.token)).then((res)=> {
      if(res?.data){
        toast.success(res?.data?.result)
        setReason('')
        setFirstName('')
        setLastName('')
        setCompanyName('')
        setEmail('')
        setMsg('')
      }else{
        toast.error("Somthing went wrong!")
      }
    })
    // let list = { ...userCreateList, description: description };
    // dispatch(addDescription(list));
    // props.history.push('/me/create/step4');
  };

  useEffect(()=>{
  },[])

  return (
    <>
      <Profileheadermobile history={props.history}/>
      {/* <UserDeskTopHeaderOne history={props.history} /> */}
      <ListCreationMobileHeader
        pageTitle='Contact Us'
        previousPage={null}
        close='/account-created'
        history={props.history}
      />

      <div class='content dashboard contact-us'>
        <div class='content-wrapper'>
          <h3 class='subhead'>Contact Us</h3>
          <p class='description'>Use the form below to drop us an email.</p>
          <form class='contant-us-form' onSubmit={handleSubmit(submit)}>
            <div class='form-group row'>
              <div class='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12'>
                <label for='firstname' class='label'>
                  First name
                </label>
                <input
                  type='text'
                  class='form-control'
                  value = {firstName}
                  id='firstname'
                  // placeholder='Carol'
                  name='firstname'
                  ref={contactinfo({
                    required: 'First Name cannot be empty',
                    maxLength: '40',
                  })}
                  onChange = {(e)=> setFirstName(e?.target?.value)}
                />
                {Object.keys(errors).length !== 0 && (
                  <div className='error-box'>
                    <ul>
                      {errors.firstname && (
                        <li key='1' className='incorrect'>
                          {errors.firstname.message}
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              </div>
              <div class='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12'>
                <label for='lastname' class='label'>
                  Last name
                </label>
                <input
                  type='text'
                  class='form-control'
                  id='lastname'
                  value={lastName}
                  // placeholder='Smith'
                  name='lastname'
                  ref={contactinfo({
                    required: 'Last Name cannot be empty',
                    maxLength: '40',
                  })}
                  onChange = {(e)=> setLastName(e?.target?.value)}
                />
                {Object.keys(errors).length !== 0 && (
                  <div className='error-box'>
                    <ul>
                      {errors.lastname && (
                        <li key='1' className='incorrect'>
                          {errors.lastname.message}
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div class='form-group row'>
              <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
                <label for='firstname' class='label'>
                  Company (if applicable)
                </label>
                <input
                  type='text'
                  class='form-control'
                  id='company'
                  value={companyName}
                  placeholder=''
                  name='company'
                  ref={contactinfo({})}
                  onChange = {(e)=> setCompanyName(e?.target?.value)}
                />
              </div>
            </div>
            <div class='form-group row'>
              <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
                <label for='email' class='label'>
                  Email address
                </label>
                <input
                  type='email'
                  class='form-control'
                  id='email'
                  value={email}
                  placeholder=''
                  name='email'
                  ref={contactinfo({
                    required: 'Email cannot be empty',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: 'Enter a valid email address',
                    },
                  })}
                  onChange = {(e)=> setEmail(e?.target?.value)}
                />
                {Object.keys(errors).length !== 0 && (
                  <div className='error-box'>
                    <ul>
                      {errors.email && (
                        <li key='1' className='incorrect'>
                          {errors.email.message}
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div class='form-group row'>
              <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
                <label for='email' class='label'>
                  Why are you contacting us?
                </label>
                <div class='dropdown purpose-dropdown'>
                  <button
                    class='btn dropdown-toggle'
                    type='button'
                    id='dropdownMenuButton'
                    data-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    <span
                      class='selected'
                      id='selected'
                      style={{ color: reason && '#243457' }}
                    >
                      {reason ? reason : 'Select a reason'}
                    </span>
                    <svg
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                      class='ml-auto'
                    >
                      <path
                        d='M4 9L12 17L20 9'
                        stroke='#607BFF'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                    </svg>
                  </button>
                  <div
                    class='dropdown-menu dropdown-menu-right'
                    aria-labelledby='dropdownMenuButton'
                  >
                    <a
                      class='dropdown-item'
                      href='javascript:void(0);'
                      onClick={() => setReason('General question')}
                    >
                      General question
                    </a>
                    <a
                      class='dropdown-item'
                      href='javascript:void(0);'
                      onClick={() => setReason('Feedback')}
                    >
                      Feedback
                    </a>
                    <a
                      class='dropdown-item'
                      href='javascript:void(0);'
                      onClick={() => setReason('Partnership')}
                    >
                      Partnership
                    </a>
                    <a
                      class='dropdown-item'
                      href='javascript:void(0);'
                      onClick={() => setReason('Investment request')}
                    >
                      Investment request
                    </a>
                    <a
                      class='dropdown-item'
                      href='javascript:void(0);'
                      onClick={() => setReason('Job opportunities')}
                    >
                      Job opportunities
                    </a>
                    <a
                      class='dropdown-item'
                      href='javascript:void(0);'
                      onClick={() => setReason('Media')}
                    >
                      Media
                    </a>
                  </div>
                </div>
                <div className='error-box'>
                  <ul>
                    {!reason && submitStatus && (
                      <li key='1' className='incorrect'>
                        Select any one reason
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
            <div class='form-group row'>
              <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
                <label for='message' class='label'>
                  Message
                </label>
                <div class='story_description_wrapper'>
                  <textarea
                    class='form-control'
                    id='message'
                    placeholder=''
                    value={msg}
                    name='message'
                    ref={contactinfo({
                      required: 'Message cannot be empty',
                    })}
                    onChange = {(e)=> setMsg(e?.target?.value)}
                  ></textarea>
                  {Object.keys(errors).length !== 0 && (
                    <div className='error-box'>
                      <ul>
                        {errors.message && (
                          <li key='1' className='incorrect'>
                            {errors.message.message}
                          </li>
                        )}
                      </ul>
                    </div>
                  )}
                  <button type='button' class='expand_button'></button>
                </div>
              </div>
            </div>
            <button type='submit' class='btn btn-prime'>
              Send
            </button>
          </form>
        </div>
        <ToastContainer autoClose={3000} />
      </div>
    </>
  );
};

export default Contactus;
