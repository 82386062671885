import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Profileheadermobile from '../../Common/Profileheadermobile';
import Footer from '../../Common/Footer';
import {getContactTermsData} from '../../Store/Actions/userActions';
import renderHTML from 'react-render-html'
import UnloginuserHead from '../../Common/UnloginuserHead';
import Cookies from 'js-cookie';

const TermAndCondition = (props) => {
  const dispatch = useDispatch();
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const [contactTermsItem, setContactTermsItem] = useState('');

  useEffect(()=>{
    dispatch(getContactTermsData(userInfo?.token)).then((res)=>{
      if(res?.data){
        setContactTermsItem(res?.data?.result)
      }else{
        setContactTermsItem('')
      }
    })
  },[])

  return (
    <div>
      {Cookies.get('token') ? (
        <Profileheadermobile
          history={props.history}
        />
      ) : (
        <UnloginuserHead history={props.history} />
      )}
      <div className="content dashboard privacy-and-terms">
        {renderHTML(contactTermsItem)}
        {/* <div className="container">
          <div className="header-row">
            <h3 className="subhead">Terms of service</h3>
          </div>
          <div className="content">
            <p>IMECOUNTS INC. (“TIMECOUNTS”/“WE”/ “US”) OWNS AND OPERATES THE WEBSITE LOCATED AT TIMECOUNTS.ORG (THE “WEBSITE”), WHICH ENABLES USERS (“USERS”/“YOU”) TO USE OUR PLATFORM TO CONNECT WITH EACH OTHER, FIND ORGANIZATIONS AND OPPORTUNITIES OF INTEREST, VOLUNTEER TIME AND MANAGE COMMUNITIES (THE “SERVICES”). THE ACCESS AND USE OF OUR WEBSITE, ITS PLATFORMS, TOOLS, AND SERVICES ARE OFFERED TO YOU SUBJECT TO THE TERMS OF SERVICE SET FORTH IN THIS TERMS OF SERVICE AGREEMENT, TOGETHER WITH THE PRIVACY POLICY AND DATA PROCESSING AGREEMENT (COLLECTIVELY, THE “AGREEMENT”). THESE TERMS OF SERVICE APPLY TO ALL USERS OF THE WEBSITE, INCLUDING USERS WHO UPLOAD ANY MATERIALS TO THE WEBSITE, USERS WHO USE SERVICES PROVIDED THROUGH THIS WEBSITE AND USERS WHO SIMPLY VIEW THE CONTENT ON OR AVAILABLE THROUGH THIS WEBSITE. TIMECOUNTS INC. (“TIMECOUNTS”/“WE”/ “US”) OWNS AND OPERATES THE WEBSITE LOCATED AT TIMECOUNTS.ORG (THE “WEBSITE”), WHICH ENABLES USERS (“USERS”/“YOU”) TO USE OUR PLATFORM TO CONNECT WITH EACH OTHER, FIND ORGANIZATIONS AND OPPORTUNITIES OF INTEREST, VOLUNTEER TIME AND MANAGE COMMUNITIES (THE “SERVICES”). THESE TERMS OF SERVICE APPLY TO ALL USERS OF THE WEBSITE, INCLUDING USERS WHO UPLOAD ANY MATERIALS TO THE WEBSITE, USERS WHO USE SERVICES PROVIDED THROUGH THIS WEBSITE AND USERS WHO SIMPLY VIEW THE CONTENT ON OR AVAILABLE THROUGH THIS WEBSITE.</p>
            <p>BY CREATING AN ACCOUNT THROUGH THE WEBSITE OR OTHERWISE USING THE SERVICES, YOU INDICATE YOUR ACCEPTANCE OF THESE TERMS OF SERVICE. IF YOU DO NOT ACCEPT THESE TERMS OF SERVICE, THEN DO NOT USE THIS WEBSITE OR ANY OF ITS CONTENT OR SERVICES.</p>
            <p>THESE TERMS OF SERVICE MAY BE AMENDED OR UPDATED BY TIMECOUNTS FROM TIME TO TIME WITHOUT NOTICE AND THE TERMS OF SERVICE MAY HAVE CHANGED SINCE YOUR LAST VISIT TO THIS WEBSITE. IT IS YOUR RESPONSIBILITY TO REVIEW THESE TERMS OF SERVICE FOR ANY CHANGES. YOUR USE AFTER ANY AMENDMENTS OR UPDATES OF THESE TERMS OF SERVICE SHALL SIGNIFY YOUR ASSENT TO AND ACCEPTANCE OF SUCH REVISED TERMS. ANY NEW FEATURES THAT MAY BE ADDED TO THIS WEBSITE FROM TIME TO TIME WILL BE SUBJECT TO THESE TERMS OF SERVICE, UNLESS STATED OTHERWISE. YOU SHOULD VISIT THIS PAGE PERIODICALLY TO REVIEW THESE TERMS OF SERVICE.</p>
            <p>IN ORDER TO USE THE SERVICES, YOU MUST REGISTER WITH US AND PROVIDE CERTAIN INFORMATION. FOR INFORMATION ON HOW WE USE YOUR PERSONAL INFORMATION, PLEASE SEE THE TIMECOUNTS PRIVACY POLICY, THE TERMS OF WHICH ARE INCORPORATED BY REFERENCE INTO THIS AGREEMENT. IF YOU DO NOT AGREE WITH OUR PRIVACY POLICY, DO NOT USE OUR WEBSITE OR SERVICES.</p>
            <div className="divider" />
            <h4 className="section-heading">What is ActionList?</h4>
            <p>Timecounts is a website used by Organizations comprised of members who share common interests. Organizations are managed and moderated directly by administrators and/or by Timecounts. Each Organization may have specific rules that are not created by Timecounts and are not reviewed or controlled by Timecounts. For example, some Organizations may be private and by invitation only, and others may be public on the Website and freely accessible by any member. However, if there is any discrepancy between specific rules in an Organization and these Terms, these Terms will govern in all matters. If you are concerned about any specific rules in a Organization, you may contact Timecounts to seek clarifications. Our contact information is at the end of these Terms.</p>
            <div className="divider" />
            <h4 className="section-heading">Registering as a User</h4>
            <p>You must be at least 13 years old to register for the Services as a User. In order to register for the Services you must provide your name, email address, and additional information required by the organization that you are affiliated with. Any personal information provided by You to us will be stored in accordance with the Timecounts Privacy Policy, which You should read carefully. We ask that to provide your real name when creating a Timecounts account. You will be solely responsible and liable for any and all loss, damage, and additional costs that we or any other person may incur as a result of your submission of any false, incorrect or incomplete information or your failure to update your registration information as needed. Here are the commitments Users make to us relating to registering and maintaining the security of their account:</p>
            <p>Users will not provide any false information on Timecounts.<br />
            If we disable a User’s account, they will not create another one without our permission.<br />
            Users under 18 are only permitted with a parent or legal guardian's supervision (including that parent or guardian agreeing to these Terms of Service and our Privacy Policy on behalf of the User).<br />
            Users’ contact information should be accurate and up-to-date.<br />
            Users will not share passwords, let anyone else access their account, or do anything else that might jeopardize the security of their account.<br />
            If you are accepting these Terms and using the Services on behalf of a company, organization, government, or other legal entity, you represent and warrant that you are authorized to do so.
          </p>
            <div className="divider" />
            <h4 className="section-heading">Organizations</h4>
            <p>An "Administrator" is someone who creates an Organization on the Website and/or is given the role of administrator for a Organization. Administrators (also referred to as “collaborators” or “owners” within the website) are in charge of managing and moderating their hub including content and members. Administrations have discretion over who joins their Organization (for example, they may elect to only allow people within a reasonable distance of the Organization’s epicenter to join). Administrators should act fairly when managing and establishing criteria for their Organization. Additionally, Administrators may not charge their Users any kind of fee to use Timecounts. Administrators may remove content they deem inappropriate, illegal or against Timecounts' Terms of Service.</p>
            <div className="divider" />
            <h4 className="section-heading">Conduct Guidelines</h4>
            <p>In using the Services you agree that you will:</p>
            <p>Provide accurate information when registering and keep such information up to date;<br />
            Keep your user login details and access to the Website secure. We will not be liable for any damages caused by virtue of the compromise of your account or password, including any unauthorized access to your account or use. Please contact us immediately if you suspect or become aware of any unauthorized use of your email login or password or any other breach of security; and
            Treat the other users of the Services with whom you interact with respect and not engage in any conduct which is threatening, harassing or otherwise harmful to other users or third parties, or do anything that breaches the general User conduct guidelines in Section 6 below. We reserve the right to terminate your access to the Services and take appropriate legal action if you are in breach of these requirements or the restrictions in Section 5 below;<br />
            Agree not to upload any content or create any User Groups, events that are harmful, abusive, constitute hate speech, or are harassing, discriminatory or otherwise objectionable and harmful to other Users and/or the public;<br />
            Comply with these terms and all applicable laws and regulations at all time during Engagements and during other dealings with Timecounts.<br />
            Timecounts Inc. reserves the right to terminate your access to and use of the Website and Service at any time for breach of the foregoing restrictions.</p>
            <div className="divider" />
            <h4 className="section-heading">Conduct on the Website Generally</h4>
            <p>In using the Website and Service You agree that You will not:<br />
            copy or distribute any part of the Website without our prior consent;<br />
            alter or modify any part of the Website;<br />
            upload, post, email, transmit or otherwise make available on the Website or through the Service: any material that is unlawful, harmful, threatening, abusive, harassing, tortuous, defamatory, vulgar, obscene, pornographic, libelous, invasive of another's privacy, hateful, or racially or ethnically objectionable, encourages criminal behavior, gives rise to civil liability, violates any law, or is otherwise objectionable, any falsehoods or misrepresentations or create an impression that you know is incorrect, misleading, or deceptive, or any material that could damage or harm any person in any way; any unsolicited or unauthorized advertising, solicitations for business, promotional materials, "junk mail," "spam," "chain letters," "pyramid schemes," or any other form of solicitation; any material that contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment or data or the Website or that of any User or visitor to the Website or that compromises any person’s privacy;<br />
            impersonate any person or entity or misrepresent Your affiliation with a person or entity;<br />
            interfere with or disrupt the Website or networks connected to the Website, or disobey any requirements, procedures, policies or regulations of networks connected to the Website or probe, scan, or test the vulnerability of any system or network or breach or circumvent any security or authentication measures;<br />
            collect or store personal information about other users (unless they are members of your organization); or<br />
            resell the content of the Website, the use of the Website or access to the Website or the content of the Website.</p>
            <div className="divider" />
            <h4 className="section-heading">Other User’s Conduct</h4>
            <p>We are not responsible for the behaviour, acts or omissions of Users of the Services, including any content that is uploaded to the Website and any groups or events that are created through the Website and/or Services. If you feel that another User has threatened, harassed or otherwise acted in an inappropriate manner, you may notify us of this conduct through the help center or emailing support@timecounts.org. We will take appropriate steps as necessary to review and respond to your complaint.</p>
            <div className="divider" />
            <h4 className="section-heading">Electronic Communications</h4>
            <p>When you contact us by sending emails to us, you are communicating with us electronically and you consent to receive communications from us electronically. We will communicate with you by email or by posting notices on the Website. You agree that all agreements, notices, disclosures and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing.</p>
          </div>
        </div> */}
      </div>
      <Footer {...props} />
     
    </div>

  )
};

export default TermAndCondition;
