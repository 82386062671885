import React, { useState, useEffect } from 'react';
import WithDrawListFirst from './WithDrawListFirst';
import WithdrawType from './WithdrawType';
import Withdrawbyothers from './Withdrawbyothers';
import WithDrawBenificial from './WithDrawBenificial';
import Withdrawprivate from './Withdrawprivate';
import WithDrawAddress from './WithDrawAddress';
import WithdraeCompany from './WithdraeCompany';
export default function WithDrawindex(props) {
  const [selectedIndex, setSelectedIndex] = useState(1);
  // console.log("amount",props)
  return (
    <div>
      {selectedIndex === 1 && (
        <WithDrawListFirst
          backdata={props.backdata}
          nextclick={() => setSelectedIndex(2)}
          amount = {props.amount}
          actionList = {props.actionList}
        />
      )}
      {selectedIndex === 2 && (
        <WithdrawType
          //   backdata={props.backdata}
          nextclick={(val) => setSelectedIndex(val)}
          backdata={() => setSelectedIndex(1)}
        />
      )}
      {selectedIndex === 3 && (
        <Withdrawbyothers
          //   backdata={props.backdata}
          nextclick={() => setSelectedIndex(4)}
          backdata={() => setSelectedIndex(2)}
        />
      )}
      {selectedIndex === 4 && (
        <WithDrawBenificial
          //   backdata={props.backdata}
          nextclick={() => setSelectedIndex(1)}
          backdata={() => setSelectedIndex(3)}
        />
      )}
      {selectedIndex === 5 && (
        <Withdrawprivate
          //   backdata={props.backdata}
          nextclick={(val) => setSelectedIndex(val)}
          backdata={() => setSelectedIndex(2)}
        />
      )}
      {selectedIndex === 6 && (
        <WithDrawAddress
          //   backdata={props.backdata}
          nextclick={() => setSelectedIndex(7)}
          backdata={() => setSelectedIndex(5)}
        />
      )}
      {selectedIndex === 7 && (
        <WithdraeCompany
          //   backdata={props.backdata}
          nextclick={() => setSelectedIndex(6)}
          backdata={() => setSelectedIndex(5)}
        />
      )}
    </div>
  );
}
