import AxiosApi from '../../../../Config/axios-api';

export const getSubscription = async (actionListId) => {
  try {
    const response = await AxiosApi.get(
      '/order/subscribedPlanByActionList/' + actionListId
    );
    const { data, status } = response;
    if (status === 200) {
      console.log('success', data);

      return data;
    } else return false;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const applyCoupon = async (code) => {
  try {
    const response = await AxiosApi.get(
      '/order/checkDiscountCode/' + code
    );
    const { data, status } = response;
    if (status === 200) {
      console.log('success', data);

      return data;
    } else return false;
  } catch (error) {
    console.log(error);
    return error
  }
};

export const getSubscriptionPlans = async () => {
  try {
    const response = await AxiosApi.get(
      '/order/getSubscriptionPlanPrice '
    );
    const { data, status } = response;
    if (status === 200) {
      console.log('success', data);

      return data;
    } else return false;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const setBillingDetails = async (payload, actionListId) => {
  try {
    const response = await AxiosApi.post(
      '/actionList/setBillingDetails/' + actionListId
      , payload
    );
    const { data, status } = response;
    console.log(data);
    if (status === 200) {
      console.log('success', data);
      return data;
    } else return false;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const upgradeToProPlan = async (payload) => {
  try {
    const response = await AxiosApi.post(
      '/order/getSubscription/'
      , payload
    );
    const { data, status } = response;

    if (status === 200) {
      return data;
    } else return false;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const getCancelSubscriptionPlan = async (subscriptionId) => {
  try {
    const response = await AxiosApi.get(
      `order/cancelSubscription/${subscriptionId}`
    );
    const { data, status } = response;
    if (status === 200) {
      console.log('subscriptionId', data);
      return data;
    } else return false;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const getBillingDetails = async (actionListId) => {
  try {
    const response = await AxiosApi.get(
      `actionList/getBillingDetails/${actionListId}`
    );
    const { data, status } = response;
    if (status === 200) {
      console.log('getBillingDetails', data);
      return data;
    } else return false;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const removeBillingDetails = async (actionListId) => {
  try {
    const response = await AxiosApi.delete(
      `actionList/removeBillingDetails/${actionListId}`
    );
    const { data, status } = response;
    if (status === 200) {
      console.log('removeBillingDetails', data);
      return data;
    } else return false;
  } catch (error) {
    console.log(error);
    return false;
  }
};