import React, { useState, useEffect } from 'react';
import Back from '../../../../assets/images/back.png';
import ActiveBilling from './ActiveBillingPage';
import NonActiveBilling from './NonActiveBillingPage';
import { getSubscription, getSubscriptionPlans } from './service'
import './style.css';
import './responsive.css';
import { ToastContainer, toast } from "react-toastify";

const BillingSetting = (props) => {
  const [subscriptionData, setSubscriptionData] = useState('')

  useEffect(() => {
    if (props?.actionListId || props?.actionId) {
      getData()
    }
  }, [])

  const getData = async () => {
    const id = props?.actionListId ? props?.actionListId : props?.actionId 
    const data = await getSubscription(id )
    console.log('i am done', data)
    if (data) {
      console.log(data)
      setSubscriptionData(data)
    }
    else {
      toast.error("Somthing went wrong")
    }
  }

  return (
    <>
      { subscriptionData && <div
        className='tab-pane fade show active'
        id='team'
        role='tabpanel'
        aria-labelledby='team-tab'
      >
        <div className='mobile-header-inner'>
          <nav className='navbar'>
            <a onClick={props.backdata} className='back'>
              <img src={Back} />
            </a>
            <label className='navbar-brand mx-auto middle-label'>Billing</label>
          </nav>
        </div>
        {console.log("subscriptionDatasubscriptionData",subscriptionData)}
        {subscriptionData && subscriptionData?.subscribedPlan?.status === "active" ?
          <ActiveBilling
            subscriptionData={subscriptionData} 
            actionListId={props?.actionListId ? props?.actionListId : props?.actionId }
            getSubNData={()=>getData()}
            />
          :
          <NonActiveBilling
            actionListId={props?.actionListId ? props?.actionListId : props?.actionId}
            subscriptionData={subscriptionData} 
            getSubNData={()=>getData()}
            />}
      </div>}
      <ToastContainer autoClose={3000} />
    </>
  )
}

export default BillingSetting;