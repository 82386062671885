import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Back from '../../../assets/images/back.png';
import $ from 'jquery';
import AxiosApi from '../../../Config/axios-api';
import { ToastContainer, toast } from 'react-toastify';
import '../../../../node_modules/react-toastify/dist/ReactToastify.min.css';
import { stringCapitalize } from '../../../services/stringModify';

export default function ThemeListinfo(props) {
  const dispatch = useDispatch();
  const [selectedTheme, setSelectedTheme] = useState('');

  useEffect(() => {
    var value = props.selectedaction;
    setSelectedTheme(value?.themeName);
  }, [props.selectedaction]);

  // useEffect(() => {
  //   $('.list-themes-slider').slick({
  //     centerMode: false,
  //     centerPadding: '0%',
  //     slidesToShow: 6,
  //     infinite: false,
  //     responsive: [
  //       {
  //         breakpoint: 768,
  //         settings: {
  //           arrows: false,
  //           centerMode: true,
  //           centerPadding: '0%',
  //           slidesToShow: 2,
  //         },
  //       },
  //       {
  //         breakpoint: 480,
  //         settings: {
  //           arrows: false,
  //           centerMode: true,
  //           centerPadding: '0%',
  //           slidesToShow: 1.3,
  //         },
  //       },
  //     ],
  //   });
  // }, []);

  useEffect(() => {
    var isMobile = false; //initiate as false
    // device detection
    if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
        || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) { 
        isMobile = true;

        $('.list-themes-slider').slick({
            centerMode: false,
            centerPadding: '0%',
            slidesToShow: 6,
            infinite: false,
            responsive: [
              {
                breakpoint: 768,
                settings: {
                  arrows: false,
                  centerMode: true,
                  centerPadding: '0%',
                  slidesToShow: 2,
                },
              },
              {
                breakpoint: 480,
                settings: {
                  arrows: false,
                  centerMode: true,
                  centerPadding: '0%',
                  slidesToShow: 1.3,
                },
              },
            ],
          });
    }
  }, []);

  const updateTheme = async (theme) => {
    const { data } = await AxiosApi.get(
      '/actionList/changeTheme/' + props.actionId + '/' + theme
    );
    if (data?.msg === 'theme changed!') {
      toast.success(data?.msg)  
      props.listUpdated();
    }
  };

  const renderThemeDemo = (themeName) => {
    const capitlizeThemeName = stringCapitalize(themeName)
    return( 
        <label for={"theme"+capitlizeThemeName}>
          <input
            type='radio'
            name='theme-input'
            id={"theme"+capitlizeThemeName}
            value={themeName}
            checked = {selectedTheme === themeName ? true : false}
            // onChange={(e) => updateTheme(e.target.value)}
            onClick={(e) => setSelectedTheme(e.target.value)}
          />
          <span
            class={`theme-box theme${capitlizeThemeName} ${
              selectedTheme === themeName && 'default-selected'
            }`}
          >
            <div class='top'>
              <div class='row1'>
                <svg
                  width='124'
                  height='8'
                  viewBox='0 0 124 8'
                  xmlns='http://www.w3.org/2000/svg'
                  class='img1 mr-auto'
                >
                  <rect width='124' height='8' rx='4' fill='#98A4B8' />
                </svg>
                <svg
                  width='53'
                  height='8'
                  viewBox='0 0 53 8'
                  xmlns='http://www.w3.org/2000/svg'
                  class='img2'
                >
                  <rect width='53' height='8' rx='4' />
                </svg>
              </div>

              <div class='row2'>
                <svg
                  width='83'
                  height='8'
                  viewBox='0 0 83 8'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <rect width='83' height='8' rx='4' fill='#98A4B8' />
                </svg>
              </div>

              <div class='row3'>
                <svg
                  width='48'
                  height='4'
                  viewBox='0 0 48 4'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <rect width='48' height='4' rx='2' />
                </svg>
              </div>

              <div class='row4'>
                <svg
                  width='213'
                  height='2'
                  viewBox='0 0 213 2'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <rect width='213' height='2' rx='1' fill='#72819B' />
                </svg>
              </div>

              <div class='row5'>
                <svg
                  width='213'
                  height='2'
                  viewBox='0 0 213 2'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <rect width='213' height='2' rx='1' fill='#72819B' />
                </svg>
              </div>

              <div class='row6'>
                <svg
                  width='213'
                  height='2'
                  viewBox='0 0 213 2'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <rect width='213' height='2' rx='1' fill='#72819B' />
                </svg>
              </div>

              <div class='row7'>
                <svg
                  width='48'
                  height='12'
                  viewBox='0 0 48 12'
                  xmlns='http://www.w3.org/2000/svg'
                  class='img1'
                >
                  <rect width='48' height='12' rx='6' />
                </svg>
                <svg
                  width='48'
                  height='12'
                  viewBox='0 0 48 12'
                  xmlns='http://www.w3.org/2000/svg'
                  class='img2'
                >
                  <rect
                    x='1'
                    y='1'
                    width='46'
                    height='10'
                    rx='5'
                    fill='white'
                    stroke-width='2'
                  />
                </svg>
              </div>
            </div>

            <div class='middle'>
              <div class='inner-row'>
                <svg
                  width='12'
                  height='12'
                  viewBox='0 0 12 12'
                  xmlns='http://www.w3.org/2000/svg'
                  class='img1'
                >
                  <circle cx='6' cy='6' r='6' />
                </svg>
                <svg
                  width='121'
                  height='2'
                  viewBox='0 0 121 2'
                  xmlns='http://www.w3.org/2000/svg'
                  class='img2'
                >
                  <rect width='121' height='2' rx='1' fill='#DBDFE8' />
                </svg>
                <svg
                  width='48'
                  height='12'
                  viewBox='0 0 48 12'
                  xmlns='http://www.w3.org/2000/svg'
                  class='img3'
                >
                  <rect width='48' height='12' rx='6' />
                </svg>
              </div>

              <div class='inner-row'>
                <svg
                  width='12'
                  height='12'
                  viewBox='0 0 12 12'
                  xmlns='http://www.w3.org/2000/svg'
                  class='img1'
                >
                  <circle cx='6' cy='6' r='6' />
                </svg>
                <svg
                  width='121'
                  height='2'
                  viewBox='0 0 121 2'
                  xmlns='http://www.w3.org/2000/svg'
                  class='img2'
                >
                  <rect width='121' height='2' rx='1' fill='#DBDFE8' />
                </svg>
                <svg
                  width='48'
                  height='12'
                  viewBox='0 0 48 12'
                  xmlns='http://www.w3.org/2000/svg'
                  class='img3'
                >
                  <rect width='48' height='12' rx='6' />
                </svg>
              </div>
            </div>

            <div class='bottom'>
              <label>{capitlizeThemeName}</label>
            </div>
          </span>
        </label>
    )
  }
  
  return (
    <div
      class={`${
        window.innerWidth < 768 && 'tab-pane'
      } fade show active custom_tab_pane`}
      id='theme'
      role='tabpanel'
      aria-labelledby='theme-tab'
      style={{ padding: '48px 59px 0 62px !important' }}
    >
      <div class='mobile-header-inner'>
        <nav class='navbar'>
          <a onClick={props.backdata} class='back'>
            <img src={Back} />
          </a>
          <label class='navbar-brand mx-auto middle-label'>Theme</label>
        </nav>
      </div>
      <h2 class='heading2 color-themetext'>Theme</h2>
      <div class='theme-desktop-view'>
        <div class='theme-box-wrapper'>
          {renderThemeDemo("sunshine")}
        </div> 
        <div class='theme-box-wrapper'>
          {renderThemeDemo("waterpolo")}
        </div>

        <div class='theme-box-wrapper'>
          {renderThemeDemo("greenery")}
        </div>

        <div class='theme-box-wrapper'>
          {renderThemeDemo("blossom")}
        </div>

        <div class='theme-box-wrapper '>
          {renderThemeDemo("fair")}
        </div>

        <div class='theme-box-wrapper'>
          {renderThemeDemo("dawn")}
        </div>
      </div>
      <div class='theme-mobile-view'>
        <div class='slider list-themes-slider'>
          <div class='theme-box-wrapper'>
            {renderThemeDemo("sunshine")}
          </div>

          <div class='theme-box-wrapper'>
            {renderThemeDemo("waterpolo")}
          </div>

          <div class='theme-box-wrapper'>
            {renderThemeDemo("greenery")}
          </div>

          <div class='theme-box-wrapper'>
            {renderThemeDemo("blossom")}
          </div>
          <div class='theme-box-wrapper'>
            {renderThemeDemo("fair")}
          </div>

          <div class='theme-box-wrapper'>
            {renderThemeDemo("dawn")}
          </div>
        </div>
      </div>
      <div class='view-theme'>
        <button type='button' class='btn btn-prime' onClick={() => updateTheme(selectedTheme)}>
          Save Theme
        </button>
      </div>
      <ToastContainer autoClose={3000}/>
    </div>
  );
}
