import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ShareModal = ({ location }) => {
  const [open, setOpen] = useState(false);
  const baseUrl = window.location.origin;
  const idArray = window.location.href.split("/");
  const id = idArray[idArray.length - 1];
  const handleClose = () => {
    window.$("#shareListModal").modal("hide");
  };
  const copyLink = (data) => {
    setOpen(true);
    toast.success(data);
  };
  return (
    <div
      className="modal fade share-list-modal"
      id="shareListModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <div className="mobile-header">
              <nav className="navbar">
                <label className="navbar-brand mx-auto middle-label">
                  Share the list
                </label>
                <a onClick={handleClose} className="cross">
                  <img src="images/close2.png" />
                </a>
              </nav>
            </div>
            <button
              type="button"
              className="btn-close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            >
              <img src="images/close2.png" />
            </button>
            {/* <h2>Share the list</h2> */}
            <label className="label">
              You can share the link with your friends or embed the code with
              the list to your website.
            </label>
            <div className="content">
              <input
                type="text"
                defaultValue={baseUrl + "/l/" + id}
              />
              <CopyToClipboard
                text={baseUrl + "/l/" + id}
              >
                <button
                  type="button"
                  className="btn btn-extra"
                  onClick={() => copyLink("Link Copied")}
                >
                  Copy the link
                </button>
              </CopyToClipboard>
              <textarea
                rows="3"
                defaultValue={`<iframe src="${`${window.location.origin}/embed/l/${id}`}" style='border: 0px #ffffff none' name="ActionList" frameborder="1" width="100%" height="600px" allowfullscreen></iframe>`}
              ></textarea>
              <CopyToClipboard
                text={`<iframe src="${`${window.location.origin}/embed/l/${id}`}" style='border: 0px #ffffff none' name="ActionList" frameborder="1" width="100%" height="600px" allowfullscreen></iframe>`}
              >
                <button
                  type="button"
                  className="btn btn-extra"
                  onClick={() => copyLink("Code Copied")}
                >
                  Copy the Embed the code
                </button>
              </CopyToClipboard>
            </div>
          </div>
        </div>
      </div>
      {open && <ToastContainer />}
    </div>
  );
};

export default ShareModal;
