import React, { useEffect } from "react";
import Dashboard from "../Screens/Dashboard/Dashboard";

// Purchasing Process Screens

//Dashboard

//Needs List
import EmbedPage from "../Screens/embedCode/EmbedPage";

import { Route } from "react-router-dom";
import EmbedCart from "../Screens/embedCode/EmbedCart";
import ProcessedToPayment from "../Screens/PurchasingProcess/ProcessedToPayment";
import ProcessedToCheckout from "../Screens/PurchasingProcess/ProcessedToCheckout";
import Purchase_Success from "../Screens/PurchasingProcess/Purchase_Success";
import EmbedProcessToPayment from "../Screens/embedCode/EmbedProcessToPayment";
import EmbedProcessedToCheckout from "../Screens/embedCode/EmbedProcessToCheckout";
import EmbedPurchase_Success from "../Screens/embedCode/EmbedPurchase_Success";
import EmbedSearchData from "../Screens/embedCode/EmbedSearchData";

const EmbedCodeRoute = (props) => {
  return (
    <div>
      <Route
        exact
        path="/embed/l/:id"
        render={(props) => <EmbedPage {...props} />}
      />
      <Route
        exact
        path="/embed/cart/:id"
        render={(props) => <EmbedCart {...props} />}
      />
      <Route
        exact
        path="/embed/processed-to-payment/:id"
        render={(props) => <EmbedProcessToPayment {...props} />}
      />
      <Route
        exact
        path="/embed/processed-to-checkout/:id"
        render={(props) => <EmbedProcessedToCheckout {...props} />}
      />
      <Route
        exact
        path="/embed/processed-to-success/:id"
        render={(props) => <EmbedPurchase_Success {...props} />}
      />
      <Route
        exact
        path="/embed/search/:searchData"
        render={(props) => <EmbedSearchData {...props} />}
      />
    </div>
  );
};

export default EmbedCodeRoute;
