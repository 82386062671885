import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import removePic from "../../assets/images/remove.png";
import minus_active from "../../assets/images/minus_active.png";
import plus_active from "../../assets/images/plus_active.png";
import plus_inactive from "../../assets/images/plus_inactive.png";
import minus_inactive from "../../assets/images/minus_inactive.png";
import Profileheadermobile from "../../Common/Profileheadermobile";
import Cookies from "js-cookie";
import UnloginuserHead from "../../Common/UnloginuserHead";
import { USER_REGISTER_FAIL } from "../../Store/Constants/userConstants";
import { useDispatch, useSelector } from "react-redux";
import { register as signup } from "../../Store/Actions/userActions";

const Purchase_Success = (props) => {
  console.log({ props });
  let history = useHistory();

  const dispatch = useDispatch();

  const [password, setPassword] = useState();
  console.log("Cookies.get('token')", Cookies.get("token"));

  const handlePassword = (e) => {
    e.preventDefault();
    localStorage.setItem("StepTwoPassword", password);
    // history.push("/signup-name");
    // history.push('/email-verification');
    accountCreation();
  };

  const accountCreation = () => {
    const params = {
      firstName: localStorage.getItem("StepThreeFirstName"),
      lastName: localStorage.getItem("StepThreeLastName"),
      email: localStorage.getItem("StepOneEmail"),
      password: localStorage.getItem("StepTwoPassword"),
    };
    signupApiCall(params);
  };
  const signupApiCall = (params) => {
    dispatch(signup(params)).then((res) => {
      if (res?.token) {
        Cookies.set("token", res?.token);
        Cookies.set("verified", res?.verified);
        localStorage.removeItem("StepOneEmail");
        localStorage.removeItem("StepTwoPassword");
        localStorage.removeItem("InviteCode");
        localStorage.removeItem("StepThreeLastName");
        localStorage.removeItem("StepThreeFirstName");

        if (res?.verified) {
          history.push("/account-created");
        } else {
          history.push("/email-verification");
        }
      } else {
        //setErrormsg(res?.msg);
        setTimeout(
          function () {
            // setErrormsg("");
            dispatch({ type: USER_REGISTER_FAIL, payload: [] });
          }.bind(this),
          5000
        );
      }
    });
  };

  return (
    <div>
      <div className="d-md-block d-none">
        {Cookies.get("token") ? (
          <Profileheadermobile history={props.history} />
        ) : (
          <UnloginuserHead history={props.history} />
        )}
      </div>
      <div>
        {/* <div className="desktop-header purchase without-account">
          <nav className="navbar navbar-expand-md">
            <a className="navbar-brand" href="#">
              <img src="images/logo_white_desktop.png" />
            </a>
            <div className="navbar-collapse" id="navbarSupportedContent">
              <div className="input-group search_group ml-auto">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                />
                <div className="input-group-append">
                  <button className="btn btn-secondary" type="button">
                    <img src="images/search.png" alt="search" />
                  </button>
                </div>
              </div>
              <div className="search-block-mobile">
                <button type="button">
                  <img src="images/search_white.png" />
                </button>
              </div>
              <ul className="navbar-nav login-dropdown">
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img src="images/person.png" alt="" />
                    <img src="images/down_angle_white.png" alt="" />
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <a className="dropdown-item" href="#">
                      Action
                    </a>
                    <a className="dropdown-item" href="#">
                      Another action
                    </a>
                    <a className="dropdown-item" href="#">
                      Something else here
                    </a>
                  </div>
                </li>
              </ul>
              <label className="cart_section">
                Cart
                <span>0</span>
              </label>
              <a href className="message_link">
                <img src="images/message.png" alt="message" />
              </a>
              <ul className="navbar-nav sign-log-link">
                <li className="nav-item">
                  <a className="nav-link login" href="#">
                    Sign up
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link login" href="#">
                    Log in
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div> */}

        <div class="mobile-header">
          <nav className="navbar">
            <a className="navbar-brand">
              <img src="images/logo.svg" />
            </a>
            <a
              onClick={() => {
                window.$("#successModal").modal("hide");
                props.history.push(
                  "/l/" + props.match.params.id
                );
              }}
            >
              <img src="images/close.png" />
            </a>
          </nav>
        </div>
        {Cookies.get("token") ? (
          <div className="content purchase desktop">
            <div className="center-content mt-md-0 mt-5 pt-5">
              <div className="create-password-wrapper mt-5">
                <h2 className="heading2 text-center">
                  Thank you for taking action to help Portland Forest Fire
                  Emergency Wishlist
                </h2>
                <p className="para text-center">
                  You are logged in so all transactions are saved in your
                  ActionList account.
                </p>
                <button
                  type="button"
                  className="btn btn-prime btn-widthprime"
                  onClick={() => {
                    // window.$("#successModal").modal("hide");
                    // props.history.push(
                    //   "/l/" + props.match.params.id
                    // );
                    props.history.push("/discover");
                  }}
                >
                  Back and explore the list
                </button>
                {/* <form>
                <div className="form-group">
                  <label htmlFor="createpassword">Create password</label>
                  <input
                    type="password"
                    className="form-control"
                    id="createpassword"
                    placeholder="Password"
                  />
                </div>
                <button type="submit" className="btn btn-prime">
                  Create an account
                </button>
              </form> 
              <div className="or">
                <label>or</label>
              </div>
              <button type="button" className="btn btn-extra">
                Have an account? Log in
              </button>*/}
              </div>
            </div>
          </div>
        ) : (
          <div className="content purchase desktop">
            <div className="center-content mt-md-0 mt-5 pt-5">
              <div className="create-password-wrapper mt-5">
                <h2 className="heading2 text-center">
                  Thank you for taking action to help Portland Forest Fire
                  Emergency Wishlist
                </h2>
                <p className="para text-center">
                  Create an account to keep all your data in ActionList.
                </p>

                {/* <button
                type='button'
                className='btn btn-prime btn-widthprime'
                onClick={() => {
                  // window.$("#successModal").modal("hide");
                  // props.history.push(
                  //   "/l/" + props.match.params.id
                  // );
                  props.history.push('/discover');
                }}
              >
                Back and explore the list
              </button> */}
                <form>
                  <div className="form-group">
                    <label htmlFor="createpassword">Create password</label>
                    <input
                      type="password"
                      className="form-control"
                      id="createpassword"
                      placeholder="Password"
                      onChange={(e) => setPassword(e.target.value)}
                      onKeyUp={(e) => e.keyCode === 13 && handlePassword(e)}
                    />
                  </div>
                  <button
                    onClick={(e) => handlePassword(e)}
                    className="btn btn-prime"
                  >
                    Create an account
                  </button>
                </form>
                <div className="or">
                  <label>or</label>
                </div>
                <button
                  type="button"
                  className="btn btn-extra"
                  onClick={() => {
                    props.history.push("/login");
                  }}
                >
                  Have an account? Log in
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Purchase_Success;
