import React, { useState } from "react";
import whiteSearch from "../assets/images/search_white.png";
import Person from "../assets/images/person.png";
import DownAngleWhite from "../assets/images/down_angle_white.svg";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { logout, getWishList } from "../Store/Actions/userActions";
import Search from "../assets/images/search.png";
import closeimg from "../assets/images/close.png";
import {
  MyListRoute,
  MyActionsListRoute,
  ProfileAccSettingsRoute,
} from "../Routing/RouteConstants";
import { useHistory } from "react-router";

const HeaderDropdown = ({ needsScreen }, props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const ImagebaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;

  const [show, setShow] = useState(false);
  const [searchData, setSearchData] = useState();

  const logOut = () => {
    Cookies.remove("token");
    Cookies.remove("verified");
    dispatch(logout());
    dispatch(getWishList([]));
    history?.push("/discover");
    // window.location.href = "/";
    window.location.reload();
  };

  const searchfun = () => {
    console.log(" in serach", searchData);
    if (searchData) {
      history.push("/search/" + searchData);
      setSearchData("");
    }
    setShow(false);
  };
  return (
    <div className="new-profileheadernav">
      <ul className="navbar-nav login-dropdown">
        <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {/* {userInfo?.userImage ? */}
            <div className="profile-pic88">
              <img
                src={
                  userInfo?.userImage
                    ? ImagebaseUrl + userInfo?.userImage
                    : ImagebaseUrl + "1628062524365avatar.png"
                }
                alt=""
              />
            </div>
            {/* // :
              // <img src={Person} alt='' />
              // } */}
            <img src={DownAngleWhite} alt="" />
          </a>
          <div
            className="dropdown-menu login-dropmenu"
            aria-labelledby="navbarDropdown"
          >
            <Link className="dropdown-item" to={ProfileAccSettingsRoute}>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.2774 13.2307L16.0044 14.1165L13.7224 13.2305C11.7855 12.1654 10.8112 10.1476 10.8112 8.28135V6.73749C10.8112 3.96809 13.3151 1.83398 16 1.83398C18.6849 1.83398 21.1889 3.96809 21.1889 6.73749V8.28135C21.1889 10.1204 20.2134 12.1659 18.2774 13.2307ZM11.0546 17.1187L16.005 15.1895L20.9752 17.1192C24.1374 18.6743 26.1667 21.9375 26.1667 25.501V28.6673C26.1667 29.4957 25.4951 30.1673 24.6667 30.1673H7.33338C6.50495 30.1673 5.83337 29.4957 5.83337 28.6673V25.5297C5.83337 21.9676 7.8616 18.7029 11.0546 17.1187Z"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  stroke="#FFB100"
                />
              </svg>
              Account Settings
            </Link>
            <Link className="dropdown-item" to={MyActionsListRoute}>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M23.9054 28.7098C23.9718 29.4907 23.3286 30.1666 22.4882 30.1666H3.33325C2.50483 30.1666 1.83325 29.495 1.83325 28.6666V9.42188C1.83325 8.58125 2.50914 7.9385 3.28946 8.00558C14.2364 8.9466 22.9712 17.7157 23.9054 28.7098Z"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  stroke="#FFB100"
                />
                <path
                  d="M30.0556 16.6076C30.1434 17.3696 29.5124 18.047 28.6666 18.047H15.52C14.6916 18.047 14.02 17.3755 14.02 16.547V3.33398C14.02 2.48793 14.6975 1.85749 15.4587 1.9462C23.1007 2.83681 29.1714 8.93154 30.0556 16.6076Z"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  stroke="#FFB100"
                />
              </svg>
              My Actions
            </Link>
            <Link className="dropdown-item" to={MyListRoute}>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M24.31 30.1673H7.69004C5.91407 30.1673 4.5 28.7752 4.5 27.0936V4.90773C4.5 3.22614 5.91407 1.83398 7.69004 1.83398H24.31C26.0859 1.83398 27.5 3.22614 27.5 4.90773V27.1182C27.4728 28.7797 26.0803 30.1673 24.31 30.1673Z"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  stroke="#FFB100"
                />
                <path
                  d="M12 14.5518V18.4663L20.8102 13.334"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  stroke="#FFB100"
                />
              </svg>
              My Lists
            </Link>
            <Link className="dropdown-item" to={"/contactus/"}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="12" cy="12" r="9.5" stroke="#FFB100" />
                <path
                  d="M9.4082 7.07247C9.7602 6.8698 10.1335 6.72047 10.5282 6.62447C10.9335 6.52847 11.3175 6.48047 11.6802 6.48047C12.2989 6.48047 12.8375 6.58714 13.2962 6.80047C13.7655 7.0138 14.1282 7.30714 14.3842 7.68047C14.6402 8.0538 14.7682 8.49647 14.7682 9.00847C14.7682 9.49914 14.6722 9.93647 14.4802 10.3205C14.2989 10.7045 14.0642 11.0618 13.7762 11.3925C13.4989 11.7125 13.2109 12.0378 12.9122 12.3685C12.6242 12.6991 12.3735 13.0565 12.1602 13.4405C11.9469 13.8138 11.8189 14.2458 11.7762 14.7365L11.7282 15.0245H10.9602V14.6245C10.9602 14.0698 11.0402 13.5845 11.2002 13.1685C11.3709 12.7525 11.5789 12.3791 11.8242 12.0485C12.0695 11.7178 12.3149 11.4031 12.5602 11.1045C12.8055 10.7951 13.0082 10.4805 13.1682 10.1605C13.3389 9.84047 13.4242 9.48314 13.4242 9.08847C13.4242 8.6298 13.2642 8.26714 12.9442 8.00047C12.6242 7.7338 12.1815 7.60047 11.6162 7.60047C11.2642 7.60047 10.8962 7.65914 10.5122 7.77647C10.1389 7.88314 9.77087 8.04847 9.4082 8.27247V7.07247ZM10.5282 18.0005V16.3525H12.1762V18.0005H10.5282Z"
                  fill="#FFB100"
                />
              </svg>
              Help Center
            </Link>
            <div className="dropdown-divider"></div>
            <a
              className="dropdown-item"
              onClick={(e) => {
                e.preventDefault();
                logOut();
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 5.99902C18.1755 3.57069 15.2712 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C15.2712 22 18.1755 20.4293 20 18.001"
                  stroke="#607BFF"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M22 12H11"
                  stroke="#607BFF"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14 8L9 12L14 16"
                  stroke="#607BFF"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Log out
            </a>
          </div>
        </li>
      </ul>
      {needsScreen ? null : (
        <div className="search-block-mobile">
          <button type="button">
            <img
              src={whiteSearch}
              alt="whitesearch"
              onClick={() => setShow(true)}
            />
          </button>
          <div className={`input-group search_group ${show ? "show" : ""}`}>
            <input
              type="text"
              className="form-control"
              placeholder="Search..."
              value={searchData}
              onChange={(e) => setSearchData(e.target.value)}
              onKeyUp={(e) => e.keyCode === 13 && searchfun()}
            />
            <div className="input-group-append">
              <button
                className="btn btn-secondary"
                type="button"
                onClick={() => searchfun()}
              >
                <img src={Search} alt="search" />
              </button>
            </div>
            <div className="closesearch">
              <button className="btn btn-close">
                <img
                  src={closeimg}
                  alt="search"
                  onClick={() => setShow(false)}
                />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default HeaderDropdown;
