import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../style.css";

import Edit from "../../../assets/images/edit.png";
import Close from "../../../assets/images/close2.png";
import ProfilePic from "../../../assets/images/profile_pic_alt.png";

import CrossRed from "../../../assets/images/cross_red.png";
import TickGreen from "../../../assets/images/tick_green.png";
import Buysvg from "../../../Common/SvgImage/Buysvg";
import Givesvg from "../../../Common/SvgImage/Givesvg";
import SvgforDo from "../../../Common/SvgImage/SvgforDo";
import emailImg from "../../../assets/images/email.svg";
import phoneImg from "../../../assets/images/phone.svg";

import back_black from "../../../assets/images/back_black.png";

import close_gray from "../../../assets/images/close_gray.png";
import tick_gray from "../../../assets/images/tick_gray.png";
// import ManageGiveList from "./ManageGiveList";

import send from "../../../assets/images/send.png";
import profile_pic_alt2 from "../../../assets/images/profile_pic_alt2.svg";
import back from "../../../assets/images/back.png";

import moment from "moment";
import AxiosApi from "../../../Config/axios-api";
import {
  getNeedsList,
  postLastMessage,
} from "../../../Store/Actions/userActions";
import { ToastContainer, toast } from "react-toastify";
import { postOrderStatus, renderStatus } from "../postOrderStatus";
import kebab from "../../../assets/images/kebab.png";
import duplicate from "../../../assets/images/duplicate.png";
import Delete from "../../../assets/images/delete.png";
import { stringCapitalize } from "../../../services/stringModify";
import Swal from "sweetalert2";
import { useHistory } from "react-router";
// import '../../../node_modules/react-toastify/dist/ReactToastify.min.css';
const ChatAPI = require("twilio-chat");
function NeedsCreationNeedInfoPreview(props) {
  let yesterdayCount = 0;
  let todayCount = 0;
  let dayCount = 0;
  let uniqueDate = "";
  const imgBaseUrl = "http://api.actionlist.com/static/images/";
  const dispatch = useDispatch();
  let [selectedItem, setselectedItem] = useState(props.selectedItem);
  let [selectedNeedData, setselectedNeedData] = useState([]);
  const [isNotNeededConfirm, setIsNotNeededConfirm] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [activelink, setActiveLisnk] = useState(props.selectedItem.needType);

  let history = useHistory();

  let [needsFilters, setFilters] = useState(false);
  const [scrolltop, setScrollTop] = useState(false);

  const [selectedneedList, setNeedList] = useState();

  const [messages, setMessages] = useState([]);
  const [text, setText] = useState("");
  const [channel, setChannel] = useState(null);
  const [userName, setUserName] = useState(userInfo?.userId);
  const [loginUserName, setLoginUserName] = useState("");
  const [chatNeedId, setChatNeedId] = useState("");
  const [chatActionListId, setChatActionListId] = useState("");
  const [chatUserId, setChatUserId] = useState("");
  const [lastMsg, setLastMsg] = useState("");
  const [chatChannelId, setChatChannelId] = useState("");
  const [userPersonalDetails, setUserPersonalDetails] = useState("");
  const [isCancel, setIsCancel] = useState(false);
  const [isMarkasDone, setIsMarkasDone] = useState(false);

  const [chatId, setChatId] = useState("");

  let [params, setParams] = useState(props.selectedNeedData);
  const [loading, setLoading] = useState(false);
  let [needsList, setNeedsList] = useState([]);
  let [needs, setNeeds] = useState([]);
  let scrollDiv = useRef(null);

  const getuserNeedsList = useSelector((state) => state.getuserNeedsList);
  const { loading: needsLoading, userNeedlistInfo, error } = getuserNeedsList;
  const [selectedNeed, setSelectedNeeds] = useState([]);
  const [selectedaction, setselectedaction] = useState();

  const getNeedsListgData = () => {
    dispatch(getNeedsList(params));
  };

  const [userImg, setUserImg] = useState("");
  const [filteredNeedsList, setFilteredNeedsList] = useState([]);
  const [download, setDownload] = useState([]);
  const [commissionPercentageRateData, setCommissionPercentageRateData] =
    useState(null);
  const [allMesagesItem, setAllMesagesItem] = useState();

  const totalAmount = selectedNeed.reduce(
    (subTotal, item) => subTotal + item?.items?.amount,
    0
  );

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  console.log("propsssssss", props);
  useEffect(() => {
    if (props.selectedNeedData != "") {
      getChatList();
    }
  }, [props.selectedNeedData, isNotNeededConfirm, isConfirm]);

  useEffect(() => {
    setselectedItem(props.selectedItem);
  }, [props.selectedItem]);
  const getChatList = async (needID) => {
    var urlstr = "/order/usersFromNeedId/" + props.selectedNeedData;

    const { data } = await AxiosApi.get(urlstr);
    setselectedNeedData(data);
  };
  // const postOrderStatus = (status, odrId, nedId) => {
  //   let data={
  //       orderId: odrId,
  //       needId: nedId,
  //       status: status
  //   }
  //   dispatch(setOrderStatusForMyActions(data, userInfo?.token)).then((res)=>{
  //       if(res?.data){
  //           // console.log(res);
  //           toast.success(res?.data)
  //       }else{
  //           toast.error("Somthing went wrong!")
  //       }
  //   })
  //   if(status === "declined"){
  //       setIsNotNeededConfirm(true)
  //   }else if(status === "confirmed"){
  //       setIsConfirm(true)
  //   }
  // }
  /////////////////////////////////////////////////////////////////////////////////////// manage action same start

  useEffect(() => {
    if (userNeedlistInfo) {
      setNeeds(userNeedlistInfo);
    }
  }, [userNeedlistInfo]);

  useEffect(() => {

    console.log({params})
    getNeedsListgData();
    // getdonateList();
    console.log("--", userInfo);
  }, [props.activelink]);

  useEffect(async () => {
    setselectedaction(JSON.parse(localStorage.getItem("selectedList")));
    const response = await AxiosApi.get("/admin/commissionPercentageRate", {
      headers: { token: userInfo?.token },
    });
    if (response?.data) {
      response?.data?.filter((item) => {
        if (item?.commissionFor === "donation") {
          let commData = { ...item };
          setCommissionPercentageRateData(commData);
        }
      });
    }
  }, []);

  const getdonateList = async () => {
    var userIddata = JSON.parse(localStorage.getItem("userInfo"));
    var urlstr = "/order/getOrdersByActionListId/" + props.match.params.id;

    const { data } = await AxiosApi.get(urlstr);
    // console.log(">>>-->", data);
    setNeedsList(data);
    setFilteredNeedsList(data);
    setDownload(data);
  };

  function filters() {
    setFilters(!needsFilters);
  }

  const updatescroll = (val) => {
    if (!scrolltop && val > 120) {
      setScrollTop(true);
    } else if (scrolltop && val < 90) {
      setScrollTop(false);
    }
  };

  const openselectedModal = (data) => {
    if (data === "buy") {
      window.$("#manageInfoPreviewModal").modal("show");
    } else if (data === "give") {
      window.$("#manageInfoPreviewModalgive").modal("show");
    } else if (data === "do") {
      window.$("#manageInfoPreviewModaldo").modal("show");
    }
  };

  const deductionAmount =
    (totalAmount / 100) * commissionPercentageRateData?.commissionPercentage;
  const mangeInfoPreview =
    activelink === "buy"
      ? "manageInfoPreviewModal"
      : activelink === "give"
      ? "manageInfoPreviewModalgive"
      : "manageInfoPreviewModaldo";

  const manageChatModal = (data) => {
    // if (data === 'buy') {
    //   window.$('#manageChatModalbuy').modal('show');
    //   window.$('#manageInfoPreviewModal').modal('hide');
    // } else {
    //   window.$('#manageChatModalbuy').modal('show');
    //   window.$('#manageInfoPreviewModaldo').modal('hide');
    // }

    if (data === "buy") {
      window.$("#manageChatModalbuy").modal("show");
      window.$("#manageInfoPreviewModal").modal("hide");
    } else if (data === "give") {
      window.$("#manageChatModalgive").modal("show");
      window.$("#manageInfoPreviewModalgive").modal("hide");
    } else {
      window.$("#manageChatModaldo").modal("show");
      window.$("#manageInfoPreviewModaldo").modal("hide");
    }
  };

  const closechat = (data) => {
    // if (data === 'buy') {
    //   window.$('#manageChatModalbuy').modal('hide');
    //   window.$('#manageInfoPreviewModal').modal('show');
    // } else {
    //   window.$('#manageChatModalbuy').modal('hide');
    //   window.$('#manageInfoPreviewModaldo').modal('show');
    // }

    if (data === "buy") {
      window.$("#manageChatModalbuy").modal("hide");
      window.$("#manageInfoPreviewModal").modal("show");
    } else if (data === "give") {
      window.$("#manageChatModalgive").modal("hide");
      window.$("#manageInfoPreviewModalgive").modal("show");
    } else {
      window.$("#manageChatModaldo").modal("hide");
      window.$("#manageInfoPreviewModaldo").modal("show");
    }
  };

  const postLastMsg = (msg) => {
    console.log("xyz", { allMesagesItem });
    console.log("xyz", { lastMsg });
    let data = {};
    if (chatId) {
      // data = {
      //   chatId: chatId,
      //   receiverId: chatUserId,
      //   message: [
      //     {
      //       msg: lastMsg,
      //       msgDate: moment(),
      //       sendBy: loginUserName,
      //       read: false,
      //     },
      //   ],
      // };
      data = {
        chatId: chatId,
        actionListId: allMesagesItem.items.actionListId,
        needId: allMesagesItem.items.needId,
        //need login id
        senderId: allMesagesItem.items.needId,
        receiverId: allMesagesItem?.userId,
        channelName: chatChannelId,
        message: {
          msg: msg,
          msgDate: moment(),
          //login user name
          sendBy: userInfo.firstName + " " + userInfo.lastName,
          read: false,
        },
      };
    } else {
      // data = {
      //   actionListId: chatActionListId,
      //   needId: chatNeedId,
      //   senderId: userInfo?.userId,
      //   receiverId: chatUserId,
      //   channelName: chatChannelId,
      //   message: [
      //     {
      //       msg: lastMsg,
      //       msgDate: moment(),
      //       sendBy: loginUserName,
      //       read: false,
      //     },
      //   ],
      // };
      data = {
        actionListId: allMesagesItem.items.actionListId,
        needId: allMesagesItem.items.needId,
        //need login id
        senderId: allMesagesItem.items.needId,
        receiverId: allMesagesItem?.userId,
        channelName: chatChannelId,
        message: {
          msg: msg,
          msgDate: moment(),
          //login user name
          sendBy: userInfo.firstName + " " + userInfo.lastName,
          read: false,
        },
      };
    }

    dispatch(postLastMessage(userInfo.token, data))
      .then((res) => {
        if (res.data) {
        }
        getChatList(allMesagesItem.items.needId);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getToken = async (name) => {
    // const response = await axios.get(
    //   `http://dev107.developer24x7.com:3001/order/twilioToken?username=${name}`
    // );
    const response = await AxiosApi.get(`/order/twilioToken?username=${name}`);
    const { data } = response;
    return data;
  };

  const twilioChat = async (roomName) => {
    setMessages([]);
    let token = "";
    setLoading(true);
    try {
      token = await getToken(userName);
    } catch {
      console.log("Unable to get token, please reload this page");
    }

    const client = await ChatAPI.Client.create(token);

    client.on("tokenAboutToExpire", async () => {
      const token = await getToken(userName);
      client.updateToken(token);
    });

    client.on("tokenExpired", async () => {
      const token = await getToken(userName);
      client.updateToken(token);
    });

    client.on("channelJoined", async (channel) => {
      // getting list of all messages since this is an existing channel
      const newMessages = await channel.getMessages();

      if (channel.channelState.uniqueName === roomName) {
        setMessages(newMessages.items);
        // setLastMsgIndex(newMessages?.items?.channel?.channelState?.lastMessage?.index)
        scrollToBottom();
      }
    });

    try {
      const channel = await client.getChannelByUniqueName(roomName);
      await joinChannel(channel);
      setChannel(channel);
    } catch (err) {
      try {
        const channel = await client.createChannel({
          uniqueName: roomName,
          friendlyName: roomName,
        });

        await joinChannel(channel);
        setChannel(channel);
      } catch {
        console.log("Unable to create channel, please reload this page");
      }
    }
  };

  const joinChannel = async (channel) => {
    if (channel.channelState.status !== "joined") {
      await channel.join();
    }

    setChannel(channel);
    setLoading(false);

    channel.on("messageAdded", function (message) {
      handleMessageAdded(message);
    });
  };

  const handleMessageAdded = (message) => {
    setMessages((messages) => [...messages, message]);
    scrollToBottom();
  };

  const scrollToBottom = () => {
    //console.log("object", scrollDiv.current.scrollTop);
    const scrollHeight = scrollDiv?.current?.scrollHeight;
    const height = scrollDiv?.current?.clientHeight;
    const maxScrollTop = scrollHeight - height;
    //console.log("object", scrollHeight + " - " + height + " = " + maxScrollTop);
    if (
      scrollDiv?.current?.scrollTop !== null &&
      scrollDiv?.current?.scrollTop !== undefined &&
      scrollDiv?.current?.scrollTop !== "" &&
      scrollDiv?.current?.scrollTop !== NaN
    ) {
      scrollDiv.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  };

  const sendMessage = (e, isSendButClick) => {
    console.log(
      "abcddd",
      e.keyCode + " == " + " 13 && " + e.shiftKey + " " + text
    );
    console.log("abcddd", channel?.sendMessage);
    if (e.keyCode == 13 && e.shiftKey) {
      return "";
    } else if (e.keyCode == 13) {
      if (text) {
        setLoading(true);
        channel?.sendMessage(String(text).trim());
        setLastMsg(text);
        setText("");
        setLoading(false);
        //closechat(activelink,);
        postLastMsg(text);
      }
    } else if (isSendButClick) {
      if (text) {
        setLoading(true);
        channel?.sendMessage(String(text).trim());
        setLastMsg(text);
        setText("");
        setLoading(false);
        postLastMsg(text);
        //closechat(activelink,text);
      }
    }

    // if (text) {
    //   setLoading(true);
    //   channel.sendMessage(String(text).trim());
    //   setLastMsg(text)
    //   setText('');
    //   setLoading(false);
    // }
  };
  const openShareModal = () => {
    window.$("#shareListModal").modal("show");
  };

  const generateChatId = (self, uid, needid) => {
    console.log("channelid", self + " > " + uid);
    if (self > uid) return `${self}-${uid}-${needid}`;
    else return `${uid}-${self}-${needid}`;
  };
  const handleOrderApprove = async (odrId) => {
    try {
      const { data } = await AxiosApi.post(`order/approveOrder/${odrId}`, {
        headers: {
          token: userInfo.token,
        },
      });

      return data;
    } catch (error) {
      return error;
    }
  };

  const postOrderStatusRetrun = async (status, odrId, nedId, activelink) => {
    // console.log(
    //   "abcdef",
    //   status + " " + odrId + " " + nedId + " " + " " + activelink
    // );
    const returnVal = await dispatch(postOrderStatus(status, odrId, nedId));
    if (returnVal === "declined") {
      setIsNotNeededConfirm(true);
    } else if (returnVal === "confirmed") {
      handleOrderApprove(odrId);
      setIsConfirm(true);
    } else if (returnVal === "done") {
      setIsMarkasDone(true);
    } else if (returnVal === "cancel") {
      setIsCancel(true);
    }
    // if (status.includes("declined")) {
    //   const returnVal = await dispatch(postOrderStatus(status, odrId, nedId));
    //   if (returnVal === "declined") {
    //     setIsNotNeededConfirm(true);
    //   } else if (returnVal === "confirmed") {
    //     setIsConfirm(true);
    //   } else if (returnVal === "done") {
    //     setIsMarkasDone(true);
    //   } else if (returnVal === "cancel") {
    //     setIsCancel(true);
    //   }
    // } else if (status.includes("confirmed")) {
    //   manageChatModal(activelink);
    //   const returnVal = await dispatch(postOrderStatus(status, odrId, nedId));
    //   if (returnVal === "declined") {
    //     setIsNotNeededConfirm(true);
    //   } else if (returnVal === "confirmed") {
    //     setIsConfirm(true);
    //   } else if (returnVal === "done") {
    //     setIsMarkasDone(true);
    //   } else if (returnVal === "cancel") {
    //     setIsCancel(true);
    //   }
    // } else {
    //   const returnVal = await dispatch(postOrderStatus(status, odrId, nedId));
    //   if (returnVal === "declined") {
    //     setIsNotNeededConfirm(true);
    //   } else if (returnVal === "confirmed") {
    //     setIsConfirm(true);
    //   } else if (returnVal === "done") {
    //     setIsMarkasDone(true);
    //   } else if (returnVal === "cancel") {
    //     setIsCancel(true);
    //   }
    // }
  };

  /////////////////////////////////////////////////////////////////////////////////////////////// manage action close

  const renderDayName = (msgDate, payload) => {
    let finalDate = "";
    const msgDay = moment(msgDate.toString()).format("DD MMMM YYYY");
    const yesterday = new Date(new Date().valueOf() - 1000 * 60 * 60 * 24);
    if (msgDay === moment().format("DD MMMM YYYY")) {
      todayCount += 1;
      return todayCount === 1 && "Today";
    }
    if (msgDay === moment(yesterday).format("DD MMMM YYYY")) {
      yesterdayCount += 1;
      return yesterdayCount === 1 && "Yesterday";
    }
    if (uniqueDate === "" || uniqueDate !== msgDay) {
      uniqueDate = msgDay;
      payload?.length > 0 &&
        payload?.map((item) => {
          const itemDate = moment(item?.date?.toString()).format(
            "DD MMMM YYYY"
          );
          if (itemDate === msgDay) {
            dayCount += 1;
            if (dayCount === 1) {
              finalDate = msgDay;
            }
          }
        });
      return finalDate;
    } else {
      dayCount = 0;
      return finalDate;
    }
  };

  const duplicateNeeds = async (item) => {
    const { data } = await AxiosApi.get(
      "/actionList/createDuplicateNeeds/" + item.needId,
      {}
    );
    if (data.msg === "duplicate needs created!") {
      toast.success(stringCapitalize(data?.msg));
      props.checkNeedDeleteOrDuplicate();
      props.func("closeInfoPreview", props.selectedItem);
    } else {
      toast.error("Something went worng!");
    }
  };

  const deleteNeeds = async (item) => {
    if (selectedItem?.qtyOrdered === 0) {
      Swal.fire({
        title: "Are you sure to want delete need!",
        showCancelButton: true,
        confirmButtonColor: "#e8271a",
        confirmButtonText: `Delete`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const { data } = await AxiosApi.delete(
              "/actionList/removeNeedById/" + item.needId,
              {}
            );
            if (data.msg === "need removed.") {
              Swal.fire("Deleted!", stringCapitalize(data?.msg), "success");
              props.checkNeedDeleteOrDuplicate();
              props.func("closeInfoPreview", props.selectedItem);
            } else {
              toast.error("Something went worng!");
            }
          } catch (error) {
            toast.error(error?.msg);
          }
        }
      });
    } else {
      window.$("#deleteNeedMsg").modal("show");
    }

    // const { data } = await AxiosApi.delete(
    //   '/actionList/removeNeedById/' + item.needId,
    //   {}
    // );
    // if (data.msg === 'need removed.') {
    //   toast.success(stringCapitalize(data?.msg))
    //   props.checkNeedDeleteOrDuplicate()
    //   props.func('closeInfoPreview', props.selectedItem)

    // }else{
    //   toast.error("Something went worng!")
    // }
  };
  const renderMessageWindow = (val) => {
    return (
      <div
        className="modal manage-chat-modal"
        tabindex="-1"
        role="dialog"
        id={
          val === "buy"
            ? "manageChatModalbuy"
            : val === "give"
            ? "manageChatModalgive"
            : "manageChatModaldo"
        }
        data-backdrop="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <div className="mobile-header">
                <nav className="navbar">
                  <a
                    onClick={() => closechat(val)}
                    className="back desktop-view"
                  >
                    <img src={back} alt="" />
                    Back
                  </a>
                  <a
                    onClick={() => closechat(val)}
                    className="back mobile-view"
                  >
                    <img src={back_black} alt="" />
                  </a>
                  <label className="navbar-brand mx-auto middle-label mobile-view">
                    {selectedneedList?.Name}
                  </label>
                </nav>
              </div>
            </div>

            <div className="modal-body">
              <div
                className="profile-row manage-profilerow"
                style={{ width: "100%" }}
              >
                <div className="user_name_hover">
                  <img
                    className="user_DP"
                    src={userImg ? imgBaseUrl + userImg : profile_pic_alt2}
                  />
                  <label className="name">{`${loginUserName}`}</label>
                </div>
                <div className="hoverDataShow">
                  <p>
                    <img
                      className="user_DP newUser-dp"
                      src={
                        userPersonalDetails?.userImage
                          ? imgBaseUrl + userPersonalDetails?.userImage
                          : profile_pic_alt2
                      }
                    />
                  </p>
                  <h4>
                    <strong>
                      {userPersonalDetails?.userName &&
                        userPersonalDetails?.userName}
                    </strong>
                  </h4>
                  <p className="action-token">Action Token</p>
                  <strong className="action-number">
                    {userPersonalDetails?.actionTaken &&
                      userPersonalDetails?.actionTaken}
                  </strong>
                  <hr></hr>
                  <p className="list-para">
                    <img src={phoneImg} />
                    {" " + userPersonalDetails?.phoneNumber &&
                      userPersonalDetails?.phoneNumber}
                  </p>
                  <p className="list-para">
                    <img src={emailImg} />
                    {" " + userPersonalDetails?.email &&
                      userPersonalDetails?.email}
                  </p>
                  <p className="requirement-text">Requires pick up from:</p>
                  <p className="address-text">
                    Patrick Melrose st 56/526, 88374, London, UK
                  </p>
                </div>

                <label
                  className={
                    "status position-statusfixed " +
                    renderStatus(userPersonalDetails?.items?.needOrderStatus)
                  }
                >
                  {renderStatus(userPersonalDetails?.items?.needOrderStatus)}
                </label>

                {!isNotNeededConfirm &&
                  !isConfirm &&
                  (userPersonalDetails?.items?.needOrderStatus === "pending" ||
                    userPersonalDetails?.items?.needOrderStatus ===
                      undefined) && (
                    <div className="button-row">
                      <button
                        type="button"
                        className="not-needed notNeeded-activebtn"
                        onClick={() =>
                          postOrderStatusRetrun(
                            "declined",
                            userPersonalDetails?.orderId,
                            userPersonalDetails?.items?.needId
                          )
                        }
                      >
                        <img src={close_gray} />
                        <label>Not needed</label>
                      </button>
                      <button
                        type="button"
                        className="confirm confirm-activebtn"
                        onClick={() =>
                          postOrderStatusRetrun(
                            "confirmed",
                            userPersonalDetails?.orderId,
                            userPersonalDetails?.items?.needId
                          )
                        }
                      >
                        <img src={tick_gray} />
                        <label>Confirm</label>
                      </button>
                    </div>
                  )}

                {!isCancel &&
                  !isMarkasDone &&
                  userPersonalDetails?.items?.needOrderStatus ===
                    "confirmed" && (
                    <div className="button-row">
                      <button
                        type="button"
                        className="cancel notNeeded-activebtn"
                        onClick={() =>
                          postOrderStatusRetrun(
                            "cancel",
                            userPersonalDetails?.orderId,
                            userPersonalDetails?.items?.needId
                          )
                        }
                      >
                        <label>Cancel</label>
                      </button>
                      <button
                        type="button"
                        className="done confirm-activebtn"
                        onClick={() =>
                          postOrderStatusRetrun(
                            "done",
                            userPersonalDetails?.orderId,
                            userPersonalDetails?.items?.needId
                          )
                        }
                      >
                        <label>Mark as Done</label>
                      </button>
                    </div>
                  )}
              </div>
              <div className="chat-box newcustom-checkbox">
                <div className="inner-wrapper" ref={scrollDiv}>
                  {/* {loading ? (
                    <label className='label-day'>Loading chat...</label>
                  ) : (
                    <label className='label-day'>Today</label>
                  )} */}
                  {messages.length > 0 &&
                    messages.map((message) => {
                      const isOwnMessage = message?.author === userName;
                      console.log("paritosh1234", message);
                      return (
                        <>
                          {isOwnMessage ? (
                            <div className="text-row me" key={message.index}>
                              <label className="label-day">
                                {renderDayName(
                                  message.dateCreated,
                                  messages,
                                  "mesgs"
                                )}
                              </label>
                              <div className="textbox">
                                {/* <span className='status'>Sending...</span> */}
                                <label className="chat-text">
                                  {message.body}
                                </label>
                                <span className="time">
                                  {moment(message.dateCreated).format("LT")}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div className="text-row them" key={message.index}>
                              <label className="label-day">
                                {renderDayName(
                                  message.dateCreated,
                                  messages,
                                  "mesgs"
                                )}
                              </label>
                              <div className="textbox">
                                <label className="chat-text">
                                  {message.body}
                                </label>
                                <span className="time">
                                  {moment(message.dateCreated).format("LT")}
                                </span>
                              </div>
                            </div>
                          )}
                        </>
                      );
                    })}
                  {/* <div className='thnkx-row'>
                    <button
                      type='button'
                      className='say-thnkxbtn'

                    >
                      <img src={chatImg} />
                      <label>Say Thanks</label>
                    </button>
                  </div> */}
                </div>

                <div className="send-box message-righttextarea">
                  <div className="input-group">
                    <textarea
                      type="text"
                      className="form-control"
                      placeholder="Write a message..."
                      aria-label="Write a message..."
                      aria-describedby="basic-addon2"
                      value={text}
                      disabled={
                        userPersonalDetails?.items?.needOrderStatus ===
                        "declined"
                          ? true
                          : false
                      }
                      onChange={(event) => setText(event.target.value)}
                      onKeyUp={(e) => sendMessage(e, false)}
                    />
                    <div className="input-group-append">
                      <button
                        onClick={(e) => sendMessage(e, true)}
                        className="btn btn-outline-secondary"
                        type="button"
                        disabled={!channel || !text}
                      >
                        <img src={send} />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <div
        className="modal info-preview-modal Need-areanewpopup"
        tabindex="-1"
        role="dialog"
        style={{
          display: "block",
          maxHeight: "100%",
          overflowY: "auto",
          top: "80px",
        }}
        data-backdrop="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <div className="mobile-header">
                <nav className="navbar">
                  {/* <a href='#.' className='kebab'>
                  <img src={Kebab} alt='' />
                </a> */}
                  <label className="navbar-brand mx-auto middle-label">
                    Details
                  </label>
                  <a
                    href="#."
                    className="cross"
                    data-dismiss="modal"
                    onClick={(e) => {
                      e.preventDefault();
                      props.func("closeInfoPreview", props.selectedItem);
                    }}
                  >
                    <img src={Close} alt="" />
                  </a>
                </nav>
              </div>
            </div>

            {selectedItem?.qtyOrdered > 0 && (
              <div
                className="modal fade delete-list-modal delete-needpopup"
                id="deleteNeedMsg"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-header">
                    <button
                      type="button"
                      className="btn-close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => window.$("#deleteNeedMsg").modal("hide")}
                    >
                      <img src={Close} />
                    </button>
                  </div>
                  <div className="modal-content">
                    <div className="modal-body">
                      <h4>
                        You can't delete your ongoing need. Please cancel it if
                        you don't wish to continue further.
                      </h4>
                      <button
                        onClick={() => window.$("#deleteNeedMsg").modal("hide")}
                        type="button"
                        className="oky-btn"
                      >
                        Ok
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="modal-body">
              <div className="status-row">
                {selectedItem?.needStatus && (
                  <label
                    className={
                      selectedItem?.needStatus === "active" ||
                      selectedItem?.needStatus === "done"
                        ? "status-label active"
                        : "draft-background"
                    }
                  >
                    {selectedItem?.needStatus === "active"
                      ? "Active"
                      : selectedItem?.needStatus === "done"
                      ? "Done"
                      : "Draft"}
                  </label>
                )}
                <label className="update-label">
                  Last updated:{" "}
                  {selectedItem?.needStatus === "draft"
                    ? moment(selectedItem?.updatedAt).format(
                        "MMM DD YYYY, HH:SS a"
                      )
                    : moment(selectedItem?.completedBy).format(
                        "MMM DD YYYY, HH:SS a"
                      )}
                </label>
                <a
                  href="#."
                  className="edit-link"
                  onClick={(e) => {
                    e.preventDefault();
                    props.func("closeInfoPreview", props.selectedItem);
                    props.func("openNeedEditInfo", props.selectedItem);
                  }}
                >
                  <img src={Edit} alt="" />
                </a>
              </div>
              <div className="info-box">
                <div className="name-row">
                  {selectedItem.needType === "buy" && <Buysvg />}
                  {selectedItem.needType === "give" && <Givesvg />}
                  {selectedItem.needType === "do" && <SvgforDo />}

                  <label>{stringCapitalize(selectedItem?.title)}</label>
                  <a
                    href="#."
                    className="edit-link"
                    onClick={(e) => {
                      e.preventDefault();
                      props.func("closeInfoPreview", props.selectedItem);
                      props.func("openNeedEditInfo", props.selectedItem);
                    }}
                  >
                    <img src={Edit} alt="" />
                  </a>
                  <div class="column-kebab rightside_dropdown">
                    <div class="dropdown">
                      <button
                        type="button"
                        class="btn dropdown-toggle"
                        data-toggle="dropdown"
                        // onClick={(e) => e.stopPropagation()}
                      >
                        <img src={kebab} />
                      </button>
                      <div class="dropdown-menu">
                        <a
                          class="dropdown-item"
                          onClick={(e) => (
                            e.stopPropagation(), duplicateNeeds(selectedItem)
                          )}
                        >
                          <img src={duplicate} alt="duplicate" />
                          <label className="duplicate-label">
                            Duplicate need
                          </label>
                        </a>
                        <a
                          class="dropdown-item delete"
                          onClick={(e) => (
                            e.stopPropagation(), deleteNeeds(selectedItem)
                          )}
                        >
                          <img src={Delete} alt="delete" />
                          <label className="delete-label">Delete need</label>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                {(selectedItem?.needStatus === "active" ||
                  selectedItem?.needStatus === "done") && (
                  <>
                    <label className="desc-row">
                      {selectedItem.description}
                    </label>
                    <div className="location-row">
                      {selectedItem.location && (
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M10 20C11.3333 20 18 15.7142 18 8.57139C18 3.83854 14.4173 0 10 0C5.58267 0 2 3.83854 2 8.57139C2 15.7142 8.66667 20 10 20ZM10 12C12.2091 12 14 10.2091 14 8C14 5.79086 12.2091 4 10 4C7.79086 4 6 5.79086 6 8C6 10.2091 7.79086 12 10 12Z"
                            fill="#72819B"
                          />
                        </svg>
                      )}
                      <label>{selectedItem.location}</label>
                    </div>
                    {selectedItem.needType != "do" && (
                      <div className="progress-row">
                        {selectedItem?.qtyOrdered} of {selectedItem?.qtyNeeded ? selectedItem?.qtyNeeded : 0}
                      </div>
                    )}
                  </>
                )}
              </div>
              {selectedItem?.needStatus === "draft" && (
                <div style={{ textAlign: "center" }} className="no-actiontext">
                  {" "}
                  No actions were taken yet
                </div>
              )}
              {(selectedItem?.needStatus === "active" ||
                selectedItem?.needStatus === "done") && (
                <>
                  <h5 className="inner-heading">Needs review</h5>
                  {selectedNeedData &&
                    selectedNeedData?.length &&
                    selectedNeedData.map((item, index) => {
                      return (
                        <>
                          <label className="date_label_for_chats">
                            {renderDayName(item?.date, selectedNeedData)}
                          </label>
                          <div
                            className="review-box"
                            style={{
                              marginBottom:
                                index === selectedNeedData.length - 1 && 150,
                            }}
                            onClick={() => {
                              openselectedModal(item);
                              setLoginUserName(item?.userName);
                              setChatActionListId(item?.items.actionListId);
                              setChatNeedId(item?.items?.needId);
                              setChatChannelId(
                                generateChatId(
                                  userInfo.userId,
                                  item?.userId,
                                  item?.items?.needId
                                )
                              );
                              setAllMesagesItem(item);
                              setChatId(item?.chatId);
                              setChatUserId(item?.userId);
                              setUserImg(item?.userImage);
                              setUserPersonalDetails(item);
                              twilioChat(
                                generateChatId(
                                  userInfo.userId,
                                  item?.userId,
                                  item?.items?.needId
                                )
                              );
                              manageChatModal(activelink);
                            }}
                          >
                            <div className="row1">
                              {item?.userImage ? (
                                <img
                                  src={imgBaseUrl + item?.userImage}
                                  className="profile-pic"
                                  alt=""
                                />
                              ) : (
                                <img
                                  src={ProfilePic}
                                  className="profile-pic"
                                  alt=""
                                />
                              )}
                              <label className="name"> {item?.userName}</label>
                              <label
                                className={
                                  "status " +
                                  renderStatus(item?.items?.needOrderStatus)
                                }
                              >
                                {renderStatus(item?.items?.needOrderStatus)}
                              </label>
                            </div>
                            <div className="row2">
                              <label className="message">
                                {item?.items?.needOrderStatus === "pending" ||
                                  (item?.items?.needOrderStatus === undefined &&
                                    "Sent the request to help")}
                              </label>
                              <label className="time">
                                {/* {moment(item?.date).format('lll')} */}
                                {moment(item.date).format("LT")}
                              </label>
                            </div>
                            {!isNotNeededConfirm &&
                              !isConfirm &&
                              (item?.items?.needOrderStatus === "pending" ||
                                item?.items?.needOrderStatus === undefined) && (
                                <div className="button-row">
                                  <button
                                    type="button"
                                    className="not-needed"
                                    // onClick={()=> postOrderStatus("declined", item?.orderId, item?.items?.needId)}
                                    onClick={() =>
                                      postOrderStatusRetrun(
                                        "declined",
                                        item?.orderId,
                                        item?.items?.needId
                                      )
                                    }
                                  >
                                    <img src={CrossRed} alt="" />
                                    <label>Not needed</label>
                                  </button>
                                  <button
                                    type="button"
                                    className="confirm"
                                    // onClick={()=> postOrderStatus("declined", item?.orderId, item?.items?.needId)}
                                    onClick={() =>
                                      postOrderStatusRetrun(
                                        "confirmed",
                                        item?.orderId,
                                        item?.items?.needId
                                      )
                                    }
                                  >
                                    <img src={TickGreen} alt="" />
                                    <label>Confirm</label>
                                  </button>
                                </div>
                              )}
                          </div>
                        </>
                      );
                    })}
                </>
              )}

              {/* <h5 className='inner-heading'>Today</h5> */}
              {/* <div className='review-box'>
              <div className='row1'>
                <img src={ProfilePic2} alt='' className='profile-pic' />
                <label className='name'>Jason Mamoa</label>
                <label className='status confirmed'>Confirmed</label>
              </div>
              <div className='row2'>
                <label className='comment'>
                  You: Yes, sure. Will keep in touch
                </label>
                <label className='time'>Yesterday</label>
              </div>
            </div> */}
            </div>
          </div>
        </div>

        <ToastContainer autoClose={3000} />
      </div>
      {renderMessageWindow(activelink)}
    </>
  );
}

export default NeedsCreationNeedInfoPreview;
