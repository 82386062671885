import React from 'react';
import {ShowPreviewData} from './showPreviewData';

const ListCreationPreviewSunshine = (props) => {
  return (
    <>
      <ShowPreviewData
        thmName = 'sunshine'
        history = {props?.history}
      />
    </>
  );
};

export default ListCreationPreviewSunshine;
