import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import sendImg from "../../assets/images/send.png";
import Profileheadermobile from "../../Common/Profileheadermobile";
import profile_pic_alt2 from "../../assets/images/profile_pic_alt2.png";
import BottomTab from "../../Common/BottomTab";
import do_colored from "../../assets/images/do_colored.png";
import give_colored from "../../assets/images/give_colored.png";
import buy_colored from "../../assets/images/buy_colored.png";
import right_angleimg from "../../assets/images/right_angle_gray.png";
import checkmarkImg from "../../assets/images/checkmark_blue.png";
import calendar from "../../assets/images/calendar.png";
import back_black from "../../assets/images/back_black.png";
import close_gray from "../../assets/images/close_gray.png";
import tick_gray from "../../assets/images/tick_gray.png";
import location from "../../assets/images/location.svg";
import angle_up from "../../assets/images/angle_up.png";
import angle_down from "../../assets/images/angle_down.png";
import clockImg from "../../assets/images/clock.svg";
import closeBut from "../../assets/images/cross_red.png";
import locationBule from "../../assets/images/location_blue.png";
import clearBut from "../../assets/images/clear.png";
import {
  getMyActionsRecord,
  setOrderStatusForMyActions,
  getActionListOnNeedForMsg,
} from "../../Store/Actions/userActions";
import moment from "moment";
import axios from "axios";
import { stringCapitalize } from "../../services/stringModify";
import { ToastContainer, toast } from "react-toastify";
import "../../../node_modules/react-toastify/dist/ReactToastify.min.css";
import Cookies from "js-cookie";
import Nouislider from "nouislider-react";

import PlacesAutocomplete from "react-places-autocomplete";

const ChatAPI = require("twilio-chat");

const MyActionsList = (props) => {
  let yesterdayCount = 0;
  let todayCount = 0;
  let dayCount = 0;
  let uniqueDate = "";
  const dispatch = useDispatch();
  const selectedActionContant = useSelector(
    (state) => state.getActionListOnNeedMsgInfo?.userMsgDetails
  );
  const myActionsRecords = useSelector(
    (state) => state.getMyActionsData?.myActionRecords
  );
  const userDetailsError = useSelector((state) => state.UserDetails?.error);
  let pendingCount = 0;
  let confirmedCount = 0;
  let scrollDiv = useRef(null);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [filter, setfilter] = useState(false);
  const [modal, setModal] = useState(false);
  const [msgOrganizer, setMsgOrganizer] = useState(false);
  const [isCancelAction, setIsCancelAction] = useState(false);
  const [isCancelActionConfrm, setIsCancelActionConfrm] = useState(false);
  const [isMarkusDoneConfirm, setIsMarkusDoneConfirm] = useState(false);
  const [radioButSelection, setRadioButSelection] = useState("give");
  const [selectedRecord, setSelectedRecord] = useState({});
  const [searchText, setSearchText] = useState("");
  const [actionsRecord, setActionsRecord] = useState(null);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [channel, setChannel] = useState(null);
  const [text, setText] = useState("");
  const [filterIsCompleted, setFilterIsCompleted] = useState(false);
  // const [filterType, setFilterType] = useState(false);
  const [filterFromAmount, setFilterFromAmount] = useState("");
  const [filterToAmount, setFilterToAmount] = useState("");

  // my work
  const [sliderRange, setSlinderRange] = useState("");

  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [place, setPlace] = useState("");

  useEffect(() => {
    if (userDetailsError?.invalidToken) {
      Cookies.set("token", "");
      Cookies.set("userInfo", null);
      localStorage.setItem("userInfo", null);
      props.history.push("/login");
    } else {
      setActionsRecord(null);
      dispatch(
        getMyActionsRecord(userInfo?.token, userInfo?.userId, radioButSelection)
      ).then((res) => {
        setActionsRecord();
        myActionsRecords && setActionsRecord(myActionsRecords);
        if (res?.data) {
          setActionsRecord(res?.data);
        }
      });
    }
  }, [radioButSelection, isCancelActionConfrm, isMarkusDoneConfirm]);
  const postOrderStatus = (status) => {
    let data = {
      orderId: selectedRecord?.orderId,
      needId: selectedRecord?.items?.needId,
      status: status,
    };
    dispatch(setOrderStatusForMyActions(data, userInfo?.token)).then((res) => {
      if (res?.data) {
        // console.log(res);
        toast.success(res?.data);
      } else {
        toast.error("Somthing went wrong!");
      }
    });
    if (status === "declined") {
      setIsCancelActionConfrm(true);
      setIsCancelAction(false);
    } else if (status === "done") {
      setIsMarkusDoneConfirm(true);
    }
  };

  const getSelectedAction = (item) => {
    dispatch(getActionListOnNeedForMsg(item?.items?.needId));
    setModal(!modal);
    setSelectedRecord(item);
    twilioChat(generateChatId(userInfo?.userId, item?.actionListUserId));
    handleActionStatusCheck(item?.items?.needOrderStatus);
  };

  const generateChatId = (self, uid) => {
    if (self > uid) return `${self}-${uid}`;
    else return `${uid}-${self}`;
  };

  const handleActionStatusCheck = (status) => {
    // if(status === "pending"){
    //     pendingCount += 1
    //     if(pendingCount === 1){
    //         return "Under review"
    //     }else{
    //         return ""
    //     }
    // }else if(status === "confirmed"){
    //     confirmedCount += 1
    //     if(confirmedCount === 1){
    //         return "Tomorrow"
    //     }else{
    //         return ""
    //     }
    // }
    if (status === "declined") {
      return "canceled";
    } else if (status === "done" || status === "confirmed") {
      return "confirmed";
    } else {
      return "pending";
    }
  };
  const getToken = async (name) => {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL_LIVE}/order/twilioToken?username=${name}`
    );
    const { data } = response;
    return data;
  };

  const handleMessageAdded = (message) => {
    setMessages((messages) => [...messages, message]);
    scrollToBottom();
  };

  const scrollToBottom = () => {
    //     const scrollHeight = scrollDiv.current.scrollHeight;
    //     const height = scrollDiv.current.clientHeight;
    //     const maxScrollTop = scrollHeight - height;
    //     scrollDiv.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  };

  const joinChannel = async (channel) => {
    if (channel.channelState.status !== "joined") {
      await channel.join();
    }

    setChannel(channel);
    setLoading(false);

    channel.on("messageAdded", function (message) {
      handleMessageAdded(message);
    });
  };

  const sendMessage = (e, isSendButClick) => {
    if (e.keyCode == 13 && e.shiftKey) {
      return "";
    } else if (e.keyCode == 13) {
      if (text) {
        setLoading(true);
        channel.sendMessage(String(text).trim());
        //   setLastMsg(text)
        setText("");
        setLoading(false);
      }
    } else if (isSendButClick) {
      if (text) {
        setLoading(true);
        channel.sendMessage(String(text).trim());
        //   setLastMsg(text)
        setText("");
        setLoading(false);
      }
    }
    // if (text) {
    //   setLoading(true);
    //   channel.sendMessage(String(text).trim());
    // //   setLastMsg(text)
    //   setText('');
    //   setLoading(false);
    // }
  };

  const twilioChat = async (roomName) => {
    setMessages([]);
    let token = "";
    setLoading(true);
    try {
      token = await getToken(userInfo?.userId);
    } catch {
      console.log("Unable to get token, please reload this page");
    }

    const client = await ChatAPI.Client.create(token);

    client.on("tokenAboutToExpire", async () => {
      const token = await getToken(userInfo?.userId);
      client.updateToken(token);
    });

    client.on("tokenExpired", async () => {
      const token = await getToken(userInfo?.userId);
      client.updateToken(token);
    });

    client.on("channelJoined", async (channel) => {
      // getting list of all messages since this is an existing channel
      const newMessages = await channel.getMessages();

      if (channel.channelState.uniqueName === roomName) {
        setMessages(newMessages.items);
        // setLastMsgIndex(newMessages?.items[0]?.channel?.channelState?.lastMessage?.index)
        scrollToBottom();
      }
    });

    try {
      const channel = await client.getChannelByUniqueName(roomName);
      await joinChannel(channel);
      setChannel(channel);
    } catch (err) {
      try {
        const channel = await client.createChannel({
          uniqueName: roomName,
          friendlyName: roomName,
        });

        await joinChannel(channel);
        setChannel(channel);
      } catch {
        console.log("Unable to create channel, please reload this page");
      }
    }
  };

  const handleSearching = (e) => {
    const { value } = e.target;
    setSearchText(value);
    let val = value.toLowerCase();
    const filteredRecords = myActionsRecords?.filter((item) => {
      if (
        item?.title.toLowerCase().includes(val) ||
        item?.items?.Name.toLowerCase().includes(val) ||
        item?.items?.needOrderStatus.toLowerCase().includes(val)
      ) {
        return item;
      }
    });
    setActionsRecord(filteredRecords);
  };

  const renderDayName = (msgDate, actnsRecord) => {
    let finalDate = "";

    const msgDay = moment(msgDate).format("DD MMMM YYYY");
    const yesterday = new Date(new Date().valueOf() - 1000 * 60 * 60 * 24);
    if (msgDay === moment().format("DD MMMM YYYY")) {
      todayCount += 1;
      return todayCount === 1 && "Today";
    }
    if (msgDay === moment(yesterday).format("DD MMMM YYYY")) {
      yesterdayCount += 1;
      return yesterdayCount === 1 && "Yesterday";
    }
    if (uniqueDate === "" || uniqueDate !== msgDay) {
      uniqueDate = msgDay;
      actnsRecord?.length > 0 &&
        actnsRecord?.map((item) => {
          const itemDate = moment(item?.date).format("DD MMMM YYYY");
          if (itemDate === msgDay) {
            dayCount += 1;
            if (dayCount === 1) {
              finalDate = msgDay;
            }
          }
        });
      return finalDate;
    } else {
      dayCount = 0;
      return finalDate;
    }
  };

  const renderDayNameMsg = (msgDate, msges) => {
    let finalDate = "";
    const msgDay = moment(msgDate.toString()).format("MM/DD/YYYY");
    const yesterday = new Date(new Date().valueOf() - 1000 * 60 * 60 * 24);
    if (msgDay === moment().format("DD/MMMM/YYYY")) {
      todayCount += 1;
      return todayCount === 1 && "Today";
    }
    if (msgDay === moment(yesterday).format("MM/DD/YYYY")) {
      yesterdayCount += 1;
      return yesterdayCount === 1 && "Yesterday";
    }
    if (uniqueDate === "" || uniqueDate !== msgDay) {
      uniqueDate = msgDay;
      msges?.length > 0 &&
        msges?.map((item) => {
          const itemDate = moment(item?.dateCreated?.toString()).format(
            "MM/DD/YYYY"
          );
          if (itemDate === msgDay) {
            dayCount += 1;
            if (dayCount === 1) {
              finalDate = msgDay;
            }
          }
        });
      return finalDate;
    } else {
      dayCount = 0;
      return finalDate;
    }
  };

  const closeLocationList = () => {};

  const showMatchedLocations = () => {};

  const openLocationList = () => {};

  const switchView = () => {};

  const onSlide = (render, handle, value, un, percent) => {
    console.log(value);
    setSlinderRange(value);
  };

  const handleChange = (place) => {
    setPlace(place);
  };

  const handleSelect = (place) => {
    setPlace(place);
  };

  const range = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
    "Last 7 Days": [moment().subtract(6, "days"), moment()],
    "Last 30 Days": [moment().subtract(29, "days"), moment()],
    "This Month": [moment().startOf("month"), moment().endOf("month")],
    "Last Month": [
      moment().subtract(1, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ],
    "Last Year": [
      moment().subtract(1, "year").startOf("year"),
      moment().subtract(1, "year").endOf("year"),
    ],
  };

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleEvent = (event, picker) => {
    console.log("start: ", moment(picker.startDate._d).format("DD-MM-YYYY"));
    console.log("end: ", moment(picker.endDate._d).format("DD-MM-YYYY"));
    setStartDate(moment(picker.startDate._d).format("DD-MM-YYYY"));
    setEndDate(moment(picker.endDate._d).format("DD-MM-YYYY"));
    //    setFromDate(picker.startDate._d.toISOString());
    //setToDate(picker.endDate._d.toISOString());
  };

  const renderHeaderSreach = () => {
    return (
      <div className="search-panel">
        <button
          className="btn-action btn-search collapsed"
          type="button"
          data-toggle="collapse"
          data-target="#collapseSearch"
          aria-expanded="false"
          aria-controls="collapseExample"
        >
          <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx={13} cy={11} r={8} strokeWidth={2} />
            <path
              d="M7 17L2.5 21.5"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <div className="collapse searchCollapse" id="collapseSearch">
          <input
            value={searchText}
            type="text"
            name="searchInput"
            id="searchInput"
            placeholder="Search for action..."
            onChange={(e) => handleSearching(e)}
          />
          <div className="close-inputsearch">
            {searchText && (
              <img src={clearBut} onClick={() => setSearchText("")} />
            )}
          </div>
        </div>

        {/* <div className="searchResults" id="searchResults">
          <div className="result-content">
            <label className="result-header">LAST MONTH</label>
            <ul>
              <li>Feedback</li>
              <li>Partnership</li>
              <li className="selected">Media</li>
            </ul>
            <label className="result-header">EARLIER</label>
            <ul>
              <li>Partnership</li>
            </ul>
            <a href="javascript:void(0);" className="see-all">
              See all results
            </a>
          </div>
          <div className="searching hide">
            <label className="result-header">
              Search for “<span id="searchword" />”
            </label>
            <div className="loader">
              <div className="inner-wrapper">
                <img src="images/loading.png" alt="loading" />
              </div>
            </div>
          </div>
        </div> */}
      </div>
    );
  };

  const handleFilter = () => {
    console.log(filterIsCompleted);
    console.log(sliderRange);
    console.log(startDate);
    console.log(endDate);
    console.log(myActionsRecords);
    let filterdData = [];
    if (radioButSelection == "buy") {
      if (filterIsCompleted) {
        filterdData = [];
        filterdData = myActionsRecords?.filter((item) => {
          if (
            item?.items?.needOrderStatus === "done" &&
            item?.items?.needOrderStatus === "confirmed" &&
            item?.items?.amount >= sliderRange[0] &&
            item?.items?.amount <= sliderRange[1] &&
            moment(item?.date).format("DD-MM-YYYY") >= startDate &&
            moment(item?.date).format("DD-MM-YYYY") <= endDate
          ) {
            console.log("moment(item?.date)");
            return item;
          }
        });
        setActionsRecord(filterdData);
      } else {
        filterdData = [];
        filterdData = myActionsRecords?.filter((item) => {
          if (
            item?.items?.needOrderStatus === "pending" &&
            item?.items?.amount >= sliderRange[0] &&
            item?.items?.amount <= sliderRange[1] &&
            moment(item?.date).format("DD-MM-YYYY") >= startDate &&
            moment(item?.date).format("DD-MM-YYYY") <= endDate
          ) {
            return item;
          }
        });
        setActionsRecord(filterdData);
      }
    } else if (radioButSelection == "give") {
      if (filterIsCompleted) {
        filterdData = [];
        filterdData = myActionsRecords?.filter((item) => {
          console.log(moment(item?.date).format("DD-MM-YYYY"));
          if (
            item?.items?.needOrderStatus === "done" &&
            item?.items?.needOrderStatus === "confirmed" &&
            moment(item?.date).format("DD-MM-YYYY") >= startDate &&
            moment(item?.date).format("DD-MM-YYYY") <= endDate
          ) {
            return item;
          }
        });
        setActionsRecord(filterdData);
      } else {
        filterdData = [];
        filterdData = myActionsRecords?.filter((item) => {
          if (
            item?.items?.needOrderStatus === "pending" &&
            moment(item?.date).format("DD-MM-YYYY") >=
              (startDate ? startDate : "01-07-2019") &&
            moment(item?.date).format("DD-MM-YYYY") <=
              (endDate ? endDate : "01-12-2021")
          ) {
            return item;
          }
        });
        setActionsRecord(filterdData);
      }
    }
    // if (filterIsCompleted) {
    //   const completedRecords = myActionsRecords?.filter((item) => {
    //     if (
    //       ((item?.items?.needOrderStatus === "done" &&
    //         item?.items?.needOrderStatus === "confirmed") ||
    //         (item?.items?.amount >= sliderRange[0] &&
    //           item?.items?.amount >= sliderRange[1])) ||
    //       moment(item?.date).format("DD-MM-YYYY") >=
    //         (startDate ? startDate : "01-07-2019") &&
    //       moment(item?.date).format("DD-MM-YYYY") <=
    //         (endDate ? endDate : "01-12-2021")
    //     ) {
    //       return item;
    //     }

    //   });
    //   console.log({ completedRecords });
    //   setActionsRecord(completedRecords);
    // } else {
    //   const incompletedRecords = myActionsRecords?.filter((item) => {

    //     if (
    //       item?.items?.needOrderStatus == "pending" &&
    //       (moment(item?.date).format("DD-MM-YYYY") >=
    //         (startDate ? startDate : "01-07-2019") &&
    //         moment(item?.date).format("DD-MM-YYYY") <=
    //           (endDate ? endDate : "01-12-2021"))
    //     ) {
    //       return item;
    //     }
    //   });
    //   console.log({ incompletedRecords });
    //   setActionsRecord(incompletedRecords);
    // }
    setfilter(false);
  };

  const renderHeaderFilter = () => {
    return (
      <div
        className={
          filter ? "btn-group filter-wrap show" : "btn-group filter-wrap"
        }
      >
        <button
          onClick={() => setfilter(!filter)}
          type="button"
          className="dropdown-toggle btn-action btn-filter"
          id="dropdownMenuButton"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x={1} y={3} width={22} height={4} rx={2} />
            <rect x={4} y={10} width={16} height={4} rx={2} />
            <rect x={7} y={17} width={10} height={4} rx={2} />
          </svg>
        </button>

        {radioButSelection == "buy" ? (
          <div
            className={
              filter
                ? "dropdown-menu dropdown-menu-right show"
                : "dropdown-menu dropdown-menu-right"
            }
            aria-labelledby="dropdownMenuButton"
          >
            {console.log("in buy")}
            <div className="content-wrap">
              <div className="mobile-header">
                <nav className="navbar">
                  <label className="navbar-brand mx-auto middle-label">
                    Donations filters
                  </label>
                  <a href="javascript:void(0);" className="cross close-filter">
                    <img
                      src="images/close2.png"
                      onClick={() => setfilter(!filter)}
                      alt=""
                    />
                  </a>
                </nav>
              </div>
              <h6 className="dropdown-header">Filters</h6>
              {/* <label className="status-label">Status of the need</label> */}
              <ul
                className="nav nav-tabs filter-tabs filter_actionlist"
                id="filterTab"
                role="tablist"
              >
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="completed_tab"
                    data-toggle="tab"
                    href="#completed_content"
                    role="tab"
                    aria-controls="completed"
                    aria-selected="false"
                    onClick={() => {
                      setFilterIsCompleted(true);
                    }}
                  >
                    <span className="checkmark">
                      <img src={checkmarkImg} />
                    </span>
                    Completed
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="incomplete_tab"
                    data-toggle="tab"
                    href="#incomplete_content"
                    role="tab"
                    aria-controls="incomplete"
                    aria-selected="false"
                    onClick={() => {
                      setFilterIsCompleted(false);
                    }}
                  >
                    <span className="checkmark">
                      <img src={checkmarkImg} />
                    </span>
                    Incomplete
                  </a>
                </li>
              </ul>
              <div className="filter-dropdown-content">
                <div
                  className="tab-content filter-tab-content"
                  // id="filters_tab_content"
                >
                  {/* Complete content */}
                  {/* {console.log({ filterType })} */}

                  <div
                    className="tab-pane fade show active "
                    // id="completed_content"
                    role="tabpanel"
                    aria-labelledby="my-messages-tab"
                  >
                    <form>
                      <div className="form-group">
                        <label htmlFor="donation_date">
                          When donations were made
                        </label>
                        <div className="input-wrapper">
                          <img src={calendar} alt="date" />
                          <DateRangePicker
                            ranges={range}
                            alwaysShowCalendars={true}
                            onEvent={handleEvent}
                          >
                            <input
                              type="text"
                              className="form-control"
                              id="donation_date"
                              // value={
                              //   moment(fromDate).format("MMM DD, YYYY") +
                              //   " - " +
                              //   moment(toDate).format("MMM DD, YYYY")
                              // }
                            />
                          </DateRangePicker>
                        </div>
                      </div>
                      <div className="divider" />
                      <div className="form-group">
                        <label htmlFor="donation_date">Price range</label>
                        {/* <div id="priceSlider" className="priceSlider" /> */}
                        {console.log("in buy2")}

                        <div id="priceSlider" class="priceSlider">
                          <Nouislider
                            range={{ min: 0, max: 2000 }}
                            start={[0, 50]}
                            connect
                            step={10}
                            onSlide={onSlide}
                            style={{
                              boxShadow: "none",
                              background: "transparent",
                              border: "none",
                            }}
                          />
                        </div>

                        <div className="priceValue">
                          <div className="priceMin">
                            <label htmlFor="priceMin">From</label>
                            {/* <input
                            type="number"
                            value={filterFromAmount}
                            id="priceMin"
                            min={0}
                            onChange={(e) =>
                              setFilterFromAmount(e.target.value)
                            }
                          /> */}
                            <input
                              type="text"
                              class="form-control"
                              id="minPrice"
                              placeholder="0"
                              min="0"
                              value={sliderRange[0]}
                            />
                          </div>
                          <span>-</span>
                          <div className="priceMax">
                            <label htmlFor="priceMax">To</label>
                            {/* <input
                            type="number"
                            id="priceMax"
                            value={filterToAmount}
                            min={filterFromAmount}
                            onChange={(e) => setFilterToAmount(e.target.value)}
                          /> */}

                            <input
                              type="text"
                              class="form-control"
                              id="maxPrice"
                              placeholder="1500"
                              max="2000"
                              value={sliderRange[1]}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="button-panel">
                        <button
                          type="button"
                          className="btn btn-prime"
                          onClick={handleFilter}
                        >
                          Apply filters
                        </button>
                      </div>
                    </form>
                  </div>

                  {/* Incomplete content end */}
                </div>
              </div>
            </div>
          </div>
        ) : (
          radioButSelection == "give" && (
            <div
              className={
                filter
                  ? "dropdown-menu dropdown-menu-right show"
                  : "dropdown-menu dropdown-menu-right"
              }
              aria-labelledby="dropdownMenuButton"
            >
              <div className="content-wrap">
                <div className="mobile-header">
                  <nav className="navbar">
                    <label className="navbar-brand mx-auto middle-label">
                      Actions filters
                    </label>
                    <a
                      href="javascript:void(0);"
                      className="cross close-filter"
                    >
                      <img
                        src="images/close2.png"
                        onClick={() => setfilter(!filter)}
                        alt=""
                      />
                    </a>
                  </nav>
                </div>
                <h6 className="dropdown-header">Filters</h6>
                {/* <label className="status-label">Status of the need</label> */}
                <ul
                  className="nav nav-tabs filter-tabs filter_actionlist"
                  id="filterTab"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="completed_tab"
                      data-toggle="tab"
                      href="#completed_content"
                      role="tab"
                      aria-controls="completed"
                      aria-selected="false"
                      onClick={() => {
                        setFilterIsCompleted(true);
                      }}
                    >
                      <span className="checkmark">
                        <img src={checkmarkImg} />
                      </span>
                      Completed
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="incomplete_tab"
                      data-toggle="tab"
                      href="#incomplete_content"
                      role="tab"
                      aria-controls="incomplete"
                      aria-selected="false"
                      onClick={() => {
                        setFilterIsCompleted(false);
                      }}
                    >
                      <span className="checkmark">
                        <img src={checkmarkImg} />
                      </span>
                      Incomplete
                    </a>
                  </li>
                </ul>
                <div className="filter-dropdown-content">
                  <div
                    className="tab-content filter-tab-content"
                    //  id="filters_tab_content"
                  >
                    {/* Complete content */}
                    {/* {console.log({ filterType })} */}

                    <div
                      className="tab-pane fade show active  "
                      // id="incomplete_content"
                      role="tabpanel"
                      aria-labelledby="list-messages-tab"
                    >
                      <form>
                        <div className="form-group">
                          <label htmlFor="complete_date">
                            When actions should be complete by
                          </label>
                          <div className="input-wrapper">
                            <img src={calendar} alt="date" />
                            {/* <input
                          type="text"
                          className="form-control"
                          id="complete_date"
                          placeholder="March 29, 2021 - Mar 31, 2021"
                        /> */}

                            <DateRangePicker
                              ranges={range}
                              alwaysShowCalendars={true}
                              onEvent={handleEvent}
                            >
                              <input
                                type="text"
                                className="form-control"
                                id="donation_date"
                                // value={
                                //   moment(fromDate).format("MMM DD, YYYY") +
                                //   " - " +
                                //   moment(toDate).format("MMM DD, YYYY")
                                // }
                              />
                            </DateRangePicker>
                          </div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="locationInput">
                            Location of actions
                          </label>
                          <div className="input-wrapper">
                            {/* <img src={locationBule} alt="location" />
                        <input
                          type="text"
                          className="form-control"
                          id="locationInput"
                          placeholder="Enter location"
                          onFocus={openLocationList()}
                          onBlur={closeLocationList()}
                          onKeyUp={showMatchedLocations()}
                        /> */}
                            <PlacesAutocomplete
                              value={place}
                              onChange={handleChange}
                              onSelect={handleSelect}
                            >
                              {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                                loading,
                              }) => (
                                <>
                                  <img src={locationBule} alt="location" />
                                  <input
                                    {...getInputProps({
                                      placeholder: "Enter Location",
                                      className: "form-control",
                                      id: "place",
                                      name: "place",
                                    })}
                                  />

                                  <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map((suggestion) => {
                                      const className = suggestion.active
                                        ? "suggestion-item--active"
                                        : "suggestion-item";
                                      // inline style for demonstration purpose
                                      const style = suggestion.active
                                        ? {
                                            backgroundColor: "#fafafa",
                                            cursor: "pointer",
                                          }
                                        : {
                                            backgroundColor: "#ffffff",
                                            cursor: "pointer",
                                          };
                                      return (
                                        <div
                                          key={suggestion.placeId}
                                          {...getSuggestionItemProps(
                                            suggestion,
                                            {
                                              className,
                                              style,
                                            }
                                          )}
                                        >
                                          <span>{suggestion.description}</span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </>
                              )}
                            </PlacesAutocomplete>
                          </div>
                          {/* <div className="location-list" id="locationList">
                        <ul id="recent" className="recent">
                          <li>
                            <img src="images/recent.png" />
                            Toronto, Ontario, Canada
                          </li>
                          <li>
                            <img src="images/recent.png" />
                            Washington, US
                          </li>
                          <li>
                            <img src="images/recent.png" />
                            New York, US
                          </li>
                        </ul>
                        <ul id="matched" className="matched">
                          <li>
                            <img src="images/location.png" />
                            Oklahoma, Oklahoma, US
                          </li>
                          <li>
                            <img src="images/location.png" />
                            Oakland, California, US
                          </li>
                          <li>
                            <img src="images/location.png" />
                            Ottawa, Canada
                          </li>
                        </ul>
                      </div> */}
                        </div>
                        <div className="button-panel">
                          <button
                            type="button"
                            className="btn btn-prime"
                            onClick={handleFilter}
                          >
                            Apply filters
                          </button>
                        </div>
                      </form>
                    </div>

                    {/* Incomplete content end */}
                  </div>
                </div>
              </div>
            </div>
          )
        )}
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div className="header-row header-rowpadd">
        <div className="left">
          <h3 className="subhead">My Actions</h3>
        </div>
        <div className="right">
          <ul
            className="nav nav-tabs content-nav desktop-content-nav"
            id="myTab"
            role="tablist"
          >
            <li className="nav-item">
              <a
                className="nav-link active"
                id="do_and_give_actions_tab"
                data-toggle="tab"
                href="#do_and_give_actions_content"
                role="tab"
                aria-controls="do-and-give-actions"
                aria-selected="false"
                onClick={() => {
                  setRadioButSelection("give");
                }}
              >
                <span className="checkmark" />
                Do and Give Actions
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="donations_tab"
                data-toggle="tab"
                href="#donations_content"
                role="tab"
                aria-controls="donations"
                aria-selected="false"
                onClick={() => {
                  setRadioButSelection("buy");
                }}
              >
                <span className="checkmark" />
                Donations
              </a>
            </li>
          </ul>
          {renderHeaderSreach()}
          {renderHeaderFilter()}
        </div>
      </div>
    );
  };

  const renderDoAndGiveActionsContent = () => {
    return (
      <div
        className="tab-pane fade show active"
        id="do_and_give_actions_content"
        role="tabpanel"
        aria-labelledby="my-messages-tab"
      >
        {actionsRecord && actionsRecord.length == 0 && (
          <div style={{ marginTop: "100px", textAlign: "center" }}>
            <h3>No data</h3>
          </div>
        )}
        {actionsRecord
          ?.slice()
          ?.reverse()
          ?.map((item) => (
            <div className="list-content">
              <h5 className="list-header">
                {renderDayName(item?.date, actionsRecord)}
              </h5>
              <div
                className="list-item pending"
                onClick={() => getSelectedAction(item)}
              >
                <div className="column-first">
                  <div className="left">
                    {item?.items?.needType === "do" && <img src={do_colored} />}
                    {item?.items?.needType === "give" && (
                      <img src={give_colored} />
                    )}
                    {item?.items?.needType === "buy" && (
                      <img src={buy_colored} />
                    )}
                    <img src={item?.actionImg} />
                  </div>
                  <div className="right">
                    <label className="row1">{item?.items?.Name}</label>
                    <label className="row2">{item?.title}</label>
                  </div>
                </div>
                <div className="column-status">
                  <label
                    className={handleActionStatusCheck(
                      item?.items?.needOrderStatus
                    )}
                  >
                    {stringCapitalize(
                      handleActionStatusCheck(item?.items?.needOrderStatus)
                    )}
                  </label>
                </div>
                <div className="column-last">
                  <button type="button">
                    <img src={right_angleimg} />
                  </button>
                </div>
              </div>
            </div>
          ))}
      </div>
    );
  };

  const renderDonationsContent = () => {
    return (
      <div
        className="tab-pane fade"
        id="donations_content"
        role="tabpanel"
        aria-labelledby="list-messages-tab"
      >
        {actionsRecord
          ?.slice()
          ?.reverse()
          ?.map((item) => (
            <>
              <div className="list-content">
                {/* <h5 className="list-header">{moment(item?.date).format("DD MMMM YYYY")}</h5> */}
                <h5 className="list-header">
                  {renderDayName(item?.date, actionsRecord)}
                </h5>
                <div
                  className="list-item pending"
                  onClick={() => getSelectedAction(item)}
                >
                  <div className="column-first">
                    <div className="left">
                      {item?.items?.needType === "do" && (
                        <img src={do_colored} />
                      )}
                      {item?.items?.needType === "give" && (
                        <img src={give_colored} />
                      )}
                      {item?.items?.needType === "buy" && (
                        <img src={buy_colored} />
                      )}
                    </div>
                    <div className="right">
                      <label className="row1">{item?.items?.Name}</label>
                      <label className="row2">{item?.items?.needType}</label>
                    </div>
                  </div>
                  <div className="column-status">
                    <label className="donate">
                      {"Donate $" + item?.items?.amount}
                    </label>
                    {/* <label className="donate">Donate $12</label> */}
                  </div>
                  <div className="column-last">
                    <button type="button">
                      <img src={right_angleimg} />
                    </button>
                  </div>
                </div>
              </div>
            </>
          ))}
      </div>
    );
  };

  const renderChatWindow = () => {
    return (
      <div className="content profile-rowcontent profile-rowcontentNew">
        <div className="profile-row modalprofile-row profile-rowwidth">
          <div className="modalprofile-rowdiv">
            <img src={profile_pic_alt2} />
          </div>
          <label className="name">{selectedRecord?.userName}</label>
        </div>
        {isCancelActionConfrm && (
          <div className="show_canceled_status_on_chat status-feild">
            <p>Canceled</p>
          </div>
        )}
        {isMarkusDoneConfirm && (
          <div className="show_confirmed_status_on_chat status-feild">
            <p>Confirmed</p>
          </div>
        )}
        <div
          className={
            "show_" +
            handleActionStatusCheck(selectedRecord?.items?.needOrderStatus) +
            "_status_on_chat"
          }
        >
          <p>
            <strong>
              {isCancelActionConfrm === false &&
                isMarkusDoneConfirm === false &&
                stringCapitalize(
                  handleActionStatusCheck(
                    selectedRecord?.items?.needOrderStatus
                  )
                )}
            </strong>
          </p>
        </div>
        <div className="action-panel">
          {!isCancelActionConfrm &&
            !isMarkusDoneConfirm &&
            (selectedRecord?.items?.needOrderStatus === "pending" ||
              selectedRecord?.items?.needOrderStatus === undefined) && (
              <div className="button-row">
                <button
                  type="button"
                  className="cancel"
                  onClick={() => setIsCancelAction(true)}
                  data-toggle="modal"
                  data-target="#cancelActionModalDesktop"
                >
                  <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEJSURBVHgBvZW7DcIwEIbvNxSMEUZhFCQaOkRFR1gApUOiYQWYADYhY/BQYuJIVkJevrMFf5HCOX+fdD4nRD8OzGO9O96JFOV6NEvieUoB2cSH6IXR1fD228VUVa90pJBdV/EpIs9UcER2rRTkOpsVgjRE8g1Harph1mELVkWBahRw29UFt3tRL/SRDMFbAqnEBe8UcCUceK/AJeHCBwV9kgk96CU4p0FBl6QYapIMgVPQlpTb2GOsiJEJ+ccpaB6o9MYrCdy0RfpZgQRuey65jJDCbbgS+MAlEvjCuRKEwDkShMJdknJMn2p8CoGbJPEyrY8w8E7MeilAThcCzqE/fSvRwA0aN/pHPvcRPDAJQfd3AAAAAElFTkSuQmCC" />
                  <label>Cancel Action</label>
                </button>
                <button
                  type="button"
                  className="done"
                  onClick={() => postOrderStatus("done")}
                >
                  <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGoSURBVHgB7VRNTsJQEJ5pG+MSb4A3cMkSTiCcwJCAWyu4cMcoLhVwZSIk1RNg4gFg5VZuYI9gWPLTcd6DktoU2mqXfIv2Td/rNzPf994D2CMGCBmDyMlNcf4JYECnVTs2IGNMjbkjdef9ONMEjXa/BYxlSeB6bJbUt8wkatKgzMhDTcpYeaDamxpn0sE1PeWF3NEBw41PrmDBP6HIZ2iOZJhjxscu1Sg4rztotp8vmneDM/gDZobZXZmKLoNJ4XmdwPOQeMkvSkdIgbCpPap+RyZA0U29lY42Ofkk5Kpr8IB0gexVhNyNWqcTdKjeY4SxDHMmLoZx5NpU6VoHUlyPzifb1m52EXtWRZ6iI5w0bvvdXeRBU6U4gh3YJFD6GYzVdVX2FfWLUT/MDcvxTZUdY0MMfp2De6qPhfxSjT2EYdgPZSozFIMnNVUChaAfaCwc/3tSU8OIvCps0dnQOmNeSqCD5eJ1htaXnpQOVRGQEFvvIuWByDRah2p/+yc1VvcgzG0TH+N3t1A6PZIKChIeynsilVcgJXZedt1WzV75ge6SrdTkeyTCDxsTthKzqYJNAAAAAElFTkSuQmCC" />
                  <label>Mark as Done</label>
                </button>
              </div>
            )}
        </div>
        {isCancelAction && (
          <div
            className="modal fade cancel-action-modal show"
            id="cancelActionModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            data-backdrop="false"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    type="button"
                    className="btn-close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => setIsCancelAction(false)}
                  >
                    <img src="images/close2.png" />
                  </button>
                  <div className="content">
                    <h2>Cancel your action?</h2>
                    <p>
                      Are you sure you want to cancel the action? It will be
                      deleted from your “My Action” list.
                    </p>
                    <button
                      type="button"
                      className="btn-extra"
                      onClick={() => postOrderStatus("declined")}
                    >
                      {/* <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2 2L21.7818 21.8161" stroke strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M22 2L2.21817 21.8161" stroke strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                                </svg> */}
                      <img src={closeBut} />
                      <label>I understand, cancel</label>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="chat-box chat-boxNewlist">
          <div className="inner-wrapper" ref={scrollDiv}>
            {/* {loading ? (
              <label class="label-day">Loading chat...</label>
            ) : (
              <label class="label-day">
                {messages.length == 0 ? "" : "Today"}
              </label>
            )} */}

            {messages.length > 0 &&
              messages.map((message) => {
                const isOwnMessage = message?.author === userInfo?.userId;
                console.log("---", message);
                return (
                  <>
                    {!isOwnMessage ? (
                      <div className="text-row them" key={message.index}>
                        {/* <label class="label-day">
                          {renderDayName(message.dateCreated, messages)}
                        </label> */}
                        <div className="textbox type2 chat-textboxWidth">
                          <label className="chat-text">{message.body}</label>
                          <span className="time">
                            {moment(message.dateCreated).format("LT")}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="text-row me" key={message.index}>
                        <label class="label-day">
                          {renderDayNameMsg(message.dateCreated, messages)}
                        </label>

                        <div className="textbox">
                          <label className="chat-text">{message.body}</label>
                          <span className="time">
                            {moment(message.dateCreated).format("LT")}
                          </span>
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
          </div>
          <div className="send-box message-righttextarea">
            <div className="input-group">
              <textarea
                type="text"
                value={text}
                className="form-control"
                placeholder="Write a message..."
                aria-label="Write a message..."
                aria-describedby="basic-addon2"
                onChange={(event) => setText(event.target.value)}
                onKeyUp={(e) => sendMessage(e, false)}
              />
              <div class="input-group-append">
                <button
                  onClick={(e) => sendMessage(e, true)}
                  class="btn btn-outline-secondary"
                  type="button"
                  disabled={!channel || !text}
                >
                  <img src={sendImg} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderModalPopContant = () => {
    return (
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <div
              className={
                modal && msgOrganizer
                  ? "do-action-details"
                  : "do-action-details show"
              }
            >
              <div className="mobile-header">
                <nav className="navbar">
                  <a onClick={() => setModal(!modal)} className="back">
                    <img src={back_black} alt="" />
                  </a>
                  <label className="navbar-brand mx-auto middle-label">
                    Details
                  </label>
                  <a
                    onClick={() => setModal(!modal)}
                    className="cross"
                    data-dismiss="modal"
                  >
                    <img src="images/close2.png" alt="" />
                  </a>
                </nav>
              </div>
              <div className="content">
                <div className="status-row">
                  <label
                    className={
                      selectedRecord?.items?.needOrderStatus === "declined"
                        ? "status-label declined"
                        : selectedRecord?.items?.needOrderStatus === "done" ||
                          selectedRecord?.items?.needOrderStatus === "confirmed"
                        ? "status-label confirmed"
                        : "status-label pending"
                    }
                  >
                    {stringCapitalize(
                      handleActionStatusCheck(
                        selectedRecord?.items?.needOrderStatus
                      )
                    )}
                  </label>
                </div>
                <div className="action-name-row">
                  <img src={give_colored} alt="action" />
                  <label>
                    {selectedActionContant?.title
                      ? selectedActionContant?.title
                      : ""}
                  </label>
                </div>
                <div className="action-desc-row">
                  {selectedActionContant?.description
                    ? selectedActionContant?.description
                    : ""}
                </div>
                <div className="location-row">
                  <img src={clockImg} />
                  <label>
                    Complete-by:{" "}
                    <strong>
                      {selectedActionContant?.updatedAt &&
                        moment(selectedActionContant.updatedAt).format(
                          "MMM DD YYYY, HH:SS a"
                        )}
                    </strong>
                  </label>
                </div>
                <div className="location-row">
                  {selectedActionContant?.location && <img src={location} />}
                  <label>
                    {selectedActionContant?.actionLists[0]?.place
                      ? selectedActionContant?.actionLists[0]?.place
                      : ""}
                  </label>
                </div>
                <div className="divider" />
                <h5 className="inner-heading">Pick up</h5>
                <div className="action-desc-row">
                  The organizer will be in touch to coordinate a time or state
                  the time it will be arranged
                </div>
                <div className="divider" />
                <div className="instruction-box">
                  <label>Private instructions:</label>
                  <p>
                    Contact Mr. Joyson to arrange pickup: +1 894 893 902 53.
                    Please call from 9AM to 6PM.
                  </p>
                </div>
                {!isCancelActionConfrm &&
                  !isMarkusDoneConfirm &&
                  (selectedRecord?.items?.needOrderStatus === "pending" ||
                    selectedRecord?.items?.needOrderStatus === undefined) && (
                    <div className="button-row">
                      <button
                        type="button"
                        className="not-needed"
                        onClick={() => setIsCancelAction(true)}
                        data-toggle="modal"
                        data-target="#cancelActionModalDesktop"
                      >
                        <img src={close_gray} />
                        <label>Cancel Action</label>
                      </button>
                      <button
                        type="button"
                        className="confirm"
                        onClick={() => postOrderStatus("done")}
                      >
                        <img src={tick_gray} />
                        <label>Mark as Done</label>
                      </button>
                    </div>
                  )}
              </div>
            </div>

            {isCancelAction && (
              <div
                className="modal fade cancel-action-modal show"
                id="cancelActionModalDesktop"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                data-backdrop="false"
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-body">
                      <button
                        type="button"
                        className="btn-close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => setIsCancelAction(false)}
                      >
                        <img src="images/close2.png" />
                      </button>
                      <div className="content">
                        <h2>Cancel your action?</h2>
                        <p>
                          Are you sure you want to cancel the action? It will be
                          deleted from your “My Action” list.
                        </p>
                        <div className="desktop-buttonExtra">
                          <button
                            type="button"
                            className="btn-extra"
                            onClick={() => postOrderStatus("declined")}
                          >
                            {/* <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2 2L21.7818 21.8161" stroke strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M22 2L2.21817 21.8161" stroke strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                                            </svg> */}
                            <img src={closeBut} />
                            <label>I understand, cancel</label>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="switch-view">
              {modal ? (
                <button
                  type="button"
                  className={
                    msgOrganizer ? "btn-view-chat" : "btn-view-chat show"
                  }
                  onClick={() => setMsgOrganizer(true)}
                >
                  <img src={angle_up} />
                  <span>Message organizer</span>
                </button>
              ) : null}
              {msgOrganizer ? (
                <button
                  type="button"
                  className={
                    !msgOrganizer
                      ? "btn-view-details"
                      : "btn-view-details show hides"
                  }
                  onClick={() => setMsgOrganizer(false)}
                >
                  <span>Back to details</span>
                  <img src={angle_down} />
                </button>
              ) : null}
            </div>

            {msgOrganizer ? (
              <div className="chat-with-organiser show chat-with-padding">
                <div className="mobile-header">
                  <nav className="navbar">
                    <a
                      href="javascript:void(0);"
                      className="back-seniorreq"
                      onClick={switchView()}
                    >
                      <img
                        onClick={() => setMsgOrganizer(!msgOrganizer)}
                        src={back_black}
                        alt=""
                      />
                    </a>
                    <label className="navbar-brand mx-auto middle-label back_seniortext">
                      {selectedActionContant?.title
                        ? selectedActionContant?.title
                        : ""}
                    </label>
                    <a
                      onClick={() => setMsgOrganizer(!msgOrganizer)}
                      className="cross d-none"
                      data-dismiss="modal"
                    >
                      <img src="images/close2.png" alt="" />
                    </a>
                  </nav>
                </div>
                {renderChatWindow()}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Profileheadermobile history={props.history} active="action" />

      <div
        className={
          modal
            ? "content dashboard myactions side-panel-opened"
            : "content dashboard myactions"
        }
      >
        <div className="container">
          {renderHeader()}

          <div className="my-action-content">
            <ul
              className="nav nav-tabs content-nav mobile-view"
              id="myTab"
              role="tablist"
            >
              <li className="nav-item">
                <a
                  className="nav-link active"
                  id="do_and_give_actions_tab"
                  data-toggle="tab"
                  href="#do_and_give_actions_content"
                  role="tab"
                  aria-controls="do-and-give-actions"
                  aria-selected="false"
                  onClick={() => setRadioButSelection("give")}
                >
                  Do and Give Actions
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="donations_tab"
                  data-toggle="tab"
                  href="#donations_content"
                  role="tab"
                  aria-controls="donations"
                  aria-selected="false"
                  onClick={() => setRadioButSelection("buy")}
                >
                  Donations
                </a>
              </li>
            </ul>
            <div
              className="tab-content "
              style={{ marginBottom: "0 !important", padding: "32px 8px 0px" }}
              // id="my_action_tab_content"
            >
              {renderDoAndGiveActionsContent()}
              {renderDonationsContent()}
            </div>
          </div>
          {!actionsRecord && radioButSelection == "give" ? (
            <div className="no-content">
              <div className="no-actions">
                <h2>You have no upcoming actions</h2>
                <button
                  onClick={() => props.history.push("/discover")}
                  type="button"
                  className="btn btn-prime"
                >
                  Explore needs
                </button>
              </div>
            </div>
          ) : (
            !actionsRecord &&
            radioButSelection == "buy" && (
              <div className="no-content">
                <div className="no-actions">
                  <h2>You don't have donations</h2>
                  <button
                    onClick={() => props.history.push("/discover")}
                    type="button"
                    className="btn btn-prime"
                  >
                    Explore needs
                  </button>
                </div>
              </div>
            )
          )}
          <BottomTab activeScreen="my-actions" />
        </div>

        <div
          className={
            modal
              ? "modal do-give-action-modal myaction-poupnew show desktop-modalindex"
              : "modal do-give-action-modal-hide"
          }
          tabIndex={-1}
          role="dialog"
          id="doGiveActionModal"
          data-backdrop="false"
        >
          {renderModalPopContant()}
        </div>
      </div>
      <ToastContainer autoClose={3000} />
    </div>
  );
};
export default MyActionsList;
