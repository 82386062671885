import React, { useState, useEffect } from "react";
import Remove from "../../../../assets/images/remove.png";
import MasterCard from "../../../../assets/images/mastercard.png";
import ModalAddCard from "./ModalAddCard";
import {
  applyCoupon,
  getCancelSubscriptionPlan,
  getBillingDetails,
  removeBillingDetails,
} from "./service";
import { ToastContainer, toast } from "react-toastify";
import moment, { now } from "moment";
import CloseIcon from "../../../../assets/images/close2.png";
import CloseRedIcon from "../../../../assets/images/cross_red.png";

const ActiveBilling = (props) => {
  const billingData = props?.subscriptionData;
  const [selectedCard, setSelectedCard] = useState();
  const [discountCode, setDiscountCode] = useState("");
  const [discountPercentage, setDiscountPercentage] = useState({
    isApplied: false,
    percentage: "",
  });
  const [cardListData, setBillingData] = useState([]);
  const [billingTrialDate, setBillingTrialDate] = useState();

  useEffect(() => {
    if (props?.actionListId) {
      getBillingDetailsList(props?.actionListId);
    }
    // console.log("billing-date", billingData.subscribedPlan);
    let dateData = moment(billingData?.subscribedPlan.date)
      .add(
        billingData?.subscribedPlan?.paymentResponce?.plan?.trial_period_days,
        "days"
      )
      .format("DD-MM-YYYY");

    //  console.log("billing-datea", moment(new Date()).format("DD-MM-YYYY"));
    let dateCompare = moment(new Date()).format("DD-MM-YYYY") < dateData;
    setBillingTrialDate(dateCompare);
  }, []);

  const handleCardSelect = (e) => {
    setSelectedCard(e.target.value);
    console.log(e.target.value);
  };

  const handleApplyCoupon = async () => {
    console.log(discountCode);
    if (discountCode) {
      const data = await applyCoupon(discountCode);
      if (data?.success) {
        toast.success(
          `${data?.discountcode?.discountPercentage} % discount applied`
        );
        setDiscountPercentage({
          isApplied: true,
          percentage: data?.discountcode?.discountPercentage,
        });
      } else {
        toast.error(data?.msg);
      }
    }
  };

  const cancleSubscription = async () => {
    let SubID = billingData.subscribedPlan?.subscriptionId;
    const data = await getCancelSubscriptionPlan(SubID);
    if (data.success) {
      props.getSubNData();
      toast.success(data.msg);
      handleCloseCancelModal();
    }
  };

  const getBillingDetailsList = async (actionID) => {
    const data = await getBillingDetails(actionID);
    if (data?.billingDetails) {
      setBillingData(data);
    }
    //console.log("billing-date",data)
    //console.log("billing-date",data)
  };

  const remove_BillingDetails = async () => {
    const data = await removeBillingDetails(props.actionListId);
    if (data.success) {
      toast.success(data.msg);
      getBillingDetailsList(props?.actionListId);
      handleDeleteCardHide();
      setBillingData("");
    }
  };

  const toggleAddNewCardModal = () => {
    window.$("#newPaymentMedthodModal").modal("show");
  };

  const handleOpenCancelModal = () => {
    window.$("#removeAdminModal").modal("show");
  };

  const handleCloseCancelModal = () => {
    window.$("#removeAdminModal").modal("hide");
  };

  const handleDeleteCard = () => {
    window.$("#removeCard").modal("show");
  };

  const handleDeleteCardHide = () => {
    window.$("#removeCard").modal("hide");
  };

  const handleRemoveCoupon = () => {
    setDiscountPercentage({ isApplied: false, percentage: "" });
  };
  const validity = moment(
    billingData?.subscribedPlan?.paymentResponce?.current_period_end * 1000
  ).format("MMM DD YYYY");
  console.log("cardListData*******", cardListData);
  return (
    <>
      <h2 className="heading2 pb-5">Billing</h2>
      <div className="section-header-row">
        <h4 className="pro-subscription">
          {billingTrialDate ? "Your 7 days Free Trial" : "Pro Subscription"}
        </h4>
        <a className="cancel-membership" onClick={handleOpenCancelModal}>
          Cancel Subscription
        </a>
        {console.log(" billingData", billingData.subscribedPlan)}
        <label className="label-content">
          Your{" "}
          {billingData?.subscribedPlan?.interval === "month"
            ? "monthly"
            : "yearly"}{" "}
          Pro subscription of ${billingData?.subscribedPlan?.amount} USD will
          renew on {validity}
        </label>
      </div>
      {!discountPercentage?.isApplied && (
        <form className="discount-code-form" onSubmit={handleApplyCoupon}>
          <label>Enter a Discount code</label>
          <div className="input-row">
            <input
              type="text"
              placeholder="Type code"
              value={discountCode}
              onChange={(e) => setDiscountCode(e.target.value)}
            />
            <button
              type="submit"
              className="btn btn-prime"
              disabled={!discountCode ? true : false}
            >
              Apply
            </button>
          </div>
        </form>
      )}
      {discountPercentage?.percentage && (
        <div
          className="card-row"
          style={{
            background: "#FFFFFF",
            boxSizing: "borderBox",
            borderRadius: "8px",
          }}
        >
          <div className="existing-card-row">
            <div className="column2">
              <label className="row1">
                <span style={{ color: "#607BFF" }}>
                  {discountPercentage?.percentage}% off{" "}
                </span>
                with the discount code{" "}
                <span style={{ fontWeight: "bold" }}>{discountCode}</span>
              </label>
            </div>
            <div className="column4">
              <button
                type="button"
                className="btn-remove"
                onClick={handleRemoveCoupon}
              >
                <img src={Remove} />
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="divider"></div>
      <div className="section-header-row">
        <h4 className="pb-3 payment-methodhead">Payment Methods</h4>
      </div>
      {cardListData?.billingDetails && (
        <>
          <div className="card-row">
            <div className="existing-card-row">
              <div className="column1">
                <label htmlFor="mastercard">
                  <input
                    type="radio"
                    name="paymentcard"
                    id="mastercard"
                    defaultValue="card1"
                    checked={selectedCard === "card1"}
                    onChange={handleCardSelect}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className="column2">
                <img src={MasterCard} />
              </div>
              <div className="column3">
                <label className="row1">
                  **** **** **** {String(cardListData?.cardNumber)?.substr(12)}
                  <span>- {cardListData?.expDate}</span>
                </label>
                {/* <label className="row2">MasterCard</label> */}
              </div>
              <div className="column4">
                <button
                  type="button"
                  className="btn-remove"
                  onClick={handleDeleteCard}
                >
                  <img src={Remove} />
                </button>
              </div>
            </div>
          </div>
          <div className="card-button-row edit-cardBtn">
            <button
              type="button"
              className="btn btn-extra edit-card "
              onClick={toggleAddNewCardModal}
            >
              Edit card
            </button>
          </div>{" "}
        </>
      )}
      {!cardListData?.billingDetails && <label>No Record found</label>}
      <ModalAddCard cardListDetails={cardListData} />
      <ToastContainer autoClose={3000} />
      <div
        className="modal fade delete-list-modal"
        id="removeAdminModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => window.$("#removeAdminModal").modal("hide")}
              >
                <img src={CloseIcon} />
              </button>
              <h2>Cancel the Pro Membership?</h2>
              <label style={{ marginBottom: "auto" }}>
                Are you sure you want to cancel the Pro Membership?
              </label>
              <br />
              <br />
              <p>
                The membership will be canceled once the period you paid off for
                ends. You can renew your Pro Plan whenever you want on the
                Billing page.
              </p>
              <button
                onClick={() => cancleSubscription()}
                type="button"
                className="btn btn-delete"
              >
                <img src={CloseRedIcon} />
                <label>I understand, cancel</label>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade delete-list-modal"
        id="removeCard"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => window.$("#removeCard").modal("hide")}
              >
                <img src={CloseIcon} />
              </button>
              <h2>Delete payment method?</h2>
              <label style={{ marginBottom: "auto" }}>
                You will need to add a new payment method to be able to pay for
                the subscription.
              </label>

              <button
                onClick={() => remove_BillingDetails()}
                type="button"
                className="btn btn-delete"
              >
                <img src={CloseRedIcon} />
                <label>I understand, delete</label>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActiveBilling;
