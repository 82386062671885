import React from 'react'; 
import { ShowPreviewData } from './showPreviewData';

const ListCreationPreviewBlossom = (props) => {
  return (
    <>
      <ShowPreviewData 
        thmName = 'blossom'
        history = {props?.history}
      />
    </>
  );
};

export default ListCreationPreviewBlossom;
