import React from "react";
import Logo from "../assets/images/logo_header_innerpage.svg";
import Facebook from "../assets/images/facebook.svg";
import Twitter from "../assets/images/twitter.svg";
import YouTube from "../assets/images/youtube.svg";
import Instagram from "../assets/images/instagram.svg";

const Footer = (props) => {
  return (
    <div class="footer dashboard">
      <div class="container">
        <div class="row">
          <div class="col-xl-4 col-lg-4 col-12 left">
            <a
            href="/discover"
              class="logo"
            >
              <img src={Logo} alt="Logo" />
            </a>
          </div>

          <div class="col-xl-4 col-lg-4 col-12 middle">
            <ul>
              <li>
                <a href="https://blog.actionlist.com/" target="_blank">
                  Blog
                </a>
              </li>
              <li>
                <a onClick={() => props.history.push("/contactus/")}>
                  Help Center
                </a>
              </li>
            </ul>
          </div>

          <div class="col-xl-4 col-lg-4 col-12 right">
            <div class="wrapper">
              <label>Follow us</label>
              <ul>
                <li>
                  <a
                    href="https://www.facebook.com/ActionList-112887040896845"
                    target="_blank"
                  >
                    <img src={Facebook} alt="Facebook" />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/actionlist_" target="_blank">
                    <img src={Twitter} alt="Twitter" />
                  </a>
                </li>
                {/* <li>
                  <a href="#">
                    <img src={YouTube} alt="YouTube" />
                  </a>
                </li> */}
                <li>
                  <a
                    href="https://www.instagram.com/actionlist_/"
                    target="_blank"
                  >
                    <img src={Instagram} alt="Instagram" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="row copyright-row">
          <label>Copyright 2021 ActionList. All Rights Reserved</label>
          <ul>
            <li>
              <a onClick={() => props.history.push("/privacy")}>
                Privacy policy
              </a>
            </li>
            <li>
              <a
                onClick={() => props.history.push("/terms")}
              >
                Terms
              </a>
            </li>
              {/* <li>
                <a>Legal</a>
              </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
