import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Disclaimer from "../../../Layout/GuestUser/Disclaimer";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import MobileScreenLogo from "../../../Common/MobileScreenLogo";
import DeskTopHeader from "../../../Common/DeskTopHeader";
import "./style.css";
import "./responsive.css";
import clearBut from "../../../assets/images/clear.png";
import logoWhiteDesktop from "../../../assets/images/logo_white_desktop.svg";
import { checkValidEmail } from "../../../Store/Actions/userActions";

const StepOneEmail = (props) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const inviteCode = props.match.params.id ? props.match.params.id : null;
  const { register, handleSubmit, reset, errors } = useForm();
  const [apiResMsg, setApiResMsg] = useState("");
  const onSubmit = (data) => {
    localStorage.setItem("StepOneEmail", data.email);
    localStorage.setItem("InviteCode", inviteCode);
    let payload = {
      email: data?.email,
    };
    dispatch(checkValidEmail(payload, userInfo?.token)).then((res) => {
      if (res?.data) {
        if (res?.data?.success === false) {
          setApiResMsg(res?.data?.msg);
          setTimeout(() => {
            setApiResMsg("");
          }, 3000);
        } else {
          history.push("/signup-password");
        }
      }
    });
  };

  console.log("props.match.params.id", inviteCode);
  return (
    <div>
      <div class="mobile-header">
        <MobileScreenLogo
          navlink="Log in"
          linkdata="/discover"
          pageName="signUpFirst"
        />
      </div>
      <div class="content signup_content">
        <div className="logo-desktop ml-0 ml-xl-3 ml-lg-3 ml-md-2">
          <Link to="/discover">
            <img src={logoWhiteDesktop} />
          </Link>
        </div>
      </div>
      <div className="right-signupcontent">
        <div className="login-link">
          <DeskTopHeader
            linkdata="/login"
            displayContent="Already have an account?"
            navlink="Log in"
          />
        </div>
        <div class="row">
          <div class="col-12">
            <form
              class="signup_form custom_Form_View"
              onSubmit={handleSubmit(onSubmit)}
            >
              <h2 class="signup-head">Sign up</h2>
              <div className="form-group">
                <label htmlFor="email" className="mb-2">
                  Your email address
                </label>
                <input
                  type="input"
                  className={
                    errors.email ? "form-control invalid" : "form-control"
                  }
                  id="email"
                  name="email"
                  ref={register({
                    required: "Please, enter your email",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Enter a valid email address",
                    },
                  })}
                  placeholder=""
                />
                {/*                 
                {errors?.email?.type !== 'required' && (
                  <button
                    type='button'
                    onClick={() => reset({ email: '' })}
                    className='close_button'
                  ></button>
                )} */}
              </div>

              {apiResMsg ? (
                <div className="error-box">
                  <div className="incorrect">{apiResMsg}</div>
                </div>
              ) : (
                Object.keys(errors).length !== 0 && (
                  <div className="error-box">
                    <ul>
                      {errors.email && (
                        <li key="1" className="incorrect">
                          {errors.email.message}
                        </li>
                      )}
                    </ul>
                  </div>
                )
              )}
              <button type="submit" className="btn btn-primary">
                Join ActionList
              </button>
              <Disclaimer {...props} />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepOneEmail;
