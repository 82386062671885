import React, { useEffect, useState } from 'react';
import Disclaimer from '../../../Layout/GuestUser/Disclaimer';
import { useHistory, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import {
  UserEmailVerification,
  resendVerification
} from '../../../Store/Actions/userActions';
import MobileScreenLogo from '../../../Common/MobileScreenLogo';
import DeskTopHeader from '../../../Common/DeskTopHeader';
import logoWhiteDesktop from '../../../assets/images/logo_white_desktop.svg'

const EmailVerification = (props) => {
  const [value, setValue] = useState('');
  const [verify, setVerify] = useState(true);
  const [error, seterror] = useState(false);
  const [seconds, setSeconds] = React.useState(0);
  const [successmsg, setSuccessmsg] = useState('');
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  let history = useHistory();
  const dispatch = useDispatch();

  const { register, handleSubmit, reset, errors } = useForm();

  React.useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    }
  });

  const valueChange = (e) => {
    setValue(e.target.value);
    if (e.target.value) {
      setVerify(false)
    } else {
      setVerify(true)
    }
  };

  const timeOutfun = (res) => {
    setSuccessmsg(res.msg)
    setTimeout(
      function () {
        setSuccessmsg('');
        seterror(false)
      }.bind(this),
      3000
    );
  }

  const onSubmit = () => {
    let data = {
      verificationCode: value
    }
    dispatch(UserEmailVerification(data)).then((res) => {
      if (res.success) {
        seterror(true);
        setValue('');
        timeOutfun(res);
        Cookies.set('verified', true);
        history.push('/account-created');
      } else {
        seterror(false);
        timeOutfun(res);
      }
    })
  };

  const sendAgainOTP = () => {
    dispatch(resendVerification()).then((res) => {
      if (res.success) {
        seterror(true);
        timeOutfun(res);
      } else {
        seterror(false);
        timeOutfun(res);
      }
    })
  };

  return (
    <div>
      <div class='mobile-header'>
        <MobileScreenLogo navlink='Log in' linkdata='/login' />
      </div>
      <div class='content signup_content'>
        <div className="logo-desktop ml-0 ml-xl-3 ml-lg-3 ml-md-2">
          <img src={logoWhiteDesktop} />
        </div>
      </div>
      <div className="right-signupcontent">
        <div className="login-link">
          <DeskTopHeader
            linkdata='/login'
          />
        </div>
        <div class='row'>
          <div class='col-12'>
            <form
              onSubmit={handleSubmit(onSubmit)}
              class='signup_form custom_Form_View'
            >
               <h2 class='signup-head'>Hi {userInfo?.firstName}</h2>
              <h2 class='signup-head'>Please Verify your Email</h2>
              <p class='para text-left'>
                We’ve sent a verification code to your email address.
                </p>
              <div className='form-group'>
                <label className="mb-2">Verification code</label>
                <div className='row verification-row'>
                  <div className={`${errors.number3 && 'invalid-row'}`}>
                    <input
                      type='text'
                      id='value'
                      name='number3'
                      value={value}
                      onChange={(e) => valueChange(e)}
                      ref={register({
                        required: 'Enter your number',
                        valueAsNumber: true,
                      })}
                      maxLength='15'
                      className='form-control'
                      placeholder='Enter Verification Code'
                    />
                  </div>
                </div>
                {successmsg != '' && (
                  <div className='error-box'>
                    {
                      <p className='incorrect' style={{ color: error ? 'green' : 'red' }}>
                        {successmsg}
                      </p>
                    }
                  </div>
                )}
                <div>
                  {seconds === 0 ? (
                    <a
                      onClick={() => {
                        sendAgainOTP();
                      }}
                      className='other-link'
                    >
                      Send code again
                    </a>
                  ) : (
                    <div>
                      <label>
                        <strong>00:{seconds}</strong> before you can resend
                      </label>
                    </div>
                  )}
                </div>
              </div>

              <button
                type='submit'
                disabled={verify}
                className='btn btn-primary'
              >
                Verify
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailVerification;