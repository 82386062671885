import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Back from '../../../assets/images/back.png';
import right_angle from '../../../assets/images/right_angle.png';
export default function WithDrawListFirst(props) {
  const dispatch = useDispatch();

  return (
    <div
      class='tab-pane fade show active'
      id='withdraw-funds'
      role='tabpanel'
      aria-labelledby='withdraw-funds-tab'
    >
      <div class='mobile-header-inner'>
        <nav class='navbar'>
          <a onClick={props.backdata} class='back'>
            <img src={Back} />
          </a>
          <label class='navbar-brand mx-auto middle-label'>
            Withdraw funds
          </label>
        </nav>
      </div>

      <div class='verify-header'>
        <h2 class='heading2'>Verify your identity</h2>
        <label>
          You need to verify your identity to be able to withdraw money
        </label>
        <p>
          The next steps will ask you to fill in the information required to
          receive your funds. You will need to be 18 or older to continue. If
          your information cannot be verified, there is a chance that you will
          later be asked to supply supporting documentation. To read more about
          what is required, please <a href='#'>click here.</a>
        </p>

        <div class='form-show-tabs'>
          <a onClick={() => props.nextclick(6)}>
            <label>Representative information</label>
            <img src={right_angle} />
          </a>
          <a onClick={() => props.nextclick(7)}>
            <label>Company information</label>
            <img src={right_angle} />
          </a>
        </div>
      </div>
    </div>
  );
}
