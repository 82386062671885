import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Back from "../../../assets/images/back.png";
import right_angle from "../../../assets/images/right_angle_gray.png";
import "../style.css";
import moment from "moment";
import AxiosApi from "../../../Config/axios-api";
import { getNeedDetailsData } from "../../../Store/Actions/userActions";
import { stringCapitalize } from "../../../services/stringModify";
import { ToastContainer, toast } from "react-toastify";
import $ from "jquery";
// var amount = 0;
export default function ManageBuyList(props) {
  const dispatch = useDispatch();
  let amount = 0;
  let yesterdayCount = 0;
  let todayCount = 0;
  let dayCount = 0;
  let uniqueDate = "";
  let [needs, setNeeds] = useState([]);
  let [needsList, setNeedsList] = useState([]);
  let [key, setKey] = useState();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  useEffect(() => {
    setNeedsList(props.needsList);
    var needsval = props.needsList;
    for (var i = 0; i < needsval.length; i++) {
      if (needsval[i].needType === "buy") {
        amount = amount + needsval[i]?.amount;
      }
      if (i === needsval.length - 1) {
        !props?.startSearching && props.setDonationval(amount);
        // props.setDonationval(amount);
      }
    }
  }, [props.needsList]);
  useEffect(() => {
    setNeeds(props.needs);
  }, [props.needs]);

  // useEffect(() => {
  //   $('.main-heightbox .list-item .active').parent().addClass('selectarea');
  // }, []);

  const renderDayName = (msgDate, payload) => {
    let finalDate = "";
    const msgDay = moment(msgDate.toString()).format("DD MMMM YYYY");
    const yesterday = new Date(new Date().valueOf() - 1000 * 60 * 60 * 24);
    if (msgDay === moment().format("DD MMMM YYYY")) {
      todayCount += 1;
      return todayCount === 1 && "Today";
    }
    if (msgDay === moment(yesterday).format("DD MMMM YYYY")) {
      yesterdayCount += 1;
      return yesterdayCount === 1 && "Yesterday";
    }
    if (uniqueDate === "" || uniqueDate !== msgDay) {
      uniqueDate = msgDay;
      payload?.length > 0 &&
        payload?.map((item) => {
          const itemDate = moment(item?.date?.toString()).format(
            "DD MMMM YYYY"
          );
          if (itemDate === msgDay) {
            dayCount += 1;
            if (dayCount === 1) {
              finalDate = msgDay;
            }
          }
        });
      return finalDate;
    } else {
      dayCount = 0;
      return finalDate;
    }
  };

  const getNeedDetails = (needId, index) => {
    setKey(index);
    console.log(needId);
    dispatch(getNeedDetailsData(needId, userInfo?.token)).then((res) => {
      const data = res?.data;
      if (data) {
        props?.setNeedList(data);
        props?.setNeedInfo(data);
        props?.selectedproduct("buy");
        props?.getChatList(data?.needId);
      } else {
        toast.error("Something went wrong.");
      }
    });
  };

  return (
    <div class="list-content">
      {needsList && needsList.length > 0 && (
        <h5 class="list-header">Donations</h5>
      )}
      {needsList &&
        needsList.length > 0 &&
        needsList.map((obj, index) => {
          {
            /* console.log("--->///---", needsList); */
          }
          return (
            <div className="main-heightbox">
              {obj.needType === "buy" && (
                <div>
                  {/* {obj.items &&
                    obj.items.length > 0 &&
                    obj.items.map((item) => {
                console.log("needs--->",obj.items);

                      const selectIndex = needs.findIndex(
                        (temp) => temp.needId === item.needId
                      );
                      return ( */}
                  <>
                    <label className="date_label">
                      {renderDayName(obj?.date, needsList)}
                    </label>
                    <div
                      class={`list-item priority ${
                        key == index ? "active" : ""
                      }`}
                      // onClick={() => (
                      //   props.setNeedList(obj),
                      //   props.setNeedInfo(obj),
                      //   props.selectedproduct('buy'),
                      //   props.getChatList(obj.needId)
                      // )}
                      onClick={() => {
                        getNeedDetails(obj?.needId, index);
                      }}
                    >
                      <div
                        class={`column-first ${key == index ? "active" : ""}`}
                      >
                        <div class="row1">
                          <label class="name">
                            {obj.qty} x {stringCapitalize(obj?.Name)}
                          </label>
                          <label class="price">${obj.amount * obj.qty}</label>
                        </div>
                        <label class="row2">
                          Donated by{" "}
                          <strong>
                            {obj?.userNames[0]}
                            {", "}
                            {obj?.userNames[1]}
                            {/* {obj?.userName[0]?.firstName}{' '}
                                {obj?.userName[0]?.lastName} */}
                          </strong>
                        </label>
                      </div>
                      <div class="column-last">
                        <button type="button">
                          <img src={right_angle} />
                        </button>
                      </div>
                    </div>
                  </>
                  {/* 
                    //   );
                    // })} */}
                </div>
              )}
            </div>
          );
        })}
      <ToastContainer autoClose={3000} />
    </div>
  );
}
