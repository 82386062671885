import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Profileheadermobile from "../../Common/Profileheadermobile";
import tick_gray from "../../assets/images/tick_gray.png";
import giveimg from "../../assets/images/give_colored.png";
import doimg from "../../assets/images/do_colored.png";
import buyimg from "../../assets/images/buy_colored.png";
import BottomTab from "../../Common/BottomTab";
import meatballs from "../../assets/images/meatballs.png";
import profile_pic6 from "../../assets/images/profile_pic6.png";
import seenImg from "../../assets/images/seen.png";
import do_colored from "../../assets/images/do_colored.png";
import message3 from "../../assets/images/message3.png";
import back_black from "../../assets/images/back_black.png";
import tick_inside_yellow from "../../assets/images/tick_inside_yellow.svg";
import sendImg from "../../assets/images/send.png";
import chat_user from "../../assets/images/chat_user.png";
import close_gray from "../../assets/images/close_gray.png";
import location from "../../assets/images/location.svg";
import clockImg from "../../assets/images/clock.svg";
import axios from "axios";
import AxiosApi from "../../Config/axios-api";
import { RenderChateDate } from "../../services/chatDateShow";
import {
  getActionListOnNeedForMsg,
  getUserActionListChats,
  getUserActionMessageChats,
  deleteUserActionListChats,
  postLastMessage,
  updateAcionListStatusOnChat,
  setOrderStatusForMyActions,
} from "../../Store/Actions/userActions";
import moment from "moment";
import "./style.css";
import "./responsive.css";
import ProfilePic from "../../assets/images/profile_pic_alt.png";
import ProfilePic2 from "../../assets/images/profile_pic.png";
import { ToastContainer, toast } from "react-toastify";
import "../../../node_modules/react-toastify/dist/ReactToastify.min.css";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import { stringCapitalize } from "../../services/stringModify";
import MyActionsList from "../MyActions/myActionslist";

const ChatAPI = require("twilio-chat");

const MessageList = (props) => {
  console.log("item", ChatAPI);
  let yesterdayCount = 0;
  let todayCount = 0;
  let dayCount = 0;
  let uniqueDate = "";
  const imgBaseUrl = "http://api.actionlist.com/static/images/";
  const [closeConversation, setcloseConversation] = useState(false);
  const [actionDetails, setactionDetails] = useState(false);
  const dispatch = useDispatch();
  const userMsgDetails = useSelector(
    (state) => state.getActionListOnNeedMsgInfo?.userMsgDetails
  );
  const userChatDetails = useSelector(
    (state) => state.getUserActionListChatsInfo?.userChatDetails
  );
  const userDetailsError = useSelector((state) => state.UserDetails?.error);
  // const { userInfo } = useSelector((state) => state.userSignin);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  console.log(userInfo);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userName, setUserName] = useState(userInfo?.userId);
  const [userId, setUserId] = useState("");
  const [channel, setChannel] = useState(null);
  const [userNameOfChatDetails, setUserNameOfChatDetails] = useState("");
  const [text, setText] = useState("");
  const [chatChannelId, setChatChannelId] = useState("");
  const [userChatId, setUserChatId] = useState("");
  const [chatActionListTitle, setChatActionListTitle] = useState("");
  const [needTyp, setNeedTyp] = useState("");
  const [searchToggle, setSearchToggle] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [userChatRecords, setUserChatRecords] = useState([]);
  const [receiverId, setReceiverId] = useState("");
  const [allMesagesItem, setAllMesagesItem] = useState();
  const [myMessage, setMyMessage] = useState([]);
  const [listMessage, setListMessage] = useState([]);
  const [filterMyMessage, setFilterMyMessage] = useState([]);
  const [filterListMessage, setFilterListMessage] = useState([]);
  const [mesageType, setMessageType] = useState("myMessage");
  const [dataChange, setDatachange] = useState([]);

  let scrollDiv = useRef(null);

  useEffect(() => {
    if (userDetailsError?.invalidToken) {
      Cookies.set("token", "");
      Cookies.set("userInfo", null);
      localStorage.setItem("userInfo", null);
      props.history.push("/login");
    } else {
      // dispatch(getActionListOnNeedForMsg());
      //console.log("user", userInfo?.userId);
      dispatch(getUserActionListChats(userInfo?.userId)).then((res) => {
        if (res?.data) {
          let myMessage = [];
          let myListMessage = [];
          // res?.data.map((rec) => {
          //   if (rec.userId === userInfo?.userId) {
          //     myMessage.push(rec);
          //   } else {
          //     myListMessage.push(rec);
          //   }
          // });
          // setMyMessage(res.data.chatData);
          setListMessage(res.data);
          setFilterListMessage(res.data);
          // setUserChatRecords(res?.data);
          console.log("chat res", res.data);
        }
      });
      dispatch(getUserActionMessageChats(userInfo?.userId)).then((res) => {
        if (res?.data) {
          let myMessage = [];
          let myListMessage = [];
          // res?.data.map((rec) => {
          //   if (rec.userId === userInfo?.userId) {
          //     myMessage.push(rec);
          //   } else {
          //     myListMessage.push(rec);
          //   }
          // });
          setMyMessage(res.data.chatData);
          setFilterMyMessage(res.data.chatData);
          //  setListMessage(myListMessage);
          setUserChatRecords(res?.data);
          console.log("chat res", res.data);
        }
      });
    }
    console.log("---", userInfo);
  }, [dataChange]);

  const deleteUserActLstChat = (chatId) => {
    Swal.fire({
      title: "Are you sure to want delete need!",
      showCancelButton: true,
      confirmButtonColor: "#e8271a",
      confirmButtonText: `Delete`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        dispatch(deleteUserActionListChats(chatId)).then((res) => {
          if (res?.data) {
            Swal.fire("Deleted!", stringCapitalize(res?.data?.msg), "success");
            // toast.success(res?.data?.msg)
            dispatch(getUserActionListChats(userInfo?.userId));
            dispatch(getUserActionMessageChats(userInfo?.userId));
          } else {
            toast.error("Something went worng!");
          }
        });
      }
    });

    // dispatch(deleteUserActionListChats(chatId)).then(res => {
    //   if (res?.data) {
    //     toast.success(res?.data?.msg)
    //     dispatch(getUserActionListChats(userInfo?.userId));
    //   }
    // })
  };
  console.log("messages", messages);

  const getUserId = (data) => {
    if (data?.message[0]?.userId == userName) {
      return userName;
    } else if (data?.message[0]?.userId != userName) {
      return data?.message[0]?.userId;
    } else {
      return userName;
    }
  };
  const updateLastMsg = (msg) => {
    // let data = {
    //   chatId: userChatId,
    //   receiverId: receiverId,
    //   message: [
    //     {
    //       msg: msg,
    //       msgDate: moment(),
    //       sendBy: userNameOfChatDetails,
    //       read: false,
    //     },
    //   ],
    // };
    console.log("allMesagesItem", allMesagesItem);
    let data = {};
    // if (msg == true) {
    //   if (allMesagesItem) {
    //     data = {
    //       chatId: userChatId,
    //       actionListId: allMesagesItem.actionListId,
    //       needId: allMesagesItem.needId,
    //       // senderId: allMesagesItem.senderId,
    //       senderId: userInfo.userId,
    //       // receiverId: allMesagesItem.receiverId,
    //       receiverId: allMesagesItem.needId,
    //       channelName: allMesagesItem.channelName,

    //       message: {
    //         msg: "",
    //         msgDate: moment(),
    //         sendBy: userInfo.firstName + " " + userInfo.lastName,
    //         read: true,
    //         reciverRead: false,
    //         senderRead:true
    //       },
    //     };
    //   }
    // } else {
    console.log("data 12345 data abcde", { allMesagesItem });
    console.log("data 12345 data abcde", allMesagesItem?.message[0]?.userId);
    if (mesageType == "myMessage") {
      console.log("in my messgae chat data creating");
      data = {
        chatId: userChatId,
        actionListId: allMesagesItem.actionListId,
        needId: allMesagesItem.needId,
        // senderId: allMesagesItem.senderId,
        senderId: allMesagesItem.needId,
        receiverId:
          allMesagesItem.senderId == allMesagesItem.needId
            ? allMesagesItem.receiverId
            : allMesagesItem.senderId,
        channelName: allMesagesItem.channelName,

        message: {
          msg: msg,
          msgDate: moment(),
          sendBy: userInfo.firstName + " " + userInfo.lastName,
          read: false,
          userId: getUserId(allMesagesItem),
        },
      };
      console.log("data 12345 data my", data);
    } else if (mesageType == "listMessage") {
      console.log("in list message chat data creating");

      data = {
        chatId: userChatId,
        actionListId: allMesagesItem.actionListId,
        needId: allMesagesItem.needId,
        // senderId: allMesagesItem.senderId,
        senderId: userInfo.userId,
        // receiverId: allMesagesItem.receiverId,
        receiverId: allMesagesItem.needId,
        channelName: allMesagesItem.channelName,

        message: {
          msg: msg,
          msgDate: moment(),
          sendBy: userInfo.firstName + " " + userInfo.lastName,
          read: false,
          userId: getUserId(allMesagesItem),
        },
      };
      console.log("data 12345 data list", data);
      // }
    }

    dispatch(postLastMessage(userInfo.token, data))
      .then((res) => {
        if (res?.data) {
          dispatch(getUserActionListChats(userInfo?.userId));
          dispatch(getUserActionMessageChats(userInfo?.userId));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateChatReadStatus = async (chatId, id) => {
    try {
      let body = {
        chatId: chatId,
        messageId: id,
      };
      const { data } = await AxiosApi.post(
        "/actionList/updateMessageReadStatus",
        body
      );
      console.log(data);
      setDatachange([...dataChange, "update status"]);
    } catch (error) {
      console.log(error);
    }
  };
  const getToken = async (name) => {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL_LIVE}/order/twilioToken?username=${name}`
    );
    //console.log({response})
    const { data } = response;
    return data;
  };

  const twilioChat = async (roomName, type) => {
    setChatChannelId(roomName);
    setMessageType(type);
    setMessages([]);
    let token = "";
    setLoading(true);
    try {
      token = await getToken(userName);
    } catch {
      console.log("Unable to get token, please reload this page");
    }

    const client = await ChatAPI.Client.create(token);

    client.on("tokenAboutToExpire", async () => {
      const token = await getToken(userName);
      client.updateToken(token);
    });

    client.on("tokenExpired", async () => {
      const token = await getToken(userName);
      client.updateToken(token);
    });

    client.on("channelJoined", async (channel) => {
      // getting list of all messages since this is an existing channel
      const newMessages = await channel.getMessages();
      //console.log("objecttt",newMessages);

      if (channel.channelState.uniqueName === roomName) {
        console.log("msg", newMessages);
        setMessages(newMessages.items);

        scrollToBottom();
      }
    });

    try {
      const channel = await client.getChannelByUniqueName(roomName);
      await joinChannel(channel);
      setChannel(channel);
    } catch (err) {
      try {
        const channel = await client.createChannel({
          uniqueName: roomName,
          friendlyName: roomName,
        });

        await joinChannel(channel);
        setChannel(channel);
      } catch {
        console.log("Unable to create channel, please reload this page");
      }
    }
    // if (type == "listMessage" && allMesagesItem) {
    //   updateLastMsg(true);
    // }
  };

  const joinChannel = async (channel) => {
    if (channel.channelState.status !== "joined") {
      await channel.join();
    }
    setChannel(channel);
    setLoading(false);

    channel.on("messageAdded", function (message) {
      handleMessageAdded(message);
    });
  };

  const handleMessageAdded = (message) => {
    setMessages((messages) => [...messages, message]);
    scrollToBottom();
  };

  const scrollToBottom = () => {
    //console.log("object", scrollDiv.current.scrollTop);
    const scrollHeight = scrollDiv?.current?.scrollHeight;
    const height = scrollDiv?.current?.clientHeight;
    const maxScrollTop = scrollHeight - height;
    //console.log("object", scrollHeight + " - " + height + " = " + maxScrollTop);
    if (
      scrollDiv?.current?.scrollTop !== null &&
      scrollDiv?.current?.scrollTop !== undefined &&
      scrollDiv?.current?.scrollTop !== "" &&
      scrollDiv?.current?.scrollTop !== NaN
    ) {
      scrollDiv.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  };

  const sendMessage = async (e, isSendButClick) => {
    console.log("object", messages);
    if (e.keyCode == 13 && e.shiftKey) {
      return "";
    } else if (e.keyCode == 13) {
      if (text) {
        setLoading(true);
        await channel?.sendMessage(String(text).trim());
        updateLastMsg(text);
        setText("");
        setLoading(false);
      }
    } else if (isSendButClick) {
      if (text) {
        setLoading(true);
        channel.sendMessage(String(text).trim());
        updateLastMsg(text);
        setText("");
        setLoading(false);
      }
    }
    // if (text) {
    //   setLoading(true);
    //   channel.sendMessage(String(text).trim());
    //   updateLastMsg(text);
    //   setText("");
    //   setLoading(false);
    // }
  };

  const handleActionListStatus = (id, status) => {
    let data = {
      actionListId: id,
      actionListStatus: status,
    };
    dispatch(updateAcionListStatusOnChat(data, userInfo?.token));
  };
  const renderNeedTypeImg = (needType) => {
    if (needType === "buy") {
      return buyimg;
    } else if (needType === "give") {
      return giveimg;
    } else if (needType === "do") {
      return doimg;
    }
  };

  const renderDayName = (msgDate, msges) => {
    let finalDate = "";
    const msgDay = moment(msgDate.toString()).format("DD MMMM YYYY");
    const yesterday = new Date(new Date().valueOf() - 1000 * 60 * 60 * 24);
    if (msgDay === moment().format("DD MMMM YYYY")) {
      todayCount += 1;
      return todayCount === 1 && "Today";
    }
    if (msgDay === moment(yesterday).format("DD MMMM YYYY")) {
      yesterdayCount += 1;
      return yesterdayCount === 1 && "Yesterday";
    }
    if (uniqueDate === "" || uniqueDate !== msgDay) {
      uniqueDate = msgDay;
      msges?.length > 0 &&
        msges?.map((item) => {
          const itemDate = moment(item?.dateCreated?.toString()).format(
            "DD MMMM YYYY"
          );
          if (itemDate === msgDay) {
            dayCount += 1;
            if (dayCount === 1) {
              finalDate = msgDay;
            }
          }
        });
      return finalDate;
    } else {
      dayCount = 0;
      return finalDate;
    }
  };

  const handleSearch = (e) => {
    console.log("my ", searchText);
    let val = searchText.toLowerCase();
    console.log("my val ----",val)
    if (val !== "") {
      let filteredRecordsForMyMessage = myMessage?.filter((item, index) => {
        if (
          item?.actionListTitle?.toString?.()?.toLowerCase().includes(val) ||
          item?.userName?.toString?.().toLowerCase()?.includes(val) ||
          item?.message[0]?.msg?.toString?.()?.toLowerCase()?.includes(val) ||
          item?.needDetails?.title?.toString?.()?.toLowerCase()?.includes(val)
        ) {
          return item;
        }
      });
      let filteredRecordsForListMessage = listMessage?.filter((item) => {
        if (
          item?.actionListTitle?.toString?.()?.toLowerCase().includes(val) ||
          item?.userName?.toString?.().toLowerCase()?.includes(val) ||
          item?.message[0]?.msg?.toString?.()?.toLowerCase()?.includes(val) ||
          item?.needDetails?.title?.toString?.()?.toLowerCase()?.includes(val)
        ) {
          return item;
        }
      });
      setFilterMyMessage(filteredRecordsForMyMessage);
      setFilterListMessage(filteredRecordsForListMessage);
    } else {
      setFilterMyMessage(myMessage);
      setFilterListMessage(listMessage);
    }
    // console.log("my m", filteredRecordsForMyMessage);
    // console.log("my list", filteredRecordsForListMessage);
  };

  return (
    <>
      <Profileheadermobile history={props.history} activeMsg={true} />

      <div>
        <div>
          <div className="content dashboard messages mobile_dashboard newmobile-msgdashboard">
            <div className="content-left">
              <div className="section2">
                <div className="header-row">
                  <div className="left">
                    <h3 className="subhead4">Messages</h3>
                  </div>
                  <div className="right message-input ">
                    <div className="search-panel">
                      <button
                        class="btn-action btn-search collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseSearch"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                        onClick={(e) => handleSearch(e)}
                      >
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx={13} cy={11} r={8} strokeWidth={2} />
                          <path
                            d="M7 17L2.5 21.5"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        {/* <img src="images/search.png" /> */}
                      </button>
                      {/* <button type="button" className="btn-filter btn-search">
                      
                      <img src="images/search.png" />
                    </button> */}
                      <div class="collapse searchCollapse" id="collapseSearch">
                        <input
                          type="text"
                          // name="searchInput"
                          id="searchInput"
                          placeholder="Search for action..."
                          value={searchText}
                          onChange={(e) => setSearchText(e.target.value)}
                          onKeyUp={(e) => e.keyCode === 13 && handleSearch(e)}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="search-panel">
                    <button class="btn-action btn-search collapsed" type="button" data-toggle="collapse" data-target="#collapseSearch" aria-expanded="false" aria-controls="collapseExample">
                    <img src="images/search.png" />
                    </button>
                    <div class="collapse searchCollapse" id="collapseSearch">
                      <input type="text" name="searchInput" id="searchInput" placeholder="Search for action..." value=""/>
                      <div class="close-inputsearch"></div>
                    </div>
                  </div> */}
                </div>
              </div>

              <div className="message-tabs">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  {(userInfo?.userType === "volunteer" ||
                    userInfo?.userType === "organization") && (
                    <li
                      className="nav-item"
                      onClick={() => setMessageType("myMessage")}
                    >
                      <a
                        className="nav-link active"
                        id="my_messages_tab"
                        data-toggle="tab"
                        href="#my_messages_content"
                        role="tab"
                        aria-controls="my-messages"
                        aria-selected="true"
                      >
                        My Messages
                      </a>
                    </li>
                  )}
                  {(userInfo?.userType === "volunteer" ||
                    userInfo?.userType === "organization") && (
                    <li
                      className="nav-item"
                      onClick={() => setMessageType("listMessage")}
                    >
                      <a
                        className="nav-link"
                        id="list_messages_tab"
                        data-toggle="tab"
                        href="#list_messages_content"
                        role="tab"
                        aria-controls="list-messages"
                        aria-selected="false"
                      >
                        List Messages{" "}
                        {mesageType == "myMessage" && (
                          <span
                            style={{
                              background: "#95A0FE",
                              padding: "5px 10px",
                              borderRadius: "5px",
                              fontSize: "14px",
                            }}
                          >
                            {" "}
                            {listMessage?.length}
                          </span>
                        )}
                      </a>
                    </li>
                  )}
                </ul>
              </div>
              <div className="message-tabs-content">
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="my_messages_content"
                    role="tabpanel"
                    aria-labelledby="my-messages-tab"
                  >
                    {/* {userChatDetails == undefined && <label style={{textAlign: "center"}}>Nothing found</label>} */}
                    {filterMyMessage?.length == 0 && (
                      <label
                        className="nothing-found"
                        style={{ textAlign: "center" }}
                      >
                        Nothing found
                      </label>
                    )}
                    {console.log("items1", myMessage)}
                    {filterMyMessage?.map((item, index) => {
                      return (
                        <>
                          <div
                            className="message-content-row"
                            onClick={() => {
                              console.log("click");
                              // if (closeConversation === false) {

                              setcloseConversation(true);
                              setUserChatId(item?.chatId);
                              setReceiverId(item?.receiverId);
                              setUserNameOfChatDetails(item?.userName);
                              setChatActionListTitle(item?.actionListTitle);
                              setAllMesagesItem(item);
                              setNeedTyp(item?.needDetails?.needType);
                              twilioChat(item?.channelName, "myMessage");
                              setUserId(item?.message[0]?.userId);
                              dispatch(getActionListOnNeedForMsg(item?.needId));
                              updateChatReadStatus(
                                item?.chatId,
                                item.message[0]._id
                              );
                              // }
                            }}
                          >
                            <div className="row1">
                              <div className="left">
                                <img
                                  src={
                                    item?.userImage
                                      ? imgBaseUrl + item?.userImage
                                      : ProfilePic
                                  }
                                  className="profile-picmsg"
                                  alt=""
                                />
                                <label className="person-name person2">
                                  {item?.userName}
                                </label>
                              </div>
                              <div className="right">
                                <img
                                  src={renderNeedTypeImg(
                                    item?.needDetails?.needType
                                  )}
                                  className="action-type-img"
                                  alt="profile picture"
                                />
                                <label className="action-name">
                                  {item?.needDetails?.title}
                                </label>
                                <div className="dropdown">
                                  <button
                                    className="btn btn-secondary dropdown-toggle"
                                    type="button"
                                    id="dropdownMenuButton"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <img src={meatballs} />
                                  </button>
                                  <div
                                    className="dropdown-menu dropdown-menu-right"
                                    aria-labelledby="dropdownMenuButton"
                                  >
                                    <a className="dropdown-item">
                                      Mark as spam
                                    </a>
                                    <a
                                      className="dropdown-item"
                                      onClick={() => {
                                        deleteUserActLstChat(item?.chatId);
                                      }}
                                    >
                                      Delete
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row2">
                              {/* <div className="left">
                                <label>{item?.needDetails?.title}</label>
                              </div> */}
                            </div>
                            <div className="row3">
                              <div className="left">
                                {!item.message[0]?.read &&
                                item?.needId == item?.receiverId ? (
                                  <label className="message-text">
                                    {item.message[0]?.msg &&
                                      item.message[0].msg}
                                  </label>
                                ) : (
                                  <label
                                    className=""
                                    style={{ color: "#72819B" }}
                                  >
                                    {item.message[0]?.msg &&
                                      item.message[0].msg}
                                  </label>
                                )}
                                {/* <label className="message-text">
                                  {console.log("messagesss", item)}
                                  {item.message[0]?.msg
                                    ? item.message[0].msg
                                    : ""}
                                </label> */}
                              </div>
                              <div className="right">
                                <span className="seen">
                                  {/* {item.message[0]?.read && (
                                    <>
                                      <img src={seenImg} alt="seen" />
                                      <img src={seenImg} alt="seen" />
                                    </>
                                  )}
                                </span>
                                <span className="time">
                                  {item.message[0]?.msgDate &&
                                    moment(item.message[0]?.msgDate).format(
                                      "LT"
                                    )}{" "} */}
                                  {item.message[0]?.read ? (
                                    <>
                                      <img src={seenImg} alt="seen" />
                                      <img src={seenImg} alt="seen" />
                                    </>
                                  ) : (
                                    !item.message[0]?.read &&
                                    item?.needId == item?.receiverId && (
                                      <span
                                        style={{
                                          fontStyle: "normal",
                                          fontWeight: "bold",
                                          fontSize: "13px",
                                          lineHeight: "140%",
                                          color: "#607BFF",
                                          marginRight: "5px",
                                        }}
                                      >
                                        Unread
                                      </span>
                                    )
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                  <div
                    className="tab-pane fade"
                    id="list_messages_content"
                    role="tabpanel"
                    aria-labelledby="list-messages-tab"
                  >
                    {filterListMessage?.length == 0 && (
                      <label
                        className="nothing-found"
                        style={{ textAlign: "center" }}
                      >
                        Nothing found
                      </label>
                    )}
                    {filterListMessage?.map((item, index) => {
                      return (
                        <>
                          {" "}
                          <div
                            className="message-content-row"
                            onClick={() => {
                              console.log("helllllloo");
                              // if (closeConversation === false) {

                              setcloseConversation(true);
                              setUserChatId(item?.chatId);
                              setUserNameOfChatDetails(item?.userName);
                              setChatActionListTitle(item?.actionListTitle);
                              setAllMesagesItem(item);
                              twilioChat(item?.channelName, "listMessage");
                              setUserId(item?.message[0]?.userId);
                              dispatch(getActionListOnNeedForMsg(item?.needId));
                              updateChatReadStatus(
                                item?.chatId,
                                item.message[0]._id
                              );
                              // }
                            }}
                          >
                            <div className="row1">
                              <div className="left">
                                <label className="person-name person2">
                                  {item?.actionListUserName}
                                </label>
                              </div>
                              <div className="right">
                                <img
                                  src={renderNeedTypeImg(
                                    item?.needDetails?.needType
                                  )}
                                  className="action-type-img"
                                  alt="profile picture"
                                />
                                <label className="action-name">
                                  {item?.needDetails?.title}
                                </label>
                                <div className="dropdown">
                                  <button
                                    className="btn btn-secondary dropdown-toggle"
                                    type="button"
                                    id="dropdownMenuButton"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <img src={meatballs} />
                                  </button>
                                  <div
                                    className="dropdown-menu dropdown-menu-right"
                                    aria-labelledby="dropdownMenuButton"
                                  >
                                    <a className="dropdown-item">
                                      Mark as spam
                                    </a>
                                    <a
                                      className="dropdown-item"
                                      onClick={() => {
                                        deleteUserActLstChat(item?.chatId);
                                      }}
                                    >
                                      Delete
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row2">
                              <div className="left">
                                <label>{item?.actionListTitle}</label>
                              </div>
                            </div>
                            <div className="row3">
                              <div className="left">
                                {!item.message[0]?.read &&
                                userInfo?.userId == item?.receiverId ? (
                                  <label className="message-text">
                                    {item.message[0]?.msg &&
                                      item.message[0].msg}
                                  </label>
                                ) : (
                                  <label
                                    className=""
                                    style={{ color: "#72819B" }}
                                  >
                                    {item.message[0]?.msg &&
                                      item.message[0].msg}
                                  </label>
                                )}
                              </div>
                              <div className="right">
                                <span className="seen">
                                  {item.message[0]?.read ? (
                                    <>
                                      <img src={seenImg} alt="seen" />
                                      <img src={seenImg} alt="seen" />
                                    </>
                                  ) : (
                                    !item.message[0]?.read &&
                                    userInfo?.userId == item?.receiverId && (
                                      <span
                                        style={{
                                          fontStyle: "normal",
                                          fontWeight: "bold",
                                          fontSize: "13px",
                                          lineHeight: "140%",
                                          color: "#607BFF",
                                          marginRight: "5px",
                                        }}
                                      >
                                        Unread
                                      </span>
                                    )
                                  )}
                                </span>
                                <span className="time">
                                  {item.message[0]?.msgDate &&
                                    moment(item.message[0]?.msgDate).format(
                                      "LT"
                                    )}{" "}
                                </span>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div
              className={
                closeConversation
                  ? "content-right rightblock"
                  : "content-right mobile_contentright mobile_contentrightNew desktop_contentright"
              }
            >
              <div
                className={closeConversation ? "no-content hide" : "no-content"}
              >
                <img src={message3} />
              </div>
              {console.log(closeConversation)}
              {closeConversation ? (
                <>
                  <div className="message-coversation">
                    <div className="desktop-header">
                      <button type="button" className="close">
                        <img
                          onClick={() =>
                            setcloseConversation(!closeConversation)
                          }
                          src="images/close.png"
                          alt="close"
                        />
                      </button>
                      <div className="person-desc">
                        <label>
                          {userNameOfChatDetails} from{" "}
                          <strong>{userMsgDetails?.title}</strong>
                        </label>
                        <img src={tick_inside_yellow} />
                      </div>
                      <div
                        className="action-desc"
                        onClick={() => {
                          if (actionDetails === false) {
                            setactionDetails(!actionDetails);
                          }
                        }}
                      >
                        <img src={renderNeedTypeImg(needTyp)} />
                        <label>{chatActionListTitle}</label>
                      </div>
                    </div>
                    <div className="mobile-header">
                      <nav className="navbar">
                        <a
                          onClick={() =>
                            setcloseConversation(!closeConversation)
                          }
                          className="back desktop-view"
                        >
                          <img src="images/back.png" alt="" />
                          Back
                        </a>
                        <a
                          onClick={() =>
                            setcloseConversation(!closeConversation)
                          }
                          className="back mobile-view"
                        >
                          <img src={back_black} alt="back" />
                        </a>
                        <label
                          onClick={() => {
                            if (actionDetails === false) {
                              setactionDetails(!actionDetails);
                            }
                          }}
                          className="navbar-brand mx-auto middle-label mobile-view"
                        >
                          {userMsgDetails?.title}
                        </label>
                      </nav>
                    </div>
                    <div className="profile-row">
                      <img src={chat_user} />
                      <label>
                        {userNameOfChatDetails} from{" "}
                        <strong>{userMsgDetails?.title}</strong>
                      </label>
                    </div>
                    <div className="chat-box">
                      <div className="inner-wrapper" ref={scrollDiv}>
                        {/* {loading ? (
                          <label class='label-day'>Loading chat...</label>
                        ) : (
                          <label class='label-day'>Today</label>
                        )} */}

                        {messages.length > 0 &&
                          messages.map((message, key) => {
                            // const msgDay = moment(message.dateCreated.toString()).format('YYYY-MM-DD');
                            // if(moment(msgDay).from(moment().format('YYYY-MM-DD')) === "a day ago" && !yesterdayCount){
                            //   console.log("----------Yesterday--------")
                            //   yesterdayCount = true;
                            //   todayCount = false;
                            // }else if(moment(msgDay).format("D") === moment().format("D") && !todayCount){
                            //   console.log("----------Today--------")
                            //   todayCount = true;
                            //   yesterdayCount = false;
                            // } else {
                            //   yesterdayCount = false;
                            //   todayCount = false;
                            // }
                            console.log("paritosh1", { message });
                            {/* const isOwnMessage =
                              message?.author ===
                              (userName != userId ? userId : userName); */}
                              const isOwnMessage = message?.author === userName;

                            return (
                              <>
                                {!isOwnMessage ? (
                                  <div
                                    className="text-row them"
                                    key={message.index}
                                  >
                                    {/* <label class='label-day'>{renderDayName(message.dateCreated)}</label> */}
                                    <div className="textbox type2">
                                      <label className="chat-text">
                                        {message.body}
                                      </label>
                                      <span className="time">
                                        {moment(message.dateCreated).format(
                                          "LT"
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    className="text-row me"
                                    key={message.index}
                                  >
                                    <label class="label-day">
                                      {renderDayName(
                                        message.dateCreated,
                                        messages
                                      )}
                                    </label>

                                    {/* <label class='label-day'>
                                      <RenderChateDate 
                                        msgDate={message.dateCreated}
                                        msges={messages}
                                      />
                                    </label> */}
                                    <div className="textbox">
                                      {/* <span className="status">Read</span> */}
                                      <label className="chat-text">
                                        {message.body}
                                      </label>
                                      <span className="time">
                                        {moment(message.dateCreated).format(
                                          "LT"
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </>
                            );
                          })}
                      </div>
                      <div className="send-box message-righttextarea">
                        <div className="input-group">
                          <textarea
                            type="text"
                            value={text}
                            className="form-control"
                            placeholder="Write a message..."
                            aria-label="Write a message..."
                            aria-describedby="basic-addon2"
                            onChange={(event) => setText(event.target.value)}
                            onKeyUp={(e) => sendMessage(e, false)}
                          />
                          <div class="input-group-append">
                            <button
                              onClick={(e) => sendMessage(e, true)}
                              class="btn btn-outline-secondary"
                              type="button"
                              disabled={!channel || !text}
                            >
                              <img
                                src={sendImg}
                                style={{ cursor: "pointer" }}
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
            {actionDetails ? (
              <div className="content-right">
                <div className="message-action-details">
                  <div className="header mobile_conver">
                    <a
                      href="javascript:void(0)"
                      className="back desktop-view"
                      onClick={() => setactionDetails(!actionDetails)}
                    >
                      <img src="images/back.png" alt="" />
                      Back to conversation
                    </a>
                    <a
                      href="javascript:void(0)"
                      className="back mobile-view"
                      onclick="closeActionDetails()"
                    >
                      <img
                        src={back_black}
                        alt="back"
                        onClick={() => setactionDetails(!actionDetails)}
                        className="back_converimg"
                      />
                      <label>Back to conversation</label>
                    </a>
                  </div>
                  <div className="content">
                    <div className="status-row">
                      <label className="status-label confirmed">
                        Confirmed
                      </label>
                      <label className="update-label">
                        Last updated:
                        <strong>
                          {" "}
                          {userMsgDetails?.updatedAt &&
                            moment(userMsgDetails.updatedAt).format(
                              "MMM DD YYYY, HH:SS a"
                            )}
                        </strong>
                      </label>
                    </div>
                    <div className="wishlist-name">
                      {userMsgDetails?.actionLists[0]?.title}
                    </div>
                    <div className="action-name-row">
                      <img src={giveimg} alt="action" />
                      <label>{userMsgDetails?.title}</label>
                    </div>
                    <div className="action-desc-row">
                      {userMsgDetails?.description}
                    </div>
                    <div className="location-row">
                      <img src={clockImg} />
                      <label>
                        Complete-by:{" "}
                        <strong>
                          {userMsgDetails?.completedBy &&
                            moment(userMsgDetails.completedBy).format(
                              "MMM DD YYYY, HH:SS a"
                            )}
                        </strong>
                      </label>
                    </div>
                    <div className="location-row">
                      {userMsgDetails?.location && <img src={location} />}
                      <label>{userMsgDetails?.place}</label>
                    </div>
                    <div className="divider" />
                    <h5 className="inner-heading">Pick up</h5>
                    <div className="action-desc-row">
                      The organizer will be in touch to coordinate a time or
                      state the time it will be arranged
                    </div>
                    <div className="divider" />
                    <div className="instruction-box">
                      <label>Private instructions:</label>
                      <p>
                        Contact Mr. Joyson to arrange pickup: +1 894 893 902 53.
                        Please call from 9AM to 6PM.
                      </p>
                    </div>
                    <div className="button-row">
                      <button
                        type="button"
                        className="not-needed"
                        onClick={() =>
                          handleActionListStatus(
                            userMsgDetails?.actionListId,
                            "cancelled"
                          )
                        }
                      >
                        <img src={close_gray} />
                        <label>Cancel Action</label>
                      </button>
                      <button
                        type="button"
                        className="confirm"
                        onClick={() =>
                          handleActionListStatus(
                            userMsgDetails?.actionListId,
                            "done"
                          )
                        }
                      >
                        <img src={tick_gray} />
                        <label>Mark as Done</label>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            <BottomTab activeScreen="messages" Chatactive={closeConversation} />
          </div>
        </div>
        <ToastContainer autoClose={3000} />
      </div>
    </>
  );
};
export default MessageList;
