import Cookies from "js-cookie";
import AxiosApi from "../../Config/axios-api";
import {
  USER_SIGNIN_REQUEST,
  USER_SIGNIN_SUCCESS,
  USER_SIGNIN_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_LOGOUT,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_SEND_OTP_REQUEST,
  USER_SEND_OTP_SUCCESS,
  USER_SEND_OTP_FAIL,
  USER_VERIFY_OTP_REQUEST,
  USER_VERIFY_OTP_SUCCESS,
  USER_VERIFY_OTP_FAIL,
  USER_FORGOT_PASSWORD_REQUEST,
  USER_FORGOT_PASSWORD_SUCCESS,
  USER_FORGOT_PASSWORD_FAIL,
  USER_RESET_PASSWORD_REQUEST,
  USER_RESET_PASSWORD_SUCCESS,
  USER_RESET_PASSWORD_FAIL,
  ADD_GENERAL_INFO,
  ADD_PURPOSE,
  ADD_ADDITIONAL_INFO,
  ADD_DESCRIPTION,
  CREATE_LIST_SUCCESS,
  DELETE_LIST,
  USER_CHANGE_PASSWORD_SUCCESS,
  USER_CHANGE_PASSWORD_FAIL,
  USER_NEW_CARD_BILLING_SUCCESS,
  USER_NEW_CARD_BILLING_FAIL,
  GET_USER_DETAIL_SUCCESS,
  GET_USER_DETAIL_FAIL,
  GET_USER_DETAIL_REQUEST,
  UPDATE_USER_DETAIL_SUCCESS,
  UPDATE_USER_DETAIL_FAIL,
  UPDATE_USER_DETAIL_REQUEST,
  USER_NEEDS_DETAIL_LIST_REQUEST,
  USER_NEEDS_DETAIL_LIST_SUCCESS,
  USER_NEEDS_DETAIL_LIST_FAIL,
  USER_UDATE_CARD_BILLING_REQUEST,
  USER_UDATE_CARD_BILLING_SUCCESS,
  USER_UDATE_CARD_BILLING_FAIL,
  CREATE_WISHLIST_SUCCESS,
  PLACE_ORDER_FAIL,
  PLACE_ORDER_SUCCESS,
  STRIPE_TOKEN_FAIL,
  STRIPE_TOKEN_SUCCESS,
  ACTION_LIST_ON_NEED_FOR_MSG_SUCCESS,
  ACTION_LIST_ON_NEED_FOR_MSG_FAIL,
  GET_USER_ACTION_LIST_CHATS_SUCCESS,
  GET_USER_ACTION_LIST_CHATS_FAIL,
  GET_MY_ACTIONS_RECORDS_SUCCESS,
  GET_MY_ACTIONS_RECORDS_FAIL,
  GET_REPORT_DATA_SUCCESS,
  GET_REPORT_DATA_FAIL,
} from "../Constants/userConstants";

const update =
  ({ userId, name, email, password }) =>
  async (dispatch, getState) => {
    const {
      userSignin: { userInfo },
    } = getState();
    dispatch({
      type: USER_UPDATE_REQUEST,
      payload: { userId, name, email, password },
    });
    try {
      const { data } = await AxiosApi.put(
        "/api/users/" + userId,
        { name, email, password },
        {
          headers: {
            Authorization: "Bearer " + userInfo.token,
          },
        }
      );
      dispatch({ type: USER_UPDATE_SUCCESS, payload: data });
      localStorage.setItem("userInfo", JSON.stringify(data));
    } catch (error) {
      dispatch({ type: USER_UPDATE_FAIL, payload: error });
    }
  };

const signin = (email, password) => async (dispatch) => {
  dispatch({ type: USER_SIGNIN_REQUEST, payload: { email, password } });
  try {
    const { data } = await AxiosApi.post("/user/login", { email, password });
    dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });
    Cookies.set("token", data.token);
    Cookies.set("userInfo", JSON.stringify(data));

    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({ type: USER_SIGNIN_FAIL, payload: error });
  }
};

const register = (param) => async (dispatch) => {
  dispatch({
    type: USER_REGISTER_REQUEST,
    payload: param,
  });
  try {
    const { data } = await AxiosApi.post("/user/signup", param);
    dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });
    dispatch({ type: USER_REGISTER_SUCCESS, payload: data });
    localStorage.setItem("userInfo", JSON.stringify(data));
    return data;
  } catch (error) {
    dispatch({ type: USER_REGISTER_FAIL, payload: error });
    return error;
  }
};

const sendOtp = (phone, option, countryCode) => async (dispatch) => {
  dispatch({ type: USER_SEND_OTP_REQUEST, payload: { phone } });
  try {
    const { data } = await AxiosApi.get(
      "/user/sendOtp/?phoneNumber=" +
        phone +
        "&channel=" +
        option +
        "&countryCode=" +
        countryCode
    );
    dispatch({ type: USER_SEND_OTP_SUCCESS, payload: data });
    localStorage.setItem("userSendOtp", JSON.stringify(data));
    return data;
  } catch (error) {
    dispatch({ type: USER_SEND_OTP_FAIL, payload: error });
    return error;
  }
};

const verifyOtp = (phone, otp, countryCode) => async (dispatch) => {
  dispatch({
    type: USER_VERIFY_OTP_REQUEST,
    payload: { phone, otp, countryCode },
  });
  try {
    const { data } = await AxiosApi.get(
      "/user/verifyOtp/?phoneNumber=" +
        phone +
        "&otp=" +
        otp +
        "&countryCode=" +
        countryCode
    );
    dispatch({ type: USER_VERIFY_OTP_SUCCESS, payload: data });
    localStorage.setItem("userVerifyOtp", JSON.stringify(data));
  } catch (error) {
    dispatch({ type: USER_VERIFY_OTP_FAIL, payload: error });
  }
};

const forgotPassword = (email) => async (dispatch) => {
  dispatch({ type: USER_FORGOT_PASSWORD_REQUEST, payload: { email } });
  try {
    const { data } = await AxiosApi.post("/user/forgotPassword", { email });
    dispatch({ type: USER_FORGOT_PASSWORD_SUCCESS, payload: data });
    localStorage.setItem("forgotPassword", JSON.stringify(data));
    return data;
  } catch (error) {
    dispatch({ type: USER_FORGOT_PASSWORD_FAIL, payload: error });
    return error;
  }
};

const resetPassword = (password, token) => async (dispatch) => {
  dispatch({ type: USER_RESET_PASSWORD_REQUEST, payload: { password } });
  try {
    const { data } = await AxiosApi.post("/user/resetPassword?token=" + token, {
      password,
    });
    dispatch({ type: USER_RESET_PASSWORD_SUCCESS, payload: data });
    localStorage.setItem("resetPassword", JSON.stringify(data));
  } catch (error) {
    dispatch({ type: USER_RESET_PASSWORD_FAIL, payload: error });
  }
};

const UserEmailVerification = (param) => async (dispatch) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  try {
    const { data } = await AxiosApi.post(
      `/user/verifyUser/${userInfo.userId}`,
      param,
      {
        headers: {
          token: userInfo.token,
        },
      }
    );

    return data;
  } catch (error) {
    return error;
  }
};

const resendVerification = () => async (dispatch) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  try {
    const { data } = await AxiosApi.post(
      `/user/resendVerificationCode/${userInfo.userId}`,
      {
        headers: {
          token: userInfo.token,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

const logout = () => (dispatch) => {
  Cookies.remove("userInfo");
  Cookies.remove("token");
  Cookies.remove("verified");
  localStorage.clear();
  dispatch({ type: USER_LOGOUT });
  // window.location.pathname("/")
};

const deleteList = () => (dispatch) => {
  dispatch({ type: DELETE_LIST });
};

const addGeneralInfo = (generalInfo) => {
  return async (dispatch) => {
    dispatch({ type: ADD_GENERAL_INFO, payload: generalInfo });
  };
};
const addPurpose = (purposeOption) => {
  return async (dispatch) => {
    dispatch({ type: ADD_PURPOSE, payload: purposeOption });
  };
};
const addDescription = (description) => {
  return async (dispatch) => {
    dispatch({ type: ADD_DESCRIPTION, payload: description });
  };
};
const addAdditionalInfo = (place) => {
  return async (dispatch) => {
    dispatch({ type: ADD_ADDITIONAL_INFO, payload: place });
  };
};

/*theme is add in createList as theme is the last one*/
const createList = (list) => {
  let actionList = {
    data: {
      title: list.title,
      listFor: list.isFor,
      purpose: list.purposeOption,
      themeName: list.theme,
      userId: list.userId,
      place: list.place,
      listDesc: list.description,
      currency: "USD",
      actionListStatus: list.actionListStatus,
      userName: list.userName,
    },
    userType: list?.userType,
  };
  return async (dispatch, getState) => {
    const {
      userSignin: { userInfo },
    } = getState();
    try {
      const { data } = await AxiosApi.post("actionList/create", actionList, {
        headers: {
          token: userInfo.token,
        },
      });
      dispatch({ type: CREATE_LIST_SUCCESS, payload: data });
      let userDetails = { ...userInfo, userType: "organization" };
      Cookies.set("userInfo", JSON.stringify(userDetails));
      localStorage.setItem("userInfo", JSON.stringify(userDetails));
    } catch (error) {
      dispatch({ type: USER_SIGNIN_FAIL, payload: error?.message });
    }
  };
};

const userchangePassword = (obj) => async (dispatch) => {
  dispatch({ type: USER_FORGOT_PASSWORD_REQUEST, payload: obj });
  try {
    const { data } = await AxiosApi.post("/user/changePassword", obj);
    dispatch({ type: USER_CHANGE_PASSWORD_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: USER_CHANGE_PASSWORD_FAIL, payload: error });
  }
};

const addnewCardBillingAddress = (obj) => async (dispatch) => {
  dispatch({ type: USER_FORGOT_PASSWORD_REQUEST, payload: obj });
  try {
    const { data } = await AxiosApi.post("/user/saveCardDetails", obj);
    dispatch({ type: USER_NEW_CARD_BILLING_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: USER_NEW_CARD_BILLING_FAIL, payload: error });
  }
};

const updateCardBillingAddress = (obj) => async (dispatch) => {
  dispatch({ type: USER_UDATE_CARD_BILLING_REQUEST, payload: obj });
  try {
    const { data } = await AxiosApi.post("/user/updateCardDetails", obj);
    console.log("updateCardBillingAddress ", data);
    dispatch({ type: USER_UDATE_CARD_BILLING_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: USER_UDATE_CARD_BILLING_FAIL, payload: error });
  }
};

const getUserDetailsInfo = (userId) => async (dispatch) => {
  dispatch({ type: GET_USER_DETAIL_REQUEST, payload: userId });
  try {
    const response = await AxiosApi.get("/user/userDetailsById/" + userId, {});
    dispatch({ type: GET_USER_DETAIL_SUCCESS, payload: response?.data });
    dispatch({ type: USER_SIGNIN_SUCCESS, payload: response?.data });
    return response;
  } catch (error) {
    dispatch({ type: GET_USER_DETAIL_FAIL, payload: error });
    return error;
  }
};

const updateUserDetailsInfo = (obj) => async (dispatch) => {
  dispatch({ type: UPDATE_USER_DETAIL_REQUEST, payload: obj });
  try {
    const { data } = await AxiosApi.post("/user/updateUserDetails", obj);
    dispatch({ type: UPDATE_USER_DETAIL_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: UPDATE_USER_DETAIL_FAIL, payload: error });
  }
};

const getNeedsList = (params) => async (dispatch) => {
  dispatch({ type: USER_NEEDS_DETAIL_LIST_REQUEST, payload: params });
  try {
    const { data } = await AxiosApi.get(
      "/actionList/getNeedsByActionListId/" + params,
      {}
    );
    if (data?.length > 0)
      dispatch({ type: USER_NEEDS_DETAIL_LIST_SUCCESS, payload: data });
    return data;
  } catch (error) {
    dispatch({ type: USER_NEEDS_DETAIL_LIST_FAIL, payload: error });
    return error;
  }
};

const getWishList = (params) => async (dispatch) => {
  // console.log("getWishList", params);
  try {
    dispatch({ type: CREATE_WISHLIST_SUCCESS, payload: params });
  } catch (error) {
    console.log("getWishList", error);
  }
};

const placeOrder = (obj) => async (dispatch) => {
  try {
    const { data } = await AxiosApi.post("/order/placeOrder", obj);
    dispatch({ type: PLACE_ORDER_SUCCESS, payload: data });
    return data;
  } catch (error) {
    dispatch({ type: PLACE_ORDER_FAIL, payload: error });
    return error;
  }
};

const getStripeToken = (obj) => async (dispatch) => {
  try {
    const { data } = await AxiosApi.post("/order/getStripeToken", obj);
    dispatch({ type: STRIPE_TOKEN_SUCCESS, payload: data });
    return data;
  } catch (error) {
    dispatch({ type: STRIPE_TOKEN_FAIL, payload: error });
    return error;
  }
};

const deleteNeedsById = (params) => async (dispatch) => {
  try {
    const { data } = await AxiosApi.get(
      "/actionList/removeNeedById/" + params,
      {}
    );
    return data;
    // dispatch({ type: USER_NEEDS_DETAIL_LIST_SUCCESS, payload: data });
  } catch (error) {
    // dispatch({ type: USER_NEEDS_DETAIL_LIST_FAIL, payload: error });
  }
};

const getActionListOnNeedForMsg = (params) => async (dispatch) => {
  try {
    const response = await AxiosApi.get(
      "actionList/getActionListOnNeed/" + params
    );
    if (response?.data) {
      let data = response?.data[0];
      dispatch({ type: ACTION_LIST_ON_NEED_FOR_MSG_SUCCESS, payload: data });
    }

    // return data;
  } catch (error) {
    console.log("getActionListOnNeedForMsg_error", error);
    dispatch({ type: ACTION_LIST_ON_NEED_FOR_MSG_FAIL, payload: error });
  }
};

const getUserActionListChats = (params) => async (dispatch) => {
  try {
    const response = await AxiosApi.get("actionList/chat/" + params);
    if (response?.data) {
      // let data = response?.data[0]
      // console.log("response",response)
      dispatch({
        type: GET_USER_ACTION_LIST_CHATS_SUCCESS,
        payload: response?.data,
      });
    }

    return response;
  } catch (error) {
    console.log("getUserActionListChats_error", error);
    dispatch({ type: GET_USER_ACTION_LIST_CHATS_FAIL, payload: error });
    return error;
  }
};

//mymessage
const getUserActionMessageChats = (params) => async (dispatch) => {
  try {
    const response = await AxiosApi.get("actionList/getMyMessages/" + params);
    if (response?.data) {
      // let data = response?.data[0]
      // console.log("response",response)
      dispatch({
        type: GET_USER_ACTION_LIST_CHATS_SUCCESS,
        payload: response?.data,
      });
    }

    return response;
  } catch (error) {
    console.log("getUserActionListChats_error", error);
    dispatch({ type: GET_USER_ACTION_LIST_CHATS_FAIL, payload: error });
    return error;
  }
};

const postLastMessage = (token, data) => async (dispatch) => {
  try {
    const response = await AxiosApi.post("actionList/chat", data, {
      headers: {
        token: token,
      },
    });
    return response;
  } catch (error) {
    console.log("postLastMessage", error);
  }
};

const deleteUserActionListChats = (param) => async (dispatch) => {
  try {
    const response = await AxiosApi.delete("actionList/chat/" + param);
    return response;
  } catch (error) {
    console.log("deleteUserActionListChats", error);
  }
};

const updateAcionListStatusOnChat = (param, token) => async (dispatch) => {
  try {
    const response = await AxiosApi.post(
      "actionList/updateActionListStatus",
      param,
      {
        headers: {
          token: token,
        },
      }
    );
  } catch (error) {
    console.log(error);
  }
};

const getMyActionsRecord = (token, userId, actionType) => async (dispatch) => {
  try {
    let response;
    if (actionType == "buy") {
      response = await AxiosApi.get(
        `/order/getMyActions/${userId}?actionType=${actionType}`,
        {
          headers: {
            token: token,
            actionType: actionType,
          },
        }
      );
    } else if (actionType == "give") {
      response = await AxiosApi.get("/order/getMyActions/" + userId, {
        headers: {
          token: token,
          actionType: actionType,
        },
      });
    }
    if (response?.data) {
      dispatch({
        type: GET_MY_ACTIONS_RECORDS_SUCCESS,
        payload: response?.data,
      });
    }
    return response;
  } catch (error) {
    dispatch({ type: GET_MY_ACTIONS_RECORDS_FAIL });
  }
};

const setOrderStatusForMyActions = (payload, token) => async (dispatch) => {
  try {
    const response = await AxiosApi.post("/order/updateOrderStatus", payload, {
      headers: {
        token: token,
      },
    });
    return response;
  } catch (error) {
    console.log("setOrderStatusForMyActions", error);
  }
};
const setHelpCenterContactdata = (payload, token) => async (dispatch) => {
  try {
    const response = await AxiosApi.post("contact/save", payload, {
      headers: { token: token },
    });
    return response;
  } catch (error) {
    console.log("setHelpCenterContactdata", error);
  }
};

const getContactPolicyData = (token) => async (dispatch) => {
  try {
    const response = await AxiosApi.get("contact/getPolicy", {
      headers: { token: token },
    });
    return response;
  } catch (error) {
    console.log("getContactPolicyData", error);
  }
};

const getContactTermsData = (token) => async (dispatch) => {
  try {
    const response = await AxiosApi.get("contact/getTerms", {
      headers: { token: token },
    });
    return response;
  } catch (error) {
    console.log("getContactPolicyData", error);
  }
};

const getAndPostActionListUpdateNotification =
  (payload, token) => async (dispatch) => {
    try {
      const response = await AxiosApi.post(
        "actionList/updateNotification",
        payload,
        { headers: { token: token } }
      );
      Cookies.set(
        "actionListNotifications",
        JSON.stringify(response?.data?.notification)
      );
      localStorage.setItem(
        "actionListNotifications",
        JSON.stringify(response?.data?.notification)
      );
      return response;
    } catch (error) {
      console.log("getAndPostActionListUpdateNotification", error);
      return error;
    }
  };

const getAndPostUserUpdateNotification =
  (payload, token) => async (dispatch) => {
    try {
      const response = await AxiosApi.post("user/updateNotification", payload, {
        headers: { token: token },
      });
      Cookies.set(
        "userNotifications",
        JSON.stringify(response?.data?.notification)
      );
      localStorage.setItem(
        "userNotifications",
        JSON.stringify(response?.data?.notification)
      );
      return response;
    } catch (error) {
      console.log("getAndPostUserUpdateNotification", error);
      return error;
    }
  };

const getReportDetails = (id, token) => async (dispatch) => {
  try {
    const response = await AxiosApi.get("actionList/getReports/" + id, {
      headers: { token: token },
    });
    if (response?.data) {
      dispatch({ type: "GET_REPORT_DATA_SUCCESS", payload: response?.data });
      return response;
    }
  } catch (error) {
    dispatch({ type: "GET_REPORT_DATA_FAIL" });
    return error;
  }
};

const postSendInvite = (obj, id, token) => async (dispatch) => {
  try {
    const response = await AxiosApi.post("/user/teamSignupMail/" + id, obj, {
      headers: { token: token },
    });
    if (response?.data) {
      return response;
    }
  } catch (error) {
    return error;
  }
};

const checkValidEmail = (payload, token) => async (dispatch) => {
  try {
    const response = await AxiosApi.post("user/checkValidEmail", payload, {
      headers: { token: token },
    });
    if (response) {
      return response;
    }
  } catch (error) {
    return error;
  }
};

const getNeedDetailsData = (needId, token) => async (dispatch) => {
  try {
    const response = await AxiosApi.get(
      "/actionList/getNeedDetails/" + needId,
      { headers: { token: token } }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export {
  signin,
  register,
  logout,
  update,
  sendOtp,
  verifyOtp,
  forgotPassword,
  resetPassword,
  addGeneralInfo,
  addPurpose,
  addDescription,
  addAdditionalInfo,
  createList,
  deleteList,
  userchangePassword,
  addnewCardBillingAddress,
  getUserDetailsInfo,
  updateUserDetailsInfo,
  getNeedsList,
  updateCardBillingAddress,
  getWishList,
  placeOrder,
  getStripeToken,
  deleteNeedsById,
  getActionListOnNeedForMsg,
  getUserActionListChats,
  getUserActionMessageChats,
  postLastMessage,
  deleteUserActionListChats,
  updateAcionListStatusOnChat,
  getMyActionsRecord,
  setOrderStatusForMyActions,
  setHelpCenterContactdata,
  getContactPolicyData,
  getContactTermsData,
  getAndPostActionListUpdateNotification,
  getAndPostUserUpdateNotification,
  getReportDetails,
  postSendInvite,
  UserEmailVerification,
  resendVerification,
  checkValidEmail,
  getNeedDetailsData,
};
