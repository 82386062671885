import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { usePaymentInputs } from "react-payment-inputs";
import { validateCardDetails } from "../../../../utils/cardUtils";
import { ToastContainer, toast } from "react-toastify";
import { applyCoupon } from "./service";
import {
  getSubscriptionPlans,
  setBillingDetails,
  upgradeToProPlan,
} from "./service";
import { useDispatch, useSelector } from "react-redux";
import Remove from "../../../../assets/images/remove.png";
import { getStripeToken } from "../../../../Store/Actions/userActions";
import { isMobile } from "react-device-detect";

const NonActiveBilling = (props) => {
  const [subscriptionPlans, setSubscriptionPlans] = useState("");

  const [selectedPaymentType, setSelectedPaymentType] = useState("");
  const [cardNumber, setCardNumber] = useState();
  const [holderName, setHolderName] = useState();
  const [expirydate, setexpiryDate] = useState();
  const [planpriceID, setplanpriceID] = useState("");
  const [stripToken, setStripToken] = useState("");
  const [cardShow, setCardShow] = useState(false);

  const [cvvnum, setCvvnem] = useState("");
  const [openInputDiscount, setOpenInputDiscount] = useState(false);
  const [discountCode, setDiscountCode] = useState("");
  const [nameError, setNameError] = useState("");
  const [discountPercentage, setDiscountPercentage] = useState({
    isApplied: false,
    percentage: "",
  });
  const [radioButError, setRadioButError] = useState("");
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  const [cardError, setCardError] = useState({
    cardNumber: false,
    holderName: false,
    expirydate: false,
    cvvnum: false,
    cardmsg: "",
    holdermsg: "",
    expirymsg: "",
    cvvmsg: "",
  });

  const Dispatch = useDispatch();
  useEffect(() => {
    if (props?.actionListId) {
      getPlan();
    }
  }, []);

  const getPlan = async () => {
    console.log("i am non active");
    const data = await getSubscriptionPlans();
    if (data) {
      console.log(data);
      setSubscriptionPlans(data);
    }
  };

  const { meta, getCardNumberProps, getExpiryDateProps, getCVCProps } =
    usePaymentInputs();

  const handlePaymentType = (e) => {
    setCardShow(true);
    setSelectedPaymentType(e.target.value);
    if (e.target.id === "planYearly") {
      setplanpriceID(subscriptionPlans.price[0].planPriceId);
    } else {
      setplanpriceID(subscriptionPlans.price[1].planPriceId);
    }
  };
  console.log({ selectedPaymentType });
  const handleApplyCoupon = async () => {
    if (discountCode) {
      const data = await applyCoupon(discountCode);
      if (data?.success) {
        toast.success(`${data?.coupon?.percent_off} % discount applied`);
        setDiscountPercentage({
          isApplied: true,
          percentage: data?.coupon?.percent_off,
        });
      } else {
        toast.error(data?.msg);
      }
    }
  };

  const handleUpgradeToProPlan = async (strtoken) => {
    var obj = {};

    obj.planPriceId = planpriceID;
    obj.actionListId = props?.actionListId;
    obj.userId = userInfo?.userId;
    obj.email = userInfo?.email;
    obj.stripeToken = strtoken;
    obj.coupon = discountCode;

    const data = await upgradeToProPlan(obj);
    if (data?.subs?.status === "active") {
      props.getSubNData();
      toast.success("Thanks for Subscribing!");
      window.location.reload();
      clearData();
    }
  };

  const handleSubmit = async (e) => {
    setCardError("");
    e.preventDefault();
    if (meta.error) {
      return;
    } else {
      var validationform = { cardNumber, holderName, expirydate, cvvnum };
      if (cardNumber && holderName && expirydate && cvvnum && planpriceID) {
        stripeTokengenerator();
        addnewCardDetails();
      } else {
        let datavalidation = validateCardDetails(validationform);
        setCardError(datavalidation?.errorMessage);
        setRadioButError("Please select a plan!");
      }
    }
  };

  const stripeTokengenerator = () => {
    var validationform = { cardNumber, holderName, expirydate, cvvnum };
    var mystring = expirydate;
    var exp_date = mystring?.split("/");
    var month = exp_date[0];
    var year = exp_date[1];

    var stripeObj = {};
    stripeObj.cardNumber = cardNumber;
    stripeObj.expMonth = Number(month);
    stripeObj.expYear = Number(year);
    stripeObj.cvv = cvvnum;

    Dispatch(getStripeToken(stripeObj)).then((res) => {
      setStripToken(res.id);
      handleUpgradeToProPlan(res.id);
    });
  };

  const addnewCardDetails = async () => {
    var validationform = { cardNumber, holderName, expirydate, cvvnum };
    let datavalidation = validateCardDetails(validationform);

    setCardError(datavalidation?.errorMessage);
    if (datavalidation?.isValid) {
      return;
    }
    var obj = {};

    obj.cardNumber = cardNumber;
    obj.cardHolderName = holderName;
    obj.expirationDate = expirydate;

    const data = await setBillingDetails(obj, props?.actionListId);
    if (data?.msg) {
      // stripeTokengenerator();
      toast.success(data?.msg);
    } else {
      toast.error("Card Details not saved");
    }
  };

  const setcardeipiryData = (e) => {
    setCardError({ ...cardError, expirymsg: "" });
    setexpiryDate(e.target.value);
  };

  const handleFormatCardNumber = (e) => {
    setCardError({ ...cardError, cardmsg: "" });
    if (e.target.value.length <= 20) {
      setCardNumber(e.target.value);
    }
  };

  const handleChangeCVC = (e) => {
    setCardError({ ...cardError, cvvmsg: "" });
    setCvvnem(e.target.value);
  };

  const handleNameChange = (e) => {
    setHolderName(e.target.value);
    if (e.target.value === undefined || e.target.value === "") {
      setNameError("Please Enter your Card Holder Name");
    } else {
      setCardError({ ...cardError, holdermsg: "" });
      setNameError("");
    }
  };

  const clearData = () => {
    window.location.reload();
    setCardNumber("");
    setHolderName("");
    setexpiryDate("");
    setCvvnem("");
  };

  const handleRemoveCoupon = () => {
    setDiscountPercentage({ isApplied: false, percentage: "" });
  };

  const handleOpen = () => {
    setOpenInputDiscount(!openInputDiscount);
    setDiscountCode("");
  };

  const renderCardPContant = () => {
    return (
      <>
        <div className="divider mobile-view"></div>
        <div className="form-group row">
          <div className="col-xl-6 col-lg-6 col-12">
            <label htmlFor="cardnumber" className="label">
              Card number
            </label>
            <div className="input-group">
              <div className="input-group-prepend">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 6C0 5.20435 0.316071 4.44129 0.87868 3.87868C1.44129 3.31607 2.20435 3 3 3H21C21.7956 3 22.5587 3.31607 23.1213 3.87868C23.6839 4.44129 24 5.20435 24 6V7.5H0V6Z"
                    fill="#607BFF"
                  />
                  <path
                    fillRule="evenodd"
                    clip-rule="evenodd"
                    d="M0 10.5V18C0 18.7956 0.316071 19.5587 0.87868 20.1213C1.44129 20.6839 2.20435 21 3 21H21C21.7956 21 22.5587 20.6839 23.1213 20.1213C23.6839 19.5587 24 18.7956 24 18V10.5H0ZM4.5 13.5C4.10218 13.5 3.72064 13.658 3.43934 13.9393C3.15804 14.2206 3 14.6022 3 15V16.5C3 16.8978 3.15804 17.2794 3.43934 17.5607C3.72064 17.842 4.10218 18 4.5 18H6C6.39782 18 6.77936 17.842 7.06066 17.5607C7.34196 17.2794 7.5 16.8978 7.5 16.5V15C7.5 14.6022 7.34196 14.2206 7.06066 13.9393C6.77936 13.658 6.39782 13.5 6 13.5H4.5Z"
                    fill="#607BFF"
                  />
                </svg>
              </div>
              <input
                type="text"
                className="form-control"
                id="cardnumber"
                name="cardnumber"
                placeholder="0000 0000 0000 0000"
                value={cardNumber}
                {...getCardNumberProps({
                  onChange: handleFormatCardNumber,
                })}
                maxLength={20}
                //onChange={handleFormatCardNumber}
                placeholder="0000 0000 0000 0000"
              />
            </div>
            {cardError?.cardNumber && (
              <div className="warningmessage">{cardError?.cardmsg}</div>
            )}
            {meta.touchedInputs.cardNumber &&
              meta?.erroredInputs?.cardNumber && (
                <div className="warningmessage">
                  {meta?.erroredInputs?.cardNumber}
                </div>
              )}
          </div>
          <div className="col-xl-6 col-lg-6 col-12">
            <label htmlFor="cardholdername" className="label">
              Cardholder name
            </label>
            <input
              type="text"
              className="form-control"
              id="cardholdername"
              name="cardholdername"
              value={holderName}
              onChange={handleNameChange}
              placeholder="JOHN SMITH"
            />
            {cardError?.holderName && (
              <div className="warningmessage">{cardError?.holdermsg}</div>
            )}
            {nameError && <div className="warningmessage">{nameError}</div>}
          </div>
        </div>
        <div className="form-group row">
          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6">
            <label htmlFor="expirydate" className="label">
              Expiration date
            </label>
            <input
              type="text"
              className="form-control"
              id="expirydate"
              name="expirydate"
              placeholder="MM/YY"
              value={expirydate}
              {...getExpiryDateProps({ onChange: setcardeipiryData })}
              placeholder="MM/YY"
            />
            {cardError?.expirydate && (
              <div className="warningmessage">{cardError?.expirymsg}</div>
            )}
            {meta.touchedInputs?.expiryDate &&
              meta?.erroredInputs?.expiryDate && (
                <div className="warningmessage">
                  {meta?.erroredInputs?.expiryDate}
                </div>
              )}
          </div>
          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6">
            <label htmlFor="cvv" className="label">
              CVC/Secure code
            </label>
            <input
              type="text"
              className="form-control"
              id="cvv"
              placeholder="CVC/CVV"
              value={cvvnum}
              {...getCVCProps({ onChange: handleChangeCVC })}
              maxLength={3}
              minLength={3}
            />
            {cardError?.cvvnum && (
              <div className="warningmessage">{cardError?.cvvmsg}</div>
            )}
            {meta.touchedInputs?.cvc && meta?.erroredInputs?.cvc && (
              <div className="warningmessage">{meta?.erroredInputs?.cvc}</div>
            )}
          </div>
        </div>
        <div className="billing-form payment-form pb-0">
          {!discountPercentage?.percentage && (
            <div
              className="discount-code"
              //onClick={setOpenInputDiscount(!openInputDiscount)}
            >
              <a className="link-extra" onClick={handleOpen}>
                Enter Discount Code
              </a>
            </div>
          )}
          {openInputDiscount && !discountPercentage?.percentage && (
            <div className="discount-code-form">
              <div className="row input-row">
                <div className="col-6 col-md-4">
                  <input
                    type="text"
                    placeholder="Type code"
                    value={discountCode}
                    onChange={(e) => setDiscountCode(e.target.value)}
                  />
                </div>
                <div className="col-6 col-md-4">
                  <button
                    // type="submit"
                    className="btn btn-prime"
                    onClick={() => handleApplyCoupon()}
                    disabled={!discountCode ? true : false}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          )}
          {discountPercentage?.percentage && (
            <div
              className="card-row"
              style={{
                background: "#FFFFFF",
                boxSizing: "borderBox",
                borderRadius: "8px",
              }}
            >
              <div className="existing-card-row">
                <div className="column2">
                  <label className="row1">
                    <span style={{ color: "#607BFF" }}>
                      {discountPercentage?.percentage}% off{" "}
                    </span>
                    with the discount code{" "}
                    <span style={{ fontWeight: "bold" }}>{discountCode}</span>
                  </label>
                </div>
                <div className="column4">
                  <button
                    type="button"
                    className="btn-remove"
                    onClick={handleRemoveCoupon}
                  >
                    <img src={Remove} />
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="divider for-desktop"></div>
        <div className="pick-plan for-desktop">
          <h4>Pick a plan to start after your trial</h4>
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6">
              <div className="option-wrapper">
                <div className="column1">
                  <label htmlFor="planMonthly">
                    <input
                      type="radio"
                      name="subscriptionPlan"
                      id="planMonthly"
                      onChange={handlePaymentType}
                      defaultValue="monthly"
                      checked={selectedPaymentType === "monthly"}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className="column2">
                  <label>
                    {"$" + subscriptionPlans.price[1]?.amount || "9" + "/month"}
                  </label>
                  <label>Billed monthly</label>
                </div>
              </div>
              {planpriceID === "" && (
                <div className="warningmessage">{radioButError}</div>
              )}
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6">
              <div className="option-wrapper">
                <div className="column1">
                  <label htmlFor="planYearly">
                    <input
                      type="radio"
                      name="subscriptionPlan"
                      id="planYearly"
                      onChange={handlePaymentType}
                      defaultValue="yearly"
                      checked={selectedPaymentType === "yearly"}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className="column2">
                  <label>
                    {"$" + subscriptionPlans.price[0]?.amount ||
                      "99" + "/ year"}
                  </label>
                  <label>Billed yearly</label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <button type="submit" className="btn-prime">
          Upgrade to Pro
        </button>
        <ToastContainer autoClose={3000} />
      </>
    );
  };
  return (
    <>
      <h2 className="heading2">You don't have a membership yet</h2>
      {props?.subscriptionData?.msg && (
        <h3 className="free-days">
          <b>
            Start your <span style={{ color: "#607BFF" }}>free 7 days</span>
          </b>
        </h3>
      )}
      {subscriptionPlans && subscriptionPlans.price[1]?.amount && (
        <>
          <div className="pick-plan mobile-view" style={{ padding: "0 16px" }}>
            <h4>Pick a plan to start after your trial</h4>
            <div className="row">
              <div className="col-12">
                <div className="option-wrapper">
                  <div className="column1">
                    <label htmlFor="planMonthly">
                      <input
                        type="radio"
                        name="subscriptionPlan"
                        id="planMonthly"
                        onChange={handlePaymentType}
                        defaultValue="monthly"
                        checked={selectedPaymentType === "monthly"}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="column2">
                    <label>
                      {"$" + subscriptionPlans.price[1]?.amount ||
                        "9" + "/month"}
                    </label>
                    <label>Billed monthly</label>
                  </div>
                </div>
                {planpriceID === "" && (
                  <div className="warningmessage">{radioButError}</div>
                )}
              </div>
              <div className="col-12">
                <div className="option-wrapper">
                  <div className="column1">
                    <label htmlFor="planYearly">
                      <input
                        type="radio"
                        name="subscriptionPlan"
                        id="planYearly"
                        onChange={handlePaymentType}
                        defaultValue="yearly"
                        checked={selectedPaymentType === "yearly"}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="column2">
                    <label>
                      {"$" + subscriptionPlans.price[0]?.amount ||
                        "99" + "/ year"}
                    </label>
                    <label>Billed yearly</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <form
            className="billing-form payment-form pb-0"
            onSubmit={handleSubmit}
          >
            <>
              {isMobile && cardShow
                ? renderCardPContant()
                : !isMobile && renderCardPContant()}
            </>
          </form>
        </>
      )}
    </>
  );
};

export default NonActiveBilling;
