import React, { useState, useEffect } from "react";
import goBackImage from "../../assets/images/back_black.png";
import masterCardImage from "../../assets/images/mastercard.png";
import removeArrowImage from "../../assets/images/remove.png";
import Profileheadermobile from "../../Common/Profileheadermobile";
import ProfileLeftPane from "../../Common/ProfileLeftPane";
import {
  addnewCardBillingAddress,
  getUserDetailsInfo,
  updateCardBillingAddress,
} from "../../Store/Actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import BottomTab from "../../Common/BottomTab";
import AxiosApi from "../../Config/axios-api";
import UnloginuserHead from "../../Common/UnloginuserHead";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import { isMobile } from "react-device-detect";

const PaymentMethods = ({ ...props }) => {
  const dispatch = useDispatch();
  const userSignin = useSelector((state) => state.userSignin);
  const { loading: userloading, userInfo, error: usererror } = userSignin;
  const UserDetails = useSelector((state) => state.UserDetails);
  const [countrylist, setCountryList] = useState([
    { id: 1, name: "United States" },
    { id: 2, name: "Canada" },
    { id: 3, name: "Brazil" },
    { id: 4, name: "Mexico" },
    { id: 5, name: "Colombia" },
    { id: 6, name: "Argentina" },
    { id: 7, name: "Peru" },
    { id: 8, name: "Venezuela" },
    { id: 9, name: "Chile" },
    { id: 10, name: "Ecuador" },
    { id: 11, name: "Guatemala" },
    { id: 12, name: "Bolivia" },
    { id: 13, name: "Haiti" },
    { id: 14, name: "Cuba" },
    { id: 15, name: "Dominican Republic" },
    { id: 16, name: "Honduras" },
    { id: 17, name: "Paraguay" },
    { id: 18, name: "El Salvador" },
    { id: 19, name: "Nicaragua" },
    { id: 20, name: "Costa Rica" },
    { id: 21, name: "Panama" },
    { id: 22, name: "Uruguay" },
    { id: 23, name: "Puerto Rico" },
    { id: 24, name: "Jamaica" },
    { id: 25, name: "Trinidad and Tobago" },
    { id: 26, name: "Guyana" },
    { id: 27, name: "Suriname" },
    { id: 28, name: "Belize" },
    { id: 29, name: "Guadeloupe" },
    { id: 30, name: "Bahamas" },
    { id: 31, name: "Martinique" },
    { id: 32, name: "Barbados" },
    { id: 32, name: "French Guiana" },
    { id: 32, name: "Saint Lucia" },
    { id: 32, name: "Curaçao" },
    { id: 32, name: "Grenada" },
    { id: 32, name: "Aruba" },
    { id: 32, name: "Saint Vincent and the Grenadines" },
    { id: 32, name: "United States Virgin Islands" },
    { id: 32, name: "Antigua and Barbuda" },
    { id: 32, name: "Dominica" },
    { id: 32, name: "Cayman Islands" },
    { id: 32, name: "Bermuda" },
    { id: 32, name: "Greenland" },
    { id: 32, name: "Saint Kitts and Nevis" },
    { id: 32, name: "Turks and Caicos Islands" },
    { id: 32, name: "Sint Maarten" },
    { id: 32, name: "Saint Martin" },
    { id: 32, name: "British Virgin Islands" },
    { id: 32, name: "Caribbean Netherlands" },
    { id: 32, name: "Saint Barthélemy" },
    { id: 32, name: "Saint Pierre and Miquelon" },
    { id: 32, name: "Montserrat" },
    { id: 32, name: "Falkland Islands" },
  ]);
  const {
    loading: userLoading,
    getuserInfo,
    error: userinfoerror,
  } = UserDetails;
  const userCardBillingAddress = useSelector(
    (state) => state.userCardBillingAddress
  );
  const {
    loading: cardloading,
    usercardBillingAddInfo,
    error: carderror,
  } = userCardBillingAddress;
  const userupdateCardBillingAddress = useSelector(
    (state) => state.userupdateCardBillingAddress
  );
  const {
    loading: updatecardloading,
    userupdatecardBillingAddInfo,
    error: updatecarderror,
  } = userupdateCardBillingAddress;
  const [primaryPaymentMethodChecked, setPrimaryPaymentMethodChecked] =
    useState(false);
  const [cardNumber, setCardNumber] = useState();
  const [holderName, setHolderName] = useState();
  const [expirydate, setexpiryDate] = useState();
  const [cvvnum, setCvvnem] = useState();
  const [editcardId, setCardId] = useState("");
  const [cardError, setCardError] = useState({
    cardNumber: false,
    holderName: false,
    expirydate: false,
    cvvnum: false,
    cardmsg: "",
    holdermsg: "",
    expirymsg: "",
    cvvmsg: "",
  });
  const [country, setCountry] = useState("");
  const [town, setTOwn] = useState("");
  const [street, setStreen] = useState("");
  const [province, setProvince] = useState("");
  const [postal, setPostal] = useState("");
  const [billingerror, setBillingError] = useState({
    country: false,
    town: false,
    street: false,
    province: false,
    postal: false,
    countrymsg: "",
    townmsg: "",
    streetmsg: "",
    provincemsg: "",
    postalmsg: "",
  });
  const [primaryCard, setPrimaryCard] = useState([]);
  const [otherCard, setOtherCard] = useState([]);
  const [chagecardmsg, setchagecardmsg] = useState("");
  const [editStatus, setEditStatus] = useState(false);
  useEffect(() => {
    dispatch(getUserDetailsInfo(userInfo?.userId));
  }, []);
  useEffect(() => {
    if (getuserInfo) {
      var otherCard = getuserInfo?.savedCards;  
      var temparray = [];
      var findIndex = otherCard.findIndex(
        (item) => getuserInfo?.primaryCardId == item.cardId
      );
      if (findIndex != -1) {
        otherCard.length > 0 && temparray.push(otherCard[findIndex]);
        setPrimaryCard(temparray);
        otherCard.splice(findIndex, 1);
        setOtherCard(otherCard);
      } else {
        setOtherCard(otherCard);
      }
    }

    return () => {};
  }, [getuserInfo]);

  useEffect(() => {
    if (usercardBillingAddInfo?.msg.includes("Card Details Saved")) {
      setchagecardmsg(usercardBillingAddInfo?.msg);
      cleareData();
      dispatch(getUserDetailsInfo(userInfo?.userId));
      setTimeout(
        function () {
          window.$("#addPaymentBillingModal").modal("hide");
          setchagecardmsg("");
        }.bind(this),
        3000
      );
      usercardBillingAddInfo.msg = "";
    }
    if (carderror?.msg) {
      setchagecardmsg(carderror?.msg);
      setTimeout(
        function () {
          setchagecardmsg("");
        }.bind(this),
        5000
      );
      carderror.msg = "";
    }
    return () => {
      //
    };
  }, [usercardBillingAddInfo, carderror]);

  useEffect(() => {
    if (userupdatecardBillingAddInfo?.msg.includes("card details updated!")) {
      setchagecardmsg(userupdatecardBillingAddInfo?.msg);
      cleareData();
      dispatch(getUserDetailsInfo(userInfo?.userId));
      setTimeout(
        function () {
          window.$("#addPaymentBillingModal").modal("hide");
          setchagecardmsg("");
        }.bind(this),
        3000
      );
      userupdatecardBillingAddInfo.msg = "";
    }
    if (updatecarderror?.msg) {
      setchagecardmsg(updatecarderror?.msg);
      setTimeout(
        function () {
          setchagecardmsg("");
        }.bind(this),
        5000
      );
      updatecarderror.msg = "";
    }
    return () => {
      //
    };
  }, [userupdatecardBillingAddInfo, updatecarderror]);

  const toggleAddNewCardModal = () => {
    window.$("#addPaymentBillingModal").modal("show");
    cleareData();
    setCardId("");
    setEditStatus(false);
  };

  const addBillingAddress = () => {
    window.$("#addPaymentBillingModal").modal("hide");
    window.$("#addBillingDataModal").modal("show");
  };
  const editpaymentMethod = () => {
    setCountry("");
    setTOwn("");
    setStreen("");
    setProvince("");
    setPostal("");
    window.$("#addBillingDataModal").modal("hide");
    window.$("#addPaymentBillingModal").modal("show");
  };
  const handleAddCardSubmit = (e) => {
    e.preventDefault();
  };
  const addnewCardDetails = () => {
    var validationform = { cardNumber, holderName, expirydate, cvvnum };
    let datavalidation = validationfun(validationform);

    if (datavalidation) {
      return;
    }
    var obj = {};
    var billadd = {};
    billadd.country = country;
    billadd.city = town;
    billadd.streetAddress = street;
    billadd.province = province;
    billadd.postalCode = parseInt(postal);
    if (editStatus) obj.cardId = editcardId;
    obj.userId = userInfo?.userId;
    obj.cardNumber = cardNumber;
    obj.cardHolderName = holderName;
    obj.expirationDate = expirydate;
    obj.cvv = cvvnum;
    obj.setAsPrimary = primaryPaymentMethodChecked;
    obj.billingAddress = billadd;
    if (editStatus) {
      dispatch(updateCardBillingAddress(obj));
    } else {
      dispatch(addnewCardBillingAddress(obj));
    }
  };
  const validationfun = (validationform) => {
    let showallerror = false;
    var form = {
      cardNumber: false,
      holderName: false,
      expirydate: false,
      cvvnum: false,
      cardmsg: "",
      holdermsg: "",
      expirymsg: "",
      cvvmsg: "",
    };
    if (
      validationform.cardNumber === undefined ||
      validationform.cardNumber === ""
    ) {
      showallerror = true;
      form.cardNumber = true;
      form.cardmsg = "Please Enter your Card Number";
    }
    if (
      validationform.holderName === undefined ||
      validationform.holderName === ""
    ) {
      showallerror = true;
      form.holderName = true;
      form.holdermsg = "Please Enter your Card Holder Name";
    }
    if (
      validationform.expirydate === undefined ||
      validationform.expirydate === ""
    ) {
      showallerror = true;
      form.expirydate = true;
      form.expirymsg = "Please Enter Card Expiry Date";
    }
    if (validationform.cvvnum === undefined || validationform.cvvnum === "") {
      showallerror = true;
      form.cvvnum = true;
      form.cvvmsg = "Please Enter Cvv/CVC";
    }
    setCardError(form);
    return showallerror;
  };

  const addbillingDetails = () => {
    var validationform = { country, town, street, province, postal };
    let datavalidation = billingvalidationfun(validationform);
    if (datavalidation) {
      return;
    }
    window.$("#addBillingDataModal").modal("hide");
    window.$("#addPaymentBillingModal").modal("show");
  };

  const billingvalidationfun = (validationform) => {
    let showallerror = false;
    var form = {
      country: false,
      town: false,
      street: false,
      province: false,
      postal: false,
      countrymsg: "",
      townmsg: "",
      streetmsg: "",
      provincemsg: "",
      postalmsg: "",
    };
    if (validationform.country === undefined || validationform.country === "") {
      showallerror = true;
      form.country = true;
      form.countrymsg = "Please Select Billing Country";
    }
    if (validationform.town === undefined || validationform.town === "") {
      showallerror = true;
      form.town = true;
      form.townmsg = "Please Enter Billing Town";
    }
    if (validationform.street === undefined || validationform.street === "") {
      showallerror = true;
      form.street = true;
      form.streetmsg = "Please Enter Billing Street";
    }
    if (
      validationform.province === undefined ||
      validationform.province === ""
    ) {
      showallerror = true;
      form.province = true;
      form.provincemsg = "Please Enter Billing province";
    }
    if (validationform.postal === undefined || validationform.postal === "") {
      showallerror = true;
      form.postal = true;
      form.postalmsg = "Please Enter Billing Postal Code";
    }
    setBillingError(form);
    return showallerror;
  };
  const setcardeipiryData = (e) => {
    if (e.target.value.length <= 5) {
      if (e.target.value.indexOf("/") === -1) {
        if (e.target.value.length === 3) {
          var tempstr =
            e.target.value.slice(0, 2) + "/" + e.target.value.slice(2);
        } else {
          var tempstr = e.target.value;
        }
      } else {
        var tempstr = e.target.value;
      }

      setexpiryDate(tempstr);
    }
  };

  const deleteCard = async (item) => {
    // e.preventDefault();
    Swal.fire({
      title: "Are you sure to want delete Card!",
      showCancelButton: true,
      confirmButtonColor: "#e8271a",
      confirmButtonText: `Delete`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const { data } = await AxiosApi.delete(
            "/removeCard/" + userInfo?.userId + "/" + item.cardId,
            {}
          );
          if (data?.msg === "card removed!") {
            Swal.fire("Deleted!", data?.msg, "success");
            dispatch(getUserDetailsInfo(userInfo?.userId));
          } else {
            toast.error("Something went worng!");
          }
        } catch (error) {
          toast.error(error?.msg);
        }
      }
    });
  };

  const editItem = async (item, primary) => {
    setchagecardmsg("");
    setCardId(item.cardId);
    setCardNumber(item.cardNumber);
    setHolderName(item.cardHolderName);
    setexpiryDate(item.expirationDate);
    setCvvnem(item.cvv);
    setPrimaryPaymentMethodChecked(primary);
    setCountry(item.billingAddress.country);
    setTOwn(item.billingAddress.city);
    setStreen(item.billingAddress.streetAddress);
    setProvince(item.billingAddress.province);
    setPostal(item.billingAddress.postalCode);
    window.$("#addPaymentBillingModal").modal("show");
    setEditStatus(true);
  };

  const cleareData = () => {
    setCardNumber("");
    setHolderName("");
    setexpiryDate("");
    setCvvnem("");
    setPrimaryPaymentMethodChecked(false);
    setCountry("");
    setTOwn("");
    setStreen("");
    setProvince("");
    setPostal("");
  };

  return (
    <div>
      {!isMobile &&
        (Cookies.get("token") ? (
          <Profileheadermobile history={props.history} />
        ) : (
          <UnloginuserHead history={props.history} />
        ))}
      <div className="content profile">
        <ProfileLeftPane activePage="payment-methods" />
        <div className="tab-content" id="settingsTabContent">
          <div
            className="tab-pane fade"
            id="account-settings"
            role="tabpanel"
            aria-labelledby="account-settings-tab"
          >
            Account settings
          </div>
          <div
            className="tab-pane fade show active"
            id="payment-methods"
            role="tabpanel"
            aria-labelledby="payment-methods-tab"
          >
            <div className="mobile-header">
              <nav className="navbar">
                <div onClick={() => props.history.goBack()} className="back">
                  <img src={goBackImage} alt="go-back" />
                </div>
                <label className="navbar-brand mx-auto middle-label">
                  Payment methods
                </label>
              </nav>
            </div>
            <div class="inner-content">
              {/* <div class='donation-history'>
                <a class='link-extra'>
                  View history of donations
                </a>
              </div> */}
              <h2 class="heading2">Payment methods</h2>
              {!(
                (otherCard && otherCard.length > 0) ||
                (primaryCard && primaryCard.length > 0)
              ) && "No payment methods added"}
              <div class="card-row">
                {otherCard &&
                  otherCard.length > 0 &&
                  primaryCard &&
                  primaryCard.length > 0 && <label>Primary</label>}
                {primaryCard &&
                  primaryCard.length > 0 &&
                  primaryCard.map((item, index) => {
                    return (
                      <>
                        <div
                          class="existing-card-row "
                          style={{ marginTop: index === 0 ? 0 : 10 }}
                          onClick={() => editItem(item, true)}
                        >
                          <div class="column1">
                            <label for="mastercard">
                              <input
                                type="radio"
                                name="paymentcard"
                                id="mastercard"
                              />
                              <span class="checkmark"></span>
                            </label>
                          </div>
                          <div class="column2">
                            <img src={masterCardImage} alt="master card" />
                          </div>
                          <div class="column3">
                            <label class="row1">
                              **** **** ****{" "}
                              {String(item?.cardNumber)?.substr(12)}
                              <span>- {item?.expirationDate}</span>
                            </label>
                            <label class="row2">{item?.cardname}</label>
                          </div>
                          <div class="column4">
                            <button
                              type="button"
                              class="btn-remove"
                              onClick={(e) => (
                                e.stopPropagation(), deleteCard(item)
                              )}
                            >
                              <img src={removeArrowImage} alt="remove" />
                            </button>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
              <div class="card-row">
                {otherCard &&
                  otherCard.length > 0 &&
                  primaryCard &&
                  primaryCard.length > 0 && <label>Other</label>}
                {otherCard &&
                  otherCard.length > 0 &&
                  otherCard.map((item, index) => {
                    return (
                      <>
                        <div
                          class="existing-card-row"
                          style={{ marginTop: index === 0 ? 0 : 10 }}
                          onClick={() => editItem(item, false)}
                        >
                          <div class="column1">
                            <label for="mastercard">
                              <input
                                type="radio"
                                name="paymentcard"
                                id="mastercard"
                              />
                              <span class="checkmark"></span>
                            </label>
                          </div>
                          <div class="column2">
                            <img src={masterCardImage} alt="master card" />
                          </div>
                          <div class="column3">
                            <label class="row1">
                              **** **** ****{" "}
                              {String(item.cardNumber).substr(12)}
                              <span>- {item.expirationDate}</span>
                            </label>
                            <label class="row2">{item.cardname}</label>
                          </div>
                          <div class="column4">
                            <button
                              type="button"
                              class="btn-remove"
                              onClick={(e) => (
                                e.stopPropagation(), deleteCard(item)
                              )}
                            >
                              <img src={removeArrowImage} alt="remove" />
                            </button>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
              <button
                type="button"
                class="btn btn-extra btn-add-card mb-3"
                onClick={toggleAddNewCardModal}
              >
                Add new card
              </button>
            </div>
          </div>

          <div
            className="tab-pane fade"
            id="email-notifications"
            role="tabpanel"
            aria-labelledby="email-notifications-tab"
          >
            Email notifications
          </div>
          <div
            className="tab-pane fade"
            id="password-reset"
            role="tabpanel"
            aria-labelledby="password-reset-tab"
          >
            Password reset
          </div>
        </div>

        <BottomTab activeScreen={"profile"} />
      </div>
      <div
        class="modal fade add-payment-billing-modal"
        id="addPaymentBillingModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <button
                type="button"
                class="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <img src="images/close2.png" alt="close" />
              </button>
              <div class="mobile-header">
                <nav class="navbar">
                  <label class="navbar-brand mx-auto middle-label">
                    New payment method
                  </label>
                  <span class="cross">
                    <img
                      src="images/close.png"
                      alt="close"
                      onClick={() =>
                        window.$("#addPaymentBillingModal").modal("hide")
                      }
                    />
                  </span>
                </nav>
              </div>
              <div class="content">
                <h2 class="heading2 desktop-view">Add a new payment method</h2>
                <h2 class="heading2 mobile-view">Payment details</h2>
                <form onSubmit={handleAddCardSubmit}>
                  <div class="form-group row">
                    <div class="col-xl-6 col-lg-6">
                      <label for="cardnumber" class="label">
                        Card number
                      </label>
                      <div class="input-group disabled">
                        <div class="input-group-prepend">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0 6C0 5.20435 0.316071 4.44129 0.87868 3.87868C1.44129 3.31607 2.20435 3 3 3H21C21.7956 3 22.5587 3.31607 23.1213 3.87868C23.6839 4.44129 24 5.20435 24 6V7.5H0V6Z"
                              fill="#607BFF"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M0 10.5V18C0 18.7956 0.316071 19.5587 0.87868 20.1213C1.44129 20.6839 2.20435 21 3 21H21C21.7956 21 22.5587 20.6839 23.1213 20.1213C23.6839 19.5587 24 18.7956 24 18V10.5H0ZM4.5 13.5C4.10218 13.5 3.72064 13.658 3.43934 13.9393C3.15804 14.2206 3 14.6022 3 15V16.5C3 16.8978 3.15804 17.2794 3.43934 17.5607C3.72064 17.842 4.10218 18 4.5 18H6C6.39782 18 6.77936 17.842 7.06066 17.5607C7.34196 17.2794 7.5 16.8978 7.5 16.5V15C7.5 14.6022 7.34196 14.2206 7.06066 13.9393C6.77936 13.658 6.39782 13.5 6 13.5H4.5Z"
                              fill="#607BFF"
                            />
                          </svg>
                        </div>
                        <input
                          type="text"
                          class="form-control"
                          id="cardnumber"
                          value={cardNumber}
                          onChange={(e) => setCardNumber(e.target.value)}
                          placeholder="0000 0000 0000 0000"
                        />
                      </div>
                      {cardError.cardNumber && (
                        <div className="warningmessage">
                          {cardError.cardmsg}
                        </div>
                      )}
                    </div>
                    <div class="col-xl-6 col-lg-6">
                      <label for="cardholdername" class="label">
                        Cardholder name
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="cardholdername"
                        value={holderName}
                        onChange={(e) => setHolderName(e.target.value)}
                        placeholder="JOHN SMITH"
                      />
                      {cardError.holderName && (
                        <div className="warningmessage">
                          {cardError.holdermsg}
                        </div>
                      )}
                    </div>
                  </div>
                  <div class="form-group row date-code-row">
                    <div class="col-xl-4 col-lg-4 col-6 left">
                      <label for="expirationDate" class="label">
                        Expiration date
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="expirationDate"
                        value={expirydate}
                        // onKeyUp={(e) => setcardeipiryData(e)}
                        onChange={(e) => setcardeipiryData(e)}
                        placeholder="MM/YY"
                        maxLength={5}
                        minLength={5}
                      />
                      {cardError.expirydate && (
                        <div className="warningmessage">
                          {cardError.expirymsg}
                        </div>
                      )}
                    </div>
                    <div class="col-xl-4 col-lg-4 col-6 right">
                      <label for="cvc" class="label">
                        CVC/Secure code
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="cvc"
                        type="password"
                        placeholder="CVC/CVV"
                        value={cvvnum}
                        maxLength={3}
                        minLength={3}
                        onChange={(e) =>
                          e.target.value.length <= 3 &&
                          setCvvnem(e.target.value)
                        }
                      />
                      {cardError.cvvnum && (
                        <div className="warningmessage">{cardError.cvvmsg}</div>
                      )}
                    </div>
                  </div>
                  <label for="setMethod" class="setmethod">
                    Set as a Primary Payment Method
                    <input
                      type="checkbox"
                      id="setMethod"
                      checked={primaryPaymentMethodChecked}
                      onChange={() =>
                        setPrimaryPaymentMethodChecked((val) => !val)
                      }
                    />
                    <span class="checkmark"></span>
                  </label>
                  <div class="form-group row">
                    <div class="col-xl-6 col-lg-6">
                      <button
                        type="button"
                        onClick={() => addnewCardDetails()}
                        class="btn btn-prime"
                      >
                        Add card
                      </button>
                    </div>
                    <div class="col-xl-6 col-lg-6">
                      <button
                        type="button"
                        class="btn btn-extra"
                        onClick={() => addBillingAddress()}
                      >
                        Add billing address
                      </button>
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: 20,
                      textAlign: "center",
                      color: chagecardmsg.includes("Card Details Saved")
                        ? "green"
                        : chagecardmsg.includes("card details updated!")
                        ? "green"
                        : "red",
                    }}
                  >
                    {chagecardmsg}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade add-payment-billing-modal"
        id="addBillingDataModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <button
                type="button"
                class="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <img src="images/close2.png" />
              </button>
              <div class="mobile-header">
                <nav class="navbar">
                  <label class="navbar-brand mx-auto middle-label">
                    New payment method
                  </label>
                  <span class="cross">
                    <img
                      src="images/close.png"
                      onClick={() =>
                        window.$("#addBillingDataModal").modal("hide")
                      }
                    />
                  </span>
                </nav>
              </div>
              <div class="content">
                <h2 class="heading2 desktop-view">Add billing address</h2>
                <h2 class="heading2 mobile-view">Billing address</h2>
                <form>
                  <div class="form-group row">
                    <div class="col-xl-6 col-lg-6">
                      <label for="cardnumber" class="label">
                        Country
                      </label>
                      <div class="dropdown country">
                        <button
                          class="btn btn-secondary dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          style={{
                            color: country === "" ? "#98A4B8" : "#243457",
                          }}
                        >
                          {country === "" ? "Select one" : country}
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            class="ml-auto"
                          >
                            <path
                              d="M4.00012 9L12.0001 17L20.0001 9"
                              stroke="#607BFF"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </button>
                        <div
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                          style={{
                            overflowY: "scroll",
                            height: 250,
                            transform: "translate3d(0px, 50px, 0px)",
                          }}
                        >
                          {countrylist &&
                            countrylist.length > 0 &&
                            countrylist.map((item) => {
                              return (
                                <p
                                  onClick={() => setCountry(item.name)}
                                  class="dropdown-item"
                                >
                                  {item.name}
                                </p>
                              );
                            })}
                        </div>
                      </div>
                      {billingerror.country && (
                        <div className="warningmessage">
                          {billingerror.countrymsg}
                        </div>
                      )}
                    </div>
                    <div class="col-xl-6 col-lg-6">
                      <label for="city" class="label">
                        Postal town or city
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="city"
                        placeholder="New York"
                        value={town}
                        onChange={(e) => setTOwn(e.target.value)}
                      />
                      {billingerror.town && (
                        <div className="warningmessage">
                          {billingerror.townmsg}
                        </div>
                      )}
                    </div>
                  </div>
                  <div class="form-group row date-code-row">
                    <div class="col-xl-4 col-lg-4 col-12">
                      <label for="street" class="label">
                        Street address
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="street"
                        placeholder="89 Spring St"
                        value={street}
                        onChange={(e) => setStreen(e.target.value)}
                      />
                      {billingerror.street && (
                        <div className="warningmessage">
                          {billingerror.streetmsg}
                        </div>
                      )}
                    </div>
                    <div class="col-xl-4 col-lg-4 col-12">
                      <label for="province" class="label">
                        Province
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="province"
                        placeholder="Label"
                        value={province}
                        onChange={(e) => setProvince(e.target.value)}
                      />
                      {billingerror.province && (
                        <div className="warningmessage">
                          {billingerror.postalmsg}
                        </div>
                      )}
                    </div>
                    <div class="col-xl-4 col-lg-4 col-12">
                      <label for="postcode" class="label">
                        Postal code
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="postcode"
                        placeholder="10012"
                        value={postal}
                        onChange={(e) => setPostal(e.target.value)}
                      />
                      {billingerror.postal && (
                        <div className="warningmessage">
                          {billingerror.postalmsg}
                        </div>
                      )}
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-xl-6 col-lg-6">
                      <button
                        type="button"
                        onClick={editpaymentMethod}
                        class="btn btn-extra"
                      >
                        Edit payment method
                      </button>
                    </div>
                    <div class="col-xl-6 col-lg-6">
                      <button
                        type="button"
                        onClick={addbillingDetails}
                        class="btn btn-prime"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={3000} />
    </div>
  );
};

export default PaymentMethods;
