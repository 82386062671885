import React from 'react';
import { ShowPreviewData } from './showPreviewData';

const ListCreationPreviewDawn = (props) => {
  return (
    <>
      <ShowPreviewData 
        thmName = 'dawn'
        history = {props?.history}
      />
    </>
  );
};

export default ListCreationPreviewDawn;
