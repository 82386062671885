import React from 'react';
import { Link } from 'react-router-dom';
const DeskTopHeader = (props) => {
  return (
    <div class='signup-content-top signup-page-header'>
      <label class='ml-auto'>
        {props.displayContent}
        {props.linkdata === '#' ? (
          <a href={props.linkdata}>{props.navlink}</a>
        ) : (
          <Link to={props.linkdata}>{props.navlink}</Link>
        )}
      </label>
    </div>
  );
};

export default DeskTopHeader;
