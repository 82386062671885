import React from 'react';
import ArrowDown from '../../../assets/images/arrow_down.png';
import Close from '../../../assets/images/close2.png';
import Calender from '../../../assets/images/calendar.png';
import ArrowUp from '../../../assets/images/arrow_up.png';

function NeedCreationListFilters({ close }) {
  return (
    <div
      className='modal list-filters-modal'
      tabIndex='-1'
      role='dialog'
      style={{ display: 'block', maxHeight: '100%', overflowY: 'auto' }}
      data-backdrop='false'
    >
      <div className='modal-dialog' role='document'>
        <div className='modal-content'>
          <div className='modal-header'>
            <div className='mobile-header'>
              <nav className='navbar'>
                <label className='navbar-brand mx-auto middle-label'>
                  List filters
                </label>
                <a
                  href='#.'
                  className='cross'
                  data-dismiss='modal'
                  onClick={(e) => {
                    e.preventDefault();
                    close();
                  }}
                >
                  <img src={Close} alt='' />
                </a>
              </nav>
            </div>
          </div>
          <div className='modal-body'>
            <form>
              <div className='form-group'>
                <label>Status of the need</label>
                <div className='filter-panel'>
                  <label htmlFor='completed'>
                    <input
                      type='radio'
                      name='status'
                      id='completed'
                      value='completed'
                    />
                    <span className='mark'>Completed</span>
                  </label>
                  <label htmlFor='active'>
                    <input
                      type='radio'
                      name='status'
                      id='active'
                      value='active'
                    />
                    <span className='mark'>Active</span>
                  </label>
                  <label htmlFor='draft'>
                    <input
                      type='radio'
                      name='status'
                      id='draft'
                      value='draft'
                    />
                    <span className='mark'>Draft</span>
                  </label>
                </div>
              </div>

              <div className='form-group'>
                <label>Sort by priority</label>
                <div className='filter-panel panel-2'>
                  <label htmlFor='priorityLowToHigh'>
                    <input
                      type='radio'
                      name='priority'
                      id='priorityLowToHigh'
                      value='prioritylowtohigh'
                    />
                    <span className='mark'>
                      <span>Low to High</span>
                      <img src={ArrowUp} alt='' />
                    </span>
                  </label>
                  <label htmlFor='priorityHighToLow'>
                    <input
                      type='radio'
                      name='priority'
                      id='priorityHighToLow'
                      value='priorityhightolow'
                    />
                    <span className='mark'>
                      <span>High to Low</span>
                      <img src={ArrowDown} alt='' />
                    </span>
                  </label>
                </div>
              </div>

              <div className='form-group'>
                <label>Quantity of items remaining</label>
                <div className='filter-panel panel-2'>
                  <label htmlFor='quantityLowToHigh'>
                    <input
                      type='radio'
                      name='quantity'
                      id='quantityLowToHigh'
                      value='quantitylowtohigh'
                    />
                    <span className='mark'>
                      <span>Low to High</span>
                      <img src={ArrowUp} alt='' />
                    </span>
                  </label>
                  <label htmlFor='quantityHighToLow'>
                    <input
                      type='radio'
                      name='quantity'
                      id='quantityHighToLow'
                      value='quantityhightolow'
                    />
                    <span className='mark'>
                      <span>High to Low</span>
                      <img src={ArrowDown} alt='' />
                    </span>
                  </label>
                </div>
              </div>

              <div className='form-group'>
                <label>Complete by:</label>
                <div className='input-group completed_by'>
                  <div className='input-group-prepend'>
                    <img src={Calender} alt='' />
                  </div>
                  <input
                    type='date'
                    className='form-control'
                    name=''
                    placeholder='Wed 3 Dec, 3:00PM'
                  />
                </div>
              </div>

              <button type='submit' className='btn btn-primary'>
              Apply filter
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NeedCreationListFilters;
