import React, { useState, useEffect, useRef, useMemo } from "react";
import RightAngle from "../../../assets/images/right_angle_gray.png";
import PriorityMark from "../../../assets/images/priority_mark.png";
import Draft from "../../../assets/images/draft.png";
import Complete from "../../../assets/images/complete.png";
import kebab from "../../../assets/images/kebab.png";
import duplicate from "../../../assets/images/duplicate.png";
import Delete from "../../../assets/images/delete.png";
import { useDispatch, useSelector } from "react-redux";
/*@Components*/
import NeedsCreationNeedInfoPreview from "./NeedsCreationNeedInfoPreview";
import NeedCreationNeedInfoEditing from "./NeedCreationNeedInfoEditingFreeAccountRestrictions";
import LongPressable from "react-longpressable";
import { getNeedsList } from "../../../Store/Actions/userActions";
// import Buysvg from '../../../Common/SvgImage/Buysvg';
// import Givesvg from '../../../Common/SvgImage/Givesvg';
// import SvgforDo from '../../../Common/SvgImage/SvgforDo';
import Buysvg from "../../../assets/images/Buysvg.svg";
import Givesvg from "../../../assets/images/Givesvg.svg";
import SvgforDo from "../../../assets/images/SvgforDo.svg";
import AxiosApi from "../../../Config/axios-api";
import locationIcon from "../../../assets/images/location.svg";
import clockIcon from "../../../assets/images/clock.svg";
import moment from "moment";
import { intlFormat } from "date-fns";
import Cookies from "js-cookie";
import WriteNeedForAdd from "../WriteNewNeed";
import { stringCapitalize } from "../../../services/stringModify";

const NeedsCreationShortenListView = ({
  openDraft,
  params,
  onupdateList,
  needForAdd,
  createNeed,
  match,
  history,
  liveTitleAdd,
}) => {
  console.log("object history ", history);
  const dispatch = useDispatch();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const getuserNeedsList = useSelector((state) => state.getuserNeedsList);
  const { loading, userNeedlistInfo, error } = getuserNeedsList;
  const [selectedNeedData, setselectedNeedData] = useState("");
  let [activeNeed, setActiveNeed] = useState();
  let [needInfoEdit, setNeedInfoEdit] = useState(false);
  let [infoPreview, setInfoPreview] = useState(false);
  let [ProAcc, EditProAcc] = useState(false); // if acc is pro
  let [needsList, setNeedsList] = useState([]);
  let [selectedItem, setSelecteditem] = useState();
  let [needName, setNeedName] = useState("");
  let [needType, setNeedType] = useState("");
  let [liveTitle, setLiveTitle] = useState("");
  let [liveTitleId, setLiveTitleId] = useState("");

  // useMemo(() => {
  //   setNeedsList(userNeedlistInfo)
  // },[])

  let inputRef = useRef(null);

  useEffect(onMount, [needForAdd]);

  useEffect(() => {
    console.log("object----------- getNeedsListgData useEffect ");
    getNeedsListgData();
  }, [params, needForAdd, infoPreview, needInfoEdit]);

  const getNeedsListgData = () => {
    console.log("object----------- getNeedsList ");
    dispatch(getNeedsList(params));
  };
  console.log("object-----------", userNeedlistInfo);
  useEffect(() => {
    console.log("object----------- in useEffect");
    if (userNeedlistInfo) {
      console.log(
        "object----------- in useEffect userNeedlistInfo.length > needsList.length",
        userNeedlistInfo.length,
        needsList.length
      );
      if (userNeedlistInfo.length > needsList.length) {
        setNeedType("");
        setNeedName("");
        // console.log(" use effect 1", getuserNeedsList);
      }
      if (userNeedlistInfo.length != needsList.length) {
        console.log(
          "object----------- in useEffect userNeedlistInfo. not equal"
        );
        // console.log(" use effect 1", getuserNeedsList);

        setNeedsList(userNeedlistInfo);
      }
    }
  }, [userNeedlistInfo]);

  useEffect(() => {
    console.log(" use effect");

    if (needForAdd) {
      inputRef.current.focus();
    }
  }, [needForAdd, infoPreview, needInfoEdit]);

  function onMount() {
    setNeedType(needForAdd);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }

  function handleClickOutside() {
    setActiveNeed(0);
  }

  console.log("object shorten view data", needsList);
  function func(m, item) {
    console.log("object----------- in func");
    setNeedsList(needsList);
    //getNeedsListgData();
    switch (m) {
      case "openNeedEditInfo":
        console.log("object----------- case 1");
        return setNeedInfoEdit(true), setSelecteditem(item);
      case "openInfoPreview":
        console.log("object----------- case 2");
        return setInfoPreview(true), setSelecteditem(item);
      case "closeNeedEditInfo":
        console.log("object----------- case 3");
        return setNeedInfoEdit(false), setNeedsList(needsList);
      case "closeInfoPreview":
        console.log("object----------- case 4");
        return setInfoPreview(false), setNeedsList(needsList);
      default:
        return 0;
    }
    //getNeedsListgData();
  }

  function longPress(n) {
    if (n === activeNeed) setActiveNeed(0);
    else setActiveNeed(n);
  }

  const deleteNeeds = async (item) => {
    const { data } = await AxiosApi.delete(
      "/actionList/removeNeedById/" + item.needId,
      {}
    );
    if (data.msg === "need removed.") {
      console.log("object----------- getNeedsListg call 6 ");
      getNeedsListgData();
    }
  };

  const duplicateNeeds = async (item) => {
    const { data } = await AxiosApi.get(
      "/actionList/createDuplicateNeeds/" + item.needId,
      {}
    );
    if (data.msg === "duplicate needs created!") {
      console.log("object----------- getNeedsListg call 7 ");
      getNeedsListgData();
    }
  };

  const handleWriteNeed = (e) => {
    const { value } = e.target;
    setNeedName(value);
  };

  const submitNeed = () => {
    createNeed({
      title: needName,
      qtyNeeded: 0,
      needType: needType,
      needStatus: "draft",
    });
  };

  const checkNeedDeleteOrDuplicate = () => {
    getNeedsListgData();
    console.log("object----------- getNeedsListg call 8 ");
  };

  const handleLiveTitle = (data, id) => {
    setLiveTitle(data);
    setLiveTitleId(id);
  };

  return (
    <div>
      {needsList &&
        needsList.length > 0 &&
        needsList.map((item, index) => {
          return (
            <LongPressable
              onLongPress={(e) => longPress(1)}
              longPressTime={1200}
              key={`row-${index}`}
            >
              {console.log("object console", needsList)}
              <div
                className={
                  activeNeed === 1
                    ? "existing-need-row priority selected"
                    : "existing-need-row priority"
                }
                onClick={(e) => {
                  console.log("object----------- getNeedsListg call 8 ");

                  return (
                    func(
                      item.needStatus == "draft"
                        ? "openNeedEditInfo"
                        : "openInfoPreview",
                      item
                    ),
                    setselectedNeedData(item?.needId),
                    getNeedsListgData()
                  );
                }}
              >
                {/* <div class='column-kebab'>
                  <div class='dropdown'>
                    <button
                      type='button'
                      class='btn btn-primary dropdown-toggle'
                      data-toggle='dropdown'
                      onClick={(e) => e.stopPropagation()}
                    >
                      <img src={kebab} />
                    </button>
                    <div class='dropdown-menu'>
                      <a
                        class='dropdown-item'
                        onClick={(e) => (
                          e.stopPropagation(), duplicateNeeds(item)
                        )}
                      >
                        <img src={duplicate} alt='duplicate' />
                        <label>Duplicate need</label>
                      </a>
                      <a
                        class='dropdown-item delete'
                        onClick={(e) => (
                          e.stopPropagation(), deleteNeeds(item)
                        )}
                      >
                        <img src={Delete} alt='delete' />
                        <label>Delete need</label>
                      </a>
                    </div>
                  </div>
                </div> */}

                <div className="column column-first column-firstWidth">
                  <div className="need-title">
                    <div
                      className={
                        item.qtyOrdered === item.qtyNeeded &&
                        item?.needStatus === "draft"
                          ? "draft"
                          : "row1"
                      }
                    >
                      {item.needType === "buy" && (
                        <img src={Buysvg} className="icon" alt="" />
                      )}
                      {item.needType === "give" && (
                        <img src={Givesvg} className="icon" alt="" />
                      )}
                      {item.needType === "do" && (
                        <img src={SvgforDo} className="icon" alt="" />
                      )}
                      {console.log(liveTitleId + " == " + item.needId)}
                      {liveTitleId == item.needId
                        ? liveTitle
                        : stringCapitalize(item.title)}

                      {userInfo?.userType === "organization" &&
                        item.priority && (
                          <img
                            src={PriorityMark}
                            className="priority-badge"
                            alt=""
                          />
                        )}
                    </div>
                  </div>
                </div>

                {item.qtyNeeded > 0 && item.qtyOrdered === item.qtyNeeded ? (
                  <div className="column column-total-complete">
                    <img src={Complete} alt="" />
                  </div>
                ) : (
                  <>
                    {item.needStatus === "draft" ? (
                      <div className="column column-work-complete">
                        <label style={{ color: "rgb(114, 129, 155)" }}>
                          {stringCapitalize(item.needStatus)}
                        </label>
                      </div>
                    ) : (
                      <>
                        <div className="column column-work-complete">
                          <label>
                            {item.qtyOrdered} of{" "}
                            {item.qtyNeeded ? item.qtyNeeded : 0}
                          </label>
                        </div>
                      </>
                    )}
                  </>
                )}

                {/* {item.qtyNeeded > 0 && item.qtyOrdered === item.qtyNeeded ? (
                  <div className="column column-total-complete">
                    <img src={Complete} alt="" />
                  </div>
                ) : (
                  <div className="column column-work-complete">
                    <label>
                      {item.qtyOrdered} of {item.qtyNeeded}
                    </label>
                  </div>
                )} */}
                <div className="column column-last">
                  <button
                    type="button"
                    data-toggle="collapse"
                    data-target=".multi-collapse"
                    aria-expanded="false"
                    aria-controls="multiCollapseExample1"
                  >
                    <img src={RightAngle} alt="" />
                  </button>
                </div>
              </div>
            </LongPressable>
          );
        })}
      <div className="web_view">
        <WriteNeedForAdd
          needType={needType}
          needName={needName}
          handleChange={(val) => handleWriteNeed(val)}
          handleSubmit={() => submitNeed()}
          inputRef={inputRef}
          liveTitleAdd={liveTitleAdd}
        />
      </div>

      {/* {needName && <label>Details</label>}
        <WriteNeedForAdd 
          needType= {needType}
          needName= {needName}
          handleChange = {(val) => handleWriteNeed(val)}
          handleSubmit = {() => submitNeed()}
          inputRef = {inputRef} 
        />
        {/* <div className='existing-need-row priority need-type-svgbox'>
          {needType === 'buy' && <Buysvg />}
          {needType === 'give' && <Givesvg />}
          {needType === 'do' && <SvgforDo />}
          <input 
            className="write-needname"
            type="text" 
            value={needType ? needName : ""} 
            placeholder="Write a need name..." 
            onChange={ (e) => handleWriteNeed(e)}
            disabled={needType ? false : true}
            onKeyUp={(e) => e.keyCode === 13 && submitNeed()} 
            ref={inputRef}
          />
          {needName && <label>Details</label>}
          {needType && <div className='column column-last'>
            <button
              type='button'
              data-toggle='collapse'
              data-target='.multi-collapse'
              aria-expanded='false'
              aria-controls='multiCollapseExample1'
            >
              <img src={RightAngle} alt='' />
            </button>
          </div>} */}

      {infoPreview && (
        <NeedsCreationNeedInfoPreview
          func={func}
          selectedItem={selectedItem}
          selectedNeedData={selectedNeedData}
          checkNeedDeleteOrDuplicate={() => checkNeedDeleteOrDuplicate()}
        />
      )}
      {needInfoEdit && (
        <NeedCreationNeedInfoEditing
          func={func}
          selectedItem={selectedItem}
          onupdateList={onupdateList}
          duplicatemobile={(id) => duplicateNeeds(id)}
          deleteneedmobile={(id) => deleteNeeds(id)}
          checkNeedDeleteOrDuplicate={() => checkNeedDeleteOrDuplicate()}
          match={match}
          title={handleLiveTitle}
          history={history}
        />
      )}
    </div>
  );
};

export default NeedsCreationShortenListView;
