import React from 'react';
import Close from '../../../../assets/images/close2.png';
import Leaf from '../../../../assets/images/leaf.svg';
import Buy from '../../../../assets/images/buy.png';
import MinusActiveFair from '../../../../assets/images/minus_active_fair.png';
import PlusInactive from '../../../../assets/images/plus_inactive.png';
import Give from '../../../../assets/images/give.png';
import Location from '../../../../assets/images/location.svg';
import Clock from '../../../../assets/images/clock.svg';
import MinusInactive from '../../../../assets/images/minus_inactive.png';
import Do from '../../../../assets/images/do.png';
import PlusActiveFair from '../../../../assets/images/plus_active_fair.png';
import LogoFooter from '../../../../assets/images/Frame.svg';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Profileheadermobile from '../../../../Common/Profileheadermobile';
import {ShowPreviewData} from './showPreviewData';

const ListCreationPreviewFair = (props) => {
  return (
    <>
      <ShowPreviewData
        thmName = 'fair'
        history = {props?.history}
      />
    </>
  );
};

export default ListCreationPreviewFair;
