import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Back from '../../../assets/images/back.png';
import TimezonePicker from 'react-timezone';
import AxiosApi from '../../../Config/axios-api';
import { ToastContainer, toast} from 'react-toastify';
import '../../../../node_modules/react-toastify/dist/ReactToastify.min.css';
import { stringCapitalize } from '../../../services/stringModify';

export default function Preferencelist(props) {
  const dispatch = useDispatch();
  const [timezone, setTimeZone] = useState();
  const [timeFormat, setTimeFormat] = useState();
  const [dateFormat, setDateFormat] = useState();
  useEffect(() => {
    console.log("myProps object in pre",props.selectedaction)
    var value = props.selectedaction;
    setTimeZone(value.timeZone);
    setTimeFormat(value.timeFormat);
    setDateFormat(value.dateFormat);
  }, [props.selectedaction]);
  const updatelist = async () => {
    var obj = {};
    obj.dateFormat = dateFormat;
    obj.timeFormat = timeFormat;
    obj.timeZone = timezone;
    obj.actionListId = props.actionId;
    const { data } = await AxiosApi.post('/actionList/setPreferences', obj);
    console.log('data', data);
    if (data.success) {
      toast.success(stringCapitalize(data?.msg))
      props.listUpdated();
    }
  };
  return (
    <div
      class='tab-pane fade show active'
      id='preferences'
      role='tabpanel'
      aria-labelledby='preferences-tab'
    >
      <div class='mobile-header-inner'>
        <nav class='navbar'>
          <a onClick={props.backdata} class='back'>
            <img src={Back} />
          </a>
          <label class='navbar-brand mx-auto middle-label'>Preferences</label>
        </nav>
      </div>
      <h2 class='heading2'>Preferences</h2>
      <form class='preferences-form'>
        <div class='form-group row'>
          <div class='col-xl-6 col-lg-6'>
            <label for='listTitleInput' class='label'>
              Date format
            </label>
            <div class='dropdown purpose-dropdown date-formatnewdrop'>
              <button
                class='btn dropdown-toggle'
                type='button'
                id='dropdownMenuButton'
                data-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='false'
                style={{ color: !dateFormat && '#98a4b8' }}
              >
                {dateFormat ? dateFormat : 'Select Date Format...'}
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                  class='ml-auto'
                >
                  <path
                    d='M4 9L12 17L20 9'
                    stroke='#607BFF'
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              </button>
              <div class='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                <a
                  class='dropdown-item'
                  onClick={() => setDateFormat('DD/MM/YYYY')}
                >
                  DD/MM/YYYY
                </a>
                <a
                  class='dropdown-item'
                  onClick={() => setDateFormat('MM/DD/YYYY')}
                >
                  MM/DD/YYYY
                </a>
                <a
                  class='dropdown-item'
                  onClick={() => setDateFormat('YYYY-MM-DD')}
                >
                  YYYY-MM-DD
                </a>
              </div>
            </div>
          </div>
          <div class='col-xl-6 col-lg-6'>
            <label for='listTitleInput' class='label'>
              Time format
            </label>
            <div class='dropdown purpose-dropdown date-formatnewdrop'>
              <button
                class='btn dropdown-toggle'
                type='button'
                id='dropdownMenuButton'
                data-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='false'
                style={{ color: !timeFormat && '#98a4b8' }}
              >
                {timeFormat ? timeFormat + ' Hour' : 'Select Time Format...'}
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                  class='ml-auto'
                >
                  <path
                    d='M4 9L12 17L20 9'
                    stroke='#607BFF'
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              </button>
              <div class='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                <a class='dropdown-item' onClick={() => setTimeFormat('12')}>
                  12 Hour
                </a>
                <a class='dropdown-item' onClick={() => setTimeFormat('24')}>
                  24 Hour
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class='form-group row'>
          <div class='col-xl-12 col-lg-12'>
            <label for='listTitleInput' class='label'>
              Time Zone
            </label>
            <div
              class='dropdown purpose-dropdown custom_time_zone'
              style={{ padding: 0, display: 'flex' }}
            >
              <TimezonePicker
                value={timezone}
                style={{ display: 'block', width: '100%' }}
                onChange={(timezone) => setTimeZone(timezone)}
                inputProps={{
                  placeholder: 'Select Timezone...',
                  name: 'timezone',
                }}
              />
           
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                class='ml-auto'
                style={{ marginRight: '24px', marginTop: '18px' }}
              >
                <path
                  d='M4 9L12 17L20 9'
                  stroke='#607BFF'
                  stroke-width='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
              </svg>
            </div>
          </div>
        </div>
        <button
          type='button'
          className='btn btn-prime'
          style={{ width: '50%', marginBottom: 200 }}
          onClick={updatelist}
        >
          Save changes
        </button>
      </form>
      <ToastContainer autoClose={3000} />
    </div>
  );
}
