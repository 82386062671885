import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Back from '../../../assets/images/back.png';
import bank from '../../../assets/images/bank.png';
import checkmark_white from '../../../assets/images/checkmark_white.png';
export default function WithdrawType(props) {
  const [fundsto, setFunds] = useState();
  const [fundsaccount, setfundsaccount] = useState();
  return (
    <div
      class='tab-pane fade show active'
      id='withdraw-funds'
      role='tabpanel'
      aria-labelledby='withdraw-funds-tab'
    >
      <div class='mobile-header-inner'>
        <nav class='navbar'>
          <a onClick={props.backdata} class='back'>
            <img src={Back} />
          </a>
          <label class='navbar-brand mx-auto middle-label'>
            Withdraw funds
          </label>
        </nav>
      </div>
      <form class='withdrawal-form form-1' style={{ marginTop: 0 }}>
        <h2 class='heading2'>Select withdrawal type</h2>
        <div class='form-group row'>
          <div class='col-xl-12 col-lg-12'>
            <label for='listTitleInput' class='label'>
              Who will withdraw the funds?
            </label>
            <div class='dropdown purpose-dropdown'>
              <button
                class='btn dropdown-toggle'
                type='button'
                id='dropdownMenuButton'
                data-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='false'
              >
                <span class='selected'>{fundsto}</span>
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                  class='ml-auto'
                >
                  <path
                    d='M4 9L12 17L20 9'
                    stroke='#607BFF'
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              </button>
              <div class='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                <a
                  class='dropdown-item'
                  onClick={() => setFunds('Someone else')}
                >
                  Someone else
                </a>
                <a class='dropdown-item' onClick={() => setFunds('Me')}>
                  Me
                </a>
              </div>
            </div>
            <div class='info d-flex'>
              <span class='mr-auto'>Must be 18+ with valid ID</span>
            </div>
          </div>
        </div>

        <div class='form-group row'>
          <div class='col-xl-12 col-lg-12'>
            <label for='listTitleInput' class='label'>
              What type of account will funds be sent to?
            </label>
            <div class='dropdown purpose-dropdown'>
              <button
                class='btn dropdown-toggle'
                type='button'
                id='dropdownMenuButton'
                data-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='false'
              >
                <span class='selected'>{fundsaccount}</span>
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                  class='ml-auto'
                >
                  <path
                    d='M4 9L12 17L20 9'
                    stroke='#607BFF'
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              </button>
              <div class='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                <a
                  class='dropdown-item'
                  onClick={() => setfundsaccount('Company')}
                >
                  Company
                </a>
                <a
                  class='dropdown-item'
                  onClick={() => setfundsaccount('Private')}
                >
                  Private
                </a>
              </div>
            </div>

            <div class='account-type-instruction'>
              <img src={bank} alt='bank' />
              <p>
                Funds will be withdrawn to an organization or business bank
                account. You must be an account representative for that business
                to withdraw funds and you may be asked for the company’s tax ID.
              </p>
            </div>

            <div class='info d-flex'>
              <span class='mr-auto'>
                To read more about withdrawal types <a href='#'>click here</a>
              </span>
            </div>
          </div>
        </div>

        <button
          type='submit'
          onClick={() =>
            fundsaccount === 'Private' ? props.nextclick(5) : props.nextclick(3)
          }
          class='btn btn-prime'
        >
          Next
        </button>
        <a href='#' class='link-extra'>
          Do this later
        </a>
      </form>
    </div>
  );
}
