import React, { useState, useEffect } from 'react';
import Disclaimer from '../../../Layout/GuestUser/Disclaimer';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { sendOtp } from '../../../Store/Actions/userActions';
import ErrorSign from '../../../assets/images/error_sign.png';
import PhoneInput, {
  isValidPhoneNumber,
  formatPhoneNumberIntl,
  parsePhoneNumber,
} from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import MobileScreenLogo from '../../../Common/MobileScreenLogo';
import DeskTopHeader from '../../../Common/DeskTopHeader';
import { Link } from 'react-router-dom';
import { USER_REGISTER_FAIL } from '../../../Store/Constants/userConstants';
import logoWhiteDesktop from '../../../assets/images/logo_white_desktop.svg' 
import downAngle from '../../../assets/images/down_angle.png';
import { ToastContainer, toast } from 'react-toastify';
import '../../../../node_modules/react-toastify/dist/ReactToastify.min.css';


const StepFourNumber = () => {
  let history = useHistory();
  const dispatch = useDispatch();

  let [phoneNo, setPhoneNo] = useState(null);
  const userSendOtp = useSelector((state) => state.userSendOtp);
  const { loading, userSendOtpInfo } = userSendOtp;
  const { register, handleSubmit, errors } = useForm();
  const [formError, setFormError] = useState({ phone: '' });

  function er(e) {
    setFormError({ phone: e });
  }
  
  useEffect(() => {
    dispatch({ type: USER_REGISTER_FAIL, payload: [] });
  }, []);

  const onSubmit = (data) => {
    if (phoneNo === null || phoneNo === ' ' || phoneNo === undefined)
      return er('Enter Phone Number');
    else er('');
    if (phoneNo && !isValidPhoneNumber(phoneNo)) {
      const { country } = parsePhoneNumber(phoneNo);
      return er(`Invalid phone number for ${country} country code`);
    } else er('');
    let phoneNoIntFmt = formatPhoneNumberIntl(phoneNo);
    let num = phoneNoIntFmt.split(' ')[0];
    const phoneNumber = phoneNo
    data.phone = phoneNumber?.slice(num?.length, phoneNumber?.length);
    localStorage.setItem('StepFourCode', num.split('+')[1]);
    localStorage.setItem('StepFourNumber', data.phone);
    localStorage.setItem('StepFourSendOption', data.sendOption);
    dispatch(sendOtp(data.phone, data.sendOption, num.split('+')[1])).then((res)=> {
      if(res?.success === false){
        toast.error(res?.msg)
      }else{
        history.push('/signup-number-verify');
      }
    })
  };

  // useEffect(() => {
  //   if (userSendOtpInfo?.msg === 'otp send to your number!') {
  //     userSendOtpInfo?.msg = '';
  //     // history.push('/signup-number-verify');
  //   }
  // }, [userSendOtpInfo, history]);
  
  return (
    <div>
      <div class='mobile-header'>
        <MobileScreenLogo navlink='Log in'   linkdata="/discover" />
      </div>
      <div class='content signup_content'>
        <div className="logo-desktop ml-0 ml-xl-3 ml-lg-3 ml-md-2">
        <Link to="/discover">
          <img src={logoWhiteDesktop} />
          </Link>
        </div>
      </div>
      <div className="right-signupcontent">
        <div className="login-link">
            <DeskTopHeader
              linkdata='/login'
              displayContent='Already have an account?'
              navlink='Log in'
            />
          </div>
        
        {/* <div class='backward-arrow'>
          <Link to='/login'>
            <img src='images/back.png' />
            Back
          </Link>
        </div> */}
       
        <div class='row'>
          <div class='col-12'>
            <form
              onSubmit={handleSubmit(onSubmit)}
              class='signup_form custom_Form_View'
            >
               <div className="backward-arrow" onClick={()=> history.goBack()}>
                <img src='images/back.png' />
                  <p>back</p>
                </div>
                <h2 class='signup-head'>Verify your number</h2>
                <p class='para text-left'>
                  Your phone number helps keep your account safe. We will never share
                  your number with anyone else.
                </p>
              <div className='form-group'>
                <label className="mb-2">Add your phone number</label>
                <div className={`phone-row phone-verifydiv ${errors.phone && 'invalid'}`}>
                  <div className='col1 column'></div>
                  <div className='col2 column'></div>
                  <div className='col3 column'>
                    
                    <PhoneInput
                      value={phoneNo}
                      defaultCountry="US"
                      onChange={setPhoneNo}
                      placeholder='(000) 000 000 00'
                    />
                  </div>
                  <div className="drop-arrowdiv">
                  <img src={downAngle}></img>
                  </div>
                </div>

                {formError.phone !== '' && (
                  <div className='error-msg'>
                    <img src={ErrorSign} alt='Error' />
                    {formError.phone}
                  </div>
                )}
              </div>
              <div className='form-group sendOption-row'>
                <label>How should we send the unique code?</label>
                <div>
                  <div className='column'>
                    <label className='form-check-label' htmlFor='message'>
                      Text message
                      <input
                        type='radio'
                        name='sendOption'
                        ref={register({ required: 'Select your option' })}
                        checked={true}
                        onChange={(e) => {}}
                        id='message'
                        value='sms'
                      />
                      <span className='checkmark'></span>
                    </label>
                  </div>
                  <div className='column'>
                    <label className='form-check-label' htmlFor='call'>
                      Voice call
                      <input
                        type='radio'
                        name='sendOption'
                        ref={register({ required: 'Select your option' })}
                        id='call'
                        value='call'
                      />
                      <span className='checkmark'></span>
                    </label>
                  </div>
                </div>
              </div>
              <button type='submit' className='btn btn-primary mt-4'>
                Send a code
              </button>
              {/* <Disclaimer /> */}
            </form>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={3000}/>
    </div>
  );
};

export default StepFourNumber;
