import React, { useState, useEffect } from 'react';
import Disclaimer from '../../Layout/GuestUser/Disclaimer';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { forgotPassword } from '../../Store/Actions/userActions';
import MobileScreenLogo from '../../Common/MobileScreenLogo';
import DeskTopHeader from '../../Common/DeskTopHeader';
import { ToastContainer, toast } from 'react-toastify';
import logoWhiteDesktop from "../../assets/images/logo_white_desktop.svg";
import '../../../node_modules/react-toastify/dist/ReactToastify.min.css';

const ForgotPassword = () => {
  let history = useHistory();
  const dispatch = useDispatch();

  const userForgotPassword = useSelector((state) => state.userForgotPassword);
  const { loading, userForgotPasswordInfo, error } = userForgotPassword;
  const [passwordShown, setPasswordShown] = useState(false);
  const { register, handleSubmit, reset, errors } = useForm();
  const onSubmit = (data) => {
    localStorage.setItem('forgotPasswordEmail', data.email);
    dispatch(forgotPassword(data.email)).then((res)=>{
      if(res?.msg === "User not exists!"){
        toast.error(res?.msg)  
      }
    })
  };

  useEffect(() => {
    if (userForgotPasswordInfo?.msg.includes('A password reset')) {
      userForgotPasswordInfo.msg = '';
      history.push('/forgot-completed');
    }
    return () => {
      //
    };
  }, [userForgotPasswordInfo]);

  const togglePasswordVisiblity = () =>
    setPasswordShown(passwordShown ? false : true);
  return (
    <div>
      <MobileScreenLogo navlink='Sign up' linkdata='#' />
      <div class='content signup_content'>
        <div className="logo-desktop ml-0 ml-xl-3 ml-lg-3 ml-md-2">
          <Link to="/discover">
            <img src={logoWhiteDesktop} />
          </Link>
        </div>
      </div>
      <div className="right-signupcontent">
        <div className="login-link">
          <DeskTopHeader
            linkdata='#'
            displayContent='Don’t have an account?'
            navlink='Sign up'
          />
        </div>
       
        <div class='row'>
          <div class='col-12'>
            <form
              onSubmit={handleSubmit(onSubmit)}
              class='signup_form custom_Form_View'
            >
              <h2 class='heading2 signup-head'>Forgot your password?</h2>
              <div className='form-group'>
                <label htmlFor='email' className="pb-2">Your email address or phone</label>
                <input
                  type='input'
                  className={errors.email ? 'form-control invalid':'form-control'}
                  name='email'
                  id='email'
                  ref={register({
                    required: 'Please, enter your email',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: 'Enter a valid email address',
                    },
                  })}
                  placeholder=''
                />
                {/* {errors?.email?.type !== 'required' && (
                  <button
                    type='button'
                    onClick={() => reset({ email: '' })}
                    className='close_button'
                  ></button>
                )} */}
              </div>
              {errors.email && (
                <div className='error-box'>
                  {<p className='incorrect'>{errors.email.message}</p>}
                </div>
              )}
              <button type='submit' className='btn btn-primary'>
                Reset Password
              </button>
              <div className="back-tologin text-center p-4">
                {/* <a href="">Back to Log in </a> */}
                <Link to='/login'>Back to Log in</Link>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={3000} />
    </div>
  );
};

export default ForgotPassword;
