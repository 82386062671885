import React, { useState, useEffect } from "react";
import removePic from "../../assets/images/remove.png";
import minus_active from "../../assets/images/minus_active.png";
import plus_active from "../../assets/images/plus_active.png";
import location from "../../assets/images/location.svg";
import clock from "../../assets/images/clock.svg";
import Profileheadermobile from "../../Common/Profileheadermobile";
import { useHistory } from "react-router-dom";
import { ProcessedToPaymentRoute } from "../../Routing/RouteConstants";
import { getWishList } from "../../Store/Actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import BottomTab from "../../Common/BottomTab";
import Cookies from "js-cookie";
import UnloginuserHead from "../../Common/UnloginuserHead";
import Header from "./Header";

var moment = require("moment");

const EmbedCart = (props) => {
  const idArray = window.location.href.split("/");
  const id = idArray[idArray.length - 1];
  const [addLocation, setAddLocation] = useState({ drop: false, pick: false });
  const [address, setAddress] = useState("");
  let history = useHistory();
  const dispatch = useDispatch();
  const getuserWishList = useSelector(
    (state) => state.getuserWishList.userWishlistInfo
  );
  useEffect(() => {
    if (getuserWishList) {
      console.log("i am running");
      if (getuserWishList?.deliveryType === "Pick_up") {
        setAddLocation({ drop: false, pick: true });
      } else {
        setAddLocation({ drop: true, pick: false });
      }
    }
  }, []);

  const subTotal = getuserWishList?.reduce(
    (subTotal, item) => subTotal + item.cost * item.qty,
    0
  );

  const BuyQty = getuserWishList?.reduce(function (sum, item) {
    return item.needType === "buy" ? sum + item.qty : sum;
  }, 0);

  const DoQty = getuserWishList?.reduce(function (sum, item) {
    return item.needType === "do" ? sum + item.qty : sum;
  }, 0);

  const GiveQty = getuserWishList?.reduce(function (sum, item) {
    return item.needType === "give" ? sum + item.qty : sum;
  }, 0);

  const DonationSum = getuserWishList?.reduce(function (sum, item) {
    return item.needType === "Donation" ? sum + item.cost : sum;
  }, 0);

  var Buy = false;
  var Do = false;
  var Give = false;
  var Donate = false;

  getuserWishList?.map((item) => {
    if (item.needType === "buy") {
      return (Buy = true);
    } else if (item.needType === "do") {
      return (Do = true);
    } else if (item.needType === "give") {
      return (Give = true);
    } else if (item.needType === "Donation") {
      return (Donate = true);
    }
  });

  const removeItem = (index) => {
    var arrayData = [...getuserWishList];
    if (index !== -1) {
      arrayData.splice(index, 1);
      dispatch(getWishList(arrayData));
    }
  };
console.log("object",getuserWishList)
  const updateItemQty = (id, value) => {
    const index = getuserWishList.findIndex((item) => item.needId === id);
    let list = [...getuserWishList];
    if (value) {
      list[index].qty = list[index].qty + 1;
      dispatch(getWishList(list));
    } else {
      if (list[index].qty > 1) {
        list[index].qty = list[index].qty - 1;
        dispatch(getWishList(list));
      }
    }
  };
  const [mainData, setMainData] = useState([]);
  const [mainData1, setMainData1] = useState([]);
  let uniqueChars = [];
  useEffect(async () => {
    // const new actionList = []
    // htmlFor(  ) {

    // }

    setMainData(() => {
      const newList = [];
      const newActionList = getuserWishList?.map((item) => {
        // const wishDataWithGiveType = getuserWishList.map((item) => {
        //   let arr= item
        //   if(item.needType === "give"){
        //   if(addLocation.pick){
        //     arr.deliveryType='Pick_up'
        //   }
        //   if(addLocation.drop){
        //     arr.deliveryType='Dropp_off'
        //   }
        // }
        //  return arr
        // })
        let wishData = getuserWishList.filter(
          (data) => data.actionListId === item.actionListId
        );
        console.log(addLocation);

        const obj = {
          title: item.ActionListTitle,
          items: [...wishData],
          id: item.actionListId,
        };
        console.log("items", obj);
        const findOne = newList.find((x) => x.id === item.actionListId);
        if (!findOne) {
          newList.push(obj);
        }

        return item;
      });
      // console.log("newList", newList);

      return [...newList];
    });

    // const data = getuserWishList.map((item) => {
    //   let wishData = getuserWishList.filter(
    //     (data) => data.actionListId === item.actionListId
    //   );
    //   return { title: item.ActionListTitle, items: [...wishData] };
    // });

    // data.map((rec) => {
    //   data.map((item) => {
    //    if(rec.title === item.title){
    //     setMainData(..ma);
    //    }
    //   });
    // });
  }, [getuserWishList]);

  //console.log("mainData", mainData);

  function exist(value, array) {
    return array.some((e) => e === value);
  }

  const handleCheckout = () => {
    let isBuyGiveType;
    const typeOfNeed = getuserWishList?.map((item) => {
      if (item.needType === "buy") {
        return "Buy";
      } else if (item.needType === "do") {
        return "Do";
      } else if (item.needType === "give") {
        return "Give";
      } else if (item.needType === "Donation") {
        return "Donation";
      }
    });

    isBuyGiveType = exist("Buy", typeOfNeed) || exist("Donation", typeOfNeed);

    console.log("isBuyGiveType", isBuyGiveType);
    let arrItems = [...mainData[0].items];

    dispatch(getWishList(arrItems));
    history.push("/embed/processed-to-payment/" + id);
  };

  const handlePickup = async (id, val) => {
    const index = getuserWishList.findIndex((item) => item.needId === id);

    console.log(index);

    let arrItems = [...mainData[0].items];

    if (arrItems[index].needType === "give") {
      arrItems[index].deliveryType = "Pick_up";
      if (address) {
        arrItems[index].pickUpAddress = address;
      }
      await setMainData([
        {
          title: mainData.title,
          items: arrItems,
          id: id,
        },
      ]);
    }

    console.log("pick", mainData);

    //setAddLocation({drop:addLocation.pick,pick:!addLocation.pick})
  };

  const handleDropOff = async (id, val) => {
    const index = getuserWishList.findIndex((item) => item.needId === id);
    let arrItems = [...mainData[0].items];
    if (arrItems[index].needType === "give") {
      if (address) {
        delete arrItems[index].pickUpAddress;
        setAddress("");
      }
      arrItems[index].deliveryType = "Drop_off";

      await setMainData([
        {
          title: mainData.title,

          items: arrItems,
          id: id,
        },
      ]);
    }

    console.log("drop", mainData);

    // let arrayData = [...getuserWishList];
    // delete  arrayData[index].pickUpAddress
    // arrayData[index].deliveryType='Drop_off'
    //dispatch(getWishList(arrayData));

    //setAddLocation({pick:addLocation.drop,drop:!addLocation.drop})
  };

  return (
    <div>
      <Header />
      <div>
        {console.log(mainData)}
        <div className="content purchase desktop mb-md-2 mb-5">
          <div className="breadcrumb-row">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">
                  Review
                </li>
                <li className="breadcrumb-item">
                  <a href="#">Details &amp; Payment</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="#">Submit</a>
                </li>
              </ol>
            </nav>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-8 left">
                <div className="header d-md-flex d-none">
                  <h2 className="heading2">Cart</h2>
                  {Cookies.get("token") ? null : (
                    <a
                      onClick={() => {
                        props.history.push("/login");
                      }}
                      className="link-extra"
                    >
                      Have an account? Log in
                    </a>
                  )}
                </div>

                {getuserWishList === undefined ||getuserWishList?.length === 0 ? (
                  <label className="need-label mt-md-3 mt-3">
                    Cart is Empty
                  </label>
                ) : (
                  mainData &&
                  mainData.length > 0 &&
                  mainData.map((rec, index) => {
                    return (
                      <>
                        <label className="need-label mt-md-4 mt-3">
                          {rec?.title !== undefined
                            ? rec?.title
                            : "Actionlist title Donation"}
                        </label>
                        {rec.items.map((item, i) => {
                          return (
                            <>
                              {item.needType === "buy" && (
                                <div className="purchase-detail-box">
                                  <a className="remove">
                                    <img
                                      src={removePic}
                                      onClick={() => removeItem(i++)}
                                    />
                                  </a>
                                  <h3>
                                    <span>BUY:</span> {item.Name}
                                  </h3>
                                  <div className="row2">
                                    <div className="input-group">
                                      <div className="input-group-prepend">
                                        <button
                                          className="btn btn-outline-secondary"
                                          type="button"
                                          onClick={() => {
                                            updateItemQty(item.needId, false);
                                          }}
                                        >
                                          <img src={minus_active} />
                                        </button>
                                      </div>
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={item.qty}
                                      />
                                      <div className="input-group-append">
                                        <button
                                          className="btn btn-outline-secondary"
                                          type="button"
                                          onClick={() => {
                                            updateItemQty(item.needId, true);
                                          }}
                                        >
                                          <img src={plus_active} />
                                        </button>
                                      </div>
                                    </div>
                                    <label className="price">
                                      ${item.cost.toFixed(2)}
                                    </label>
                                  </div>
                                </div>
                              )}
                              {item.needType === "Donation" && (
                                <div className="purchase-detail-box">
                                  <a className="remove">
                                    <img
                                      src={removePic}
                                      onClick={() => removeItem(i++)}
                                    />
                                  </a>
                                  <h3>
                                    <span>DONATE:</span> {item.Name}
                                  </h3>
                                  <div className="input-group donation-price">
                                    <div className="input-group-prepend">
                                      <span
                                        className="input-group-text"
                                        id="basic-addon1"
                                      >
                                        USD
                                      </span>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder
                                        defaultValue={item.cost}
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                              {item.needType === "give" && (
                                <div className="purchase-detail-box">
                                  <a className="remove">
                                    <img
                                      src={removePic}
                                      onClick={() => removeItem(i++)}
                                    />
                                  </a>
                                  <h3>
                                    <span>GIVE:</span> {item.Name}
                                  </h3>
                                  <div className="location-row">
                                    <div className="left d-inline-block">
                                      <img src={location} />
                                    </div>
                                    <div className="right d-inline-block">
                                      <label>Downtown Toronto</label>
                                    </div>
                                  </div>
                                  <div className="drop-pick-row">
                                    <button
                                      type="button"
                                      className={
                                        item.deliveryType === "Drop_off"
                                          ? "active"
                                          : ""
                                      }
                                      onClick={() =>
                                        handleDropOff(item.needId, index)
                                      }
                                      //()=> setAddLocation({pick:addLocation.drop,drop:!addLocation.drop})}
                                    >
                                      Drop off
                                    </button>
                                    <button
                                      type="button"
                                      className={
                                        item.deliveryType === "Pick_up"
                                          ? "active"
                                          : ""
                                      }
                                      onClick={() =>
                                        handlePickup(item.needId, index)
                                      }
                                      //()=> setAddLocation({drop:addLocation.pick,pick:!addLocation.pick})}
                                    >
                                      Pick up
                                    </button>
                                  </div>
                                  {item.deliveryType === "Pick_up" && (
                                    <form>
                                      <div className="form-group" id={index}>
                                        <label htmlFor="wherefrom">
                                          Where it should be picked up from?
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          id={item.needId}
                                          placeholder="Write an address"
                                          onChange={(e) =>
                                            setAddress(e.target.value)
                                          }
                                        />
                                      </div>
                                    </form>
                                  )}
                                </div>
                              )}

                              {item.needType === "do" && (
                                <div className="purchase-detail-box">
                                  <a className="remove">
                                    <img
                                      src={removePic}
                                      onClick={() => removeItem(i++)}
                                    />
                                  </a>
                                  <h3>
                                    <span>DO:</span> {item.Name}
                                  </h3>
                                  <div className="row2">
                                    <div className="input-group">
                                      <div className="input-group-prepend">
                                        <button
                                          className="btn btn-outline-secondary"
                                          type="button"
                                          onClick={() => {
                                            updateItemQty(item.needId, false);
                                          }}
                                        >
                                          <img src={minus_active} />
                                        </button>
                                      </div>
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={item.qty}
                                      />
                                      <div className="input-group-append">
                                        <button
                                          className="btn btn-outline-secondary"
                                          type="button"
                                          onClick={() => {
                                            updateItemQty(item.needId, true);
                                          }}
                                        >
                                          <img src={plus_active} />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="location-row">
                                    <div className="left d-inline-block">
                                      <img src={clock} />
                                    </div>
                                    <div className="right d-inline-block">
                                      <label>
                                        Complete-by:{" "}
                                        {moment(item?.completedBy).format(
                                          "MMM DD YYYY, HH:SS a"
                                        )}
                                      </label>
                                    </div>
                                  </div>
                                  <div className="location-row">
                                    <div className="left d-inline-block">
                                      <img src={location} />
                                    </div>
                                    <div className="right d-inline-block">
                                      <label>{item.location}</label>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          );
                        })}
                      </>
                    );
                  })
                )}
              </div>
              <div className="col-xl-4 col-lg-4 right">
                <div className="header d-md-block d-none">
                  <div className="stepper type2">
                    <div className="step active">
                      <img src="images/checkmark_white.png" />
                      <span>1</span>
                    </div>
                    <div className="step-line" />
                    <div className="step">
                      <img src="images/checkmark_white.png" />
                      <span>2</span>
                    </div>
                    <div className="step-line" />
                    <div className="step">
                      <img src="images/checkmark_white.png" />
                      <span>3</span>
                    </div>
                  </div>
                  <div className="stepper-label type2">
                    <label className="active">Review</label>
                    <label>Details &amp; Payment</label>
                    <label>Submit</label>
                  </div>
                </div>
                <h2 className="heading2 d-md-block d-none text-left">
                  Summary
                </h2>
                {Buy ? (
                  <div className="buy-need-row">
                    <label className="text">Buy needs</label>
                    <label className="quantity">x {BuyQty}</label>
                  </div>
                ) : (
                  ""
                )}
                {Give ? (
                  <div className="buy-need-row">
                    <label className="text">Give needs</label>
                    <label className="quantity">x {GiveQty}</label>
                  </div>
                ) : (
                  ""
                )}
                {Do ? (
                  <div className="buy-need-row">
                    <label className="text">Do needs</label>
                    <label className="quantity">x {DoQty}</label>
                  </div>
                ) : (
                  ""
                )}
                {Donate ? (
                  <div className="buy-need-row">
                    <label className="text">General donation</label>
                    <label className="quantity">
                      ${DonationSum.toFixed(2)}
                    </label>
                  </div>
                ) : (
                  ""
                )}
                {Buy || Donate ? (
                  <div className="sub-total-row">
                    <label className="text">Subtotal:</label>
                    <label className="price">${subTotal.toFixed(2)}</label>
                  </div>
                ) : (
                  ""
                )}
                <div className="bottom-row">
                  <button
                    type="button"
                    className="btn btn-prime"
                    onClick={handleCheckout}
                    disabled={getuserWishList?.length ? false : true}
                  >
                    Proceed to Checkout
                  </button>
                  <div className="secure-link">
                    <svg
                      width={16}
                      height={16}
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8 0C5.93225 0 4.25 1.7944 4.25 4V6.4H3.5C3.10218 6.4 2.72064 6.56857 2.43934 6.86863C2.15804 7.16869 2 7.57565 2 8V14.4C2 14.8243 2.15804 15.2313 2.43934 15.5314C2.72064 15.8314 3.10218 16 3.5 16H12.5C12.8978 16 13.2794 15.8314 13.5607 15.5314C13.842 15.2313 14 14.8243 14 14.4V8C14 7.57565 13.842 7.16869 13.5607 6.86863C13.2794 6.56857 12.8978 6.4 12.5 6.4H11.75V4C11.75 1.7944 10.0677 0 8 0ZM5.75 4C5.75 2.6768 6.7595 1.6 8 1.6C9.2405 1.6 10.25 2.6768 10.25 4V6.4H5.75V4ZM8.75 12.5784V14.4H7.25V12.5784C6.98779 12.4182 6.77724 12.1771 6.64612 11.8867C6.515 11.5963 6.46946 11.2704 6.51551 10.9518C6.56156 10.6333 6.69704 10.3371 6.90407 10.1023C7.11111 9.86759 7.37999 9.70527 7.67525 9.6368C7.89456 9.58508 8.12196 9.58652 8.34068 9.64103C8.5594 9.69554 8.76386 9.80172 8.93899 9.95174C9.11411 10.1018 9.25543 10.2918 9.35253 10.5078C9.44963 10.7239 9.50002 10.9604 9.5 11.2C9.49957 11.4798 9.43002 11.7545 9.29835 11.9965C9.16668 12.2385 8.97754 12.4392 8.75 12.5784Z"
                        fill="#72819B"
                      />
                    </svg>
                    Secure checkout
                  </div>
                </div>
              </div>
            </div>
            <BottomTab activeScreen="dashboard" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmbedCart;
