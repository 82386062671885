import React from "react";
import { Link } from "react-router-dom";

const Disclaimer = (props) => {
  return (
    <div className="disclaimer">
      <p>
        By continuing, you agree to ActionList’s &nbsp;
        <Link to="/terms">Terms of Use</Link> and confirm that you
        have read ActionList’s &nbsp;
        <Link to="/privacy">Privacy Policy</Link>
      </p>
    </div>
  );
};

export default Disclaimer;
