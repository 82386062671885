import React from 'react';
import LogoHeaderInnerPage from '../assets/images/logo_header_innerpage.svg';
import { useDispatch } from 'react-redux';
import HeaderDropdown from "./headerDropdown";

export default function UserDesktopHeaderTwo(props) {
  return (
    <div className='desktop-header desktop-header-inner manage-header-inner'>
      <nav className='navbar navbar-expand-md'>
        <a
          className='navbar-brand'
          onClick={() => props.history.push('/discover')}
        >
          <img src={LogoHeaderInnerPage} alt='' />
        </a>
        <button
          className='navbar-toggler'
          type='button'
          data-toggle='collapse'
          data-target='#navbarSupportedContent'
          aria-controls='navbarSupportedContent'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon'></span>
        </button>
        <HeaderDropdown needsScreen={true}/>
      </nav>
    </div>
  );
}