import React, { useRef, useState } from "react";
import Disclaimer from "../../../Layout/GuestUser/Disclaimer";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import MobileScreenLogo from "../../../Common/MobileScreenLogo";
import DeskTopHeader from "../../../Common/DeskTopHeader";
import backBut from "../../../assets/images/back.png";
import logoWhiteDesktop from "../../../assets/images/logo_white_desktop.svg";
import closeBut from "../../../assets/images/close.png";
import visibleIcon from "../../../assets/images/view_enabled.png";
import hideIcon from "../../../assets/images/view_disabled.png";

const StepTwoPassword = () => {
  let history = useHistory();
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordResetShown, setPasswordResetShown] = useState(false);

  const { register, handleSubmit, watch, errors } = useForm({
    // by setting criteriaMode to 'all',
    // all validation errors for single field will display at once
    criteriaMode: "all",
  });
  const password = useRef({});
  password.current = watch("password", "");
  const onSubmit = (data) => {
    localStorage.setItem("StepTwoPassword", data.password);
    history.push("/signup-name");
  };

  const togglePasswordVisiblity = () =>
    setPasswordShown(passwordShown ? false : true);

  const togglePasswordResetVisiblity = () =>
    setPasswordResetShown(passwordResetShown ? false : true);

  let isVailidate = errors?.password?.types?.validate;

  let errorsLength = Object.keys(errors).length;
  let passwordLengthInvalid =
    errors?.password?.types?.required || errors?.password?.types?.minLength;
  let passwordUppercaseInValid =
    errors.password?.types?.validate?.includes("uppercaseInValid");
  let passwordLowercaseInValid =
    errors.password?.types?.validate?.includes("lowercaseInValid");
  let passwordNumberInValid =
    errors.password?.types?.validate?.includes("numberInValid");
  let passwordSpecialInValid =
    errors.password?.types?.validate?.includes("specialInValid");

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (isVailidate === "true" && errors?.password_repeat === undefined) {
      localStorage.setItem("StepTwoPassword", password.current);
      history.push("/signup-name");
    }
  };

  return (
    <div>
      <div class="mobile-header">
        <MobileScreenLogo
          navlink="Log in"
          linkdata="/discover"
        />
      </div>
      <div class="content signup_content">
        <div className="logo-desktop ml-0 ml-xl-3 ml-lg-3 ml-md-2">
          <Link to="/discover">
            <img src={logoWhiteDesktop} />
          </Link>
        </div>
      </div>
      <div className="right-signupcontent">
        <div className="login-link">
          <DeskTopHeader
            linkdata="/login"
            displayContent="Already have an account?"
            navlink="Log in"
          />
        </div>

        <div class="row">
          <div class="col-12">
            <form
              // onSubmit={handleSubmit(onSubmit)}
              onSubmit={(e) => handleFormSubmit(e)}
              class="signup_form custom_Form_View"
            >
              <div className="backward-arrow" onClick={() => history.goBack()}>
                <img src={backBut} />
                <p>back</p>
              </div>

              <h2 class="heading2 signup-head">Create a password</h2>
              <div className="form-group">
                <label htmlFor="createpassword" className="mb-2">
                  Create Password
                </label>
                <input
                  type={passwordShown ? "text" : "password"}
                  className={
                    isVailidate === undefined || isVailidate === "true"
                      ? "form-control"
                      : "form-control invalid"
                  }
                  onChange={handleSubmit(onSubmit)}
                  ref={register({
                    required: true,
                    minLength: 8,
                    validate: (value) => {
                      let errorsChar = "";
                      const uppercaseValid = /[A-Z]/;
                      const lowercaseValid = /[a-z]/;
                      const numberValid = /[0-9]/i;
                      const specialValid =
                        /[?=.*[!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]/i;

                      if (value.search(uppercaseValid) < 0)
                        errorsChar += " uppercaseInValid";
                      if (value.search(lowercaseValid) < 0)
                        errorsChar += " lowercaseInValid";
                      if (value.search(numberValid) < 0)
                        errorsChar += " numberInValid";
                      if (value.search(specialValid) < 0)
                        errorsChar += " specialInValid";
                      return errorsChar || "true";
                    },
                  })}
                  id="password"
                  name="password"
                  placeholder="Password"
                />
                <button
                  type="button"
                  style={
                    passwordShown
                      ? { backgroundImage: `url(${visibleIcon})` }
                      : { backgroundImage: `url(${hideIcon})` }
                  }
                  onClick={togglePasswordVisiblity}
                  className="view_button"
                ></button>
              </div>
              <div className={errorsLength === 0 ? "info-box" : "error-box"}>
                <label>Your password must have:</label>
                <ul>
                  <li
                    key="1"
                    className={passwordLengthInvalid ? "incorrect" : "correct"}
                  >
                    {!!errorsLength && (
                      <img
                        src={`images/${
                          passwordLengthInvalid ? "incorrect" : "correct"
                        }.png`}
                        alt="PasswordLength"
                      />
                    )}
                    At least 8 characters
                  </li>
                  <li
                    key="2"
                    className={
                      passwordUppercaseInValid ? "incorrect" : "correct"
                    }
                  >
                    {!!errorsLength && (
                      <img
                        src={`images/${
                          passwordUppercaseInValid ? "incorrect" : "correct"
                        }.png`}
                        alt="PasswordUpperCase"
                      />
                    )}
                    1 uppercase letter
                  </li>
                  <li
                    key="3"
                    className={
                      passwordLowercaseInValid ? "incorrect" : "correct"
                    }
                  >
                    {!!errorsLength && (
                      <img
                        src={`images/${
                          passwordLowercaseInValid ? "incorrect" : "correct"
                        }.png`}
                        alt="PasswordLowerCase"
                      />
                    )}
                    1 lowercase letter
                  </li>
                  <li
                    key="4"
                    className={passwordNumberInValid ? "incorrect" : "correct"}
                  >
                    {!!errorsLength && (
                      <img
                        src={`images/${
                          passwordNumberInValid ? "incorrect" : "correct"
                        }.png`}
                        alt="PasswordNumber"
                      />
                    )}
                    1 number
                  </li>
                  <li
                    key="5"
                    className={passwordSpecialInValid ? "incorrect" : "correct"}
                  >
                    {!!errorsLength && (
                      <img
                        src={`images/${
                          passwordSpecialInValid ? "incorrect" : "correct"
                        }.png`}
                        alt="PasswordSpecial"
                      />
                    )}
                    1 symbol
                  </li>
                </ul>
              </div>
              {/* <div className='form-group'>
                <label htmlFor='repeatpassword' className="mb-2">Repeat Password</label>
                <input
                  type={passwordResetShown ? 'text' : 'password'}
                  // className={errors?.password_repeat?.message  ? 'form-control invalid':'form-control'}
                  className={errors?.password_repeat?.message === 'The passwords do not match' ? 'form-control invalid':'form-control'}

                  id='repeatpassword'
                  name='password_repeat'
                  ref={register({
                    required: 'Enter your repeat password',
                    validate: (value) =>
                      value === password.current ||
                      'The passwords do not match',
                  })}
                  placeholder='Password'
                />
                <button
                  type='button'
                  style={passwordResetShown ? { backgroundImage:`url(${visibleIcon})`}:{ backgroundImage:`url(${hideIcon})`} }

                  onClick={togglePasswordResetVisiblity}
                  className='view_button'
                ></button>
              </div>
              {errors?.password_repeat?.message ===
                "The passwords do not match" && (
                <div className={"error-box"}>
                  <p className="incorrect">{errors.password_repeat.message}</p>
                </div>
              )} */}
              <button
                type="submit"
                className="btn btn-primary submit-btn mb-5"
                disabled={
                  !(
                    isVailidate === "true" &&
                    errors?.password_repeat === undefined
                  )
                }
              >
                Next
              </button>
              {/* <Disclaimer /> */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepTwoPassword;
