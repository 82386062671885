import React, { useState, useEffect } from "react";
import Duplicate from "../../../assets/images/duplicate.png";
import Back from "../../../assets/images/back.png";
import Delete from "../../../assets/images/delete.png";
import Kebab from "../../../assets/images/kebab.png";
import Close from "../../../assets/images/close2.png";
import Calender from "../../../assets/images/calendar.png";
import MinusBlue from "../../../assets/images/minus_blue_32x32.png";
import PlusBlue from "../../../assets/images/plus_blue_32x32.png";
import AxiosApi from "../../../Config/axios-api";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import PlacesAutocomplete from "react-places-autocomplete";
import { getSubscription } from "../ListSetting/BillingSetting/service";
import "../style.css";
import lockedImg from "../../../assets/images/locked.png";
import NeedsCreationEmptyList from "../NeedsCreation/NeedsCreationEmptyList";
import { ToastContainer, toast } from "react-toastify";
import { stringCapitalize } from "../../../services/stringModify";
import Swal from "sweetalert2";

function NeedCreationNeedInfoEditingFreeAccountRestrictions(props) {
  console.log(" editing props", props);
  let [title, setTitle] = useState();
  let [quantity, setQuantity] = useState();
  let [needType, setNeed_Type] = useState();
  let [description, setdescription] = useState("");
  let [location, setlocation] = useState();
  let [instructions, setinstructions] = useState();
  let [thankYouMsg, setthankYouMsg] = useState();
  let [completedBy, setcompletedBy] = useState();
  let [priority, setpriority] = useState();
  let [autoAppoved, setautoAppoved] = useState();
  let [deliveryType, setdeliveryType] = useState();
  let [picupDistance, setpicupDistance] = useState();
  let [cost, setcost] = useState();
  let [subscriptionApiRes, setSubscriptionApiRes] = useState();
  const userSignin = useSelector((state) => state.userSignin);
  const { loading: userloading, userInfo, error: usererror } = userSignin;

  useEffect(() => {
    setTitle(props.selectedItem.title);
    setQuantity(
      props.selectedItem.qtyNeeded ? props.selectedItem.qtyNeeded : 1
    );
    setNeed_Type(props.selectedItem.needType);
    props.selectedItem.description
      ? setdescription(props.selectedItem.description)
      : setdescription("");
    setlocation(props.selectedItem.location);
    setinstructions(props.selectedItem.instructions);
    setthankYouMsg(props.selectedItem.thankYouMsg);
    props.selectedItem.completedBy &&
      setcompletedBy(props.selectedItem.completedBy.substring(0, 10));
    setautoAppoved(props.selectedItem.autoAppoved);
    setdeliveryType(props.selectedItem.deliveryType);
    setpicupDistance(props.selectedItem.picupDistance);
    setpriority(props.selectedItem.priority);
    setcost(props.selectedItem.cost);
  }, [props.selectedItem]);

  useEffect(() => {
    getSubscription(props?.selectedItem?.actionListId).then((res) => {
      if (res) {
        setSubscriptionApiRes(res);
        console.log("apiRes-----", res);
      }
    });
  }, []);

  function add(e) {
    e.preventDefault();
    let qty = quantity;
    if (qty === undefined) {
      qty = 1;
    } else {
      qty++;
    }

    setQuantity(qty);
  }

  function minus(e) {
    e.preventDefault();
    let qty = quantity;
    if (qty !== 1) qty--;

    setQuantity(qty);
  }

  function changeQuantity(e) {
    setQuantity(e.target.value);
  }
  const handleChange = (place) => {
    setlocation(place);
  };

  const handleSelect = (place) => {
    setlocation(place);
  };

  async function createNeed(e) {
    e.preventDefault();
    let need = {
      title,
      needType,
      completedBy,
      cost,
      description,
      location,
      deliveryType,
      picupDistance,
      qtyNeeded: quantity,
      priority,
      instructions,
      thankYouMsg,
      autoAppoved,
      needStatus: props?.selectedItem?.needStatus,
    };
    need = {
      ...need,
      actionListId: props.selectedItem.actionListId,
      needId: props.selectedItem.needId,
    };
    try {
      const { data } = await AxiosApi.post(
        "/actionList/updateNeedsData",
        need,
        {
          headers: {
            token: Cookies.get("token"),
          },
        }
      );
      if (data) {
        toast.success(stringCapitalize(data.msg));
      }
      props.onupdateList();
      props.func("closeInfoPreview");
      props.func("closeNeedEditInfo");
    } catch (err) {
      console.log(err);
    }
  }

  const renderAccordToSubscriptionStatus = () => {
    return (
      <>
        <div class="form-group">
          <label>
            Mark as a Priority <span class="info_tag">?</span>
          </label>
          <div class="priority-check">
            <label class="switch">
              <input
                type="checkbox"
                checked={priority}
                onChange={(e) =>
                  priority === true ? setpriority(false) : setpriority(true)
                }
              />
              <span class="slider round"></span>
            </label>
          </div>
        </div>

        {needType != "buy" && (
          <div class="form-group">
            <label>Add private instructions (optional)</label>
            <input
              type="text"
              class="form-control"
              placeholder="e.g. Please call me before you come"
              value={instructions}
              onChange={(e) => setinstructions(e.target.value)}
            />
            <div class="info">
              <span>Message sent after volunteer is approved</span>
            </div>
          </div>
        )}

        <div class="form-group">
          <label>Add “Thank you” message (optional)</label>
          <input
            type="text"
            class="form-control"
            placeholder="Message"
            value={thankYouMsg}
            onChange={(e) => setthankYouMsg(e.target.value)}
          />
          <div class="info">
            <span>Message sent after action is completed and verified</span>
          </div>
        </div>
      </>
    );
  };

  const duplicateNeeds = async (item) => {
    const { data } = await AxiosApi.get(
      "/actionList/createDuplicateNeeds/" + item.needId,
      {}
    );
    if (data.msg === "duplicate needs created!") {
      toast.success(stringCapitalize(data?.msg));
      props.checkNeedDeleteOrDuplicate();
      props.func("closeInfoPreview", props.selectedItem);
    } else {
      toast.error("Something went worng!");
    }
  };

  const deleteNeeds = async (item) => {
    if (props?.selectedItem?.qtyOrdered === 0) {
      Swal.fire({
        title: "Are you sure to want delete need!",
        showCancelButton: true,
        confirmButtonColor: "#e8271a",
        confirmButtonText: `Delete`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const { data } = await AxiosApi.delete(
              "/actionList/removeNeedById/" + item.needId,
              {}
            );
            if (data.msg === "need removed.") {
              Swal.fire("Deleted!", stringCapitalize(data?.msg), "success");
              props.checkNeedDeleteOrDuplicate();
              props.func("closeInfoPreview", props.selectedItem);
            } else {
              toast.error("Something went worng!");
            }
          } catch (error) {
            toast.error(error?.msg);
          }
        }
      });
    } else {
      window.$("#deleteNeedMsg").modal("show");
    }

    // const { data } = await AxiosApi.delete(
    //   '/actionList/removeNeedById/' + item.needId,
    //   {}
    // );
    // if (data.msg === 'need removed.') {
    //   toast.success(stringCapitalize(data?.msg))
    //   props.checkNeedDeleteOrDuplicate()
    //   props.func('closeInfoPreview', props.selectedItem)

    // }else{
    //   toast.error("Something went worng!")
    // }
  };
  const [isDelete, setIsDelete] = useState(false);
  const deleteData = async (e, id) => {
    e.preventDefault();
    setIsDelete(true);
    Swal.fire({
      title: "Are you sure to want delete NeedList!",
      showCancelButton: true,
      confirmButtonColor: "#e8271a",
      confirmButtonText: `Delete`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { data } = await AxiosApi.delete(
          `/actionList/deleteNeed/${props?.selectedItem?.needId}`
        );
        if (data?.msg) {
          Swal.fire("Deleted!", data?.msg, "success");
          window.location.reload();
        } else {
          toast.error("Something went worng!");
        }
      }
    });
  };
  return (
    <>
      <div
        class="modal need-edit-modal need-edit-modalNew"
        tabindex="-1"
        role="dialog"
        style={{ display: "block", maxHeight: "100%", overflowY: "auto" }}
        data-backdrop="false"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <div class="mobile-header">
                <nav class="navbar">
                  <div>
                    <a
                      // href='#.'
                      class="back_link cursor-pointer need-editbacklink"
                      onClick={(e) => {
                        if (props?.type && props?.type == "manage") {
                          e.preventDefault();
                          props.func(false);
                        } else {
                          e.preventDefault();
                          props.func("openInfoPreview", props?.selectedItem);
                          props.func("closeNeedEditInfo");
                        }
                      }}
                    >
                      <img src={Back} alt="" className="mr-2" />
                      Back
                    </a>
                  </div>
                  <div class="kebab-menu">
                    <div class="dropdown">
                      <button
                        type="button"
                        class="btn btn-primary dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        <img src={Kebab} alt="" />
                      </button>
                      <div class="dropdown-menu">
                        <a
                          class="dropdown-item"
                          onClick={() => duplicateNeeds(props.selectedItem)}
                        >
                          <img src={Duplicate} alt="duplicate" />
                          <label>Duplicate need</label>
                        </a>
                        <a
                          class="dropdown-item delete"
                          onClick={() => deleteNeeds(props.selectedItem)}
                        >
                          <img src={Delete} alt="delete" />
                          <label>Delete need</label>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div>
                    <label class="navbar-brand mx-auto middle-label">
                      Edit
                    </label>

                    <a
                      // href='#.'
                      class="cross"
                      data-dismiss="modal"
                      onClick={(e) => {
                        if (props?.type && props?.type == "manage") {
                          e.preventDefault();
                          props.func(false);
                        } else {
                          e.preventDefault();
                          props.func("closeInfoPreview");
                          props.func("closeNeedEditInfo");
                        }
                      }}
                    >
                      <img src={Close} alt="" />
                    </a>
                  </div>
                </nav>
              </div>
            </div>
            {props?.selectedItem?.qtyOrdered > 0 && (
              <div
                className="modal fade delete-list-modal"
                id="deleteNeedMsg"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-body">
                      <button
                        type="button"
                        className="btn-close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => window.$("#deleteNeedMsg").modal("hide")}
                      >
                        <img src={Close} />
                      </button>
                      <h4>
                        You can't delete your ongoing need. Please cancel it if
                        you don't wish to continue further.
                      </h4>
                      <button
                        onClick={() => window.$("#deleteNeedMsg").modal("hide")}
                        type="button"
                      >
                        <label>Ok</label>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {props?.selectedItem?.needStatus === "draft" && (
              <div>
                <NeedsCreationEmptyList
                  match={props?.match}
                  history={props?.history}
                  selectedNeedItem={props?.selectedItem}
                  func={(val) => props.func(val)}
                  selcetedNeedType={props?.selectedItem?.needType}
                  draftWindowOpen={true}
                />
              </div>
            )}
            {(props?.selectedItem?.needStatus === "active" ||
              props?.selectedItem?.needStatus === "done") && (
              <div class="modal-body">
                <form>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      name="need_title"
                      placeholder="Title of the need"
                      value={title}
                      onChange={(e) => {
                        if (props?.type && props?.type == "manage") {
                          e.preventDefault();
                          setTitle(e.target.value);
                        } else {
                          e.preventDefault();
                          setTitle(e.target.value);
                          props.title(
                            e.target.value,
                            props?.selectedItem.needId
                          );
                        }
                      }}
                    />
                  </div>

                  <div class="form-group need-type-row">
                    <label>Select type of the need</label>
                    <div class="type-options">
                      <label for="buy_type">
                        <input
                          type="radio"
                          name="type"
                          id="buy_type"
                          value="buy"
                          defaultChecked={
                            props?.selectedItem?.needType === "buy"
                          }
                          onClick={() => setNeed_Type("buy")}
                        />
                        <span class="mark">
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M10.5 23C8.567 23 7 24.567 7 26.5C7 28.433 8.567 30 10.5 30C12.433 30 14 28.433 14 26.5C14 24.567 12.433 23 10.5 23ZM5 26.5C5 23.4624 7.46244 21 10.5 21C13.5376 21 16 23.4624 16 26.5C16 29.5376 13.5376 32 10.5 32C7.46243 32 5 29.5376 5 26.5Z"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M21.5 23C19.567 23 18 24.567 18 26.5C18 28.433 19.567 30 21.5 30C23.433 30 25 28.433 25 26.5C25 24.567 23.433 23 21.5 23ZM16 26.5C16 23.4624 18.4624 21 21.5 21C24.5376 21 27 23.4624 27 26.5C27 29.5376 24.5376 32 21.5 32C18.4624 32 16 29.5376 16 26.5Z"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M1.20992 6.38701C1.39934 6.14286 1.691 6 2.00001 6H10.6784C11.0838 6 11.4491 6.24477 11.6032 6.61973C11.7574 6.99469 11.6699 7.42562 11.3818 7.71079L7.26351 11.7863L16 20.5811L24.7365 11.7863L20.6182 7.71079C20.3301 7.42562 20.2426 6.99469 20.3968 6.61973C20.5509 6.24477 20.9162 6 21.3216 6H30C30.309 6 30.6007 6.14286 30.7901 6.38701C30.9795 6.63116 31.0454 6.94916 30.9686 7.24848L27.1207 22.2485C27.0072 22.6907 26.6086 23 26.1521 23H5.84796C5.39138 23 4.99278 22.6907 4.87933 22.2485L1.03137 7.24848C0.954588 6.94916 1.0205 6.63116 1.20992 6.38701ZM18.4029 21H25.3762L28.7111 8H23.7538L26.8555 11.0694C27.0442 11.2562 27.1509 11.5104 27.1521 11.7759C27.1532 12.0415 27.0487 12.2966 26.8615 12.485L18.4029 21ZM13.5971 21H6.62381L3.28892 8H8.24622L5.14456 11.0694C4.95581 11.2562 4.84911 11.5104 4.84797 11.7759C4.84684 12.0415 4.95137 12.2966 5.13851 12.485L13.5971 21Z"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M16 0C16.2968 0 16.5782 0.131823 16.7682 0.359816L21.7682 6.35982C22.0166 6.6579 22.0702 7.07277 21.9056 7.42416C21.741 7.77554 21.388 8 21 8H11C10.612 8 10.259 7.77554 10.0944 7.42416C9.92984 7.07277 9.98339 6.6579 10.2318 6.35982L15.2318 0.359816C15.4218 0.131823 15.7032 0 16 0ZM13.1351 6H18.865L16 2.56205L13.1351 6Z"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M10.0493 6.29454C10.237 6.106 10.492 6 10.7581 6H21.2419C21.508 6 21.763 6.106 21.9507 6.29454L26.7087 11.0748C27.0947 11.4625 27.0974 12.0885 26.7148 12.4796L16.7147 22.6994C16.5266 22.8916 16.269 23 16 23C15.731 23 15.4734 22.8916 15.2852 22.6994L5.28525 12.4796C4.90259 12.0885 4.90527 11.4625 5.29125 11.0748L10.0493 6.29454ZM11.1736 8L7.40496 11.7862L16 20.5702L24.595 11.7862L20.8264 8H11.1736Z"
                            />
                          </svg>
                          Buy
                        </span>
                      </label>

                      <label for="give_type">
                        <input
                          type="radio"
                          name="type"
                          id="give_type"
                          value="give"
                          defaultChecked={
                            props?.selectedItem?.needType === "give"
                          }
                          onClick={() => setNeed_Type("give")}
                        />
                        <span class="mark">
                          <svg
                            width="33"
                            height="32"
                            viewBox="0 0 33 32"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M1.26343 12C1.26343 11.4477 1.71114 11 2.26343 11H11.2634C11.8157 11 12.2634 11.4477 12.2634 12V19H20.16C20.7123 19 21.16 19.4477 21.16 20V30C21.16 30.5523 20.7123 31 20.16 31H2.26343C1.71114 31 1.26343 30.5523 1.26343 30V12ZM3.26343 13V29H19.16V21H11.2634C10.7111 21 10.2634 20.5523 10.2634 20V13H3.26343Z"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M12.5403 1.07516C12.9153 1.22933 13.1601 1.59462 13.1601 2.00003V12C13.1601 12.5523 12.7123 13 12.1601 13H2.26346C1.85994 13 1.49596 12.7575 1.34057 12.3851C1.18519 12.0127 1.26884 11.5834 1.55268 11.2966L11.4493 1.29661C11.7345 1.00845 12.1654 0.92099 12.5403 1.07516ZM4.66004 11H11.1601V4.4321L4.66004 11Z"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M11.16 2C11.16 1.44772 11.6077 1 12.16 1H30.16C30.5645 1 30.9291 1.24364 31.0839 1.61732C31.2387 1.99099 31.1531 2.42111 30.8671 2.70711L20.8671 12.7071C20.6796 12.8946 20.4253 13 20.16 13H12.16C11.6077 13 11.16 12.5523 11.16 12V2ZM13.16 3V11H19.7458L27.7458 3H13.16Z"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M20.16 19H30.16C30.5645 19 30.9291 19.2436 31.0839 19.6173C31.2387 19.991 31.1531 20.4211 30.8671 20.7071L20.8671 30.7071C20.5811 30.9931 20.151 31.0787 19.7774 30.9239C19.4037 30.7691 19.16 30.4045 19.16 30V20C19.16 19.4477 19.6077 19 20.16 19ZM21.16 21V27.5858L27.7458 21H21.16Z"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M30.5427 1.07615C30.9164 1.23093 31.16 1.59557 31.16 2.00003V20C31.16 20.5523 30.7123 21 30.16 21H20.16C19.6077 21 19.16 20.5523 19.16 20V12C19.16 11.7348 19.2654 11.4805 19.4529 11.2929L29.4529 1.29292C29.7389 1.00692 30.169 0.921369 30.5427 1.07615ZM21.16 12.4142V19H29.16V4.41424L21.16 12.4142Z"
                            />
                          </svg>
                          Give
                        </span>
                      </label>

                      <label for="do_type">
                        <input
                          type="radio"
                          name="type"
                          id="do_type"
                          value="do"
                          defaultChecked={
                            props?.selectedItem?.needType === "do"
                          }
                          onClick={() => setNeed_Type("do")}
                        />
                        <span class="mark">
                          <svg
                            width="33"
                            height="32"
                            viewBox="0 0 33 32"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M3.53178 4.71647C7.15375 1.09451 13.0271 1.09451 16.6491 4.71647L17.5224 5.58984C17.9129 5.98036 17.9129 6.61353 17.5224 7.00405L5.81936 18.7071C5.42883 19.0976 4.79567 19.0976 4.40514 18.7071L3.53178 17.8337C-0.0901837 14.2118 -0.0901837 8.33844 3.53178 4.71647ZM15.2348 6.13069C12.3939 3.28977 7.78691 3.28977 4.946 6.13069C2.10508 8.9716 2.10508 13.5786 4.946 16.4195L5.11225 16.5858L15.4011 6.29694L15.2348 6.13069Z"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M16.9816 4.71647C20.6035 1.09451 26.4769 1.09451 30.0988 4.71647C33.7208 8.33844 33.7208 14.2118 30.0988 17.8337L29.2255 18.7071C29.0379 18.8946 28.7836 19 28.5184 19C28.2531 19 27.9988 18.8946 27.8113 18.7071L16.1082 7.00405C15.7177 6.61353 15.7177 5.98036 16.1082 5.58984L16.9816 4.71647ZM28.6846 6.13069C25.8437 3.28977 21.2367 3.28977 18.3958 6.13069L18.2295 6.29694L28.5184 16.5858L28.6846 16.4195C31.5255 13.5786 31.5255 8.9716 28.6846 6.13069Z"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M16.7322 5.06019C17.0004 5.05785 17.2583 5.16337 17.448 5.35304L29.3094 17.2144C29.6978 17.6028 29.7002 18.2317 29.3149 18.6231L17.3734 30.7527C17.1838 30.9453 16.9243 31.053 16.654 31.0511C16.3838 31.0493 16.1257 30.9381 15.9387 30.743L4.43992 18.7419C4.06828 18.3541 4.06948 17.7418 4.44265 17.3554L16.0216 5.36548C16.2079 5.17253 16.464 5.06253 16.7322 5.06019ZM6.54951 18.0528L16.6705 28.6159L27.1936 17.927L16.7533 7.48678L6.54951 18.0528Z"
                            />
                          </svg>
                          Do
                        </span>
                      </label>
                    </div>
                  </div>
                  <div class="form-group">
                    <label> How many items do you need?</label>
                    <div class="input-group items-input">
                      <div class="input-group-prepend">
                        <button
                          class="btn btn-outline-secondary"
                          type="button"
                          onClick={(e) => minus(e)}
                        >
                          <img src={MinusBlue} alt="" />
                        </button>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        aria-label=""
                        aria-describedby="basic-addon1"
                        value={quantity}
                        onChange={(e) => {
                          changeQuantity(e);
                        }}
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary "
                          type="button"
                          style={{ padding: 0 }}
                          onClick={(e) => add(e)}
                        >
                          <img src={PlusBlue} alt="" />
                        </button>
                      </div>
                    </div>
                  </div>
                  {needType === "buy" && (
                    <div className="form-group">
                      {/* <label>How much it costs?</label> */}
                      <label>What is the price?</label>
                      <div className="input-group howmuch_cost">
                        <div className="input-group-prepend">
                          {/* <label>CAD</label> */}
                          <label>USD</label>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          name=""
                          value={cost}
                          placeholder="$0.00"
                          onChange={(e) => setcost(e.target.value)}
                        />
                      </div>
                    </div>
                  )}
                  {needType != "buy" && (
                    <div class="form-group">
                      <label>Complete by:</label>
                      <div class="input-group completed_by">
                        <div class="input-group-prepend">
                          <img src={Calender} alt="" />
                        </div>
                        <input
                          type="date"
                          class="form-control"
                          name=""
                          placeholder="Wed 3 Dec, 3:00PM"
                          value={completedBy}
                          onChange={(e) => setcompletedBy(e.target.value)}
                        />
                      </div>
                    </div>
                  )}

                  <div class="form-group">
                    <label for="title">Description (optional)</label>
                    <div class="story_description_wrapper">
                      <textarea
                        rows="5"
                        class="form-control"
                        id="title"
                        placeholder=""
                        maxlength="200"
                        value={description}
                        onChange={(e) => setdescription(e.target.value)}
                      ></textarea>
                      <button type="butotn" class="expand_button"></button>
                    </div>
                    <div class="info d-flex">
                      <span class="mr-auto">
                        You can edit the description later.
                      </span>
                      <span class="limit">{description?.length}/200</span>
                    </div>
                  </div>
                  {needType === "give" && (
                    <div class="form-group sendOption-row">
                      <label>
                        How do you prefer the item(s) to be delivered?
                      </label>
                      <div>
                        <div class="column">
                          <label class="form-check-label" for="dropoff">
                            Drop off
                            <input
                              type="radio"
                              name="pickupDropoff"
                              id="dropoff"
                              // value='dropoff'
                              checked={
                                deliveryType === "Drop_off" ? true : false
                              }
                              onChange={() => setdeliveryType("Drop_off")}
                            />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                        <div class="column">
                          <label class="form-check-label" for="pickup">
                            Pick up
                            <input
                              type="radio"
                              name="pickupDropoff"
                              id="pickup"
                              // value='pickup'
                              checked={
                                deliveryType === "Pick_up" ? true : false
                              }
                              onChange={() => setdeliveryType("Pick_up")}
                            />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  )}

                  {needType === "give" && (
                    <div className="form-group">
                      <label
                        className={`${
                          deliveryType != "Drop_off" && "disabled"
                        }`}
                      >
                        Add place where it happens:
                      </label>
                      <div
                        className="input-group completed_by"
                        style={{ height: "auto", minHeight: 56 }}
                      >
                        <div className="input-group-prepend">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                            className="location disabled"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M10 20C11.3333 20 18 15.7142 18 8.57139C18 3.83854 14.4173 0 10 0C5.58267 0 2 3.83854 2 8.57139C2 15.7142 8.66667 20 10 20ZM10 12C12.2091 12 14 10.2091 14 8C14 5.79086 12.2091 4 10 4C7.79086 4 6 5.79086 6 8C6 10.2091 7.79086 12 10 12Z"
                            />
                          </svg>
                        </div>
                        <PlacesAutocomplete
                          value={location}
                          onChange={handleChange}
                          onSelect={handleSelect}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <div style={{ width: "87%" }}>
                              <input
                                {...getInputProps({
                                  placeholder:
                                    "e.x.: Red st., 65/316, Texas, USA",
                                  className: "form-control",
                                  id: "place",
                                  name: "place",
                                })}
                              />
                              {suggestions?.length > 0 && (
                                <div className="autocomplete-dropdown-container">
                                  {loading && <div>Loading...</div>}
                                  {suggestions.map((suggestion) => {
                                    const className = suggestion.active
                                      ? "suggestion-item--active"
                                      : "suggestion-item";
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                      ? {
                                          backgroundColor: "#fafafa",
                                          cursor: "pointer",
                                        }
                                      : {
                                          backgroundColor: "#ffffff",
                                          cursor: "pointer",
                                        };
                                    return (
                                      <div
                                        key={suggestion.placeId}
                                        {...getSuggestionItemProps(suggestion, {
                                          className,
                                          style,
                                        })}
                                        style={{
                                          backgroundColor:
                                            "transparent !important",
                                        }}
                                      >
                                        <span>{suggestion.description}</span>
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                            </div>
                          )}
                        </PlacesAutocomplete>
                        {/* <input
                    type='text'
                    className='form-control'
                    name=''
                    placeholder='Add location'
                    value={location}
                    onChange={(e) => setlocation(e.target.value)}
                    disabled={deliveryType != 'Drop_off' ? true : false}
                  /> */}
                      </div>
                    </div>
                  )}
                  {needType === "do" && (
                    <div className="form-group">
                      <label
                        className={`${
                          deliveryType != "Drop_off" && "disabled"
                        }`}
                      >
                        Add place where it happens:
                      </label>
                      <div
                        className="input-group completed_by"
                        style={{ height: "auto", minHeight: 56 }}
                      >
                        <div className="input-group-prepend ">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                            className="location disabled"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M10 20C11.3333 20 18 15.7142 18 8.57139C18 3.83854 14.4173 0 10 0C5.58267 0 2 3.83854 2 8.57139C2 15.7142 8.66667 20 10 20ZM10 12C12.2091 12 14 10.2091 14 8C14 5.79086 12.2091 4 10 4C7.79086 4 6 5.79086 6 8C6 10.2091 7.79086 12 10 12Z"
                            />
                          </svg>
                        </div>
                        <PlacesAutocomplete
                          value={location}
                          onChange={handleChange}
                          onSelect={handleSelect}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <div style={{ width: "87%" }}>
                              <input
                                {...getInputProps({
                                  placeholder:
                                    "e.x.: Red st., 65/316, Texas, USA",
                                  className: "form-control",
                                  id: "place",
                                  name: "place",
                                })}
                              />
                              {suggestions?.length > 0 && (
                                <div className="autocomplete-dropdown-container">
                                  {loading && <div>Loading...</div>}
                                  {suggestions.map((suggestion) => {
                                    const className = suggestion.active
                                      ? "suggestion-item--active"
                                      : "suggestion-item";
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                      ? {
                                          backgroundColor: "#fafafa",
                                          cursor: "pointer",
                                        }
                                      : {
                                          backgroundColor: "#ffffff",
                                          cursor: "pointer",
                                        };
                                    return (
                                      <div
                                        key={suggestion.placeId}
                                        {...getSuggestionItemProps(suggestion, {
                                          className,
                                          style,
                                        })}
                                        style={{
                                          backgroundColor:
                                            "transparent !important",
                                        }}
                                      >
                                        <span>{suggestion.description}</span>
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                            </div>
                          )}
                        </PlacesAutocomplete>
                        {/* <input
                    type='text'
                    className='form-control'
                    name=''
                    placeholder='Add location'
                    value={location}
                    onChange={(e) => setlocation(e.target.value)}
                    disabled={deliveryType != 'Drop_off' ? true : false}
                  /> */}
                      </div>
                    </div>
                  )}
                  {needType === "give" && (
                    <div class="form-group">
                      <label class="disabled">Will pick it up if within:</label>
                      <input
                        type="text"
                        class="form-control"
                        id="distance"
                        placeholder="Enter a distance"
                        value={picupDistance}
                        onChange={(e) => setpicupDistance(e.target.value)}
                        disabled={deliveryType != "Pick_up" ? true : false}
                      />
                    </div>
                  )}

                  {needType != "buy" && (
                    <div class="form-group sendOption-row">
                      <label>When people take action:</label>
                      <div>
                        <div class="column">
                          <label class="form-check-label" for="letreview">
                            Let me review
                            <input
                              type="radio"
                              name="deliverOption"
                              id="letreview"
                              value="letreview"
                              checked={autoAppoved === false ? true : false}
                              onChange={() => setautoAppoved(false)}
                            />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                        <div class="column">
                          <label class="form-check-label" for="automatically">
                            Automatically approve
                            <input
                              type="radio"
                              name="deliverOption"
                              id="automatically"
                              value="automatically"
                              checked={autoAppoved === true ? true : false}
                              onChange={() => setautoAppoved(true)}
                            />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  )}

                  {subscriptionApiRes &&
                  (subscriptionApiRes?.subscribedPlan?.status === "active" ||
                    subscriptionApiRes?.subscribedPlan?.status === "done") ? (
                    <div class="upgrade-wrapper">
                      {/* {userInfo.membership === 'free' && (
                    <div class='upgrade-box'>
                      <img src={Locked} alt='' />
                      <h3>
                        Upgrade the account <br />
                        to customize info you require
                      </h3>
                      <button type='button' class='btn btn-upgrade'>
                        Upgrade Account
                      </button>
                    </div>
                  )} */}
                      {renderAccordToSubscriptionStatus()}
                    </div>
                  ) : (
                    <>
                      {/* {userInfo.membership != 'free' && ( */}
                      <button
                        type="submit"
                        class="btn btn-primary"
                        onClick={createNeed}
                      >
                        Post Need
                      </button>
                      {/* <p type="button" className="type-danger" onClick={deleteData}>
                        Delete Need
                      </p> */}
                      {/* )} */}
                      <div className="position-relative">
                        <div className="disabledMarkasProrityHover">
                          <div className="text-center">
                            {/* <img src={lockedImg} />
                            <h3>
                              Upgrade the account to customize info you require
                            </h3> */}
                            <a
                              className="btn Upgrade-Accountbutton"
                              onClick={() => {
                                props?.history?.push({
                                  pathname:
                                    "/user/setting/" + props?.match?.params?.id,
                                  state: { data: "billing" },
                                });
                              }}
                            >
                              <span>Upgrade to Pro</span> to unlock additional features
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="disabledMarkasProrityDiv">
                        {renderAccordToSubscriptionStatus()}
                      </div>
                      
                    </>
                  )}

                  
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default NeedCreationNeedInfoEditingFreeAccountRestrictions;

// {subscriptionApiRes &&
//   (subscriptionApiRes?.subscribedPlan?.status === "active" ||
//     subscriptionApiRes?.subscribedPlan?.status === "done") ? (
//     <div class="upgrade-wrapper">
//       {/* {userInfo.membership === 'free' && (
//       <div class='upgrade-box'>
//         <img src={Locked} alt='' />
//         <h3>
//           Upgrade the account <br />
//           to customize info you require
//         </h3>
//         <button type='button' class='btn btn-upgrade'>
//           Upgrade Account
//         </button>
//       </div>
//     )} */}
//       {renderAccordToSubscriptionStatus()}
//     </div>
//   ) : (
//     <>

//       {/* {userInfo.membership != 'free' && ( */}
//       <button
//         type="submit"
//         class="btn btn-primary"
//         onClick={createNeed}
//       >
//         Post Need
//       </button>
//       {/* )} */}
//       <div className="position-relative">
//         <div className="disabledMarkasProrityHover">
//           <div className="text-center">
//             {/* <img src={lockedImg} />
//             <h3></h3> */}
//             <a
//               className="btn Upgrade-Accountbutton"
//               onClick={() => {
//                 props?.history?.push({
//                   pathname:
//                     "/setting/" + props?.match?.params?.id,
//                   state: { data: "billing" },
//                 });
//               }}
//             >
//               <span>Upgrade to Pro</span> to unlock additional features
//             </a>
//           </div>
//         </div>
//       </div>
//       <div class="disabledMarkasProrityDiv">
//         {renderAccordToSubscriptionStatus()}
//       </div>
//     </>
//   )}
