import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import UserDeskTopHeaderOne from '../../Common/UserDeskTopHeaderOne';
import ListCreationMobileHeader from '../../Common/MobileHeaderOne';
import error404 from '../../assets/images/404.png';
const Error404 = (props) => {
  return (
    <>
      <UserDeskTopHeaderOne history={props.history} />
      <ListCreationMobileHeader
        pageTitle='Contact Us'
        previousPage={null}
        close='/account-created'
        history={props.history}
      />

      <div class='content dashboard error'>
        <div class='content-wrapper' style={{ marginTop: 50 }}>
          <div class='error-img-wrapper'>
            <img src={error404} alt='404' />
          </div>
          <p class='error-message'>Oops, something's missing</p>
          <button type='button' class='btn btn-extra'>
            Back to Home
          </button>
        </div>
      </div>
    </>
  );
};

export default Error404;
