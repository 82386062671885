import React, { useState } from "react";
import Backdrop from "../../../Common/Backdrop/Backdrop";
import Minimize from "../../../assets/images/minimize.png";
import MinusBlue from "../../../assets/images/minus_blue_32x32.png";
import PlusBlue from "../../../assets/images/plus_blue_32x32.png";
import AxiosApi from "../../../Config/axios-api";
import Swal from "sweetalert2";

import { ToastContainer, toast } from "react-toastify";

export default function NeedsCreationOpenCard(props) {
  // console.log('propsto->', props?.selectedNeedItem?.needId);
  const [itemId, setItemId] = useState(props?.selectedNeedItem?._id);
  let [title, setTitle] = useState(props?.selectedNeedItem?.title);
  let [quantity, setQuantity] = useState(1);
  let [needType, setNeed_Type] = useState("give");
  console.log("object give open");

  const [isDelete, setIsDelete] = useState(false);

  function add(e) {
    e.preventDefault();
    let qty = quantity + 1;
    setQuantity(qty);
  }

  function minus(e) {
    e.preventDefault();
    let qty = quantity;
    if (qty !== 1) qty--;

    setQuantity(qty);
  }

  function changeQuantity(e) {
    setQuantity(e.target.value);
  }

  function changeTitle(e) {
    setTitle(e.target.value);
  }

  function createBuyNeed(e) {
    e.preventDefault();
    props.createNeed(
      { title, qtyNeeded: quantity, needType: "give", needStatus: "active" },
      true
    );
    props.clicked();
  }
  function updateData() {
    var obj = {};
    obj.title = title;
    obj.quantity = quantity;
    props.addDetails(obj);
  }

  const deleteData = async (e, id) => {
    e.preventDefault();
    setIsDelete(true);
    Swal.fire({
      title: "Are you sure to want delete NeedList!",
      showCancelButton: true,
      confirmButtonColor: "#e8271a",
      confirmButtonText: `Delete`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { data } = await AxiosApi.delete(
          `/actionList/deleteNeed/${props?.selectedNeedItem?.needId}`
        );
        if (data?.msg) {
          Swal.fire("Deleted!", data?.msg, "success");
          window.location.reload();
        } else {
          toast.error("Something went worng!");
        }
      }
    });
  };

  const renderGiveDesktopView = () => {
    return (
      <div className="modal-body">
        <form>
          <div className="form-group">
            <label>Select type of the need</label>
            <div className="type-options">
              <label htmlFor="buy_type">
                <input
                  type="radio"
                  name="type"
                  id="buy_type"
                  defaultValue="buy"
                  onClick={(e) => props.openBuyModal()}
                />
                <span className="mark">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10.5 23C8.567 23 7 24.567 7 26.5C7 28.433 8.567 30 10.5 30C12.433 30 14 28.433 14 26.5C14 24.567 12.433 23 10.5 23ZM5 26.5C5 23.4624 7.46244 21 10.5 21C13.5376 21 16 23.4624 16 26.5C16 29.5376 13.5376 32 10.5 32C7.46243 32 5 29.5376 5 26.5Z"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M21.5 23C19.567 23 18 24.567 18 26.5C18 28.433 19.567 30 21.5 30C23.433 30 25 28.433 25 26.5C25 24.567 23.433 23 21.5 23ZM16 26.5C16 23.4624 18.4624 21 21.5 21C24.5376 21 27 23.4624 27 26.5C27 29.5376 24.5376 32 21.5 32C18.4624 32 16 29.5376 16 26.5Z"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M1.20992 6.38701C1.39934 6.14286 1.691 6 2.00001 6H10.6784C11.0838 6 11.4491 6.24477 11.6032 6.61973C11.7574 6.99469 11.6699 7.42562 11.3818 7.71079L7.26351 11.7863L16 20.5811L24.7365 11.7863L20.6182 7.71079C20.3301 7.42562 20.2426 6.99469 20.3968 6.61973C20.5509 6.24477 20.9162 6 21.3216 6H30C30.309 6 30.6007 6.14286 30.7901 6.38701C30.9795 6.63116 31.0454 6.94916 30.9686 7.24848L27.1207 22.2485C27.0072 22.6907 26.6086 23 26.1521 23H5.84796C5.39138 23 4.99278 22.6907 4.87933 22.2485L1.03137 7.24848C0.954588 6.94916 1.0205 6.63116 1.20992 6.38701ZM18.4029 21H25.3762L28.7111 8H23.7538L26.8555 11.0694C27.0442 11.2562 27.1509 11.5104 27.1521 11.7759C27.1532 12.0415 27.0487 12.2966 26.8615 12.485L18.4029 21ZM13.5971 21H6.62381L3.28892 8H8.24622L5.14456 11.0694C4.95581 11.2562 4.84911 11.5104 4.84797 11.7759C4.84684 12.0415 4.95137 12.2966 5.13851 12.485L13.5971 21Z"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M16 0C16.2968 0 16.5782 0.131823 16.7682 0.359816L21.7682 6.35982C22.0166 6.6579 22.0702 7.07277 21.9056 7.42416C21.741 7.77554 21.388 8 21 8H11C10.612 8 10.259 7.77554 10.0944 7.42416C9.92984 7.07277 9.98339 6.6579 10.2318 6.35982L15.2318 0.359816C15.4218 0.131823 15.7032 0 16 0ZM13.1351 6H18.865L16 2.56205L13.1351 6Z"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10.0493 6.29454C10.237 6.106 10.492 6 10.7581 6H21.2419C21.508 6 21.763 6.106 21.9507 6.29454L26.7087 11.0748C27.0947 11.4625 27.0974 12.0885 26.7148 12.4796L16.7147 22.6994C16.5266 22.8916 16.269 23 16 23C15.731 23 15.4734 22.8916 15.2852 22.6994L5.28525 12.4796C4.90259 12.0885 4.90527 11.4625 5.29125 11.0748L10.0493 6.29454ZM11.1736 8L7.40496 11.7862L16 20.5702L24.595 11.7862L20.8264 8H11.1736Z"
                    />
                  </svg>
                  Buy
                </span>
              </label>

              <label htmlFor="give_type">
                <input
                  type="radio"
                  name="type"
                  id="give_type"
                  defaultValue="give"
                  checked={props?.show}
                  onClick={(e) => props.openGiveModal()}
                />
                <span className="mark">
                  <svg
                    width="33"
                    height="32"
                    viewBox="0 0 33 32"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M1.26343 12C1.26343 11.4477 1.71114 11 2.26343 11H11.2634C11.8157 11 12.2634 11.4477 12.2634 12V19H20.16C20.7123 19 21.16 19.4477 21.16 20V30C21.16 30.5523 20.7123 31 20.16 31H2.26343C1.71114 31 1.26343 30.5523 1.26343 30V12ZM3.26343 13V29H19.16V21H11.2634C10.7111 21 10.2634 20.5523 10.2634 20V13H3.26343Z"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.5403 1.07516C12.9153 1.22933 13.1601 1.59462 13.1601 2.00003V12C13.1601 12.5523 12.7123 13 12.1601 13H2.26346C1.85994 13 1.49596 12.7575 1.34057 12.3851C1.18519 12.0127 1.26884 11.5834 1.55268 11.2966L11.4493 1.29661C11.7345 1.00845 12.1654 0.92099 12.5403 1.07516ZM4.66004 11H11.1601V4.4321L4.66004 11Z"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11.16 2C11.16 1.44772 11.6077 1 12.16 1H30.16C30.5645 1 30.9291 1.24364 31.0839 1.61732C31.2387 1.99099 31.1531 2.42111 30.8671 2.70711L20.8671 12.7071C20.6796 12.8946 20.4253 13 20.16 13H12.16C11.6077 13 11.16 12.5523 11.16 12V2ZM13.16 3V11H19.7458L27.7458 3H13.16Z"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M20.16 19H30.16C30.5645 19 30.9291 19.2436 31.0839 19.6173C31.2387 19.991 31.1531 20.4211 30.8671 20.7071L20.8671 30.7071C20.5811 30.9931 20.151 31.0787 19.7774 30.9239C19.4037 30.7691 19.16 30.4045 19.16 30V20C19.16 19.4477 19.6077 19 20.16 19ZM21.16 21V27.5858L27.7458 21H21.16Z"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M30.5427 1.07615C30.9164 1.23093 31.16 1.59557 31.16 2.00003V20C31.16 20.5523 30.7123 21 30.16 21H20.16C19.6077 21 19.16 20.5523 19.16 20V12C19.16 11.7348 19.2654 11.4805 19.4529 11.2929L29.4529 1.29292C29.7389 1.00692 30.169 0.921369 30.5427 1.07615ZM21.16 12.4142V19H29.16V4.41424L21.16 12.4142Z"
                    />
                  </svg>
                  Give
                </span>
              </label>

              <label htmlFor="do_type">
                <input
                  type="radio"
                  name="type"
                  id="do_type"
                  defaultValue="do"
                  onClick={(e) => props.openDoModal()}
                />
                <span className="mark">
                  <svg
                    width="33"
                    height="32"
                    viewBox="0 0 33 32"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M3.53178 4.71647C7.15375 1.09451 13.0271 1.09451 16.6491 4.71647L17.5224 5.58984C17.9129 5.98036 17.9129 6.61353 17.5224 7.00405L5.81936 18.7071C5.42883 19.0976 4.79567 19.0976 4.40514 18.7071L3.53178 17.8337C-0.0901837 14.2118 -0.0901837 8.33844 3.53178 4.71647ZM15.2348 6.13069C12.3939 3.28977 7.78691 3.28977 4.946 6.13069C2.10508 8.9716 2.10508 13.5786 4.946 16.4195L5.11225 16.5858L15.4011 6.29694L15.2348 6.13069Z"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M16.9816 4.71647C20.6035 1.09451 26.4769 1.09451 30.0988 4.71647C33.7208 8.33844 33.7208 14.2118 30.0988 17.8337L29.2255 18.7071C29.0379 18.8946 28.7836 19 28.5184 19C28.2531 19 27.9988 18.8946 27.8113 18.7071L16.1082 7.00405C15.7177 6.61353 15.7177 5.98036 16.1082 5.58984L16.9816 4.71647ZM28.6846 6.13069C25.8437 3.28977 21.2367 3.28977 18.3958 6.13069L18.2295 6.29694L28.5184 16.5858L28.6846 16.4195C31.5255 13.5786 31.5255 8.9716 28.6846 6.13069Z"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M16.7322 5.06019C17.0004 5.05785 17.2583 5.16337 17.448 5.35304L29.3094 17.2144C29.6978 17.6028 29.7002 18.2317 29.3149 18.6231L17.3734 30.7527C17.1838 30.9453 16.9243 31.053 16.654 31.0511C16.3838 31.0493 16.1257 30.9381 15.9387 30.743L4.43992 18.7419C4.06828 18.3541 4.06948 17.7418 4.44265 17.3554L16.0216 5.36548C16.2079 5.17253 16.464 5.06253 16.7322 5.06019ZM6.54951 18.0528L16.6705 28.6159L27.1936 17.927L16.7533 7.48678L6.54951 18.0528Z"
                    />
                  </svg>
                  Do
                </span>
              </label>
            </div>
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              name="need_title"
              placeholder="Title of the need"
              value={title}
              onChange={(e) => changeTitle(e)}
            />
          </div>

          {(props?.selectedNeedItem?.needType || props?.show) && (
            <>
              <div className="form-group how-manymargin">
                <label>How many items do you need?</label>
                <div className="input-group items-input">
                  <div
                    className="input-group-prepend"
                    onClick={(e) => minus(e)}
                  >
                    <button className="btn btn-outline-secondary" type="button">
                      <img src={MinusBlue} alt="" />
                    </button>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    value={quantity}
                    placeholder=""
                    aria-label=""
                    aria-describedby="basic-addon1"
                    onChange={(e) => {
                      changeQuantity(e);
                    }}
                  />
                  <div className="input-group-append" onClick={(e) => add(e)}>
                    <button className="btn btn-outline-secondary" type="button">
                      <img src={PlusBlue} alt="" />
                    </button>
                  </div>
                </div>
              </div>
              <button
                type="button"
                className="btn btn-extra"
                onClick={updateData}
              >
                Add details
              </button>
            </>
          )}
          <button
            type="submit"
            className="btn btn-primary"
            // disabled={!(props?.show)}
            onClick={createBuyNeed}
          >
            Post Need
          </button>
          {/* <p type="button" className="type-danger" onClick={deleteData}>
            Delete Need
          </p> */}
        </form>
      </div>
    );
  };

  const renderGiveMobileView = () => {
    return (
      <div
        className="modal need-creation-card-modal show"
        tabIndex="-1"
        role="dialog"
        style={{ display: "block" }}
      >
        <Backdrop show={true} close={props.clicked} />
        <div className="modal-dialog show" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="btn-minimize">
                <img src={Minimize} alt="" />
              </button>
            </div>
            {/* {renderGiveDesktopView()} */}
          </div>
        </div>
        <ToastContainer autoClose={3000} />
      </div>
    );
  };
  return (
    <>
      <div className="web_view">{renderGiveDesktopView()}</div>
      <div className="mob_view">{renderGiveMobileView()}</div>
    </>
  );
}
