import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "./embedCode.css";
import { useDispatch, useSelector } from "react-redux";
const Header = (props) => {
  const idArray = window.location.href.split("/");
  const id = idArray[idArray.length - 1];
  let history = useHistory();
  const [searchData, setSearchData] = useState();

  const getuserWishList = useSelector(
    (state) => state.getuserWishList.userWishlistInfo
  );
  const searchfun = () => {
    if (searchData) {
      history.push("/embed/search/" + searchData);
      setSearchData("");
    }
  };

  return (
    <>
      <header>
        <div className="container">
          <div className="header-alignment">
            <div className="header-search">
              <div className="input-type-search">
                <input
                  placeholder="Search for needs..."
                  className="from-control"
                  type="text"
                  value={searchData}
                  onChange={(e) => setSearchData(e.target.value)}
                  onKeyDown={(e) => {
                    console.log(e);
                    if (e.which === 13) {
                      return searchfun();
                    }
                  }}
                />
                <div className="search-icon" onClick={() => searchfun()}>
                  {/* <img src={require("../../../public/images/search.png")}/> */}
                  <img src="images/search.png" alt="search" />
                </div>
              </div>
            </div>

            <div className="cart-design">
              <Link className="cart-design" to={`/embed/cart/${id}`}>
                <span>Cart</span>
              </Link>
              <div className="rounded-icon">
                {getuserWishList?.length ? getuserWishList?.length : 0}
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
