import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Back from '../../../assets/images/back.png';
import help from '../../../assets/images/help.png';
export default function Withdrawbyothers(props) {
  return (
    <div
      class='tab-pane fade show active'
      id='withdraw-funds'
      role='tabpanel'
      aria-labelledby='withdraw-funds-tab'
    >
      <div class='mobile-header-inner'>
        <nav class='navbar'>
          <a onClick={props.backdata} class='back'>
            <img src={Back} />
          </a>
          <label class='navbar-brand mx-auto middle-label'>
            Withdraw funds
          </label>
        </nav>
      </div>
      <form class='withdrawal-form form-2' style={{ marginTop: 0 }}>
        <h2 class='heading2'>Who will withdraw the money raised?</h2>
        <div class='form-group'>
          <label for='firstname' class='label'>
            First name
          </label>
          <input
            type='text'
            class='form-control'
            id='firstname'
            placeholder='ex. Carol'
          />
        </div>
        <div class='form-group'>
          <label for='lastname' class='label'>
            Last name
          </label>
          <input
            type='text'
            class='form-control'
            id='lastname'
            placeholder='ex. Smith'
          />
        </div>
        <div class='form-group'>
          <label for='email' class='label'>
            Email address
          </label>
          <input
            type='text'
            class='form-control'
            id='email'
            placeholder=''
          />
        </div>

        <label class='grant-acces-label'>
          <strong>Grant full access</strong> to this person, who will withdraw
          the mobey raised. This person will be able to see donor names, send
          thank-you notes, and post list updates.
        </label>

        <button type='submit' onClick={props.nextclick} class='btn btn-prime'>
          Invite beneficiary
        </button>

        <div class='need-help'>
          <img src={help} />
          Need help?
        </div>
      </form>
    </div>
  );
}
