import React, { useEffect, useState } from "react";
import Close from "../../../assets/images/close2.png";
import Leaf from "../../../assets/images/leaf.svg";
import PriorityMark from "../../../assets/images/priority_mark.png";
import Buy from "../../../assets/images/buy.svg";
// import MinusActiveBlossom from "../../../assets/images/minus_active_blossom.png";
import MinusActiveBlossom from "../../../assets/images/minus_active.png";
// import PlusInactive from "../../../assets/images/plus_inactive.png";
import PlusInactive from "../../../assets/images/plus_active.png";
import Give from "../../../assets/images/give.svg";
import Location from "../../../assets/images/location.svg";
import Clock from "../../../assets/images/clock.svg";
import MinusInactive from "../../../assets/images/minus_inactive.png";
import Do from "../../../assets/images/do.svg";
import PlusActiveBlossom from "../../../assets/images/plus_active_blossom.png";
import LogoFooter from "../../../assets/images/Frame.svg";
import { Link } from "react-router-dom";
import { getNeedsList, getWishList } from "../../../Store/Actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import filter from "../../../assets/images/filter.png";
import BottomTab from "../../../Common/BottomTab";
import GeneralDonation from "../../../Common/donationModal/GeneralDonation";
import Nouislider from "nouislider-react";
//import 'nouislider/distribute/nouislider.css';
import { PurchaseAddToCart } from "../../../Routing/RouteConstants";
import { useHistory } from "react-router-dom";
import AxiosApi from "../../../Config/axios-api";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Profileheadermobile from "../../../Common/Profileheadermobile";

import PlacesAutocomplete from "react-places-autocomplete";
import Form from "react-bootstrap/Form";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import UnloginuserHead from "../../../Common/UnloginuserHead";
import Cookies from "js-cookie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import { stringCapitalize } from "../../../services/stringModify";

var moment = require("moment");

const ListCreationPreviewBlossom = (props) => {
  const baseUrl = window.location.origin;
  const dispatch = useDispatch();
  let history = useHistory();
  let [needsList, setNeedsList] = useState([]);

  const { userCreateList } = useSelector((state) => state.createList);
  const { userInfo } = useSelector((state) => state.userSignin);
  const getuserNeedsList = useSelector((state) => state.getuserNeedsList);
  const getuserWishList = useSelector(
    (state) => state.getuserWishList.userWishlistInfo
  );

  const { loading, userNeedlistInfo, error } = getuserNeedsList;
  const [filterChecked, setFilterChecked] = useState("");
  const [selectedaction, setselectedaction] = useState();
  const [headerhide, setHeaderHide] = useState(false);
  const [place, setPlace] = useState("");
  const [category, setCategory] = useState("");
  const [filterDate, setFilterDate] = useState("");
  const [sliderRange, setSlinderRange] = useState("");
  const [readMore, setReadMore] = useState(false);
  const [view, setView] = useState("short");
  const [key, setKey] = useState();
  const [qty, setQty] = useState();

  // console.log({ getuserNeedsList });
  useEffect(() => {
    dispatch(getNeedsList(props.match.params.id));
    getActionListData();
    var str = window.location.href;
    var iframeindex = str.search("iframe");
    var sharelink = str.search("sharelink");
    if (iframeindex != -1) {
      setHeaderHide(true);
    } else if (sharelink != -1) {
      setHeaderHide(true);
    } else {
      setHeaderHide(false);
    }
  }, []);

  const getActionListData = async () => {
    const { data } = await AxiosApi.get(
      "/actionList/getActionListByActionListId/" + props.match.params.id
    );
    setselectedaction(data[0]);
    // console.log(data[0]);
  };

  useEffect(() => {
    if (userNeedlistInfo) {
      const newFile = userNeedlistInfo.map((item) => {
        return { ...item, qty: 1 };
      });
      setNeedsList(newFile);
    }
  }, [userNeedlistInfo]);

  const onSlide = (render, handle, value, un, percent) => {
    setSlinderRange(value);
  };

  const handleChange = (place) => {
    setPlace(place);
  };

  const handleSelect = (place) => {
    setPlace(place);
  };

  const handleSelectCategory = (e) => {
    // console.log(e);
    setCategory(e);
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div className="selected">
      <a
        className="dropdown-item"
        href=""
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        <span>{children}</span>
        {/* &#x25bc; */}
      </a>
    </div>
  ));
  //console.log("abc", window.location.origin);
  const addToWishList = (item) => {
    console.log("already", getuserWishList);
    console.log("working", item);

    if (getuserWishList?.length > 0 || getuserWishList !== undefined) {
      if (
        getuserWishList[0] &&
        getuserWishList[0]?.actionListId != item.actionListId
      ) {
        Swal.fire({
          title: "Please clear your old actionlist cart?",
          text: "You can not add different action list item at a time",
          icon: "warning",
          showCancelButton: false,
        });
      } else {
        const index = getuserWishList.findIndex(
          (rec) => rec.needId === item.needId
        );
        if (index >= 0) {
          getuserWishList[index].qty = getuserWishList[index].qty + item.qty;
          console.log({ getuserWishList });
          const wishList = getuserWishList;
          const data = wishList ? wishList : [];
          var wishListData = [...data];
          dispatch(getWishList(wishListData));
          toast.success("Need successfully added to your cart.");
        } else {
          const wishList = getuserWishList;
          const data = wishList ? wishList : [];
          var wishListData = [...data];
          wishListData.push({
            needId: item.needId,
            actionListId: item.actionListId,
            qty: item.qty,
            needType: item.needType,
            Name: item.title,
            cost: item.cost ? item.cost : 0,
            location: item.location,
            ActionListTitle: selectedaction?.title,
          });
          dispatch(getWishList(wishListData));
          toast.success("Need successfully added to your cart.");
        }
      }
    } else {
      const wishList = getuserWishList;
      const data = wishList ? wishList : [];
      var wishListData = [...data];
      wishListData.push({
        needId: item.needId,
        actionListId: item.actionListId,
        qty: item.qty,
        needType: item.needType,
        Name: item.title,
        cost: item.cost ? item.cost : 0,
        location: item.location,
        ActionListTitle: selectedaction?.title,
      });
      dispatch(getWishList(wishListData));
      toast.success("Need successfully added to your cart.");
    }
  };
  const updateItemQty = (item, value, inputValue) => {
    // console.log("list item", item);
    const index = needsList.findIndex((rec) => rec.needId === item.needId);
    console.log(index);
    let list = [...needsList];
    if (index >= 0) {
      if (
        value == "input" &&
        parseInt(inputValue) <= item.qtyNeeded - item.qtyOrdered
      ) {
        // console.log("list", { list });
        list[index].qty = parseInt(inputValue);
        setNeedsList(list);
      } else {
        if (value == true) {
          if (list[index].qty <= item.qtyNeeded - item.qtyOrdered - 1) {
            list[index].qty = list[index].qty + 1;
            setNeedsList(list);
          }
        } else if (value == false) {
          if (list[index].qty > 1) {
            list[index].qty = list[index].qty - 1;
            setNeedsList(list);
          }
        }
      }
    }
  };
  // console.log("list", { needsList });
  const handleDateSearch = (e) => {
    setFilterDate(e.target.value);
  };
  const handleSetNeedList = () => {
    if (userNeedlistInfo) {
      const newFile = userNeedlistInfo.map((item) => {
        return { ...item, qty: 1 };
      });
      return newFile;
    }
  };
  const filterPopUp = (e) => {
    e.preventDefault();

    let filterCheckValue = filterChecked === "completed" ? "done" : "active";
    let filteredData = handleSetNeedList();
    console.log({ category });
    console.log(place);
    setNeedsList();
    const data = filteredData.filter((item) => {
      if (category == "do") {
        console.log("in d");
        // if (filterCheckValue == "active") {
        //   if (
        //     filterCheckValue == item.needStatus &&
        //     item?.needType?.toLowerCase() == category &&
        //     item?.location &&
        //     item?.location?.includes(place) &&
        //     moment(item.updatedAt).format("DD-MM-YYYY") ==
        //       moment(filterDate).format("DD-MM-YYYY")
        //   ) {
        //     return item;
        //   }
        // } else if (filterCheckValue == "done") {
        if (
          // moment(item.updatedAt).format("DD-MM-YYYY") ==
          //   moment(filterDate).format("DD-MM-YYYY") ||
          // item.qtyNeeded - item.qtyOrdered == 0 &&
          // filterCheckValue == item.needStatus &&
          (item?.needType?.toLowerCase() == category &&
            item?.location &&
            item?.location?.includes(place)) ||
          (filterDate &&
            moment(item.updatedAt).format("DD-MM-YYYY") ==
              moment(filterDate).format("DD-MM-YYYY"))
        ) {
          return item;
        }
        // }
      } else if (category == "give") {
        // if (filterCheckValue == "active") {
        //   console.log("in give active");
        //   if (
        //     // moment(item.updatedAt).format("DD-MM-YYYY") ==
        //     //   moment(filterDate).format("DD-MM-YYYY") ||
        //     filterCheckValue == item.needStatus &&
        //     item?.needType?.toLowerCase() == category &&
        //     item?.location &&
        //     item?.location?.includes(place) &&
        //     filterDate &&
        //     moment(item.updatedAt).format("DD-MM-YYYY") ==
        //       moment(filterDate).format("DD-MM-YYYY")
        //   ) {
        //     return item;
        //   }
        // } else if (filterCheckValue == "done") {
        console.log("in give done");
        if (
          // moment(item.updatedAt).format("DD-MM-YYYY") ==
          //   moment(filterDate).format("DD-MM-YYYY") ||
          // item.qtyNeeded - item.qtyOrdered == 0 &&
          // filterCheckValue == item.needStatus &&
          (item?.needType?.toLowerCase() == category &&
            item?.location &&
            item?.location?.includes(place)) ||
          (filterDate &&
            moment(item.updatedAt).format("DD-MM-YYYY") ==
              moment(filterDate).format("DD-MM-YYYY"))
        ) {
          return item;
        }
        // }
      } else if (category == "buy") {
        // console.log("in buy");
        // if (filterCheckValue == "active") {
        //   console.log("in buy active");
        //   if (
        //     // moment(item.updatedAt).format("DD-MM-YYYY") ==
        //     //   moment(filterDate).format("DD-MM-YYYY") ||
        //     filterCheckValue == item.needStatus &&
        //     item?.needType?.toLowerCase() == category &&
        //     item.cost <= sliderRange[1] &&
        //     item.cost >= sliderRange[0] &&
        //     filterDate &&
        //     moment(item.updatedAt).format("DD-MM-YYYY") ==
        //       moment(filterDate).format("DD-MM-YYYY")
        //   ) {
        //     return item;
        //   }
        // } else if (filterCheckValue == "done") {
        console.log("in buy done");
        if (
          // moment(item.updatedAt).format("DD-MM-YYYY") ==
          //   moment(filterDate).format("DD-MM-YYYY") ||

          // filterCheckValue == item.needStatus &&
          (item?.needType?.toLowerCase() == category &&
            item.cost <= sliderRange[1] &&
            item.cost >= sliderRange[0]) ||
          (filterDate &&
            moment(item.updatedAt).format("DD-MM-YYYY") ==
              moment(filterDate).format("DD-MM-YYYY"))
        ) {
          return item;
        }
        // }
      } else {
        if (category == "buy" && item?.needType?.toLowerCase() == category) {
          return item;
        } else if (
          category == "give" &&
          item?.needType?.toLowerCase() == category
        ) {
          return item;
        } else if (
          category == "do" &&
          item?.needType?.toLowerCase() == category
        ) {
          return item;
        } else {
          return item;
        }
      }
    });
    console.log("--", data);
    setNeedsList(data);

    // console.log("filteredData", data);
  };
  console.log("=========>>>>>>>>>>>>>>>>>", selectedaction);
  let percentwidth =
    Math.ceil((selectedaction?.done / selectedaction?.needsCount) * 100) + "%";
  let buy = 0;
  let give = 0;
  let dolength = 0;
  const copyLink = (data) => {
    toast.success(data);
  };
  // console.log("window", window.location);
  return (
    <div className={`content theme-page ${selectedaction?.themeName}`}>
      {Cookies.get("token") ? (
        <Profileheadermobile history={props.history} />
      ) : (
        <UnloginuserHead history={props.history} />
      )}
      {/* <div
        className='preview-header-row'
        style={{ display: headerhide ? 'none' : 'flex' }}
      >
        <label className='status-label mx-auto text-center'>Preview mode</label>
        <div onClick={() => props.history.goBack()} className='close'>
          <img src={Close} alt='close' />
        </div>
      </div> */}
      {selectedaction !== undefined && needsList.length >=  0 ? (
        <div>
          <div className="section_one">
            <div className="container">
              <div className="row row1">
                <div className="col left">
                  <h2>{stringCapitalize(selectedaction?.title)}</h2>
                </div>
                <div className="col right text-right desktop-view">
                  <div className="wrapper text-left">
                    <div className="progress w-100">
                      <div
                        className={
                          selectedaction?.done
                            ? "progress-bar progress-barBlock"
                            : "progress-bar progress-barNone"
                        }
                        role="progressbar"
                        style={{
                          width:
                            selectedaction?.done === 0 ? "0%" : percentwidth,
                        }}
                        aria-valuenow="28"
                        aria-valuemin="0"
                        aria-valuemax="40"
                      ></div>
                    </div>
                    <strong>{selectedaction?.done} Actioned</strong> of{" "}
                    {selectedaction?.needsCount} needs
                  </div>
                </div>
              </div>
              <div className="row row2">
                <div className="col">
                  <label>
                    Organized by{" "}
                    <strong>{`${selectedaction?.userName}`}</strong>
                    {selectedaction?.verified ? (
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M7.46246 0.897018L8.45212 1.2865C8.80515 1.42543 9.19771 1.42543 9.55075 1.2865L10.5404 0.897018C11.7517 0.420296 13.1231 0.988317 13.6425 2.18196L14.0669 3.15715C14.2183 3.50503 14.4959 3.78261 14.8438 3.934L15.819 4.35839C17.0126 4.87784 17.5806 6.24917 17.1039 7.46051L16.7144 8.45016C16.5755 8.8032 16.5755 9.19576 16.7144 9.54879L17.1039 10.5384C17.5806 11.7498 17.0126 13.1211 15.819 13.6406L14.8438 14.065C14.4959 14.2163 14.2183 14.4939 14.0669 14.8418L13.6425 15.817C13.1231 17.0106 11.7517 17.5787 10.5404 17.1019L9.55075 16.7125C9.19771 16.5735 8.80515 16.5735 8.45212 16.7125L7.46246 17.1019C6.25112 17.5787 4.8798 17.0106 4.36034 15.817L3.93595 14.8418C3.78457 14.4939 3.50698 14.2163 3.15911 14.065L2.18391 13.6406C0.99027 13.1211 0.422249 11.7498 0.898972 10.5384L1.28845 9.54879C1.42739 9.19576 1.42739 8.8032 1.28845 8.45016L0.898972 7.46051C0.422249 6.24917 0.99027 4.87784 2.18391 4.35839L3.15911 3.934C3.50698 3.78261 3.78457 3.50503 3.93595 3.15715L4.36034 2.18196C4.8798 0.988317 6.25112 0.420296 7.46246 0.897018ZM11.6277 6.6673L7.98002 10.771L6.35499 9.14592C6.15972 8.95066 5.84314 8.95066 5.64788 9.14592C5.45262 9.34119 5.45262 9.65777 5.64788 9.85303L7.64788 11.853C7.8513 12.0565 8.18401 12.0467 8.37514 11.8317L12.3751 7.33166C12.5586 7.12527 12.54 6.80923 12.3336 6.62577C12.1272 6.44232 11.8112 6.46091 11.6277 6.6673Z" />
                      </svg>
                    ) : null}
                  </label>
                </div>
              </div>
              <div className="row row3">
                <div className="col">
                  <ul>
                    <li>
                      <img src={Leaf} alt="Leaf" />
                      {`${stringCapitalize(selectedaction?.purpose)}`}
                    </li>
                    <li>
                      Updated {moment(selectedaction?.updatedAt).fromNow()}
                    </li>
                  </ul>
                  <p
                    className="desc"
                    style={{ marginTop: "24px", color: "#72819B" }}
                  >
                    {stringCapitalize(selectedaction?.listDesc)}
                  </p>
                </div>
              </div>
              <div className="mobile-view progress-line">
                <div className="wrapper text-left">
                  <div className="progress w-100">
                    <div
                      className={
                        selectedaction?.done
                          ? "progress-bar progress-barBlock"
                          : "progress-bar progress-barNone"
                      }
                      role="progressbar"
                      style={{
                        width: selectedaction?.done === 0 ? "0%" : percentwidth,
                      }}
                      aria-valuenow="28"
                      aria-valuemin="0"
                      aria-valuemax="40"
                    ></div>
                  </div>

                  <label>
                    {selectedaction?.done} of {selectedaction?.needsCount} needs
                    actioned
                  </label>
                </div>
              </div>
              <div className="row row4">
                <div className="col">
                  <p>{stringCapitalize(userCreateList?.description)}</p>
                </div>
              </div>
              <div className="row row5">
                <div className="col">
                  <button
                    type="button"
                    className="btn btn-donate"
                    onClick={() => window.$("#donateListModal").modal("show")}
                  >
                    Donate
                  </button>
                  <button
                    type="button"
                    className="btn btn-share"
                    onClick={() => window.$("#shareListModal").modal("show")}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M17.7143 14.75C16.705 14.75 15.7774 15.086 15.0451 15.6478L10.4698 12.8955C10.6053 12.3056 10.6053 11.6943 10.4698 11.1044L15.0451 8.35213C15.7774 8.91403 16.705 9.25 17.7143 9.25C20.0812 9.25 22 7.40316 22 5.125C22 2.84684 20.0812 1 17.7143 1C15.3474 1 13.4286 2.84684 13.4286 5.125C13.4286 5.43257 13.4638 5.73215 13.5302 6.02051L8.95491 8.77283C8.22263 8.21097 7.295 7.875 6.28571 7.875C3.91879 7.875 2 9.72184 2 12C2 14.2782 3.91879 16.125 6.28571 16.125C7.295 16.125 8.22263 15.789 8.95491 15.2272L13.5302 17.9795C13.4626 18.2735 13.4285 18.5738 13.4286 18.875C13.4286 21.1532 15.3474 23 17.7143 23C20.0812 23 22 21.1532 22 18.875C22 16.5968 20.0812 14.75 17.7143 14.75Z" />
                    </svg>
                    Share
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="section_two">
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="action-row">
                    <div class="mobile-view">
                      <div class="dropdown">
                        <button
                          class="btn btn-secondary dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Filter
                          <img src={filter} class="ml-auto" />
                        </button>
                        <div
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <div class="mobile-header">
                            <nav class="navbar">
                              <label class="navbar-brand mx-auto middle-label">
                                Filters
                              </label>
                              <a data-toggle="dropdown" class="cross">
                                <img src="images/close2.png" />
                              </a>
                            </nav>
                          </div>
                          <div class="content">
                            {/* mobile */}
                            <form>
                              {/* <h4>Status of a need</h4>
                          <div class="form-group row status-row">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                              <label for="completed">
                                Completed
                                <input
                                  type="checkbox"
                                  id="completed"
                                  value={"completed"}
                                  onChange={() => setFilterChecked("completed")}
                                  checked={
                                    filterChecked === "completed" ? true : false
                                  }
                                />
                                <span class="checkmark"></span>
                              </label>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                              <label for="incompleted">
                                Incompleted
                                <input
                                  type="checkbox"
                                  id="incompleted"
                                  value={"incompleted"}
                                  onChange={() =>
                                    setFilterChecked("incompleted")
                                  }
                                  checked={
                                    filterChecked === "incompleted"
                                      ? true
                                      : false
                                  }
                                />
                                <span class="checkmark"></span>
                              </label>
                            </div>
                          </div> */}

                              <div class="form-group price-row">
                                <h4>Price range</h4>
                                <div id="price_slider" class="price_slider">
                                  <Nouislider
                                    range={{ min: 0, max: 2000 }}
                                    start={[0, 50]}
                                    connect
                                    step={10}
                                    onSlide={onSlide}
                                    style={{
                                      boxShadow: "none",
                                      background: "transparent",
                                      border: "none",
                                    }}
                                  />
                                </div>
                                <div class="input-wrapper">
                                  <div class="input-box left">
                                    <label for="minPrice">
                                      Min. price, USD
                                    </label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      id="minPrice"
                                      placeholder="0"
                                      min="0"
                                      value={sliderRange[0]}
                                    />
                                  </div>
                                  <div class="middle">-</div>
                                  <div class="input-box right">
                                    <label for="maxPrice">
                                      Max. price, USD
                                    </label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      id="maxPrice"
                                      placeholder="1500"
                                      max="2000"
                                      value={sliderRange[1]}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <PlacesAutocomplete
                                  value={place}
                                  onChange={handleChange}
                                  onSelect={handleSelect}
                                >
                                  {({
                                    getInputProps,
                                    suggestions,
                                    getSuggestionItemProps,
                                    loading,
                                  }) => (
                                    <div class="input-group">
                                      <input
                                        {...getInputProps({
                                          placeholder: "Enter Location",
                                          className: "form-control",
                                          id: "place",
                                          name: "place",
                                        })}
                                      />

                                      <div class="input-group-append">
                                        <svg
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M12 24C13.6667 24 22 18.8571 22 10.2857C22 4.60624 17.5217 0 12 0C6.47833 0 2 4.60624 2 10.2857C2 18.8571 10.3333 24 12 24ZM12 13C14.2091 13 16 11.2091 16 9C16 6.79086 14.2091 5 12 5C9.79086 5 8 6.79086 8 9C8 11.2091 9.79086 13 12 13Z"
                                            fill="#607BFF"
                                          />
                                        </svg>
                                      </div>
                                      <div className="autocomplete-dropdown-container">
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map((suggestion) => {
                                          const className = suggestion.active
                                            ? "suggestion-item--active"
                                            : "suggestion-item";
                                          // inline style for demonstration purpose
                                          const style = suggestion.active
                                            ? {
                                                backgroundColor: "#fafafa",
                                                cursor: "pointer",
                                              }
                                            : {
                                                backgroundColor: "#ffffff",
                                                cursor: "pointer",
                                              };
                                          return (
                                            <div
                                              key={suggestion.placeId}
                                              {...getSuggestionItemProps(
                                                suggestion,
                                                {
                                                  className,
                                                  style,
                                                }
                                              )}
                                            >
                                              <span>
                                                {suggestion.description}
                                              </span>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  )}
                                </PlacesAutocomplete>
                              </div>
                              <div class="form-group">
                                <div class="dropdown dropdown">
                                  <DropdownButton
                                    title={
                                      !category ? "Category of need" : category
                                    }
                                    id="dropdown-basic-button"
                                    onSelect={handleSelectCategory}
                                  >
                                    <Dropdown.Item eventKey="buy">
                                      Buy
                                    </Dropdown.Item>
                                    <Dropdown.Item eventKey="give">
                                      Give
                                    </Dropdown.Item>
                                    <Dropdown.Item eventKey="do">
                                      Do
                                    </Dropdown.Item>
                                  </DropdownButton>
                                </div>
                              </div>

                              <div class="form-group">
                                <div class="input-group">
                                  <input
                                    type="date"
                                    class="form-control"
                                    placeholder="Complete-by date"
                                    aria-label="Complete-by date"
                                    aria-describedby="basic-addon2"
                                    onChange={handleDateSearch}

                                    // onChange={(e) => { setFilterDate(e.target.value) }}
                                  />
                                </div>
                              </div>

                              <button
                                onClick={(e) => filterPopUp(e)}
                                // data-toggle="dropdown"
                                class="btn btn-prime"
                              >
                                Apply filters
                              </button>
                            </form>
                          </div>
                        </div>
                      </div>
                      <button
                        type="button"
                        className={`btn grid-btn ${
                          view == "expand" && "active"
                        }`}
                        onClick={() => setView("expand")}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="4.5" cy="6.5" r="4.5" />
                          <circle cx="4.5" cy="17.5" r="4.5" />
                          <rect x="12" y="2" width="12" height="9" rx="4" />
                          <rect x="12" y="13" width="12" height="9" rx="4" />
                        </svg>
                      </button>
                      <button
                        className={`btn grid-btn ${
                          view == "short" && "active"
                        }`}
                        onClick={() => setView("short")}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="2.5" cy="12.5" r="2.5" />
                          <circle cx="2.5" cy="19.5" r="2.5" />
                          <circle cx="2.5" cy="5.5" r="2.5" />
                          <rect x="8" y="10" width="16" height="4" rx="2" />
                          <rect x="8" y="18" width="16" height="4" rx="2" />
                          <rect x="8" y="3" width="16" height="4" rx="2" />
                        </svg>
                      </button>
                    </div>
                    <ul
                      className="nav nav-tabs mr-auto"
                      id="myTab"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          id="all-tab"
                          data-toggle="tab"
                          href="#all"
                          role="tab"
                          aria-controls="all"
                          aria-selected="true"
                        >
                          All Needs
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="buy-tab"
                          data-toggle="tab"
                          href="#buy"
                          role="tab"
                          aria-controls="buy"
                          aria-selected="false"
                        >
                          Buy
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="give-tab"
                          data-toggle="tab"
                          href="#give"
                          role="tab"
                          aria-controls="give"
                          aria-selected="false"
                        >
                          Give
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="do-tab"
                          data-toggle="tab"
                          href="#do"
                          role="tab"
                          aria-controls="do"
                          aria-selected="false"
                        >
                          Do
                        </a>
                      </li>
                    </ul>
                    <div className="for-desktop">
                      <div className="dropdown">
                        <button
                          className="btn btn-secondary dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Filter
                          {/* <!-- <img src="images/filter.png" className="ml-auto"> --> */}
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            className="ml-auto"
                          >
                            <rect x="1" y="3" width="22" height="4" rx="2" />
                            <rect x="4" y="10" width="16" height="4" rx="2" />
                            <rect x="7" y="17" width="10" height="4" rx="2" />
                          </svg>
                        </button>

                        <div
                          class="dropdown-menu Fixedposition-dropdown"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <div class="mobile-header">
                            <nav class="navbar">
                              <label class="navbar-brand mx-auto middle-label">
                                Filters
                              </label>
                              <a data-toggle="dropdown" class="cross">
                                <img src="images/close2.png" />
                              </a>
                            </nav>
                          </div>
                          <div class="content">
                            <form>
                              {/* <h4>Status of a need </h4>
                          <div class="form-group row status-row">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                              <label for="completed">
                                Completed
                                <input
                                  type="checkbox"
                                  id="completed"
                                  value={"completed"}
                                  onChange={() => setFilterChecked("completed")}
                                  checked={
                                    filterChecked === "completed" ? true : false
                                  }
                                />
                                <span class="checkmark"></span>
                              </label>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                              <label for="incompleted">
                                Incompleted
                                <input
                                  type="checkbox"
                                  id="incompleted"
                                  value={"incompleted"}
                                  onChange={() =>
                                    setFilterChecked("incompleted")
                                  }
                                  checked={
                                    filterChecked === "incompleted"
                                      ? true
                                      : false
                                  }
                                />
                                <span class="checkmark"></span>
                              </label>
                            </div>
                          </div> */}

                              <div class="form-group price-row">
                                <h4>Price range</h4>
                                <div id="price_slider" class="price_slider">
                                  <Nouislider
                                    range={{ min: 0, max: 2000 }}
                                    start={[0, 50]}
                                    connect
                                    step={10}
                                    onSlide={onSlide}
                                    style={{
                                      boxShadow: "none",
                                      background: "transparent",
                                      border: "none",
                                    }}
                                  />
                                </div>
                                <div class="input-wrapper">
                                  <div class="input-box left">
                                    <label for="minPrice">
                                      Min. price, USD
                                    </label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      id="minPrice"
                                      placeholder="0"
                                      min="0"
                                      value={sliderRange[0]}
                                    />
                                  </div>
                                  <div class="middle">-</div>
                                  <div class="input-box right">
                                    <label for="maxPrice">
                                      Max. price, USD
                                    </label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      id="maxPrice"
                                      placeholder="1500"
                                      max="2000"
                                      value={sliderRange[1]}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <PlacesAutocomplete
                                  value={place}
                                  onChange={handleChange}
                                  onSelect={handleSelect}
                                >
                                  {({
                                    getInputProps,
                                    suggestions,
                                    getSuggestionItemProps,
                                    loading,
                                  }) => (
                                    <div class="input-group">
                                      <input
                                        {...getInputProps({
                                          placeholder: "Enter Location",
                                          className: "form-control",
                                          id: "place",
                                          name: "place",
                                        })}
                                      />

                                      <div class="input-group-append">
                                        <svg
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M12 24C13.6667 24 22 18.8571 22 10.2857C22 4.60624 17.5217 0 12 0C6.47833 0 2 4.60624 2 10.2857C2 18.8571 10.3333 24 12 24ZM12 13C14.2091 13 16 11.2091 16 9C16 6.79086 14.2091 5 12 5C9.79086 5 8 6.79086 8 9C8 11.2091 9.79086 13 12 13Z"
                                            fill="#607BFF"
                                          />
                                        </svg>
                                      </div>
                                      <div className="autocomplete-dropdown-container">
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map((suggestion) => {
                                          const className = suggestion.active
                                            ? "suggestion-item--active"
                                            : "suggestion-item";
                                          // inline style for demonstration purpose
                                          const style = suggestion.active
                                            ? {
                                                backgroundColor: "#fafafa",
                                                cursor: "pointer",
                                              }
                                            : {
                                                backgroundColor: "#ffffff",
                                                cursor: "pointer",
                                              };
                                          return (
                                            <div
                                              key={suggestion.placeId}
                                              {...getSuggestionItemProps(
                                                suggestion,
                                                {
                                                  className,
                                                  style,
                                                }
                                              )}
                                            >
                                              <span>
                                                {suggestion.description}
                                              </span>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  )}
                                </PlacesAutocomplete>
                              </div>
                              <div class="form-group">
                                <div class="dropdown dropdown">
                                  <DropdownButton
                                    title={
                                      !category ? "Category of need" : category
                                    }
                                    id="dropdown-basic-button"
                                    onSelect={handleSelectCategory}
                                  >
                                    <Dropdown.Item eventKey="buy">
                                      Buy
                                    </Dropdown.Item>
                                    <Dropdown.Item eventKey="give">
                                      Give
                                    </Dropdown.Item>
                                    <Dropdown.Item eventKey="do">
                                      Do
                                    </Dropdown.Item>
                                  </DropdownButton>
                                </div>
                              </div>

                              <div class="form-group">
                                <div class="input-group">
                                  <input
                                    type="date"
                                    class="form-control"
                                    placeholder="Complete-by date"
                                    aria-label="Complete-by date"
                                    aria-describedby="basic-addon2"
                                    onChange={handleDateSearch}

                                    // onChange={(e) => { setFilterDate(e.target.value) }}
                                  />
                                </div>
                              </div>

                              <button
                                onClick={(e) => filterPopUp(e)}
                                // data-toggle="dropdown"
                                class="btn btn-prime"
                              >
                                Apply filters
                              </button>
                            </form>
                          </div>
                        </div>
                      </div>

                      {/* expand */}
                      <button
                        type="button"
                        className={`btn grid-btn ${
                          view == "expand" && "active"
                        }`}
                        onClick={() => setView("expand")}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="4.5" cy="6.5" r="4.5" />
                          <circle cx="4.5" cy="17.5" r="4.5" />
                          <rect x="12" y="2" width="12" height="9" rx="4" />
                          <rect x="12" y="13" width="12" height="9" rx="4" />
                        </svg>
                      </button>
                      {/* short */}
                      <button
                        type="button"
                        className={`btn grid-btn ${
                          view == "short" && "active"
                        }`}
                        onClick={() => setView("short")}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="2.5" cy="12.5" r="2.5" />
                          <circle cx="2.5" cy="19.5" r="2.5" />
                          <circle cx="2.5" cy="5.5" r="2.5" />
                          <rect x="8" y="10" width="16" height="4" rx="2" />
                          <rect x="8" y="18" width="16" height="4" rx="2" />
                          <rect x="8" y="3" width="16" height="4" rx="2" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="all"
                      role="tabpanel"
                      aria-labelledby="all-tab"
                      style={{ marginBottom: 50 }}
                    >
                      {needsList && needsList.length === 0 ? (
                        <center>
                          <div className="empty-msg">
                            <h2>No Data Found :(</h2>
                          </div>
                        </center>
                      ) : (
                        needsList.length > 0 &&
                        needsList.map((item, index) => {
                          return (
                            <>
                              {item.qtyNeeded ? (
                                item.qtyNeeded - item.qtyOrdered <= 0 ? (
                                  ""
                                ) : (
                                  <>
                                    <>
                                      {(item.needType == "buy" ||
                                        item.needType == "Buy") &&
                                      item.needStatus !== "draft" ? (
                                        <div
                                          className={
                                            item.priority
                                              ? "need-box priority"
                                              : "need-box"
                                          }
                                        >
                                          <div className="column column-first mr-auto">
                                            <div className="left d-inline-block">
                                              <img src={Buy} alt="Buy" />
                                            </div>
                                            <div className="right d-inline-block">
                                              <h5>
                                                {stringCapitalize(item.title)}
                                                {item?.priority && (
                                                  <span className="badge">
                                                    Priority
                                                  </span>
                                                )}
                                              </h5>{" "}
                                              {view == "expand" && (
                                                <p className="for-desktop">
                                                  {item?.description?.substring(
                                                    0,
                                                    50
                                                  )}{" "}
                                                  {item?.description?.length >
                                                    50 && "..."}
                                                  {/* {item?.description?.length > 50 && (
                                      <a>Read More</a>
                                    )} */}
                                                </p>
                                              )}
                                            </div>
                                          </div>
                                          <div className="column column-price">
                                            <label>${item.cost}</label>
                                            <label className="quantity">
                                              {item.qtyNeeded
                                                ? item.qtyNeeded -
                                                  item.qtyOrdered
                                                : 0}{" "}
                                              needed
                                            </label>
                                          </div>
                                          <div className="divider mobile-view">
                                            <hr />
                                          </div>
                                          <div className="column column-last">
                                            <div
                                              className={
                                                item.qtyNeeded
                                                  ? item.qtyNeeded -
                                                      item.qtyOrdered !==
                                                    0
                                                    ? "left"
                                                    : "disabledDivView"
                                                  : "disabledDivView"
                                              }
                                            >
                                              <div className="input-group">
                                                <div className="input-group-prepend">
                                                  <button
                                                    className="btn btn-outline-secondary"
                                                    type="button"
                                                    onClick={() =>
                                                      updateItemQty(item, false)
                                                    }
                                                    disabled={
                                                      item.qtyNeeded
                                                        ? item.qtyNeeded -
                                                            item.qtyOrdered ===
                                                          0
                                                          ? true
                                                          : item.qtyNeeded -
                                                              item.qtyOrdered <
                                                            0
                                                          ? true
                                                          : false
                                                        : true
                                                    }
                                                  >
                                                    <img
                                                      src={MinusActiveBlossom}
                                                      alt="MinusActiveBlossom"
                                                    />
                                                  </button>
                                                </div>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  defaultValue="1"
                                                  defaultValue={item.qty}
                                                  value={item.qty}
                                                  onChange={(e) =>
                                                    updateItemQty(
                                                      item,
                                                      "input",
                                                      e.target.value
                                                    )
                                                  }
                                                  disabled={
                                                    item.qtyNeeded -
                                                      item.qtyOrdered ===
                                                    0
                                                      ? true
                                                      : item.qtyNeeded -
                                                          item.qtyOrdered <
                                                        0
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                <div className="input-group-append">
                                                  <button
                                                    className="btn btn-outline-secondary"
                                                    type="button"
                                                    onClick={() =>
                                                      updateItemQty(item, true)
                                                    }
                                                    disabled={
                                                      item.qtyNeeded -
                                                        item.qtyOrdered ===
                                                      0
                                                        ? true
                                                        : item.qtyNeeded -
                                                            item.qtyOrdered <
                                                          0
                                                        ? true
                                                        : false
                                                    }
                                                  >
                                                    <img
                                                      src={PlusInactive}
                                                      alt="PlusInactive"
                                                    />
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="right">
                                              <label>
                                                {item.qtyNeeded
                                                  ? item.qtyNeeded -
                                                    item.qtyOrdered
                                                  : 0}{" "}
                                                needed
                                              </label>
                                              <button
                                                type="button"
                                                className="btn"
                                                onClick={() =>
                                                  addToWishList(item)
                                                }
                                                disabled={
                                                  item.qtyNeeded
                                                    ? item.qtyNeeded -
                                                        item.qtyOrdered ===
                                                      0
                                                      ? true
                                                      : false
                                                    : true
                                                }
                                              >
                                                {/* Add to cart */}
                                                Buy
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      ) : null}{" "}
                                    </>
                                    {item.needType === "give" &&
                                      item.needStatus !== "draft" && (
                                        <div
                                          className={
                                            item.priority
                                              ? "need-box priority"
                                              : "need-box"
                                          }
                                        >
                                          <div className="column column-first mr-auto">
                                            <div className="left h-100">
                                              <div className=" d-inline-block">
                                                <img src={Give} alt="Give" />
                                              </div>
                                            </div>
                                            <div className="right d-inline-block">
                                              <h5>
                                                {stringCapitalize(item.title)}
                                                {item?.priority && (
                                                  <span className="badge">
                                                    Priority
                                                  </span>
                                                )}
                                              </h5>
                                              {view == "expand" && (
                                                <p className="for-desktop">
                                                  {item?.description?.substring(
                                                    0,
                                                    50
                                                  )}{" "}
                                                  {item?.description?.length >
                                                    50 && "..."}
                                                  {/* {item?.description?.length > 50 && (
                                      <a>Read More</a>
                                    )} */}
                                                </p>
                                              )}
                                            </div>
                                          </div>
                                          <div className="column column-first mobile-view">
                                            {view == "expand" && (
                                              <p>
                                                {item?.description?.substring(
                                                  0,
                                                  50
                                                )}{" "}
                                                {item?.description?.length >
                                                  50 && "..."}
                                                {/* {item?.description?.length > 50 && (
                                    <a>Read More</a>
                                  )} */}
                                              </p>
                                            )}
                                          </div>
                                          {item?.location && (
                                            <div className="column column-location">
                                              <div className="wrapper">
                                                <div className="left d-inline-block">
                                                  {item?.location && (
                                                    <img
                                                      src={Location}
                                                      alt="Location"
                                                    />
                                                  )}
                                                </div>
                                                &nbsp;
                                                <div className="right d-inline-block">
                                                  <label>{item.location}</label>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                          <div className="divider mobile-view">
                                            <hr />
                                          </div>
                                          <div className="column column-last">
                                            <div
                                              className={
                                                item.qtyNeeded
                                                  ? item.qtyNeeded -
                                                      item.qtyOrdered !==
                                                      0 &&
                                                    item.qtyNeeded -
                                                      item.qtyOrdered >
                                                      0
                                                    ? "left"
                                                    : "disabledDivView"
                                                  : "disabledDivView"
                                              }
                                            >
                                              {/* <div className="input-group disabled for-desktop"> */}
                                              <div className="input-group">
                                                <div className="input-group-prepend">
                                                  <button
                                                    className="btn btn-outline-secondary"
                                                    type="button"
                                                    onClick={() =>
                                                      updateItemQty(item, false)
                                                    }
                                                    disabled={
                                                      item.qtyNeeded -
                                                        item.qtyOrdered ===
                                                      0
                                                        ? true
                                                        : item.qtyNeeded -
                                                            item.qtyOrdered <
                                                          0
                                                        ? true
                                                        : false
                                                    }
                                                  >
                                                    <img
                                                      // src={MinusInactive}
                                                      // alt="MinusInactive"
                                                      src={MinusActiveBlossom}
                                                      alt="MinusActiveBlossom"
                                                    />
                                                  </button>
                                                </div>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  // defaultValue="1"
                                                  defaultValue={item.qty}
                                                  value={item.qty}
                                                  onChange={(e) =>
                                                    updateItemQty(
                                                      item,
                                                      "input",
                                                      e.target.value
                                                    )
                                                  }
                                                  disabled={
                                                    item.qtyNeeded -
                                                      item.qtyOrdered ===
                                                    0
                                                      ? true
                                                      : item.qtyNeeded -
                                                          item.qtyOrdered <
                                                        0
                                                      ? true
                                                      : false
                                                  }
                                                />

                                                <div className="input-group-append">
                                                  <button
                                                    className="btn btn-outline-secondary"
                                                    type="button"
                                                    onClick={() =>
                                                      updateItemQty(item, true)
                                                    }
                                                    disabled={
                                                      item.qtyNeeded -
                                                        item.qtyOrdered ===
                                                      0
                                                        ? true
                                                        : item.qtyNeeded -
                                                            item.qtyOrdered <
                                                          0
                                                        ? true
                                                        : false
                                                    }
                                                  >
                                                    <img
                                                      src={PlusInactive}
                                                      alt="PlusInactive"
                                                    />
                                                  </button>
                                                </div>
                                              </div>
                                              <div className="quantity mobile-view">
                                                <label>
                                                  {item.qtyNeeded
                                                    ? item.qtyNeeded -
                                                        item.qtyOrdered <
                                                      0
                                                      ? 0
                                                      : item.qtyNeeded -
                                                        item.qtyOrdered
                                                    : 0}{" "}
                                                  needed
                                                </label>
                                              </div>
                                            </div>
                                            <div className="right">
                                              <label>
                                                {item.qtyNeeded
                                                  ? item.qtyNeeded -
                                                      item.qtyOrdered <
                                                    0
                                                    ? 0
                                                    : item.qtyNeeded -
                                                      item.qtyOrdered
                                                  : 0}{" "}
                                                needed
                                              </label>
                                              <button
                                                type="button"
                                                className="btn"
                                                onClick={() =>
                                                  addToWishList(item)
                                                }
                                                disabled={
                                                  item.qtyNeeded
                                                    ? item.qtyNeeded -
                                                        item.qtyOrdered ===
                                                      0
                                                      ? true
                                                      : item.qtyNeeded -
                                                          item.qtyOrdered <
                                                        0
                                                      ? true
                                                      : false
                                                    : true
                                                }
                                              >
                                                Give
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    {item.needType === "do" &&
                                      item.needStatus !== "draft" && (
                                        <div
                                          className={
                                            item.priority
                                              ? "need-box priority"
                                              : "need-box"
                                          }
                                        >
                                          <div className="column column-first mr-auto">
                                            <div className="left d-inline-block">
                                              <img src={Do} alt="Do" />
                                            </div>
                                            <div className="right d-inline-block">
                                              <h5>
                                                {stringCapitalize(item.title)}
                                                {item?.priority && (
                                                  <span className="badge">
                                                    Priority
                                                  </span>
                                                )}
                                              </h5>{" "}
                                              {view == "expand" && (
                                                <p className="for-desktop">
                                                  {item?.description?.substring(
                                                    0,
                                                    50
                                                  )}{" "}
                                                  {item?.description?.length >
                                                    50 && "..."}
                                                  {/* {item?.description?.length > 50 && (
                                      <a>Read More</a>
                                    )} */}
                                                </p>
                                              )}
                                            </div>
                                          </div>
                                          <div className="column column-first mobile-view">
                                            {view == "expand" && (
                                              <p>
                                                {item?.description?.substring(
                                                  0,
                                                  50
                                                )}{" "}
                                                {item?.description?.length >
                                                  50 && "..."}
                                                {item?.description?.length >
                                                  50 && <div>Read More</div>}
                                              </p>
                                            )}
                                          </div>
                                          {item?.updatedAt && (
                                            <>
                                              {view == "expand" && (
                                                <div className="column column-location column-time new_wrapper">
                                                  <div className="wrapper">
                                                    <div className="left d-inline-block">
                                                      <img
                                                        src={Clock}
                                                        alt="Clocl"
                                                      />
                                                    </div>
                                                    <div className="right d-inline-block">
                                                      <label>
                                                        Complete-by:{" "}
                                                        {moment(
                                                          item?.updatedAt
                                                        ).format(
                                                          "MMM DD YYYY,HH:MM a"
                                                        )}
                                                      </label>
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                            </>
                                          )}
                                          {item?.location && (
                                            <div className="column column-location">
                                              <div className="wrapper">
                                                <div className="left d-inline-block">
                                                  {item?.location && (
                                                    <img
                                                      src={Location}
                                                      alt="Location"
                                                    />
                                                  )}
                                                  &nbsp;
                                                </div>
                                                <div className="right d-inline-block">
                                                  {/* <h5>
                                          {stringCapitalize(item.title)}
                                          {item?.priority && (
                                            <span className="badge">
                                              Priority
                                            </span>
                                          )}
                                        </h5> */}
                                                  <label>
                                                    {view == "expand"
                                                      ? item.location
                                                      : item.location?.substring(
                                                          0,
                                                          50
                                                        )}
                                                    <span>
                                                      {/* (Lawrence/Scarborough Golf Club Rd) */}
                                                    </span>
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                          <div className="divider mobile-view">
                                            <hr />
                                          </div>
                                          <div className="column column-last">
                                            <div
                                              className={
                                                item.qtyNeeded
                                                  ? item.qtyNeeded -
                                                      item.qtyOrdered !==
                                                    0
                                                    ? "left"
                                                    : "disabledDivView"
                                                  : "disabledDivView"
                                              }
                                            >
                                              {/* <div className="input-group disabled for-desktop"> */}
                                              <div className="input-group">
                                                <div className="input-group-prepend">
                                                  <button
                                                    className="btn btn-outline-secondary"
                                                    type="button"
                                                    onClick={() => {
                                                      updateItemQty(
                                                        item,
                                                        false
                                                      );
                                                    }}
                                                    disabled={
                                                      item.qtyNeeded -
                                                        item.qtyOrdered ===
                                                      0
                                                        ? true
                                                        : item.qtyNeeded -
                                                            item.qtyOrdered <
                                                          0
                                                        ? true
                                                        : false
                                                    }
                                                  >
                                                    <img
                                                      // src={MinusInactive}
                                                      // alt="MinusInactive"
                                                      src={MinusActiveBlossom}
                                                      alt="MinusActiveBlossom"
                                                    />
                                                  </button>
                                                </div>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  // defaultValue="1"
                                                  defaultValue={item.qty}
                                                  value={item.qty}
                                                  onChange={(e) =>
                                                    updateItemQty(
                                                      item,
                                                      "input",
                                                      e.target.value
                                                    )
                                                  }
                                                  disabled={
                                                    item.qtyNeeded -
                                                      item.qtyOrdered ===
                                                    0
                                                      ? true
                                                      : item.qtyNeeded -
                                                          item.qtyOrdered <
                                                        0
                                                      ? true
                                                      : false
                                                  }
                                                />

                                                <div className="input-group-append">
                                                  <button
                                                    className="btn btn-outline-secondary"
                                                    type="button"
                                                    onClick={() => {
                                                      updateItemQty(item, true);
                                                    }}
                                                    disabled={
                                                      item.qtyNeeded -
                                                        item.qtyOrdered ===
                                                      0
                                                        ? true
                                                        : item.qtyNeeded -
                                                            item.qtyOrdered <
                                                          0
                                                        ? true
                                                        : false
                                                    }
                                                  >
                                                    <img
                                                      src={PlusInactive}
                                                      alt="PlusInactive"
                                                    />
                                                  </button>
                                                </div>
                                              </div>
                                              <div className="quantity mobile-view">
                                                <label>
                                                  {item.qtyNeeded
                                                    ? item.qtyNeeded -
                                                      item.qtyOrdered
                                                    : 0}{" "}
                                                  needed
                                                </label>
                                              </div>
                                            </div>
                                            <div className="right">
                                              <label>
                                                {item.qtyNeeded
                                                  ? item.qtyNeeded -
                                                    item.qtyOrdered
                                                  : 0}{" "}
                                                needed
                                              </label>
                                              <button
                                                type="button"
                                                className="btn"
                                                onClick={() =>
                                                  addToWishList(item)
                                                }
                                                disabled={
                                                  item.qtyNeeded
                                                    ? item.qtyNeeded -
                                                        item.qtyOrdered ===
                                                      0
                                                      ? true
                                                      : false
                                                    : true
                                                }
                                              >
                                                Volunteer
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      )}{" "}
                                  </>
                                )
                              ) : (
                                ""
                              )}
                            </>
                          );
                        })
                      )}
                    </div>

                    <div
                      className="tab-pane fade"
                      id="buy"
                      role="tabpanel"
                      aria-labelledby="buy-tab"
                      style={{ marginBottom: 50 }}
                    >
                      {needsList.length > 0 &&
                        needsList.map((item, index) => {
                          if (
                            item.needType == "buy" ||
                            item.needType == "Buy"
                          ) {
                            buy += 1;
                          }
                          return (
                            <>
                              {" "}
                              {item.qtyNeeded ? (
                                item.qtyNeeded - item.qtyOrdered <= 0 ? (
                                  ""
                                ) : (
                                  <>
                                    {(item.needType == "buy" ||
                                      item.needType == "Buy") &&
                                    item.needStatus !== "draft" ? (
                                      <div
                                        className={
                                          item.priority
                                            ? "need-box priority"
                                            : "need-box"
                                        }
                                      >
                                        <div className="column column-first mr-auto">
                                          <div className="left d-inline-block">
                                            <img src={Buy} alt="Buy" />
                                          </div>
                                          <div className="right d-inline-block">
                                            <h5>
                                              {stringCapitalize(item.title)}
                                              {item?.priority && (
                                                <span className="badge">
                                                  Priority
                                                </span>
                                              )}
                                            </h5>{" "}
                                            {view == "expand" && (
                                              <p className="for-desktop">
                                                {item?.description?.substring(
                                                  0,
                                                  50
                                                )}{" "}
                                                {item?.description?.length >
                                                  50 && "..."}
                                                {/* {item?.description?.length > 50 && (
                                      <a>Read More</a>
                                    )} */}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                        <div className="column column-price">
                                          <label>${item.cost}</label>
                                          <label className="quantity">
                                            {item.qtyNeeded
                                              ? item.qtyNeeded - item.qtyOrdered
                                              : 0}{" "}
                                            needed
                                          </label>
                                        </div>
                                        <div className="divider mobile-view">
                                          <hr />
                                        </div>
                                        <div className="column column-last">
                                          <div
                                            className={
                                              item.qtyNeeded
                                                ? item.qtyNeeded -
                                                    item.qtyOrdered !==
                                                  0
                                                  ? "left"
                                                  : "disabledDivView"
                                                : "disabledDivView"
                                            }
                                          >
                                            <div className="input-group">
                                              <div className="input-group-prepend">
                                                <button
                                                  className="btn btn-outline-secondary"
                                                  type="button"
                                                  onClick={() =>
                                                    updateItemQty(item, false)
                                                  }
                                                  disabled={
                                                    item.qtyNeeded
                                                      ? item.qtyNeeded -
                                                          item.qtyOrdered ===
                                                        0
                                                        ? true
                                                        : item.qtyNeeded -
                                                            item.qtyOrdered <
                                                          0
                                                        ? true
                                                        : false
                                                      : true
                                                  }
                                                >
                                                  <img
                                                    src={MinusActiveBlossom}
                                                    alt="MinusActiveBlossom"
                                                  />
                                                </button>
                                              </div>
                                              <input
                                                type="text"
                                                className="form-control"
                                                defaultValue="1"
                                                defaultValue={item.qty}
                                                value={item.qty}
                                                onChange={(e) =>
                                                  updateItemQty(
                                                    item,
                                                    "input",
                                                    e.target.value
                                                  )
                                                }
                                                disabled={
                                                  item.qtyNeeded -
                                                    item.qtyOrdered ===
                                                  0
                                                    ? true
                                                    : item.qtyNeeded -
                                                        item.qtyOrdered <
                                                      0
                                                    ? true
                                                    : false
                                                }
                                              />
                                              <div className="input-group-append">
                                                <button
                                                  className="btn btn-outline-secondary"
                                                  type="button"
                                                  onClick={() =>
                                                    updateItemQty(item, true)
                                                  }
                                                  disabled={
                                                    item.qtyNeeded -
                                                      item.qtyOrdered ===
                                                    0
                                                      ? true
                                                      : item.qtyNeeded -
                                                          item.qtyOrdered <
                                                        0
                                                      ? true
                                                      : false
                                                  }
                                                >
                                                  <img
                                                    src={PlusInactive}
                                                    alt="PlusInactive"
                                                  />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="right">
                                            <label>
                                              {item.qtyNeeded
                                                ? item.qtyNeeded -
                                                  item.qtyOrdered
                                                : 0}{" "}
                                              needed
                                            </label>
                                            <button
                                              type="button"
                                              className="btn"
                                              onClick={() =>
                                                addToWishList(item)
                                              }
                                              disabled={
                                                item.qtyNeeded
                                                  ? item.qtyNeeded -
                                                      item.qtyOrdered ===
                                                    0
                                                    ? true
                                                    : false
                                                  : true
                                              }
                                            >
                                              {/* Add to cart */}
                                              Buy
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}{" "}
                                  </>
                                )
                              ) : (
                                ""
                              )}
                            </>
                          );
                        })}
                      {buy === 0 ? (
                        <center>
                          <div className="empty-msg">
                            <h2>No Data Found :(</h2>
                          </div>
                        </center>
                      ) : null}
                    </div>
                    <div
                      className="tab-pane fade"
                      id="give"
                      role="tabpanel"
                      aria-labelledby="give-tab"
                      style={{ marginBottom: 50 }}
                    >
                      {needsList.length > 0 &&
                        needsList.map((item, index) => {
                          if (
                            item.needType == "give" ||
                            item.needType == "Give"
                          ) {
                            give += 1;
                          }
                          return (
                            <>
                              {" "}
                              {item.qtyNeeded ? (
                                item.qtyNeeded - item.qtyOrdered <= 0 ? (
                                  ""
                                ) : (
                                  <>
                                    {item.needType === "give" &&
                                      item.needStatus !== "draft" && (
                                        <div
                                          className={
                                            item.priority
                                              ? "need-box priority"
                                              : "need-box"
                                          }
                                        >
                                          <div className="column column-first mr-auto">
                                            <div className="left d-inline-block">
                                              <img src={Give} alt="Give" />
                                            </div>
                                            <div className="right d-inline-block">
                                              <h5>
                                                {stringCapitalize(item.title)}
                                                {item?.priority && (
                                                  <span className="badge">
                                                    Priority
                                                  </span>
                                                )}
                                              </h5>{" "}
                                              {view == "expand" && (
                                                <p className="for-desktop">
                                                  {item?.description?.substring(
                                                    0,
                                                    50
                                                  )}{" "}
                                                  {item?.description?.length >
                                                    50 && "..."}
                                                  {/* {item?.description?.length > 50 && (
                                      <a>Read More</a>
                                    )} */}
                                                </p>
                                              )}
                                            </div>
                                          </div>
                                          <div className="column column-first mobile-view">
                                            {view == "expand" && (
                                              <p>
                                                {item?.description?.substring(
                                                  0,
                                                  50
                                                )}{" "}
                                                {item?.description?.length >
                                                  50 && "..."}
                                                {/* {item?.description?.length > 50 && (
                                    <a>Read More</a>
                                  )} */}
                                              </p>
                                            )}
                                          </div>
                                          <div className="column column-location">
                                            <div className="wrapper">
                                              <div className="left d-inline-block">
                                                {item?.location && (
                                                  <img
                                                    src={Location}
                                                    alt="Location"
                                                  />
                                                )}
                                                &nbsp;
                                              </div>
                                              <div className="right d-inline-block">
                                                <label>{item.location}</label>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="divider mobile-view">
                                            <hr />
                                          </div>
                                          <div className="column column-last">
                                            <div
                                              className={
                                                item.qtyNeeded -
                                                  item.qtyOrdered !==
                                                  0 &&
                                                item.qtyNeeded -
                                                  item.qtyOrdered >
                                                  0
                                                  ? "left"
                                                  : "disabledDivView"
                                              }
                                            >
                                              <div className="input-group  for-desktop">
                                                <div className="input-group-prepend">
                                                  <button
                                                    className="btn btn-outline-secondary"
                                                    type="button"
                                                    onClick={() => {
                                                      updateItemQty(
                                                        item,
                                                        false
                                                      );
                                                    }}
                                                  >
                                                    <img
                                                      src={MinusActiveBlossom}
                                                      alt="MinusInactive"
                                                    />
                                                  </button>
                                                </div>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  defaultValue={item.qty}
                                                  value={item.qty}
                                                  onChange={(e) =>
                                                    updateItemQty(
                                                      item,
                                                      "input",
                                                      e.target.value
                                                    )
                                                  }
                                                  disabled={
                                                    item.qtyNeeded -
                                                      item.qtyOrdered ===
                                                    0
                                                      ? true
                                                      : item.qtyNeeded -
                                                          item.qtyOrdered <
                                                        0
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                <div className="input-group-append">
                                                  <button
                                                    className="btn btn-outline-secondary"
                                                    type="button"
                                                    onClick={() => {
                                                      updateItemQty(item, true);
                                                    }}
                                                    disabled={
                                                      item.qtyNeeded -
                                                        item.qtyOrdered ===
                                                      0
                                                        ? true
                                                        : item.qtyNeeded -
                                                            item.qtyOrdered <
                                                          0
                                                        ? true
                                                        : false
                                                    }
                                                  >
                                                    <img
                                                      src={PlusInactive}
                                                      alt="PlusInactive"
                                                    />
                                                  </button>
                                                </div>
                                              </div>
                                              <div className="quantity mobile-view">
                                                <label>
                                                  {item.qtyNeeded
                                                    ? item.qtyNeeded -
                                                        item.qtyOrdered <
                                                      0
                                                      ? 0
                                                      : item.qtyNeeded -
                                                        item.qtyOrdered
                                                    : 0}{" "}
                                                  needed
                                                </label>
                                              </div>
                                            </div>
                                            <div className="right">
                                              <label>
                                                {item.qtyNeeded
                                                  ? item.qtyNeeded -
                                                      item.qtyOrdered <
                                                    0
                                                    ? 0
                                                    : item.qtyNeeded -
                                                      item.qtyOrdered
                                                  : 0}{" "}
                                                needed
                                              </label>
                                              <button
                                                type="button"
                                                className="btn"
                                                onClick={() =>
                                                  addToWishList(item)
                                                }
                                                disabled={
                                                  item.qtyNeeded
                                                    ? item.qtyNeeded -
                                                        item.qtyOrdered ===
                                                      0
                                                      ? true
                                                      : item.qtyNeeded -
                                                          item.qtyOrdered <
                                                        0
                                                      ? true
                                                      : false
                                                    : true
                                                }
                                              >
                                                Give
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      )}{" "}
                                  </>
                                )
                              ) : (
                                ""
                              )}
                            </>
                          );
                        })}
                      {give === 0 ? (
                        <center>
                          <div className="empty-msg">
                            <h2>No Data Found :(</h2>
                          </div>
                        </center>
                      ) : null}
                    </div>
                    <div
                      className="tab-pane fade"
                      id="do"
                      role="tabpanel"
                      aria-labelledby="do-tab"
                      style={{ marginBottom: 50 }}
                    >
                      {needsList.length > 0 &&
                        needsList.map((item, index) => {
                          if (item.needType == "do" || item.needType == "do") {
                            dolength += 1;
                          }
                          return (
                            <>
                              {item.qtyNeeded ? (
                                item.qtyNeeded - item.qtyOrdered <= 0 ? (
                                  ""
                                ) : (
                                  <>
                                    {item.needType === "do" &&
                                      item.needStatus !== "draft" && (
                                        <div
                                          className={
                                            item.priority
                                              ? "need-box priority"
                                              : "need-box"
                                          }
                                        >
                                          <div className="column column-first mr-auto">
                                            <div className="left d-inline-block">
                                              <img src={Do} alt="Do" />
                                            </div>
                                            <div className="right d-inline-block">
                                              <h5>
                                                {stringCapitalize(item.title)}
                                                {item?.priority && (
                                                  <span className="badge">
                                                    Priority
                                                  </span>
                                                )}
                                              </h5>{" "}
                                              {view == "expand" && (
                                                <p className="for-desktop">
                                                  {item?.description?.substring(
                                                    0,
                                                    50
                                                  )}{" "}
                                                  {item?.description?.length >
                                                    50 && "..."}
                                                  {/* {item?.description?.length > 50 && (
                                      <a>Read More</a>
                                    )} */}
                                                </p>
                                              )}
                                            </div>
                                          </div>
                                          <div className="column column-first mobile-view">
                                            {view == "expand" && (
                                              <p>
                                                {item?.description?.substring(
                                                  0,
                                                  50
                                                )}{" "}
                                                {item?.description?.length >
                                                  50 && "..."}
                                                {/* {item?.description?.length > 50 && (
                                    <div>Read More</div>
                                  )} */}
                                              </p>
                                            )}
                                          </div>
                                          <div className="column column-location column-time">
                                            <div className="wrapper">
                                              <div className="left d-inline-block">
                                                <img src={Clock} alt="Clocl" />
                                              </div>
                                              <div className="right d-inline-block">
                                                <label>
                                                  Complete-by:{" "}
                                                  {moment(
                                                    item?.updatedAt
                                                  ).format(
                                                    "MMM DD YYYY, HH:MM a"
                                                  )}
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="column column-location">
                                            <div className="wrapper">
                                              <div className="left d-inline-block">
                                                {item?.location && (
                                                  <img
                                                    src={Location}
                                                    alt="Location"
                                                  />
                                                )}
                                              </div>
                                              <div className="right d-inline-block">
                                                &nbsp;
                                                <label>
                                                  {item.location}
                                                  <span>
                                                    {/* (Lawrence/Scarborough Golf Club Rd) */}
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="divider mobile-view">
                                            <hr />
                                          </div>
                                          <div className="column column-last">
                                            <div
                                              className={
                                                item.qtyNeeded -
                                                  item.qtyOrdered !==
                                                0
                                                  ? "left"
                                                  : "disabledDivView"
                                              }
                                            >
                                              <div className="input-group  for-desktop">
                                                <div className="input-group-prepend">
                                                  <button
                                                    className="btn btn-outline-secondary"
                                                    type="button"
                                                    onClick={() => {
                                                      updateItemQty(
                                                        item,
                                                        false
                                                      );
                                                    }}
                                                  >
                                                    <img
                                                      src={MinusActiveBlossom}
                                                      alt="MinusInactive"
                                                    />
                                                  </button>
                                                </div>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  defaultValue="1"
                                                  defaultValue={item.qty}
                                                  value={item.qty}
                                                  onChange={(e) =>
                                                    updateItemQty(
                                                      item,
                                                      "input",
                                                      e.target.value
                                                    )
                                                  }
                                                  disabled={
                                                    item.qtyNeeded -
                                                      item.qtyOrdered ===
                                                    0
                                                      ? true
                                                      : item.qtyNeeded -
                                                          item.qtyOrdered <
                                                        0
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                <div className="input-group-append">
                                                  <button
                                                    className="btn btn-outline-secondary"
                                                    type="button"
                                                    onClick={() => {
                                                      updateItemQty(
                                                        item.needId,
                                                        true
                                                      );
                                                    }}
                                                    disabled={
                                                      item.qtyNeeded -
                                                        item.qtyOrdered ===
                                                      0
                                                        ? true
                                                        : item.qtyNeeded -
                                                            item.qtyOrdered <
                                                          0
                                                        ? true
                                                        : false
                                                    }
                                                  >
                                                    <img
                                                      src={PlusInactive}
                                                      alt="PlusInactive"
                                                    />
                                                  </button>
                                                </div>
                                              </div>
                                              <div className="quantity mobile-view">
                                                <label>
                                                  {item.qtyNeeded
                                                    ? item.qtyNeeded -
                                                      item.qtyOrdered
                                                    : 0}{" "}
                                                  needed
                                                </label>
                                              </div>
                                            </div>
                                            <div className="right">
                                              <label>
                                                {item.qtyNeeded
                                                  ? item.qtyNeeded -
                                                    item.qtyOrdered
                                                  : 0}{" "}
                                                needed
                                              </label>
                                              <button
                                                type="button"
                                                className="btn"
                                                onClick={() =>
                                                  addToWishList(item)
                                                }
                                                disabled={
                                                  item.qtyNeeded
                                                    ? item.qtyNeeded -
                                                        item.qtyOrdered ===
                                                      0
                                                      ? true
                                                      : false
                                                    : true
                                                }
                                              >
                                                Volunteer
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      )}{" "}
                                  </>
                                )
                              ) : (
                                ""
                              )}
                            </>
                          );
                        })}
                      {dolength === 0 ? (
                        <center>
                          <div className="empty-msg">
                            <h2>No Data Found :(</h2>
                          </div>
                        </center>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="theme-page-footer">
            <label>
              Powered by <img src={LogoFooter} alt="LogoFooter" />
            </label>
          </div>
          <BottomTab activeScreen="dashboard" />
          {/* {console.log("GeneralDonation-up", props)} */}
          <GeneralDonation
            id={"donateListModal"}
            onClick={(item) => addToWishList(item)}
            history={props.history}
            actionId={props.match.params.id}
            actionListId={props.match.params.actionId}
            actionListTitle={selectedaction && selectedaction?.title}
            type={"normal"}
          />

          <div
            class="modal fade share-list-modal"
            id="shareListModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-body">
                  <div class="mobile-header">
                    <nav class="navbar">
                      <label class="navbar-brand mx-auto middle-label">
                        Share the list
                      </label>
                      <a
                        onClick={() =>
                          window.$("#shareListModal").modal("hide")
                        }
                        class="cross"
                      >
                        <img src="images/close2.png" />
                      </a>
                    </nav>
                  </div>
                  <button
                    type="button"
                    class="btn-close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => window.$("#shareListModal").modal("hide")}
                  >
                    <img src="images/close2.png" />
                  </button>
                  {/* <h2>Share the list</h2> */}
                  <label class="label">
                    You can share the link with your friends or embed the code
                    with the list to your website.
                  </label>
                  <div class="content">
                    <input
                      type="text"
                      // defaultValue={`${window.location.origin}${window.location.pathname}/embed/l/${props.match.params.id}`}
                      defaultValue={baseUrl + "/l/" + props.match.params.id}
                    />
                    <CopyToClipboard
                      // text={`${window.location.origin}${window.location.pathname}/embed/l/${props.match.params.id}`}
                      text={baseUrl + "/l/" + props.match.params.id}
                    >
                      <button
                        type="button"
                        className="btn btn-extra"
                        onClick={() => copyLink("Link Copied")}
                      >
                        Copy the link
                      </button>
                    </CopyToClipboard>
                    <textarea
                      rows="3"
                      defaultValue={`<iframe src="${`${window.location.origin}/embed/l/${props.match.params.id}`}" style='border: 0px #ffffff none' name="ActionList" frameborder="1" width="100%" height="600px" allowfullscreen></iframe>`}
                    ></textarea>
                    <CopyToClipboard
                      text={`<iframe src="${`${window.location.origin}/embed/l/${props.match.params.id}`}" style='border: 0px #ffffff none' name="ActionList" frameborder="1" width="100%" height="600px" allowfullscreen></iframe>`}
                    >
                      <button
                        type="button"
                        className="btn btn-extra"
                        onClick={() => copyLink("Embed Code Copied")}
                      >
                        Copy the Embed the code
                      </button>
                    </CopyToClipboard>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer autoClose={2000} />
        </div>
      ) : (
        <div class="loader">
          <div class="lds-dual-ring"></div>
        </div>
      )}
    </div>
  );
};

export default ListCreationPreviewBlossom;
