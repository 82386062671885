import {
  USER_SIGNIN_REQUEST,
  USER_SIGNIN_SUCCESS,
  USER_SIGNIN_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_LOGOUT,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_SEND_OTP_REQUEST,
  USER_SEND_OTP_SUCCESS,
  USER_SEND_OTP_FAIL,
  USER_VERIFY_OTP_REQUEST,
  USER_VERIFY_OTP_SUCCESS,
  USER_VERIFY_OTP_FAIL,
  USER_FORGOT_PASSWORD_REQUEST,
  USER_FORGOT_PASSWORD_SUCCESS,
  USER_FORGOT_PASSWORD_FAIL,
  USER_RESET_PASSWORD_REQUEST,
  USER_RESET_PASSWORD_SUCCESS,
  USER_RESET_PASSWORD_FAIL,
  ADD_GENERAL_INFO,
  ADD_PURPOSE,
  ADD_ADDITIONAL_INFO,
  ADD_DESCRIPTION,
  CREATE_LIST_SUCCESS,
  CREATE_LIST_FAIL,
  CREATE_NEED,
  DELETE_LIST,
  USER_CHANGE_PASSWORD_REQUEST,
  USER_CHANGE_PASSWORD_SUCCESS,
  USER_CHANGE_PASSWORD_FAIL,
  USER_NEW_CARD_BILLING_REQUEST,
  USER_NEW_CARD_BILLING_SUCCESS,
  USER_NEW_CARD_BILLING_FAIL,
  GET_USER_DETAIL_REQUEST,
  GET_USER_DETAIL_SUCCESS,
  GET_USER_DETAIL_FAIL,
  UPDATE_USER_DETAIL_REQUEST,
  UPDATE_USER_DETAIL_SUCCESS,
  UPDATE_USER_DETAIL_FAIL,
  USER_NEEDS_DETAIL_LIST_REQUEST,
  USER_NEEDS_DETAIL_LIST_SUCCESS,
  USER_NEEDS_DETAIL_LIST_FAIL,
  USER_UDATE_CARD_BILLING_REQUEST,
  USER_UDATE_CARD_BILLING_SUCCESS,
  USER_UDATE_CARD_BILLING_FAIL,
  USER_ACTION_LIST_LIST_SUCCESS,
  CREATE_WISHLIST_SUCCESS,
  PLACE_ORDER_SUCCESS,
  PLACE_ORDER_FAIL,
  STRIPE_TOKEN_SUCCESS,
  STRIPE_TOKEN_FAIL,
  ACTION_LIST_ON_NEED_FOR_MSG_SUCCESS,
  ACTION_LIST_ON_NEED_FOR_MSG_FAIL,
  GET_USER_ACTION_LIST_CHATS_SUCCESS,
  GET_USER_ACTION_LIST_CHATS_FAIL,
  GET_MY_ACTIONS_RECORDS_SUCCESS,
  GET_MY_ACTIONS_RECORDS_FAIL,
  GET_REPORT_DATA_SUCCESS,
  GET_REPORT_DATA_FAIL,
} from "../Constants/userConstants";

function createListReducer(state = {}, action) {
  switch (action.type) {
    case ADD_GENERAL_INFO:
      return { userCreateList: { ...action.payload } };

    case ADD_PURPOSE:
      return { userCreateList: { ...action.payload } };

    case ADD_DESCRIPTION:
      return { userCreateList: { ...action.payload } };

    case ADD_ADDITIONAL_INFO:
      return { userCreateList: { ...action.payload } };
    case DELETE_LIST:
      return { userCreateList: {} };
    case CREATE_LIST_SUCCESS:
      return { successCreateList: { ...action.payload } };
    case CREATE_LIST_FAIL: {
      return { loading: false, error: action.payload };
    }
    default:
      return state;
  }
}

function userSigninReducer(state = {}, action) {
  switch (action.type) {
    case USER_SIGNIN_REQUEST:
      return { loading: true };
    case USER_SIGNIN_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_SIGNIN_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
}

function userUpdateReducer(state = {}, action) {
  switch (action.type) {
    case USER_UPDATE_REQUEST:
      return { loading: true };
    case USER_UPDATE_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function userRegisterReducer(state = {}, action) {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { loading: true };
    case USER_REGISTER_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_REGISTER_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return { loading: false, userInfo: {} };
    default:
      return state;
  }
}

function userSendOtpReducer(state = {}, action) {
  switch (action.type) {
    case USER_SEND_OTP_REQUEST:
      return { loading: true };
    case USER_SEND_OTP_SUCCESS:
      return { loading: false, userSendOtpInfo: action.payload };
    case USER_SEND_OTP_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function userVerifyOtpReducer(state = {}, action) {
  switch (action.type) {
    case USER_VERIFY_OTP_REQUEST:
      return { loading: true };
    case USER_VERIFY_OTP_SUCCESS:
      return { loading: false, userVerifyOtpInfo: action.payload };
    case USER_VERIFY_OTP_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function userForgotPasswordReducer(state = {}, action) {
  switch (action.type) {
    case USER_FORGOT_PASSWORD_REQUEST:
      return { loading: true };
    case USER_FORGOT_PASSWORD_SUCCESS:
      return { loading: false, userForgotPasswordInfo: action.payload };
    case USER_FORGOT_PASSWORD_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function userResetPasswordReducer(state = {}, action) {
  switch (action.type) {
    case USER_RESET_PASSWORD_REQUEST:
      return { loading: true };
    case USER_RESET_PASSWORD_SUCCESS:
      return { loading: false, userResetPasswordInfo: action.payload };
    case USER_RESET_PASSWORD_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function userChangePasswordReducer(state = {}, action) {
  switch (action.type) {
    case USER_CHANGE_PASSWORD_REQUEST:
      return { loading: true };
    case USER_CHANGE_PASSWORD_SUCCESS:
      return { loading: false, userchangepasswordInfo: action.payload };
    case USER_CHANGE_PASSWORD_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function userCardBillingAddressReducer(state = {}, action) {
  switch (action.type) {
    case USER_NEW_CARD_BILLING_REQUEST:
      return { loading: true };
    case USER_NEW_CARD_BILLING_SUCCESS:
      return { loading: false, usercardBillingAddInfo: action.payload };
    case USER_NEW_CARD_BILLING_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function UserDetailsReducer(state = {}, action) {
  switch (action.type) {
    case GET_USER_DETAIL_REQUEST:
      return { loading: true, error: null };
    case GET_USER_DETAIL_SUCCESS:
      return { loading: false, getuserInfo: action.payload, error: null };
    case GET_USER_DETAIL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function updateUserDetailsReducer(state = {}, action) {
  switch (action.type) {
    case UPDATE_USER_DETAIL_REQUEST:
      return { loading: true };
    case UPDATE_USER_DETAIL_SUCCESS:
      return { loading: false, updateuserInfo: action.payload };
    case UPDATE_USER_DETAIL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function getuserNeedsListReducer(state = {}, action) {
  switch (action.type) {
    case USER_NEEDS_DETAIL_LIST_REQUEST:
      return { loading: true };
    case USER_NEEDS_DETAIL_LIST_SUCCESS:
      return { loading: false, userNeedlistInfo: action.payload };
    case USER_NEEDS_DETAIL_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function userupdateCardBillingAddressReducer(state = {}, action) {
  switch (action.type) {
    case USER_UDATE_CARD_BILLING_REQUEST:
      return { loading: true };
    case USER_UDATE_CARD_BILLING_SUCCESS:
      return { loading: false, userupdatecardBillingAddInfo: action.payload };
    case USER_UDATE_CARD_BILLING_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function userActionSelectedreducer(state = {}, action) {
  switch (action.type) {
    case USER_ACTION_LIST_LIST_SUCCESS:
      return { loading: false, selectedaction: action.payload };
    default:
      return state;
  }
}

function getuserWishListReducer(state = [], action) {
  switch (action.type) {
    case CREATE_WISHLIST_SUCCESS:
      return { loading: false, userWishlistInfo: action.payload };
    default:
      return state;
  }
}

function placeOrderReducer(state = {}, action) {
  switch (action.type) {
    case PLACE_ORDER_SUCCESS:
      return { loading: false, userPlaceOrderInfo: action.payload };
    case PLACE_ORDER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function getStripeTokenrReducer(state = {}, action) {
  switch (action.type) {
    case STRIPE_TOKEN_SUCCESS:
      return { stripeTokenInfo: action.payload };
    case STRIPE_TOKEN_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function getActionListOnNeedReducer(state = {}, action) {
  switch (action.type) {
    case ACTION_LIST_ON_NEED_FOR_MSG_SUCCESS:
      return { userMsgDetails: action.payload };
    case ACTION_LIST_ON_NEED_FOR_MSG_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function getUserActionListChatsReducer(state = {}, action) {
  switch (action.type) {
    case GET_USER_ACTION_LIST_CHATS_SUCCESS:
      return { userChatDetails: action.payload };
    case GET_USER_ACTION_LIST_CHATS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function getMyActionsRecordReducer(state = {}, action) {
  switch (action.type) {
    case GET_MY_ACTIONS_RECORDS_SUCCESS:
      return { myActionRecords: action.payload };
    case GET_MY_ACTIONS_RECORDS_FAIL:
      return { myActionRecords: null };
    default:
      return state;
  }
}

function getReportDetailsReducer(state = {}, action) {
  switch (action.type) {
    case GET_REPORT_DATA_SUCCESS:
      return { reportData: action.payload };
    case GET_REPORT_DATA_FAIL:
      return { reportData: null };
    default:
      return state;
  }
}

export {
  userSigninReducer,
  userRegisterReducer,
  userUpdateReducer,
  userSendOtpReducer,
  userVerifyOtpReducer,
  userForgotPasswordReducer,
  userResetPasswordReducer,
  createListReducer,
  userChangePasswordReducer,
  userCardBillingAddressReducer,
  UserDetailsReducer,
  updateUserDetailsReducer,
  getuserNeedsListReducer,
  userupdateCardBillingAddressReducer,
  userActionSelectedreducer,
  getuserWishListReducer,
  placeOrderReducer,
  getStripeTokenrReducer,
  getActionListOnNeedReducer,
  getUserActionListChatsReducer,
  getMyActionsRecordReducer,
  getReportDetailsReducer,
};
