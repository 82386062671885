import React, { useEffect } from "react";
import Dashboard from "../Screens/Dashboard/Dashboard";

// Purchasing Process Screens
import PurchaseAddToCart from "../Screens/PurchasingProcess/PurchaseAddToCart";
import ProcessedToCheckout from "../Screens/PurchasingProcess/ProcessedToCheckout";
import ProcessedToPayment from "../Screens/PurchasingProcess/ProcessedToPayment";
import Purchase_Success from "../Screens/PurchasingProcess/Purchase_Success";
import SearchData from "../Screens/Search/Search";
//Dashboard
import MyList from "../Screens/Dashboard/MyList";
import EmbedPage from "../Screens/embedCode/EmbedPage";
//Needs List
import ActionListWithNeedsList from "../Screens/ActionUser/ListCreation/ActionListWithNeedsList";

import { Redirect, Route } from "react-router-dom";
import TermAndCondition from "../Screens/TermAndCondition/TermAndCondition";
import Cookies from "js-cookie";
import Login from "../Screens/GuestUser/Login";
import AuthenticationCheck from "../Common/AuthenticationCheck";
import Privacypolicy from "../Screens/PrivacyPolicy/Privacypolicy";
import StepOneEmail from "../Screens/GuestUser/SignupStep/StepOneEmail";
import StepSixAccountCreated from "../Screens/GuestUser/SignupStep/StepSixAccountCreated";
import StepFiveNumberVerify from "../Screens/GuestUser/SignupStep/StepFiveNumberVerify";
import StepFourNumber from "../Screens/GuestUser/SignupStep/StepFourNumber";
import StepThreeName from "../Screens/GuestUser/SignupStep/StepThreeName";
import StepTwoPassword from "../Screens/GuestUser/SignupStep/StepTwoPassword";
import ForgotCompleted from "../Screens/GuestUser/ForgotCompleted";
import PublicActionList from "../Screens/GuestUser/PublicActionList";
import ResetPassword from "../Screens/GuestUser/ResetPassword";
import ForgotPasswordSuccess from "../Screens/GuestUser/ForgotPasswordSuccess";
import ForgotPassword from "../Screens/GuestUser/ForgotPassword";

const RoutingWithoutlogin = (props) => {
  console.log("abc", Cookies.get("token"));
  return (
    <div>
      {/* <Redirect to="/discover" {...props} /> */}
      <Route
        exact
        path="/discover"
        render={(props) => <Dashboard {...props} />}
      />
      <Route exact path="/" render={(props) => <Dashboard {...props} />} />
      <Route
        exact
        path="/l/:id"
        render={(props) => <ActionListWithNeedsList {...props} />}
      />

      <Route
        exact
        path="/cart/:id"
        render={(props) => <PurchaseAddToCart {...props} />}
      />
      <Route
        exact
        path="/processed-to-payment/:id"
        render={(props) => <ProcessedToPayment {...props} />}
      />
      <Route
        exact
        path="/processed-to-checkout/:id"
        render={(props) => <ProcessedToCheckout {...props} />}
      />
      <Route
        exact
        path="/processed-to-success/:id"
        render={(props) => <Purchase_Success {...props} />}
      />
      <Route
        exact
        path="/term-and-conditionss/"
        render={(props) => <TermAndCondition {...props} />}
      />
      <Route
        exact
        path="/search/:searchData"
        render={(props) => <SearchData {...props} />}
      />
      <AuthenticationCheck
        exact
        path="/login"
        component={Login}
        redirectUrl="/me/create/step1"
      />
      <AuthenticationCheck
        exact
        path="/forgot-password"
        component={ForgotPassword}
        redirectUrl="/me/create/step1"
      />
      <AuthenticationCheck
        exact
        path="/forgot-password-success"
        component={ForgotPasswordSuccess}
        redirectUrl="/me/create/step1"
      />
      <AuthenticationCheck
        exact
        path="/reset-password/:id"
        component={ResetPassword}
        redirectUrl="/me/create/step1"
      />
      <AuthenticationCheck
        exact
        path="/action-list"
        component={PublicActionList}
        redirectUrl="/me/create/step1"
      />
      <AuthenticationCheck
        exact
        path="/forgot-completed"
        component={ForgotCompleted}
        redirectUrl="/me/create/step1"
      />
      <AuthenticationCheck
        exact
        path="/signup/:id"
        component={StepOneEmail}
        redirectUrl="/me/create/step1"
      />
      <AuthenticationCheck
        exact
        path="/signup-password"
        component={StepTwoPassword}
        redirectUrl="/me/create/step1"
      />
      <AuthenticationCheck
        exact
        path="/signup-name"
        component={StepThreeName}
        redirectUrl="/me/create/step1"
      />
      <AuthenticationCheck
        exact
        path="/signup-number"
        component={StepFourNumber}
        redirectUrl="/me/create/step1"
      />
      <AuthenticationCheck
        exact
        path="/signup-number-verify"
        component={StepFiveNumberVerify}
        redirectUrl="/me/create/step1"
      />
      <AuthenticationCheck
        exact
        path="/signup-completed"
        component={StepSixAccountCreated}
        redirectUrl="/me/create/step1"
      />
      <AuthenticationCheck
        exact
        path="/signup"
        component={StepOneEmail}
        redirectUrl="/me/create/step1"
      />
      <AuthenticationCheck
        exact
        path="/privacy/"
        component={Privacypolicy}
        redirectUrl="/me/create/step1"
      />
      <AuthenticationCheck
        exact
        path="/terms/"
        component={TermAndCondition}
        redirectUrl="/me/create/step1"
      />
    </div>
  );
};

export default RoutingWithoutlogin;
