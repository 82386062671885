import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import CommonRoute from "./Routing/CommonRoute";
import GuestUserRoute from "./Routing/GuestUserRoutes";
import RoutingWithoutlogin from "./Routing/RoutingWithoutlogin";
import Cookies from "js-cookie";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import EmbedCodeRoute from "./Routing/EmbedCodeRoute";

const App = () => {
  const userSignin = useSelector((state) => state.userSignin);
  const [token, setToken] = useState();
  const { userInfo } = userSignin;
  console.log("test frontend ci cd  28/10/21");
  useEffect(() => {
    setToken(userInfo?.token);
  }, [userInfo]);

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/embed" component={EmbedCodeRoute} />
          <Route exact path="/" component={CommonRoute} />

          {token || Cookies.get("token") ? (
            <>
              <Route path="/" component={GuestUserRoute} />
              {/* <Route exact path="/discover" component={GuestUserRoute} /> */}
            </>
          ) : (
            <>
              <Route path="/" component={RoutingWithoutlogin} />
              {/* <Route exact path="/discover" component={RoutingWithoutlogin} /> */}
            </>
          )}
          <Route exact path="/*" component="Page not found" />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
