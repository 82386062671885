import React, { useEffect } from 'react';
import WhiteLogo from '../../../assets/images/logo_white.png';
import Person from '../../../assets/images/person.png';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { logout, getUserDetailsInfo } from '../../../Store/Actions/userActions';
import BottomTab from '../../../Common/BottomTab';
import { ToastContainer, toast } from 'react-toastify';

const StepSixAccountCreated = (props) => {
  const dispatch = useDispatch();
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const user_Info = JSON.parse(localStorage.getItem('userInfo'))

  useEffect(() => {
    if (userInfo) {
      dispatch(getUserDetailsInfo(user_Info?.userId));
    }
  }, []);

  function logoutAll(e) {
    e.preventDefault();
    Cookies.remove('token');
    dispatch(logout());
    props.history.push('/');
  }

  function createActionList(e) {
    if (userInfo?.allowCreateActionLIst) {
      e.preventDefault();
      props.history.push('/me/create/step1');
    } else {
      toast.error("You don't have permissions to create ActionList...!")
    }
  }

  function dashboardRedirect(e) {
    e.preventDefault();
    props.history.push('/discover');
  }

  return (
    <div className='content1'>
      <div className='congratulation_page'>
        {/*<div className="header bg_blue">
          <nav className="navbar">
              <a className="navbar-brand" href="#.">
                <img src={WhiteLogo} alt=""/>
              </a>
              <a href="#.">
                <img src={Person} alt="" />
              </a>
          </nav>
        </div>*/}
        <div className='mobile-header bg_blue'>
          <nav className='navbar'>
            <a className='navbar-brand' href='#.'>
              <img src={WhiteLogo} alt='' />
            </a>
            <a href='#.' onClick={(e) => logoutAll(e)}>
              <img src={Person} alt='' />
            </a>
          </nav>
        </div>
        <div className='content congratulation_content cogratulation-details'>
          <h2 className='heading2 pb-3'>Congratulations!</h2>
          <p>Now you are ready to create your own ActionList!</p>
          <button
            type='button'
            // className='btn_primary'
            className={userInfo?.allowCreateActionLIst ? 'btn btn-prime desktop-view' : 'btn btn-prime desktop-view disabled-btnbg'}

            onClick={(e) => createActionList(e)}
          >
            Create an ActionList
          </button>
          <button
            type='button'
            className='btn-extra'
            onClick={(e) => dashboardRedirect(e)}
          >
            Explore
          </button>
        </div>
        <br />
        <br />
        <BottomTab />
      </div>
      <ToastContainer autoClose={3000} />
    </div>
  );
};

export default StepSixAccountCreated;
