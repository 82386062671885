import React, { useState, useEffect } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import Back from '../../../assets/images/back.png';
import AxiosApi from '../../../Config/axios-api';
import { ToastContainer, toast} from 'react-toastify';
import '../../../../node_modules/react-toastify/dist/ReactToastify.min.css';
import { stringCapitalize } from '../../../services/stringModify';

export default function ContactInfoList(props) {
  const dispatch = useDispatch();
  const [place, setPlace] = useState('');
  const [postcode, setPostalCode] = useState();
  useEffect(() => {
    var value = props?.selectedaction;
    setPlace(value?.place);
    setPostalCode(value?.pincode);
  }, [props?.selectedaction]);
  const handleChange = (place) => {
    setPlace(place);
  };

  const handleSelect = (place) => {
    setPlace(place);
  };
  const updatelist = async () => {
    var obj = {};
    obj.pincode = postcode;
    obj.place = place;
    obj.actionListId = props.actionId;
    const { data } = await AxiosApi.post(
      '/actionList/updateMailingAddress',
      obj
    );
    console.log('data', data);
    if (data.success) {
      toast.success(stringCapitalize(data?.msg))
      props.listUpdated();
    }
  };
  return (
    <div
      class='tab-pane fade show active'
      id='contact-information'
      role='tabpanel'
      aria-labelledby='contact-information-tab'
    >
      <div class='mobile-header-inner'>
        <nav class='navbar'>
          <a onClick={props.backdata} class='back'>
            <img src={Back} />
          </a>
          <label class='navbar-brand mx-auto middle-label'>
            Contact information
          </label>
        </nav>
      </div>
      <h2 class='heading2'>Mailing address</h2>
      <form class='mailing-form'>
        <h5 class='form-heading'>Organizer shipping details</h5>
        <div class='form-group row'>
          <div class='col-xl-6 col-lg-6'>
            <label for='postcode' class='label'>
              Your postcode
            </label>
            <input
              type='number'
              class='form-control'
              id='postcode'
              placeholder='123456'
              min='0'
              onChange={(e) => setPostalCode(e.target.value)}
              value={postcode}
            />
          </div>
          <div class='col-xl-6 col-lg-6'>
            <label for='mailingaddress' class='label'>
              Your mailing address
            </label>
            {/* <input
              type='text'
              class='form-control'
              id='mailingaddress'
              placeholder=''
              value='Red st., 65/316, Texas, USA'
            /> */}
            <PlacesAutocomplete
              value={place}
              onChange={handleChange}
              onSelect={handleSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div>
                  <input
                    {...getInputProps({
                      placeholder: 'e.x.: Red st., 65/316, Texas, USA',
                      className: 'form-control',
                      id: 'place',
                      name: 'place',
                    })}
                  />
                  <div className='autocomplete-dropdown-container'>
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? 'suggestion-item--active'
                        : 'suggestion-item';
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                      return (
                        <div
                          key={suggestion.placeId}
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </div>
        </div>
        <button
          type='button'
          disabled={(postcode && place) ? false : true}
          className='btn btn-prime'
          style={{ width: '50%', float: 'left', marginBottom: 200 }}
          onClick={updatelist}
        >
          Save changes
        </button>
      </form>
      <ToastContainer autoClose={3000}/>
    </div>
  );
}
