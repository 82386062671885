import React, { useState, useEffect, useRef } from "react";
import RightAngle from "../../../assets/images/right_angle_gray.png";
import PriorityMark from "../../../assets/images/priority_mark.png";
import Draft from "../../../assets/images/draft.png";
import Location from "../../../assets/images/location.svg";
import Clock from "../../../assets/images/clock.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getNeedsList,
  deleteNeedsById,
} from "../../../Store/Actions/userActions";
import moment from "moment";
import Buysvg from "../../../Common/SvgImage/Buysvg";
import Givesvg from "../../../Common/SvgImage/Givesvg";
import SvgforDo from "../../../Common/SvgImage/SvgforDo";
import kebab from "../../../assets/images/kebab.png";
import duplicate from "../../../assets/images/duplicate.png";
import Delete from "../../../assets/images/delete.png";
import NeedsCreationNeedInfoPreview from "./NeedsCreationNeedInfoPreview";
import NeedCreationNeedInfoEditing from "./NeedCreationNeedInfoEditingFreeAccountRestrictions";
import AxiosApi from "../../../Config/axios-api";
import WriteNeedForAdd from "../WriteNewNeed";
import { stringCapitalize } from "../../../services/stringModify";

const NeedCreationExpandedListView = ({
  openDraft,
  params,
  onupdateList,
  needForAdd,
  createNeed,
  match,
  history,
  liveTitleAdd,
}) => {
  let [needsList, setNeedsList] = useState([]);
  let [needInfoEdit, setNeedInfoEdit] = useState(false);
  let [infoPreview, setInfoPreview] = useState(false);
  const dispatch = useDispatch();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const getuserNeedsList = useSelector((state) => state.getuserNeedsList);
  const { loading, userNeedlistInfo, error } = getuserNeedsList;
  const [selectedNeedData, setselectedNeedData] = useState("");
  let [selectedItem, setSelecteditem] = useState();
  let [needName, setNeedName] = useState("");
  let [needType, setNeedType] = useState("");

  let inputRef = useRef(null);

  useEffect(() => {
    setNeedType(needForAdd);
  }, [needForAdd]);

  useEffect(() => {
    getNeedsListgData();
  }, [params, needForAdd, infoPreview, needInfoEdit]);

  useEffect(() => {
    if (userNeedlistInfo) {
      if (userNeedlistInfo.length > needsList.length) {
        setNeedType("");
        setNeedName("");
      }

      setNeedsList(userNeedlistInfo);
    }
    if (needForAdd) {
      inputRef.current.focus();
    }
  }, [userNeedlistInfo, needForAdd, infoPreview, needInfoEdit]);

  const getNeedsListgData = () => {
    dispatch(getNeedsList(params));
  };

  function func(m, item) {
    setNeedsList(needsList);

    switch (m) {
      case "openNeedEditInfo":
        return setNeedInfoEdit(true), setSelecteditem(item);
      case "openInfoPreview":
        return setInfoPreview(true), setSelecteditem(item);
      case "closeNeedEditInfo":
        return setNeedInfoEdit(false), setNeedsList(needsList);
      case "closeInfoPreview":
        return setInfoPreview(false), setNeedsList(needsList);
      default:
        return 0;
    }
    getNeedsListgData();
  }

  const deleteNeeds = async (item) => {
    const { data } = await AxiosApi.delete(
      "/actionList/removeNeedById/" + item.needId,
      {}
    );
    if (data.msg === "need removed.") {
      getNeedsListgData();
    }
  };

  const duplicateNeeds = async (item) => {
    const { data } = await AxiosApi.get(
      "/actionList/createDuplicateNeeds/" + item.needId,
      {}
    );
    // console.log('data.msg', data.msg);
    if (data.msg === "duplicate needs created!") {
      getNeedsListgData();
    }
  };

  const handleWriteNeed = (e) => {
    const { value } = e.target;
    setNeedName(value);
  };

  const submitNeed = () => {
    createNeed({
      title: needName,
      qtyNeeded: 0,
      needType: needType,
      needStatus: "draft",
    });
  };

  const checkNeedDeleteOrDuplicate = () => {
    getNeedsListgData();
  };

  return (
    <div>
      {needsList &&
        needsList.length > 0 &&
        needsList.map((item, index) => {
          return (
            <div
              className="existing-need-row expanded mainUI"
              onClick={(e) => (
                func(
                  item.needStatus == "draft"
                    ? "openNeedEditInfo"
                    : "openInfoPreview",
                  item
                ),
                setselectedNeedData(item.needId)
              )}
              key={`rows-${index}`}
            >
              {" "}
              {/* <div class='column-kebab'>
                <div class='dropdown'>
                  <button
                    type='button'
                    class='btn btn-primary dropdown-toggle'
                    data-toggle='dropdown'
                    onClick={(e) => e.stopPropagation()}
                  >
                    <img src={kebab} />
                  </button>
                  <div class='dropdown-menu'>
                    <a
                      class='dropdown-item'
                      onClick={(e) => (
                        e.stopPropagation(), duplicateNeeds(item)
                      )}
                    >
                      <img src={duplicate} alt='duplicate' />
                      <label>Duplicate need</label>
                    </a>
                    <a
                      class='dropdown-item delete'
                      onClick={(e) => (e.stopPropagation(), deleteNeeds(item))}
                    >
                      <img src={Delete} alt='delete' />
                      <label>Delete need</label>
                    </a>
                  </div>
                </div>
              </div> */}
              <div className="column column-first">
                {console.log(item.location + " " + index)}
                <div className="need-title">
                  <div
                    className={
                      item.qtyOrdered === item.qtyNeeded &&
                      item?.needStatus === "draft"
                        ? "draft"
                        : "row1"
                    }
                  >
                    {item.needType === "buy" && <Buysvg />}
                    {item.needType === "give" && <Givesvg />}
                    {item.needType === "do" && <SvgforDo />}
                    {stringCapitalize(item.title)}
                    {userInfo?.userType === "organization" && item.priority && (
                      <img
                        src={PriorityMark}
                        className="priority-badge"
                        alt=""
                      />
                    )}
                  </div>
                  <div className="row2">{item.description}</div>
                </div>
              </div>
              {item.completedBy && item.needType == "do" ? (
                <div className="column column-location column-time">
                  <div className="wrapper">
                    <div className="left d-inline-block">
                      <img src={Clock} alt="" />
                    </div>
                    <div className="right d-inline-block">
                      <label>
                        Complete-by: {moment(item.completedBy).format("lll")}
                      </label>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="column column-location column-time">
                  <div className="wrapper">
                    <div className="left d-inline-block">
                      {/* <img src={Clock} alt="" /> */}
                    </div>
                    <div className="right d-inline-block">
                      <label>--</label>
                    </div>
                  </div>
                </div>
              )}
              {item?.location ||
              item.needType == "give" ||
              item.needType == "do" ? (
                <div className="column column-location">
                  <div className="wrapper">
                    <div className="left d-inline-block">
                      {item?.location && <img src={Location} alt="Location" />}
                    </div>
                    <div className="right d-inline-block">
                      <label>
                        {/* {item.place}{' '} */}
                        {item.location && (
                          <>
                            <span>({item.location})</span>
                          </>
                        )}
                      </label>
                    </div>
                  </div>
                </div>
              ) : (
                !item?.location ||
                item.needType == "do" ||
                item.needType == "buy" && (
                  <div className="column column-location">
                    <div className="wrapper">
                      <div className="left d-inline-block">
                        {item?.location && (
                          <img src={Location} alt="Location" />
                        )}
                      </div>
                      <div className="right d-inline-block">
                        <label>
                          {/* {item.place}{' '} */}

                          <>
                            <span>--</span>
                          </>
                        </label>
                      </div>
                    </div>
                  </div>
                )
              )}
              {item?.needType === "buy" && item?.cost && (
                <div className="column column-price">
                  <label>${item?.cost}</label>
                </div>
              )}
              {item?.needType === "buy" && !item?.cost && (
                <div className="column column-price">
                  <label>--</label>
                </div>
              )}
              {item.needType != "" && (
                <div className="column column-work-complete">
                  <label>0 of {item.qtyNeeded}</label>
                </div>
              )}
              <div className="column column-last ">
                <button
                  type="button"
                  data-toggle="collapse"
                  data-target=".multi-collapse"
                  aria-expanded="false"
                  aria-controls="multiCollapseExample1"
                >
                  <img src={RightAngle} alt="" />
                </button>
              </div>
            </div>
          );
        })}
      <div className="web_view">
        <WriteNeedForAdd
          needType={needType}
          needName={needName}
          handleChange={(val) => handleWriteNeed(val)}
          handleSubmit={() => submitNeed()}
          inputRef={inputRef}
          liveTitleAdd={liveTitleAdd}
        />
      </div>
      {infoPreview && (
        <NeedsCreationNeedInfoPreview
          func={func}
          selectedItem={selectedItem}
          selectedNeedData={selectedNeedData}
          checkNeedDeleteOrDuplicate={() => checkNeedDeleteOrDuplicate()}
        />
      )}
      {needInfoEdit && (
        <NeedCreationNeedInfoEditing
          func={func}
          selectedItem={selectedItem}
          onupdateList={onupdateList}
          duplicatemobile={(id) => duplicateNeeds(id)}
          deleteneedmobile={(id) => deleteNeeds(id)}
          checkNeedDeleteOrDuplicate={() => checkNeedDeleteOrDuplicate()}
          match={match}
          history={history}
        />
      )}
    </div>
  );
};

export default NeedCreationExpandedListView;
