import React, { useEffect, useState } from "react";
import PrivateGuestUserRoute from "../Common/PrivateGuestUserRoute";

// Route constants
import {
  ProfileRoute,
  ProfileAccSettingsRoute,
  ProfilePaymentMethodsRoute,
  ProfileEmailNotificationsRoute,
  ProfileChangePasswordRoute,
} from "./RouteConstants";

/*@ListCreationRoutes*/
import ListCreationGeneralInfo from "../Screens/ActionUser/ListCreation/ListCreationGeneralInfo";
import ListCreationPurpose from "../Screens/ActionUser/ListCreation/ListCreationPurpose";
import ListCreationDescription from "../Screens/ActionUser/ListCreation/ListCreationDescription";
import ListCreationDescriptionFullPreview from "../Screens/ActionUser/ListCreation/ListCreationDescriptionFullPreview";
import ListCreationAdditionalInfo from "../Screens/ActionUser/ListCreation/ListCreationAdditionalInfo";
import ListCreationSelectTheme from "../Screens/ActionUser/ListCreation/ListCreationSelectTheme";
import ListCreationSuccessScreen from "../Screens/ActionUser/ListCreation/ListCreationSuccessScreen";
import StepSixAccountCreated from "../Screens/GuestUser/SignupStep/StepSixAccountCreated";
import ListCreationPreviewSunshine from "../Screens/ActionUser/ListCreation/Preview/ListCreationPreviewSunshine";
import ListCreationPreviewWaterpolo from "../Screens/ActionUser/ListCreation/Preview/ListCreationPreviewWaterpolo";
import ListCreationPreviewGreenery from "../Screens/ActionUser/ListCreation/Preview/ListCreationPreviewGreenery";
import ListCreationPreviewBlossom from "../Screens/ActionUser/ListCreation/Preview/ListCreationPreviewBlossom";
import ListCreationPreviewFair from "../Screens/ActionUser/ListCreation/Preview/ListCreationPreviewFair";
import ListCreationPreviewDawn from "../Screens/ActionUser/ListCreation/Preview/ListCreationPreviewDawn";
/*@NeedsCreationRoutes*/
import NeedsCreationEmptyList from "../Screens/ActionUser/NeedsCreation/NeedsCreationEmptyList";

import Dashboard from "../Screens/Dashboard/Dashboard";
// Profile Screens
import Profile from "../Screens/Profile/Profile";
import AccountSettings from "../Screens/Profile/AccountSettings";
import PaymentMethods from "../Screens/Profile/PaymentMethods";
import EmailNotifications from "../Screens/Profile/EmailNotifications";
import PasswordReset from "../Screens/Profile/PasswordReset";

// Purchasing Process Screens
import PurchaseAddToCart from "../Screens/PurchasingProcess/PurchaseAddToCart";
import ProcessedToCheckout from "../Screens/PurchasingProcess/ProcessedToCheckout";
import ProcessedToPayment from "../Screens/PurchasingProcess/ProcessedToPayment";
import Purchase_Success from "../Screens/PurchasingProcess/Purchase_Success";

// Messages Screens
import MessageList from "../Screens/Messages/MessagesList";

// My Actions List Screens
import MyActionsList from "../Screens/MyActions/myActionslist";

//Dashboard
import MyList from "../Screens/Dashboard/MyList";

//Privacypolicy and Term and Conditions
import Privacypolicy from "../Screens/PrivacyPolicy/Privacypolicy";
import TermAndCondition from "../Screens/TermAndCondition/TermAndCondition";

//Needs List
import ActionListWithNeedsList from "../Screens/ActionUser/ListCreation/ActionListWithNeedsList";

// Action List Setting
import SettingMenu from "../Screens/ActionUser/ListSetting/SettingMenu";

// Email Verification
import EmailVerification from "../Screens/GuestUser/SignupStep/EmailVerification";

// Action List Manage creation
import ManageAvtionList from "../Screens/ActionUser/ManageList/ManageAvtionList";
import Contactus from "../Screens/Contactus/Contactus";
import Error403 from "../Screens/ErrorScreen/Error403";
import Error404 from "../Screens/ErrorScreen/Error404";
import Error500 from "../Screens/ErrorScreen/Error500";
import SearchData from "../Screens/Search/Search";
import { RenderReport } from "../Screens/ActionUser/ReportView";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import { Redirect } from "react-router";

const UserRoutes = (props) => {
  const userSignin = useSelector((state) => state.userSignin);
  const [token, setToken] = useState();
  const [verified, setVerified] = useState();

  const { userInfo } = userSignin;

  useEffect(() => {
    // setToken(userInfo?.token)
    setVerified(userInfo?.verified);
  }, [userInfo]);

  return (
    <div>
      {/* {(verified === false || Cookies.get('verified') === false) ? ( */}
      <PrivateGuestUserRoute
        path="/email-verification"
        component={EmailVerification}
        redirectUrl="/login"
      />
      {/* ) : ( */}
      <>
        <PrivateGuestUserRoute
          path="/me/create/step1"
          component={ListCreationGeneralInfo}
          redirectUrl="/login"
        />
        <PrivateGuestUserRoute
          path="/me/create/step2"
          component={ListCreationPurpose}
          redirectUrl="/login"
        />
        <PrivateGuestUserRoute
          path="/me/create/step3"
          component={ListCreationDescription}
          redirectUrl="/login"
        />
        <PrivateGuestUserRoute
          path="/me/create/step3-full-preview"
          component={ListCreationDescriptionFullPreview}
          redirectUrl="/login"
        />
        <PrivateGuestUserRoute
          path="/me/create/step4"
          component={ListCreationAdditionalInfo}
          redirectUrl="/login"
        />
        <PrivateGuestUserRoute
          path="/me/create/step5"
          component={ListCreationSelectTheme}
          redirectUrl="/login"
        />
        <PrivateGuestUserRoute
          path="/me/create/step1-success-screen"
          component={ListCreationSuccessScreen}
          redirectUrl="/login"
        />
        <PrivateGuestUserRoute
          path="/me/create/preview/sunshine"
          component={ListCreationPreviewSunshine}
          redirectUrl="/login"
        />
        <PrivateGuestUserRoute
          path="/me/create/preview/waterpolo"
          component={ListCreationPreviewWaterpolo}
          redirectUrl="/login"
        />
        <PrivateGuestUserRoute
          path="/me/create/preview/greenery"
          component={ListCreationPreviewGreenery}
          redirectUrl="/login"
        />
        <PrivateGuestUserRoute
          path="/me/create/preview/blossom"
          component={ListCreationPreviewBlossom}
          redirectUrl="/login"
        />
        <PrivateGuestUserRoute
          path="/l/:id"
          component={ActionListWithNeedsList}
          redirectUrl="/login"
        />

        <PrivateGuestUserRoute
          path="/me/create/preview/fair"
          component={ListCreationPreviewFair}
          redirectUrl="/login"
        />
        <PrivateGuestUserRoute
          path="/me/create/preview/dawn"
          component={ListCreationPreviewDawn}
          redirectUrl="/login"
        />
        <PrivateGuestUserRoute
          path="/account-created"
          component={StepSixAccountCreated}
          redirectUrl="/login"
        />
        <PrivateGuestUserRoute
          path="/needs/:id"
          component={NeedsCreationEmptyList}
          redirectUrl="/login"
        />
        <PrivateGuestUserRoute
          path="/reports/:id"
          component={RenderReport}
          redirectUrl="/login"
        />
        <PrivateGuestUserRoute
          path="/discover"
          component={Dashboard}
          redirectUrl="/login"
        />
        {/* <PrivateGuestUserRoute
          path="/"
          component={Dashboard}
          redirectUrl="/login"
        /> */}
        <PrivateGuestUserRoute
          exact
          path={ProfileRoute}
          component={Profile}
          redirectUrl="/login"
        />
        <PrivateGuestUserRoute
          exact
          path={ProfileAccSettingsRoute}
          component={AccountSettings}
          redirectUrl="/login"
        />
        <PrivateGuestUserRoute
          exact
          path={ProfilePaymentMethodsRoute}
          component={PaymentMethods}
          redirectUrl="/login"
        />
        <PrivateGuestUserRoute
          exact
          path={ProfileEmailNotificationsRoute}
          component={EmailNotifications}
          redirectUrl="/login"
        />
        <PrivateGuestUserRoute
          exact
          path={ProfileChangePasswordRoute}
          component={PasswordReset}
          redirectUrl="/login"
        />
        <PrivateGuestUserRoute
          exact
          path="/me/lists"
          component={MyList}
          redirectUrl="/login"
        />
        <PrivateGuestUserRoute
          exact
          path="/cart/:id"
          component={PurchaseAddToCart}
          redirectUrl="/login"
        />
        <PrivateGuestUserRoute
          exact
          path="/processed-to-payment/:id"
          component={ProcessedToPayment}
          redirectUrl="/login"
        />
        <PrivateGuestUserRoute
          exact
          path="/processed-to-checkout/:id"
          component={ProcessedToCheckout}
          redirectUrl="/login"
        />
        <PrivateGuestUserRoute
          exact
          path="/processed-to-success/:id"
          component={Purchase_Success}
          redirectUrl="/login"
        />
        <PrivateGuestUserRoute
          path="/setting/:id"
          component={SettingMenu}
          redirectUrl="/login"
        />
        <PrivateGuestUserRoute
          path="/manage/:id"
          component={ManageAvtionList}
          redirectUrl="/login"
        />
        <PrivateGuestUserRoute
          path="/messageList"
          component={MessageList}
          redirectUrl="/login"
        />
        <PrivateGuestUserRoute
          path="/contactus/"
          component={Contactus}
          redirectUrl="/login"
        />
        <PrivateGuestUserRoute
          path="/error403/"
          component={Error403}
          redirectUrl="/login"
        />
        <PrivateGuestUserRoute
          path="/error404/"
          component={Error404}
          redirectUrl="/login"
        />
        <PrivateGuestUserRoute
          path="/error500/"
          component={Error500}
          redirectUrl="/login"
        />
        <PrivateGuestUserRoute
          path="/search/:searchData"
          component={SearchData}
          redirectUrl="/login"
        />
        <PrivateGuestUserRoute
          path="/me/actions/"
          component={MyActionsList}
          redirectUrl="/login"
        />
        <PrivateGuestUserRoute
          path="/privacy/"
          component={Privacypolicy}
          redirectUrl="/login"
        />
        <PrivateGuestUserRoute
          path="/terms/"
          component={TermAndCondition}
          redirectUrl="/login"
        />
        {/* <Redirect to="/discover" /> */}
      </>
      {/* )} */}
    </div>
  );
};

export default UserRoutes;
