import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../style.css";
import "../responsive.css";
import LeftPlane from "../../../Common/LeftPlane";
import MobileHeaderTwo from "../../../Common/MobileHeaderTwo";
import DesktopHeaderTwo from "../../../Common/UserDesktopHeaderTwo";
import ManageBuyList from "./ManageBuyList";
import ManageDoList from "./ManageDoList";
// import share from "../../../assets/images/share.png";
import close2 from "../../../assets/images/close2.png";
import back_black from "../../../assets/images/back_black.png";
import edit from "../../../assets/images/edit.png";
import profile_pic from "../../../assets/images/profile_pic.png";
import profile_pic_alt from "../../../assets/images/profile_pic_alt.png";
import bill from "../../../assets/images/bill.png";
import close_gray from "../../../assets/images/close_gray.png";
import tick_gray from "../../../assets/images/tick_gray.png";
import ManageGiveList from "./ManageGiveList";
import profile_pic3 from "../../../assets/images/profile_pic3.png";
import profile_pic2 from "../../../assets/images/profile_pic2.png";
import send from "../../../assets/images/send.png";
import profile_pic_alt2 from "../../../assets/images/profile_pic_alt2.svg";
import back from "../../../assets/images/back.png";
import view_enabled from "../../../assets/images/view_enabled.svg";
import share from "../../../assets/images/share.svg";
import filter from "../../../assets/images/filter.png";
import chatImg from "../../../assets/images/chat.png";
import emailImg from "../../../assets/images/email.svg";
import phoneImg from "../../../assets/images/phone.svg";
import giveImg from "../../../assets/images/give.png";
import moment from "moment";
import axios from "axios";
import AxiosApi from "../../../Config/axios-api";
import Close from "../../../assets/images/close2.png";
import {
  getNeedsList,
  postLastMessage,
} from "../../../Store/Actions/userActions";
import { postOrderStatus, renderStatus } from "../postOrderStatus";
import { ToastContainer, toast } from "react-toastify";
import "../../../../node_modules/react-toastify/dist/ReactToastify.min.css";
import { RenderReport } from "../ReportView";
import ShareModal from "../../../Common/ShareModal";
import Button from "react-bootstrap/esm/Button";
import { Modal } from "react-bootstrap";
import CsvDownload from "react-json-to-csv";
import ellipse from "../../../assets/images/Ellipse 163.png";
import { stringCapitalize } from "../../../services/stringModify";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import NeedCreationNeedInfoEditingFreeAccountRestrictions from "../NeedsCreation/NeedCreationNeedInfoEditingFreeAccountRestrictions";

const ChatAPI = require("twilio-chat");
export default function NeedsCreationEmptyList(props) {
  console.log(window.location.origin)
  const baseUrl = window.location.origin

  console.log(baseUrl);
  let yesterdayCount = 0;
  let todayCount = 0;
  let dayCount = 0;
  let uniqueDate = "";
  let history = useHistory();

  const reportDetails = useSelector((state) => state.getReportInfo?.reportData);
  const imgBaseUrl = "http://api.actionlist.com/static/images/";
  const dispatch = useDispatch();
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  let [needsFilters, setFilters] = useState(false);
  const [scrolltop, setScrollTop] = useState(false);
  const [activelink, setActiveLisnk] = useState("buy");
  const [selectedneedList, setNeedList] = useState();
  const [selectedNeedInfo, setNeedInfo] = useState();
  const [messages, setMessages] = useState([]);
  const [text, setText] = useState("");
  const [channel, setChannel] = useState(null);
  const [userName, setUserName] = useState(userInfo?.userId);
  const [loginUserName, setLoginUserName] = useState("");
  const [chatNeedId, setChatNeedId] = useState("");
  const [chatActionListId, setChatActionListId] = useState("");
  const [chatUserId, setChatUserId] = useState("");
  const [lastMsg, setLastMsg] = useState("");
  const [chatChannelId, setChatChannelId] = useState("");
  const [userPersonalDetails, setUserPersonalDetails] = useState("");
  const [isCancel, setIsCancel] = useState(false);
  const [isMarkasDone, setIsMarkasDone] = useState(false);
  const [userId, setUserId] = useState("");

  const [chatId, setChatId] = useState("");
  const [donationval, setDonationval] = useState(0);
  let [params, setParams] = useState(props.match.params.id);
  const [loading, setLoading] = useState(false);
  let [needsList, setNeedsList] = useState([]);
  let [needs, setNeeds] = useState([]);
  let scrollDiv = useRef(null);

  const getuserNeedsList = useSelector((state) => state.getuserNeedsList);
  const { loading: needsLoading, userNeedlistInfo, error } = getuserNeedsList;
  const [selectedNeed, setSelectedNeeds] = useState([]);
  const [selectedaction, setselectedaction] = useState();
  const [searchTxt, setSearchTxt] = useState();
  const [startSearching, setStartSearching] = useState(false);
  const getNeedsListgData = () => {
    dispatch(getNeedsList(params));
  };
  const [isNotNeededConfirm, setIsNotNeededConfirm] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [userImg, setUserImg] = useState("");
  const [filteredNeedsList, setFilteredNeedsList] = useState([]);
  const [download, setDownload] = useState([]);
  const [editInfo, setEditinfo] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [commissionPercentageRateData, setCommissionPercentageRateData] =
    useState(null);
  const [allMesagesItem, setAllMesagesItem] = useState();

  const totalAmount = selectedNeed.reduce(
    (subTotal, item) => subTotal + item?.items?.amount,
    0
  );

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (userNeedlistInfo) {
      setNeeds(userNeedlistInfo);
    }
  }, [userNeedlistInfo]);

  useEffect(() => {
    getNeedsListgData();
    getdonateList();
    console.log("--", userInfo);
  }, [props.activelink]);

  useEffect(async () => {
    setselectedaction(JSON.parse(localStorage.getItem("selectedList")));
    const response = await AxiosApi.get("/admin/commissionPercentageRate", {
      headers: { token: userInfo?.token },
    });
    if (response?.data) {
      response?.data?.filter((item) => {
        if (item?.commissionFor === "donation") {
          let commData = { ...item };
          setCommissionPercentageRateData(commData);
        }
      });
    }
  }, []);

  const getdonateList = async () => {
    var userIddata = JSON.parse(localStorage.getItem("userInfo"));
    var urlstr = "/order/getOrdersByActionListId/" + props.match.params.id;

    const { data } = await AxiosApi.get(urlstr);
    // console.log(">>>-->", data);
    setNeedsList(data);
    setFilteredNeedsList(data);
    setDownload(data);
  };

  function filters() {
    setFilters(!needsFilters);
  }

  const updatescroll = (val) => {
    if (!scrolltop && val > 120) {
      setScrollTop(true);
    } else if (scrolltop && val < 90) {
      setScrollTop(false);
    }
  };

  const openselectedModal = (data, item) => {
    if (data === "buy") {
      window.$("#manageInfoPreviewModal").modal("show");
      setEditinfo(false);
    } else if (data === "give") {
      window.$("#manageInfoPreviewModalgive").modal("show");
      setEditinfo(false);
    } else if (data === "do") {
      window.$("#manageInfoPreviewModaldo").modal("show");
      setEditinfo(false);
    } else if (data === "edit") {
      window.$("#manageInfoPreviewModal").modal("hide");
      window.$("#manageInfoPreviewModalgive").modal("hide");
      window.$("#manageInfoPreviewModaldo").modal("hide");
      setEditinfo(true);
      setSelectedItem(item);
    }
  };

  const manageChatModal = (data) => {
    // if (data === 'buy') {
    //   window.$('#manageChatModalbuy').modal('show');
    //   window.$('#manageInfoPreviewModal').modal('hide');
    // } else {
    //   window.$('#manageChatModalbuy').modal('show');
    //   window.$('#manageInfoPreviewModaldo').modal('hide');
    // }

    if (data === "buy") {
      window.$("#manageChatModalbuy").modal("show");
      window.$("#manageInfoPreviewModal").modal("hide");
    } else if (data === "give") {
      window.$("#manageChatModalgive").modal("show");
      window.$("#manageInfoPreviewModalgive").modal("hide");
    } else {
      window.$("#manageChatModaldo").modal("show");
      window.$("#manageInfoPreviewModaldo").modal("hide");
    }
  };

  const closechat = (data) => {
    // if (data === 'buy') {
    //   window.$('#manageChatModalbuy').modal('hide');
    //   window.$('#manageInfoPreviewModal').modal('show');
    // } else {
    //   window.$('#manageChatModalbuy').modal('hide');
    //   window.$('#manageInfoPreviewModaldo').modal('show');
    // }

    if (data === "buy") {
      window.$("#manageChatModalbuy").modal("hide");
      window.$("#manageInfoPreviewModal").modal("show");
    } else if (data === "give") {
      window.$("#manageChatModalgive").modal("hide");
      window.$("#manageInfoPreviewModalgive").modal("show");
    } else {
      window.$("#manageChatModaldo").modal("hide");
      window.$("#manageInfoPreviewModaldo").modal("show");
    }
  };

  const postLastMsg = (msg) => {
    console.log("xyz", { allMesagesItem });
    console.log("xyz", { lastMsg });
    let data = {};
    if (chatId) {
      // data = {
      //   chatId: chatId,
      //   receiverId: chatUserId,
      //   message: [
      //     {
      //       msg: lastMsg,
      //       msgDate: moment(),
      //       sendBy: loginUserName,
      //       read: false,
      //     },
      //   ],
      // };
      data = {
        chatId: chatId,
        actionListId: allMesagesItem.items.actionListId,
        needId: allMesagesItem.items.needId,
        //need login id
        senderId: allMesagesItem.items.needId,
        receiverId: allMesagesItem?.userId,
        channelName: chatChannelId,
        message: {
          msg: msg,
          msgDate: moment(),
          //login user name
          sendBy: userInfo.firstName + " " + userInfo.lastName,
          read: false,
          userId:
            userInfo.userId == allMesagesItem &&
            allMesagesItem.message[0].userId
              ? allMesagesItem.message[0].userId
              : userInfo.userId,
        },
      };
    } else {
      // data = {
      //   actionListId: chatActionListId,
      //   needId: chatNeedId,
      //   senderId: userInfo?.userId,
      //   receiverId: chatUserId,
      //   channelName: chatChannelId,
      //   message: [
      //     {
      //       msg: lastMsg,
      //       msgDate: moment(),
      //       sendBy: loginUserName,
      //       read: false,
      //     },
      //   ],
      // };
      data = {
        actionListId: allMesagesItem.items.actionListId,
        needId: allMesagesItem.items.needId,
        //need login id
        senderId: allMesagesItem.items.needId,
        receiverId: allMesagesItem?.userId,
        channelName: chatChannelId,
        message: {
          msg: msg,
          msgDate: moment(),
          //login user name
          sendBy: userInfo.firstName + " " + userInfo.lastName,
          read: false,
          userId:
            userInfo.userId == allMesagesItem &&
            allMesagesItem.message[0].userId
              ? allMesagesItem.message[0].userId
              : userInfo.userId,
        },
      };
    }

    dispatch(postLastMessage(userInfo.token, data))
      .then((res) => {
        if (res.data) {
        }
        getChatList(allMesagesItem.items.needId);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getToken = async (name) => {
    // const response = await axios.get(
    //   `http://dev107.developer24x7.com:3001/order/twilioToken?username=${name}`
    // );
    const response = await AxiosApi.get(`/order/twilioToken?username=${name}`);
    const { data } = response;
    return data;
  };

  const twilioChat = async (roomName) => {
    console.log("twilioChat room name", roomName);
    setMessages([]);
    let token = "";
    setLoading(true);
    try {
      token = await getToken(userName);
      console.log("twilioChat room name first check token", token);
    } catch {
      console.log("Unable to get token, please reload this page");
    }

    const client = await ChatAPI.Client.create(token);
    console.log("twilioChat room name first check client", client);
    client.on("tokenAboutToExpire", async () => {
      const token = await getToken(userName);

      console.log(
        "twilioChat room name second check token tokenAboutToExpire",
        token
      );
      client.updateToken(token);
    });

    client.on("tokenExpired", async () => {
      const token = await getToken(userName);
      console.log("twilioChat room name third check token tokenExpired", token);

      // console.log("twilioChat room name second check token",token)
      client.updateToken(token);
    });

    client.on("channelJoined", async (channel) => {
      // getting list of all messages since this is an existing channel
      const newMessages = await channel.getMessages();
      console.log(
        "twilioChat room name  check newMessages channelJoined",
        token
      );

      if (channel.channelState.uniqueName === roomName) {
        setMessages(newMessages.items);
        // setLastMsgIndex(newMessages?.items?.channel?.channelState?.lastMessage?.index)
        scrollToBottom();
      }
    });

    try {
      const channel = await client.getChannelByUniqueName(roomName);

      console.log("twilioChat room name first check channeld", channel);
      await joinChannel(channel);
      setChannel(channel);
    } catch (err) {
      try {
        const channel = await client.createChannel({
          uniqueName: roomName,
          friendlyName: roomName,
        });
        console.log("twilioChat room name second check channeld", channel);
        await joinChannel(channel);
        setChannel(channel);
      } catch {
        console.log("Unable to create channel, please reload this page");
      }
    }
  };

  const joinChannel = async (channel) => {
    if (channel.channelState.status !== "joined") {
      await channel.join();
    }

    setChannel(channel);
    setLoading(false);

    channel.on("messageAdded", function (message) {
      handleMessageAdded(message);
    });
  };

  const handleMessageAdded = (message) => {
    setMessages((messages) => [...messages, message]);
    scrollToBottom();
  };

  const scrollToBottom = () => {
    //console.log("object", scrollDiv.current.scrollTop);
    const scrollHeight = scrollDiv?.current?.scrollHeight;
    const height = scrollDiv?.current?.clientHeight;
    const maxScrollTop = scrollHeight - height;
    //console.log("object", scrollHeight + " - " + height + " = " + maxScrollTop);
    if (
      scrollDiv?.current?.scrollTop !== null &&
      scrollDiv?.current?.scrollTop !== undefined &&
      scrollDiv?.current?.scrollTop !== "" &&
      scrollDiv?.current?.scrollTop !== NaN
    ) {
      scrollDiv.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  };

  const sendMessage = (e, isSendButClick) => {
    console.log(
      "abcddd",
      e.keyCode + " == " + " 13 && " + e.shiftKey + " " + text
    );
    console.log("abcddd", channel?.sendMessage);
    if (e.keyCode == 13 && e.shiftKey) {
      return "";
    } else if (e.keyCode == 13) {
      if (text) {
        setLoading(true);
        channel?.sendMessage(String(text).trim());
        setLastMsg(text);
        setText("");
        setLoading(false);
        //closechat(activelink,);
        postLastMsg(text);
      }
    } else if (isSendButClick) {
      if (text) {
        setLoading(true);
        channel?.sendMessage(String(text).trim());
        setLastMsg(text);
        setText("");
        setLoading(false);
        postLastMsg(text);
        //closechat(activelink,text);
      }
    }

    // if (text) {
    //   setLoading(true);
    //   channel.sendMessage(String(text).trim());
    //   setLastMsg(text)
    //   setText('');
    //   setLoading(false);
    // }
  };
  const openShareModal = () => {
    window.$("#shareListModal").modal("show");
  };

  const generateChatId = (self, uid, needid) => {
    console.log("channelid", self + " > " + uid);
    if (self > uid) return `${self}-${uid}-${needid}`;
    else return `${uid}-${self}-${needid}`;
  };
  const handleOrderApprove = async (odrId) => {
    try {
      const { data } = await AxiosApi.post(`order/approveOrder/${odrId}`, {
        headers: {
          token: userInfo.token,
        },
      });

      return data;
    } catch (error) {
      return error;
    }
  };
  const getChatList = async (needID) => {
    var urlstr = "/order/usersFromNeedId/" + needID;

    const { data } = await AxiosApi.get(urlstr);
    data.map((item) => {
      if (selectedNeed && !chatId) {
        if (chatUserId == item.userId) {
          setChatId(item.chatId);
        }
      }
    });
    setSelectedNeeds(data);
    console.log("datadata", { data });
  };
  console.log("outfunc", selectedNeed);
  const postOrderStatusRetrun = async (status, odrId, nedId, linkName) => {
    // console.log(
    //   "abcdef",
    //   status + " " + odrId + " " + nedId + " " + " " + linkName
    // );
    const returnVal = await dispatch(postOrderStatus(status, odrId, nedId));
    if (returnVal === "declined") {
      handleOrderApprove(odrId);
      setIsNotNeededConfirm(true);
    } else if (returnVal === "confirmed") {
      handleOrderApprove(odrId);
      setIsConfirm(true);
    } else if (returnVal === "done") {
      handleOrderApprove(odrId);
      setIsMarkasDone(true);
    } else if (returnVal === "cancel") {
      handleOrderApprove(odrId);
      setIsCancel(true);
    }
    // if (status.includes("declined")) {
    //   const returnVal = await dispatch(postOrderStatus(status, odrId, nedId));
    //   if (returnVal === "declined") {
    //     setIsNotNeededConfirm(true);
    //   } else if (returnVal === "confirmed") {
    //     setIsConfirm(true);
    //   } else if (returnVal === "done") {
    //     setIsMarkasDone(true);
    //   } else if (returnVal === "cancel") {
    //     setIsCancel(true);
    //   }
    // } else if (status.includes("confirmed")) {
    //   manageChatModal(linkName);
    //   const returnVal = await dispatch(postOrderStatus(status, odrId, nedId));
    //   if (returnVal === "declined") {
    //     setIsNotNeededConfirm(true);
    //   } else if (returnVal === "confirmed") {
    //     setIsConfirm(true);
    //   } else if (returnVal === "done") {
    //     setIsMarkasDone(true);
    //   } else if (returnVal === "cancel") {
    //     setIsCancel(true);
    //   }
    // } else {
    //   const returnVal = await dispatch(postOrderStatus(status, odrId, nedId));
    //   if (returnVal === "declined") {
    //     setIsNotNeededConfirm(true);
    //   } else if (returnVal === "confirmed") {
    //     setIsConfirm(true);
    //   } else if (returnVal === "done") {
    //     setIsMarkasDone(true);
    //   } else if (returnVal === "cancel") {
    //     setIsCancel(true);
    //   }
    // }
  };

  const renderDayName = (msgDate, payload, contantName) => {
    let finalDate = "";
    const msgDay = moment(msgDate.toString()).format("DD MMMM YYYY");
    const yesterday = new Date(new Date().valueOf() - 1000 * 60 * 60 * 24);
    if (msgDay === moment().format("DD MMMM YYYY")) {
      todayCount += 1;
      return todayCount === 1 && "Today";
    }
    if (msgDay === moment(yesterday).format("DD MMMM YYYY")) {
      yesterdayCount += 1;
      return yesterdayCount === 1 && "Yesterday";
    }
    if (contantName === "chats") {
      if (uniqueDate === "" || uniqueDate !== msgDay) {
        uniqueDate = msgDay;
        payload?.length > 0 &&
          payload?.map((item) => {
            const itemDate = moment(item?.date?.toString()).format(
              "DD MMMM YYYY"
            );
            if (itemDate === msgDay) {
              dayCount += 1;
              if (dayCount === 1) {
                finalDate = msgDay;
              }
            }
          });
        return finalDate;
      } else {
        dayCount = 0;
        return finalDate;
      }
    } else {
      if (uniqueDate === "" || uniqueDate !== msgDay) {
        uniqueDate = msgDay;
        payload?.length > 0 &&
          payload?.map((item) => {
            const itemDate = moment(item?.dateCreated?.toString()).format(
              "DD MMMM YYYY"
            );
            if (itemDate === msgDay) {
              dayCount += 1;
              if (dayCount === 1) {
                finalDate = msgDay;
              }
            }
          });
        return finalDate;
      } else {
        dayCount = 0;
        return finalDate;
      }
    }
  };

  const handleSearch = (e) => {
    setStartSearching(true);
    const { value } = e.target;
    setSearchTxt(value);
    const val = value.toLowerCase();
    console.log("needsList", val);
    const filteredData = needsList.filter((item) => {
      if (
        JSON.stringify(item?.amount)?.includes(val.toString()) ||
        item?.userNames[0]?.toLowerCase().includes(val.toString()) ||
        item?.userNames[1]?.toLowerCase().includes(val.toString()) ||
        item?.Name.toLowerCase().includes(val.toString())
        // ||
        // item?.userName[0]?.lastName?.toLowerCase().includes(val)
      ) {
        return item;
      }
      //  else {
      //   let isItem = false;
      //   // item?.items.filter((i) => {
      //   //   if (
      //   //     String(i?.qty)?.includes(val) ||
      //   //     i?.Name?.toLowerCase().includes(val)
      //   //   ) {
      //   //     isItem = true;
      //   //   }
      //   // });
      //   if (isItem) {
      //     return item;
      //   }
      // }
    });
    console.log("needsList", { filteredData });
    setFilteredNeedsList(filteredData);
  };
  const renderMessageWindow = (val) => {
    return (
      <div
        className="modal manage-chat-modal"
        tabindex="-1"
        role="dialog"
        id={
          val === "buy"
            ? "manageChatModalbuy"
            : val === "give"
            ? "manageChatModalgive"
            : "manageChatModaldo"
        }
        data-backdrop="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <div className="mobile-header">
                <nav className="navbar">
                  <a
                    onClick={() => closechat(val)}
                    className="back desktop-view"
                  >
                    <img src={back} alt="" />
                    Back
                  </a>
                  <a
                    onClick={() => closechat(val)}
                    className="back mobile-view"
                  >
                    <img src={back_black} alt="" />
                  </a>
                  <label className="navbar-brand mx-auto middle-label mobile-view">
                    {selectedneedList?.Name}
                  </label>
                </nav>
              </div>
            </div>

            <div className="modal-body">
              <div className="profile-row manage-profilerow">
                <div className="user_name_hover">
                  <img
                    className="user_DP"
                    src={userImg ? imgBaseUrl + userImg : profile_pic_alt2}
                  />
                  <label className="name">{`${loginUserName}`}</label>
                </div>
                <div className="hoverDataShow">
                  <p>
                    <img
                      className="user_DP newUser-dp"
                      src={
                        userPersonalDetails?.userImage
                          ? imgBaseUrl + userPersonalDetails?.userImage
                          : profile_pic_alt2
                      }
                    />
                  </p>
                  <h4>
                    <strong>
                      {userPersonalDetails?.userName &&
                        userPersonalDetails?.userName}
                    </strong>
                  </h4>
                  <p className="action-token">Action Token</p>
                  {console.log("userPersonalDetails", userPersonalDetails)}
                  <strong className="action-number">
                    {userPersonalDetails?.actionTaken &&
                      userPersonalDetails?.actionTaken}
                  </strong>
                  <hr></hr>
                  <p className="list-para">
                    {userPersonalDetails?.phoneNumber && (
                      <img src={phoneImg} />
                    )}
                    {" " + userPersonalDetails?.phoneNumber &&
                      userPersonalDetails?.phoneNumber}
                  </p>
                  <p className="list-para">
                    <img src={emailImg} />
                    {" " + userPersonalDetails?.email &&
                      userPersonalDetails?.email}
                  </p>
                  <p className="requirement-text">Requires pick up from:</p>
                  <p className="address-text">
                    Patrick Melrose st 56/526, 88374, London, UK
                  </p>
                </div>

                <label
                  className={
                    "status position-statusfixed " +
                    renderStatus(userPersonalDetails?.items?.needOrderStatus)
                  }
                >
                  {renderStatus(userPersonalDetails?.items?.needOrderStatus)}
                </label>

                {!isNotNeededConfirm &&
                  !isConfirm &&
                  (userPersonalDetails?.items?.needOrderStatus === "pending" ||
                    userPersonalDetails?.items?.needOrderStatus ===
                      undefined) && (
                    <div className="button-row">
                      <button
                        type="button"
                        className="not-needed notNeeded-activebtn"
                        onClick={() =>
                          postOrderStatusRetrun(
                            "declined",
                            userPersonalDetails?.orderId,
                            userPersonalDetails?.items?.needId
                          )
                        }
                      >
                        <img src={close_gray} />
                        <label>Not needed</label>
                      </button>
                      <button
                        type="button"
                        className="confirm confirm-activebtn"
                        onClick={() =>
                          postOrderStatusRetrun(
                            "confirmed",
                            userPersonalDetails?.orderId,
                            userPersonalDetails?.items?.needId
                          )
                        }
                      >
                        <img src={tick_gray} />
                        <label>Confirm</label>
                      </button>
                    </div>
                  )}

                {!isCancel &&
                  !isMarkasDone &&
                  userPersonalDetails?.items?.needOrderStatus ===
                    "confirmed" && (
                    <div className="button-row">
                      <button
                        type="button"
                        className="cancel notNeeded-activebtn"
                        onClick={() =>
                          postOrderStatusRetrun(
                            "cancel",
                            userPersonalDetails?.orderId,
                            userPersonalDetails?.items?.needId
                          )
                        }
                      >
                        <label>Cancel</label>
                      </button>
                      <button
                        type="button"
                        className="done confirm-activebtn"
                        onClick={() =>
                          postOrderStatusRetrun(
                            "done",
                            userPersonalDetails?.orderId,
                            userPersonalDetails?.items?.needId
                          )
                        }
                      >
                        <label>Mark as Done</label>
                      </button>
                    </div>
                  )}
              </div>
              <div className="chat-box newcustom-checkbox">
                <div className="inner-wrapper" ref={scrollDiv}>
                  {/* {loading ? (
                    <label className='label-day'>Loading chat...</label>
                  ) : (
                    <label className='label-day'>Today</label>
                  )} */}
                  {messages.length > 0 &&
                    messages.map((message) => {
                      const isOwnMessage = message?.author === userName;
                      console.log("paritosh1234", message);
                      return (
                        <>
                          {isOwnMessage ? (
                            <div className="text-row me" key={message.index}>
                              <label className="label-day">
                                {renderDayName(
                                  message.dateCreated,
                                  messages,
                                  "mesgs"
                                )}
                              </label>
                              <div className="textbox">
                                {/* <span className='status'>Sending...</span> */}
                                <label className="chat-text">
                                  {message.body}
                                </label>
                                <span className="time">
                                  {moment(message.dateCreated).format("LT")}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div className="text-row them" key={message.index}>
                              <label className="label-day">
                                {renderDayName(
                                  message.dateCreated,
                                  messages,
                                  "mesgs"
                                )}
                              </label>
                              <div className="textbox">
                                <label className="chat-text">
                                  {message.body}
                                </label>
                                <span className="time">
                                  {moment(message.dateCreated).format("LT")}
                                </span>
                              </div>
                            </div>
                          )}
                        </>
                      );
                    })}
                  {/* <div className='thnkx-row'>
                    <button
                      type='button'
                      className='say-thnkxbtn'

                    >
                      <img src={chatImg} />
                      <label>Say Thanks</label>
                    </button>
                  </div> */}
                </div>

                <div className="send-box message-righttextarea">
                  <div className="input-group">
                    <textarea
                      type="text"
                      className="form-control"
                      placeholder="Write a message..."
                      aria-label="Write a message..."
                      aria-describedby="basic-addon2"
                      value={text}
                      disabled={
                        userPersonalDetails?.items?.needOrderStatus ===
                        "declined"
                          ? true
                          : false
                      }
                      onChange={(event) => setText(event.target.value)}
                      onKeyUp={(e) => sendMessage(e, false)}
                    />
                    <div className="input-group-append">
                      <button
                        onClick={(e) => sendMessage(e, true)}
                        className="btn btn-outline-secondary"
                        type="button"
                        disabled={!channel || !text}
                      >
                        <img src={send} />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderChatsWindow = (linkName) => {
    const deductionAmount =
      (totalAmount / 100) * commissionPercentageRateData?.commissionPercentage;
    const mangeInfoPreview =
      linkName === "buy"
        ? "manageInfoPreviewModal"
        : linkName === "give"
        ? "manageInfoPreviewModalgive"
        : "manageInfoPreviewModaldo";
    return (
      <div
        className={
          linkName === "buy"
            ? "modal manage-info-preview-modal manage-areanewpopup"
            : "modal manage-info-preview-modal"
        }
        tabindex="-1"
        role="dialog"
        // id={'manageInfoPreviewModal'}
        id={mangeInfoPreview}
        data-backdrop="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <div className="mobile-header">
                <nav className="navbar">
                  <a
                    onClick={() =>
                      window.$("#" + mangeInfoPreview).modal("hide")
                    }
                    className="back mobile-view"
                  >
                    <img src={back_black} alt="" />
                  </a>
                  <label className="navbar-brand mx-auto middle-label">
                    Details
                  </label>
                  <a
                    onClick={() =>
                      window.$("#" + mangeInfoPreview).modal("hide")
                    }
                    className="cross"
                  >
                    <img src={close2} alt="close" />
                  </a>
                </nav>
              </div>
            </div>

            <div className="modal-body">
              <div className="status-row">
                {/* <label className="status-label active">{!(selectedNeedInfo?.needStatus) && 'Active'}</label> */}
                {selectedNeedInfo?.needStatus && (
                  <label
                    className={
                      selectedNeedInfo?.needStatus === "active"
                        ? "status-label active"
                        : "draft-background"
                    }
                  >
                    {stringCapitalize(selectedNeedInfo?.needStatus)}
                  </label>
                )}

                <a
                  className="edit-link"
                  onClick={(e) => {
                    e.preventDefault();
                    openselectedModal("edit", selectedNeedInfo);
                  }}
                >
                  <img src={edit} />
                </a>
              </div>
              <div className="info-box">
                <div className="name-row">
                  <svg
                    width="33"
                    height="32"
                    viewBox="0 0 33 32"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    {/* <path
                      fill-rule='evenodd'
                      clip-rule='evenodd'
                      d='M3.53178 4.71647C7.15375 1.09451 13.0271 1.09451 16.6491 4.71647L17.5224 5.58984C17.9129 5.98036 17.9129 6.61353 17.5224 7.00405L5.81936 18.7071C5.42883 19.0976 4.79567 19.0976 4.40514 18.7071L3.53178 17.8337C-0.0901837 14.2118 -0.0901837 8.33844 3.53178 4.71647ZM15.2348 6.13069C12.3939 3.28977 7.78691 3.28977 4.946 6.13069C2.10508 8.9716 2.10508 13.5786 4.946 16.4195L5.11225 16.5858L15.4011 6.29694L15.2348 6.13069Z'
                    />
                    <path
                      fill-rule='evenodd'
                      clip-rule='evenodd'
                      d='M16.9816 4.71647C20.6035 1.09451 26.4769 1.09451 30.0988 4.71647C33.7208 8.33844 33.7208 14.2118 30.0988 17.8337L29.2255 18.7071C29.0379 18.8946 28.7836 19 28.5184 19C28.2531 19 27.9988 18.8946 27.8113 18.7071L16.1082 7.00405C15.7177 6.61353 15.7177 5.98036 16.1082 5.58984L16.9816 4.71647ZM28.6846 6.13069C25.8437 3.28977 21.2367 3.28977 18.3958 6.13069L18.2295 6.29694L28.5184 16.5858L28.6846 16.4195C31.5255 13.5786 31.5255 8.9716 28.6846 6.13069Z'
                    />
                    <path
                      fill-rule='evenodd'
                      clip-rule='evenodd'
                      d='M16.7322 5.06019C17.0004 5.05785 17.2583 5.16337 17.448 5.35304L29.3094 17.2144C29.6978 17.6028 29.7002 18.2317 29.3149 18.6231L17.3734 30.7527C17.1838 30.9453 16.9243 31.053 16.654 31.0511C16.3838 31.0493 16.1257 30.9381 15.9387 30.743L4.43992 18.7419C4.06828 18.3541 4.06948 17.7418 4.44265 17.3554L16.0216 5.36548C16.2079 5.17253 16.464 5.06253 16.7322 5.06019ZM6.54951 18.0528L16.6705 28.6159L27.1936 17.927L16.7533 7.48678L6.54951 18.0528Z'
                    /> */}
                    {linkName === "give" ? (
                      <>
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M1.26343 12C1.26343 11.4477 1.71114 11 2.26343 11H11.2634C11.8157 11 12.2634 11.4477 12.2634 12V19H20.16C20.7123 19 21.16 19.4477 21.16 20V30C21.16 30.5523 20.7123 31 20.16 31H2.26343C1.71114 31 1.26343 30.5523 1.26343 30V12ZM3.26343 13V29H19.16V21H11.2634C10.7111 21 10.2634 20.5523 10.2634 20V13H3.26343Z"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M12.5403 1.07516C12.9153 1.22933 13.1601 1.59462 13.1601 2.00003V12C13.1601 12.5523 12.7123 13 12.1601 13H2.26346C1.85994 13 1.49596 12.7575 1.34057 12.3851C1.18519 12.0127 1.26884 11.5834 1.55268 11.2966L11.4493 1.29661C11.7345 1.00845 12.1654 0.92099 12.5403 1.07516ZM4.66004 11H11.1601V4.4321L4.66004 11Z"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M11.16 2C11.16 1.44772 11.6077 1 12.16 1H30.16C30.5645 1 30.9291 1.24364 31.0839 1.61732C31.2387 1.99099 31.1531 2.42111 30.8671 2.70711L20.8671 12.7071C20.6796 12.8946 20.4253 13 20.16 13H12.16C11.6077 13 11.16 12.5523 11.16 12V2ZM13.16 3V11H19.7458L27.7458 3H13.16Z"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M20.16 19H30.16C30.5645 19 30.9291 19.2436 31.0839 19.6173C31.2387 19.991 31.1531 20.4211 30.8671 20.7071L20.8671 30.7071C20.5811 30.9931 20.151 31.0787 19.7774 30.9239C19.4037 30.7691 19.16 30.4045 19.16 30V20C19.16 19.4477 19.6077 19 20.16 19ZM21.16 21V27.5858L27.7458 21H21.16Z"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M30.5427 1.07615C30.9164 1.23093 31.16 1.59557 31.16 2.00003V20C31.16 20.5523 30.7123 21 30.16 21H20.16C19.6077 21 19.16 20.5523 19.16 20V12C19.16 11.7348 19.2654 11.4805 19.4529 11.2929L29.4529 1.29292C29.7389 1.00692 30.169 0.921369 30.5427 1.07615ZM21.16 12.4142V19H29.16V4.41424L21.16 12.4142Z"
                        />
                      </>
                    ) : (
                      <>
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M3.53178 4.71647C7.15375 1.09451 13.0271 1.09451 16.6491 4.71647L17.5224 5.58984C17.9129 5.98036 17.9129 6.61353 17.5224 7.00405L5.81936 18.7071C5.42883 19.0976 4.79567 19.0976 4.40514 18.7071L3.53178 17.8337C-0.0901837 14.2118 -0.0901837 8.33844 3.53178 4.71647ZM15.2348 6.13069C12.3939 3.28977 7.78691 3.28977 4.946 6.13069C2.10508 8.9716 2.10508 13.5786 4.946 16.4195L5.11225 16.5858L15.4011 6.29694L15.2348 6.13069Z"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M16.9816 4.71647C20.6035 1.09451 26.4769 1.09451 30.0988 4.71647C33.7208 8.33844 33.7208 14.2118 30.0988 17.8337L29.2255 18.7071C29.0379 18.8946 28.7836 19 28.5184 19C28.2531 19 27.9988 18.8946 27.8113 18.7071L16.1082 7.00405C15.7177 6.61353 15.7177 5.98036 16.1082 5.58984L16.9816 4.71647ZM28.6846 6.13069C25.8437 3.28977 21.2367 3.28977 18.3958 6.13069L18.2295 6.29694L28.5184 16.5858L28.6846 16.4195C31.5255 13.5786 31.5255 8.9716 28.6846 6.13069Z"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M16.7322 5.06019C17.0004 5.05785 17.2583 5.16337 17.448 5.35304L29.3094 17.2144C29.6978 17.6028 29.7002 18.2317 29.3149 18.6231L17.3734 30.7527C17.1838 30.9453 16.9243 31.053 16.654 31.0511C16.3838 31.0493 16.1257 30.9381 15.9387 30.743L4.43992 18.7419C4.06828 18.3541 4.06948 17.7418 4.44265 17.3554L16.0216 5.36548C16.2079 5.17253 16.464 5.06253 16.7322 5.06019ZM6.54951 18.0528L16.6705 28.6159L27.1936 17.927L16.7533 7.48678L6.54951 18.0528Z"
                        />
                      </>
                    )}
                  </svg>
                  <label>{stringCapitalize(selectedNeedInfo?.title)}</label>
                  <a
                    className="edit-link"
                    // onClick={() => {
                    //   history.push({
                    //     pathname: `/needs/${props.match.params.id}`,
                    //     state: {
                    //       EditData: true,
                    //       needId: selectedneedList.needId,
                    //     },
                    //   });
                    // }}
                    onClick={(e) => {
                      e.preventDefault();
                      openselectedModal("edit", selectedNeedInfo);
                    }}
                  >
                    {/* edit pencil */}
                    <img src={edit} />
                  </a>
                </div>
                <label className="desc-row">
                  {selectedNeedInfo?.description}
                </label>
                <div className={linkName === "do" ? "row3 full-row" : "row3"}>
                  {/* <label className='label-price'>${selectedneedList?.amount}</label> */}
                  {linkName === "buy" && (
                    <label className="label-price">
                      ${totalAmount ? totalAmount : 0}
                    </label>
                  )}
                  {(linkName === "give" || linkName === "do") && (
                    <label className="label-location">
                      {selectedNeedInfo?.location && (
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M10 20C11.3333 20 18 15.7142 18 8.57139C18 3.83854 14.4173 0 10 0C5.58267 0 2 3.83854 2 8.57139C2 15.7142 8.66667 20 10 20ZM10 12C12.2091 12 14 10.2091 14 8C14 5.79086 12.2091 4 10 4C7.79086 4 6 5.79086 6 8C6 10.2091 7.79086 12 10 12Z"
                            fill="#72819B"
                          />
                        </svg>
                      )}
                      <label>{selectedNeedInfo?.location}</label>
                    </label>
                  )}
                  {linkName === "do" && (
                    <label className="label-completed-by">
                      <svg
                        width="21"
                        height="20"
                        viewBox="0 0 21 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.5 0C4.986 0 0.5 4.486 0.5 10C0.5 15.514 4.986 20 10.5 20C16.014 20 20.5 15.514 20.5 10C20.5 4.486 16.014 0 10.5 0ZM16.25 11H9.5V4H11.5V9H16.25V11Z"
                          fill="#72819B"
                        />
                      </svg>
                      <label>
                        Complete-by:
                        <strong>
                          {moment(selectedNeedInfo?.completedBy).format("lll")}
                        </strong>
                      </label>
                    </label>
                  )}
                  <label className="label-progress">
                    {/* {selectedneedList?.qty} of {selectedNeedInfo?.qtyNeeded} */}
                    {selectedNeedInfo?.qtyOrdered} of{" "}
                    {selectedNeedInfo?.qtyNeeded}
                    {linkName === "give" && "given"}
                    {linkName === "do" && "volunteers"}
                  </label>
                </div>
              </div>
              {/* <h5 className='inner-heading'>Today</h5> */}
              {selectedNeed &&
                selectedNeed?.length > 0 &&
                selectedNeed.map((item, index) => {
                  return (
                    <>
                      <label className="date_label_for_chats">
                        {renderDayName(item?.date, selectedNeed, "chats")}
                      </label>
                      {console.log("dataaaaaaaaaaaaaaaaaaaaaa", item)}
                      <div
                        key={index}
                        className="review-box"
                        onClick={() => {
                          setLoginUserName(item?.userName);
                          setChatActionListId(item?.items.actionListId);
                          setChatNeedId(item?.items?.needId);
                          setChatChannelId(
                            generateChatId(
                              userInfo.userId,
                              item?.userId,
                              item?.items?.needId
                            )
                          );
                          setAllMesagesItem(item);
                          setChatId(item?.chatId);
                          setChatUserId(item?.userId);
                          setUserId(item?.userId);
                          setUserImg(item?.userImage);
                          setUserPersonalDetails(item);
                          twilioChat(
                            generateChatId(
                              userInfo.userId,
                              item?.userId,
                              item?.items?.needId
                            )
                          );
                          manageChatModal(linkName);
                        }}
                      >
                        {console.log("heypritesh", item)}
                        {linkName === "do" && (
                          <div className="new-message">
                            <span>1</span>
                          </div>
                        )}
                        <div className="row1">
                          <img
                            src={
                              item?.userImage
                                ? imgBaseUrl + item?.userImage
                                : profile_pic
                            }
                            className="profile-pic"
                          />
                          <label className="name" onClick={() => {}}>
                            {item?.userName}
                          </label>
                          {linkName === "buy" && (
                            <label className="quantity">
                              x {item.items?.qty}
                            </label>
                          )}
                          {(linkName === "give" || linkName === "do") && (
                            <label
                              className={
                                "status " +
                                renderStatus(item?.items?.needOrderStatus)
                              }
                            >
                              {renderStatus(item?.items?.needOrderStatus)}
                            </label>
                          )}
                        </div>
                        <div className="row2">
                          {linkName === "buy" && (
                            <label className="donated">
                              Donated: <span>${item.items?.amount}</span>
                            </label>
                          )}
                          {linkName === "give" && (
                            <label className="message">
                              {item?.items?.needOrderStatus === "pending" ||
                              item?.items?.needOrderStatus === undefined
                                ? "Sent the request to help"
                                : item?.chatId?.message[0]?.msg &&
                                  item?.chatId?.message[0]?.msg}
                            </label>
                          )}
                          {linkName === "do" && (
                            <label className="comment">
                              {item?.chatId?.message[0]?.msg &&
                                item?.chatId?.message[0]?.msg}
                            </label>
                          )}
                          <label className="time">
                            {moment(item.date).format("LT")}
                          </label>
                        </div>
                        {(linkName === "give" || linkName === "do") &&
                          !isNotNeededConfirm &&
                          !isConfirm &&
                          (item?.items?.needOrderStatus === "pending" ||
                            item?.items?.needOrderStatus === undefined) && (
                            <div className="button-row">
                              <button
                                type="button"
                                className="not-needed notNeeded-activebtn "
                                // onClick={() => postOrderStatus("declined", item?.orderId, item?.items?.needId)}
                                onClick={() =>
                                  postOrderStatusRetrun(
                                    "declined",
                                    item?.orderId,
                                    item?.items?.needId,
                                    linkName
                                  )
                                }
                              >
                                <img src={close_gray} />
                                {/* desktop not needed my action list  */}
                                <label>Not needed</label>
                              </button>
                              <button
                                type="button"
                                className="confirm confirm-activebtn"
                                // onClick={()=> postOrderStatus("confirmed", item?.orderId, item?.items?.needId)}
                                onClick={() =>
                                  postOrderStatusRetrun(
                                    "confirmed",
                                    item?.orderId,
                                    item?.items?.needId,
                                    linkName
                                  )
                                }
                              >
                                <img src={tick_gray} />
                                <label>Confirm</label>
                              </button>
                            </div>
                          )}
                      </div>
                    </>
                  );
                })}

              {/* <h5 className='inner-heading'>Yesterday</h5> */}

              {linkName === "buy" && (
                <div className="bill-box">
                  {/* <img src={bill} /> */}
                  <div>
                    <label>Amount </label>
                    <span> US ${totalAmount ? totalAmount : 0}</span>
                  </div>
                  <div>
                    <label>Fee </label>
                    <span> US ${totalAmount ? deductionAmount : 0}</span>
                  </div>
                  <div>
                    <label>Net </label>
                    <span>
                      {" "}
                      US ${totalAmount ? totalAmount - deductionAmount : 0}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  // useEffect(() => {
  //   for (let i = 0; i < filteredNeedsList.length; i++) {
  //     for (let j = 0; j < filteredNeedsList[i].items.length; j++) {
  //       //console.log("data",filteredNeedsList[i].items[j])
  //       const csvData = {
  //         Name: filteredNeedsList[i].items[j].Name,
  //         amount: filteredNeedsList[i].items[j].amount,
  //         needId: filteredNeedsList[i].items[j].needId,
  //         needOrderStatus: filteredNeedsList[i].items[j].needOrderStatus,
  //         needType: filteredNeedsList[i].items[j].needType,
  //         qty: filteredNeedsList[i].items[j].qty,
  //       };
  //       setDownload((prevState) => {
  //         return [...prevState, csvData];
  //       });
  //     }
  //   }
  // }, [filteredNeedsList]);

  return (
    <>
      <div>
        {editInfo && (
          <>
            <NeedCreationNeedInfoEditingFreeAccountRestrictions
              func={setEditinfo}
              selectedItem={selectedItem}
              type={"manage"}
              // onupdateList={""}
              // duplicatemobile={""}
              // deleteneedmobile={""}
              // checkNeedDeleteOrDuplicate={""}
              // match={""}
              // title={""}
              // history={""}
            />
          </>
        )}
      </div>
      <div className="custom-manageaction">
        <DesktopHeaderTwo history={props.history} />
        <MobileHeaderTwo
          pageTitle="Manage"
          previousPage="/me/lists"
          filter={true}
          close="/account-created"
          history={props.history}
          filterClose={filters}
          selectedTheme={selectedaction?.themeName}
        />
        <div className="list-creation-innerpage list-creation-innerpagenew ">
          <LeftPlane
            history={props.history}
            route="/manage-creation"
            activrID={props.match.params.id}
            selectedTheme={selectedaction?.themeName}
          />
          <div class={`rightpane rightpane-manage  ${scrolltop && "scrolled"}`}>
            <div class="rightpane-header">
              <h2 class="mr-auto">
                {localStorage.getItem("selectedListName")}
              </h2>
              <div class="button-panel">
                <button
                  class="btn view-btn"
                  onClick={
                    () =>
                      window.open(
                        baseUrl +
                          "/l/" +
                          props.match.params.id,
                        "_blank"
                      )
                    // props.history.push(
                    //   '/l/' + props.match.params.id
                    // )
                  }
                >
                  <img src={view_enabled} />
                  View
                </button>
                <button
                  className="btn share-btn for-desktop"
                  onClick={openShareModal}
                >
                  <img src={share} />
                  Share
                </button>
              </div>
            </div>
            <div
              className="tab-content leftpaneTabContent manageactionlefttab"
              id="leftpaneTabContent"
              onScroll={(e) => updatescroll(e.target.scrollTop)}
            >
              <div
                className="tab-pane fade show active manage-contentnew"
                id="manage-content"
                role="tabpanel"
                aria-labelledby="manage-tab"
              >
                <div
                  className={`${selectedaction?.themeName} content-wrapper`}
                  id="manageContentWrapper"
                >
                  <div className="manage-tabs-row">
                    <ul className="nav nav-tabs" id="manageTabs" role="tablist">
                      <li className="nav-item" href="#buy">
                        <a
                          className="nav-link active"
                          id="manage_buy_tab"
                          data-toggle="tab"
                          href="#manage_buy"
                          role="tab"
                          aria-controls="manage-buy"
                          aria-selected="true"
                          onClick={() => setActiveLisnk("buy")}
                        >
                          Buy
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="manage_give_tab"
                          data-toggle="tab"
                          href="#manage_give"
                          role="tab"
                          aria-controls="manage-give"
                          aria-selected="false"
                          onClick={() => setActiveLisnk("give")}
                        >
                          Give
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="manage_do_tab"
                          data-toggle="tab"
                          href="#manage_do"
                          role="tab"
                          aria-controls="manage-do"
                          aria-selected="false"
                          onClick={() => setActiveLisnk("do")}
                        >
                          Do
                        </a>
                      </li>
                    </ul>

                    <div className="btn-panel desktop-view">
                      {/* <button type='button' className='btn-search'>
                      <img src='images/search.png' />
                    </button> */}

                      <div class="right new-searchdiv message-input">
                        <div className="search-panel">
                          <button
                            class="btn-action btn-filter btn-search collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseSearch"
                            aria-expanded="false"
                            aria-controls="collapseExample"
                          >
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle cx={13} cy={11} r={8} strokeWidth={2} />
                              <path
                                d="M7 17L2.5 21.5"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                          <div
                            class="collapse searchCollapse"
                            id="collapseSearch"
                          >
                            <input
                              type="text"
                              name="searchInput"
                              id="searchInput"
                              placeholder="Search for action..."
                              value={searchTxt}
                              onChange={(e) => handleSearch(e)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="filter-div">
                        {/* <button type='button' className='btn-filter'>
                      <img src={filter} />
                    </button> */}
                      </div>
                    </div>
                  </div>

                  <div className="tab-content manage-tab-content">
                    <div
                      className="tab-pane fade show active height-manageFix"
                      id="manage_buy"
                      role="tabpanel"
                      aria-labelledby="manage_buy_tab"
                    >
                      <div className="top-row">
                        <div className="name-panel">
                          <svg
                            viewBox="0 0 32 32"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M10.5 23C8.567 23 7 24.567 7 26.5C7 28.433 8.567 30 10.5 30C12.433 30 14 28.433 14 26.5C14 24.567 12.433 23 10.5 23ZM5 26.5C5 23.4624 7.46244 21 10.5 21C13.5376 21 16 23.4624 16 26.5C16 29.5376 13.5376 32 10.5 32C7.46243 32 5 29.5376 5 26.5Z"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M21.5 23C19.567 23 18 24.567 18 26.5C18 28.433 19.567 30 21.5 30C23.433 30 25 28.433 25 26.5C25 24.567 23.433 23 21.5 23ZM16 26.5C16 23.4624 18.4624 21 21.5 21C24.5376 21 27 23.4624 27 26.5C27 29.5376 24.5376 32 21.5 32C18.4624 32 16 29.5376 16 26.5Z"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M1.20992 6.38701C1.39934 6.14286 1.691 6 2.00001 6H10.6784C11.0838 6 11.4491 6.24477 11.6032 6.61973C11.7574 6.99469 11.6699 7.42562 11.3818 7.71079L7.26351 11.7863L16 20.5811L24.7365 11.7863L20.6182 7.71079C20.3301 7.42562 20.2426 6.99469 20.3968 6.61973C20.5509 6.24477 20.9162 6 21.3216 6H30C30.309 6 30.6007 6.14286 30.7901 6.38701C30.9795 6.63116 31.0454 6.94916 30.9686 7.24848L27.1207 22.2485C27.0072 22.6907 26.6086 23 26.1521 23H5.84796C5.39138 23 4.99278 22.6907 4.87933 22.2485L1.03137 7.24848C0.954588 6.94916 1.0205 6.63116 1.20992 6.38701ZM18.4029 21H25.3762L28.7111 8H23.7538L26.8555 11.0694C27.0442 11.2562 27.1509 11.5104 27.1521 11.7759C27.1532 12.0415 27.0487 12.2966 26.8615 12.485L18.4029 21ZM13.5971 21H6.62381L3.28892 8H8.24622L5.14456 11.0694C4.95581 11.2562 4.84911 11.5104 4.84797 11.7759C4.84684 12.0415 4.95137 12.2966 5.13851 12.485L13.5971 21Z"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M16 0C16.2968 0 16.5782 0.131823 16.7682 0.359816L21.7682 6.35982C22.0166 6.6579 22.0702 7.07277 21.9056 7.42416C21.741 7.77554 21.388 8 21 8H11C10.612 8 10.259 7.77554 10.0944 7.42416C9.92984 7.07277 9.98339 6.6579 10.2318 6.35982L15.2318 0.359816C15.4218 0.131823 15.7032 0 16 0ZM13.1351 6H18.865L16 2.56205L13.1351 6Z"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M10.0493 6.29454C10.237 6.106 10.492 6 10.7581 6H21.2419C21.508 6 21.763 6.106 21.9507 6.29454L26.7087 11.0748C27.0947 11.4625 27.0974 12.0885 26.7148 12.4796L16.7147 22.6994C16.5266 22.8916 16.269 23 16 23C15.731 23 15.4734 22.8916 15.2852 22.6994L5.28525 12.4796C4.90259 12.0885 4.90527 11.4625 5.29125 11.0748L10.0493 6.29454ZM11.1736 8L7.40496 11.7862L16 20.5702L24.595 11.7862L20.8264 8H11.1736Z"
                            />
                          </svg>
                          <label>${donationval} Donated</label>
                        </div>
                        <div className="btn-panel">
                          <button
                            type="button"
                            className="btn btn-getlist desktop-view"
                            // onClick={() => handleShow()}
                            onClick={() =>
                              window.$("#showShoppingData").modal("show")
                            }
                          >
                            Get shopping list
                          </button>
                          {console.log(props.match.params.id)}
                          {/* <Link
                            to={{
                              pathname: `http://localhost:3002/cnp1286-frontend/React/build#/setting/${props.match.params.id}`,
                              state: { withdraw: true },
                            }}
                          > */}
                          <button
                            //type="button"
                            className="btn btn-withdraw"
                            onClick={() =>
                              history.push({
                                pathname:
                                  "/setting/" + props.match.params.id,
                                state: { withdraw: true },
                              })
                            }
                          >
                            Withdraw funds
                          </button>
                          {/* </Link> */}
                          <button
                            type="button"
                            className="btn btn-getlist mobile-view"
                            onClick={() =>
                              window.$("#showShoppingData").modal("show")
                            }
                          >
                            Get shopping list
                          </button>
                        </div>
                      </div>
                      {console.log("abc", { selectedneedList })}
                      {activelink === "buy" && (
                        <ManageBuyList
                          selectedproduct={(data) => openselectedModal(data)}
                          listId={props.match.params.id}
                          activelink={activelink}
                          setNeedList={(data) => setNeedList(data)}
                          setNeedInfo={(data) => setNeedInfo(data)}
                          needsList={filteredNeedsList}
                          needs={needs}
                          setDonationval={(val) => setDonationval(val)}
                          getChatList={(id) => getChatList(id)}
                          startSearching={startSearching}
                        />
                      )}
                    </div>

                    <div
                      className="tab-pane fade"
                      id="manage_give"
                      role="tabpanel"
                      aria-labelledby="manage_give_tab"
                    >
                      {activelink === "give" && (
                        <ManageGiveList
                          selectedproduct={(data) => openselectedModal(data)}
                          listId={props.match.params.id}
                          activelink={activelink}
                          setNeedList={(data) => setNeedList(data)}
                          setNeedInfo={(data) => setNeedInfo(data)}
                          needsList={filteredNeedsList}
                          needs={needs}
                          getChatList={(id) => getChatList(id)}
                          startSearching={startSearching}
                        />
                      )}
                    </div>
                    <div
                      className="tab-pane fade"
                      id="manage_do"
                      role="tabpanel"
                      aria-labelledby="manage_do_tab"
                    >
                      {activelink === "do" && (
                        <ManageDoList
                          selectedproduct={(data) => openselectedModal(data)}
                          listId={props.match.params.id}
                          activelink={activelink}
                          setNeedList={(data) => setNeedList(data)}
                          setNeedInfo={(data) => setNeedInfo(data)}
                          needsList={filteredNeedsList}
                          needs={needs}
                          getChatList={(id) => getChatList(id)}
                          startSearching={startSearching}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="needs-content"
                role="tabpanel"
                aria-labelledby="needs-tab"
              >
                Needs
              </div>
              <div
                className="tab-pane fade"
                id="reports-content"
                role="tabpanel"
                aria-labelledby="reports-tab"
              >
                Reports
                {/* {RenderReport()} */}
              </div>
              <div
                className="tab-pane fade"
                id="settings-content"
                role="tabpanel"
                aria-labelledby="settings-tab"
              >
                Settings
              </div>
            </div>
          </div>

          <div
            className="modal fade delete-list-modal"
            id="showShoppingData"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    type="button"
                    className="btn-close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => window.$("#showShoppingData").modal("hide")}
                  >
                    <img src={Close} />
                  </button>
                  <h2 style={{ textAlign: "center" }}>
                    <strong> {localStorage.getItem("selectedListName")}</strong>
                  </h2>
                  <label>
                    Download {localStorage.getItem("selectedListName")} report
                  </label>
                  <br></br>
                  <br></br>
                  <button
                    onClick={() => window.$("#showShoppingData").modal("hide")}
                    type="button"
                    className="cancel-button"
                  >
                    Cancel
                  </button>
                  <div className="download-button">
                    <CsvDownload
                      style={{
                        border: "none",
                        outline: "none",
                        background: "transparent",
                      }}
                      data={download}
                      filename={`${localStorage.getItem(
                        "selectedListName"
                      )}.csv`}
                    >
                      <button
                        variant="primary"
                        onClick={() =>
                          window.$("#showShoppingData").modal("hide")
                        }
                      >
                        Download Report
                      </button>
                    </CsvDownload>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <Modal size="md" show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>
              <strong> {localStorage.getItem("selectedListName")}</strong>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Download {localStorage.getItem("selectedListName")} report
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <CsvDownload
              style={{
                border: "none",
                outline: "none",
                background: "transparent",
              }}
              data={download}
              filename={`${localStorage.getItem("selectedListName")}.csv`}
            >
              <Button variant="primary" onClick={handleClose}>
                Download Report
              </Button>
            </CsvDownload>
          </Modal.Footer>
        </Modal> */}
        </div>
        {renderChatsWindow(activelink)}
        {renderMessageWindow(activelink)}
        <ShareModal location={window.location.href} />

        <ToastContainer autoClose={3000} />
      </div>
    </>
  );
}
