import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../style.css";
import Back from "../../../assets/images/back.png";
import right_angle from "../../../assets/images/right_angle_gray.png";
import {
  getNeedsList,
  getNeedDetailsData,
} from "../../../Store/Actions/userActions";
import { stringCapitalize } from "../../../services/stringModify";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
// var needsContribution = 0;
export default function ManageDoList(props) {
  const dispatch = useDispatch();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  let yesterdayCount = 0;
  let needsContribution = 0;
  let todayCount = 0;
  let dayCount = 0;
  let uniqueDate = "";
  let [needsList, setNeedsList] = useState([]);
  let [needs, setNeeds] = useState([]);
  let [needsContri, setNeedsContri] = useState(0);
  let [key, setKey] = useState();

  useEffect(() => {
    setNeedsList(props?.needsList);
    var needsval = props?.needsList;
    for (var i = 0; i < needsval?.length; i++) {
      if (needsval[i]?.needType === "give") {
        // needsContribution = needsContribution + needsval[i].totalQuantity;
        needsContribution = needsContribution + needsval[i]?.amount;
      }
      if (i === needsval?.length - 1) {
        !props?.startSearching && setNeedsContri(needsContribution);
        // setNeedsContri(needsContribution);
      }
    }
  }, [props?.needsList]);

  useEffect(() => {
    setNeeds(props.needs);
  }, [props.needs]);

  const renderDayName = (msgDate, payload) => {
    let finalDate = "";
    const msgDay = moment(msgDate.toString()).format("DD MMMM YYYY");
    const yesterday = new Date(new Date().valueOf() - 1000 * 60 * 60 * 24);
    if (msgDay === moment().format("DD MMMM YYYY")) {
      todayCount += 1;
      return todayCount === 1 && "Today";
    }
    if (msgDay === moment(yesterday).format("DD MMMM YYYY")) {
      yesterdayCount += 1;
      return yesterdayCount === 1 && "Yesterday";
    }
    if (uniqueDate === "" || uniqueDate !== msgDay) {
      uniqueDate = msgDay;
      payload?.length > 0 &&
        payload?.map((item) => {
          const itemDate = moment(item?.date?.toString()).format(
            "DD MMMM YYYY"
          );
          if (itemDate === msgDay) {
            dayCount += 1;
            if (dayCount === 1) {
              finalDate = msgDay;
            }
          }
        });
      return finalDate;
    } else {
      dayCount = 0;
      return finalDate;
    }
  };

  const getNeedDetails = (needId, index) => {
    setKey(index);
    dispatch(getNeedDetailsData(needId, userInfo?.token)).then((res) => {
      const data = res?.data;
      if (data) {
        props?.setNeedList(data);
        props?.setNeedInfo(data);
        props?.selectedproduct("do");
        props?.getChatList(data?.needId);
      } else {
        toast.error("Something went wrong.");
      }
    });
  };

  return (
    <div>
      <div class="top-row">
        <div class="name-panel">
          <svg
            width="33"
            height="32"
            viewBox="0 0 33 32"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3.53178 4.71647C7.15375 1.09451 13.0271 1.09451 16.6491 4.71647L17.5224 5.58984C17.9129 5.98036 17.9129 6.61353 17.5224 7.00405L5.81936 18.7071C5.42883 19.0976 4.79567 19.0976 4.40514 18.7071L3.53178 17.8337C-0.0901837 14.2118 -0.0901837 8.33844 3.53178 4.71647ZM15.2348 6.13069C12.3939 3.28977 7.78691 3.28977 4.946 6.13069C2.10508 8.9716 2.10508 13.5786 4.946 16.4195L5.11225 16.5858L15.4011 6.29694L15.2348 6.13069Z"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M16.9816 4.71647C20.6035 1.09451 26.4769 1.09451 30.0988 4.71647C33.7208 8.33844 33.7208 14.2118 30.0988 17.8337L29.2255 18.7071C29.0379 18.8946 28.7836 19 28.5184 19C28.2531 19 27.9988 18.8946 27.8113 18.7071L16.1082 7.00405C15.7177 6.61353 15.7177 5.98036 16.1082 5.58984L16.9816 4.71647ZM28.6846 6.13069C25.8437 3.28977 21.2367 3.28977 18.3958 6.13069L18.2295 6.29694L28.5184 16.5858L28.6846 16.4195C31.5255 13.5786 31.5255 8.9716 28.6846 6.13069Z"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M16.7322 5.06019C17.0004 5.05785 17.2583 5.16337 17.448 5.35304L29.3094 17.2144C29.6978 17.6028 29.7002 18.2317 29.3149 18.6231L17.3734 30.7527C17.1838 30.9453 16.9243 31.053 16.654 31.0511C16.3838 31.0493 16.1257 30.9381 15.9387 30.743L4.43992 18.7419C4.06828 18.3541 4.06948 17.7418 4.44265 17.3554L16.0216 5.36548C16.2079 5.17253 16.464 5.06253 16.7322 5.06019ZM6.54951 18.0528L16.6705 28.6159L27.1936 17.927L16.7533 7.48678L6.54951 18.0528Z"
            />
          </svg>
          <label>{needsContri} Volunteers</label>
        </div>
      </div>
      <div class="list-content">
        {/* <h5 class="list-header">Needs Review</h5>{" "} */}
        {/* {needsList && needsList.length > 0 && (
          <h5 class="list-header">Needs Review</h5>
        )}{" "} */}
        {(() => {
          for (let i = 0; i < needsList.length; i++) {
            if (needsList[i].needType === "do" && !needsList[i].approved) {
             return  <h5 class="list-header">Needs Review</h5>
              break;
            }
          }
        })()}
        {needsList &&
          needsList.length > 0 &&
          needsList.map((obj, index) => {
            return (
              <div>
                {obj.needType === "do" && !obj.approved && (
                  <div>
                    {/* {obj.items &&
                      obj.items.length > 0 &&
                      obj.items.map((item, index) => {
                        const selectIndex = needs.findIndex(
                          (temp) => temp.needId === item.needId
                        );
                        console.log('selectIndex', selectIndex, needs); */}
                    {/* return ( */}
                    <>
                      {/* <label className="date_label">{renderDayName(obj?.date, needsList)}</label> */}
                      <div
                        class={`list-item priority ${
                          key == index ? "active" : ""
                        }`}
                        // onClick={() => (
                        //   props.setNeedList(obj),
                        //   props.setNeedInfo(obj),
                        //   props.selectedproduct('do'),
                        //   props.getChatList(obj.needId)
                        // )}

                        onClick={() => {
                          getNeedDetails(obj?.needId, index);
                        }}
                      >
                        <div class="column-first">
                          <div class="row1">
                            <label class="name">
                              {stringCapitalize(obj?.Name)}
                            </label>
                          </div>
                          {/* <label class='row2'>
                                {selectIndex != -1 &&
                                  needs[selectIndex]?.description}
                              </label> */}
                        </div>
                        <div class="column-last">
                          <button type="button">
                            <img src={right_angle} />
                          </button>
                        </div>
                      </div>
                    </>
                    {/* );
                      })} */}
                  </div>
                )}
              </div>
            );
          })}
        {needsList &&
          needsList.length > 0 &&
          needsList.map((obj, index) => {
            return (
              <div>
                {obj.needType === "do" && obj.approved && (
                  <div>
                    {/* {obj.items &&
                      obj.items.length > 0 &&
                      obj.items.map((item, index) => {
                        const selectIndex = needs.findIndex(
                          (temp) => temp.needId === item.needId
                        );
                        console.log('selectIndex', selectIndex, needs); */}
                    {/* return ( */}
                    <>
                      <label className="date_label">
                        {renderDayName(obj?.date, needsList)}
                      </label>
                      <div
                        class={`list-item priority ${
                          key == index ? "active" : ""
                        }`}
                        // onClick={() => (
                        //   props.setNeedList(obj),
                        //   props.setNeedInfo(obj),
                        //   props.selectedproduct('do'),
                        //   props.getChatList(obj.needId)
                        // )}

                        onClick={() => {
                          getNeedDetails(obj?.needId, index);
                        }}
                      >
                        <div class="column-first">
                          <div class="row1">
                            <label class="name">
                              {stringCapitalize(obj?.Name)}
                            </label>
                          </div>
                          {/* <label class='row2'>
                                {selectIndex != -1 &&
                                  needs[selectIndex]?.description}
                              </label> */}
                        </div>
                        <div class="column-last">
                          <button type="button">
                            <img src={right_angle} />
                          </button>
                        </div>
                      </div>
                    </>
                    {/* );
                      })} */}
                  </div>
                )}
              </div>
            );
          })}
      </div>
      <ToastContainer autoClose={3000} />
    </div>
  );
}
