import React, { useState } from "react";
import Close from "../../../../assets/images/close2.png";
import RoundBlueTick from "../../../../assets/images/round_blue_tick.png";
import Leaf from "../../../../assets/images/leaf.svg";
import Share from "../../../../assets/images/share.png";
import Filter from "../../../../assets/images/filter.png";
import Buy from "../../../../assets/images/buy.svg";
import MinusActive from "../../../../assets/images/minus_active.png";
import PlusInactive from "../../../../assets/images/plus_inactive.png";
import Give from "../../../../assets/images/give.svg";
import Location from "../../../../assets/images/location.svg";
import Clock from "../../../../assets/images/clock.svg";
import MinusInactive from "../../../../assets/images/minus_inactive.png";
import Do from "../../../../assets/images/do.svg";
import PlusActive from "../../../../assets/images/plus_active.png";
import LogoFooter from "../../../../assets/images/logo_footer.png";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Profileheadermobile from "../../../../Common/Profileheadermobile";
import * as _ from "loadsh";

export const ShowPreviewData = (props) => {
  const { userCreateList } = useSelector((state) => state.createList);
  const { userInfo } = useSelector((state) => state.userSignin);

  const [actionType, setActionType] = useState("all-tab");

  const showPurpose = () => {
    switch (userCreateList?.purposeOption) {
      case "advocacy":
        return "Advocacy";
      case "animalsandpets":
        return "Animals and Pets";
      case "artsandculture":
        return "Arts and Culture";
      case "crisissupport":
        return "Crisis Support";
      case "communityandlocal":
        return "Community and Local";
      case "education":
        return "Education";
      case "emergencyanddisasterrelief":
        return "Emergency and Disaster Relief";
      case "environment":
        return "Environment";
      case "events":
        return "Events";
      case "faith":
        return "Faith";
      case "friendsandfamily":
        return "Friends and Family";
      case "healthandmedical":
        return "Health and Medical";
      case "socialjustice":
        return "Social Justice";
      case "sportsandrecreation":
        return "Sports and Recreation";
      case "other":
        return "Other";
      default:
        return "Other";
    }
  };

  const renderPriorityContent = () => {
    return (
      <>
        {(actionType === "all-tab" || actionType === "buy-tab") && (
          <div className="need-box priority">
            <div className="column column-first mr-auto">
              <div className="left d-inline-block">
                <img src={Buy} alt="Buy" />
              </div>
              <div className="right d-inline-block">
                <h5>
                  Peanut butter <span className="badge">Priority</span>
                </h5>
              </div>
            </div>
            <div className="column column-price">
              <label>$5.00</label>
              <label className="quantity">1000 needed</label>
            </div>
            <div className="divider mobile-view">
              <hr />
            </div>
            <div className="column column-last">
              <div className="left">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <button className="btn btn-outline-secondary" type="button">
                      <img src={MinusActive} alt="MinusActive" />
                    </button>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue="1000"
                  />
                  <div className="input-group-append">
                    <button className="btn btn-outline-secondary" type="button">
                      <img src={PlusInactive} alt="PlusInactive" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="right">
                <label>1000 needed</label>
                <button type="button" className="btn">
                  Buy
                </button>
              </div>
            </div>
          </div>
        )}
        {(actionType === "all-tab" || actionType === "give-tab") && (
          <div className="need-box priority">
            <div className="column column-first mr-auto">
              <div className="left d-inline-block">
                <img src={Give} alt="Give" />
              </div>
              <div className="right d-inline-block">
                <h5>
                  Set of 6 dining room chairs
                  <span className="badge">Priority</span>
                </h5>
                <p className="for-desktop">
                  A family in need would be glad to get 6 chairs...{" "}
                  <a href="#.">Read More</a>
                </p>
              </div>
            </div>
            <div className="column column-first mobile-view">
              <p>
                A family in need would be glad to get 6 chairs...{" "}
                <a href="#.">Read More</a>
              </p>
            </div>
            <div className="column column-location">
              <div className="wrapper">
                <div className="left d-inline-block">
                  <img src={Location} alt="location" />
                </div>
                <div className="right d-inline-block">
                  <label>Downtown Toronto</label>
                </div>
              </div>
            </div>
            <div className="divider mobile-view">
              <hr />
            </div>
            <div className="column column-last">
              <div className="left">
                <div className="input-group disabled for-desktop">
                  <div className="input-group-prepend">
                    <button className="btn btn-outline-secondary" type="button">
                      <img src={MinusInactive} alt="minusinactive" />
                    </button>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue="1"
                  />
                  <div className="input-group-append">
                    <button className="btn btn-outline-secondary" type="button">
                      <img src={PlusInactive} alt="plusinactive" />
                    </button>
                  </div>
                </div>
                <div className="quantity mobile-view">
                  <label>1 needed</label>
                </div>
              </div>
              <div className="right">
                <label>1 needed</label>
                <button type="button" className="btn">
                  Give
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const renderNonPriorityContent = () => {
    return (
      <>
        {(actionType === "all-tab" || actionType === "do-tab") && (
          <div className="need-box">
            <div className="column column-first mr-auto">
              <div className="left d-inline-block">
                <img src={Do} alt="do" />
              </div>
              <div className="right d-inline-block">
                <h5>Senior requires food bank pickup</h5>
                <p className="for-desktop">
                  Senior needs help with food bank pickup. Plea...{" "}
                  <a href="#.">Read More</a>
                </p>
              </div>
            </div>
            <div className="column column-first mobile-view">
              <p>
                Senior needs help with food bank pickup. Plea...{" "}
                <a href="#.">Read More</a>
              </p>
            </div>
            <div className="column column-location column-time">
              <div className="wrapper">
                <div className="left d-inline-block">
                  <img src={Clock} alt="clocl" />
                </div>
                <div className="right d-inline-block">
                  <label>Complete-by: Dec 21, 3:00 PM</label>
                </div>
              </div>
            </div>
            <div className="column column-location">
              <div className="wrapper">
                <div className="left d-inline-block">
                  <img src={Location} alt="location" />
                </div>
                <div className="right d-inline-block">
                  <label>
                    SCARBOROUGH <span>(Lawrence/Scarborough Golf Club Rd)</span>
                  </label>
                </div>
              </div>
            </div>
            <div className="divider mobile-view">
              <hr />
            </div>
            <div className="column column-last">
              <div className="left">
                <div className="input-group disabled for-desktop">
                  <div className="input-group-prepend">
                    <button className="btn btn-outline-secondary" type="button">
                      <img src={MinusInactive} alt="minusInactive" />
                    </button>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue="1"
                  />
                  <div className="input-group-append">
                    <button className="btn btn-outline-secondary" type="button">
                      <img src={PlusInactive} alt="plusInactive" />
                    </button>
                  </div>
                </div>
                <div className="quantity mobile-view">
                  <label>1 needed</label>
                </div>
              </div>
              <div className="right">
                <label>1 needed</label>
                <button type="button" className="btn">
                  Volunteer
                </button>
              </div>
            </div>
          </div>
        )}

        {(actionType === "all-tab" || actionType === "buy-tab") && (
          <div className="need-box">
            <div className="column column-first mr-auto">
              <div className="left d-inline-block">
                <img src={Buy} alt="buy" />
              </div>
              <div className="right d-inline-block">
                <h5>
                  Arts & crafts, books for children ages 18, 15, 4, 2, 3 and a
                  14 month
                </h5>
              </div>
            </div>
            <div className="column column-price">
              <label>$20.99</label>
              <label className="quantity">8 needed</label>
            </div>
            <div className="divider mobile-view">
              <hr />
            </div>
            <div className="column column-last">
              <div className="left">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <button className="btn btn-outline-secondary" type="button">
                      <img src={MinusActive} alt="minusactive" />
                    </button>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue="1"
                  />
                  <div className="input-group-append">
                    <button className="btn btn-outline-secondary" type="button">
                      <img src={PlusActive} alt="plusActive" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="right">
                <label>8 needed</label>
                <button type="button" className="btn">
                  Buy
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <div className={"content theme-page " + props?.thmName}>
      <Profileheadermobile history={props.history} />
      <div className="preview-header-row">
        <label className="status-label mx-auto text-center">Preview mode</label>
        <Link to="/me/create/step5" className="close">
          <img
            src={Close}
            alt="close"
            onClick={() => localStorage.setItem("themeName", props?.thmName)}
          />
        </Link>
      </div>

      <div className="section_one">
        <div className="container">
          <div className="row row1">
            <div className="col left">
              <h2>Portland Forest Fire Emergency Wishlist</h2>
            </div>
            <div className="col right text-right desktop-view">
              <div className="wrapper text-left">
                <div className="progress w-100">
                  <div
                    className="progress-bar sunshine"
                    role="progressbar"
                    style={{ width: "50%" }}
                    aria-valuenow="28"
                    aria-valuemin="0"
                    aria-valuemax="40"
                  ></div>
                </div>
                <label>1 of 2 needs actioned</label>
              </div>
            </div>
          </div>

          <div className="row row2">
            <div className="col">
              <label>
                Organized by{" "}
                <strong>{`${userInfo?.firstName} ${userInfo?.lastName}`}</strong>
                <img src={RoundBlueTick} alt="RoundBlue" />
              </label>
            </div>
          </div>
          <div className="row row3">
            <div className="col">
              <ul>
                <li>
                  <img src={Leaf} alt="Leaf" />
                  {showPurpose()}
                </li>
                <li>Updated 0 mins ago</li>
              </ul>
            </div>
          </div>
          <div className="mobile-view progress-line">
            <div className="wrapper text-left">
              <div className="progress w-100">
                <div
                    className="progress-bar sunshine"
                    role="progressbar"
                    style={{ width: "50%" }}
                    aria-valuenow="28"
                    aria-valuemin="0"
                    aria-valuemax="40"
                  ></div>
              </div>
              <label>1 of 5 needs actioned</label>
            </div>
          </div>
          <div className="row row4">
            <div className="col">
              <p>{userCreateList?.description?.slice(0, 50)}</p>
            </div>
          </div>
          <div className="row row5">
            <div className="col">
              <button type="button" className="btn btn-donate">
                Donate
              </button>
              <button type="button" className="btn btn-share">
                <img src={Share} alt="Share" />
                Share
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="section_two">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="action-row">
                <div className="mobile-view">
                  <div className="dropdown">
                    <button
                      className="btn btn-secondary dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Filter
                      <img src={Filter} className="ml-auto" alt="Filter" />
                    </button>
                  </div>
                  <button type="button" className="btn grid-btn active">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="4.5" cy="6.5" r="4.5" />
                      <circle cx="4.5" cy="17.5" r="4.5" />
                      <rect x="12" y="2" width="12" height="9" rx="4" />
                      <rect x="12" y="13" width="12" height="9" rx="4" />
                    </svg>
                  </button>
                  <button type="button" className="btn list-btn">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="2.5" cy="12.5" r="2.5" />
                      <circle cx="2.5" cy="19.5" r="2.5" />
                      <circle cx="2.5" cy="5.5" r="2.5" />
                      <rect x="8" y="10" width="16" height="4" rx="2" />
                      <rect x="8" y="18" width="16" height="4" rx="2" />
                      <rect x="8" y="3" width="16" height="4" rx="2" />
                    </svg>
                  </button>
                </div>
                <ul className="nav nav-tabs mr-auto" id="myTab" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="all-tab"
                      data-toggle="tab"
                      href="#all"
                      role="tab"
                      aria-controls="all"
                      aria-selected="true"
                      onClick={() => setActionType("all-tab")}
                    >
                      All Needs
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="buy-tab"
                      data-toggle="tab"
                      href="#buy"
                      role="tab"
                      aria-controls="buy"
                      aria-selected="false"
                      onClick={() => setActionType("buy-tab")}
                    >
                      Buy
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="give-tab"
                      data-toggle="tab"
                      href="#give"
                      role="tab"
                      aria-controls="give"
                      aria-selected="false"
                      onClick={() => setActionType("give-tab")}
                    >
                      Give
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="do-tab"
                      data-toggle="tab"
                      href="#do"
                      role="tab"
                      aria-controls="do"
                      aria-selected="false"
                      onClick={() => setActionType("do-tab")}
                    >
                      Do
                    </a>
                  </li>
                </ul>
                <div className="for-desktop">
                  <div className="dropdown">
                    <button
                      className="btn btn-secondary dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Filter
                      <img src={Filter} className="ml-auto" alt="Filter" />
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <a className="dropdown-item" href="#.">
                        Action
                      </a>
                      <a className="dropdown-item" href="#.">
                        Another action
                      </a>
                      <a className="dropdown-item" href="#.">
                        Something else here
                      </a>
                    </div>
                  </div>
                  <button type="button" className="btn grid-btn active ">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="4.5" cy="6.5" r="4.5" />
                      <circle cx="4.5" cy="17.5" r="4.5" />
                      <rect x="12" y="2" width="12" height="9" rx="4" />
                      <rect x="12" y="13" width="12" height="9" rx="4" />
                    </svg>
                  </button>
                  <button type="button" className="btn list-btn">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="2.5" cy="12.5" r="2.5" />
                      <circle cx="2.5" cy="19.5" r="2.5" />
                      <circle cx="2.5" cy="5.5" r="2.5" />
                      <rect x="8" y="10" width="16" height="4" rx="2" />
                      <rect x="8" y="18" width="16" height="4" rx="2" />
                      <rect x="8" y="3" width="16" height="4" rx="2" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="all"
                  role="tabpanel"
                  aria-labelledby="all-tab"
                >
                  {renderPriorityContent()}
                  {renderNonPriorityContent()}
                </div>

                <div
                  className="tab-pane fade"
                  id="buy"
                  role="tabpanel"
                  aria-labelledby="buy-tab"
                >
                  {_.times(3, () => {
                    return renderPriorityContent();
                  })}

                  {_.times(2, () => {
                    return renderNonPriorityContent();
                  })}
                </div>
                <div
                  className="tab-pane fade"
                  id="give"
                  role="tabpanel"
                  aria-labelledby="give-tab"
                >
                  {_.times(3, () => {
                    return renderPriorityContent();
                  })}

                  {_.times(2, () => {
                    return renderNonPriorityContent();
                  })}
                </div>
                <div
                  className="tab-pane fade"
                  id="do"
                  role="tabpanel"
                  aria-labelledby="do-tab"
                >
                  {_.times(3, () => {
                    return renderPriorityContent();
                  })}

                  {_.times(2, () => {
                    return renderNonPriorityContent();
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="theme-page-footer">
        <label>
          Powered by <img src={LogoFooter} alt="LogoFooter" />
        </label>
      </div>
    </div>
  );
};
