import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Back from "../../../assets/images/back.png";
import { getAndPostActionListUpdateNotification } from "../../../Store/Actions/userActions";
import { ToastContainer, toast } from "react-toastify";
import "../../../../node_modules/react-toastify/dist/ReactToastify.min.css";
import AxiosApi from "../../../Config/axios-api";

export default function Notificationlist(props) {
  const dispatch = useDispatch();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const actionListNotifications = JSON.parse(
    localStorage.getItem("actionListNotifications")
  );
  const selectedList = JSON.parse(localStorage.getItem("selectedList"));
  const [enableEmailNotification, setEnableEmailNotification] = useState();
  const [newMsgNotify, setNewMsgNotify] = useState();
  const [newDonationNotify, setNewDonationNotify] = useState();
  const [requestHelpNotify, setRequestHelpNotify] = useState();
  const [completedActionNotify, setCompletedActionNotify] = useState();
  const [notifyUpdate, setNotifyUpdate] = useState(false);
  const [notifyUpdateCall, setNotifyUpdateCall] = useState("");
  const getActionListData = async () => {
    const { data } = await AxiosApi.get(
      "/actionList/getActionListByActionListId/" + props.actionId
    );
console.log({data})
    const notification = data[0]?.notification;
    setEnableEmailNotification(notification?.email);
    setNewMsgNotify(notification?.newMsg);
    setNewDonationNotify(notification?.newDonation);
    setRequestHelpNotify(notification?.requestHelp);
    setCompletedActionNotify(notification?.completeAction);
  };

  useEffect(() => {
    getActionListData();
  }, []);

  useEffect(() => {
    console.log(notifyUpdateCall);
    if (notifyUpdateCall) {
      notificationUpdate();
    }
  }, [notifyUpdateCall]);

  const notificationUpdate = () => {
    let data = {
      actionListId: selectedList?.actionListId,
      notification: {
        email: enableEmailNotification,
        requestHelp: requestHelpNotify,
        completeAction: completedActionNotify,
        newMsg: newMsgNotify,
        newDonation: newDonationNotify,
      },
    };
    dispatch(
      getAndPostActionListUpdateNotification(data, userInfo?.token)
    ).then((res) => {
      if (res?.data) {
        getActionListData();
        const { msg, notification } = res?.data;
        notifyUpdate && toast.success(msg);
      } else {
        toast.error("Somthing went wrong");
      }
    });
  };

  return (
    <div
      class="tab-pane fade show active"
      id="notifications "
      role="tabpanel"
      aria-labelledby="notifications-tab"
    >
      <div class="mobile-header-inner">
        <nav class="navbar">
          <a onClick={props.backdata} class="back">
            <img src={Back} />
          </a>
          <label class="navbar-brand mx-auto middle-label">Notifications</label>
        </nav>
      </div>
      <h2 class="heading2">Notifications settings</h2>
      <p class="para para2">
        Enable email notifications to receive alerts when there is activity
        related to your list.
      </p>
      <form class="notification-form">
        <div class="row">
          <div class="col-xl-12 col-lg-12">
            <label for="emailNotification">
              Email notifications
              <input
                type="checkbox"
                onClick={() => setNotifyUpdate(true)}
                id="emailNotification"
                checked={enableEmailNotification}
                onChange={(e) => {
                  setEnableEmailNotification((val) => !val);
                  setNotifyUpdateCall("emailNotification");
                }}
              />
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-xl-5 col-lg-5">
            <label for="requested">
              Someone requested to help
              <input
                type="checkbox"
                id="requested"
                checked={requestHelpNotify}
                onClick={() => setNotifyUpdate(true)}
                onChange={(e) => {
                  setRequestHelpNotify((val) => !val);
                  setNotifyUpdateCall("requested");
                }}
              />
              <span class="checkmark"></span>
            </label>
            <label for="newMessage">
              New message in a chat
              <input
                type="checkbox"
                id="newMessage"
                checked={newMsgNotify}
                onClick={() => setNotifyUpdate(true)}
                onChange={(e) => {
                  setNewMsgNotify((val) => !val);
                  setNotifyUpdateCall("newMessage");
                }}
              />
              <span class="checkmark"></span>
            </label>
          </div>
          <div class="col-xl-5 col-lg-5">
            <label for="actionCompleted">
              Someone completed an action
              <input
                type="checkbox"
                id="actionCompleted"
                checked={completedActionNotify}
                onClick={() => setNotifyUpdate(true)}
                onChange={(e) => {
                  setCompletedActionNotify((val) => !val);
                  setNotifyUpdateCall("actionCompleted");
                }}
              />
              <span class="checkmark"></span>
            </label>
            <label for="newDonation">
              New donations
              <input
                type="checkbox"
                id="newDonation"
                checked={newDonationNotify}
                onClick={() => setNotifyUpdate(true)}
                onChange={(e) => {
                  setNewDonationNotify((val) => !val);
                  setNotifyUpdateCall("newDonation");
                }}
              />
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
      </form>
      <ToastContainer autoClose={3000} />
    </div>
  );
}
