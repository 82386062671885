import React, { useRef, useState, useEffect, useCallback } from "react";
import Cropper from "react-easy-crop";
import Slider from "@material-ui/core/Slider";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import getCroppedImg from "./getCroppedImg";

export const CropImage = (props) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [imageFile, setImageFile] = useState(props?.imageFilll);

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    console.log(croppedArea, croppedAreaPixels);
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageFile,
        croppedAreaPixels,
        rotation
      );
      console.log("donee", { croppedImage });
      setCroppedImage(croppedImage);
      props?.handleProfileImg(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);

  const handleCancelBut = () => {
    props?.handleCancelProfileImg();
    setImageFile(null);
  };

  console.log("zoom, rotation--", zoom, rotation);
  return (
    <div className="popup-backdropcss">
      <div className="mainReactEasyCrop_Container">
        <Cropper
          image={imageFile}
          crop={crop}
          zoom={zoom}
          rotation={rotation}
          aspect={4 / 3}
          onCropChange={setCrop}
          onRotationChange={setRotation}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>
      <div>
        <div className="controls">
          <div className="sliderContainer">
            <Typography variant="overline">Zoom</Typography>
            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e, zoom) => setZoom(zoom)}
            />
          </div>
          <div className="sliderContainer">
            <Typography variant="overline">Rotation</Typography>
            <Slider
              value={rotation}
              min={0}
              max={360}
              step={1}
              aria-labelledby="Rotation"
              onChange={(e, rotation) => setRotation(rotation)}
            />
          </div>
          <Button
            onClick={showCroppedImage}
            variant="contained"
            color="primary"
          >
            Set
          </Button>
          <Button
            onClick={() => handleCancelBut()}
            variant="contained"
            color="danger"
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};
