import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import ListCreationHeader from "../../../Common/MobileHeaderOne";
import { addDescription } from "../../../Store/Actions/userActions";

export default function ListCreationDescriptionFullPreview(props) {
  let { register, handleSubmit, errors } = useForm();
  let [character, setCharacter] = useState(0);
  let [description, setDescription] = useState("");
  let dispatch = useDispatch();

  let history = useHistory()
  console.log("object", props);
  const createList = useSelector((state) => state.createList);
  const { userCreateList } = createList;

  useEffect(() => {
    if (props?.history?.location?.state?.detail == "editneed") {
      setDescription(props?.history?.location?.state?.desc);
      setCharacter(props?.history?.location?.state?.desc);
    } else {
      setDescription(userCreateList?.description);
      setCharacter(userCreateList?.description?.length);
    }
  }, []);

  let submit = (data) => {
    data.description = description;
    console.log("submit")
    if (props?.history?.location?.state?.detail == "editneed") {
      console.log("in if submit")
      history.push({
        pathname: `/setting/${props?.history?.location?.state?.id}`,
        state: { detail: description,data : "editneed" },
      });
    } else {
      let list = { ...userCreateList, description: data.description };
      dispatch(addDescription(list));
      props.history.push("/me/create/step3");
    }
  };

  function characterCount(e) {
    setCharacter(e.target.value.length);
    setDescription(e.target.value);
  }

  return (
    <div>
      <ListCreationHeader
        pageTitle="Description"
        previousPage={null}
        close={
          props?.history?.location?.state?.detail == "editneed"
            ? `/setting/${props?.history?.location?.state?.id}`
            : "/me/create/step3"
        }
        history={props.history}
      />

      <div className="content">
        <h2 className="heading2">Step 3 of 5: Description</h2>
        <div className="row">
          <div className="col-12">
            <form onSubmit={handleSubmit(submit)}>
              <div className="form-group">
                <label htmlFor="title">
                  Tell your story and describe needs
                </label>
                <div className="story_description_wrapper full_view">
                  <textarea
                    rows="10"
                    onChange={characterCount}
                    maxLength="400"
                    className="form-control"
                    name="description"
                    ref={register({ maxLength: 400 })}
                    id="title"
                    value={description}
                    placeholder="e.g. Hello everyone! I can’t pay for the deliver, and I decided to ask someone who has a car to help me. I need to move 4 chairs etc..."
                  ></textarea>
                  {/*<button type="button" className="expand_button"></button>*/}
                  <div className="save_desc_btn">
                    <button type="submit">Save</button>
                  </div>
                </div>
                {Object.keys(errors).length !== 0 && (
                  <div className="error-box">
                    <ul>
                      {errors.description && (
                        <li key="1" className="incorrect">
                          {errors.description.message}
                        </li>
                      )}
                    </ul>
                  </div>
                )}
                <div className="info d-flex">
                  <span className="mr-auto">
                    You can edit the description later.
                  </span>
                  <span className="limit">{character}/400</span>
                </div>
              </div>
              <div className="fixed-bottom bottom-btn">
                <button className="btn btn-primary">Next</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
