import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import UserDeskTopHeaderOne from '../../Common/UserDeskTopHeaderOne';
import ListCreationMobileHeader from '../../Common/MobileHeaderOne';
import error403 from '../../assets/images/403.png';
const Error403 = (props) => {
  return (
    <>
      <UserDeskTopHeaderOne history={props.history} />
      <ListCreationMobileHeader
        pageTitle='Contact Us'
        previousPage={null}
        close='/account-created'
        history={props.history}
      />

      <div class='content dashboard error'>
        <div class='content-wrapper' style={{ marginTop: 50 }}>
          <div class='error-img-wrapper'>
            <img src={error403} alt='403' />
          </div>
          <p class='error-message'>
            Access to this resource on the server is denied
          </p>
          <button type='button' class='btn btn-extra'>
            Back to Home
          </button>
        </div>
      </div>
    </>
  );
};

export default Error403;
