import React, { useState, useRef, useEffect } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "../../Store/Actions/userActions";
import visibleIcon from "../../assets/images/view_enabled.png";
import hideIcon from "../../assets/images/view_disabled.png";
import MobileScreenLogo from "../../Common/MobileScreenLogo";
import DeskTopHeader from "../../Common/DeskTopHeader";

import backBut from "../../assets/images/back.png";
import logoWhiteDesktop from "../../assets/images/logo_white_desktop.svg";
// import closeBut from "../../../assets/images/close.png";

const ResetPassword = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const history = useHistory();
  const [passwordShow, setPasswordShow] = useState(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);

  const { register, handleSubmit, watch, errors, reset } = useForm({
    // by setting criteriaMode to 'all',
    // all validation errors for single field will display at once
    criteriaMode: "all",
  });
  const newPassword = useRef({});
  newPassword.current = watch("newPassword", "");
  let errorsLength = Object.keys(errors).length;

  let passwordLengthInvalid =
    errors?.newPassword?.types?.required ||
    errors?.newPassword?.types?.minLength;
  let passwordUppercaseInValid =
    errors.newPassword?.types?.validate?.includes("uppercaseInValid");
  let passwordLowercaseInValid =
    errors.newPassword?.types?.validate?.includes("lowercaseInValid");
  let passwordNumberInValid =
    errors.newPassword?.types?.validate?.includes("numberInValid");
  let passwordSpecialInValid =
    errors.newPassword?.types?.validate?.includes("specialInValid");

  let isVailidate = errors?.newPassword?.types?.validate;

  const userResetPassword = useSelector((state) => state.userResetPassword);
  const { userResetPasswordInfo, error } = userResetPassword;

  useEffect(() => {
    if (userResetPasswordInfo) {
      history.push("/login");
    }
    if (error) {
      reset();
    }
  }, [userResetPasswordInfo, history, error, reset]);

  const onSubmit = (data) => {
    // e.preventDefault()
    // console.log("object",data)
    // console.log({ data });
    const { newPassword } = data;
    // dispatch(resetPassword(newPassword, id));
    // history.push("/forgot-password-success");
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (isVailidate === "true" && newPassword.current) {
      dispatch(resetPassword(newPassword.current, id));
      // history.push("/forgot-password-success");
    }
  };

  return (
    <div>
      <div class="mobile-header">
        <MobileScreenLogo navlink="Sign up"   linkdata="/discover" />
      </div>
      <div class="content signup_content">
        <div className="logo-desktop ml-0 ml-xl-3 ml-lg-3 ml-md-2">
        <Link to="/discover">
          <img src={logoWhiteDesktop} />
          </Link>
        </div>
      </div>
      <div className="right-signupcontent">
        <div className="login-link">
          <DeskTopHeader
            navlink="Sign up"
            linkdata="/signup"
            displayContent="Already have an account?"
          />
        </div>

        <div class="row">
          <div class="col-12">
            <form
              // onSubmit={handleSubmit(onSubmit)}
              // onSubmit={(e) => handleSubmit(onSubmit(e))}
              onSubmit={(e) => handleFormSubmit(e)}
              class="signup_form custom_Form_View"
            >
              <h2 className="" style={{ fontWeight: "800" }}>
                Reset password
              </h2>
              <div className="form-group">
                <label htmlFor="newPassword">New password</label>
                <input
                  type={passwordShow ? "text" : "password"}
                  className={
                    isVailidate === undefined || isVailidate === "true"
                      ? "form-control"
                      : "form-control invalid"
                  }
                  id="newPassword"
                  name="newPassword"
                  onChange={handleSubmit(onSubmit)}
                  ref={register({
                    required: true,
                    minLength: 8,

                    validate: (value) => {
                      let errorsChar = "";
                      const uppercaseValid = /[A-Z]/;
                      const lowercaseValid = /[a-z]/;
                      const numberValid = /[0-9]/i;
                      const specialValid =
                        /[?=.*[!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]/i;

                      if (value.search(uppercaseValid) < 0)
                        errorsChar += " uppercaseInValid";
                      if (value.search(lowercaseValid) < 0)
                        errorsChar += " lowercaseInValid";
                      if (value.search(numberValid) < 0)
                        errorsChar += " numberInValid";
                      if (value.search(specialValid) < 0)
                        errorsChar += " specialInValid";
                      return errorsChar || "true";
                    },
                  })}
                  placeholder="Password"
                  autoComplete={false}
                />
                <button
                  type="button"
                  className="view_button"
                  style={
                    passwordShow
                      ? { backgroundImage: `url(${visibleIcon})` }
                      : { backgroundImage: `url(${hideIcon})` }
                  }
                  onClick={() => setPasswordShow(!passwordShow)}
                ></button>
              </div>
              {error?.msg.includes("previously") && (
                <div className="error-msg">
                  Your new password can't be one you previously used. Try again
                </div>
              )}
              <div className={errorsLength === 0 ? "info-box" : "error-box"}>
                <label>your password must have:</label>
                <ul>
                  <li
                    key="1"
                    className={passwordLengthInvalid ? "incorrect" : "correct"}
                  >
                    {!!errorsLength && (
                      <img
                        src={`images/${
                          passwordLengthInvalid ? "incorrect" : "correct"
                        }.png`}
                        alt="PasswordLength"
                      />
                    )}
                    At least 8 characters
                  </li>
                  <li
                    key="2"
                    className={
                      passwordUppercaseInValid ? "incorrect" : "correct"
                    }
                  >
                    {!!errorsLength && (
                      <img
                        src={`images/${
                          passwordUppercaseInValid ? "incorrect" : "correct"
                        }.png`}
                        alt="PasswordUpperCase"
                      />
                    )}
                    1 uppercase letter
                  </li>
                  <li
                    key="3"
                    className={
                      passwordLowercaseInValid ? "incorrect" : "correct"
                    }
                  >
                    {!!errorsLength && (
                      <img
                        src={`images/${
                          passwordLowercaseInValid ? "incorrect" : "correct"
                        }.png`}
                        alt="PasswordLowerCase"
                      />
                    )}
                    1 lowercase letter
                  </li>
                  <li
                    key="4"
                    className={passwordNumberInValid ? "incorrect" : "correct"}
                  >
                    {!!errorsLength && (
                      <img
                        src={`images/${
                          passwordNumberInValid ? "incorrect" : "correct"
                        }.png`}
                        alt="PasswordNumber"
                      />
                    )}
                    1 number
                  </li>
                  <li
                    key="5"
                    className={passwordSpecialInValid ? "incorrect" : "correct"}
                  >
                    {!!errorsLength && (
                      <img
                        src={`images/${
                          passwordSpecialInValid ? "incorrect" : "correct"
                        }.png`}
                        alt="PasswordSpecial"
                      />
                    )}
                    1 symbol
                  </li>
                </ul>
              </div>

              {/* <div className="form-group">
                <label htmlFor="confirmPassword">Confirm new password</label>
                <input
                  type={confirmPasswordShow ? "text" : "password"}
                  className="form-control"
                  id="confirmPassword"
                  name="confirmPassword"
                  placeholder="Password"
                  ref={register({
                    required: "Enter your repeat password",
                    validate: (value) =>
                      value === newPassword.current ||
                      "Password is different from the previously typed one.",
                  })}
                />
                <button
                  type="button"
                  className="view_button"
                  style={
                    confirmPasswordShow
                      ? { backgroundImage: `url(${visibleIcon})` }
                      : { backgroundImage: `url(${hideIcon})` }
                  }
                  onClick={() => setConfirmPasswordShow(!confirmPasswordShow)}
                ></button>
              </div> */}
              <div className={errorsLength === 0 ? "info-box" : "error-box"}>
                {errors.confirmPassword && (
                  <p className="incorrect">{errors.confirmPassword.message}</p>
                )}
              </div>
              <button className="btn btn-prime">Update my password</button>
              {error?.msg.includes("Failed") && (
                <div className="error-msg">{error?.msg}</div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
