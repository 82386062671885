



export function validateCardDetails(validationform){
  let showallerror = false;
  const form = {
    cardNumber: false,
    holderName: false,
    expirydate: false,
    cvvnum: false,
    cardmsg: '',
    holdermsg: '',
    expirymsg: '',
    cvvmsg: '',
  };
  if (
    validationform.cardNumber === undefined ||
    validationform.cardNumber === ''
  ) {
    showallerror = true;
    form.cardNumber = true;
    form.cardmsg = 'Please Enter your Card Number';
  }
  if (
    validationform.holderName === undefined ||
    validationform.holderName === ''
  ) {
    showallerror = true;
    form.holderName = true;
    form.holdermsg = 'Please Enter your Card Holder Name';
  }
  if (
    validationform.expirydate === undefined ||
    validationform.expirydate === ''
  ) {
    showallerror = true;
    form.expirydate = true;
    form.expirymsg = 'Please Enter Card Expiry Date';
  }
  if (validationform.cvvnum === undefined || validationform.cvvnum === '') {
    showallerror = true;
    form.cvvnum = true;
    form.cvvmsg = 'Please Enter Cvv/CVC';
  }
  //setCardError(form);
  return {'isValid':showallerror,
          'errorMessage':form};
};

