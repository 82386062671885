import React, { useState } from "react";
import LogoWhiteDesktop from "../assets/images/logo_white_desktop.png";
import Search from "../assets/images/search.png";
import whiteSearch from "../assets/images/search_white.png";
import Person from "../assets/images/person.png";
import DownAngleWhite from "../assets/images/down_angle_white.png";
import Message from "../assets/images/message.png";
import filterImg from "../assets/images/filter.png";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../Store/Actions/userActions";
import { Link } from "react-router-dom";
import { ProfileAccSettingsRoute } from "../Routing/RouteConstants";
import ShowCartInfoPreview from "../Screens/PurchasingProcess/CartInfoPreview";
import closeimg from "../assets/images/close.png";
import { useHistory } from "react-router";

export default function UnloginuserHead(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isOpenCartInfoPreview, setIsOpenCartInfoPreview] = useState(false);
  const [show, setShow] = useState(false);
  const [searchData, setSearchData] = useState();
  const searchfun = () => {
    console.log(" in serach", searchData);
    if (searchData) {
      history.push("/search/" + searchData);
      setSearchData("");
    }
    setShow(false);
  };

  const getuserWishList = useSelector(
    (state) => state.getuserWishList.userWishlistInfo
  );

  return (
    <div className="desktop-header dashboard profile">
      <nav className="navbar navbar-expand-md">
        <a
          className="navbar-brand"
       href="/discover"
        >
          <img src="images/logo_white_desktop.svg" />
        </a>
        <div className="navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto main-menu">
            <li className="nav-item"></li>
            <li className="nav-item"></li>
          </ul>
          <div className="input-group search_group" style={{ marginRight: 10 }}>
            <input
              type="text"
              className="form-control"
              placeholder="Search..."
              value={searchData}
              onChange={(e) => setSearchData(e.target.value)}
              onKeyUp={(e) => e.keyCode === 13 && searchfun()}
            />
            <div className="input-group-append">
              <button
                className="btn btn-secondary"
                type="button"
                onClick={() => searchfun()}
              >
                <img src={Search} alt="search" />
              </button>
            </div>
          </div>

          <label
            className="cart_section for-mobilenonecart"
            style={{ borderLeft: "none", marginRight: 70, borderRight: "none" }}
            //   onClick={() => {
            //     if(getuserWishList?.length){
            //     const id =
            //       getuserWishList && getuserWishList[0]?.actionListId
            //         ? getuserWishList[0]?.actionListId
            //         : getuserWishList && getuserWishList[1]?.actionListId;
            //     props.history.push("/cart/" + id);
            //   }}
            // }
            onClick={() => setIsOpenCartInfoPreview(true)}
          >
            Cart
            <span>{getuserWishList?.length ? getuserWishList?.length : 0}</span>
          </label>
          {isOpenCartInfoPreview && (
            <ShowCartInfoPreview
              isOpenModal={(val) => setIsOpenCartInfoPreview(val)}
            />
          )}
          <div className="search-block-mobile">
            <div className="search-block-mobile">
              <button type="button">
                <img
                  src={whiteSearch}
                  alt="whitesearch"
                  onClick={() => setShow(true)}
                />
              </button>
              <div className={`input-group search_group ${show ? "show" : ""}`}>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                  value={searchData}
                  onChange={(e) => setSearchData(e.target.value)}
                  onKeyUp={(e) => e.keyCode === 13 && searchfun()}
                />
                <div className="input-group-append">
                  <button
                    className="btn btn-secondary"
                    type="button"
                    onClick={() => searchfun()}
                  >
                    <img src={Search} alt="search" />
                  </button>
                </div>
                <div className="closesearch">
                  <button className="btn btn-close">
                    <img
                      src={closeimg}
                      alt="search"
                      onClick={() => setShow(false)}
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <label
            className="cart_section display-mobilecart"
            style={{ borderLeft: "none", marginRight: 70, borderRight: "none" }}
            //   onClick={() => {
            //     if(getuserWishList?.length){
            //     const id =
            //       getuserWishList && getuserWishList[0]?.actionListId
            //         ? getuserWishList[0]?.actionListId
            //         : getuserWishList && getuserWishList[1]?.actionListId;
            //     props.history.push("/cart/" + id);
            //   }}
            // }
            onClick={() => setIsOpenCartInfoPreview(true)}
          >
            Cart
            <span>{getuserWishList?.length ? getuserWishList?.length : 0}</span>
          </label>
          <div
            className="cart_section mobile-signup"
            style={{ borderLeft: "none" }}
            onClick={() => props.history.push("/signup")}
          >
            Sign Up
          </div>
          <div
            className="cart_section mobile-login"
            style={{ borderLeft: "none", borderRight: "none" }}
            onClick={() => props.history.push("/login")}
          >
            Login
          </div>
        </div>
      </nav>
    </div>
  );
}
