import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Back from "../../../assets/images/back.png";
import checkmark_blue from "../../../assets/images/checkmark_blue.png";
import remove from "../../../assets/images/remove.png";
import edit from "../../../assets/images/edit.png";
import AxiosApi from "../../../Config/axios-api";
import { ToastContainer, toast } from "react-toastify";
import "../../../../node_modules/react-toastify/dist/ReactToastify.min.css";
import { postSendInvite } from "../../../Store/Actions/userActions";

export default function TeamList(props) {
  var mailformat =
    /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  const dispatch = useDispatch();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [fullName, setFullName] = useState();
  const [mailId, setmailId] = useState();
  const [teamlist, setListTeam] = useState();
  const [isCloseButEnable, setIsCloseButEnable] = useState(false);

  useEffect(() => {
    getsubadminList();
  }, [props?.teamMembersData]);

  const sendInvite = () => {
    var obj = {};
    obj.name = fullName;
    obj.email = mailId;
    dispatch(postSendInvite(obj, props.actionId, userInfo?.token)).then(
      (res) => {
        if (res?.data?.msg?.includes("A Sign-up mail send")) {
          toast.success(res?.data?.msg);
          setFullName("");
          setmailId("");
        }
      }
    );
  };

  const getsubadminList = async () => {
    const { data } = await AxiosApi.get(
      "/actionList/teamMembersByActionListId/" + props.actionId
    );
    if (data.success) {
      setListTeam(data.teamMembersData);
    }
  };

  return (
    <div
      class="tab-pane fade show active"
      id="team"
      role="tabpanel"
      aria-labelledby="team-tab"
    >
      <div class="mobile-header-inner">
        <nav class="navbar">
          <a onClick={props.backdata} class="back">
            <img src={Back} />
          </a>
          <label class="navbar-brand mx-auto middle-label">Team</label>
        </nav>
      </div>
      <h2 class="heading2">Team</h2>
      <p class="para para2">
        Invite team members to help organize this ActionList together.
      </p>
      <div class="team-form">
        {/* <h5 class='form-heading'>Organizer shipping details</h5> */}
        <div class="form-group row">
          <div class="col-xl-6 col-lg-6">
            <label for="fullname" class="label">
              Full Name
            </label>
            <input
              type="text"
              class="form-control"
              id="fullname"
              placeholder="ex. John Smith"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
          </div>
          <div class="col-xl-6 col-lg-6">
            <label for="emailaddress" class="label">
              Email
            </label>
            <input
              type="email"
              class="form-control"
              id="emailaddress"
              placeholder="ex. example@gmail.com"
              value={mailId}
              onChange={(e) => setmailId(e.target.value)}
            />
          </div>
        </div>
        <button
          type="submit"
          class="btn btn-prime"
          disabled={fullName && mailformat.test(mailId) ? false : true}
          onClick={() => sendInvite()}
        >
          Send Invite
        </button>
      </div>
      <div class="admin-block">
        <div class="wrapper">
          <h4>Current Organizers</h4>
          {teamlist &&
            teamlist.length > 0 &&
            teamlist.slice().reverse().map((item, index) => {
              return (
                <div class="detail-box">
                  <div class="accordion" id={"accordion" + index}>
                    <div class="card">
                      <div class="card-header" id="headingOne">
                        <h5 class="mb-0">
                          <button
                            class="btn btn-link collapsed"
                            data-toggle="collapse"
                            data-target={
                              !isCloseButEnable && "#collapse" + index
                            }
                            style={{background:`${item.admin && 'rgb(187 179 249)'}`}}
                          >
                            <div class="btn-panel">
                              {/* <a
                                aria-expanded="false"
                                aria-controls={"collape" + index}
                                class="edit"
                                onClick={() => {
                                  // setFullName(item?.firstName + ' ' + item?.lastName);
                                  // setmailId(item?.email)
                                  setIsCloseButEnable(false);
                                }}
                              >
                                <img
                                  src={edit}
                                  onClick={() => {
                                    // setFullName(item?.firstName + ' ' + item?.lastName);
                                    // setmailId(item?.email)
                                    setIsCloseButEnable(false);
                                  }}
                                />
                              </a> */}
                              <a
                                data-target="#removeAdminModal"
                                data-toggle="modal"
                                class="remove"
                                onClick={(e) => (
                                  window
                                    .$("#collapse" + index)
                                    .collapse("hide"),
                                  props.selectedMember(item.userId),
                                  props.slctedActnForRemove(item),
                                  setIsCloseButEnable(true)
                                  // props.getsubadminList()
                                )}
                                // data-target={'#collapse' + index}
                              >
                                {!item.admin && <img src={remove} />}
                              </a>
                              <a class="done">
                                <img src={checkmark_blue} />
                              </a>
                            </div>
                            <label>
                              {item?.firstName} {item?.lastName} 
                            </label>
                            <label>{item?.email}</label>
                          </button>
                        </h5>
                      </div>

                      <div
                        id={"collapse" + index}
                        class="collapse content"
                        aria-labelledby="headingOne"
                        data-parent={"#accordion" + index}
                      >
                        <div class="card-body">
                          <form>
                            <div class="form-group">
                              <label for="fullName" class="label">
                                Full Name
                              </label>
                              <input
                                readOnly
                                //disabled={true}
                                type="text"
                                class="form-control"
                                id="fullName"
                                placeholder=""
                                defaultValue={
                                  item?.firstName + " " + item?.lastName
                                }
                                value={fullName}
                                onChange={(e) => setFullName(e.target.value)}
                              />
                            </div>
                            <div class="form-group">
                              <label for="emailAddress" class="label">
                                Email
                              </label>
                              <input
                                readOnly
                                // disabled={true}
                                type="text"
                                class="form-control"
                                id="emailAddress"
                                placeholder=""
                                value={item?.email}
                                defaultValue={item?.email}
                                value={mailId}
                                onChange={(e) => setmailId(e.target.value)}
                              />
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <ToastContainer autoClose={3000} />
    </div>
  );
}
