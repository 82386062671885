import React from 'react';
import { ShowPreviewData } from './showPreviewData';

const ListCreationPreviewGreenery = (props) => {
  return (
    <>
      <ShowPreviewData
        thmName = 'greenery'
        history = {props?.history}
      />
    </>
  );
};

export default ListCreationPreviewGreenery;
