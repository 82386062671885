import React, { useState, useEffect, useRef } from 'react';
import $ from 'jquery';
import goBackImage from '../../assets/images/back_black.png';
import Profileheadermobile from '../../Common/Profileheadermobile';
import ProfileLeftPane from '../../Common/ProfileLeftPane';
import {
  forgotPassword,
  userchangePassword,
} from '../../Store/Actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import UnloginuserHead from '../../Common/UnloginuserHead';
import Cookies from 'js-cookie';
import { isMobile } from "react-device-detect";

const PasswordReset = ({ ...props }) => {
  const { register, handleSubmit, reset, errors } = useForm();
  const {
    register: change_pass_register,
    handleSubmit: change_pass_handleSubmit,
    watch: watch,
    errors: change_pass_errors,
  } = useForm();
  const dispatch = useDispatch();
  const [forgetPasStatus, setForgetStatus] = useState(false);
  const userForgotPassword = useSelector((state) => state.userForgotPassword);
  const { loading, userForgotPasswordInfo, error } = userForgotPassword;
  const userSignin = useSelector((state) => state.userSignin);
  const { loading: userloading, userInfo, error: usererror } = userSignin;
  const userChangePassword = useSelector((state) => state.userChangePassword);
  const {
    loading: passloading,
    userchangepasswordInfo,
    error: passerror,
  } = userChangePassword;
  const [chagepassmsg, setchagepassmsg] = useState('');
  const [errormessage, setErrorMsg] = useState('');
  const [email, setEmail] = useState('');
  const newPassword = useRef({});
  newPassword.current = watch('newPassword', '');

  const toggleForgotPasswordModal = () => {
    setForgetStatus(false);
    window.$('#forgotPasswordModal').modal('show');
  };

  const onSubmit = (data) => {
    setErrorMsg('');
    localStorage.setItem('forgotPasswordEmail', data.email);
    dispatch(forgotPassword(data.email));
  };

  const changePassword = (data) => {
    var obj = {};
    obj.email = userInfo.email;
    obj.password = data.currentPassword;
    obj.newPassword = data.newPassword;
    dispatch(userchangePassword(obj));
  };

  useEffect(() => {
    if (userForgotPasswordInfo?.msg.includes('A password reset')) {
      userForgotPasswordInfo.msg = '';
      setForgetStatus(true);
    }
    if (error?.msg?.includes('User not exists')) {
      setErrorMsg(error?.msg);
      error.msg = '';
    } else {
      setErrorMsg(error?.msg);
    }
    return () => {
      //
    };
  }, [userForgotPasswordInfo, error]);

  useEffect(() => {
    if (userchangepasswordInfo?.msg.includes('password changed')) {
      setchagepassmsg(userchangepasswordInfo?.msg);
      document.querySelectorAll('input').forEach((input) => {
        input.value = '';
        input.removeAttribute('value');
      });
      setTimeout(
        function () {
          setchagepassmsg('');
        }.bind(this),
        5000
      );
      userchangepasswordInfo.msg = '';
    }
    if (passerror?.msg) {
      setchagepassmsg(passerror?.msg);
      setTimeout(
        function () {
          setchagepassmsg('');
        }.bind(this),
        5000
      );
      passerror.msg = '';
    }
    return () => {
      //
    };
  }, [userchangepasswordInfo, passerror]);

  

  return (
    <div>
        {!isMobile &&(Cookies.get('token') ? (
        <Profileheadermobile history={props.history} />
      ) : (
        <UnloginuserHead history={props.history} />
        ))}
      <div className='content profile'>
        <ProfileLeftPane activePage='change-password' />
        <div class='tab-content' id='settingsTabContent'>
          <div
            class='tab-pane fade'
            id='account-settings'
            role='tabpanel'
            aria-labelledby='account-settings-tab'
          >
            Account settings
          </div>
          <div
            class='tab-pane fade'
            id='payment-methods'
            role='tabpanel'
            aria-labelledby='payment-methods-tab'
          >
            Payment methods
          </div>
          <div
            class='tab-pane fade'
            id='email-notifications'
            role='tabpanel'
            aria-labelledby='email-notifications-tab'
          >
            Email notifications
          </div>
          <div
            class='tab-pane fade show active'
            id='password-reset'
            role='tabpanel'
            aria-labelledby='password-reset-tab'
          >
            <div class='mobile-header'>
              <nav class='navbar'>
                <div onClick={() => props.history.goBack()} class='back'>
                  <img src={goBackImage} alt='go-back' />
                </div>
                <label class='navbar-brand mx-auto middle-label'>
                  Change Password
                </label>
              </nav>
            </div>
            <form
              class='password-reset form-1'
              style={{ margin: '50px 0 70px 25%;' }}
              onSubmit={change_pass_handleSubmit(changePassword)}
            >
              <h2 class='heading2'>Change Password</h2>
              <div class='form-group'>
                <label for='currentPassword'>Enter your password</label>
                <input
                  type='password'
                  class='form-control'
                  id='currentPassword'
                  name='currentPassword'
                  placeholder='Current password'
                  ref={change_pass_register({
                    required: 'Current Password is required!',
                  })}
                />
                {Object.keys(change_pass_errors).length !== 0 && (
                  <div className='error-box'>
                    <ul>
                      {change_pass_errors.currentPassword && (
                        <li key='1' className='incorrect'>
                          {change_pass_errors.currentPassword.message}
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              </div>
              <p class="forgot_password_at_account_setting" onClick={toggleForgotPasswordModal}>Forgot password?</p>
              <div class='form-group'>
                <label for='newPassword'>New password</label>
                <input
                  type='password'
                  class='form-control'
                  id='newPassword'
                  name='newPassword'
                  placeholder='Password'
                  ref={change_pass_register({
                    required: 'New Password is Required!',
                    pattern: {
                      value:
                        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,100}$/,
                      message:
                        'your password must have:  At least 8 characters, 1 uppercase letter,1 lowercase letter, 1 number & 1 Special charecter ',
                    },
                  })}
                />
                {Object.keys(change_pass_errors).length !== 0 && (
                  <div className='error-box'>
                    <ul>
                      {change_pass_errors.newPassword && (
                        <li key='1' className='incorrect'>
                          {change_pass_errors.newPassword.message}
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              </div>
              <div class='form-group'>
                <label for='confirmPassword'>Confirm new password</label>
                <input
                  type='password'
                  class='form-control'
                  id='confirmPassword'
                  name='confirmPassword'
                  placeholder='Password'
                  ref={change_pass_register({
                    required: 'Repeat password is required !',
                    validate: (value) =>
                      value === newPassword.current ||
                      'The passwords do not match',
                  })}
                />
                {Object.keys(change_pass_errors).length !== 0 && (
                  <div className='error-box'>
                    <ul>
                      {change_pass_errors.confirmPassword && (
                        <li key='1' className='incorrect'>
                          {change_pass_errors.confirmPassword.message}
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              </div>
              <button type='submit' class='btn btn-prime'>
                Change password
              </button>
              <div
                style={{
                  marginTop: 20,
                  textAlign: 'center',
                  color: chagepassmsg.includes('password changed')
                    ? 'green'
                    : 'red',
                }}
              >
                {chagepassmsg}
              </div>
            </form>
          </div>
        </div>
      </div>
      <div
        class='modal fade forgot-password-modal'
        id='forgotPasswordModal'
        tabindex='-1'
        role='dialog'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        {/* Modal */}
        {!forgetPasStatus ? (
          <div class='modal-dialog modal-dialog-centered' role='document'>
            <div class='modal-content'>
              <div class='modal-body'>
                <button
                  type='button'
                  class='btn-close'
                  data-dismiss='modal'
                  aria-label='Close'
                >
                  <img src='images/close2.png' />
                </button>
                <div class='mobile-header'>
                  <nav class='navbar'>
                    <label class='navbar-brand mx-auto middle-label'>
                      Forgot password
                    </label>
                    <a href='#' class='cross'>
                      <img src='images/close.png' />
                    </a>
                  </nav>
                </div>
                <div class='content'>
                  <h2 class='heading2'>Forgot your password?</h2>
                  <p class='para'>
                    To reset your password, enter the email address you use to
                    sign in to <span>actionlist.com</span>
                  </p>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div class='form-group'>
                      <label for='email'>Your email address</label>
                      <input
                        // type='email'
                        // class='form-control'
                        // id='email'
                        // value={forgetmail}
                        // onChange={(e) => setForgetMail(e.target.value)}
                        // placeholder='example@gmail.com'
                        type='email'
                        className='form-control'
                        id='email'
                        name='email'
                        ref={register({
                          required: 'Enter your email',
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: 'Enter a valid email address',
                          },
                        })}
                        value={email} 
                        placeholder='example@gmail.com'
                        onChange ={(e) => setEmail(e.target.value)}
                      />
                      <div className='warningmessage'>{errormessage}</div>
                      {Object.keys(errors).length !== 0 && (
                        <div className='error-box'>
                          <ul>
                            {errors.email && (
                              <li key='1' className='incorrect'>
                                {errors.email.message}
                              </li>
                            )}
                          </ul>
                        </div>
                      )}
                    </div>
                    <button
                      type='submit'
                      // onClick={() => onSubmit()}
                      class='btn btn-prime'
                    >
                      Get a Reset Link
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div class='modal-dialog modal-dialog-centered' role='document'>
            <div class='modal-content'>
              <div class='modal-body'>
                <button
                  type='button'
                  class='btn-close'
                  data-dismiss='modal'
                  aria-label='Close'
                >
                  <img src='images/close2.png' />
                </button>
                <div class='mobile-header'>
                  <nav class='navbar'>
                    <label class='navbar-brand mx-auto middle-label'>
                      Forgot password
                    </label>
                    <a href='#' class='cross'>
                      <img src='images/close.png' />
                    </a>
                  </nav>
                </div>
                <div class='content'>
                  <h2 class='heading2'>Reset link sent</h2>
                  <p class='para'>
                    Instructions for reseting your password have been sent to{' '}
                    <span>{email}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PasswordReset;
