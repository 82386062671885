import { Link } from "react-router-dom";
import {
  ProfileAccSettingsRoute,
  ProfilePaymentMethodsRoute,
  ProfileEmailNotificationsRoute,
  ProfileChangePasswordRoute,
} from "../Routing/RouteConstants";
import { useDispatch, useSelector } from "react-redux";
import { logout, getWishList } from "../Store/Actions/userActions";
import { useHistory } from "react-router";
import Cookies from "js-cookie";
import { isMobile } from "react-device-detect";

const ProfileLeftPane = ({ activePage }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <ul className="nav nav-tabs profile-tabs" id="profileTab" role="tablist">
      <li className="nav-item">
        <Link
          className={`nav-link ${
            activePage === "account-settings" ? "active" : ""
          }`}
          id="account-settings-tab"
          data-toggle="tab"
          href="#account-settings"
          role="tab"
          aria-controls="account-settings"
          aria-selected="true"
          to={ProfileAccSettingsRoute}
        >
          Account Settings
        </Link>
      </li>
      <li className="nav-item">
        <Link
          className={`nav-link ${
            activePage === "payment-methods" ? "active" : ""
          }`}
          id="payment-methods-tab"
          data-toggle="tab"
          href="#payment-methods"
          role="tab"
          aria-controls="payment-methods"
          aria-selected="false"
          to={ProfilePaymentMethodsRoute}
        >
          Payment Methods
        </Link>
      </li>
      <li className="nav-item">
        <Link
          className={`nav-link ${
            activePage === "email-notifications" ? "active" : ""
          }`}
          id="email-notifications-tab"
          data-toggle="tab"
          href="#email-notifications"
          role="tab"
          aria-controls="email-notifications"
          aria-selected="false"
          to={ProfileEmailNotificationsRoute}
        >
          Email Notifications
        </Link>
      </li>
      <li className="nav-item">
        <Link
          className={`nav-link ${
            activePage === "change-password" ? "active" : ""
          }`}
          id="password-reset-tab"
          data-toggle="tab"
          href="#password-reset"
          role="tab"
          aria-controls="password-reset"
          aria-selected="false"
          to={ProfileChangePasswordRoute}
        >
          Change Password
        </Link>
      </li>
      <li
        className="nav-item"
        onClick={(e) => {
          e.preventDefault();
          Cookies.remove("token");
          Cookies.remove("verified");
          dispatch(logout());
          dispatch(getWishList([]));
          history?.push("/discover");
        }}
      >
        <Link
          className={`nav-link ${activePage === "sign-out" ? "active" : ""}`}
          id="sign-out-tab"
          data-toggle="tab"
          href="#sign-out"
          role="tab"
          aria-controls="sign-out"
          aria-selected="false"
          //to={ProfileChangePasswordRoute}
        >
          {isMobile ? "Sign out" : "Log out"}
        </Link>
      </li>
    </ul>
  );
};

export default ProfileLeftPane;
