import React from "react";
import { useDispatch } from "react-redux";
import Back from "../assets/images/back.png";
import Close from "../assets/images/close2.png";
import { deleteList } from "../Store/Actions/userActions";

export default function ListCreationHeader({
  pageTitle,
  previousPage,
  close,
  ...rest
}) {
  const dispatch = useDispatch();
  return (
    <div className="mobile-header">
      <nav className="navbar">
        {previousPage ? (
          <div
            className="back"
            {...rest}
            onClick={(e) => rest.history.push(previousPage)}
          >
            <img src={Back} alt="Go back" />
          </div>
        ) : null}
        <label className="navbar-brand mx-auto middle-label">{pageTitle}</label>
        {close ? (
          <div
            href="#."
            className="cross"
            onClick={(e) => {
              dispatch(deleteList());
              rest.history.push(close);
            }}
          >
            <img src={Close} alt="Close" />
          </div>
        ) : null}
      </nav>
    </div>
  );
}
