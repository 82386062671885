import React, { useEffect, useState } from "react";
import ListPreview from "../../assets/images/list_preview.svg";
import TickInsideYellow from "../../assets/images/tick_inside_yellow.svg";
import Leaf from "../../assets/images/leaf.svg";
import location from "../../assets/images/location.svg";
import ListPreviewGray from "../../assets/images/list_preview_gray.svg";
import Filter from "../../assets/images/filter.png";
import Ellipse from "../../assets/images/ellipse.png";
import BuyColored from "../../assets/images/buy_colored.png";
import GiveColored from "../../assets/images/give_colored.png";
import DoColored from "../../assets/images/do_colored.png";
import $ from "jquery";
import "slick-carousel";
import BottomTab from "../../Common/BottomTab";
import Profileheadermobile from "../../Common/Profileheadermobile";
import { useDispatch, useSelector } from "react-redux";
import AxiosApi from "../../Config/axios-api";
import { USER_ACTION_LIST_LIST_SUCCESS } from "../../Store/Constants/userConstants";
import Footer from "../../Common/Footer";
import UnloginuserHead from "../../Common/UnloginuserHead";
import Cookies from "js-cookie";
import { getUserDetailsInfo } from "../../Store/Actions/userActions";
import { stringCapitalize } from "../../services/stringModify";
import { ToastContainer, toast } from "react-toastify";
import "../../../node_modules/react-toastify/dist/ReactToastify.min.css";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";

const Dashboard = (props) => {
  const { innerWidth: width, innerHeight: height } = window;
  const dispatch = useDispatch();
  const history = useHistory();

  const [showmorecat, setshowmorecat] = useState(false);
  // const userSignin = useSelector((state) => state.userSignin);
  // const { userInfo } = userSignin;
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [browserList, setBrowserList] = useState([]);
  const [savedList, setSsaveList] = useState([]);
  const [displayCount, setDisplayCount] = useState(3);
  const UserDetails = useSelector((state) => state.UserDetails);
  const { loading, getuserInfo, error } = UserDetails;
  const [savelistrender, setSavelistRender] = useState(false);
  const [userInfoData, setUserDetails] = useState();

  useEffect(() => {
    sliderfun();
  }, [savedList]);

  const sliderfun = () => {
    $(".saved-list-slider").not('.slick-initialized')?.slick({
      centerMode: false,
      slidesToShow: 3,
      infinite: false,
      dots: true,
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            arrows: false,
            centerMode: false,
            centerPadding: "0%",
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 991,
          settings: {
            arrows: false,
            centerMode: false,
            centerPadding: "0%",
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            centerMode: false,
            centerPadding: "0%",
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: "0%",
            slidesToShow: 1,
          },
        },
      ],
    });
  };

  useEffect(() => {
    browerlist();
    console.log("object use effect", userInfo);
    if (Cookies.get("token")) {
      getuserDetails();
    }
  }, []);

  const browerlist = async () => {
    const { data } = await AxiosApi.get("/actionList/getAllActionLists");
    setBrowserList(data);
  };
  const getSavedList = async (item) => {
    setSsaveList([]);
    var obj = {};
    obj.actionListIds = item?.savedActionList;
    const { data } = await AxiosApi.post("/actionList/getSavedActionList", obj);
    setSsaveList(data);
  };
  const getuserDetails = async () => {
    console.log("object callllll");
    dispatch(getUserDetailsInfo(userInfo?.userId)).then((res) => {
      if (res?.data) {
        setUserDetails(res?.data);
        getSavedList(res?.data);
      }
    });
    // const { data } = await AxiosApi.get(
    //   '/userDetailsById/' + userInfo?.userId,
    //   {}
    // );
    // setUserDetails(data);
    // getSavedList(data);
  };
  const saveActionList = async (item, e) => {
    e.stopPropagation();
    var findIndex = userInfoData?.savedActionList?.findIndex(
      (val) => val == item.actionListId
    );
    var obj = {};
    obj.actionListId = item.actionListId;
    obj.userId = userInfo.userId;
    if (findIndex === -1) {
      const { data } = await AxiosApi.post("/user/actionListSave", obj);
      if (data.msg === "Action List Saved.") {
        browerlist();
        getuserDetails();
        toast.success(data?.msg);
      }
    } else {
      removeActionList(item, e);
    }
  };
  const removeActionList = async (item, e) => {
    e.stopPropagation();
    var obj = {};
    obj.actionListId = item.actionListId;
    obj.userId = userInfo.userId;
    const { data } = await AxiosApi.post("/user/removeSavedActionList", obj);
    if (data.msg === "Remove action List.") {
      browerlist();
      getuserDetails();
      toast.success(data?.msg);
    }
  };

  const redirectlist = (item) => {
    dispatch({
      type: USER_ACTION_LIST_LIST_SUCCESS,
      payload: item,
    });
    props.history.push("/l/" + item.actionListId);
  };

  return (
    <>
      {Cookies.get("token") ? (
        <Profileheadermobile
          history={props.history}
          // invailTokenDetails = {invailideTokenDetails}
        />
      ) : (
        <UnloginuserHead history={props.history} />
      )}

      <div className="content dashboard">
        <div className="container">
          {savedList?.length != 0 && (
            <div className="section1 section2">
              <h5 className="subhead">Saved lists</h5>

              {savedList?.length == 0 ? (
                <>
                  {/* desktop view skeleton start here */}
                  <div className="skeletonbox desktop-view">
                    <div className="row">
                      <div className="col-12 col-lg-4">
                        <div className="skeletonbox-sec">
                          <div className="whiteborder-row w-50 h-15px rounded"></div>
                          <div className="whiteborder-row w-75 h-15px rounded"></div>
                          <div className="whiteborder-row w-50 h-15px rounded"></div>
                          <div className="whiteborder-row w-75 h-15px rounded"></div>
                          <div className="whiteborder-row w-100 h-15px rounded"></div>
                          <div className="whiteborder-row w-75 h-15px rounded"></div>
                          <div className="whiteborder-row w-100 h-8px rounded"></div>
                          <div className="whiteborder-row w-100 h-8px rounded"></div>
                          <div className="whiteborder-row w-100 h-8px rounded"></div>
                          <div className="whiteborder-row w-100 h-5px rounded"></div>
                          <div className="whiteborder-row w-25 h-15px rounded"></div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-4">
                        <div className="skeletonbox-sec">
                          <div className="whiteborder-row w-50 h-15px rounded"></div>
                          <div className="whiteborder-row w-75 h-15px rounded"></div>
                          <div className="whiteborder-row w-50 h-15px rounded"></div>
                          <div className="whiteborder-row w-75 h-15px rounded"></div>
                          <div className="whiteborder-row w-100 h-15px rounded"></div>
                          <div className="whiteborder-row w-75 h-15px rounded"></div>
                          <div className="whiteborder-row w-100 h-8px rounded"></div>
                          <div className="whiteborder-row w-100 h-8px rounded"></div>
                          <div className="whiteborder-row w-100 h-8px rounded"></div>
                          <div className="whiteborder-row w-100 h-5px rounded"></div>
                          <div className="whiteborder-row w-25 h-15px rounded"></div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-4">
                        <div className="skeletonbox-sec">
                          <div className="whiteborder-row w-50 h-15px rounded"></div>
                          <div className="whiteborder-row w-75 h-15px rounded"></div>
                          <div className="whiteborder-row w-50 h-15px rounded"></div>
                          <div className="whiteborder-row w-75 h-15px rounded"></div>
                          <div className="whiteborder-row w-100 h-15px rounded"></div>
                          <div className="whiteborder-row w-75 h-15px rounded"></div>
                          <div className="whiteborder-row w-100 h-8px rounded"></div>
                          <div className="whiteborder-row w-100 h-8px rounded"></div>
                          <div className="whiteborder-row w-100 h-8px rounded"></div>
                          <div className="whiteborder-row w-100 h-5px rounded"></div>
                          <div className="whiteborder-row w-25 h-15px rounded"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* desktop view skeleton start here */}
                </>
              ) : (
                savedList.length > 0 && (
                  <div className="slider saved-list-slider">
                    {savedList &&
                      savedList.length > 0 &&
                      savedList.map((item, index) => {
                        let percentwidth =
                          Math.ceil((item.done / item.needsCount) * 100) + "%";
                        let theme;
                        switch (item.themeName) {
                          case "blossom":
                            theme = "list-box theme-blossom text-overflowcss";
                            break;
                          case "greenery":
                            theme = "list-box theme-greenery text-overflowcss";
                            break;
                          case "waterpolo":
                            theme = "list-box theme-waterpolo text-overflowcss";
                            break;
                          case "dawn":
                            theme = "list-box theme-dawn text-overflowcss";
                            break;
                          case "fair":
                            theme = "list-box theme-fair text-overflowcss";
                            break;
                          case "sunshine":
                            theme = "list-box theme-sunshine text-overflowcss";
                            break;
                          default:
                            theme = "list-box theme-sunshine text-overflowcss";
                        }
                        return (
                          <div
                            className="wrapper"
                            onClick={() => redirectlist(item)}
                          >
                            <div className={theme}>
                              <div className="top">
                                <div class="row3" style={{ marginTop: 0 }}>
                                  <img src={location} alt="leaf" />
                                  <label className="label-hover">
                                    {item.place}
                                  </label>
                                  <div className="location-hover">
                                    daadadada
                                  </div>
                                </div>

                                <button
                                  type="button"
                                  className="btn-preview"
                                  onClick={(e) => removeActionList(item, e)}
                                >
                                  <img src={ListPreview} alt="listPreview" />
                                </button>
                                <label className="list-name">
                                  {stringCapitalize(item?.title)}
                                </label>
                                <label className="organized-by">
                                  Organized by{" "}
                                  <strong>
                                    {item?.userName[0]?.firstName}{" "}
                                    {item?.userName[0]?.lastName}
                                  </strong>
                                  {item?.verified && (
                                    <img src={TickInsideYellow} alt="tick" />
                                  )}
                                </label>
                                <div className="row3">
                                  <img src={Leaf} alt="leaf" />
                                  <label>{item.purpose}</label>
                                </div>
                              </div>
                              <div className="bottom custom_progress_bottom">
                                {percentwidth != "0%" && (
                                  <div className="progress custom_progress">
                                    <div
                                      className="progress-bar"
                                      role="progressbar"
                                      style={{
                                        width:
                                          item.done === 0 ? "0%" : percentwidth,
                                      }}
                                      aria-valuenow="25"
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    ></div>
                                  </div>
                                )}
                                <label className="progress-report">
                                  <strong>{item.done} actioned</strong> of{" "}
                                  {item.needsCount} needs
                                </label>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                )
              )}
            </div>
          )}
          {!Cookies.get("token") && (
            <div className="mobile-signupbox">
              <div className="sm-logo">
                <img src="images/smlogo.svg" />
              </div>
              <h2>Buy. Give. Do</h2>
              <button
                type="button"
                class="btn btn-prime"
                onClick={() => history.push("/signup")}
              >
                Create an ActionList
              </button>
              <p>
                Have an account? <Link to="/login">Log in</Link>
              </p>
            </div>
          )}
          <div className="section2">
            <div className="header-row">
              <div className="left">
                <h3 className="subhead">Browse lists</h3>
                <label>
                  You can explore needs and lists with needs according to
                  categories and types of needs
                </label>
              </div>
              <div className="right">
                {/* <button type='button' className='btn-filter'>
                  <img src={Filter} alt='filter' />
                </button> */}
              </div>
            </div>

            {console.log("----", browserList)}
            {browserList.length == 0 ? (
              <>
                {/* mobile view skeleton start here */}
                <div className="skeletonbox mobile-view">
                  <div className="row">
                    <div className="col-12 col-lg-4">
                      <div className="skeletonbox-sec">
                        <div className="whiteborder-row w-50 h-15px rounded"></div>
                        <div className="whiteborder-row w-75 h-15px rounded"></div>
                        <div className="whiteborder-row w-50 h-15px rounded"></div>
                        <div className="whiteborder-row w-75 h-15px rounded"></div>
                        <div className="whiteborder-row w-100 h-15px rounded"></div>
                        <div className="whiteborder-row w-75 h-15px rounded"></div>
                        <div className="whiteborder-row w-100 h-8px rounded"></div>
                        <div className="whiteborder-row w-100 h-8px rounded"></div>
                        <div className="whiteborder-row w-100 h-8px rounded"></div>
                        <div className="whiteborder-row w-100 h-5px rounded"></div>
                        <div className="whiteborder-row w-25 h-15px rounded"></div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-4">
                      <div className="skeletonbox-sec">
                        <div className="whiteborder-row w-50 h-15px rounded"></div>
                        <div className="whiteborder-row w-75 h-15px rounded"></div>
                        <div className="whiteborder-row w-50 h-15px rounded"></div>
                        <div className="whiteborder-row w-75 h-15px rounded"></div>
                        <div className="whiteborder-row w-100 h-15px rounded"></div>
                        <div className="whiteborder-row w-75 h-15px rounded"></div>
                        <div className="whiteborder-row w-100 h-8px rounded"></div>
                        <div className="whiteborder-row w-100 h-8px rounded"></div>
                        <div className="whiteborder-row w-100 h-8px rounded"></div>
                        <div className="whiteborder-row w-100 h-5px rounded"></div>
                        <div className="whiteborder-row w-25 h-15px rounded"></div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-4">
                      <div className="skeletonbox-sec">
                        <div className="whiteborder-row w-50 h-15px rounded"></div>
                        <div className="whiteborder-row w-75 h-15px rounded"></div>
                        <div className="whiteborder-row w-50 h-15px rounded"></div>
                        <div className="whiteborder-row w-75 h-15px rounded"></div>
                        <div className="whiteborder-row w-100 h-15px rounded"></div>
                        <div className="whiteborder-row w-75 h-15px rounded"></div>
                        <div className="whiteborder-row w-100 h-8px rounded"></div>
                        <div className="whiteborder-row w-100 h-8px rounded"></div>
                        <div className="whiteborder-row w-100 h-8px rounded"></div>
                        <div className="whiteborder-row w-100 h-5px rounded"></div>
                        <div className="whiteborder-row w-25 h-15px rounded"></div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* mobile view skeleton end here */}{" "}
                {/* desktop view skeleton start here */}
                <div className="skeletonbox desktop-view">
                  <div className="row margin-top-add">
                    <div className="col-12 col-lg-12">
                      <div className="skeletonbox-sec fullskeleton">
                        <div className="whiteborder-row w-50 h-15px rounded"></div>
                        <div className="whiteborder-row w-75 h-15px rounded"></div>
                        <div className="whiteborder-row w-100 h-15px rounded"></div>
                        <div className="whiteborder-row w-75 h-15px rounded"></div>
                        <div className="whiteborder-row w-100 h-8px rounded"></div>
                        <div className="whiteborder-row w-100 h-5px rounded"></div>
                        <div className="whiteborder-row w-25 h-15px rounded"></div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-12">
                      <div className="skeletonbox-sec fullskeleton">
                        <div className="whiteborder-row w-50 h-15px rounded"></div>
                        <div className="whiteborder-row w-75 h-15px rounded"></div>
                        <div className="whiteborder-row w-100 h-15px rounded"></div>
                        <div className="whiteborder-row w-75 h-15px rounded"></div>
                        <div className="whiteborder-row w-100 h-8px rounded"></div>
                        <div className="whiteborder-row w-100 h-5px rounded"></div>
                        <div className="whiteborder-row w-25 h-15px rounded"></div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-12">
                      <div className="skeletonbox-sec fullskeleton">
                        <div className="whiteborder-row w-50 h-15px rounded"></div>
                        <div className="whiteborder-row w-75 h-15px rounded"></div>
                        <div className="whiteborder-row w-100 h-15px rounded"></div>
                        <div className="whiteborder-row w-75 h-15px rounded"></div>
                        <div className="whiteborder-row w-100 h-8px rounded"></div>
                        <div className="whiteborder-row w-100 h-5px rounded"></div>
                        <div className="whiteborder-row w-25 h-15px rounded"></div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* desktop view skeleton start here */}
              </>
            ) : (
              browserList.length > 0 &&
              browserList.map((item, index) => {
                var findIndex = userInfoData?.savedActionList?.findIndex(
                  (val) => val === item.actionListId
                );
                let percentwidth =
                  Math.ceil((item.done / item.needsCount) * 100) + "%";
                let theme;
                switch (item.themeName) {
                  case "blossom":
                    theme = "list-box theme-blossom";
                    break;
                  case "greenery":
                    theme = "list-box theme-greenery";
                    break;
                  case "waterpolo":
                    theme = "list-box theme-waterpolo";
                    break;
                  case "dawn":
                    theme = "list-box heme-dawn";
                    break;
                  case "fair":
                    theme = "list-box theme-fair";
                    break;
                  case "sunshine":
                    theme = "list-box theme-sunshine";
                    break;
                  default:
                    theme = "list-box theme-sunshine";
                }
                return (
                  <>
                    {item.actionListStatus !== "draft" && (
                      <>
                        {index < displayCount && (
                          <div
                            className={theme}
                            onClick={() => redirectlist(item)}
                          >
                            <div className="top">
                              {Cookies.get("token") ? (
                                <button
                                  type="button"
                                  className="btn-preview"
                                  disabled={!userInfo?.token}
                                  onClick={(e) => saveActionList(item, e)}
                                >
                                  <img
                                    src={
                                      findIndex === -1
                                        ? ListPreviewGray
                                        : ListPreview
                                    }
                                    alt="listPreviewGray"
                                  />
                                </button>
                              ) : (
                                ""
                              )}
                              <label className="need-name">
                                {stringCapitalize(item?.title)}
                              </label>
                              <div className="row2">
                                <div className="left">
                                  <label className="organized-by">
                                    Organized by{" "}
                                    <strong>{item?.userName}</strong>{" "}
                                    <div className="mob_view">
                                      {item?.verified && (
                                        <img
                                          src={TickInsideYellow}
                                          alt="tick"
                                        />
                                      )}
                                    </div>
                                  </label>
                                </div>
                                <div className="right">
                                  <img src={Leaf} alt="leaf" />
                                  <label>{item.purpose}</label>
                                </div>
                              </div>
                            </div>
                            <div className="bottom">
                              <label className="progress-report desktop-view">
                                <strong>{item.done} actioned</strong> of{" "}
                                {item.needsCount} needs
                              </label>
                              <div className="progress">
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{
                                    width:
                                      item.done === 0 ? "0%" : percentwidth,
                                  }}
                                  aria-valuenow="0"
                                  aria-valuemin="25"
                                  aria-valuemax="100"
                                ></div>
                              </div>
                              <label className="progress-report mobile-view">
                                <strong>{item.done} actioned</strong> of{" "}
                                {item.needsCount} needs
                              </label>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </>
                );
              })
            )}

            {browserList.length > 3 && (
              <button
                type="button"
                className="btn btn-more"
                onClick={() =>
                  browserList.length === displayCount
                    ? setDisplayCount(3)
                    : displayCount + 3 > browserList.length
                    ? setDisplayCount(browserList.length)
                    : setDisplayCount(displayCount + 3)
                }
              >
                See {displayCount === browserList.length ? "less" : "more"}
              </button>
            )}
          </div>

          {/* <div className='section3'>
            <h3 className='subhead'>Browse needs by types</h3>
            <div className='wrapper'>
              <div className='type-box'>
                <div className='type-img'>
                  <img src={BuyColored} alt='buyColored' />
                </div>
                <label className='type-name'>Buy</label>
                <label className='type-desc'>
                  Needs for people to purchase online
                </label>
              </div>

              <div className='type-box'>
                <div className='type-img'>
                  <img src={GiveColored} alt='giveColored' />
                </div>
                <label className='type-name'>Give</label>
                <label className='type-desc'>
                  Needs for people to bring or make
                </label>
              </div>

              <div className='type-box'>
                <div className='type-img'>
                  <img src={DoColored} alt='doColored' />
                </div>
                <label className='type-name'>Do</label>
                <label className='type-desc'>
                  Needs for people to volunteer their time
                </label>
              </div>
            </div>
          </div>

          <div className='section4' style={{ paddingBottom: 20 }}>
            <h3 className='subhead'>Browse needs by categories</h3>
            <div className='categories'>
              <div className='column'>
                <label className='purpose-option' htmlFor='advocacy'>
                  <input
                    type='radio'
                    name='purpose-option'
                    id='advocacy'
                    value='advocacy'
                  />
                  <div className='option-wrap'>
                    <img src={Ellipse} alt='ellipse' />
                    <span>Advocacy</span>
                  </div>
                </label>
                <label className='purpose-option' htmlFor='animalsandpets'>
                  <input
                    type='radio'
                    name='purpose-option'
                    id='animalsandpets'
                    value='animalsandpets'
                  />
                  <div className='option-wrap'>
                    <img src={Ellipse} alt='ellipse' />
                    <span>Animals and Pets</span>
                  </div>
                </label>
                <label className='purpose-option' htmlFor='artsandculture'>
                  <input
                    type='radio'
                    name='purpose-option'
                    id='artsandculture'
                    value='artsandculture'
                  />
                  <div className='option-wrap'>
                    <img src={Ellipse} alt='ellipse' />
                    <span>Arts and Culture</span>
                  </div>
                </label>
                <label className='purpose-option' htmlFor='crisissupport'>
                  <input
                    type='radio'
                    name='purpose-option'
                    id='crisissupport'
                    value='crisissupport'
                  />
                  <div className='option-wrap'>
                    <img src={Ellipse} alt='ellipse' />
                    <span>Crisis Support</span>
                  </div>
                </label>
                <label className='purpose-option' htmlFor='communityandlocal'>
                  <input
                    type='radio'
                    name='purpose-option'
                    id='communityandlocal'
                    value='communityandlocal'
                  />
                  <div className='option-wrap'>
                    <img src={Ellipse} alt='ellipse' />
                    <span>Community and Local</span>
                  </div>
                </label>
              </div>

              <div
                className={`column ${showmorecat && width < 768 ? '' : 'desktop-view'
                  }`}
              >
                <label className='purpose-option' htmlFor='education'>
                  <input
                    type='radio'
                    name='purpose-option'
                    id='education'
                    value='education'
                  />
                  <div className='option-wrap'>
                    <img src={Ellipse} alt='ellipse' />
                    <span>Education</span>
                  </div>
                </label>
                <label
                  className='purpose-option'
                  htmlFor='emergencyanddisasterrelief'
                >
                  <input
                    type='radio'
                    name='purpose-option'
                    id='emergencyanddisasterrelief'
                    value='emergencyanddisasterrelief'
                  />
                  <div className='option-wrap'>
                    <img src={Ellipse} alt='ellipse' />
                    <span>Emergency and Disaster Relief</span>
                  </div>
                </label>
                <label className='purpose-option' htmlFor='environment'>
                  <input
                    type='radio'
                    name='purpose-option'
                    id='environment'
                    value='environment'
                  />
                  <div className='option-wrap'>
                    <img src={Ellipse} alt='ellipse' />
                    <span>Environment</span>
                  </div>
                </label>
                <label className='purpose-option' htmlFor='events'>
                  <input
                    type='radio'
                    name='purpose-option'
                    id='events'
                    value='events'
                  />
                  <div className='option-wrap'>
                    <img src={Ellipse} alt='ellipse' />
                    <span>Events</span>
                  </div>
                </label>
                <label className='purpose-option' htmlFor='faith'>
                  <input
                    type='radio'
                    name='purpose-option'
                    id='faith'
                    value='faith'
                  />
                  <div className='option-wrap'>
                    <img src={Ellipse} alt='ellipse' />
                    <span>Faith</span>
                  </div>
                </label>
              </div>

              <div
                className={`column ${showmorecat && width < 768 ? '' : 'desktop-view'
                  }`}
              >
                <label className='purpose-option' htmlFor='friendsandfamily'>
                  <input
                    type='radio'
                    name='purpose-option'
                    id='friendsandfamily'
                    value='friendsandfamily'
                  />
                  <div className='option-wrap'>
                    <img src={Ellipse} alt='ellipse' />
                    <span>Friends and Family</span>
                  </div>
                </label>
                <label className='purpose-option' htmlFor='healthandmedical'>
                  <input
                    type='radio'
                    name='purpose-option'
                    id='healthandmedical'
                    value='healthandmedical'
                  />
                  <div className='option-wrap'>
                    <img src={Ellipse} alt='ellipse' />
                    <span>Health and Medical</span>
                  </div>
                </label>
                <label className='purpose-option' htmlFor='socialjustice'>
                  <input
                    type='radio'
                    name='purpose-option'
                    id='socialjustice'
                    value='socialjustice'
                  />
                  <div className='option-wrap'>
                    <img src={Ellipse} alt='ellipse' />
                    <span>Social Justice</span>
                  </div>
                </label>
                <label className='purpose-option' htmlFor='sportsandrecreation'>
                  <input
                    type='radio'
                    name='purpose-option'
                    id='sportsandrecreation'
                    value='sportsandrecreation'
                  />
                  <div className='option-wrap'>
                    <img src={Ellipse} alt='ellipse' />
                    <span>Sports and Recreation</span>
                  </div>
                </label>
                <label className='purpose-option' htmlFor='other'>
                  <input
                    type='radio'
                    name='purpose-option'
                    id='other'
                    value='other'
                  />
                  <div className='option-wrap'>
                    <img src={Ellipse} alt='ellipse' />
                    <span>Other</span>
                  </div>
                </label>
              </div>
            </div>

            <button
              type='button'
              className='btn btn-more mobile-view'
              onClick={() => setshowmorecat(!showmorecat)}
            >
              Show more categories
            </button>
          </div> */}
        </div>
        <ToastContainer autoClose={3000} />
      </div>
      <div></div>
      <BottomTab activeScreen="dashboard" />
      <Footer {...props} />
    </>
  );
};

export default Dashboard;
