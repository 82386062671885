import React, { useState } from "react";
import whiteSearch from "../../assets/images/search_white.png";
import { useSelector, useDispatch } from "react-redux";
import { placeOrder, ClearStripeToken } from "../../Store/Actions/userActions";
import clock from "../../assets/images/clock.svg";
import location from "../../assets/images/location.svg";
import Profileheadermobile from "../../Common/Profileheadermobile";
import SuccessPopUp from "../../Common/successPopModal/SuccessPopUp";
import { getWishList } from "../../Store/Actions/userActions";
import BottomTab from "../../Common/BottomTab";
import checkmark_white from "../../assets/images/checkmark_white.png";
import Cookies from "js-cookie";
import {
  ProcessedToSuccessRoute,
  ProcessedToPaymentRoute,
} from "../../Routing/RouteConstants";
import UnloginuserHead from "../../Common/UnloginuserHead";
import Header from "./Header";

var moment = require("moment");

const EmbedProcessedToCheckout = (props) => {
  const idArray = window.location.href.split("/");
  const id = idArray[idArray.length - 1];
  const dispatch = useDispatch();
  const stripeTokenInfo = useSelector(
    (state) => state.stripeTokenInfo.stripeTokenInfo
  );
  const [PlaceOrderError, setPlaceOrderError] = useState("");
  const [saveChecked, setsaveChecked] = useState(false);
  const userSignin = useSelector((state) => state.userSignin);
  const { loading: userloading, userInfo, error: usererror } = userSignin;
  const state = props?.location?.state;
  console.log(props);

  const getuserWishList = useSelector(
    (state) => state.getuserWishList.userWishlistInfo
  );
  const subTotal = getuserWishList.reduce(
    (subTotal, item) => subTotal + item.cost * item.qty,
    0
  );

  const BuyQty = getuserWishList.reduce(function (sum, item) {
    return item.needType === "buy" ? sum + item.qty : sum;
  }, 0);

  const DoQty = getuserWishList.reduce(function (sum, item) {
    return item.needType === "do" ? sum + item.qty : sum;
  }, 0);

  const GiveQty = getuserWishList.reduce(function (sum, item) {
    return item.needType === "give" ? sum + item.qty : sum;
  }, 0);
  const DonationSum = getuserWishList.reduce(function (sum, item) {
    return item.needType === "Donation" ? sum + item.cost : sum;
  }, 0);

  var Buy = false;
  var Do = false;
  var Give = false;
  var Donate = false;
  getuserWishList.map((item) => {
    if (item.needType === "buy") {
      return (Buy = true);
    } else if (item.needType === "do") {
      return (Do = true);
    } else if (item.needType === "give") {
      return (Give = true);
    } else if (item.needType === "Donation") {
      return (Donate = true);
    }
  });

  const placeOrderApi = () => {
    var obj = {};
    var items = [];

    getuserWishList.map((item) => {
      let arr = {
        needId: item.needId,
        qty: item.qty,
        needType: item.needType,
        Name: item.Name,
        amount: item.cost,
        actionListId: item.actionListId,
      };
      if (item?.deliveryType) {
        return items.push({
          ...arr,

          deliveryType: item.deliveryType,
        });
      } else if (item?.deliveryType && item?.pickUpLocation) {
        return items.push({
          ...arr,
          pickUpAddress: item.pickUpAddress,
          deliveryType: item.deliveryType,
        });
      }
      return items.push({
        ...arr,
      });
    });

    console.log(items);

    obj.userId = userInfo?.userId;
    // obj.fullName = state?.fullName;
    obj.fullName = state?.firstName + " " + state?.lastName;
    obj.email = state?.email;
    // obj.actionListId = props.match.params.id;
    obj.orderType =
      getuserWishList[0]?.needType === "Donation"
        ? "donation"
        : getuserWishList[0]?.needType;
    // obj.amount = subTotal;
    obj.phoneNumber = state?.phoneNumber;
    obj.cardNumber = parseInt(state?.cardNumber);
    obj.approved = false;
    obj.stripeToken = stripeTokenInfo?.id;
    obj.items = items;

    dispatch(placeOrder(obj)).then((res) => {
      if (res?.success) {
        props.history.push("/embed/processed-to-success/" + id);
        dispatch(getWishList([]));
      } else {
        setPlaceOrderError(res?.msg);
        setTimeout(
          function () {
            setPlaceOrderError("");
          }.bind(this),
          5000
        );
      }
    });
  };

  return (
    <div>
      <Header />
      <div className="content purchase desktop">
        <div className="breadcrumb-row">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a>Review</a>
              </li>
              <li className="breadcrumb-item">
                <a>Details &amp; Payment</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Submit
              </li>
            </ol>
          </nav>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-8 left col-padding">
              <div className="header d-md-flex d-none">
                <h2 className="heading2">Review &amp; Submit</h2>
                <a
                  onClick={() => props.history.goBack()}
                  className="link-extra"
                >
                  Edit
                </a>
              </div>
              <h3 className="section-heading">Contact information</h3>
              <form className="contact-form">
                <div className="form-group">
                  <label htmlFor="fullname">Full name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="fullname"
                    // defaultValue={state?.fullName ? state?.fullName : ''}
                    defaultValue={
                      state?.firstName || state?.lastName
                        ? state?.firstName + " " + state?.lastName
                        : ""
                    }
                    disabled
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Your email address</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    defaultValue={state?.email ? state?.email : ""}
                    disabled
                  />
                </div>
              </form>
              <h3 className="section-heading">Payment details</h3>
              <form className="payment-form">
                <div className="form-group">
                  <label htmlFor="cardnumber">Card number</label>
                  <div className="input-group disabled">
                    <div className="input-group-prepend">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0 6C0 5.20435 0.316071 4.44129 0.87868 3.87868C1.44129 3.31607 2.20435 3 3 3H21C21.7956 3 22.5587 3.31607 23.1213 3.87868C23.6839 4.44129 24 5.20435 24 6V7.5H0V6Z"
                          fill="#607BFF"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M0 10.5V18C0 18.7956 0.316071 19.5587 0.87868 20.1213C1.44129 20.6839 2.20435 21 3 21H21C21.7956 21 22.5587 20.6839 23.1213 20.1213C23.6839 19.5587 24 18.7956 24 18V10.5H0ZM4.5 13.5C4.10218 13.5 3.72064 13.658 3.43934 13.9393C3.15804 14.2206 3 14.6022 3 15V16.5C3 16.8978 3.15804 17.2794 3.43934 17.5607C3.72064 17.842 4.10218 18 4.5 18H6C6.39782 18 6.77936 17.842 7.06066 17.5607C7.34196 17.2794 7.5 16.8978 7.5 16.5V15C7.5 14.6022 7.34196 14.2206 7.06066 13.9393C6.77936 13.658 6.39782 13.5 6 13.5H4.5Z"
                          fill="#607BFF"
                        />
                      </svg>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      id="cardnumber"
                      defaultValue={
                        "**** **** ****" + String(state?.cardNumber)?.substr(12)
                      }
                      disabled
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="cardholdername">Cardholder name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="cardholdername"
                    defaultValue={state?.holderName}
                    disabled
                  />
                </div>
              </form>
              <h3 className="section-heading">Needs you are helping with</h3>
              {getuserWishList.length > 0 &&
                getuserWishList.map((item, index) => {
                  return (
                    <>
                      <div className="purchase-need-detail-box">
                        {item.needType === "buy" && (
                          <>
                            <h3>
                              <span>BUY:</span> {item.Name}
                            </h3>
                            <label className="quantity">
                              Quantity: {item.qty}
                            </label>
                            <label className="price">
                              ${item.cost.toFixed(2)}
                            </label>
                          </>
                        )}
                        {item.needType === "Donation" && (
                          <>
                            <h3>
                              <span>DONATE:</span> {item.Name}
                            </h3>
                            <label className="price">
                              ${item.cost.toFixed(2)}
                            </label>
                          </>
                        )}
                        {item.needType === "give" && (
                          <>
                            <h3>
                              <span>GIVE:</span> {item.Name}
                            </h3>
                            <label className="quantity">
                              Quantity: {item.qty}
                            </label>
                            <label className="dropoff">
                              Drop off to <a >{item?.location}</a>
                            </label>
                          </>
                        )}
                        {item.needType === "do" && (
                          <>
                            <h3>
                              <span>DO:</span> {item.Name}
                            </h3>
                            <label className="quantity">
                              Quantity: {item.qty}
                            </label>
                            <div className="location-row">
                              <div className="left d-inline-block">
                                <img src={clock} />
                              </div>
                              <div className="right d-inline-block">
                                <label>
                                  Complete-by:{" "}
                                  {moment(item?.completedBy).format(
                                    "MMM DD YYYY, HH:SS a"
                                  )}
                                </label>
                              </div>
                            </div>
                            <div className="location-row">
                              <div className="left d-inline-block">
                                <img src={location} />
                              </div>
                              <div className="right d-inline-block">
                                <label>{item.location}</label>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  );
                })}
            </div>

            <div className="col-xl-4 col-lg-4 right  col-padding">
              <div className="header d-md-block d-none">
                <div className="stepper type2">
                  <div className="step complete">
                    <img src={checkmark_white} />
                    <span>1</span>
                  </div>
                  <div className="step-line" />
                  <div className="step complete">
                    <img src={checkmark_white} />
                    <span>2</span>
                  </div>
                  <div className="step-line" />
                  <div className="step active">
                    <img src="images/checkmark_white.png" />
                    <span>3</span>
                  </div>
                </div>
                <div className="stepper-label type2">
                  <label className="complete">Review</label>
                  <label className="complete">Details &amp; Payment</label>
                  <label className="active">Submit</label>
                </div>
              </div>

              <h2 className="heading2 d-md-block d-none">Summary</h2>
              <div className="bottom-content">
                <div
                  style={{
                    marginTop: 20,
                    textAlign: "center",
                    color: PlaceOrderError ? "red" : "green",
                  }}
                >
                  {PlaceOrderError}
                </div>
                {Buy ? (
                  <div className="buy-need-row">
                    <label className="text">Buy needs</label>
                    <label className="quantity">x {BuyQty}</label>
                  </div>
                ) : (
                  ""
                )}
                {Give ? (
                  <div className="buy-need-row">
                    <label className="text">Give needs</label>
                    <label className="quantity">x {GiveQty}</label>
                  </div>
                ) : (
                  ""
                )}
                {Do ? (
                  <div className="buy-need-row">
                    <label className="text">Do needs</label>
                    <label className="quantity">x {DoQty}</label>
                  </div>
                ) : (
                  ""
                )}
                {Donate ? (
                  <div className="buy-need-row">
                    <label className="text">General donation</label>
                    <label className="quantity">
                      ${DonationSum.toFixed(2)}
                    </label>
                  </div>
                ) : (
                  ""
                )}
                {Buy || Donate ? (
                  <div className="sub-total-row">
                    <label className="text">Subtotal:</label>
                    <label className="price">${subTotal.toFixed(2)}</label>
                  </div>
                ) : (
                  ""
                )}
                <div className="bottom-row">
                  <button
                    type="button"
                    className="btn btn-prime"
                    onClick={() => {
                      placeOrderApi();
                    }}
                    disabled={!saveChecked}
                  >
                    Checkout
                  </button>
                  <label htmlFor="terms" className="terms-check">
                    I have read, understood and accept the{" "}
                    <a href="#">terms and conditions.</a>
                    <input
                      type="checkbox"
                      id="terms"
                      checked={saveChecked}
                      onChange={() => setsaveChecked((val) => !val)}
                    />
                    <span className="checkmark" />
                  </label>
                  <div className="secure-link">
                    <svg
                      width={16}
                      height={16}
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8 0C5.93225 0 4.25 1.7944 4.25 4V6.4H3.5C3.10218 6.4 2.72064 6.56857 2.43934 6.86863C2.15804 7.16869 2 7.57565 2 8V14.4C2 14.8243 2.15804 15.2313 2.43934 15.5314C2.72064 15.8314 3.10218 16 3.5 16H12.5C12.8978 16 13.2794 15.8314 13.5607 15.5314C13.842 15.2313 14 14.8243 14 14.4V8C14 7.57565 13.842 7.16869 13.5607 6.86863C13.2794 6.56857 12.8978 6.4 12.5 6.4H11.75V4C11.75 1.7944 10.0677 0 8 0ZM5.75 4C5.75 2.6768 6.7595 1.6 8 1.6C9.2405 1.6 10.25 2.6768 10.25 4V6.4H5.75V4ZM8.75 12.5784V14.4H7.25V12.5784C6.98779 12.4182 6.77724 12.1771 6.64612 11.8867C6.515 11.5963 6.46946 11.2704 6.51551 10.9518C6.56156 10.6333 6.69704 10.3371 6.90407 10.1023C7.11111 9.86759 7.37999 9.70527 7.67525 9.6368C7.89456 9.58508 8.12196 9.58652 8.34068 9.64103C8.5594 9.69554 8.76386 9.80172 8.93899 9.95174C9.11411 10.1018 9.25543 10.2918 9.35253 10.5078C9.44963 10.7239 9.50002 10.9604 9.5 11.2C9.49957 11.4798 9.43002 11.7545 9.29835 11.9965C9.16668 12.2385 8.97754 12.4392 8.75 12.5784Z"
                        fill="#72819B"
                      />
                    </svg>
                    Secure checkout
                  </div>
                </div>
                {/* <SuccessPopUp
            id={"successModal"}
            history={props.history}
            actionId={props.match.params.id}
          /> */}
              </div>
            </div>
            <BottomTab activeScreen="dashboard" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmbedProcessedToCheckout;
