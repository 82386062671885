import axios from "axios";
import Cookies from "js-cookie";

const getAppUrl = (flag = true) => {
  if(flag)
    return process.env.REACT_APP_BACKEND_BASE_URL_LIVE;
    
  return process.env.NODE_ENV === 'development' 
    ? process.env.REACT_APP_BACKEND_BASE_URL_LOCAL 
    : process.env.REACT_APP_BACKEND_BASE_URL;
}

const AxiosApi = axios.create({
  baseURL: getAppUrl(),
});

AxiosApi.interceptors.request.use((config) => {
  config.headers.token = Cookies.get("token");
  return config;
});

AxiosApi.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log("AxiosApi error", error?.response?.data);
    // We really want to throw the error so it is handled and we
    // don't get an unhandledrejection error. By throwing here, we
    // are handling the rejection, and bubbling up to the closest
    // error handler (try/catch or catch method call on a promise).
    throw error?.response?.data;
  }
);

export default AxiosApi;
