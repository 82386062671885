import React from 'react';

const Buysvg = (props) => {
  return (
    <svg
      className='need-type-svg '
      width='32'
      height='32'
      viewBox='0 0 32 32'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.5 23C8.567 23 7 24.567 7 26.5C7 28.433 8.567 30 10.5 30C12.433 30 14 28.433 14 26.5C14 24.567 12.433 23 10.5 23ZM5 26.5C5 23.4624 7.46244 21 10.5 21C13.5376 21 16 23.4624 16 26.5C16 29.5376 13.5376 32 10.5 32C7.46243 32 5 29.5376 5 26.5Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.5 23C19.567 23 18 24.567 18 26.5C18 28.433 19.567 30 21.5 30C23.433 30 25 28.433 25 26.5C25 24.567 23.433 23 21.5 23ZM16 26.5C16 23.4624 18.4624 21 21.5 21C24.5376 21 27 23.4624 27 26.5C27 29.5376 24.5376 32 21.5 32C18.4624 32 16 29.5376 16 26.5Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.20992 6.38701C1.39934 6.14286 1.691 6 2.00001 6H10.6784C11.0838 6 11.4491 6.24477 11.6032 6.61973C11.7574 6.99469 11.6699 7.42562 11.3818 7.71079L7.26351 11.7863L16 20.5811L24.7365 11.7863L20.6182 7.71079C20.3301 7.42562 20.2426 6.99469 20.3968 6.61973C20.5509 6.24477 20.9162 6 21.3216 6H30C30.309 6 30.6007 6.14286 30.7901 6.38701C30.9795 6.63116 31.0454 6.94916 30.9686 7.24848L27.1207 22.2485C27.0072 22.6907 26.6086 23 26.1521 23H5.84796C5.39138 23 4.99278 22.6907 4.87933 22.2485L1.03137 7.24848C0.954588 6.94916 1.0205 6.63116 1.20992 6.38701ZM18.4029 21H25.3762L28.7111 8H23.7538L26.8555 11.0694C27.0442 11.2562 27.1509 11.5104 27.1521 11.7759C27.1532 12.0415 27.0487 12.2966 26.8615 12.485L18.4029 21ZM13.5971 21H6.62381L3.28892 8H8.24622L5.14456 11.0694C4.95581 11.2562 4.84911 11.5104 4.84797 11.7759C4.84684 12.0415 4.95137 12.2966 5.13851 12.485L13.5971 21Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16 0C16.2968 0 16.5782 0.131823 16.7682 0.359816L21.7682 6.35982C22.0166 6.6579 22.0702 7.07277 21.9056 7.42416C21.741 7.77554 21.388 8 21 8H11C10.612 8 10.259 7.77554 10.0944 7.42416C9.92984 7.07277 9.98339 6.6579 10.2318 6.35982L15.2318 0.359816C15.4218 0.131823 15.7032 0 16 0ZM13.1351 6H18.865L16 2.56205L13.1351 6Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.0493 6.29454C10.237 6.106 10.492 6 10.7581 6H21.2419C21.508 6 21.763 6.106 21.9507 6.29454L26.7087 11.0748C27.0947 11.4625 27.0974 12.0885 26.7148 12.4796L16.7147 22.6994C16.5266 22.8916 16.269 23 16 23C15.731 23 15.4734 22.8916 15.2852 22.6994L5.28525 12.4796C4.90259 12.0885 4.90527 11.4625 5.29125 11.0748L10.0493 6.29454ZM11.1736 8L7.40496 11.7862L16 20.5702L24.595 11.7862L20.8264 8H11.1736Z'
      />
    </svg>
  );
};
export default Buysvg;
