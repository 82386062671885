import React, { useState, useEffect } from "react";
import Close from "../../../assets/images/close2.png";
import { useForm } from "react-hook-form";
import ListCreationMobileHeader from "../../../Common/MobileHeaderOne";
import { useSelector, useDispatch } from "react-redux";
import { addGeneralInfo } from "../../../Store/Actions/userActions";
import UserDeskTopHeaderOne from "../../../Common/UserDeskTopHeaderOne";
import Profileheadermobile from "../../../Common/Profileheadermobile";
import { deleteList } from "../../../Store/Actions/userActions";
import { isMobile } from "react-device-detect";
const ListCreationGeneralInfo = (props) => {
  const [title, setTitle] = useState("");
  const [isFor, setIsFor] = useState("");
  let dispatch = useDispatch();
  const createList = useSelector((state) => state.createList);
  const { userCreateList } = createList;

  useEffect(() => {
    if (userCreateList && userCreateList.title && userCreateList.isFor) {
      setTitle(userCreateList.title);
      setIsFor(userCreateList.isFor);
    }
  }, [userCreateList]);

  let { register, handleSubmit, errors } = useForm();

  let submit = (data) => {
    let generalInfo = {
      ...userCreateList,
      title: data.title,
      isFor: data.isFor,
    };
    dispatch(addGeneralInfo(generalInfo));
    props.history.push("/me/create/step2");
  };
console.log(isMobile)
  return (
    <div>
      {isMobile ? "" : <Profileheadermobile history={props.history} />}
      <ListCreationMobileHeader
        pageTitle="Create new ActionList"
        previousPage={null}
        close="/account-created"
        history={props.history}
      />
      <div className="content">
        <div className="content-top-header title-page-header">
          <a
            onClick={() => {
              props.history.goBack();
              dispatch(deleteList());
            }}
            className="ml-auto"
          >
            <img src={Close} alt="close" />
          </a>
        </div>
        {/* <label className='page-subhead'>Create new ActionList</label> */}
        <h2 className="heading2">Step 1 of 5: General info</h2>
        <div className="row">
          <div className="col-12">
            <form
              className="title-form custom_form_list_style"
              onSubmit={handleSubmit(submit)}
            >
              <div className="form-group">
                <label htmlFor="title">Give your ActionList a title</label>
                <input
                  type="text"
                  className="form-control"
                  id="title"
                  name="title"
                  placeholder="e.g. Fire Recovery List"
                  ref={register({ required: "Title cannot be empty" })}
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                  maxLength={50}
                />
                <div className="info d-flex justify-content-end mt-1">
                  <span className="mr-auto">You can edit the title later</span>
                  <span className="limit">{title?.length}/50</span>
                </div>
                <label style={{ color: "red" }}>
                  {title.length === 50 && "You can write only 50 letters!"}{" "}
                </label>  
                {Object.keys(errors).length !== 0 && (
                  <div className="error-box">
                    <ul>
                      {errors.title && (
                        <li key="1" className="incorrect">
                          {errors.title.message}
                        </li>
                      )}
                    </ul>
                  </div>
                )}
                {/* <div className="info d-flex">
                  <span className="mr-auto">You can edit the title later</span>
                  <span className="limit">{title?.length}/50</span>
                </div> */}
              </div>
              <div className="form-group isFor-row">
                <label htmlFor="createpassword">The list is for:</label>
                <div className="listoption">
                  <div className="position-relative custom-row">
                    <label className="form-check-label" htmlFor="personal">
                      Personal reasons
                      <input
                        type="radio"
                        name="isFor"
                        id="personal"
                        value="personal"
                        ref={register({ required: "Please select reason" })}
                        checked={isFor === "personal"}
                        onChange={(e) => setIsFor("personal")}
                      />
                      <span className="checkmark"></span>
                    </label>
                    {Object.keys(errors).length !== 0 && (
                      <div className="error-box">
                        <ul>
                          {errors.isFor && (
                            <li key="1" className="incorrect">
                              {errors.isFor.message}
                            </li>
                          )}
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="position-relative custom-row">
                    <label
                      className="form-check-label"
                      defaultChecked="organization"
                    >
                      A nonprofit organization
                      <input
                        type="radio"
                        name="isFor"
                        id="organization"
                        value="organization"
                        ref={register}
                        checked={isFor === "organization"}
                        onChange={(e) => setIsFor("organization")}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="fixed-bottom bottom-btn">
                <button type="submit" className="btn btn-primary">
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListCreationGeneralInfo;
