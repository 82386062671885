import React from "react";
import { Switch } from "react-router-dom";
import AuthenticationCheck from "../Common/AuthenticationCheck";
import Header from "../Layout/GuestUser/Header";
import Login from "../Screens/GuestUser/Login";
import Signup from "../Screens/GuestUser/Signup";
import StepOneEmail from "../Screens/GuestUser/SignupStep/StepOneEmail";
import StepTwoPassword from "../Screens/GuestUser/SignupStep/StepTwoPassword";
import StepThreeName from "../Screens/GuestUser/SignupStep/StepThreeName";
import StepFourNumber from "../Screens/GuestUser/SignupStep/StepFourNumber";
import StepSixAccountCreated from "../Screens/GuestUser/SignupStep/StepSixAccountCreated";
import StepFiveNumberVerify from "../Screens/GuestUser/SignupStep/StepFiveNumberVerify";
import ForgotPassword from "../Screens/GuestUser/ForgotPassword";
import ForgotCompleted from "../Screens/GuestUser/ForgotCompleted";
import ResetPassword from "../Screens/GuestUser/ResetPassword";
import PublicActionList from "../Screens/GuestUser/PublicActionList";
//Privacypolicy and Term and Conditions
import Privacypolicy from "../Screens/PrivacyPolicy/Privacypolicy";
import TermAndCondition from "../Screens/TermAndCondition/TermAndCondition";
import Dashboard from "../Screens/Dashboard/Dashboard";
import ForgotPasswordSuccess from "../Screens/GuestUser/ForgotPasswordSuccess";

export default function CommonAuthenticationCheck(props) {
  return (
    <div>
      {/* <Header history={props.history} /> */}
      <Switch>
        <AuthenticationCheck
          exact
          path="/login"
          component={Login}
          redirectUrl="/me/create/step1"
        />
        <AuthenticationCheck
          exact
          path="/forgot-password"
          component={ForgotPassword}
          redirectUrl="/me/create/step1"
        />
        <AuthenticationCheck
          exact
          path="/forgot-password-success"
          component={ForgotPasswordSuccess}
          redirectUrl="/me/create/step1"
        />
        <AuthenticationCheck
          exact
          path="/reset-password/:id"
          component={ResetPassword}
          redirectUrl="/me/create/step1"
        />
        <AuthenticationCheck
          exact
          path="/action-list"
          component={PublicActionList}
          redirectUrl="/me/create/step1"
        />
        <AuthenticationCheck
          exact
          path="/forgot-completed"
          component={ForgotCompleted}
          redirectUrl="/me/create/step1"
        />
        <AuthenticationCheck
          exact
          path="/signup/:id"
          component={StepOneEmail}
          redirectUrl="/me/create/step1"
        />
        <AuthenticationCheck
          exact
          path="/signup-password"
          component={StepTwoPassword}
          redirectUrl="/me/create/step1"
        />
        <AuthenticationCheck
          exact
          path="/signup-name"
          component={StepThreeName}
          redirectUrl="/me/create/step1"
        />
        <AuthenticationCheck
          exact
          path="/signup-number"
          component={StepFourNumber}
          redirectUrl="/me/create/step1"
        />
        <AuthenticationCheck
          exact
          path="/signup-number-verify"
          component={StepFiveNumberVerify}
          redirectUrl="/me/create/step1"
        />
        <AuthenticationCheck
          exact
          path="/signup-completed"
          component={StepSixAccountCreated}
          redirectUrl="/me/create/step1"
        />
        <AuthenticationCheck
          exact
          path="/signup"
          component={StepOneEmail}
          redirectUrl="/me/create/step1"
        />
        <AuthenticationCheck
          exact
          path="/"
          component={Dashboard}
          redirectUrl="/me/create/step1"
        />
        <AuthenticationCheck
          exact
          path="/privacy/"
          component={Privacypolicy}
          redirectUrl="/me/create/step1"
        />
        <AuthenticationCheck
          exact
          path="/terms/"
          component={TermAndCondition}
          redirectUrl="/me/create/step1"
        />
      </Switch>
    </div>
  );
}
