import React from "react";
import Close from "../../assets/images/close2.png";
import Leaf from "../../assets/images/leaf.svg";
import Share from "../../assets/images/share.png";
import Filter from "../../assets/images/filter.png";
import Buy from "../../assets/images/buy.svg";
import MinusActive from "../../assets/images/minus_active.png";
import PlusInactive from "../../assets/images/plus_inactive.png";
import Give from "../../assets/images/give.svg";
import Location from "../../assets/images/location.svg";
import Clock from "../../assets/images/clock.svg";
import MinusInactive from "../../assets/images/minus_inactive.png";
import Do from "../../assets/images/do.svg";
import PlusActive from "../../assets/images/plus_active.png";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const PublicActionList = () => {
  const copyLink = (data) => {
    toast.success(data);
  };
  return (
    <div className="content theme-page sunshine">
      <div className="section_one">
        <div className="container">
          <div className="row row1">
            <div className="col left">
              <h2>Portland Forest Fire Emergency Wishlist</h2>
            </div>
            <div className="col right text-right desktop-view">
              <div className="wrapper text-left">
                <div className="progress">
                  <div
                    className="progress-bar sunshine"
                    role="progressbar"
                    style={{ width: "70%" }}
                    aria-valuenow="28"
                    aria-valuemin="0"
                    aria-valuemax="40"
                  ></div>
                </div>
                <label>28 of 40 needs actioned</label>
              </div>
            </div>
          </div>
          <div className="row row2">
            <div className="col">
              <label>
                Organized by <strong>Save Planet</strong>
                {/* <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.46246 0.897018L8.45212 1.2865C8.80515 1.42543 9.19771 1.42543 9.55075 1.2865L10.5404 0.897018C11.7517 0.420296 13.1231 0.988317 13.6425 2.18196L14.0669 3.15715C14.2183 3.50503 14.4959 3.78261 14.8438 3.934L15.819 4.35839C17.0126 4.87784 17.5806 6.24917 17.1039 7.46051L16.7144 8.45016C16.5755 8.8032 16.5755 9.19576 16.7144 9.54879L17.1039 10.5384C17.5806 11.7498 17.0126 13.1211 15.819 13.6406L14.8438 14.065C14.4959 14.2163 14.2183 14.4939 14.0669 14.8418L13.6425 15.817C13.1231 17.0106 11.7517 17.5787 10.5404 17.1019L9.55075 16.7125C9.19771 16.5735 8.80515 16.5735 8.45212 16.7125L7.46246 17.1019C6.25112 17.5787 4.8798 17.0106 4.36034 15.817L3.93595 14.8418C3.78457 14.4939 3.50698 14.2163 3.15911 14.065L2.18391 13.6406C0.99027 13.1211 0.422249 11.7498 0.898972 10.5384L1.28845 9.54879C1.42739 9.19576 1.42739 8.8032 1.28845 8.45016L0.898972 7.46051C0.422249 6.24917 0.99027 4.87784 2.18391 4.35839L3.15911 3.934C3.50698 3.78261 3.78457 3.50503 3.93595 3.15715L4.36034 2.18196C4.8798 0.988317 6.25112 0.420296 7.46246 0.897018ZM11.6277 6.6673L7.98002 10.771L6.35499 9.14592C6.15972 8.95066 5.84314 8.95066 5.64788 9.14592C5.45262 9.34119 5.45262 9.65777 5.64788 9.85303L7.64788 11.853C7.8513 12.0565 8.18401 12.0467 8.37514 11.8317L12.3751 7.33166C12.5586 7.12527 12.54 6.80923 12.3336 6.62577C12.1272 6.44232 11.8112 6.46091 11.6277 6.6673Z"
                    fill="#FFB100"
                  />
                </svg> */}
              </label>
            </div>
          </div>
          <div className="row row3">
            <div className="col">
              <ul>
                <li>
                  <img src={Leaf} alt="Leaf" />
                  Environment
                </li>
                <li>Updated 15 mins ago</li>
              </ul>
            </div>
          </div>
          <div className="mobile-view progress-line">
            <div className="wrapper text-left">
              <div className="progress w-100">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: "70%" }}
                  aria-valuenow="28"
                  aria-valuemin="0"
                  aria-valuemax="40"
                ></div>
              </div>
              <label>28 of 40 needs actioned</label>
            </div>
          </div>
          <div className="row row4">
            <div className="col">
              <p>
                Hi! A terrible disaster is happening right now, and we really
                need help from anybody who cares about the future of our city.
                You can either donate money or help doing smth. Choose what fits
                you best and contact with us, thank you!
              </p>
            </div>
          </div>
          <div className="row row5">
            <div className="col">
              <button type="button" className="btn btn-donate">
                Donate
              </button>
              <button
                type="button"
                className="btn btn-share"
                onClick={() => window.$("#shareListModal").modal("show")}
              >
                <img src={Share} alt="Share" />
                Share
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="section_two">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="action-row">
                <div className="mobile-view">
                  <div className="dropdown">
                    <button
                      className="btn btn-secondary dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Filter
                      <img src={Filter} className="ml-auto" alt="Filter" />
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <div className="mobile-header">
                        <nav className="navbar">
                          <label className="navbar-brand mx-auto middle-label">
                            Filters
                          </label>
                          <a href="#" className="cross">
                            <img src={Close} alt="Close" />
                          </a>
                        </nav>
                      </div>
                      <div className="content">
                        <form>
                          <h4>Status of a need</h4>
                          <div className="form-group row status-row">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                              <label htmlFor="completed">
                                Completed
                                <input type="checkbox" id="completed" />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                              <label htmlFor="incompleted">
                                Incompleted
                                <input type="checkbox" id="incompleted" />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </div>

                          <div className="form-group price-row">
                            <h4>Price range</h4>
                            <div
                              id="price_slider"
                              className="price_slider"
                            ></div>
                            <div className="input-wrapper">
                              <div className="input-box left">
                                <label htmlFor="minPrice">
                                  Min. price, USD
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="minPrice"
                                  placeholder=""
                                  defaultValue="20"
                                />
                              </div>
                              <div className="middle">-</div>
                              <div className="input-box right">
                                <label htmlFor="maxPrice">
                                  Max. price, USD
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="maxPrice"
                                  placeholder=""
                                  defaultValue="1500+"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="form-group">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter location"
                                aria-label="Enter location"
                                aria-describedby="basic-addon2"
                              />
                              <div className="input-group-append">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M12 24C13.6667 24 22 18.8571 22 10.2857C22 4.60624 17.5217 0 12 0C6.47833 0 2 4.60624 2 10.2857C2 18.8571 10.3333 24 12 24ZM12 13C14.2091 13 16 11.2091 16 9C16 6.79086 14.2091 5 12 5C9.79086 5 8 6.79086 8 9C8 11.2091 9.79086 13 12 13Z"
                                    fill="#607BFF"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>

                          <div className="form-group">
                            <div className="dropdown dropdown">
                              <button
                                className="btn btn-secondary dropdown-toggle"
                                type="button"
                                id="innerDropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                Category of need
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="ml-auto"
                                >
                                  <path
                                    d="M4 9L12 17L20 9"
                                    stroke="#607BFF"
                                    strokeWidth="4"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </button>
                              <div
                                className="dropdown-menu"
                                aria-labelledby="innerDropdownMenuButton"
                              >
                                <a className="dropdown-item" href="#">
                                  Action
                                </a>
                                <a className="dropdown-item" href="#">
                                  Another action
                                </a>
                                <a className="dropdown-item" href="#">
                                  Something else here
                                </a>
                              </div>
                            </div>
                          </div>

                          <div className="form-group">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Complete-by date"
                                aria-label="Complete-by date"
                                aria-describedby="basic-addon2"
                              />
                              <div className="input-group-append">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M3 5C1.89543 5 1 5.89543 1 7V21C1 22.1046 1.89543 23 3 23H21C22.1046 23 23 22.1046 23 21V7C23 5.89543 22.1046 5 21 5H3ZM5 7C3.89543 7 3 7.89543 3 9V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V9C21 7.89543 20.1046 7 19 7H5Z"
                                    fill="#607BFF"
                                  />
                                  <path
                                    d="M5 3.5V5H10V3.5C10 2.11929 8.88071 1 7.5 1C6.11929 1 5 2.11929 5 3.5Z"
                                    fill="#607BFF"
                                  />
                                  <path
                                    d="M14 3.5V5H19V3.5C19 2.11929 17.8807 1 16.5 1C15.1193 1 14 2.11929 14 3.5Z"
                                    fill="#607BFF"
                                  />
                                  <rect
                                    x="5"
                                    y="9"
                                    width="14"
                                    height="4"
                                    rx="2"
                                    fill="#607BFF"
                                  />
                                  <rect
                                    x="5"
                                    y="15"
                                    width="11"
                                    height="4"
                                    rx="2"
                                    fill="#607BFF"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>

                          <button type="submit" className="btn btn-prime">
                            Apply filters
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                  <button type="button" className="btn grid-btn active">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="4.5" cy="6.5" r="4.5" />
                      <circle cx="4.5" cy="17.5" r="4.5" />
                      <rect x="12" y="2" width="12" height="9" rx="4" />
                      <rect x="12" y="13" width="12" height="9" rx="4" />
                    </svg>
                  </button>
                  <button type="button" className="btn list-btn">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="2.5" cy="12.5" r="2.5" />
                      <circle cx="2.5" cy="19.5" r="2.5" />
                      <circle cx="2.5" cy="5.5" r="2.5" />
                      <rect x="8" y="10" width="16" height="4" rx="2" />
                      <rect x="8" y="18" width="16" height="4" rx="2" />
                      <rect x="8" y="3" width="16" height="4" rx="2" />
                    </svg>
                  </button>
                </div>
                <ul className="nav nav-tabs mr-auto" id="myTab" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="all-tab"
                      data-toggle="tab"
                      href="#all"
                      role="tab"
                      aria-controls="all"
                      aria-selected="true"
                    >
                      All Needs
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="buy-tab"
                      data-toggle="tab"
                      href="#buy"
                      role="tab"
                      aria-controls="buy"
                      aria-selected="false"
                    >
                      Buy
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="give-tab"
                      data-toggle="tab"
                      href="#give"
                      role="tab"
                      aria-controls="give"
                      aria-selected="false"
                    >
                      Give
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="do-tab"
                      data-toggle="tab"
                      href="#do"
                      role="tab"
                      aria-controls="do"
                      aria-selected="false"
                    >
                      Do
                    </a>
                  </li>
                </ul>
                <div className="for-desktop">
                  <div className="dropdown">
                    <button
                      className="btn btn-secondary dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Filter
                      <img src={Filter} className="ml-auto" alt="Filter" />
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <div className="content">
                        <form>
                          <h4>Status of a need</h4>
                          <div className="form-group row status-row">
                            <div className="col-xl-6">
                              <label htmlFor="completed">
                                Completed
                                <input type="checkbox" id="completed" />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                            <div className="col-xl-6">
                              <label htmlFor="incompleted">
                                Incompleted
                                <input type="checkbox" id="incompleted" />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </div>

                          <div className="form-group price-row">
                            <h4>Price range</h4>
                            <div
                              id="price_slider"
                              className="price_slider"
                            ></div>
                            <div className="input-wrapper">
                              <div className="input-box left">
                                <label htmlFor="minPrice">
                                  Min. price, USD
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="minPrice"
                                  placeholder=""
                                  defaultValue="20"
                                />
                              </div>
                              <div className="middle">-</div>
                              <div className="input-box right">
                                <label htmlFor="maxPrice">
                                  Max. price, USD
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="maxPrice"
                                  placeholder=""
                                  defaultValue="1500+"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="form-group">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter location"
                                aria-label="Enter location"
                                aria-describedby="basic-addon2"
                              />
                              <div className="input-group-append">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M12 24C13.6667 24 22 18.8571 22 10.2857C22 4.60624 17.5217 0 12 0C6.47833 0 2 4.60624 2 10.2857C2 18.8571 10.3333 24 12 24ZM12 13C14.2091 13 16 11.2091 16 9C16 6.79086 14.2091 5 12 5C9.79086 5 8 6.79086 8 9C8 11.2091 9.79086 13 12 13Z"
                                    fill="#607BFF"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>

                          <div className="form-group">
                            <div className="dropdown">
                              <button
                                className="btn btn-secondary dropdown-toggle"
                                type="button"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                Category of need
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="ml-auto"
                                >
                                  <path
                                    d="M4 9L12 17L20 9"
                                    stroke="#607BFF"
                                    strokeWidth="4"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </button>
                              <div
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <a className="dropdown-item" href="#">
                                  Action
                                </a>
                                <a className="dropdown-item" href="#">
                                  Another action
                                </a>
                                <a className="dropdown-item" href="#">
                                  Something else here
                                </a>
                              </div>
                            </div>
                          </div>

                          <div className="form-group">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Complete-by date"
                                aria-label="Complete-by date"
                                aria-describedby="basic-addon2"
                              />
                              <div className="input-group-append">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M3 5C1.89543 5 1 5.89543 1 7V21C1 22.1046 1.89543 23 3 23H21C22.1046 23 23 22.1046 23 21V7C23 5.89543 22.1046 5 21 5H3ZM5 7C3.89543 7 3 7.89543 3 9V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V9C21 7.89543 20.1046 7 19 7H5Z"
                                    fill="#607BFF"
                                  />
                                  <path
                                    d="M5 3.5V5H10V3.5C10 2.11929 8.88071 1 7.5 1C6.11929 1 5 2.11929 5 3.5Z"
                                    fill="#607BFF"
                                  />
                                  <path
                                    d="M14 3.5V5H19V3.5C19 2.11929 17.8807 1 16.5 1C15.1193 1 14 2.11929 14 3.5Z"
                                    fill="#607BFF"
                                  />
                                  <rect
                                    x="5"
                                    y="9"
                                    width="14"
                                    height="4"
                                    rx="2"
                                    fill="#607BFF"
                                  />
                                  <rect
                                    x="5"
                                    y="15"
                                    width="11"
                                    height="4"
                                    rx="2"
                                    fill="#607BFF"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>

                          <button type="submit" className="btn btn-prime">
                            Apply filters
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                  <button type="button" className="btn grid-btn active ">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="4.5" cy="6.5" r="4.5" />
                      <circle cx="4.5" cy="17.5" r="4.5" />
                      <rect x="12" y="2" width="12" height="9" rx="4" />
                      <rect x="12" y="13" width="12" height="9" rx="4" />
                    </svg>
                  </button>
                  <button type="button" className="btn list-btn">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="2.5" cy="12.5" r="2.5" />
                      <circle cx="2.5" cy="19.5" r="2.5" />
                      <circle cx="2.5" cy="5.5" r="2.5" />
                      <rect x="8" y="10" width="16" height="4" rx="2" />
                      <rect x="8" y="18" width="16" height="4" rx="2" />
                      <rect x="8" y="3" width="16" height="4" rx="2" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="all"
                  role="tabpanel"
                  aria-labelledby="all-tab"
                >
                  <div className="need-box priority">
                    <div className="column column-first mr-auto">
                      <div className="left d-inline-block">
                        <img src={Buy} alt="Buy" />
                      </div>
                      <div className="right d-inline-block">
                        <h5>
                          Peanut butter <span className="badge">Priority</span>
                        </h5>
                      </div>
                    </div>
                    <div className="column column-price">
                      <label>$5.00</label>
                      <label className="quantity">10000 needed</label>
                    </div>
                    <div className="divider mobile-view">
                      <hr />
                    </div>
                    <div className="column column-last">
                      <div className="left">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <button
                              className="btn btn-outline-secondary"
                              type="button"
                            >
                              <img src={MinusActive} alt="MinusActive" />
                            </button>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue="10000"
                          />
                          <div className="input-group-append">
                            <button
                              className="btn btn-outline-secondary"
                              type="button"
                            >
                              <img src={PlusInactive} alt="PlusInactive" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="right">
                        <label>10000 needed</label>
                        <button type="button" className="btn">
                          Buy
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="need-box priority">
                    <div className="column column-first mr-auto">
                      <div className="left d-inline-block">
                        <img src={Give} alt="Give" />
                      </div>
                      <div className="right d-inline-block">
                        <h5>
                          Set of 6 dining room chairs
                          <span className="badge">Priority</span>
                        </h5>
                        <p className="for-desktop">
                          A family in need would be glad to get 6 chairs...{" "}
                          <a href="">Read More</a>
                        </p>
                      </div>
                    </div>
                    <div className="column column-first mobile-view">
                      <p>
                        A family in need would be glad to get 6 chairs...{" "}
                        <a href="">Read More</a>
                      </p>
                    </div>
                    <div className="column column-location">
                      <div className="wrapper">
                        <div className="left d-inline-block">
                          <img src={Location} alt="Location" />
                        </div>
                        <div className="right d-inline-block">
                          <label>Downtown Toronto</label>
                        </div>
                      </div>
                    </div>
                    <div className="divider mobile-view">
                      <hr />
                    </div>
                    <div className="column column-last">
                      <div className="left">
                        <div className="input-group disabled for-desktop">
                          <div className="input-group-prepend">
                            <button
                              className="btn btn-outline-secondary"
                              type="button"
                            >
                              <img src={MinusInactive} alt="MinusInactive" />
                            </button>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue="1"
                          />
                          <div className="input-group-append">
                            <button
                              className="btn btn-outline-secondary"
                              type="button"
                            >
                              <img src={PlusInactive} alt="PlusInactive" />
                            </button>
                          </div>
                        </div>
                        <div className="quantity mobile-view">
                          <label>1 needed</label>
                        </div>
                      </div>
                      <div className="right">
                        <label>1 needed</label>
                        <button type="button" className="btn">
                          Give
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="need-box">
                    <div className="column column-first mr-auto">
                      <div className="left d-inline-block">
                        <img src={Do} alt="Do" />
                      </div>
                      <div className="right d-inline-block">
                        <h5>Senior requires food bank pickup</h5>
                        <p className="for-desktop">
                          Senior needs help with food bank pickup. Plea...{" "}
                          <a href="">Read More</a>
                        </p>
                      </div>
                    </div>
                    <div className="column column-first mobile-view">
                      <p>
                        Senior needs help with food bank pickup. Plea...{" "}
                        <a href="">Read More</a>
                      </p>
                    </div>
                    <div className="column column-location column-time">
                      <div className="wrapper">
                        <div className="left d-inline-block">
                          <img src={Clock} alt="Clock" />
                        </div>
                        <div className="right d-inline-block">
                          <label>Complete-by: Dec 21, 3:00 PM</label>
                        </div>
                      </div>
                    </div>
                    <div className="column column-location">
                      <div className="wrapper">
                        <div className="left d-inline-block">
                          <img src={Location} alt="Location" />
                        </div>
                        <div className="right d-inline-block">
                          <label>
                            SCARBOROUGH{" "}
                            <span>(Lawrence/Scarborough Golf Club Rd)</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="divider mobile-view">
                      <hr />
                    </div>
                    <div className="column column-last">
                      <div className="left">
                        <div className="input-group disabled for-desktop">
                          <div className="input-group-prepend">
                            <button
                              className="btn btn-outline-secondary"
                              type="button"
                            >
                              <img src={MinusInactive} alt="MinusInactive" />
                            </button>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue="1"
                          />
                          <div className="input-group-append">
                            <button
                              className="btn btn-outline-secondary"
                              type="button"
                            >
                              <img src={PlusInactive} alt="PlusInactive" />
                            </button>
                          </div>
                        </div>
                        <div className="quantity mobile-view">
                          <label>1 needed</label>
                        </div>
                      </div>
                      <div className="right">
                        <label>1 needed</label>
                        <button type="button" className="btn">
                          Volunteer
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="need-box">
                    <div className="column column-first mr-auto">
                      <div className="left d-inline-block">
                        <img src={Buy} alt="Buy" />
                      </div>
                      <div className="right d-inline-block">
                        <h5>
                          Arts & crafts, books for children ages 18, 15, 4, 2, 3
                          and a 14 month
                        </h5>
                      </div>
                    </div>
                    <div className="column column-price">
                      <label>$20.99</label>
                      <label className="quantity">8 needed</label>
                    </div>
                    <div className="divider mobile-view">
                      <hr />
                    </div>
                    <div className="column column-last">
                      <div className="left">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <button
                              className="btn btn-outline-secondary"
                              type="button"
                            >
                              <img src={MinusActive} alt="MinusActive" />
                            </button>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue="1"
                          />
                          <div className="input-group-append">
                            <button
                              className="btn btn-outline-secondary"
                              type="button"
                            >
                              <img src={PlusActive} alt="PlusActive" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="right">
                        <label>8 needed</label>
                        <button type="button" className="btn">
                          Buy
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="buy"
                  role="tabpanel"
                  aria-labelledby="buy-tab"
                >
                  Buy
                </div>
                <div
                  className="tab-pane fade"
                  id="give"
                  role="tabpanel"
                  aria-labelledby="give-tab"
                >
                  Give
                </div>
                <div
                  className="tab-pane fade"
                  id="do"
                  role="tabpanel"
                  aria-labelledby="do-tab"
                >
                  Do
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade share-list-modal"
        id="shareListModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="mobile-header">
                <nav className="navbar">
                  <label className="navbar-brand mx-auto middle-label">
                    Filters
                  </label>
                  <span className="cross">
                    <img
                      src="images/close2.png"
                      onClick={() => window.$("#shareListModal").modal("hide")}
                      alt="Close"
                    />
                  </span>
                </nav>
              </div>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <img src="images/close2.png" alt="Close" />
              </button>
              <h2>Share the list</h2>
              <label className="label">
                You can share the link with your friends or embed the code with
                the list to your website.
              </label>
              <div className="content">
                <input type="text" defaultValue={window.location.href} />
                <CopyToClipboard text={window.location.href}>
                  <button
                    type="button"
                    className="btn btn-extra"
                    onClick={() => copyLink("Link Copied")}
                  >
                    Copy the link
                  </button>
                </CopyToClipboard>
                <textarea
                  rows="3"
                  defaultValue={`<iframe src="${window.location.href}" style="border: 0px #ffffff none" name="ActionList" frameborder="1" width="100%" height="600px" allowfullscreen></iframe>`}
                ></textarea>
                <CopyToClipboard
                  text={`<iframe src="${window.location.href}" style="border: 0px #ffffff none" name="ActionList" frameborder="1" width="100%" height="600px" allowfullscreen></iframe>`}
                >
                  <button
                    type="button"
                    className="btn btn-extra"
                    onClick={() => copyLink("Code Copied")}
                  >
                    Embed the code
                  </button>
                </CopyToClipboard>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default PublicActionList;
