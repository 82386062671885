import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import closeBut from '../../src/assets/images/close.png';
const MobileScreenLogo = (props) => {
  const history = useHistory()
  return (
    <div class='mobile-header'>
      <nav class='navbar' style={{ display: 'flex', alignItems: 'center' }}>
        <a class='navbar-brand' href={props.linkdata} style={{ width: '48%' }}>
          <img src='images/logo.svg' />
        </a>
        {/* {props.linkdata === '#' ? (
          <a
            class='login'
            href='#'
            style={{ width: '35%', textAlign: 'right' }}
          >
            {props.navlink}
          </a>
        ) : (<>
          {props.pageName && props.pageName === "signUpFirst" ?  */}
          <Link
            class='login'
               to={props.navlink == "Log in" ? "/login" : "signup-email"}
            style={{ width: '35%', textAlign: 'right' }}
          >
            {props.navlink}
          </Link>
          {/* : <img src={closeBut} onClick = {() => history.push('/login')} />}
          
          </>)} */}
        {/* <a class='login' href='#' style={{ width: '35%', textAlign: 'right' }}>
          {props.navlink}
        </a> */}
      </nav>
    </div>
  );
};

export default MobileScreenLogo;
