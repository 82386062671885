import React, { useState, useEffect } from 'react';
import Back from '../../../assets/images/back.png';
import Close from '../../../assets/images/close2.png';
import { useForm } from 'react-hook-form';
import ListCreationMobileHeader from '../../../Common/MobileHeaderOne';
import UserDeskTopHeaderOne from '../../../Common/UserDeskTopHeaderOne';
import { useSelector, useDispatch } from 'react-redux';
import { addAdditionalInfo, deleteList, createList } from '../../../Store/Actions/userActions';
import Profileheadermobile from "../../../Common/Profileheadermobile";

import PlacesAutocomplete from 'react-places-autocomplete';
import { isMobile } from "react-device-detect";
export default function ListCreationAddtionalInfo(props) {
  const [place, setPlace] = useState('');
  const [age, setAge] = useState(false);
  let { register, errors, handleSubmit } = useForm();
  let dispatch = useDispatch();
  const create_List = useSelector((state) => state.createList);
  const { userCreateList } = create_List;
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  useEffect(() => {
    if (userCreateList && userCreateList.place && userCreateList.confirmAge) {
      setPlace(userCreateList.place);
      setAge(userCreateList.confirmAge);
    }
  }, [userCreateList]);

  const handleChange = (place) => {
    setPlace(place);
  };

  const handleSelect = (place) => {
    setPlace(place);
  };

  let submit = (data) => {
    let list = { ...userCreateList, ...data };
    dispatch(addAdditionalInfo(list));
    localStorage.setItem('themeName', '')
    props.history.push('/me/create/step5');
  };

  const closeButtonFunc = () => {
    let list = {
      ...userCreateList,
      userId: userInfo?.userId,
      userName: userInfo?.firstName + ' ' + userInfo?.lastName,
      actionListStatus: "draft",
      userType: userInfo?.userType
    };
    dispatch(createList(list))
    dispatch(deleteList());
    props.history.push('/me/lists')
  }

  return (
    <div>
      {/* <UserDeskTopHeaderOne history={props.history} /> */}
      {isMobile ? "" :  <Profileheadermobile history={props.history} />}
      <ListCreationMobileHeader
        pageTitle='Create new ActionList'
        previousPage='/me/create/step3'
        close='/account-created'
        history={props.history}
      />
      <div className='content'>
        <div
          className='content-top-header additional-page-header'
          style={{ marginBottom: 100 }}
        >
          <a
            onClick={() =>
              props.history.push('/me/create/step3')
            }
            className='back_link cursor-pointer'
          >
            <img src={Back} alt='back' />
            Back
          </a>
          <a
            onClick={() => { closeButtonFunc() }}
            className='ml-auto'
          >
            <img src={Close} alt='close' />
          </a>
        </div>
        {/* <label className='page-subhead'>Create new ActionList</label> */}
        <h2 className='heading2'>Step 4 of 5: Location</h2>
        <p className='para'>
          {/* We need this information to set up your ActionList payments. */}
          Help people discover your ActionList
        </p>
        <div className='row'>
          <div className='col-12'>
            <form
              className='additionalinfo-form'
              onSubmit={handleSubmit(submit)}
            >
              <div className='form-group'>
                <label htmlFor='place'>What is the location?</label>
                <PlacesAutocomplete
                  value={place}
                  onChange={handleChange}
                  onSelect={handleSelect}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div>
                      <input
                        {...getInputProps({
                          placeholder: 'e.x.: Red st., 65/316, Texas, USA',
                          className: 'form-control',
                          ref: register({
                            required: 'Where is this taking place?',
                          }),
                          id: 'place',
                          name: 'place',
                        })}
                      />
                      {suggestions?.length > 0 && <div className='autocomplete-dropdown-container'>
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? 'suggestion-item--active'
                            : 'suggestion-item';
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                          return (
                            <div
                              key={suggestion.placeId}
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>}
                    </div>
                  )}
                </PlacesAutocomplete>
                {Object.keys(errors).length !== 0 && (
                  <div className='error-box'>
                    {
                      <ul>
                        {errors.place && (
                          <li key='1' className='incorrect'>
                            {errors.place.message}
                          </li>
                        )}
                      </ul>
                    }
                  </div>
                )}
              </div>
              <div className='form-group'>
                {/* <label>Confirm your age</label>
                 <div className='confirmAge'>
                  <label htmlFor='confirmAge'>
                    I'm over 18
                    <input
                      type='checkbox'
                      id='confirmAge'
                      name='confirmAge'
                      ref={register({ required: 'Confirm your age' })}
                      checked={age}
                      onChange={(e) => setAge(e.target.checked)}
                    />
                    <span className='checkmark'></span>
                  </label>
                </div> */}
                {Object.keys(errors).length !== 0 && (
                  <div className='error-box'>
                    {
                      <ul>
                        {errors.confirmAge && (
                          <li key='1' className='incorrect'>
                            {errors.confirmAge.message}
                          </li>
                        )}
                      </ul>
                    }
                  </div>
                )}
              </div>
              <div className='fixed-bottom bottom-btn'>
                <button type='submit' className='btn btn-primary'>
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}