import Cookies from "js-cookie";
import React, { useState, useEffect } from "react";
import Profileheadermobile from "../../Common/Profileheadermobile";
import ListCreationMobileHeader from "../../Common/MobileHeaderOne";
import AxiosApi from "../../Config/axios-api";
import { useDispatch, useSelector } from "react-redux";
import { USER_ACTION_LIST_LIST_SUCCESS } from "../../Store/Constants/userConstants";
import Leaf from "../../assets/images/leaf.svg";
import ListPreviewGray from "../../assets/images/list_preview_gray.png";
import ListPreview from "../../assets/images/list_preview.png";
import UnloginuserHead from "../../Common/UnloginuserHead";
import { data } from "jquery";
import { getUserDetailsInfo } from "../../Store/Actions/userActions";
import right_angleimg from "../../assets/images/right_angle_gray.png";
import Header from "./Header";
const EmbedSearchData = (props) => {
  const { innerWidth: width, innerHeight: height } = window;

  const [searchResponse, setResponse] = useState();
  const [noDataResponse, setNoDataResponse] = useState("");
  const [userInfoData, setUserDetails] = useState();
  const dispatch = useDispatch();

  const userSignin = useSelector((state) => state.userSignin);

  const { userInfo } = userSignin;
  console.log(props.match.params.searchData);
  useEffect(() => {
    getSearchData(props.match.params.searchData);
  }, [props.match.params.searchData]);

  const getSearchData = async (searchitem) => {
    const { data } = await AxiosApi.get(
      "actionList/searchActionList?searchKey=" + searchitem
    );

    if (data?.actionLists) {
      setResponse(data);
      setNoDataResponse("");
    } else if (data?.msg.includes("No Record")) {
      setNoDataResponse(data?.msg);
    }
  };

  useEffect(() => {
    getuserDetails();
  }, []);

  const redirectlist = (item) => {
    dispatch({
      type: USER_ACTION_LIST_LIST_SUCCESS,
      payload: item,
    });
    props.history.push("/embed/l/" + item.actionListId);
  };

  const getuserDetails = async () => {
    dispatch(getUserDetailsInfo(userInfo?.userId)).then((res) => {
      if (res?.data) {
        setUserDetails(res?.data);
      }
    });
    // const { data } = await AxiosApi.get(
    //   "/userDetailsById/" + userInfo?.userId,
    //   {}
    // );
    // setUserDetails(data);
  };

  return (
    <>
      <Header />

      <div className="content dashboard ">
        <div className="container">
          {searchResponse ? (
            <div>
              <div className="section1 section2">
                <div className="header-row">
                  <div className="left">
                    <h3 className="subhead">Search Result</h3>
                    {noDataResponse !== "" ? null : <label>Action Lists</label>}
                  </div>
                </div>

                {noDataResponse !== "" ? (
                  <div className="">
                    <div className="nothing-found subhead">
                      <label
                        style={{
                          width: "100%",
                          textAlign: "center",
                          fontSize: "25px",
                        }}
                      >
                        Nothing found
                      </label>
                    </div>
                  </div>
                ) : (
                  searchResponse?.actionLists.length > 0 &&
                  searchResponse?.actionLists.map((item, index) => {
                    let percentwidth =
                      Math.ceil((item.done / item.needsCount) * 100) + "%";
                    let theme;
                    switch (item.themeName) {
                      case "blossom":
                        theme = "list-box theme-blossom";
                        break;
                      case "greenery":
                        theme = "list-box theme-greenery";
                        break;
                      case "waterpolo":
                        theme = "list-box theme-waterpolo";
                        break;
                      case "dawn":
                        theme = "list-box theme-dawn";
                        break;
                      case "fair":
                        theme = "list-box theme-fair";
                        break;
                      case "sunshine":
                        theme = "list-box theme-sunshine";
                        break;
                      default:
                        theme = "list-box theme-sunshine";
                    }
                    return (
                      <>
                        {
                          <div
                            className={theme}
                            onClick={() => redirectlist(item)}
                          >
                            <div className="action-listbox">
                              <h3>{item.title}</h3>
                              <img
                                src={right_angleimg}
                                className="search-listimg"
                              />
                              <p>
                                Organized by <strong>{item?.userName}</strong>
                              </p>
                              {/* <p>{item.purpose}</p> */}
                            </div>

                            {/* <div className='top'>
                           <label className='need-name'>{item.title}</label>
                           <div className='row2'>
                             <div className='left'>
                               <label className='organized-by'>
                                 Organized by{' '}
                                 <strong>
                                 {item?.userName}
                                 </strong>
                               </label>
                             </div>
                             <div className='right'>
                               <img src={Leaf} alt='leaf' />
                               <label>{item.purpose}</label>
                             </div>
                           </div>
                         </div> */}
                          </div>
                        }
                      </>
                    );
                  })
                )}
              </div>
            </div>
          ) : (
            <div className="header-row">
              <div className="">
                <h3 className="subhead">Search Result</h3>
                <div className="nothing-found">
                  <label
                    style={{
                      width: "100%",
                      textAlign: "center",
                      fontSize: "25px",
                    }}
                    className="subhead"
                  >
                    Nothing found
                  </label>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EmbedSearchData;
