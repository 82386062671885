import React, { useState, useEffect } from "react";
import Ellipse from "../../../assets/images/ellipse.png";
import Back from "../../../assets/images/back.png";
import Close from "../../../assets/images/close2.png";
import { useForm } from "react-hook-form";
import ListCreationMobileHeader from "../../../Common/MobileHeaderOne";
import { useSelector, useDispatch } from "react-redux";
import { addPurpose } from "../../../Store/Actions/userActions";
import UserDeskTopHeaderOne from "../../../Common/UserDeskTopHeaderOne";
import Profileheadermobile from "../../../Common/Profileheadermobile";

import { deleteList, createList } from "../../../Store/Actions/userActions";
import { isMobile } from "react-device-detect";
export default function ListCreationPurpose(props) {
  let { register, handleSubmit, errors } = useForm();
  let dispatch = useDispatch();
  const [purpose, setPurpose] = useState("");
  const create_List = useSelector((state) => state.createList);
  const { userCreateList } = create_List;
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  useEffect(() => {
    if (userCreateList && userCreateList.purposeOption) {
      setPurpose(userCreateList.purposeOption);
    }
  }, [userCreateList]);

  let submit = (data) => {
    let list = { ...userCreateList, ...data };
    dispatch(addPurpose(list));
    props.history.push("/me/create/step3");
  };
  console.log(purpose);
  const closeButtonFunc = () => {
    let list = {
      ...userCreateList,
      userId: userInfo?.userId,
      userName: userInfo?.firstName + " " + userInfo?.lastName,
      actionListStatus: "draft",
      userType: userInfo?.userType,
    };
    console.log("list", { list });
    //  dispatch(createList(list))
    dispatch(deleteList());
    props.history.push("/me/lists");
  };

  return (
    <div>
      {isMobile ? "" :   <Profileheadermobile history={props.history} />}
      <ListCreationMobileHeader
        pageTitle="Create new ActionList"
        previousPage="/me/create/step1"
        close="/account-created"
        history={props.history}
      />

      <div className="content">
        <div
          className="content-top-header purpose-page-header"
          style={{ marginBottom: 45 }}
        >
          <a
            onClick={() => props.history.push("/me/create/step1")}
            className="back_link cursor-pointer"
          >
            <img src={Back} alt="back" />
            Back
          </a>
          <a
            onClick={() => {
              closeButtonFunc();
            }}
            className="ml-auto"
          >
            <img src={Close} alt="close" />
          </a>
        </div>
        {/* <label className='page-subhead'>Create new ActionList</label> */}
        <h2 className="heading2">Step 2 of 5: Purpose</h2>
        <div className="row">
          <div className="col-12">
            <form className="purpose-form" onSubmit={handleSubmit(submit)}>
              <div className="form-group">
                <div className="column">
                  <label className="purpose-option" htmlFor="advocacy">
                    <input
                      type="radio"
                      name="purposeOption"
                      id="advocacy"
                      value="advocacy"
                      ref={register()}
                      onChange={(e) => setPurpose("advocacy")}
                      // defaultChecked
                    />
                    <div className="option-wrap">
                      <img alt="imag" src={Ellipse} />
                      <span>Advocacy</span>
                    </div>
                  </label>
                  <label className="purpose-option" htmlFor="animalsandpets">
                    <input
                      type="radio"
                      name="purposeOption"
                      id="animalsandpets"
                      value="animals and pets"
                      ref={register}
                      checked={purpose === "animals and pets"}
                      onChange={(e) => setPurpose("animals and pets")}
                    />
                    <div className="option-wrap">
                      <img alt="imag" src={Ellipse} />
                      <span>Animals and Pets</span>
                    </div>
                  </label>
                  <label className="purpose-option" htmlFor="artsandculture">
                    <input
                      type="radio"
                      name="purposeOption"
                      id="artsandculture"
                      value="arts and culture"
                      ref={register}
                      checked={purpose === "arts and culture"}
                      onChange={(e) => setPurpose("arts and culture")}
                    />
                    <div className="option-wrap">
                      <img alt="imag" src={Ellipse} />
                      <span>Arts and Culture</span>
                    </div>
                  </label>
                  <label className="purpose-option" htmlFor="crisissupport">
                    <input
                      type="radio"
                      name="purposeOption"
                      id="crisissupport"
                      value="crisis support"
                      ref={register}
                      checked={purpose === "crisis support"}
                      onChange={(e) => setPurpose("crisis support")}
                    />
                    <div className="option-wrap">
                      <img alt="imag" src={Ellipse} />
                      <span>Crisis Support</span>
                    </div>
                  </label>
                  <label className="purpose-option" htmlFor="communityandlocal">
                    <input
                      type="radio"
                      name="purposeOption"
                      id="communityandlocal"
                      value="community and local"
                      ref={register}
                      checked={purpose === "community and local"}
                      onChange={(e) => setPurpose("community and local")}
                    />
                    <div className="option-wrap">
                      <img alt="imag" src={Ellipse} />
                      <span>Community and Local</span>
                    </div>
                  </label>
                </div>

                <div className="column">
                  <label className="purpose-option" htmlFor="education">
                    <input
                      type="radio"
                      name="purposeOption"
                      id="education"
                      value="education"
                      ref={register}
                      checked={purpose === "education"}
                      onChange={(e) => setPurpose("education")}
                    />
                    <div className="option-wrap">
                      <img alt="imag" src={Ellipse} />
                      <span>Education</span>
                    </div>
                  </label>
                  <label
                    className="purpose-option"
                    htmlFor="emergencyanddisasterrelief"
                  >
                    <input
                      type="radio"
                      name="purposeOption"
                      id="emergencyanddisasterrelief"
                      value="emergency and disaster relief"
                      ref={register}
                      checked={purpose === "emergency and disaster relief"}
                      onChange={(e) =>
                        setPurpose("emergency and disaster relief")
                      }
                    />
                    <div className="option-wrap">
                      <img alt="imag" src={Ellipse} />
                      <span>Emergency and Disaster Relief</span>
                    </div>
                  </label>
                  <label className="purpose-option" htmlFor="environment">
                    <input
                      type="radio"
                      name="purposeOption"
                      id="environment"
                      value="environment"
                      ref={register}
                      checked={purpose === "environment"}
                      onChange={(e) => setPurpose("environment")}
                    />
                    <div className="option-wrap">
                      <img alt="imag" src={Ellipse} />
                      <span>Environment</span>
                    </div>
                  </label>
                  <label className="purpose-option" htmlFor="events">
                    <input
                      type="radio"
                      name="purposeOption"
                      id="events"
                      value="events"
                      ref={register}
                      checked={purpose === "events"}
                      onChange={(e) => setPurpose("events")}
                    />
                    <div className="option-wrap">
                      <img alt="imag" src={Ellipse} />
                      <span>Events</span>
                    </div>
                  </label>
                  <label className="purpose-option" htmlFor="faith">
                    <input
                      type="radio"
                      name="purposeOption"
                      id="faith"
                      value="faith"
                      ref={register}
                      checked={purpose === "faith"}
                      onChange={(e) => setPurpose("faith")}
                    />
                    <div className="option-wrap">
                      <img alt="imag" src={Ellipse} />
                      <span>Faith</span>
                    </div>
                  </label>
                </div>

                <div className="column">
                  <label className="purpose-option" htmlFor="friendsandfamily">
                    <input
                      type="radio"
                      name="purposeOption"
                      id="friendsandfamily"
                      value="friends and family"
                      ref={register}
                      checked={purpose === "friends and family"}
                      onChange={(e) => setPurpose("friends and family")}
                    />
                    <div className="option-wrap">
                      <img alt="imag" src={Ellipse} />
                      <span>Friends and Family</span>
                    </div>
                  </label>
                  <label className="purpose-option" htmlFor="healthandmedical">
                    <input
                      type="radio"
                      name="purposeOption"
                      id="healthandmedical"
                      value="health and medical"
                      ref={register}
                      checked={purpose === "health and medical"}
                      onChange={(e) => setPurpose("health and medical")}
                    />
                    <div className="option-wrap">
                      <img alt="imag" src={Ellipse} />
                      <span>Health and Medical</span>
                    </div>
                  </label>
                  <label className="purpose-option" htmlFor="socialjustice">
                    <input
                      type="radio"
                      name="purposeOption"
                      id="socialjustice"
                      value="social justice"
                      ref={register}
                      checked={purpose === "social justice"}
                      onChange={(e) => setPurpose("social justice")}
                    />
                    <div className="option-wrap">
                      <img alt="imag" src={Ellipse} />
                      <span>Social Justice</span>
                    </div>
                  </label>
                  <label
                    className="purpose-option"
                    htmlFor="sportsandrecreation"
                  >
                    <input
                      type="radio"
                      name="purposeOption"
                      id="sportsandrecreation"
                      value="sports and recreation"
                      ref={register}
                      checked={purpose === "sports and recreation"}
                      onChange={(e) => setPurpose("sports and recreation")}
                    />
                    <div className="option-wrap">
                      <img alt="imag" src={Ellipse} />
                      <span>Sports and Recreation</span>
                    </div>
                  </label>
                  <label className="purpose-option" htmlFor="other">
                    <input
                      type="radio"
                      name="purposeOption"
                      id="other"
                      value="other"
                      ref={register}
                      checked={purpose === "other"}
                      onChange={(e) => setPurpose("other")}
                    />
                    <div className="option-wrap">
                      <img alt="imag" src={Ellipse} />
                      <span>Other</span>
                    </div>
                  </label>
                </div>
                {Object.keys(errors).length !== 0 && (
                  <div className="error-box">
                    <ul>
                      {errors.purposeOption && (
                        <li key="1" className="incorrect">
                          {errors.purposeOption.message}
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              </div>
              <div className="fixed-bottom bottom-btn">
                <button
                  disabled={!purpose}
                  style={!purpose ? { backgroundColor: "#A5A5A5" } : {}}
                  type="submit"
                  className="btn btn-primary"
                >
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
