import React from "react";
import { Link } from "react-router-dom";
import {
  ProfileRoute,
  MyListRoute,
  AccountCreatedRoute,
  DashboardRoute,
  MessageListRoute,
  MyActionsListRoute,
} from "../Routing/RouteConstants";
import Cookies from "js-cookie";

const BottomTab = (props) => {
  return (
    <div
      className={
        props.Chatactive
          ? "public_list-footer-mobilehide"
          : "public-list-footer-menu"
      }
    >
      {Cookies.get("token") ? (
        <ul className="navbar-nav">
          <li
            className={`nav-item ${
              props?.activeScreen &&
              props?.activeScreen === "dashboard" &&
              "active"
            }`}
          >
            <Link className="nav-link" to={DashboardRoute}>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M29.4922 9.39674C29.9132 9.67429 30.1666 10.1448 30.1666 10.6491V26.9459C30.1666 28.699 28.6062 30.1667 26.6206 30.1667H21.6054C20.777 30.1667 20.1054 29.4951 20.1054 28.6667V24.8754C20.1054 22.703 18.2128 20.9847 15.9183 20.9847C13.6625 20.9847 11.7313 22.6675 11.7313 24.8754V28.6667C11.7313 29.4951 11.0597 30.1667 10.2313 30.1667H5.37923C3.36644 30.1667 1.83325 28.6741 1.83325 26.9459V10.6328C1.83325 10.1229 2.09229 9.64789 2.52096 9.37176L15.3218 1.12613C15.8213 0.804382 16.4636 0.807784 16.9597 1.13481L23.1315 5.20349L29.4922 9.39674Z"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Home
            </Link>
          </li>
          <li
            className={`nav-item ${
              props?.activeScreen &&
              props?.activeScreen === "my-actions" &&
              "active"
            }`}
          >
            {/* <Link className="nav-link" to={AccountCreatedRoute}> */}
            <Link className="nav-link" to={MyActionsListRoute}>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M23.9054 28.7098C23.9718 29.4907 23.3286 30.1666 22.4882 30.1666H3.33325C2.50483 30.1666 1.83325 29.495 1.83325 28.6666V9.42188C1.83325 8.58125 2.50914 7.9385 3.28946 8.00558C14.2364 8.9466 22.9712 17.7157 23.9054 28.7098Z"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M30.0556 16.6076C30.1434 17.3696 29.5124 18.047 28.6666 18.047H15.52C14.6916 18.047 14.02 17.3755 14.02 16.547V3.33398C14.02 2.48793 14.6975 1.85749 15.4587 1.9462C23.1007 2.83681 29.1714 8.93154 30.0556 16.6076Z"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              My Actions
            </Link>
          </li>
          <li
            className={`nav-item ${
              props?.activeScreen &&
              props?.activeScreen === "my-list" &&
              "active"
            }`}
          >
            <Link className="nav-link" to={MyListRoute}>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M24.31 30.1673H7.69004C5.91407 30.1673 4.5 28.7752 4.5 27.0936V4.90773C4.5 3.22614 5.91407 1.83398 7.69004 1.83398H24.31C26.0859 1.83398 27.5 3.22614 27.5 4.90773V27.1182C27.4728 28.7797 26.0803 30.1673 24.31 30.1673Z"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12 14.5518V18.4663L20.8102 13.334"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              My Lists
            </Link>
          </li>
          <li
            className={`nav-item ${
              props?.activeScreen &&
              props?.activeScreen === "messages" &&
              "active"
            }`}
          >
            <Link className="nav-link" to={MessageListRoute}>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.8034 24.3164C11.7121 24.1698 11.5517 24.0807 11.379 24.0807H6.93024C4.10198 24.0807 1.83337 21.8272 1.83337 19.0381V8.20865C1.83337 5.41961 4.10198 3.16602 6.93024 3.16602H25.0698C27.8964 3.16602 30.1667 5.44637 30.1667 8.20865V19.0381C30.1667 21.8272 27.8981 24.0807 25.0698 24.0807H21.0516C20.8861 24.0807 20.7314 24.1626 20.6383 24.2993L16.4506 30.4493C16.2473 30.7479 15.804 30.7389 15.6129 30.4322L11.8034 24.3164Z"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.63123 14.6443V12.6895H8.86903V14.6443H7.63123Z"
                  fill="#243457"
                />
                <path
                  d="M15.3804 14.6443V12.6895H16.6182V14.6443H15.3804Z"
                  fill="#243457"
                />
                <path
                  d="M23.1301 14.6443V12.6895H24.3679V14.6443H23.1301Z"
                  fill="#243457"
                />
              </svg>
              Messages
            </Link>
          </li>
          <li
            className={`nav-item ${
              props?.activeScreen &&
              props?.activeScreen === "profile" &&
              "active"
            }`}
          >
            <Link className="nav-link" to={ProfileRoute}>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.2774 13.2307L16.0044 14.1165L13.7224 13.2305C11.7855 12.1654 10.8112 10.1476 10.8112 8.28135V6.73749C10.8112 3.96809 13.3151 1.83398 16 1.83398C18.6849 1.83398 21.1889 3.96809 21.1889 6.73749V8.28135C21.1889 10.1204 20.2134 12.1659 18.2774 13.2307ZM11.0546 17.1187L16.005 15.1895L20.9752 17.1192C24.1374 18.6743 26.1667 21.9375 26.1667 25.501V28.6673C26.1667 29.4957 25.4951 30.1673 24.6667 30.1673H7.33338C6.50495 30.1673 5.83337 29.4957 5.83337 28.6673V25.5297C5.83337 21.9676 7.8616 18.7029 11.0546 17.1187Z"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Profile
            </Link>
          </li>
        </ul>
      ) : (
        ""
      )}
    </div>
  );
};
export default BottomTab;
