import React from 'react';

const SvgforDo = (props) => {
  return (
    <svg
      width='33'
      height='32'
      viewBox='0 0 33 32'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.53178 4.71647C7.15375 1.09451 13.0271 1.09451 16.6491 4.71647L17.5224 5.58984C17.9129 5.98036 17.9129 6.61353 17.5224 7.00405L5.81936 18.7071C5.42883 19.0976 4.79567 19.0976 4.40514 18.7071L3.53178 17.8337C-0.0901837 14.2118 -0.0901837 8.33844 3.53178 4.71647ZM15.2348 6.13069C12.3939 3.28977 7.78691 3.28977 4.946 6.13069C2.10508 8.9716 2.10508 13.5786 4.946 16.4195L5.11225 16.5858L15.4011 6.29694L15.2348 6.13069Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.9816 4.71647C20.6035 1.09451 26.4769 1.09451 30.0988 4.71647C33.7208 8.33844 33.7208 14.2118 30.0988 17.8337L29.2255 18.7071C29.0379 18.8946 28.7836 19 28.5184 19C28.2531 19 27.9988 18.8946 27.8113 18.7071L16.1082 7.00405C15.7177 6.61353 15.7177 5.98036 16.1082 5.58984L16.9816 4.71647ZM28.6846 6.13069C25.8437 3.28977 21.2367 3.28977 18.3958 6.13069L18.2295 6.29694L28.5184 16.5858L28.6846 16.4195C31.5255 13.5786 31.5255 8.9716 28.6846 6.13069Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.7322 5.06019C17.0004 5.05785 17.2583 5.16337 17.448 5.35304L29.3094 17.2144C29.6978 17.6028 29.7002 18.2317 29.3149 18.6231L17.3734 30.7527C17.1838 30.9453 16.9243 31.053 16.654 31.0511C16.3838 31.0493 16.1257 30.9381 15.9387 30.743L4.43992 18.7419C4.06828 18.3541 4.06948 17.7418 4.44265 17.3554L16.0216 5.36548C16.2079 5.17253 16.464 5.06253 16.7322 5.06019ZM6.54951 18.0528L16.6705 28.6159L27.1936 17.927L16.7533 7.48678L6.54951 18.0528Z'
      />
    </svg>
  );
};
export default SvgforDo;
