import React, { useEffect, useState } from "react";
import Search from "../assets/images/search.svg";
import Message from "../assets/images/message.svg";
import Message2 from "../assets/images/message2.png";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import HeaderDropdown from "./headerDropdown";
import {
  MyListRoute,
  MessageListRoute,
  MyActionsListRoute,
} from "../Routing/RouteConstants";
import Cookies from "js-cookie";
import ShowCartInfoPreview from "../Screens/PurchasingProcess/CartInfoPreview";
import PurchaseDonationCart from "../Screens/PurchasingProcess/PurchaseAddToCart";
import {
  getUserActionListChats,
  getUserActionMessageChats,
} from "../Store/Actions/userActions";
import axios from "axios";
import AxiosApi from "../Config/axios-api";

export default function UserDesktopHeader(props) {
  const [searchData, setSearchData] = useState();
  const userDetailsError = useSelector((state) => state.UserDetails?.error);
  const [isOpenCartInfoPreview, setIsOpenCartInfoPreview] = useState(false);
  const [messageLength, setMessageLength] = useState();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const dispatch = useDispatch();
  const searchfun = () => {
    if (userDetailsError?.invalidToken) {
      Cookies.set("token", "");
      Cookies.set("userInfo", null);
      localStorage.setItem("userInfo", null);
      props.history.push("/login");
    } else {
      if (searchData) {
        props.history.push("/search/" + searchData);
        setSearchData("");
      }
    }
  };

  const getuserWishList = useSelector(
    (state) => state.getuserWishList.userWishlistInfo
  );

  useEffect(() => {
    if (userDetailsError?.invalidToken) {
      Cookies.set("token", "");
      Cookies.set("userInfo", null);
      localStorage.setItem("userInfo", null);
      props.history.push("/login");
    } else {
      getMessageLength();
    }
  }, []);

  const getMessageLength = async () => {
    const { data } = await AxiosApi.get(
      "/actionList/getMessageLength/" + userInfo?.userId
    );
    console.log({ data });
    setMessageLength(data?.messageLength);
  };

  return (
    <div className="desktop-header dashboard profile">
      <nav className="navbar navbar-expand-md header-navbar">
        <a className="navbar-brand" href="/discover">
          <img src="images/logo_white_desktop.svg" />
        </a>
        <div className="navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto main-menu">
            <li className="nav-item mr-5 mr-xl-5 mr-lg-5 mr-md-0">
              <Link
                className="nav-link "
                style={props.active === "action" ? { fontWeight: "700" } : {}}
                to={MyActionsListRoute}
              >
                My Actions
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                style={props.active === "list" ? { fontWeight: "700" } : {}}
                to={MyListRoute}
              >
                My Lists
              </Link>
            </li>
          </ul>
          <div className="input-group search_group">
            <input
              type="text"
              className="form-control"
              placeholder="Search..."
              value={searchData}
              onChange={(e) => setSearchData(e.target.value)}
              onKeyUp={(e) => e.keyCode === 13 && searchfun()}
            />
            <div className="input-group-append">
              <button
                // className='btn btn-secondary'
                type="button"
                onClick={() => searchfun()}
              >
                <img src={Search} alt="search" />
              </button>
            </div>
          </div>

          <HeaderDropdown />
          <label
            className="cart_section"
            // onClick={() => {
            //   if (getuserWishList?.length) {
            //     const id =
            //       getuserWishList && getuserWishList[0]?.actionListId
            //         ? getuserWishList[0]?.actionListId
            //         : getuserWishList && getuserWishList[1]?.actionListId;
            //     props.history.push("/cart/" + id);
            //   }
            // }
            // }
            onClick={() => setIsOpenCartInfoPreview(true)}
          >
            Cart
            <span>{getuserWishList?.length ? getuserWishList?.length : 0}</span>
          </label>
          {/* {isOpenCartInfoPreview && 
            <ShowCartInfoPreview 
              isOpenModal = {(val) => setIsOpenCartInfoPreview(val)}
              history = {props.history}
            />
          } */}
          {isOpenCartInfoPreview && (
            <PurchaseDonationCart
              isOpenModal={(val) => setIsOpenCartInfoPreview(val)}
              isOpenCartInfoPreviewState={isOpenCartInfoPreview}
              history={props.history}
            />
          )}

          <a
            onClick={() => props.history.push(MessageListRoute)}
            className="message_link"
            style={{ position: "relative" }}
          >
            {props.activeMsg ? (
              <>
                <img src={Message2} alt="message" />
                {messageLength > 0 ? (
                  <span
                    style={{
                      width: "26px",
                      height: "20px",
                      background: "#FF4444",
                      borderRadius: "4px",
                      position: "absolute",
                      top: "-9px",
                      right: "-8px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "#fff",
                      fontSize: "14px",
                    }}
                  >
                    {messageLength > 0 ? messageLength : ""}
                  </span>
                ) : (
                  ""
                )}
              </>
            ) : (
              <>
                <img src={Message} alt="message" />
                {/* {messageLength && (
                  <span
                    style={{
                      width: "26px",
                      height: "20px",
                      background: "#FF4444",
                      borderRadius: "4px",
                      position: "absolute",
                      top: "-9px",  
                      right: "-8px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "#fff",
                      fontSize: "14px",
                    }}
                  >
                    {messageLength && messageLength}
                  </span>
                )} */}
              </>
            )}
          </a>
        </div>
      </nav>
    </div>
  );
}
