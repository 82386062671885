import React, { useState, useEffect } from "react";
/*@Components*/
import LeftPlane from "../../../Common/LeftPlane";
import MobileHeaderTwo from "../../../Common/MobileHeaderTwo";
import DesktopHeaderTwo from "../../../Common/UserDesktopHeaderTwo";
import Editlistdetails from "./Editlistdetails";
import ThemeListinfo from "./ThemeListinfo";
import Preferencelist from "./Preferencelist";
import ContactInfoList from "./ContactInfoList";
import Notificationlist from "./Notificationlist";
import WithDrawindex from "./WithDrawindex";
import TeamList from "./TeamList";
import { USER_NEEDS_DETAIL_LIST_SUCCESS } from "../../../Store/Constants/userConstants";
import { useDispatch, useSelector } from "react-redux";
import AxiosApi from "../../../Config/axios-api";
import Cookies from "js-cookie";
import cross_red from "../../../assets/images/cross_red.png";
import { RenderReport } from "../ReportView";
import "../style.css";
import "../responsive.css";
import BillingSetting from "./BillingSetting";
import { ToastContainer, toast } from "react-toastify";
import "../../../../node_modules/react-toastify/dist/ReactToastify.min.css";

export default function NeedsCreationEmptyList(props) {
  const actionlistData = JSON.parse(localStorage.getItem("selectedList"));
  // console.log({props})
  const locationState = props?.location?.state?.data;
  let editTab = props?.location?.state?.data;
  // console.log({actionlistData})
  const dispatch = useDispatch();
  let [needsFilters, setFilters] = useState(false);
  let [screenWidth, SetScreenWidth] = useState(window.innerWidth);
  let [showStatus, setShowstatus] = useState(
    locationState === "billing" || editTab == "editneed" ? true : false
  );
  let [selectedtab, setSelectedtab] = useState(
    locationState === "billing" ? "billing" : "edit"
  );
  const [selectedaction, setselectedaction] = useState();
  const [currentAction, setcurrentAction] = useState();
  const [teamdelete, setTeramDeteate] = useState();
  const [teamMembersData, setteamMembersData] = useState([]);
  const [selectedActionForRemove, setSelectedActionForRemove] = useState();
  const [amount, setAmount] = useState();

  function filters() {
    setFilters(!needsFilters);
  }

  useEffect(() => {
    setcurrentAction(JSON.parse(localStorage.getItem("selectedList")));
    if (props?.location?.state?.data == "editneed") {
      setSelectedtab("edit");
    }
  }, []);
  console.log(props);
  useEffect(() => {
    if (props?.history?.location?.state?.withdraw == true) {
      setcurrentTab("withdraw");
    }
  }, [props.history?.location?.state?.withdraw]);

  useEffect(() => {
    getActionListData();
    SetScreenWidth(screenWidth);
  }, [window.innerWidth]);

  const setcurrentTab = (tab) => {
    setSelectedtab(tab);
    setShowstatus(true);
    getWithdrawalableBalance();
  };

  const getActionListData = async () => {
    const { data } = await AxiosApi.get(
      "/actionList/getActionListByActionListId/" + props.match.params.id
    );
    localStorage.setItem("selectedList", JSON.stringify(data[0]));
    setcurrentAction(data[0]);
    setselectedaction(data[0]);
  };

  const deleteActionList = async () => {
    const { data } = await AxiosApi.delete(
      "/actionList/removeActionListById/" + props.match.params.id
    );
    if (data.msg === "action list deleted!") {
      window.$("#deleteListModal").modal("toggle");
      props.history.push("/me/lists");
    }
  };

  const removeAdminMember = async () => {
    const { data } = await AxiosApi.delete(
      "/actionList/removeTeamMembers/?actionListId=" +
        props.match.params.id +
        "&userId=" +
        teamdelete
    );
    if (data.success) {
      window.$("#removeAdminModal").modal("toggle");
      toast.success(data?.msg);
      setteamMembersData(data.teamMembersData);
    }
  };

  useEffect(() => {
    //getWithdrawalableBalance();
  }, []);
  const getWithdrawalableBalance = async () => {
    try {
      const { data } = await AxiosApi.get(
        "order/getWithdrawalableBalance/" + props.match.params.id
      );
      console.log(data);
      setAmount(data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <DesktopHeaderTwo history={props.history} />
      <MobileHeaderTwo
        pageTitle="Needs"
        previousPage="/me/lists"
        filter={true}
        close="/account-created"
        history={props.history}
        filterClose={filters}
        selectedTheme={currentAction?.themeName}
      />
      <div class="list-creation-innerpage">
        <LeftPlane
          history={props.history}
          route="/settings"
          activrID={props.match.params.id}
          selectedTheme={currentAction?.themeName}
        />
        <div class="rightpane rightpane-settings">
          <div class="rightpane-header">
            <h2 class="mr-auto">{localStorage.getItem("selectedListName")}</h2>
          </div>
          <div class="tab-content leftpaneTabContent" id="leftpaneTabContent">
            <div
              class="tab-pane fade"
              id="manage-content"
              role="tabpanel"
              aria-labelledby="manage-tab"
            >
              Manage
            </div>
            <div
              class="tab-pane fade"
              id="needs-content"
              role="tabpanel"
              aria-labelledby="needs-tab"
            >
              Needs
            </div>
            <div
              class="tab-pane fade"
              id="reports-content"
              role="tabpanel"
              aria-labelledby="reports-tab"
            >
              Reports
              {/* {RenderReport()} */}
            </div>
            <div
              class={`${currentAction?.themeName} tab-pane fade show active `}
              id="settings-content"
              role="tabpanel"
              aria-labelledby="settings-tab"
            >
              <div
                class="content-wrapper settings setting-newpage"
                id="settingsContentWrapper"
              >
                <ul
                  class="nav nav-tabs settings-tabs "
                  id="settingsTab"
                  role="tablist"
                >
                  <li class="nav-item">
                    <a
                      // class={`nav-link ${screenWidth > 768 && 'active'} `}
                      class={
                        locationState === "billing"
                          ? "nav-link"
                          : `nav-link ${screenWidth > 768 && "active"}`
                      }
                      id="edit-list-tab"
                      data-toggle="tab"
                      href="#edit-list"
                      role="tab"
                      aria-controls="edit-list"
                      aria-selected="true"
                      onClick={() => setcurrentTab("edit")}
                    >
                      Edit list info
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="theme-tab"
                      data-toggle="tab"
                      href="#theme"
                      role="tab"
                      aria-controls="theme"
                      aria-selected="false"
                      onClick={() => setcurrentTab("theme")}
                    >
                      Theme
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="preferences-tab"
                      data-toggle="tab"
                      href="#preferences"
                      role="tab"
                      aria-controls="preferences"
                      aria-selected="false"
                      onClick={() => setcurrentTab("prefernce")}
                    >
                      Preferences
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="withdraw-funds-tab"
                      data-toggle="tab"
                      href="#withdraw-funds"
                      role="tab"
                      aria-controls="withdraw-funds"
                      aria-selected="false"
                      onClick={() => setcurrentTab("withdraw")}
                    >
                      Withdraw funds
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="contact-information-tab"
                      data-toggle="tab"
                      href="#contact-information"
                      role="tab"
                      aria-controls="contact-information"
                      aria-selected="false"
                      onClick={() => setcurrentTab("contact")}
                    >
                      Contact information
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      // class='nav-link'
                      class={
                        locationState === "billing"
                          ? `nav-link ${"active"}`
                          : "nav-link"
                      }
                      id="billing-tab"
                      data-toggle="tab"
                      href="#billing"
                      role="tab"
                      aria-controls="billing"
                      aria-selected="false"
                      onClick={() => setcurrentTab("billing")}
                    >
                      Billing
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="notifications-tab"
                      data-toggle="tab"
                      href="#notifications"
                      role="tab"
                      aria-controls="notifications"
                      aria-selected="false"
                      onClick={() => setcurrentTab("notify")}
                    >
                      Notifications
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="team-tab"
                      data-toggle="tab"
                      href="#team"
                      role="tab"
                      aria-controls="team"
                      aria-selected="false"
                      onClick={() => setcurrentTab("team")}
                    >
                      Team
                    </a>
                  </li>
                </ul>
                <div
                  class={`tab-content ${
                    screenWidth < 768 && showStatus ? "show-content" : "hide"
                  } `}
                  id="settingsTabContent"
                >
                  {selectedtab === "edit" && (
                    <Editlistdetails
                      backdata={() => setShowstatus(false)}
                      selectedaction={selectedaction}
                      actionId={props.match.params.id}
                      listUpdated={getActionListData}
                      newData={props}

                    />
                  )}

                  {selectedtab === "theme" && (
                    <ThemeListinfo
                      backdata={() => setShowstatus(false)}
                      actionId={props.match.params.id}
                      selectedaction={selectedaction}
                      listUpdated={getActionListData}
                    />
                  )}
                  {selectedtab === "prefernce" && (
                    <Preferencelist
                      backdata={() => setShowstatus(false)}
                      actionId={props.match.params.id}
                      selectedaction={selectedaction}
                      listUpdated={getActionListData}
                    />
                  )}
                  {selectedtab === "contact" && (
                    <ContactInfoList
                      backdata={() => setShowstatus(false)}
                      actionId={props.match.params.id}
                      selectedaction={selectedaction}
                      listUpdated={getActionListData}
                    />
                  )}
                  {selectedtab === "notify" && (
                    <Notificationlist
                      backdata={() => setShowstatus(false)}
                      actionId={props.match.params.id}
                    />
                  )}
                  {selectedtab === "team" && (
                    <TeamList
                      backdata={() => setShowstatus(false)}
                      actionId={props.match.params.id}
                      selectedMember={(id) => setTeramDeteate(id)}
                      slctedActnForRemove={(action) =>
                        setSelectedActionForRemove(action)
                      }
                      teamMembersData={teamMembersData}
                    />
                  )}
                  {selectedtab === "withdraw" && (
                    <WithDrawindex
                      backdata={() => setShowstatus(false)}
                      amount={amount}
                      actionList={actionlistData}
                    />
                  )}
                  {selectedtab === "billing" && (
                    // <WithDrawBilling backdata={() => setShowstatus(false)} />
                    <BillingSetting
                      backdata={() => setShowstatus(false)}
                      actionId={props.match.params.id}
                      actionListId={selectedaction?.actionListId}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade delete-list-modal"
        id="deleteListModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <button
                type="button"
                class="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <img src="images/close2.png" />
              </button>
              <h2>Delete the list?</h2>
              <label>Are you sure you want to delete the list?</label>
              <p>
                1. Funds remaining in your account will be returned to donors
              </p>
              <p>2. Any pending needs will be canceled and people notified</p>
              <p>3. You cannot undo this action</p>
              <button
                type="button"
                class="btn btn-delete"
                onClick={() => deleteActionList()}
                // data-dismiss='modal'
              >
                <img src={cross_red} />
                <label>I understand, delete</label>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade remove-admin-modal"
        id="removeAdminModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <button
                type="button"
                class="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <img src="images/close2.png" />
              </button>
              <h2>Remove Organizer</h2>
              <label>
                {"Are you sure you wish to remove " +
                  selectedActionForRemove?.firstName +
                  " " +
                  selectedActionForRemove?.lastName +
                  " as an organizer of this ActionList?"}
              </label>
              <button
                type="button"
                class="btn btn-prime"
                onClick={() => removeAdminMember()}
              >
                Yes, Please remove
              </button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={3000} />
    </div>
  );
}
