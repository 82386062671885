import React, { useState, useEffect } from 'react';
import Disclaimer from '../../../Layout/GuestUser/Disclaimer';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { register as signup } from '../../../Store/Actions/userActions';
import Cookies from 'js-cookie';
import MobileScreenLogo from '../../../Common/MobileScreenLogo';
import DeskTopHeader from '../../../Common/DeskTopHeader';
import { Link } from 'react-router-dom';
import { USER_REGISTER_FAIL } from '../../../Store/Constants/userConstants';
import logoWhiteDesktop from '../../../assets/images/logo_white_desktop.svg'

const StepThreeName = () => {
  let history = useHistory();
  const dispatch = useDispatch();

  const [submitEvent, setSubmitEvent] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [errForFirstName, setErrForFirstName] = useState('')
  const [errForLastName, setErrForLastName] = useState('')
  const { register, handleSubmit, reset, errors } = useForm();
  const [errormess, setErrormsg] = useState('');
  const userRegister = useSelector((state) => state.userRegister);
  const { loading, userInfo, error } = userRegister;

  // useEffect(() => {
  //   if (userInfo) {
  //     if (userInfo.token) {
  //       Cookies.set('token', userInfo.token);
  //       Cookies.set('verified', userInfo.verified);
  //       localStorage.removeItem('StepOneEmail');
  //       localStorage.removeItem('StepTwoPassword');
  //       localStorage.removeItem('InviteCode');
  //       localStorage.removeItem('StepThreeLastName');
  //       localStorage.removeItem('StepThreeFirstName');

  //       if (userInfo.verified) {
  //         history.push('/account-created');
  //       } else {
  //         history.push('/email-verification');
  //       }
  //     }
  //   }
  //   if (error) {
  //     setErrormsg(error.msg);
  //     setTimeout(
  //       function () {
  //         setErrormsg('');
  //         dispatch({ type: USER_REGISTER_FAIL, payload: [] });
  //       }.bind(this),
  //       10000
  //     );
  //   }

  //   return () => {
  //     //
  //   };
  // }, [userInfo, error]);
  
  const onSubmit = (data) => {
    localStorage.setItem('StepThreeFirstName', data.firstname);
    localStorage.setItem('StepThreeLastName', data.lastname);
    if (submitEvent === 'addPhone') {
      localStorage.setItem('StepThreeFirstName', data.firstname);
      localStorage.setItem('StepThreeLastName', data.lastname);
      history.push('/signup-number');
    } else if (submitEvent === 'create') {
      accountCreation(data);
    }
  };

  // const customHandleSubmit = (e) => {
  //   e.preventDefault();
  //   if(firstName && lastName){
  //     localStorage.setItem('StepThreeFirstName', firstName);
  //     localStorage.setItem('StepThreeLastName', lastName);
  //     if (submitEvent === 'addPhone') {
  //       localStorage.setItem('StepThreeFirstName', firstName);
  //       localStorage.setItem('StepThreeLastName', lastName);
  //       history.push('/signup-number');
  //     } else if (submitEvent === 'create') {
  //       accountCreation();
  //     }
  //   }else{
  //     firstName === '' && setErrForFirstName('Enter your first name')
  //     lastName === '' && setErrForLastName('Enter your Last name')
  //   }
  // }

  const signupApiCall = (params) => {
    dispatch(signup(params)).then((res) => {
      if(res?.token){
        Cookies.set('token', res?.token);
        Cookies.set('verified', res?.verified);
        localStorage.removeItem('StepOneEmail');
        localStorage.removeItem('StepTwoPassword');
        localStorage.removeItem('InviteCode');
        localStorage.removeItem('StepThreeLastName');
        localStorage.removeItem('StepThreeFirstName');

        if (res?.verified) {
          history.push('/account-created');
        } else {
          history.push('/email-verification');
        }
      }else{
        setErrormsg(res?.msg);
        setTimeout(
          function () {
            setErrormsg('');
            dispatch({ type: USER_REGISTER_FAIL, payload: [] });
          }.bind(this),
          5000
        );
      }
    });
  }

  const accountCreation = (data) => {
    if (localStorage.getItem('InviteCode') != "null") {
      const params = {
        firstName: data.firstname,
        lastName: data.lastname,
        email: localStorage.getItem('StepOneEmail'),
        password: localStorage.getItem('StepTwoPassword'),
        actionListId: localStorage.getItem('InviteCode')
      }
      signupApiCall(params)
    } else {
      const params = {
        firstName: data.firstname,
        lastName: data.lastname,
        email: localStorage.getItem('StepOneEmail'),
        password: localStorage.getItem('StepTwoPassword')
      }
      signupApiCall(params)
    }
  };

  return (
    <div>
      <div class='mobile-header'>
        <MobileScreenLogo navlink='Log in'   linkdata="/discover"/>
      </div>
      <div class='content signup_content'>
        <div className="logo-desktop ml-0 ml-xl-3 ml-lg-3 ml-md-2">
        <Link to="/discover">
            <img src={logoWhiteDesktop} />
          </Link>
        </div>
      </div>
      <div className="right-signupcontent">
        <div className="login-link">
          <DeskTopHeader
            linkdata='/login'
            displayContent='Already have an account?'
            navlink='Log in'
          />
        </div>
        {/* <div class='desktop-view v2-signup-back-link'>
          <Link to='/login'>
            <img src='images/back.png' />
            Back
          </Link>
  </div> */}

        <div class='row'>
          <div class='col-12'>
            <form
              onSubmit={handleSubmit(onSubmit)}
              // onSubmit={(e) => customHandleSubmit(e)}
              class='signup_form custom_Form_View'
            >
              <div className="backward-arrow" onClick={() => history.goBack()}>
                <img src='images/back.png' />
                <p>back</p>
              </div>
              <h2 class='heading2 signup-head'>Enter your name</h2>
              <div className='form-group'>
                {/* {error?.errors?.length && ( */}
                  <div className='error-box'>
                    {errormess && <p className='incorrect'>{errormess}</p>}
                  </div>
                {/* )}  */}
                <label htmlFor='firstname'>Your first name</label>
                <input
                  type='text'
                  className='form-control'
                  name='firstname'
                  // value = {firstName}
                  // onChange = {(e) => {setFirstName(e?.target?.value)}}
                  ref={register({ required: 'Enter your first name' })}
                  placeholder=''
                />
                {errors.firstname && (
                  <div className='error-box'>
                    {<p className='incorrect'>{errors.firstname.message}</p>}
                  </div>
                )}
                {/* {firstName === '' && (
                  <div className='error-box'>
                    <p className='incorrect'>{errForFirstName}</p>
                  </div>
                )} */}
              </div>
              <div className='form-group'>
                <label htmlFor='lastname'>Your last name</label>
                <input
                  type='text'
                  className='form-control'
                  name='lastname'
                  // value = {lastName}
                  // onChange = {(e) => {setLastName(e?.target?.value)}}
                  ref={register({ required: 'Enter your last name' })}
                  placeholder=''
                />
                {errors.lastname && (
                  <div className='error-box'>
                    {<p className='incorrect'>{errors.lastname.message}</p>}
                  </div>
                )}
                {/* {lastName === '' && (
                  <div className='error-box'>
                    <p className='incorrect'>{errForLastName}</p>
                  </div>
                )} */}
              </div>
              <button
                type='submit'
                onClick={() => setSubmitEvent('create')}
                className='btn btn-primary'
              >
                Create Account
              </button>
              <button
                type='submit'
                onClick={() => setSubmitEvent('addPhone')}
                className='btn btn-extra'
              >
                Add phone number
              </button>
              {/* <Disclaimer /> */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepThreeName;
