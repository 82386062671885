import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Close from "../../assets/images/close2.png";
import minus_active from "../../assets/images/minus_active.png";
import plus_active from "../../assets/images/plus_active.png";
import { getWishList } from "../../Store/Actions/userActions";
import removePic from "../../assets/images/remove.png";
import location from "../../assets/images/location.svg";
import clock from "../../assets/images/clock.svg";
import Profileheadermobile from "../../Common/Profileheadermobile";
import { useHistory } from "react-router-dom";
import { ProcessedToPaymentRoute } from "../../Routing/RouteConstants";
import BottomTab from "../../Common/BottomTab";
import Cookies from "js-cookie";
import UnloginuserHead from "../../Common/UnloginuserHead";
import { stringCapitalize } from "../../services/stringModify";
import moment from "moment";

const ShowCartInfoPreview = (props) => {
  //   const dispatch = useDispatch();
  //   const getuserWishList = useSelector(
  //     (state) => state.getuserWishList.userWishlistInfo
  //   );
  //     const updateItemQty = (id, value) => {
  //     const index = getuserWishList.findIndex((item) => item.needId === id);
  //     let list = [...getuserWishList];
  //     if (value) {
  //       list[index].qty = list[index].qty + 1;
  //       dispatch(getWishList(list));
  //     } else {
  //       if (list[index].qty > 1) {
  //         list[index].qty = list[index].qty - 1;
  //         dispatch(getWishList(list));
  //       }
  //     }
  //   };
  // console.log({getuserWishList});

  const [addLocation, setAddLocation] = useState({ drop: false, pick: false });
  const [title, setTitle] = useState();
  const [address, setAddress] = useState("");
  let history = useHistory();
  const dispatch = useDispatch();
  const getuserWishList = useSelector(
    (state) => state.getuserWishList.userWishlistInfo
  );

  const subTotal = getuserWishList?.reduce(
    (subTotal, item) => subTotal + item.cost * item.qty,
    0
  );

  const BuyQty = getuserWishList?.reduce(function (sum, item) {
    return item.needType === "buy" ? sum + item.qty : sum;
  }, 0);

  const DoQty = getuserWishList?.reduce(function (sum, item) {
    return item.needType === "do" ? sum + item.qty : sum;
  }, 0);

  const GiveQty = getuserWishList?.reduce(function (sum, item) {
    return item.needType === "give" ? sum + item.qty : sum;
  }, 0);

  const DonationSum = getuserWishList?.reduce(function (sum, item) {
    return item.needType === "Donation" ? sum + item.cost : sum;
  }, 0);

  var Buy = false;
  var Do = false;
  var Give = false;
  var Donate = false;

  getuserWishList?.map((item) => {
    if (item.needType === "buy") {
      return (Buy = true);
    } else if (item.needType === "do") {
      return (Do = true);
    } else if (item.needType === "give") {
      return (Give = true);
    } else if (item.needType === "Donation") {
      return (Donate = true);
    }
  });

  const removeItem = (index) => {
    var arrayData = [...getuserWishList];
    if (index !== -1) {
      arrayData.splice(index, 1);
      dispatch(getWishList(arrayData));
    }
  };

  const updateItemQty = (id, value) => {
    const index = getuserWishList.findIndex((item) => item.needId === id);
    let list = [...getuserWishList];
    if (value) {
      list[index].qty = list[index].qty + 1;
      dispatch(getWishList(list));
    } else {
      if (list[index].qty > 1) {
        list[index].qty = list[index].qty - 1;
        dispatch(getWishList(list));
      }
    }
  };
  const [mainData, setMainData] = useState([]);
  const [mainData1, setMainData1] = useState([]);
  let uniqueChars = [];
  useEffect(async () => {
    // const new actionList = []
    // htmlFor() {

    // }

    setMainData(() => {
      const newList = [];
      const newActionList = getuserWishList?.map((item) => {
        // const wishDataWithGiveType = getuserWishList.map((item) => {
        //   let arr= item
        //   if(item.needType === "give"){
        //   if(addLocation.pick){
        //     arr.deliveryType='Pick_up'
        //   }
        //   if(addLocation.drop){
        //     arr.deliveryType='Dropp_off'
        //   }
        // }
        //  return arr
        // })
        let wishData = getuserWishList?.filter(
          (data) => data.actionListId === item.actionListId
        );
        console.log(addLocation);
        setTitle(item.ActionListTitle);
        const obj = {
          title: item.ActionListTitle,
          items: [...wishData],
          id: item.actionListId,
        };
        console.log("items", obj);
        const findOne = newList.find((x) => x.id === item.actionListId);
        if (!findOne) {
          newList.push(obj);
        }

        return item;
      });
      // console.log("newList", newList);

      return [...newList];
    });

    // const data = getuserWishList.map((item) => {
    //   let wishData = getuserWishList.filter(
    //     (data) => data.actionListId === item.actionListId
    //   );
    //   return { title: item.ActionListTitle, items: [...wishData] };
    // });

    // data.map((rec) => {
    //   data.map((item) => {
    //    if(rec.title === item.title){
    //     setMainData(..ma);
    //    }
    //   });
    // });
  }, [getuserWishList]);

  //console.log("mainData", mainData);

  function exist(value, array) {
    return array.some((e) => e === value);
  }

  const handleCheckout = () => {
    let isBuyGiveType;
    const typeOfNeed = getuserWishList?.map((item) => {
      if (item.needType === "buy") {
        return "Buy";
      } else if (item.needType === "do") {
        return "Do";
      } else if (item.needType === "give") {
        return "Give";
      } else if (item.needType === "Donation") {
        return "Donation";
      }
    });

    isBuyGiveType = exist("Buy", typeOfNeed) || exist("Donation", typeOfNeed);

    console.log("isBuyGiveType", isBuyGiveType);
    let arrItems = [...mainData[0].items];

    dispatch(getWishList(arrItems));
    history.push(ProcessedToPaymentRoute + "/" + props.match.params.id);
  };

  const handlePickup = async (id, val) => {
    const index = getuserWishList.findIndex((item) => item.needId === id);

    console.log({ index });
    console.log({ mainData });

    let arrItems = [...mainData[0].items];

    console.log(arrItems);
    if (arrItems[index].needType === "give") {
      arrItems[index].deliveryType = "Pick_up";
      arrItems[index].pickUpAddress = address;
      // if (address) {
      //   arrItems[index].pickUpAddress = address;
      // }
      await setMainData([
        {
          title: title,
          items: arrItems,
          id: id,
        },
      ]);
    }

    console.log("pick", mainData);

    //setAddLocation({drop:addLocation.pick,pick:!addLocation.pick})
  };

  const handleDropOff = async (id, val) => {
    const index = getuserWishList.findIndex((item) => item.needId === id);
    let arrItems = [...mainData[0].items];
    if (arrItems[index].needType === "give") {
      if (address) {
        delete arrItems[index].pickUpAddress;
        setAddress("");
      }
      arrItems[index].deliveryType = "Drop_off";

      await setMainData([
        {
          title: title,

          items: arrItems,
          id: id,
        },
      ]);
    }

    console.log("drop", mainData);

    // let arrayData = [...getuserWishList];
    // delete  arrayData[index].pickUpAddress
    // arrayData[index].deliveryType='Drop_off'
    //dispatch(getWishList(arrayData));

    //setAddLocation({pick:addLocation.drop,drop:!addLocation.drop})
  };

  const handleSaveAddress = async (id, index) => {
    if (address) {
      const index = getuserWishList.findIndex((item) => item.needId === id);

      let arrItems = [...mainData[0].items];

      if (arrItems[index].needType === "give") {
        arrItems[index].pickUpAddress = address;

        await setMainData([
          {
            title: title,
            items: arrItems,
            id: id,
          },
        ]);
      }
    }
  };
  // return (
  //   <div
  //     style={{
  //       width: "100%",
  //       height: "100%",
  //       position: "fixed",
  //       zIndex: "100",
  //       left: "0",
  //       top: "0",
  //       backgroundColor: "rgba(0, 0, 0, 0.5)",
  //     }}
  //   >
  //     <div
  //       className="modal info-preview-modal Need-areanewpopup"
  //       tabindex="-1"
  //       role="dialog"
  //       style={{
  //         display: "block",
  //         maxHeight: "100%",
  //         overflowY: "auto",
  //         top: "0px",
  //       }}
  //       data-backdrop="false"
  //     >
  //       <div className="modal-dialog" role="document">
  //         <div className="modal-content">
  //           <div className="modal-header">
  //             <div className="mobile-header">
  //               <nav className="navbar">
  //                 <div className="cross">
  //                   <img
  //                     src={Close}
  //                     onClick={() => props?.isOpenModal(false)}
  //                   />
  //                 </div>
  //               </nav>
  //             </div>
  //           </div>

  //           {/* <div className="modal-body">
  //             <div className="info-box">
  //               <div className="name-row">
  //                 <label>Cart</label>
  //               </div>
  //             </div>
  //           </div> */}
  //           {(getuserWishList === undefined ||
  //             getuserWishList === null ||
  //             getuserWishList.length === 0) && (
  //             // <center>
  //             //   <div className="empty-msg">
  //             //     <h2>No Data Found :(</h2>
  //             //   </div>
  //             // </center>
  //             <center>
  //               <label className="need-label mt-md-4 mt-3">Cart is Empty</label>
  //             </center>
  //           )}
  //           {/* {getuserWishList.length > 0 &&
  //             getuserWishList.map((item, index) => {
  //               return (
  //                 <div className="modal-body">
  //                   <div className="info-box">
  //                     <label>{item?.needType && item?.needType}:</label>
  //                     <sapan> {item?.Name && item?.Name}</sapan>
  //                     <div className="row2">
  //                       <div className="input-group">
  //                         <div className="input-group-prepend">
  //                           <button
  //                             className="btn btn-outline-secondary"
  //                             type="button"
  //                             onClick={() => {
  //                               updateItemQty(item.needId, false);
  //                             }}
  //                           >
  //                             <img src={minus_active} />
  //                           </button>
  //                         </div>
  //                         <input
  //                           type="text"
  //                           className="form-control"
  //                           value={item.qty}
  //                         />
  //                         <div className="input-group-append">
  //                           <button
  //                             className="btn btn-outline-secondary"
  //                             type="button"
  //                             onClick={() => {
  //                               updateItemQty(item.needId, true);
  //                             }}
  //                           >
  //                             <img src={plus_active} />
  //                           </button>
  //                         </div>
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>
  //               );
  //             })} */}
  //           {/* <button
  //             type="button"
  //             className="btn btn-prime"
  //             onClick={() => {
  //               if (getuserWishList?.length) {
  //                 const id =
  //                   getuserWishList && getuserWishList[0]?.actionListId
  //                     ? getuserWishList[0]?.actionListId
  //                     : getuserWishList && getuserWishList[1]?.actionListId;
  //                 props?.history?.push("/cart/" + id);
  //               }
  //             }}
  //             disabled={getuserWishList?.length ? false : true}
  //           >
  //             Proceed to Checkout
  //           </button> */}
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );
  const renderGetuserWishList = () => {
    return getuserWishList?.length === 0 ? (
      <label className="need-label mt-md-3 mt-3">Nothing in your cart</label>
    ) : (
      mainData &&
        mainData.length > 0 &&
        mainData.map((rec, index) => {
          return (
            <>
              <label className="need-label1 mt-md-4 mt-3">
                {rec?.title !== undefined
                  ? rec?.title
                  : "Actionlist title Donation"}
              </label>
              {rec.items.map((item, i) => {
                return (
                  <>
                    {item.needType === "buy" && (
                      <div className="purchase-detail-box">
                        <a className="remove">
                          <img
                            src={removePic}
                            onClick={() => removeItem(i++)}
                          />
                        </a>
                        <h3>
                          <span>BUY:</span> {stringCapitalize(item?.Name)}
                        </h3>
                        <div className="row2">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <button
                                className="btn btn-outline-secondary"
                                type="button"
                                onClick={() => {
                                  updateItemQty(item.needId, false);
                                }}
                              >
                                <img src={minus_active} />
                              </button>
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              value={item.qty}
                            />
                            <div className="input-group-append">
                              <button
                                className="btn btn-outline-secondary"
                                type="button"
                                onClick={() => {
                                  updateItemQty(item.needId, true);
                                }}
                              >
                                <img src={plus_active} />
                              </button>
                            </div>
                          </div>
                          <label className="price">
                            ${item.cost.toFixed(2)}
                          </label>
                        </div>
                      </div>
                    )}
                    {item.needType === "Donation" && (
                      <div className="purchase-detail-box">
                        <a className="remove">
                          <img
                            src={removePic}
                            onClick={() => removeItem(i++)}
                          />
                        </a>
                        <h3>
                          <span>DONATE:</span> {stringCapitalize(item?.Name)}
                        </h3>
                        <div className="input-group donation-price">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text"
                              id="basic-addon1"
                            >
                              USD
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              placeholder
                              defaultValue={item.cost}
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {item.needType === "give" && (
                      <div className="purchase-detail-box">
                        <a className="remove">
                          <img
                            src={removePic}
                            onClick={() => removeItem(i++)}
                          />
                        </a>
                        <h3>
                          <span>GIVE:</span> {stringCapitalize(item?.Name)}
                        </h3>
                        <div className="location-row">
                          <div className="left d-inline-block">
                          {item?.location && <img src={location} />}
                          </div>
                          <div className="right d-inline-block">
                            <label>Downtown Toronto</label>
                          </div>
                        </div>
                        <div className="drop-pick-row">
                          <button
                            type="button"
                            className={
                              item.deliveryType === "Drop_off" ? "active" : ""
                            }
                            onClick={() => handleDropOff(item.needId, index)}
                            //()=> setAddLocation({pick:addLocation.drop,drop:!addLocation.drop})}
                          >
                            Drop off
                          </button>
                          <button
                            type="button"
                            className={
                              item.deliveryType === "Pick_up" ? "active" : ""
                            }
                            onClick={() => handlePickup(item.needId, index)}
                            //()=> setAddLocation({drop:addLocation.pick,pick:!addLocation.pick})}
                          >
                            Pick up
                          </button>
                        </div>
                        {item.deliveryType === "Pick_up" && (
                          <form>
                            <div className="form-group" id={index}>
                              <label htmlFor="wherefrom">
                                Where it should be picked up from?{" "}
                                <span
                                  role="button"
                                  style={{
                                    color: "#007bff",
                                    textDecoration: "underline",
                                  }}
                                  onClick={() =>
                                    handleSaveAddress(item.needId, index)
                                  }
                                >
                                  Save Address
                                </span>
                              </label>
                              {/* <label htmlFor="wherefrom">Where it should be picked up from?</label> */}
                              <input
                                type="text"
                                className="form-control"
                                id={item.needId}
                                placeholder="Write an address"
                                onChange={(e) => {
                                  setAddress(e.target.value);
                                  handlePickup(item.needId, index);
                                }}
                              />
                            </div>
                          </form>
                        )}
                      </div>
                    )}

                    {item.needType === "do" && (
                      <div className="purchase-detail-box">
                        <a className="remove">
                          <img
                            src={removePic}
                            onClick={() => removeItem(i++)}
                          />
                        </a>
                        <h3>
                          <span>DO:</span> {stringCapitalize(item?.Name)}
                        </h3>
                        <div className="row2">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <button
                                className="btn btn-outline-secondary"
                                type="button"
                                onClick={() => {
                                  updateItemQty(item.needId, false);
                                }}
                              >
                                <img src={minus_active} />
                              </button>
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              value={item.qty}
                            />
                            <div className="input-group-append">
                              <button
                                className="btn btn-outline-secondary"
                                type="button"
                                onClick={() => {
                                  updateItemQty(item.needId, true);
                                }}
                              >
                                <img src={plus_active} />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="location-row">
                          <div className="left d-inline-block">
                            <img src={clock} />
                          </div>
                          <div className="right d-inline-block">
                            <label>
                              Complete-by:{" "}
                              {moment(item?.completedBy).format(
                                "MMM DD YYYY, HH:MM a"
                              )}
                            </label>
                          </div>
                        </div>
                        <div className="location-row">
                          <div className="left d-inline-block">
                          {item?.location &&<img src={location} />}
                          </div>
                          <div className="right d-inline-block">
                            <label>{item.location}</label>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
            </>
          );
        })
    );
  };
  return (
    <>
      <div className="popup-backdropcss">
        <div
          className="modal info-preview-modal Need-areanewpopup cart-newpopup"
          tabindex="-1"
          role="dialog"
          style={{
            display: "block",
            maxHeight: "100%",
            overflowY: "auto",
            top: "0px",
          }}
          data-backdrop="false"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <div className="mobile-header">
                  <nav className="navbar">
                    <div className="cross">
                      <img
                        src={Close}
                        onClick={() => props?.isOpenModal(false)}
                      />
                    </div>
                  </nav>
                </div>
              </div>

              <div className="modal-body ">
                <div className="info-box">
                  <div className="name-row">
                    <label>Cart</label>
                  </div>
                </div>
              </div>
              {renderGetuserWishList()}
              {/* {getuserWishList && getuserWishList?.length > 0 && (
          <button
            type="button"
            className="btn btn-prime proceedto-Checkoutbtn"
            onClick={() => {
              if (getuserWishList?.length) {
                const id =
                  getuserWishList && getuserWishList[0]?.actionListId
                    ? getuserWishList[0]?.actionListId
                    : getuserWishList &&
                      getuserWishList[1]?.actionListId;
                props?.history?.push("/cart/" + id);
              }
              props?.isOpenModal(false);
            }}
            disabled={getuserWishList?.length ? false : true}
          >
            Proceed to Checkout
          </button>
        )} */}
              {getuserWishList && getuserWishList?.length > 0 && (
                <button
                  type="button"
                  className="btn btn-prime proceedto-Checkoutbtn"
                  onClick={() => {
                    console.log("length", getuserWishList);
                    if (getuserWishList?.length) {
                      const id =
                        getuserWishList && getuserWishList[0]?.actionListId
                          ? getuserWishList[0]?.actionListId
                          : getuserWishList && getuserWishList[1]?.actionListId;

                      history?.push("/cart/" + id);
                    }
                    props?.isOpenModal(false);
                  }}
                  disabled={getuserWishList?.length ? false : true}
                >
                  Proceed to Checkout
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShowCartInfoPreview;
