import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Profileheadermobile from '../../Common/Profileheadermobile';
import Footer from '../../Common/Footer';
import {getContactPolicyData} from '../../Store/Actions/userActions';
import renderHTML from 'react-render-html'
import UnloginuserHead from '../../Common/UnloginuserHead';
import Cookies from 'js-cookie';

const Privacypolicy = (props) => {
  const dispatch = useDispatch();
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const [contactPolicyItem, setContactPolicyItem] = useState('');
  
  useEffect(()=>{
    dispatch(getContactPolicyData(userInfo?.token)).then((res) => {
      if(res?.data){
        setContactPolicyItem(res?.data?.result)
      }else{
        setContactPolicyItem('')
      }
    })
  },[])

  return (
    <div>
      {Cookies.get('token') ? (
        <Profileheadermobile
          history={props.history}
        />
      ) : (
        <UnloginuserHead history={props.history} />
      )}
      <div className="content dashboard privacy-and-terms">
        {renderHTML(contactPolicyItem)}
        {/* <div className="container">
          <div className="header-row">
            <h3 className="subhead">Privacy Policy</h3>
          </div>
          <div className="content">
            <p>Timecounts Inc ("we"/"us") is committed to protecting your privacy. We make the website, www.timecounts.org (the “Website”) available as a hub for individuals and Organizations to connect on the terms of the Timecounts Terms of Service (the “Services”). Any capitalized terms not defined herein shall have the meaning defined in the Terms of Service. This Privacy Policy describes how we collect, store, use and distribute information about our site visitors and our users through the Website and Services.</p>
            <div className="divider" />
            <h4 className="section-heading">Consent</h4>
            <p>By using the Website or our Services you consent to the use of your Personal Information as described in this Privacy Policy. Except as set forth in this Privacy Policy, your Personal Information will not be used for any other purpose without your consent. We will not actively collect Personal Information for the purpose of sale or marketing in a way that specifically identifies the individual. In other words, we don’t sell customer lists. You may withdraw your consent to our processing of your Personal Information at any time. However, withdrawing consent may result in your inability to continue using the Website and/or the Services.</p>
            <div className="divider" />
            <h4 className="section-heading">Collection of Information</h4>
            <p className="type-2">We aim to collect, use and disclose only such information as is required to enable us to manage your account, to provide the Services, to maintain our customer/visitor lists, to respond to your inquiries or provide feedback, for identification and authentication purposes and for service improvement. We will maintain the confidentiality of any contact information you provide to us on signing up for our Services or contacting us with questions or for further information and we will use it only for the purposes for which we have collected it (subject to the exclusions and disclosures we have listed below), unless you agree that we may disclose it to other third parties.</p>
            <p className="type-2">Two types of information may be collected through the Website and our Services: Personal Information and Non-Personal Information. This Privacy Policy does not extend to the collection, use or disclosure of the following information which is currently not limited by applicable privacy laws: (a) information that is publicly available, such as names, addresses, telephone numbers and electronic address when listed in a directory or made available through directory assistance; or (b) Non-Personal Information (as defined further below).</p>
            <p><strong>"Personal Information"</strong> is personally identifiable information, such as your name, address, email address, birthdate and gender. At the time of collection, we will clearly identify the information being collected and the purposes for which it will be used. It is always your choice whether or not to provide Personal Information but if you choose not to provide certain requested Personal Information, in some instances you may not be able to register to use the Website or the Services or be able to access and use them at all. In other instances, your choice not to provide certain other Personal Information may mean that you will not be able to use certain features of the Website or the Services. We may collect Personal Information in respect of the Website through registration processes; communications with you; information downloads; user support and surveys.</p>
            <p><strong>"Non-Personal Information"</strong> is information of an anonymous nature, such as the domain used to access the Website, and the type and version of browser or operating system being used by visitors to the Website. Aggregate information, such as demographic statistics of our users (e.g. average age or geographical allocation of our users), number of visitors, what pages users access or visit, and average time spent on the Website is not considered Personal Information. Depending on the jurisdiction you are located in, business contact information such as the name, title, business address, e-mail address, or telephone number of a business or professional person or an employee of an organization is not considered Personal Information.<br />
              Although the use of certain Non-Personal Information collected, used or disclosed through the Internet as described herein is not restricted (and to the extent that such is the case, the obligations under this Privacy Policy do not apply to such information), we provide information in this Privacy Policy about the collection of such information for the sake of transparency with respect to the operation of the Website. Such Non-Personal Information is collected or derived by us in the course of operating this Website. For example, our web servers may automatically collect Non-Personal Information that is provided through your browser or stored in a cookie when you choose to visit the Website.</p>
            <div className="divider" />
            <h4 className="section-heading">Use of Information</h4>
            <p>We collect information for the following purposes:</p>
            <p><strong>Registration:</strong> In order to use the Website and access the Services, you must register for an account either as an individual and/or as an individual who will manage or administer a community on behalf of an organization. You must be at least 13 years old in order to register for an account. When you register for an account, you will be required to input your name and email address. If you register on behalf of an organization you must do so with your email address with that institution should we require verification of your connection with the institution. Once registered, a profile will be created for you that will show your name and a profile picture. Your registration information will not be publicly available to users of the Website, however, your name and profile picture will be visible to other members of your institution and to the members of any activities you join. Your contact information will not be shared with any other users of the Website or Services unless you agree to that information being provided.</p>
            <p><strong>Community Owners:</strong> If you create a community within the Website, you can import your contacts and use the Services like a CRM system. You represent and warrant that you have the right to make such personal information available to us. Timecounts will not use your contacts information for any purpose other than providing the Services to you. If you wish to invite or connect with individuals or send invites to your contacts, this functionality is available within the Services and will only be transmitted with a community administrators’ permission.</p>
            <p><strong>Information Sharing between Users:</strong> Users of the Website can create and join community hubs, and administrators can create events, assignments, and schedules for publishing to the Organization or more publicly on the Website. If you signup or indicate that you will attend an event, your selection will be visible to the Orgaization’s administrators and, for public events, a thumbnail of your photo and the outline of your profile may show up on the event page.</p>
            <p><strong>Transactional Notifications:</strong> We provide notifications for certain activities relating to your use of our Services (eg. if there are events or alerts from Organizations you are a member of and for which you have subscribed for updates). You can manage your notifications by by following the opt-out instructions in each communication, or by contacting the administrator of your Organization. However, even if you have indicated you do not wish to receive emails from us, for example we may send you notices of any updates to our Security, Terms of Service or Privacy Policy.</p>
            <p><strong>Marketing Communications:</strong> If you opt-in to receive marketing communications from us, we will keep you up to date on Timecounts news. You may withdraw your consent to receiving marketing communications from us at any time by following the opt-out instructions in each communication, or by contacting us at support@timecounts.org.</p>
            <p><strong>Statistics:</strong> We also collect statistics about use of the Services for analytics purposes, including predictive analytics. Aggregate statistics that do not personally identify an individual user will be kept by us and such aggregate statistics may be made available to other members or third parties.</p>
            <p><strong>System Logs and Cookies:</strong> Cookies are used by us to track traffic on the Website and to track what content or features of the Website and Services are being used by Users. A cookie is a feature of your web browser that consists of a text file that is placed on your hard disk by a web server. The Website uses cookies to help it compile aggregate statistics about usage of this Website, such as how many users visit the Website, how long users spend viewing the Website, how long users are logged into their account and what pages are viewed most often. This information is used to improve the content of the Website. You can set your browser to notify you when you are sent a cookie. This gives you the chance to decide whether or not to accept it. If you disable cookies, you may not be able to take advantage of all the features of the Website or Services. Your IP address is reported by your web browser whenever you visit a page on the Website. This information is recorded together with your registration information on our databases.</p>
            <p>If we plan to use your Personal Information in future for any other purposes not identified above, we will only do so after informing you by updating this Privacy Policy. See further the section of this Privacy Policy entitled ‘Amendment of this Policy’.</p>
            <div className="divider" />
            <h4 className="section-heading">Discolosures and Transfers</h4>
            <p>We have put in place contractual and other organizational safeguards with our agents (see further below) to ensure a proper level of protection of your Personal Information (see further Security below). In addition to those measures, we will not disclose or transfer your Personal Information to third parties without your permission, except as specified in this Privacy Policy (see further Important Exceptions below).</p>
            <p>We host and store user information on our servers that are located in the United States and accordingly your Personal Information may be available to Canadian and United States government or its agencies under a lawful order, irrespective of the safeguards we have put in place for the protection of your Personal Information.</p>
            <p>Our business partners may include: software developers, hardware vendors, system integrators, payment processors, other websites that cater to mailing list management or analytics and our group companies and affiliates. From time to time we may employ third parties to help us improve the Website and/or the Services. These third parties may have limited access to databases of user information solely for the purpose of helping us to improve the Website and/or the Services and they will be subject to contractual restrictions prohibiting them from using the information about our members for any other purpose.</p>
            <div className="divider" />
            <h4 className="section-heading">Important Exceptions</h4>
            <p>We may disclose your Personal Information to third parties without your consent if we have reason to believe that disclosing this information is necessary to identify, contact or bring legal action against someone who may be causing injury to or interference with (either intentionally or unintentionally) our rights or property, other members of the Website, other users of the Services, or anyone else (including the rights or property of anyone else) that could be harmed by such activities. We may disclose Personal Information when we believe in good faith that such disclosure is required by and in accordance with the law.</p>
            <p>We may also disclose your Personal Information in connection with a corporate reorganization, a merger or amalgamation with another entity, a sale of all or a substantial portion of our assets or stock, including any due diligence exercise carried out in relation to the same, provided that the information disclosed continues to be used for the purposes permitted by this Privacy Policy by the entity acquiring the information.</p>
            <div className="divider" />
            <h4 className="section-heading">Security</h4>
            <p>The security of your Personal Information is important to us. We use commercially reasonable efforts to store and maintain your Personal Information in a secure environment. We take technical, contractual, administrative, and physical security steps designed to protect Personal Information that you provide to us. We have implemented procedures designed to limit the dissemination of your Personal Information to only such designated staff as are reasonably necessary to carry out the stated purposes we have communicated to you. You are also responsible for helping to protect the security of your Personal Information. For instance, never give out your email account information or your password for the Services to third parties.</p>
            <div className="divider" />
            <h4 className="section-heading">Children's Privacy</h4>
            <p>The Website and the Services are not intended for use by persons under the age of 13. We do not knowingly collect information about persons under the age of 13. If you think we have collected such information, please contact us and we will remove such information from our systems.</p>
            <div className="divider" />
            <h4 className="section-heading">Data Retention</h4>
            <p>We will keep your Personal Information for as long as it remains necessary for the identified purpose or as required by law, which may extend beyond the termination of our relationship with you. We may retain certain data as necessary to prevent fraud or future abuse, or for legitimate business purposes, such as analysis of aggregated, non-personally-identifiable data, account recovery, or if required by law. All retained personal information will remain subject to the terms of this Privacy Policy. If you request that your name be removed from our databases, it may not be possible to completely delete all your Personal Information due to technological and legal constraints.</p>
            <div className="divider" />
            <h4 className="section-heading">Amendments of this Policy</h4>
            <p>We reserve the right to change this Privacy Policy at any time. If we decide to change this Privacy Policy in the future, we will provide the user with reasonable notice prior to the change taking effect, either by emailing the email address associated with the user’s account or by messaging through the Services. Any non-material change (such as clarifications) to this Privacy Policy will become effective on the date the change is posted and any material changes will become effective 30 days from their posting on the Website. Unless stated otherwise, our current Privacy Policy applies to all Personal Information that we have about you. The date on which the latest update was made is indicated at the bottom of this document. We recommend that you print a copy of this Privacy Policy for your reference and revisit this policy from time to time to ensure you are aware of any changes. Your continued use of the Website signifies your acceptance of any changes.</p>
            <div className="divider" />
            <h4 className="section-heading">Access and Accuracy</h4>
            <p>You have the right to access the Personal Information we hold about you in order to verify the Personal Information we have collected in respect to you and to have a general account of our uses of that information. Upon receipt of your written request, we will provide you with a copy of your Personal Information for an administrative fee of $20, although in certain limited circumstances, we may not be able to make all relevant information available to you such as where that information also pertains to another user. In such circumstances we will provide reasons for the denial to you upon request within 30 days. We will endeavor to deal with all requests for access and modifications in a timely manner.</p>
            <p>We will make every reasonable effort to keep your Personal Information accurate and up-to-date, and we will provide you with mechanisms to update, correct, delete or add to your Personal Information as appropriate. As appropriate, this amended Personal Information will be transmitted to those parties to which we are permitted to disclose your information. Having accurate Personal Information about you enables us to give you the best possible service.</p>
            <div className="divider" />
            <h4 className="section-heading">Data Controller and Data Processor</h4>
            <p>Each Community Owner is the "Data Controller" of their Administrators' and Members' (volunteers) Personal Data (excluding User Account Data), when Timecounts processes such data solely on such Owner’s behalf. This means that in such cases, we will only process such Member’s data in accordance with the reasonable instructions of the Owner, subject to our Terms of Service and Data Processing Agreement, and strictly on their behalf. The Owner will be solely responsible for meeting any legal requirements applicable to Data Controllers (such as establishing a legal basis for processing and responding to Data Subject Rights requests concerning the data they control).</p>
            <p>Timecounts is the "Data Controller" of the Account Data of its Owners, Managers and Visitors, and also of certain Member Personal Data, when it processes such data for its own purposes. This means that when processing is performed for Timecounts’ purposes (as set forth under this Notice), namely Personal Data which pertains to Owners and Visitors, and Member (volunteers) Personal Data which relevant to their overall use of our Services, then Timecounts will control such data, and assume the responsibilities of Data Controller (solely to the extent applicable under law).</p>
            <div className="divider" />
            <h4 className="section-heading">Contact us</h4>
            <p>You can help by keeping us informed of any changes such as a change of address or telephone number. If you would like to access your information, if you have any questions, comments or suggestions of if you find any errors in our information about you, please contact us at support@timecounts.org or at our mailing address below:</p>
            <p>Timecounts Inc.<br />
              1 Yonge Street,<br />
              Suite 1801,<br />
              Toronto,<br />
              Ontario,<br />
              Canada<br />
              M5E 1W7</p>
            <p>Last Updated: October 30, 2019</p>
          </div>
        </div> */}
      </div>
      <Footer {...props} />
    </div>

  );
};

export default Privacypolicy;
