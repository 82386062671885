import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import {setOrderStatusForMyActions} from '../../Store/Actions/userActions';
import { ToastContainer, toast } from "react-toastify";
import '../../../node_modules/react-toastify/dist/ReactToastify.min.css'
import { stringCapitalize } from "../../services/stringModify";

export const postOrderStatus = (status, odrId, nedId) => async(dispatch) =>{
    const finalStatus =  status === "cancel" ? "declined" : status
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data={
            orderId: odrId,
            needId: nedId,
            status: finalStatus
        }

        try{
            const response = await dispatch(setOrderStatusForMyActions(data, userInfo?.token))
            if(response?.data){
                toast.success(stringCapitalize(response?.data?.msg))
                if(status === "declined" ){
                    return "declined"
                }else if(status === "confirmed"){
                    return "confirmed"
                }else if(status === "done"){
                    return "done"
                }else if(status === "cancel"){
                    return "cancel"
                }
            }

        }catch(error){
            toast.error("Somthing went wrong!")
        }
        
        
        
        return(
            <div>
                <ToastContainer autoClose={3000} />
            </div>
        )
}

export const renderStatus = (status) => {
    // if(status === "confirmed" || status === "done"){
    //     return "Confirmed"
    // }else if(status === "declined"){
    //     return "Canceled"
    // }else{
    //     return "Pending"
    // }

    if(status === "confirmed"){
        return "Confirmed"
    }else if( status === "done"){
        return "Done"
    }else if(status === "declined"){
        // return "Canceled"
        return "Not Needed"
    }else{
        return "Pending"
    }
}