// user Route
const userRoute = '';

// Profile Routes paths constants
export const ProfileRoute = `${userRoute}/me/settings`;
export const ProfileAccSettingsRoute = `${ProfileRoute}/account-settings`;
export const ProfilePaymentMethodsRoute = `${ProfileRoute}/payment-methods`;
export const ProfileEmailNotificationsRoute = `${ProfileRoute}/email-notifications`;
export const ProfileChangePasswordRoute = `${ProfileRoute}/change-password`;

//My List route
export const MyListRoute = `${userRoute}/me/lists`;
export const ListCreationRoute = `${userRoute}/me/create/step1`;
export const AccountCreatedRoute = `${userRoute}/account-created`;
export const DashboardRoute = `${userRoute}/discover`;

//Purchasing Process route
export const PurchaseAddToCart = `${userRoute}/cart`;
export const ProcessedToCheckoutRoute = `${userRoute}/processed-to-checkout`;
export const ProcessedToPaymentRoute = `${userRoute}/processed-to-payment`;
export const ProcessedToSuccessRoute = `${userRoute}/processed-to-success`;

//Messaging route
export const MessageListRoute = `${userRoute}/messageList`;

//My-Actions-Route
export const MyActionsListRoute = `${userRoute}/me/actions`;

//Privacy-Policy-Route
export const PrivacyPolicyRoute = `${userRoute}/privacy`;

//Term-and-Condition-Route
export const TermAndConditionRoute = `${userRoute}/terms`;