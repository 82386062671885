import React, { useState } from "react";
import { getWishList } from "../../Store/Actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { ProcessedToPaymentRoute } from "../../Routing/RouteConstants";

const GeneralDonation = ({
  id,
  onClick,
  history,
  actionId,
  actionListTitle,
  type,
}) => {
  const [donationAmount, setDonationamount] = useState("");
  const dispatch = useDispatch();
  const getuserWishList = useSelector(
    (state) => state.getuserWishList.userWishlistInfo
  );
  const donate = () => {
    let obj = {};
    obj.needId = "donate";
    obj.qty = 1;
    obj.needType = "Donation";
    obj.title = actionListTitle && actionListTitle;
    obj.cost = parseInt(donationAmount);
    obj.actionListId = actionId;
    return obj;
  };

  const OnlyDonation = (e) => {
    e.preventDefault()
    console.log({getuserWishList})
    if (getuserWishList !== undefined) {
      let temp = [...getuserWishList];
      let finalData = [];
      let obj = {};

      obj.needId = "donate";
      obj.qty = 1;
      obj.needType = "Donation";
      obj.ActionListTitle = actionListTitle && actionListTitle;
      obj.Name = actionListTitle && actionListTitle;
      obj.cost = parseInt(donationAmount);
      obj.actionListId = actionId;
      temp.push(obj);
      console.log({ history });
      console.log("temp", temp);
      dispatch(getWishList(temp));
      window.$("#donateListModal").modal("hide");
      if (type === "normal") {
        history.push(ProcessedToPaymentRoute + "/" + actionId);
      } else if (type === "embed") {
        history.push("/embed/processed-to-payment/" + actionId);
      }
    } else {
      let temp = [];
      let finalData = [];
      let obj = {};

      obj.needId = "donate";
      obj.qty = 1;
      obj.needType = "Donation";
      obj.ActionListTitle = actionListTitle && actionListTitle;
      obj.Name = actionListTitle && actionListTitle;
      obj.cost = parseInt(donationAmount);
      obj.actionListId = actionId;
      temp.push(obj);
      console.log({ history });
      console.log("temp", temp);
      dispatch(getWishList(temp));
      window.$("#donateListModal").modal("hide");
      // toast.success("Need successfully added to your cart.");
    }
  };
  // const forActionListId = (item) => {
  //   console.log("OnlyDonation", item);
  // };
  // useEffect(() => {
  //   if (userNeedlistInfo) {
  //     const newFile = userNeedlistInfo.map((item) => {
  //       return { ...item, qty: 1 };
  //     });
  //     setNeedsList(newFile);
  //   }
  // }, [userNeedlistInfo]);

  return (
    <div>
      <div
        class="modal fade share-list-modal"
        id={id}
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <div class="mobile-header">
                <nav class="navbar">
                  <label class="navbar-brand mx-auto middle-label">
                    Enter your donation
                  </label>
                  <a
                    onClick={() => window.$("#donateListModal").modal("hide")}
                    class="cross"
                  >
                    <img src="images/close2.png" />
                  </a>
                </nav>
              </div>

              <h1 class="donation_head d-md-block d-none">
                Enter your donation
              </h1>
              <button
                type="button"
                class="btn-close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => window.$("#donateListModal").modal("hide")}
              >
                <img src="images/close2.png" />
              </button>
              <div class="content modal_content1">
                <p className="para text-center">
                  Your donation will go towards Buy items on our ActionList that
                  the organizer selects
                </p>

                <form
                  className="donation-form"
                  onChange={(e) => {
                    setDonationamount(e.target.value);
                  }}
                >
                  <label htmlFor="$10" className="radio-label">
                    <input type="radio" name="donation" id="$10" value={10} />
                    <span className="checkmark">$10</span>
                  </label>
                  <label htmlFor="$25" className="radio-label">
                    <input type="radio" name="donation" id="$25" value={25} />
                    <span className="checkmark">$25</span>
                  </label>
                  <label htmlFor="$100" className="radio-label">
                    <input type="radio" name="donation" id="$100" value={100} />
                    <span className="checkmark">$100</span>
                  </label>
                  <label htmlFor="$250" className="radio-label">
                    <input type="radio" name="donation" id="$250" value={250} />
                    <span className="checkmark">$250</span>
                  </label>
                  <div className="input-group donation-price mt-lg-0 mb-lg-0 mt-3 mb-4">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">
                        USD
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="$0.00"
                        value={donationAmount}
                        onChange={(e) => setDonationamount(e.target.value)}
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                      />
                    </div>
                  </div>
                  {console.log({donationAmount})}
                  <button
                    type="submit"
                    className="btn btn-prime btn-donate"
                    onClick={(e) => OnlyDonation(e)}
                    disabled ={donationAmount === "" ? true : false}
                  >
                    Donate
                  </button>
                  <a
                    className="link-extra"
                    onClick={() => {
                      onClick(donate());
                      window.$("#donateListModal").modal("hide");
                    }}
                  >
                    Buy
                  </a>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralDonation;
