import React from 'react';
import { useSelector } from 'react-redux';
import ListCreationMobileHeader from '../../../Common/MobileHeaderOne';
import UserDeskTopHeaderOne from '../../../Common/UserDeskTopHeaderOne';

export default function ListCreationSuccessScreen(props) {
  const createList = useSelector((state) => state.createList);
  const { userCreateList } = createList;
  return (
    <div>
      <UserDeskTopHeaderOne history={props.history} />
      <ListCreationMobileHeader
        pageTitle='Create new ActionList'
        previousPage='/me/create/step1'
        close='/account-created'
        history={props.history}
      />

      <div className='content congratulation_content'>
        <h3 className='heading2'>You have created a new ActionList!</h3>
        <p>
          The next step is to start adding Needs to your list so people can find ways to take action.
        </p>
        <button
          type='button'
          className='btn_primary'
          // onClick={(e) => props.history.push("/needs-creation")}
          onClick={(e) =>
            props.history.push('/needs/' + userCreateList.actionListId)
          }
        >
          Add Needs
        </button>
      </div>
    </div>
  );
}
